import create from 'zustand'
import { devtools } from 'zustand/middleware'

export interface BankTransferState {
  bank?: string
  accountHolderName?: string
  accountType?: string
  routingNumber?: string
  accountNumber?: string

  email?: string
  phone?: string

  addressCity?: any
  addressStreet?: any
  addressState?: {
    name?: string
    state_code?: string
  } | null

  addressPostalCode?: string

  amount?: number
  amountSetting?: string

  transferType?: 'outbound-transfers' | 'outbound-payments'
}

interface BankTransferActions {
  setBankTransferDetails: (data: BankTransferState) => void
  reset: () => void
}

const initialState: BankTransferState = {
  bank: '',
  accountHolderName: '',
  accountType: '',
  routingNumber: '',
  accountNumber: '',
  amount: undefined,

  email: '',
  phone: '',

  addressCity: undefined,
  addressStreet: undefined,
  addressState: null,

  addressPostalCode: '',

  amountSetting: 'custom',
  transferType: undefined
}

export const useBankTransferStore = create<BankTransferState & BankTransferActions>()(
  devtools(
    (set) => ({
      ...initialState,
      reset() {
        set(initialState)
      },
      setBankTransferDetails(data: BankTransferState) {
        return set((state) => {
          return {
            ...state,
            ...data
          }
        })
      }
    }),
    {
      name: 'zt_owners_bank_transfer'
    }
  )
)
