import { ReactNode } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { BsBinocularsFill, BsCalendar2Date, BsCardList, BsCurrencyDollar, BsFillBriefcaseFill, BsFillChatFill, BsFillClockFill, BsFillExclamationTriangleFill, BsInboxFill, BsLayoutTextWindowReverse, BsNodePlusFill, BsPalette2, BsPeopleFill, BsPersonCheckFill, BsPersonFill, BsWalletFill } from 'react-icons/bs'
import { CgProfile } from 'react-icons/cg'
import { FaHatWizard, FaTasks } from 'react-icons/fa'
import { HiHome } from 'react-icons/hi2'
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from 'react-router-dom'
import { Badge as MUIBadge, BadgeProps, styled } from '@mui/material'

import { THEME } from '#app/layouts/theme'
import { OwnersManagement } from '#app/routes/Admin/OwnersManagement'
import { OwnersTasks } from '#app/routes/Admin/OwnersTasks'
import UserManagement from '#app/routes/Admin/UserManagement/UserManagement'
import { Onboarding as BankingOnboarding } from '#app/routes/User/Banking/Onboarding'
import { CustomersManagement } from '#app/routes/User/CustomersManagement'
import CustomerDetails from '#app/routes/User/CustomersManagement/CustomerDetails'
import Congratulations from '#app/routes/User/Onboarding/Congratulations'
import FirstBusinessInformation from '#app/routes/User/Onboarding/FirstBusinessInformation'
import Onboarding from '#app/routes/User/Onboarding/Onboarding'
import PersonalInformation from '#app/routes/User/Onboarding/PersonalInformation'
import { Profile } from '#app/routes/User/Profile'
import { useStoreUser } from '#app/store/user'
import { User } from '#app/types'
import { useIsDesktopSize } from '#app/utils/mediaQueries'
import { Badge } from '#app/v2/components/ui/badge'
import { routesUI } from '#app/v2/config/routesV2UI'
import { routesV2 } from '#app/v2-core/config/routesV2'

import { Audiences } from './Admin/Audiences'
import { BusinessesManagement } from './Admin/Businesses'
import { BusinessDetails } from './Admin/Businesses/BusinessDetails'
import { ChatChannel } from './Admin/Chat'
import { ChatWrapper } from './Admin/Chat/ChatWrapper'
import { Superchat } from './Admin/Chat/Superchat'
import { OwnersTasksPending } from './Admin/OwnersTasksPending'
import { PropertyManagers } from './Admin/PropertyManagers'
import { WorkOrder } from './Admin/PropertyManagers/WorkOrder'
import { SequencesTable } from './Admin/Sequences'
import { ShowcaseComponents } from './Admin/Showcase'
import { VersionEditor } from './Admin/WebsiteEditor/VersionEditor'
import { WebsiteTemplates } from './Admin/WebsiteEditor/WebsiteList'
import Wizards from './Admin/Wizard'
import WizardDemo from './Admin/Wizard/WizardDemo'
import { JobDetails } from './Jobs/JobDetails'
import { CalendlyEmbed } from './Public/CalendlyEmbed'
import { CreateBO } from './Public/CreateBO'
import { CustomerSuccessBot } from './Public/CustomerSuccessBot'
import { PublicInvoices } from './Public/Invoices'
import { Payments } from './Public/Payments'
import { PMWorkOrderDetails } from './Public/PropertyManagers/PMWorkOrderDetails'
import { CreateBid } from './Public/PropertyManagers/ServiceProviders/CreateBid'
import { WorkOrderDetails } from './Public/PropertyManagers/ServiceProviders/WorkOrderDetails'
import { PublicCustomerManagement } from './Public/PublicCustomerManagement/PublicCustomerManagement'
import { PublicGenerateSite } from './Public/PublicGenerateSite/PublicGenerateSite'
import { PublicInstall } from './Public/PublicInstall/PublicInstall'
import { PreviewDeployment } from './Public/WebsiteEditor/PreviewDeployment'
import { WelcomeForm } from './Public/WelcomeCallBot'
import { Banking } from './User/Banking/Banking'
import { Invoices } from './User/Banking/Invoices'
import { Link } from './User/Banking/Link'
import { Transactions } from './User/Banking/Transactions'
import { Booking } from './User/Bookings'
import { Availability } from './User/Bookings/Availability'
import { EventTypes } from './User/Bookings/EventTypes'
import { Configuring } from './User/Configuring'
import { DomainSelection } from './User/OnboardingTasks/DomainSelection'
import { OwnersTasksAsUser } from './User/OwnersTasks'
import Plans from './User/Plans/Plans'
import { CreateBid as CreateBidPilot } from './User/PropertyManagers/CreateBid'
import { CancelSubscription } from './User/Subscription/CancelSubscription'
import { NotFoundPage } from './NotFoundPage'
import { ProtectedRoutesWrapper } from './ProtectedRoutesWrapper'
import { ProtectedRoutesWrapperV2 } from './ProtectedRoutesWrapperV2'
import { PublicRoutesWrapper } from './PublicRoutesWrapper'
import { Scheduler } from './Scheduler'
import { TaskDetail } from './TaskDetail'

export const OWNERS_ADMIN = 'owners-admin'
export const OWNERS_USER = 'owners-user'

export const USER_PROSPECT = 'prospect'
export const BUSINESS_PROSPECT = 'prospect'
export const USER_SIGNED_UP = 'signed-up'
export const BUSINESS_ONBOARDING = 'onboarding'
export const BUSINESS_ONBOARDED = 'onboarded'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BUSINESS_LAUNCHED = 'launched'

export interface RouteOwners extends RouteObject {
  path?: string
  element?: JSX.Element
  img?: string
  icon?: ReactNode
  title?: string
  default?: boolean
  navBar?: boolean
  bottomNavigation?: Array<{ path: string, label: string, icon: JSX.Element }>
  children?: RouteOwners[]
  role?: string[]
  status?: string[]
  pattern?: RegExp
  externalLink?: string
  statusCondition?: 'and' | 'or'
}

const StyledBadge = styled(MUIBadge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: 13,
    padding: 0,
    fontSize: '10px',
  },
}))

const paymentsBottomRoutes = (t: TFunction<'translation', undefined>) => {
  return [{
    icon: <BsCurrencyDollar fontSize={THEME.ICONS.size.sm} />,
    label: t('payments.bottomNavigation.link', 'Payments'),
    path: '/payments/link'
  },
  {
    icon: <BsLayoutTextWindowReverse fontSize={THEME.ICONS.size.sm} />,
    label: t('payments.bottomNavigation.invoices', 'Invoices'),
    path: '/payments/invoices'
  },
  {
    icon: <BsCardList fontSize={THEME.ICONS.size.sm} />,
    label: t('payments.bottomNavigation.transactions', 'Transactions'),
    path: '/payments/transactions'
  },
  {
    icon: <BsWalletFill fontSize={THEME.ICONS.size.sm} />,
    label: t('payments.bottomNavigation.banking', 'Banking'),
    path: '/payments/banking'
  }]
}

function useRoutesOwner() {
  const { t } = useTranslation()
  const isDesktop = useIsDesktopSize()

  const { unreadMessages } = useStoreUser()

  const routes: RouteOwners[] = [
    {
      path: '/v2-ui',
      element: window.location.pathname === '/v2-ui'
        ? (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}
          >
            <a href='/v2-ui/index'>
              <Badge className=' text-lg'>MiniApp V2 UI</Badge>
            </a>
          </div>
        )
        : undefined,
      children: routesUI
    },
    {
      path: '/v2',
      element: <ProtectedRoutesWrapperV2 />,
      default: !isDesktop,
      children: routesV2,
      role: [OWNERS_USER],
      status: [USER_SIGNED_UP, BUSINESS_ONBOARDED]
    },
    {
      path: '/',
      element: <ProtectedRoutesWrapper />,
      children: [{
        path: '/user-management',
        role: [OWNERS_ADMIN],
        navBar: true,
        title: t('navigations.adminManagement').toUpperCase(),
        icon: <BsPersonCheckFill fontSize={THEME.ICONS.size.sm} />,
        element: <UserManagement />
      },
      {
        title: t('navigations.ownersManagement').toUpperCase(),
        icon: <BsPeopleFill fontSize={THEME.ICONS.size.sm} />,
        path: '/owners-management',
        default: true,
        navBar: true,
        role: [OWNERS_ADMIN],
        element: <OwnersManagement />,
        children: [{
          path: '/owners-management/:userId',
          role: [OWNERS_ADMIN],
          pattern: /\/owners-management\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/,
          element: <OwnersManagement />
        }]
      },
      {
        path: '/chat',
        role: [OWNERS_ADMIN],
        element: <ChatWrapper />,
        children: [{
          path: '/chat',
          index: true,
          icon: (
            <>
              <StyledBadge
                badgeContent={unreadMessages}
                color='error'
                max={9}
                overlap='circular'
                invisible={unreadMessages === 0}
              >
                <BsFillChatFill fontSize={THEME.ICONS.size.sm} />
              </StyledBadge>
            </>),
          title: t('navigations.chat').toUpperCase(),
          navBar: true,
          role: [OWNERS_ADMIN],
          element: <Superchat />
        },
        {
          path: '/chat/user/:userId',
          element: <Superchat />,
          role: [OWNERS_ADMIN],
          pattern: /\/chat\/user\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/
        },
        {
          path: '/chat/:userId',
          role: [OWNERS_ADMIN],
          pattern: /\/chat\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/,
          element: <ChatChannel />
        },

        {
          path: '/chat/channel/:channelId',
          role: [OWNERS_ADMIN],
          pattern: /^\/chat\/channel\/[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\-]{1,64}$/,
          element: <ChatChannel />
        }]
      },
      {
        title: t('navigations.ownersInbox').toUpperCase(),
        icon: <FaTasks fontSize={THEME.ICONS.size.sm} />,
        path: '/owners-tasks',
        role: [OWNERS_ADMIN],
        navBar: true,
        element: <OwnersTasks />,
        children: [{
          path: '/owners-tasks/:userId',
          pattern: /\/owners-tasks\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/,
          role: [OWNERS_ADMIN],
          element: <OwnersTasks />
        }]
      },
      {
        title: t('navigations.inboxPending').toUpperCase(),
        icon: <BsInboxFill fontSize={THEME.ICONS.size.sm} />,
        path: '/pending-owners-tasks',
        role: [OWNERS_ADMIN],
        navBar: true,
        element: <OwnersTasksPending />
      },
      {
        title: t('navigations.sequences').toUpperCase(),
        icon: <BsNodePlusFill fontSize={THEME.ICONS.size.sm} />,
        path: '/sequences',
        role: [OWNERS_ADMIN],
        navBar: true,
        element: <SequencesTable />
      },
      {
        title: t('navigations.audiences').toUpperCase(),
        icon: <BsBinocularsFill fontSize={THEME.ICONS.size.sm} />,
        path: '/audiences',
        role: [OWNERS_ADMIN],
        navBar: true,
        element: <Audiences />
      },
      {
        title: t('navigations.ownersTaskDetails').toUpperCase(),
        navBar: false,
        pattern: /\/task\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/,
        path: '/task/:taskId',
        role: [OWNERS_ADMIN],
        element: <TaskDetail />
      },
      {
        title: t('navigations.ownersTaskDetails').toUpperCase(),
        navBar: false,
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        pattern: /\/task\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/,
        path: '/task/:taskId',
        role: [OWNERS_USER],
        element: <TaskDetail />
      },
      {
        path: '/onboarding',
        element: <Onboarding />,
        default: true,
        role: [OWNERS_USER],
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDING],
        children: [{
          path: '/onboarding/personal-information',
          status: [USER_SIGNED_UP, BUSINESS_ONBOARDING],
          role: [OWNERS_USER],
          element: <PersonalInformation />
        },
        {
          path: '/onboarding/first-business-information',
          role: [OWNERS_USER],
          status: [USER_SIGNED_UP, BUSINESS_ONBOARDING],
          element: <FirstBusinessInformation />
        },
        {
          path: '/onboarding/congratulations',
          role: [OWNERS_USER],
          status: [USER_SIGNED_UP, BUSINESS_ONBOARDING],
          element: <Congratulations />
        },
        {
          path: '/onboarding/congratulations',
          role: [OWNERS_USER],
          status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
          element: <Congratulations />
        }]
      },
      {
        path: '/v2/home',
        title: 'HOME',
        icon: <HiHome fontSize={THEME.ICONS.size.sm} />,
        role: [OWNERS_USER],
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        navBar: !isDesktop,
        element: <Navigate to='/v2' />
      },
      {
        path: '/profile',
        title: t('navigations.profile', 'Profile').toUpperCase(),
        icon: <CgProfile fontSize={THEME.ICONS.size.sm} />,
        role: [OWNERS_USER],
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        navBar: true,
        element: <Profile />
      },
      {
        path: '/tasks',
        title: t('navigations.inbox', 'Inbox').toUpperCase(),
        icon: <FaTasks fontSize={THEME.ICONS.size.sm} />,
        role: [OWNERS_USER],
        default: isDesktop,
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        navBar: true,
        element: <OwnersTasksAsUser />
      },
      {
        path: '/tasks/:taskId',
        pattern: /\/tasks\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/,
        role: [OWNERS_USER],
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        element: <TaskDetail />
      },
      {
        path: '/tasks/:taskId',
        pattern: /\/tasks\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/,
        role: [OWNERS_ADMIN],
        element: <TaskDetail />
      },
      {
        path: '/payments',
        title: t('navigations.payments').toUpperCase(),
        icon: <BsWalletFill fontSize={THEME.ICONS.size.sm} />,
        role: [OWNERS_USER],
        default: false,
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        navBar: true,
        bottomNavigation: paymentsBottomRoutes(t),
        element: <BankingOnboarding />
      },
      {
        path: '/bookings/main',
        title: t('navigations.bookings').toUpperCase(),
        icon: <BsCalendar2Date fontSize={THEME.ICONS.size.sm} />,
        role: [OWNERS_USER],
        default: false,
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        navBar: true,
        element: <Booking />
      },
      {
        path: '/bookings/types',
        role: [OWNERS_USER],
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        navBar: false,
        element: <EventTypes />
      },
      {
        path: '/bookings/availability',
        role: [OWNERS_USER],
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        navBar: false,
        element: <Availability />
      },
      {
        path: '/payments/link',
        role: [OWNERS_USER],
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        navBar: false,
        element: <Link />,
        bottomNavigation: paymentsBottomRoutes(t)
      },
      {
        path: '/payments/invoices',
        role: [OWNERS_USER],
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        navBar: false,
        element: <Invoices />,
        bottomNavigation: paymentsBottomRoutes(t)
      },
      {
        path: '/payments/transactions',
        role: [OWNERS_USER],
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        navBar: false,
        element: <Transactions />,
        bottomNavigation: paymentsBottomRoutes(t)
      },
      {
        path: '/payments/banking',
        role: [OWNERS_USER],
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        navBar: false,
        element: <Banking />,
        bottomNavigation: paymentsBottomRoutes(t)
      },
      {
        title: t('navigations.customers').toUpperCase(),
        icon: <BsPersonFill fontSize={THEME.ICONS.size.sm} />,
        path: '/customers',
        role: [OWNERS_USER],
        navBar: true,
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        element: <CustomersManagement />
      },
      {
        path: '/customers/:customerId',
        role: [OWNERS_USER],
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        pattern: /\/customers\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/,
        element: <CustomerDetails />
      },
      {
        path: '/customers/:customerId/jobs/:jobId',
        role: [OWNERS_USER],
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        pattern: /\/customers\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\/jobs\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/,
        element: (
          <JobDetails
            showEdit={false}
            navigateBack={({ customerId }: { customerId?: string }) => {
              if (customerId) {
                return `/customers/${customerId}`
              }
              return ''
            }}
          />)
      },
      {
        title: 'BUSINESSES',
        icon: <BsFillBriefcaseFill fontSize={THEME.ICONS.size.sm} />,
        path: '/businesses',
        role: [OWNERS_ADMIN],
        navBar: true,
        element: <BusinessesManagement />
      },
      {
        path: '/businesses/:businessId',
        role: [OWNERS_ADMIN],
        pattern: /\/businesses\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/,
        element: <BusinessDetails />
      },
      {
        path: '/showcase',
        role: [OWNERS_ADMIN],
        navBar: false,
        element: <ShowcaseComponents />
      },
      {
        path: '/businesses/:businessId/jobs/:jobId',
        role: [OWNERS_ADMIN],
        pattern: /\/businesses\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\/jobs\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/,
        element:
        (
          <JobDetails
            showEdit={true}
            navigateBack={({ businessId }: { businessId?: string }) => {
              if (businessId) {
                return `/businesses/${businessId}`
              }
              return ''
            }}
          />
        )
      },
      {
        title: t('navigations.propertyManagers').toUpperCase(),
        icon: <BsPalette2 fontSize={THEME.ICONS.size.sm} />,
        path: '/property-managers',
        role: [OWNERS_ADMIN],
        navBar: true,
        element: <PropertyManagers />
      },
      {
        path: '/property-managers/work-order/:workOrderId',
        pattern: /\/property-managers\/work-order\/.*/,
        role: [OWNERS_ADMIN],
        navBar: false,
        element: <WorkOrder />
      },
      {
        title: t('navigations.scheduler').toUpperCase(),
        icon: <BsFillClockFill fontSize={THEME.ICONS.size.sm} />,
        path: '/scheduler',
        role: [OWNERS_ADMIN],
        navBar: true,
        element: <Scheduler />
      },
      {
        title: t('navigations.scheduler').toUpperCase(),
        icon: <BsFillClockFill fontSize={THEME.ICONS.size.sm} />,
        path: '/scheduler',
        role: [OWNERS_USER],
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        navBar: false,
        element: <Scheduler />
      },
      {
        path: '/create-bid',
        role: [OWNERS_USER],
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        navBar: false,
        element: <CreateBidPilot />
      },
      {
        path: '/cancel-subscription',
        role: [OWNERS_USER],
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED],
        navBar: false,
        element: <CancelSubscription />
      },
      {
        path: '/plans',
        role: [OWNERS_USER],
        default: true,
        status: [USER_PROSPECT, BUSINESS_PROSPECT],
        element: <Plans />
      },
      {
        title: t('configuring.navbarLabel', 'Configuring').toUpperCase(),
        icon: <BsFillExclamationTriangleFill fontSize={THEME.ICONS.size.sm} />,
        path: '/configuring',
        role: [OWNERS_USER],
        statusCondition: 'or',
        status: [USER_PROSPECT, BUSINESS_PROSPECT],
        navBar: true,
        element: <Configuring />
      },
      {
        path: '/onboarding-tasks/name-domain-selection/:type',
        pattern: /\/onboarding-tasks\/name-domain-selection\/(domain-only|name-and-domain)/,
        element: <DomainSelection />,
        default: false,
        role: [OWNERS_USER],
        status: [USER_SIGNED_UP, BUSINESS_ONBOARDED]
      },
      {
        path: '/onboarding-tasks/name-domain-selection/:type',
        pattern: /\/onboarding-tasks\/name-domain-selection\/(domain-only|name-and-domain)/,
        element: <DomainSelection />,
        default: false,
        role: [OWNERS_ADMIN]
      },
      {
        path: '/wizards',
        title: 'Wizards',
        icon: <FaHatWizard />,
        element: <Wizards />,
        navBar: true,
        default: false,
        role: [OWNERS_ADMIN]
      },
      {
        path: '/wizards/demo/:wizardId',
        icon: <BsFillExclamationTriangleFill />,
        pattern: /\/wizards\/demo\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/,
        element: <WizardDemo />,
        navBar: false,
        default: false,
        role: [OWNERS_ADMIN]
      },
      // {
      //   path: '/website-generator/:boId',
      //   icon: <BsFillExclamationTriangleFill />,
      //   pattern: /\/website-generator\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/,
      //   element: <WebsiteGenerator />,
      //   navBar: false,
      //   default: false,
      //   role: [OWNERS_ADMIN]
      // },
      {
        path: '/website-editor/:userId',
        icon: <BsFillExclamationTriangleFill />,
        element: <WebsiteTemplates />,
        title: 'WEBSITE TEMPLATES',
        navBar: false,
        default: false,
        role: [OWNERS_ADMIN]
      },
      {
        path: '/website-editor/:userId/:websiteVersion',
        icon: <BsFillExclamationTriangleFill />,
        pattern: /\/website-editor\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/,
        element: <VersionEditor />,
        navBar: false,
        default: false,
        role: [OWNERS_ADMIN]
      },
      ]
    },
    {
      // NOTE: Only use for customer related pages
      path: '/public',
      element: <PublicRoutesWrapper />,
      children: [{
        path: '/public/customer/:userId',
        element: <PublicCustomerManagement />
      },
      {
        path: '/public/service-providers/work-order',
        element: <WorkOrderDetails />
      },
      {
        path: '/public/new-bo',
        element: <CreateBO />
      },
      {
        path: '/public/b/deployment/:deploymentId',
        element: <PreviewDeployment />
      },
      {
        path: '/public/book/:calendlySlug',
        element: <CalendlyEmbed />
      },
      {
        path: '/public/book/:calendlySlug/:calendlyEventType',
        element: <CalendlyEmbed />
      },
      {
        path: '/public/welcome-flow',
        element: <WelcomeForm />
      },
      {
        path: '/public/cs-bot',
        element: <CustomerSuccessBot />
      },
      {
        path: '/public/property-managers/work-order',
        element: <PMWorkOrderDetails />
      },
      {
        path: '/public/service-providers/work-order/bid',
        element: <CreateBid />
      },
      {
        path: '/public/payments/:businessId',
        element: <Payments />
      },
      {
        path: '/public/:businessId/invoices/:invoiceId',
        element: <PublicInvoices />
      },
      {
        path: '/public/demo-sites',
        element: <PublicGenerateSite />
      }]
    },
    {
      path: '/install',
      element: <PublicInstall />
    },
    {
      path: '*',
      element: <NotFoundPage />
    }]

  return {
    routes
  }
}

export const OwnersRouter = () => {
  const { routes } = useRoutesOwner()
  const router = createBrowserRouter(routes)
  return <RouterProvider router={router} />
}

export function useAccessibleRoutes(user: User.UserType): RouteOwners[] {
  const isBlocked = user?.blocked
  const { routes } = useRoutesOwner()
  if (isBlocked) {
    return routes
      .filter((r) => r.path === '/plans')
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const accessibleRoutes: RouteOwners[] = routes
    // flatten
    // @ts-ignore
    .reduce((acc: RouteOwners, curr: RouteOwners) => {
      // @ts-ignore
      return getRoutes(curr, acc)
    }, [])
    // @ts-ignore
    .filter((route: RouteOwners) => {
      const userRole = user?.role?.slug
      const userStatus = user?.status
      const businessStatus = user?.businesses[0]?.status

      const bankingStatus = user?.stripe_banking_status

      const rolesDependency = route.role

      const hasRole = rolesDependency ? rolesDependency.includes(userRole) : true

      const statusDepencency = route.status

      const hasUserStatus = statusDepencency ? route.status?.includes(userStatus) : true
      const hasBusinessStatus = statusDepencency ? route.status?.includes(businessStatus) : true

      if (route.path === '/payments' && bankingStatus !== 'open') {
        delete route.bottomNavigation
      }

      const checkStatusCondition = (hasUserStatus?: boolean, hasBusinessStatus?: boolean, statusCondition: 'and' | 'or' = 'and') => {
        if (statusCondition === 'and') {
          return hasUserStatus && hasBusinessStatus
        }
        else {
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          return (hasUserStatus || hasBusinessStatus)
        }
      }

      return hasRole && checkStatusCondition(hasUserStatus, hasBusinessStatus, route.statusCondition)
    })

  return accessibleRoutes
}

function getRoutes(node: RouteOwners, acc: RouteOwners[] = []): RouteOwners[] {
  acc.push(node)

  if (node.children) {
    node.children.forEach((child) => getRoutes(child, acc))
  }

  return acc
}
