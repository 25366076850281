import { useMemo } from 'react'
import { FormControlLabel, FormGroup, Stack } from '@mui/material'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Unstable_Grid2'

import { DividerExtended } from '#app/components/Divider'
import useWebsiteEditorStore from '#app/store/website-editor'

import { SectionProps } from '../../types'
import { CustomTextField } from '../styled'

export function HeaderSection({ isContainer, sectionId, title, description }: SectionProps) {
  const { sections, updateSectionDetails } = useWebsiteEditorStore()

  const isActiveSection = useMemo(() => {
    if (sections && sections[sectionId]) {
      return sections[sectionId].isActive
    }
    return false
  }, [sectionId, sections])

  return (
    <>
      <Stack direction='row'>
        <FormGroup>
          <FormControlLabel
            control={(
              <Switch
                disabled={isContainer}
                checked={isActiveSection}
                color='success'
                onChange={(event) => {
                  // console.log('[updateSectionDetails] HeaderSection')
                  updateSectionDetails(sectionId, {
                    isActive: event.target.checked
                  })
                }}
              />
            )}
            label={isActiveSection ? 'ON' : 'OFF'}
            sx={{
              '.MuiFormControlLabel-label': {
                minWidth: '5ch',
                color: isActiveSection ? 'green' : 'gray',
                fontWeight: '600',
              },
              marginRight: '0px'
            }}
          />
        </FormGroup>
      </Stack>
      <DividerExtended />
      <Grid
        container
        gap={1}
        alignItems='center'
      >
        <Grid
          xs={4}
        >
          <CustomTextField
            hiddenLabel
            disabled
            variant='standard'
            defaultValue={title}
            placeholder='Section title'
            size='medium'
            sx={{
              '& .MuiInputBase-input': {
                fontSize: '20px'
              }
            }}
          />
        </Grid>
        <Grid xs={6}>
          <CustomTextField
            hiddenLabel
            multiline
            fullWidth
            disabled
            maxRows={2}
            variant='standard'
            defaultValue={description}
            placeholder='Section description'
            size='small'
            className='flex-grow-1'
            sx={{
              '& .MuiInputBase-input': {
                fontSize: '14px'
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
