import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { renderString } from 'nunjucks'

import { getInitialsUser } from '#app/components/Chip/ChipUser'
import { ENVIROMENT } from '#app/store/enviroment'
import { HorizontalMenuCircle } from '#app/v2/assets/Icons'
import { Avatar, AvatarFallback, AvatarImage } from '#app/v2/components/ui/avatar'
import { Badge } from '#app/v2/components/ui/badge'
import { Button } from '#app/v2/components/ui/button'
import Dropdown from '#app/v2/components/ui/dropdown-menu'
import { cn } from '#app/v2/libs/utils'

import { TaskCardProps } from '../types'
export interface ToDoCardProps {
  onClickURL: string // i.e '/v2/tasks/{{task_id}}',
  task: TaskCardProps
}

export const ToDoCard = ({ onClickURL, task }: ToDoCardProps) => {
  const { id, sector, profile, title, description, badges } = task
  const { t } = useTranslation()
  const cardURL = `${renderString(onClickURL, {
    task_id: id
  })}`
  const copyURL = `${ENVIROMENT.front_url}/${cardURL}`
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  return (
    <Link
      to={cardURL}
      state={{
        from: '/v2/tasks',
        to: cardURL
      }}
      className=' block'
    >
      <li
        className='space-y-2 rounded-xl bg-white px-6 py-4 shadow'
        onClick={handleClick}
      >
        {/* Header */}
        <div className='flex justify-between'>
          <div className=' flex items-center gap-2'>
            {profile
              ? (
                <Avatar className='h-6 w-6'>
                  <AvatarImage
                    src={profile?.avatar}
                    className='object-cover'
                  />
                  <AvatarFallback className='bg-[#E9F0FF] text-xs text-[#304985]'>{getInitialsUser(`${profile?.firstName} ${profile?.lastName}`)}</AvatarFallback>
                </Avatar>
              )
              : null}
            <Badge
              variant='secondary'
              className='h-fit border-0 bg-[#6BE7BE]/60 text-xs/4 text-[#304985]'
            >
              {sector ?? 'General'}
            </Badge>
          </div>
          <Dropdown.Menu>
            <Dropdown.Trigger asChild>
              <Button
                variant='outline'
                className='rounded-full border-0 p-1.5'
              >
                <HorizontalMenuCircle className='h-6 w-6 text-[#70828A]' />
              </Button>
            </Dropdown.Trigger>
            <Dropdown.Content align='end'>
              <CopyToClipboard text={copyURL}>
                <Dropdown.Item>
                  {t('v2.tasks.option.copyLink', 'Copy link')}
                </Dropdown.Item>
              </CopyToClipboard>
            </Dropdown.Content>
          </Dropdown.Menu>
        </div>
        {/* Title */}
        <h2 className=' text-sm font-semibold text-[#3B4B52]'>{title}</h2>
        {/* Description */}
        {/* <p className=" max-w-[30ch] text-xs text-[#454545]">{description}</p> */}
        {/* <PreviewRichContentV2 htmlContent={description} /> */}
        <div className=' flex w-full justify-end gap-1'>
          {badges?.length
            ? (
              badges.map((val, i) => (
                <Badge
                  key={i}
                  variant='secondary'
                  className={cn('border-0 p-1 text-xs/3 text-[#304985]', {
                    'bg-[#304985]/10': val === 'assigned',
                    'bg-[#6BE7BE]/60': val.includes('comments')
                  }
                  )}
                >
                  {val === 'assigned' && t('v2.tasks.status.newTask', 'New Task')}
                  {val !== 'assigned' && `${val}`}
                </Badge>

              ))
            )
            : null}
        </div>
      </li>
    </Link>
  )
}
