import * as React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import parse from 'autosuggest-highlight/parse'
import { useField } from 'formik'
import { isArray, isObject } from 'lodash'
import throttle from 'lodash/throttle'

const autocompleteService = {
  current: null
}

export default function InputWithPlaces({ onlyMainText = false, label, types, fields, countries, placeholder = '', multiple, disabled = false, limitTags, sx = {}, ...props }) {
  const [, meta, helpers] = useField(props)
  const { setValue } = helpers
  const { value } = meta
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState([])

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        const autoCompleteReq = {
          ...request,
          componentRestrictions: {
            country: countries || ['us']
          },
          types: types || ['address'],
          fields: fields || ['geometry', 'formatted_address', 'place_id'],
          language: 'en'
        }
        autocompleteService.current.getPlacePredictions(autoCompleteReq, callback)
      }, 200),
    []
  )

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && window.google) {
      autocompleteService.current
        = new window.google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({
      input: inputValue
    }, (results) => {
      if (active) {
        let newOptions = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  return (
    <Autocomplete
      fullWidth
      autoComplete
      filterSelectedOptions
      disabled={disabled}
      multiple={!!multiple}
      limitTags={limitTags || 0}
      getOptionLabel={(option) =>
        typeof option === 'string'
          ? option
          : (onlyMainText && option?.structured_formatting?.main_text
            ? option?.structured_formatting?.main_text
            : option.description || ' ')}
      filterOptions={(x) => x}
      options={options}
      value={meta.value}
      isOptionEqualToValue={(option, value) => {
        if (isArray(option)) {
          return option.find((opt) => opt.place_id === value.place_id)
        }
        else if (isObject(option)) {
          return option.place_id === value.place_id
        }
        else {
          return true
        }
      }}
      renderInput={(params) => {
        return (
          <>
            {label && <label className='form-label'>{label}</label>}
            <TextField
              sx={sx}
              error={meta.touched && !!meta.error}
              placeholder={placeholder}
              {...params}
            />
            <div className='error'>{meta.touched && meta.error}</div>
          </>
        )
      }}
      renderOption={(props, option) => {
        const selectedOption = isArray(option) ? option[option.length - 1] : option
        if (!selectedOption) { return null }
        const matches = selectedOption?.structured_formatting?.main_text_matched_substrings ?? []

        const parts = parse(
          selectedOption?.structured_formatting?.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        )

        return (
          <li {...props}>
            <Grid
              container
              alignItems='center'
            >
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{
                    color: 'text.secondary',
                    mr: 2
                  }}
                />
              </Grid>
              <Grid
                item
                xs
              >
                {parts.map((part, index) => (
                  <span
                    key={`${part.text}-${index}`}
                    style={{
                      fontWeight: part.highlight ? 700 : 400
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography
                  variant='body2'
                  color='text.secondary'
                >
                  {selectedOption?.structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
      onChange={(event, values) => {
        const newValue = isArray(values) ? values[values.length - 1] : values

        setOptions(newValue ? [newValue, ...options] : options)

        const hasCoordinates = newValue && newValue.coordinates

        if (newValue && !hasCoordinates) {
          const geocoder = new window.google.maps.Geocoder()

          geocoder.geocode({
            placeId: newValue.place_id
          },
          function (responses, status) {
            if (status === 'OK') {
              const lat = responses[0].geometry.location.lat()
              const lng = responses[0].geometry.location.lng()

              if (!isArray(values)) {
                values = {
                  ...values,
                  coordinates: {
                    lat,
                    lng
                  }
                }
              }
              else {
                values[values.length - 1] = {
                  ...values[values.length - 1],
                  coordinates: {
                    lat,
                    lng
                  }
                }
              }
            }

            setValue(values)
          })
        }
        else {
          setValue(values)
        }

        document?.activeElement?.blur()
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
    />
  )
}
