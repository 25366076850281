import { useContext, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

import { useAppContext } from '#app/contexts/AppContext/useAppContext'

import AppContext from '../../../contexts/AppContext'
import BusinessInforForm from '../../../forms/Onboarding/BusinessInforForm'
import { getFormValues } from '../../../hooks/useOnboardingFormReducer'

const FirstBusinessInformation = () => {
  const { appState } = useContext(AppContext)
  const { V1_SET_LOADING } = useAppContext()

  const {
    onboardingReducer: [onboarding, dispatch],
    navigateToPrevious,
    loadingState: [, setLoading]
  } = useOutletContext()

  useEffect(() => {
    if (appState.isLoading) {
      setTimeout(() => {
        V1_SET_LOADING(false)
      }, 500)
    }
  })

  const dispatchAction = async (values) => {
    setLoading(true)
    await dispatch({
      type: 'SET_BUSINESS_INFORMATION',
      values
    })
  }

  return (
    <BusinessInforForm
      information={getFormValues(onboarding.sections.businessInformation)}
      goBack={navigateToPrevious}
      onSubmit={dispatchAction}
    />
  )
}

export default FirstBusinessInformation
