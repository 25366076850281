import { useTranslation } from 'react-i18next'

import { Button } from '#app/v2/components/ui/button'

export const Actions = ({ onNextStep, canProceed, onSaveAndContinue }: { onNextStep?: () => void, canProceed?: boolean, onSaveAndContinue?: () => void }) => {
  const { t } = useTranslation()
  return (
    <div className=' mx-auto mb-14 mt-10 w-[60%] max-w-xs space-y-6'>
      <Button
        disabled={!canProceed}
        className=' w-full py-3.5 font-bold'
        onClick={onNextStep}
      >
        {t('v2.wizard.actions.primary', 'Next')}
      </Button>
      <Button
        className='w-full border-[#304985] py-3.5 font-bold text-[#304985]'
        variant='outline'
        onClick={onSaveAndContinue}
      >
        {t('v2.wizard.actions.secondary', 'Save and continue later')}
      </Button>
    </div>
  )
}
