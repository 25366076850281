import styled from '@emotion/styled'
import { TimelineDot } from '@mui/lab'

export const TimelineDotExtended = styled(TimelineDot)`
  cursor: ${(props) => props.onClick ? 'pointer' : 'auto'};
  border-radius: 5px;
  &:hover {
    transform: scale(1.5);
    transition: transform .3s;
  }
`
