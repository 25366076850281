/* eslint-disable @typescript-eslint/no-unsafe-call */

import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Grid, Stack, Typography } from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'

import Button from '#app/components/Buttons/Button'
import RadioButtonGroup from '#app/components/RadioButtonGroup/RadioButtonGroup'
import { useEntityUserTasks } from '#app/hooks/useEntityUserTasks'
import { useOnboardingNameDomain } from '#app/store/onboarding-name-domain'
import { GET_PROFILE_TYPE } from '#app/utils/graphqlQueries'

export interface SelectNameComponentsProps {
  userProfile?: GET_PROFILE_TYPE
}

export const SelectNameComponents = ({ userProfile }: SelectNameComponentsProps) => {
  const { t } = useTranslation()

  const [isLoading, setLoading] = useState<boolean>()

  const [searchParams] = useSearchParams()

  const initialComponents = ['includeCity', 'includeName']

  const [uiSuggestionComponents, setUiSuggestionComponents] = useState<string[]>(initialComponents)

  const { suggestionComponents, setSuggestionComponents, setCurrentStep, task, setTask } = useOnboardingNameDomain()

  const { mutationEditUserTask } = useEntityUserTasks()

  const isSoleProp: boolean = useMemo(() => !!task?.metadata?.isSoleProp, [task])

  const schema = Yup
    .object()
    .shape({
      nameOptions: Yup
        .array()
    })

  const onSubmit = async (values: FormikValues) => {
    setLoading(true)

    setSuggestionComponents(values.nameOptions as string[])

    const taskMetadata = {
      ...task?.metadata,
      currentStep: 'generate-options',
      suggestionComponents: values.nameOptions
    }

    const editTask = {
      id: searchParams.get('taskId') ?? '',
      user_id: userProfile?.getProfile.user?.id ?? '',
      metadata: taskMetadata
    }

    await mutationEditUserTask(
      {
        variables: editTask
      })
      .then(() => {
        if (task && editTask) {
          setTask({
            ...task,
            ...editTask
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })

    setCurrentStep('generate-options')

    setLoading(false)
  }

  useEffect(() => {
    if (task?.metadata?.suggestions?.length) {
      const isSoleProp = task?.metadata?.isSoleProp
      const wordSuggestions = task
        .metadata
        .suggestions
        .map(({ suggestion }: { suggestion: string }) => suggestion)

      let suggestionsList = [...wordSuggestions]
      if (!isSoleProp) {
        suggestionsList = [...initialComponents, ...suggestionsList]
      }

      setUiSuggestionComponents(suggestionsList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, onSubmit])

  return (
    <Stack spacing={3}>
      <Typography variant='h6'>{t('onboarding.domainNameSelection.steps.nameComponents.title', 'We are going to help you selecting the name for your company')}</Typography>
      {!isSoleProp
      && <Typography>{t('onboarding.domainNameSelection.steps.nameComponents.description', 'Let\'s start by picking some inspirational components for your name, do not select any options if you want to generate random names based on your service')}</Typography>}
      <Formik
        initialValues={{
          nameOptions: suggestionComponents
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {
          ({ values }) => {
            return (
              <Form>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    {/* @ts-ignore */}
                    <RadioButtonGroup
                      initialValue={values.nameOptions}
                      name='nameOptions'
                    >
                      {
                        uiSuggestionComponents
                          .map((suggestion, key) => {
                            if (suggestion === 'includeCity') {
                              return (
                                // @ts-ignore
                                <RadioButtonGroup.CheckboxButton
                                  key={key}
                                  value='includeCity'
                                >
                                  {t('onboarding.domainNameSelection.steps.nameComponents.includeMyCity', 'Include my city')}
                                </RadioButtonGroup.CheckboxButton>
                              )
                            }
                            else if (suggestion === 'includeName') {
                              return (
                                // @ts-ignore
                                <RadioButtonGroup.CheckboxButton
                                  key={key}
                                  value='includeName'
                                >
                                  {t('onboarding.domainNameSelection.steps.nameComponents.includeMyName', 'Include my name')}
                                </RadioButtonGroup.CheckboxButton>
                              )
                            }
                            else {
                              return (
                                // @ts-ignore
                                <RadioButtonGroup.CheckboxButton
                                  key={key}
                                  disabled={isSoleProp}
                                  value={suggestion}
                                >
                                  {t('onboarding.domainNameSelection.steps.nameComponents.includeWord', 'Include word:') + ` ${suggestion}`}
                                </RadioButtonGroup.CheckboxButton>
                              )
                            }
                          })
                      }
                    </RadioButtonGroup>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Stack
                      direction='row'
                      justifyContent='end'
                    >
                      <Button
                        disabled={isLoading}
                        isLoading={isLoading}
                        type='submit'
                      >
                        {t('onboarding.domainNameSelection.steps.nameComponents.button', 'Generate')}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            )
          }
        }
      </Formik>
    </Stack>
  )
}
