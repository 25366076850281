import { useCallback } from 'react'
import { ApolloCache, useLazyQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { Sequence } from '#app/types'
import { MUTATION, QUERY, QUERY_GET_SEQUENCES } from '#app/utils/graphqlQueries'

import useMutationWithNotification from './useMutationWithNotification'

export function useEntitySequence({ variables }: { variables: any }) {
  const { enqueueSnackbar } = useSnackbar()
  const mainQuery = QUERY.sequences.getSequences

  const updateCacheApollo = useCallback((mutationName: string, cache: ApolloCache<any>, data: any) => {
    const cacheQuery = cache.readQuery<QUERY_GET_SEQUENCES>({
      query: mainQuery,
      variables
    })

    if (cacheQuery?.sequences && mutationName) {
      try {
        const sequenceData = data[mutationName]?.sequence
        const sequenceUpdated: Sequence.SequenceType = {
          ...sequenceData,
          __typename: 'sequences'
        }

        let sequencesUpdated: Sequence.SequenceType[]

        if (mutationName === 'createSequence') {
          sequencesUpdated = [...(cacheQuery?.sequences ?? []), sequenceUpdated]
        }
        else {
          sequencesUpdated = cacheQuery?.sequences.map((sequenceTmp) => {
            if (sequenceTmp?.id === sequenceUpdated.id) {
              return sequenceUpdated
            }
            return sequenceTmp
          })
        }

        cache.writeQuery({
          query: mainQuery,
          variables,
          data: {
            sequences: sequencesUpdated
          }
        })
      }
      catch (err) {
        enqueueSnackbar('We have an error updating the screen', {
          variant: 'error'
        })
      }
    }
  }, [enqueueSnackbar, mainQuery, variables])

  const [
    queryGetSequences,
    { data: queryGetSequencesData, ...queryGetSequencesUtils }
  ] = useLazyQuery<QUERY_GET_SEQUENCES>(mainQuery, {
    pollInterval: 10000
  })

  const [mutationCreateSequence, mutationCreateSequenceUtils] = useMutationWithNotification(MUTATION.sequences.createSequence, {
    update(cache, { data }) {
      updateCacheApollo('createSequence', cache, data)
    }
  })

  return {
    // GraphQL Query
    queryGetSequences,
    queryGetSequencesData,
    queryGetSequencesUtils,
    // GraphQL Mutation
    mutationCreateSequence,
    mutationCreateSequenceUtils
  }
}
