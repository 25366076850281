import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import SearchInput from '#app/components/SearchInput/SearchInput'
import { AdvancedFilters, getNumberOfFilters, OwnersFilter } from '#app/forms/OwnersFilter'

import Table, { TableStructure } from '../../components/Table/Table'

interface Props {
  tableStructure?: TableStructure
  onFilterChange?: (e: ChangeEvent<HTMLInputElement>) => void
  advancedFilters?: AdvancedFilters
  onAdvancedFilterChange?: (values: any, closeHandler?: () => void) => void
}

export default function OwnersList({
  tableStructure,
  onFilterChange,
  advancedFilters,
  onAdvancedFilterChange,
}: Props) {
  const { t } = useTranslation()

  return (
    <div className='v1-bo-list'>
      {
        onFilterChange && onAdvancedFilterChange && (
          <SearchInput
            advancedFiltersApplied={getNumberOfFilters(advancedFilters)}
            hasAdvancedFilters={true}
            filterForm={(
              <OwnersFilter
                filters={advancedFilters}
                onSubmit={onAdvancedFilterChange}
              />
            )}
            placeholder={t('general.searchPlaceholder')}
            onFilterChange={onFilterChange}
          />
        )
      }
      <Box sx={{
        height: '1.25rem'
      }}
      />
      <Table
        displayMobile={true}
        structure={tableStructure}
      />
    </div>
  )
}
