/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useEffect, useRef, useState } from 'react'
import { JSONEditor } from '@json-editor/json-editor'
import { Divider } from '@mui/material'

import Button from '#app/components/Buttons/Button'

import { WizardState } from './CreateWizard'

import './styles.css'

interface WizardEditorProps {
  schema: WizardState
  onDataChanged?: (data: any) => void
  onSubmit: (data: any) => void
  isSubmitting?: boolean
  initialValues?: WizardState | null
  setMounted?: (bool: boolean) => void
}

const WizardEditor: React.FC<WizardEditorProps> = ({ schema, onDataChanged, isSubmitting, onSubmit, initialValues, setMounted }) => {
  const editorRef = useRef<HTMLDivElement | null>(null)
  const [values, setValues] = useState<JSON | null>(null)
  const [buttonText, setButtonText] = useState<string>('Create Wizard')
  useEffect(() => {
    if (!editorRef.current) return

    let editor: { getEditor: (arg0: any) => any, on: (arg0: string, arg1: () => void) => void, getValue: () => any, setValue: (arg0: any) => void, destroy: () => void, enable: () => void }
    let isInternalUpdate = false

    // Deactivate the UUID generator for the questions and options keys

    // const setEditorValue = (path: string, value: any) => {
    //   const editorField = editor.getEditor(path)
    //   if (editorField) {
    //     editorField.setValue(value)
    //   }
    // }

    // const assignUUIDsToQuestions = (questions: any[], basePath = 'root.questions') => {
    //   questions.forEach((question, qIndex) => {
    //     const questionPath = `${basePath}.${qIndex}`
    //     if (!question.question_key) {
    //       question.question_key = uuidv4()
    //       setEditorValue(`${questionPath}.question_key`, question.question_key)
    //     }

    //     if (question.options) {
    //       question.options.forEach((option: { option_key: string, subquestions: any[] }, oIndex: any) => {
    //         const optionPath = `${questionPath}.options.${oIndex}`
    //         if (!option.option_key) {
    //           option.option_key = uuidv4()
    //           setEditorValue(`${optionPath}.option_key`, option.option_key)
    //         }

    //         if (option.subquestions) {
    //           assignUUIDsToQuestions(option.subquestions, `${optionPath}.subquestions`)
    //         }
    //       })
    //     }
    //   })
    // }

    if (editorRef.current) {
      editor = new JSONEditor(editorRef.current, {
        schema,
        theme: 'tailwind',
        disable_collapse: true
        // iconlib: 'fontawesome4',
      })

      editor.on('ready', () => {
        editor.enable()
        if (initialValues && initialValues.wizard_flow) {
          const { title, subtitle, wizard_flow: questions, text_end_1: text1, text_end_2: text2 } = initialValues
          editor.setValue({
            title,
            subtitle,
            questions,
            text_end_1: text1,
            text_end_2: text2
          })
        }
        setMounted && setMounted(false)
      })

      editor.on('change', () => {
        if (isInternalUpdate) {
          isInternalUpdate = false
          return
        }

        const data = editor.getValue()
        // assignUUIDsToQuestions(data.questions)

        isInternalUpdate = true
        editor.setValue(data)

        if (onDataChanged) {
          onDataChanged(data)
        }
        setValues(data)
      })
    }

    return () => {
      if (editor) {
        editor.destroy()
        editorRef.current = null
      }
    }
  }, [schema, onDataChanged, initialValues])

  useEffect(() => {
    if (initialValues) {
      setButtonText('Save Changes')
    }
  }, [setButtonText, initialValues])

  useEffect(() => {
    console.warn('Editor Ref Current', editorRef.current)
  }, [editorRef.current])

  return (
    <>
      <div
        ref={editorRef}
        style={{
          width: '100%',
          height: '100%'
        }}
      />
      <Divider sx={{
        mt: 3,
        mb: 3
      }}
      />
      <Button
        type='submit'
        disabled={isSubmitting}
        onClick={() => onSubmit(values)}
      >
        {isSubmitting ? 'Creating...' : buttonText }
      </Button>
    </>
  )
}

export default WizardEditor
