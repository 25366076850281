import { NavigationBottom } from '#app/v2/components/NavigationBottom'

export const BlankScreen = () => {
  return (
    <div className='h-[200vh] bg-[#F9F9F9]'>
      <NavigationBottom
        isIOS={false}
        pendingMessages={5}
        routes={{
          home: '/v2-ui/home',
          messages: '/v2-ui/messages',
          tasks: '/v2-ui/tasks',
          business: '/v2-ui/business',
        }}
      />
    </div>
  )
}
