import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { BsCalendar2Date } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { Box, capitalize, Card, CardActions, CardContent, Divider, Grid, Menu, MenuItem, MenuList, Stack, Tab, Tabs, Typography } from '@mui/material'
import { t } from 'i18next'
import img from 'src/assets/img/google_calendar.png'

import { Alert } from '#app/components/Alerts'
import { Btn } from '#app/components/Buttons'
import CustomCard from '#app/components/Card/Card'
import { LoadingGrid } from '#app/components/Loading/LoadingGrid'
import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import ProfileContext from '#app/contexts/ProfileContext'
import { useDayJs } from '#app/hooks/useDayJs'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { useShare, UseShareProps } from '#app/hooks/useShare'
import { EntityPage } from '#app/layouts/EntityView'
import { THEME } from '#app/layouts/theme'
import { BookingsByEmailQuery, useBookingsByEmailQuery, useEventTypesByEmailQuery, UserByEmailQuery, useUserByEmailQuery } from '#app/operations/booking/booking.queries.generated'
import { GET_PROFILE, GET_PROFILE_TYPE } from '#app/utils/graphqlQueries'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

interface Props {
  dataUser?: UserByEmailQuery | undefined
  bookingTypeFilter: string
  booking: BookingsByEmailQuery['calcom_users'][0]['Bookings'][0]
  setCalIframeURL: Dispatch<SetStateAction<string | null>>
}

function EmptyBookings() {
  return (
    <Card sx={{
      maxWidth: '400px'
    }}
    >
      <CardContent>
        {/* @ts-ignore */}
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          mb={2}
        >
          <BsCalendar2Date fontSize={THEME.ICONS.size.lg} />
        </Stack>
        <Typography variant='h5'>
          <>
            {t('booking.empty.title', 'There are no bookings in this section')}
          </>
        </Typography>
        <Typography variant='body1'>
          <>
            {t('booking.empty.subtitle', 'When you have events they will be shown on this screen.')}
          </>
        </Typography>
      </CardContent>
    </Card>
  )
}

function BookingItem({ booking, bookingTypeFilter, dataUser, setCalIframeURL }: Props) {
  const { formatTimeCal, formatDateCal } = useDayJs()
  const { getProfile: { user } } = useContext(ProfileContext)

  return (
    <Card sx={{
      minWidth: 275
    }}
    >
      <CardContent>
        <Typography
          gutterBottom
          sx={{
            fontSize: 14
          }}
          color='text.secondary'
        >
        </Typography>
        <Typography
          variant='h5'
          component='div'
        >
          {booking.title}
        </Typography>
        <Typography
          sx={{
            mb: 1.5
          }}
          color='text.secondary'
        >
          <>
            {t('booking.main.description', 'Description')}
            :
            {' '}
            {booking.description}
          </>
        </Typography>
        <Grid
          container
          spacing={6}
          direction='row'
          sx={{
            width: '100%'
          }}
        >
          <Grid
            item
            xs={12}
            lg={4}
            md={6}
          >
            <Typography variant='h5'>{formatDateCal(booking.startTime, dataUser?.calcom_users?.[0].timeZone)}</Typography>
            <Typography>
              {formatTimeCal(booking.startTime, dataUser?.calcom_users?.[0].timeZone)}
              {' '}
              -
              {' '}
              {formatTimeCal(booking.endTime, dataUser?.calcom_users?.[0].timeZone)}
              {' '}
            </Typography>
            <Typography>
              <>
                {t('booking.main.duration', 'Duration')}
                :
                {' '}
                {booking.EventType?.length}
                {' '}
                Min
              </>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            md={6}
          >
            <Typography>
              <>
                {t('booking.main.participants', 'Participants')}
                :
              </>
            </Typography>
            {
              booking.Attendees.map((attende) => {
                return (
                  <Typography>
                    {attende.name}
                    {' '}
                    {attende.name && attende.email && '-'}
                    {' '}
                    <a href={`mailto:${attende.email}`}>{attende.email}</a>
                    {' '}
                  </Typography>
                )
              })
            }
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Stack
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          spacing={2}
          sx={{
            width: '100%'
          }}
        >
          <Btn.Button
            primary
            variant='contained'
            onClick={() => setCalIframeURL(generateCalSignedUrl(`${process.env.REACT_APP_CAL_DOMAIN ?? ''}/booking/${booking.uid}?allRemainingBookings=false&`, user))}
          >
            {t('booking.main.moreInfo', 'More info')}
          </Btn.Button>
          {
            (booking.status !== 'cancelled' || bookingTypeFilter !== 'cancelled')
            && (
              <Btn.Button
                variant='outlined'
                color='warning'
                onClick={() => setCalIframeURL(generateCalSignedUrl(`${process.env.REACT_APP_CAL_DOMAIN ?? ''}/booking/${booking.uid}?cancel=true&`, user))}
              >
                {t('booking.main.cancelBooking', 'Cancel booking')}
              </Btn.Button>
            )
          }
        </Stack>
      </CardActions>
    </Card>
  )
}

export const generateEventURL: (dataEventTypes: any, event: any) => string = (dataEventTypes: any, event: any) => {
  const team = dataEventTypes?.calcom_users[0]?.Memberships[0]?.Team
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const res = `${process.env.REACT_APP_CAL_DOMAIN ?? ''}/team/${team?.slug}/${event?.slug}?embed=true`
  return res
}

export const generateCalSignedUrl: (url: string, user: any) => string = (url, user) => {
  const signedParams = `embed=true&email=${encodeURIComponent(user?.cal_email as string) || ''}&hashedPassword=${encodeURIComponent(user?.cal_hashed_password as string) || ''}`
  return `${url}+${signedParams}`
}

export function Booking() {
  const [shareData, setShareData] = useState<UseShareProps>()
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const [iframeHideLoading, setIframeHideLoading] = useState(true)
  const [calIframeURL, setCalIframeURL] = useState<string | null>(null)
  const [bookingTypeFilter, setBookingTypeFilter] = useState('upcoming')

  const googleAuthUrl = useCallback(() => {
    // blocked by cal based on cors origin ->
    // const res = await fetch(`${process.env.REACT_APP_CAL_DOMAIN ?? ''}/api/integrations/google_calendar/add`)

    // TODO
    // Temporal: after test this on production, move the above fetch to our backend
    const urlByEnv = {
      development: {
        url: 'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events&prompt=consent&state=&response_type=code&client_id=948388481453-b2jg3bqs458qbvkoca99tvpol7vjdq5c.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A4001%2Fapi%2Fintegrations%2Fgooglecalendar%2Fcallback',
        newTab: true
      },
      production: {
        url: 'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events&prompt=consent&state=&response_type=code&client_id=974204009968-46jd9napuepc3nkivfcj48ri8ns2l5s2.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fcal.joinowners.com%2Fapi%2Fintegrations%2Fgooglecalendar%2Fcallback',
        newTab: true
      },
      staging: {
        url: 'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events&prompt=consent&state=&response_type=code&client_id=51091789631-2pdn0kbku23vreu07etsc7al2sfoallu.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fcal-staging.joinowners.com%2Fapi%2Fintegrations%2Fgooglecalendar%2Fcallback',
        newTab: true
      }
    }

    const env = process.env.REACT_APP_ENV
    if (env) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const newWindow = window.open(urlByEnv[env].url, 'Connect with Google', 'height=800px,width=600px')
      // @ts-ignore
      if (newWindow && window.focus) { newWindow.focus() }
    }
  }, [])

  const { getProfile: { user } } = useContext(ProfileContext)
  const { onShare } = useShare(shareData)
  const { userEmail } = useEntityUser()
  const [
    queryGetProfile,
    {
      data: dataProfile,
      loading: loadingProfile
    }
  ] = useLazyQuery<GET_PROFILE_TYPE>(GET_PROFILE, {
    pollInterval: 3000
  })
  const { dayjs } = useDayJs()
  const navigate = useNavigate()
  const isDesktop = useIsDesktopSize()

  const [anchorEl, setAnchorEl] = useState<null | EventTarget & HTMLButtonElement>(null)
  const openAnchor = Boolean(anchorEl)

  const [anchorLinkEl, setAnchorLinkEl] = useState<null | EventTarget & HTMLButtonElement>(null)
  const openAnchorLink = Boolean(anchorLinkEl)

  const { data, loading, startPolling: startPollingBookings } = useBookingsByEmailQuery({
    variables: {
      email: userEmail ?? ''
    }
  })
  const { data: dataUser, loading: loadingUser, startPolling: startPollingUser } = useUserByEmailQuery({
    variables: {
      email: userEmail ?? ''
    }
  })
  const { data: dataEventTypes, loading: loadingEventType, startPolling: startPollingEvents } = useEventTypesByEmailQuery({
    variables: {
      email: userEmail ?? ''
    }
  })

  useEffect(() => {
    void queryGetProfile()
  }, [queryGetProfile])

  useEffect(() => {
    if (dataProfile?.getProfile.user?.sync_calendar) {
      setCalIframeURL(null)
    }
  }, [dataProfile])

  useEffect(() => {
    startPollingBookings(5000)
    startPollingUser(10000)
    startPollingEvents(5000)
  }, [startPollingBookings, startPollingEvents, startPollingUser])

  // @ts-ignore
  const bookingsByType: any[] = useMemo(() => {
    const baseBookings = data?.calcom_users[0]?.Bookings
    if (!baseBookings) return []

    switch (bookingTypeFilter) {
      case 'upcoming': return baseBookings.filter((booking) => booking.startTime && dayjs().diff(dayjs(booking.startTime as string)) < 1 && booking.status !== 'cancelled')
      case 'past': return baseBookings.filter((booking) => booking.startTime && dayjs(booking.startTime as string).diff(dayjs()) < 1 && booking.status !== 'cancelled')
      case 'cancelled': return baseBookings.filter((booking) => booking.status === 'cancelled')
      case 'all': return baseBookings
    }
  }, [bookingTypeFilter, data, dayjs])

  const eventTypes: any[] = useMemo(() => {
    const events = dataEventTypes?.calcom_users[0]?.Memberships[0]?.Team?.EventTypes
    if (events) {
      return events
    }
    return []
  }, [dataEventTypes])

  const isLoading = useMemo(() => {
    return loading || loadingUser || loadingEventType
  }, [loading, loadingUser, loadingEventType])

  const renderOptionTypeEvent = useCallback((option: string) => {
    let res = ''
    switch (option) {
      case 'upcoming':
        res = capitalize(t('booking.status.upcoming', 'upcoming'))
        break
      case 'past':
        res = capitalize(t('booking.status.past', 'past'))
        break
      case 'cancelled':
        res = capitalize(t('booking.status.cancelled', 'cancelled'))
        break
      case 'all':
        res = capitalize(t('booking.status.all', 'all'))
        break
    }
    return res
  }, [])

  return (
    <EntityPage
      isLoading={false}
      layout='free'
      pageTitle={t('booking.main.title', 'Bookings')}
      pageSubtitle={t('booking.main.subTitle', 'See upcoming and past events booked through your event type links.')}
      mainContent={(
        <>
          <iframe
            style={{
              display: 'none'
            }}
            name='owners-embed'
            allow='clipboard-write'
            // @ts-ignore
            src={`${process.env.REACT_APP_CAL_DOMAIN ?? ''}/auth/login?email=${encodeURIComponent(user?.cal_email) || ''}&hashedPassword=${encodeURIComponent(user?.cal_hashed_password) || ''}`}
            onLoad={() => setIframeHideLoading(false)}
          />
          {
            iframeHideLoading
            && <LoadingGrid />
          }
          { !iframeHideLoading
          && (
            <>
              <RightSideBar
                size='md'
                isOpen={!!calIframeURL}
                closeHandler={() => { setCalIframeURL(null); setIframeLoaded(false) }}
              >
                <>
                  <Typography variant='h4'>
                    <>{t('booking.main.title', 'Bookings')}</>
                  </Typography>
                  <Typography variant='subtitle2'>
                    {
                      calIframeURL?.includes(`${process.env.REACT_APP_CAL_DOMAIN ?? ''}/apps?embed=true`)
                        ? <>{t('booking.main.drawer.googleCalendar', 'Scroll to the bottom and install Google Calendar')}</>
                        : <>{t('booking.main.drawer.text', 'You can edit reschedule or cancel this event ')}</>
                    }
                  </Typography>
                  {
                    calIframeURL
                    && (
                      <Box
                        sx={{
                          width: '100%',
                          height: '85vh'
                        }}
                        mt={2}
                      >
                        {
                          !iframeLoaded
                          && <LoadingGrid />
                        }
                        <iframe
                          height='100%'
                          width='100%'
                          src={calIframeURL}
                          onLoad={() => setIframeLoaded(true)}
                        />
                      </Box>
                    )
                  }
                </>
              </RightSideBar>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <CustomCard>
                    <CustomCard.Body className='v1-card-bottom-padding'>
                      <Stack
                        direction='row'
                        justifyContent='flex-end'
                      >
                        <Box mb={2}>
                          <Btn.IconButton.Link onClick={(event) => setAnchorLinkEl(event.currentTarget)} />
                          <Menu
                            id='basic-menu'
                            anchorEl={anchorLinkEl}
                            open={openAnchorLink}
                            onClose={() => setAnchorLinkEl(null)}
                          >
                            {
                              // @ts-ignore
                              !isLoading && eventTypes.map((event) => {
                                return (
                                  <MenuList>
                                    <MenuItem onClick={() => {
                                      setAnchorLinkEl(null)
                                      setShareData({
                                        url: generateEventURL(dataEventTypes, event),
                                        successText: t('booking.eventType.copiedSuccess', 'Event type copied successfully')
                                      })
                                      void onShare()
                                    }}
                                    >
                                      {t('booking.eventType.copyLinkTo', 'Copy link to')}
                                      {' '}
                                      &quot;
                                      {event?.title}
                                      &quot;
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                      setAnchorLinkEl(null)
                                      setCalIframeURL(generateEventURL(dataEventTypes, event))
                                    }}
                                    >
                                      {t('booking.eventType.preview', 'Preview')}
                                      {' '}
                                      &quot;
                                      {event?.title}
                                      &quot;
                                    </MenuItem>
                                    {eventTypes && eventTypes.length > 1 && <Divider />}
                                  </MenuList>
                                )
                              })
                            }
                          </Menu>
                          <Btn.IconButton.More onClick={(event) => setAnchorEl(event.currentTarget)} />
                          <Menu
                            id='basic-menu'
                            anchorEl={anchorEl}
                            open={openAnchor}
                            onClose={() => setAnchorEl(null)}
                          >
                            <MenuItem onClick={() => navigate('/bookings/types')}><>{t('booking.eventTypes.title', 'Bookings Types')}</></MenuItem>
                            <MenuItem onClick={() => navigate('/bookings/availability')}><>{t('booking.availability.title', 'Booking Availability')}</></MenuItem>
                          </Menu>
                        </Box>
                      </Stack>
                      <Tabs
                        allowScrollButtonsMobile
                        sx={{
                          ml: isDesktop ? '0px' : '-15px'
                        }}
                        value={bookingTypeFilter}
                        variant='scrollable'
                        scrollButtons='auto'
                      >
                        {['upcoming', 'past', 'cancelled', 'all'].map((option) => (
                          <Tab
                            value={option}
                            label={renderOptionTypeEvent(option)}
                            onClick={() => setBookingTypeFilter(option)}
                          />
                        )
                        )}
                      </Tabs>
                    </CustomCard.Body>
                  </CustomCard>
                  <Stack
                    spacing={4}
                    mt={4}
                  >
                    {
                      !dataProfile?.getProfile.user?.sync_calendar
                      && (
                        <Card sx={{
                          minWidth: 275
                        }}
                        >
                          <Alert.Basic
                            severity='warning'
                            title={t('booking.calendarNotSync.title', 'Your calendar is not connected yet')}
                            action={(
                              <Btn.Button
                                variant='outlined'
                                onClick={() => {
                                  googleAuthUrl()
                                  // setCalIframeURL(generateCalSignedUrl(`${process.env.REACT_APP_CAL_DOMAIN ?? ''}/apps?embed=true&`, user))
                                }}
                              >
                                <div className='flex items-center'>
                                  <img
                                    height={25}
                                    width={25}
                                    src={img}
                                    alt='Google calendar icon'
                                    style={{
                                      marginRight: '10px'
                                    }}
                                  />
                                  {t('booking.calendar.connect', 'Connect Google Calendar') as string}
                                </div>
                              </Btn.Button>
                            )}
                            description={t('booking.calendarNotSync.description', 'Connect your Google Calendar and start organizing your bookings')}
                          />
                        </Card>
                      )
                    }
                    {
                      (isLoading || loadingProfile) && <LoadingGrid />
                    }
                    {
                      !isLoading && bookingsByType.map((booking) => {
                        return (
                          <BookingItem
                            setCalIframeURL={setCalIframeURL}
                            booking={booking}
                            dataUser={dataUser}
                            bookingTypeFilter={bookingTypeFilter}
                          />
                        )
                      })
                    }
                    {
                      !isLoading && bookingsByType && bookingsByType.length === 0
                      && <EmptyBookings />
                    }
                  </Stack>

                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    />
  )
}
