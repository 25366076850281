import { Component } from 'react'
import * as Sentry from '@sentry/react'

import AppContext from '../contexts/AppContext'

class OwnersErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    return error
  }

  // TODO:
  componentDidCatch(error, errorInfo) {
    const [, setAppState] = this.context
    setAppState((previousState) => ({
      ...previousState,
      isLoading: false,
      error: error.toString()
    }))
  }

  render() {
    return (
      <Sentry.ErrorBoundary showDialog>
        {this.props.children}
      </Sentry.ErrorBoundary>
    )
  }
}

OwnersErrorBoundary.contextType = AppContext

export default OwnersErrorBoundary
