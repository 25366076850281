import { motion } from 'framer-motion'

import { Activities } from '#app/operations/autogenerate/schemas'

import { NotificationCard } from '../../ActivityCenter/components/NotificationCard'

import { newActivityHomeCardVariant, previousActivityHomeCardVariant } from './animations'
import { FinishedNotifications } from './FinishedNotifications'

interface Props {
  totalActivities?: number
  activity?: Partial<Activities>
  handleHomeCard?: () => void
}

export const NewActivityCard = ({ totalActivities, activity, handleHomeCard }: Props) => {
  if (totalActivities && activity && totalActivities > 0) {
    return (
      <motion.div
        key={activity?.id}
        className='absolute inset-x-0'
        variants={newActivityHomeCardVariant}
        initial='initial'
        animate='animate'
        exit='exit'
      >
        <NotificationCard
          key={activity?.id}
          homePage
          data={activity?.data}
          {...activity}
          handleHomeCard={handleHomeCard}
        />
      </motion.div>
    )
  }
  return null
}

export const PreviousActivityCard = ({ totalActivities, activity }: Props) => {
  if (totalActivities && activity && totalActivities >= 1) {
    return (
      <motion.div
        key={activity?.id}
        className='absolute inset-x-0 rounded-xl'
        variants={previousActivityHomeCardVariant}
        initial='initial'
        animate='animate'
        exit='exit'
      >
        <NotificationCard
          key={activity?.id}
          homePage
          prevCard
          data={activity?.data}
          {...activity}
        />
      </motion.div>
    )
  }

  return (
    <motion.div
      key='done-activities-wrapper'
      variants={newActivityHomeCardVariant}
      animate={totalActivities === 0 ? 'animate' : 'initial'}
      initial='initial'
      className='absolute inset-x-0 rounded-xl '
    >
      <FinishedNotifications />
    </motion.div>
  )
}
