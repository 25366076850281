import { useEffect, useMemo } from 'react'
import { AudioRecorder, useAudioRecorder } from '@joinowners/react-audio-voice-recorder'
import clsx from 'clsx'
import {
  AttachmentPreviewList,
  ChatAutoComplete,
  FileUploadIcon,
  QuotedMessagePreview,
  UploadButton,
  useChannelStateContext,
  useComponentContext,
  useMessageInputContext
} from 'stream-chat-react'

import { MailSendEmailMessage } from '#app/v2/assets/Icons'
import { cn } from '#app/v2/libs/utils'
import { createSyntheticEvent } from '#app/v2-core/utils/events'

interface Props {
  validateParticipants: boolean
}

export const CustomMessageInput = (props: Props) => {
  const {
    handleSubmit,
    uploadNewFiles,
    text,
    fileUploads,
    message,
    numberOfUploads,
    isUploadEnabled
  } = useMessageInputContext()

  const {
    EmojiPicker,
  } = useComponentContext()

  const {
    quotedMessage
  } = useChannelStateContext()

  const isTextMessage = useMemo(() => text?.length > 0, [text])

  const { channel } = useChannelStateContext()

  const isParticipant = useMemo(() => !!channel?.state?.members[channel?._client?.userID ?? ''], [channel])

  useEffect(() => {
    const uploads = Object.values(fileUploads)
    const audio = uploads.find((upload) => upload.file.type === 'audio/webm' && upload.state === 'finished')
    if (audio) {
      handleSubmit(createSyntheticEvent(new Event('click')), {})
    }
  }, [fileUploads, handleSubmit])

  const recorderControls = useAudioRecorder({
    echoCancellation: true,
    noiseSuppression: true
  }, (err) => console.table(err))

  const displayQuotedMessage = !message && quotedMessage && !quotedMessage.parent_id

  if (props.validateParticipants && !isParticipant) return <></>

  return (
    <div
      role='presentation'
      className='str-chat__message-input'
      tabIndex={0}
    >
      <div className='str-chat__message-input-inner space-x-2'>

        {!recorderControls.isRecording
        && (
          <div className='str-chat__file-input-container'>
            <div className='str-chat__file-input-container'>
              <UploadButton
                accept='image/*,audio/*,application/pdf'
                id='upload-button'
                aria-label='File upload'
                className='str-chat__file-input'
                onFileChange={uploadNewFiles}
              />
              <label
                className='str-chat__file-input-label'
                htmlFor='upload-button'
              >
                <FileUploadIcon />
              </label>
            </div>
          </div>
        )}

        {!recorderControls.isRecording && (
          <div className='str-chat__message-textarea-container !bg-[#EFEFEF] '>
            {displayQuotedMessage && <QuotedMessagePreview quotedMessage={quotedMessage} />}
            {isUploadEnabled && !!numberOfUploads && <AttachmentPreviewList />}
            <div className='str-chat__message-textarea-with-emoji-picker'>
              <ChatAutoComplete />
              <div className='str-chat__message-textarea-emoji-picker'>
                {EmojiPicker && <EmojiPicker />}
              </div>
            </div>

          </div>
        ) }
        { isTextMessage
          ? (
            <button
              aria-label='Send'
              className={cn('str-chat__send-button rounded-full !bg-[#304985]')}
              type='button'
              onClick={handleSubmit}
            >
              <MailSendEmailMessage className={cn('!h-5 !w-5 !text-white')} />
            </button>

          )
          : (
            <div className={clsx('str-chat__send-button', {
              '!w-full': recorderControls.isRecording,
              'p-3': recorderControls.isRecording,
              'h-full': true
            })}
            >
              <AudioRecorder
                showVisualizer
                recorderControls={recorderControls}
                audioTrackConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true
                }}
                downloadFileExtension='webm'
                mediaRecorderOptions={{
                  audioBitsPerSecond: 128000
                }}
                classes={{
                  AudioRecorderClass: '!w-full'
                }}
                overrideSave={false}
                onRecordingComplete={(blob) => {
                  uploadNewFiles([
                    new File([blob], 'audio.webm', {
                      type: 'audio/webm'
                    })
                  ])
                }}
                onNotAllowedOrFound={(err) => console.table(err)}
              />
            </div>
          )}

      </div>

    </div>
  )
}
