import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import capitalize from '@mui/utils/capitalize'

import { Chip } from '#app/components/Chip'
import { Table } from '#app/components/Table'
import AppContext from '#app/contexts/AppContext'
import { useAppContext } from '#app/contexts/AppContext/useAppContext'
import { JsonTable } from '#app/feature/JsonToHtml'
import { useEntityAudiences } from '#app/hooks/useEntityAudiences'
import { EntityPage } from '#app/layouts/EntityView'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

import { CreateAudience } from './CreateAudience'

export function Audiences() {
  const { appState, setAppState } = useContext(AppContext)
  const { V1_SET_LOADING } = useAppContext()
  const [openCreateAudience, setOpenCreateAudience] = useState(false)
  const { parseCities, queryGetAudiences, queryGetAudiencesData } = useEntityAudiences()
  const { t } = useTranslation()
  const isDesktop = useIsDesktopSize()

  useEffect(() => {
    void queryGetAudiences()
  }, [queryGetAudiences])

  useEffect(() => {
    if (appState.isLoading) {
      V1_SET_LOADING(false)
    }
  }, [V1_SET_LOADING, appState.isLoading])

  const createHandler = useCallback(() => {
    setOpenCreateAudience(true)
  }, [])

  const initView = useCallback(async () => {
    await queryGetAudiences()
  }, [queryGetAudiences])

  useEffect(() => {
    void initView()
  }, [initView])

  const tableStructure = useMemo(() => {
    const structure = {
      rows: []
    }
    queryGetAudiencesData?.audiences.map((audience) => {
      const row = {
        key: audience.id,
        columns: [
          {
            align: 'left',
            component: <>
              {audience.title}
              {!isDesktop
              && (
                <Chip.Basic
                  sx={{
                    ml: 1
                  }}
                  label={audience.status}
                />
              )}
            </>
          },
          {
            align: 'left',
            component: <>
              {isDesktop && audience.metadata?.source === 'new-audience-altbo-form'
              && <Chip.Basic label={audience.status} />}
              {
                isDesktop && audience.metadata?.source !== 'new-audience-altbo-form'
                && (
                  <Chip.Basic
                    label='Deprecated'
                    color='warning'
                  />
                )
              }
            </>
          },
          {
            align: 'left',
            component: <>
              <Box sx={{
                zoom: '0.75'
              }}
              >
                {audience?.conditions
                && <JsonTable json={audience?.conditions} />}
              </Box>
            </>
          },
          {
            align: 'left',
            component: <>
              {
                audience?.metadata?.source
                && <Chip.Basic label={audience?.metadata?.source} />
              }
            </>
          }
        ]
      }
      // @ts-ignore
      structure.rows.push(row)
    })
    return structure
  }, [isDesktop, parseCities, queryGetAudiencesData?.audiences])

  return (
    <EntityPage
      pageTitle='Audiences'
      pageSubtitle='Generate rules to filter groups of Business Owners, audiences are currently only compatible with Sequences.'
      actionLabel={capitalize(t('generic.create'))}
      layout='one-column'
      isLoading={false}
      mainContent={(
        <>
          <CreateAudience
            open={openCreateAudience}
            setOpen={setOpenCreateAudience}
          />
          <Table.V2
            mobileMergeColumns
            mobileMergeExludeLastColumn
            headers={[
              {
                text: 'Name',
                align: 'left'
              },
              {
                text: 'Status',
                align: 'left'
              },
              {
                text: 'Conditions',
                align: 'left'
              },
              {
                text: 'Source',
                align: 'left'
              }
            ]}
            mobileColumns={[1, 2, 3, 4, 5]}
            structure={tableStructure}
          />
        </>
      )}
      onActionClick={createHandler}
    />
  )
}
