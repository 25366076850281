import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { CircularProgress } from '@mui/material'

import { useIsDesktopSize } from '../../utils/mediaQueries'
import ArrowRight from '../icons/ArrowRight'

import './NavigationOnboarding.css'

const NavigationOnboarding = ({ goBack, handleSubmit, page = true, ...props }) => {
  const isDesktop = useIsDesktopSize()
  const { t } = useTranslation()

  const { loadingState: [isLoading] } = useOutletContext()

  const Desktop = () => {
    return (
      <div className={page ? 'col-12 d-flex justify-content-between mt-3' : 'col-12 d-flex justify-content-end mt-3'}>
        {page && (
          <div
            className='d-flex flex-column justify-content-center'
            style={{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '140%',
              color: '#4498E5',
              textAlign: 'end',
              cursor: 'pointer',
              left: '2%'
            }}
            onClick={goBack}
          >
            {t('navigations.goBack')}
          </div>
        )}
        {
          isLoading
            ? (<CircularProgress />)
            : (
              <div
                className='v1-action-button d-flex align-items-center justify-content-center'
                style={{
                  width: '40px',
                  borderRadius: '50%'
                }}
                onClick={handleSubmit}
              >
                <ArrowRight />
              </div>
            )
        }
      </div>
    )
  }

  const Mobile = () => {
    return (
      <div className='col-12 d-flex justify-content-between mobile-navigation-safari mb-4 mt-3'>
        <div
          className='d-flex align-items-center justify-content-center'
          disabled={isLoading}
          style={{
            backgroundColor: 'var(--main-bg-color)',
            width: '100%',
            height: '40px',
            borderRadius: '5px',
            cursor: 'pointer',
            color: 'white'
          }}
          onClick={handleSubmit}
        >
          {
            isLoading
              ? (
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                >
                </span>
              )
              : t('navigations.next')
          }
        </div>
      </div>
    )
  }

  if (isDesktop) { return <Desktop /> }
  else { return <Mobile /> }
}

export default NavigationOnboarding
