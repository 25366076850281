import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsArchive } from 'react-icons/bs'
import { NumericFormat } from 'react-number-format'
import { Box, Grid, Stack, Typography } from '@mui/material'

import RightSideBar, { RightSideBarContainer } from '#app/components/RightSideBar/RightSideBar'
import { Table } from '#app/components/Table'
import { TableStructure } from '#app/components/Table/Table'
import ProfileContext from '#app/contexts/ProfileContext'
import { useDayJs } from '#app/hooks/useDayJs'
import { GetInvoicesQuery } from '#app/operations/invoices/invoices.queries.generated'
import { InvoiceState } from '#app/store/invoice'
import { UserType } from '#app/types/user'

import { CreateOrEditInvoiceForm } from '../CreateOrEditInvoiceForm'

import { EstimateChip } from './EstimateChip'
import { StatusChip } from './StatusChip'

export const InvoicesTable = ({ loading, data, asUser }: { loading: boolean, data?: GetInvoicesQuery, asUser?: UserType }) => {
  const { getProfile: { user: userFromProfile } } = useContext(ProfileContext)
  const { formatDateInFormat } = useDayJs()
  const { t } = useTranslation()

  const user = asUser ?? userFromProfile

  const [detailsOpen, setDetailsOpen] = useState<boolean>(false)
  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceState['createInvoice'] | InvoiceState['editInvoice']>()

  const rowData = data?.invoices ?? []

  const desktopRows = rowData
    ?.map((row, idx) => {
      return {
        key: String(idx),
        data: row,
        columns: [
          {
            component: <Box>{ formatDateInFormat(String(row.created_at), 'YYYY-MM-DD') }</Box>
          },
          {
            component: <Box>
              <Grid
                container
                wrap='nowrap'
              >
                <Grid
                  item
                  zeroMinWidth
                  xs
                >
                  <Typography noWrap>
                    {`${row.customer?.first_name ?? ''} ${row.customer?.last_name ?? ''}`.trim()}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          },
          {
            component: <Box>
              <NumericFormat
                thousandSeparator=','
                style={{
                  fontWeight: 'bold'
                }}
                displayType='text'
                value={(row?.total ?? 0) / 100}
                prefix='$'
              />
            </Box>
          },
          {
            component: <Box>
              { row?.metadata?.isEstimate
                ? <EstimateChip />
                : <StatusChip data={row} />}
            </Box>
          }
        ]
      }
    }) ?? []

  const mobileRows = rowData
    ?.map((row, idx) => {
      return {
        key: String(idx),
        data: row,
        columns: [{
          component: <Stack spacing={1}>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              { row?.metadata?.isEstimate
                ? <EstimateChip />
                : <StatusChip data={row} />}
              <small>{ formatDateInFormat(String(row?.created_at), 'YYYY-MM-DD') }</small>
            </Stack>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Stack
                direction='row'
                alignItems='center'
                spacing={2}
              >
                <Box sx={{
                  maxWidth: 150
                }}
                >
                  <Grid
                    container
                    wrap='nowrap'
                  >
                    <Grid
                      item
                      zeroMinWidth
                      xs
                    >
                      <Typography noWrap>
                        {`${row.customer?.first_name ?? ''} ${row.customer?.last_name ?? ''}`.trim()}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
              <NumericFormat
                thousandSeparator=','
                style={{
                  fontWeight: 'bold'
                }}
                displayType='text'
                value={(row.total ?? 0) / 100}
                prefix='$'
              />
            </Stack>
          </Stack>
        }]
      }
    }) ?? []

  const onRowClick = (data: InvoiceState['editInvoice'] | InvoiceState['createInvoice']) => {
    setInvoiceDetails(data)
    setDetailsOpen(true)
  }

  const tableStructure: TableStructure = {
    desktop: {
      headers: [{
        text: t('banking.invoices.invoicesTable.headers.type', 'Date'),
        style: {
          width: '25%'
        }
      },
      {
        text: t('banking.invoices.invoicesTable.headers.customer', 'Customer'),
        style: {
          width: '25%'
        }
      },
      {
        text: t('banking.invoices.invoicesTable.headers.total', 'Total'),
        style: {
          width: '25%'
        }
      },
      {
        text: t('banking.invoices.invoicesTable.headers.status', 'Status'),
        style: {
          width: '25%'
        }
      }],
      rows: desktopRows
    },
    mobile: {
      rows: mobileRows
    }
  }

  return (
    <>
      <Table.Custom
        isLoading={loading}
        structure={tableStructure}
        wrapInTableRow={true}
        onRowClick={onRowClick}
      />
      {
        (!rowData || rowData.length === 0) && (
          <Grid
            container
            spacing={1}
            direction='row'
            justifyContent='center'
            sx={{
              mt: 3
            }}
          >
            <Grid item>
              <Stack
                alignItems='center'
                spacing={2}
              >
                <BsArchive
                  size={50}
                  color='var(--items-admin-nav-color)'
                />
                <Typography color='var(--items-admin-nav-color)'>
                  { t('banking.invoices.invoicesTable.noInvoices', 'No invoices') }
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        )
      }
      <RightSideBar
        isOpen={detailsOpen}
        closeHandler={() => setDetailsOpen(false)}
      >
        <RightSideBarContainer>
          <CreateOrEditInvoiceForm
            asUser={user}
            invoice={invoiceDetails}
            onCallback={() => setDetailsOpen(false)}
          />
        </RightSideBarContainer>
      </RightSideBar>
    </>
  )
}
