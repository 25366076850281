/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsArrowReturnLeft, BsFillCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Box, Grid, Link, Typography } from '@mui/material'
import { Stack } from '@mui/system'
// @ts-ignore
import jsPDFInvoiceTemplate, { OutputType } from 'jspdf-invoice-template'

import { Btn } from '#app/components/Buttons'
import { InvoiceLineItems } from '#app/feature/Invoices/Invoice/InvoiceLineItems'
import { InvoiceTotal } from '#app/feature/Invoices/Invoice/InvoiceTotal'
import { THEME } from '#app/layouts/theme'
import { useCreateConnectedCheckoutSessionMutation } from '#app/operations/banking/banking.mutations.generated'
import { GetPublicInvoiceQuery, useGetPublicInvoiceQuery } from '#app/operations/invoices/invoices.queries.generated'
import { InvoiceDetails } from '#app/store/invoice'

const SuccessMessage = () => (
  <Stack
    spacing={3}
    alignItems='center'
  >
    <Stack
      direction='row'
      spacing={1}
    >
      <BsFillCheckCircleFill
        size={25}
        color='var(--green)'
      />
      <Typography variant='h4'>Success</Typography>
    </Stack>
    <Typography style={{
      textAlign: 'center'
    }}
    >
      Thank you for your payment!
      {' '}
      <br />
      Your transaction has been completed and we greatly appreciate your support.
      {' '}
      <br />
      Thank you for being our valued customer.
    </Typography>
  </Stack>
)

const CancelledMessage = () => (
  <Stack
    spacing={3}
    alignItems='center'
  >
    <Stack
      direction='row'
      spacing={1}
    >
      <BsFillXCircleFill
        size={25}
        color='var(--error-color)'
      />
      <Typography variant='h4'>Canceled</Typography>
    </Stack>
    <Typography style={{
      textAlign: 'center'
    }}
    >
      We&apos;re sorry to hear that you canceled your payment.
      <br />
      If you have any questions or need help, please don&apos;t hesitate to reach out.
      {' '}
      <br />
    </Typography>
  </Stack>
)

export const PublicInvoices = () => {
  const { t } = useTranslation()
  const { invoiceId, businessId } = useParams()
  const [searchParam] = useSearchParams()
  const navigate = useNavigate()
  const [invoice, setInvoice] = useState<InvoiceDetails | undefined>(undefined)
  const [isDownloading, setDownloading] = useState<boolean>(false)
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const { data, loading } = useGetPublicInvoiceQuery({
    variables: {
      invoiceId,
      businessId
    }
  })
  const [downloadHref, setDownloadHref] = useState<string>()

  const [createSession] = useCreateConnectedCheckoutSessionMutation()

  const generateInvoiceTemplate = (data: GetPublicInvoiceQuery) => {
    const table: Array<Array<string | number>> = data?.invoices
      ?.[0]
      ?.public_invoice
      ?.invoice
      ?.table ?? [[]]

    const invoiceTemplate: InvoiceDetails = {
      status: 'paid',
      metadata: {
        lineItems: []
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return table
      .reduce((acc, curr) => {
        acc
          ?.metadata
          ?.lineItems
          .push({
            description: String(curr[1]),
            quantity: Number(curr[2] ?? 0),
            unitCost: Number(curr[3] ?? 0) * 100,
            total: Number(curr[4] ?? 0) * 100
          })

        return acc
      }, invoiceTemplate)
  }

  const generateInvoicePdf = (data: GetPublicInvoiceQuery) => {
    const fileName = `invoice-${new Date().toISOString()}.pdf`
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const output = jsPDFInvoiceTemplate({
      outputType: OutputType.ArrayBuffer,
      fileName,
      ...data?.invoices?.[0]?.public_invoice
    })

    if (output.arrayBuffer) {
      const blob = new Blob([output.arrayBuffer], {
        type: 'application/octet-stream'
      })
      const url = URL.createObjectURL(blob)

      return url
    }
  }

  useEffect(() => {
    if (data && !loading) {
      if (!data.invoices?.[0]?.public_invoice) {
        navigate('/not-found')
      }

      if (data.invoices?.[0]?.status === 'deleted') {
        navigate('/not-found')
      }

      if (data?.invoices?.[0]?.public_invoice?.invoice?.table) {
        const invoiceTemplate = generateInvoiceTemplate(data)
        setInvoice(invoiceTemplate)
      }

      if (data?.invoices?.[0]?.download_link) {
        // new logic
        setDownloadHref(data?.invoices?.[0]?.download_link)
      }
      else {
        const url = generateInvoicePdf(data)
        setDownloadHref(url)
      }
    }
  }, [data, loading])

  const isPaidOrPending = data?.invoices?.[0]?.status === 'paid' || data?.invoices?.[0]?.status === 'pending'
  const isFailed = data?.invoices?.[0]?.status === 'failed'

  const onSubmit = async () => {
    const payload = {
      businessId,
      invoiceId
    }

    setSubmitting(true)

    await createSession({
      variables: payload
    })
      .then((result) => {
        const url = result?.data?.createConnectedCheckoutSession?.url
        if (url) {
          window.location.assign(url)
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <>
      {
        searchParam?.get('returnTo') && (
          <Box
            position='fixed'
            top={10}
            right={10}
          >
            <Box>
              <Btn.Button
                startIcon={<BsArrowReturnLeft />}
                onClick={() => navigate(searchParam?.get('returnTo') ?? '')}
              >
                {t('banking.invoices.public.returnToApp', 'Return')}
              </Btn.Button>
            </Box>
          </Box>
        )
      }
      {
        (searchParam?.get('payment_status') === 'cancelled' || isFailed) && <CancelledMessage />
      }
      {
        (searchParam?.get('payment_status') === 'success') && <SuccessMessage />
      }
      {
        !searchParam?.get('payment_status') && (
          <Grid container>
            <Grid
              item
              xs={12}
            >
              <Stack spacing={3}>
                <Typography fontWeight='bold'>
                  Invoice
                </Typography>
                <Typography>
                  Thank you for your business. Please contact us with any questions regarding this invoice
                </Typography>
                <Stack spacing={1}>
                  <Typography fontWeight='bold'>
                    Recipient
                  </Typography>
                  <Stack spacing={1}>
                    {
                      data?.invoices?.[0].public_invoice?.contact?.name
                      && (
                        <Box>
                          <Typography
                            variant='body2'
                            color={THEME.COLORS.custom.grey}
                          >
                            Name
                          </Typography>
                          <Typography>{ data?.invoices?.[0].public_invoice?.contact?.name }</Typography>
                        </Box>
                      )
                    }
                    {
                      data?.invoices?.[0].public_invoice?.contact?.email
                      && (
                        <Box>
                          <Typography
                            variant='body2'
                            color={THEME.COLORS.custom.grey}
                          >
                            Email
                          </Typography>
                          <Typography>{ data?.invoices?.[0].public_invoice?.contact?.email }</Typography>
                        </Box>
                      )
                    }
                    {
                      data?.invoices?.[0].public_invoice?.contact?.phone
                      && (
                        <Box>
                          <Typography
                            variant='body2'
                            color={THEME.COLORS.custom.grey}
                          >
                            Phone
                          </Typography>
                          <Typography>{ data?.invoices?.[0].public_invoice?.contact?.phone }</Typography>
                        </Box>
                      )
                    }
                    <Box>
                      <Typography
                        variant='body2'
                        color={THEME.COLORS.custom.grey}
                      >
                        Address
                      </Typography>
                      <Typography>{ data?.invoices?.[0].public_invoice?.contact?.address }</Typography>
                    </Box>
                  </Stack>
                </Stack>
                <InvoiceLineItems
                  invoice={invoice ?? {}}
                  onRemoveLineItem={() => {}}
                />
                <InvoiceTotal total={data?.invoices?.[0].public_invoice?.invoice?.additionalRows?.[0]?.col2} />
                <Stack spacing={3}>
                  {
                    (searchParam?.get('hidePay') && searchParam?.get('hidePay') === 'true')
                      ? ''
                      : (
                        <Btn.Button
                          disabled={isDownloading || isSubmitting || isPaidOrPending}
                          isLoading={isSubmitting}
                          onClick={onSubmit}
                        >
                          Pay
                        </Btn.Button>
                      )
                  }
                  <Box sx={{
                    textAlign: 'center'
                  }}
                  >
                    {
                      downloadHref && (
                        <Link
                          download
                          href={downloadHref}
                        >
                          Download
                        </Link>
                      )
                    }
                  </Box>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        )
      }
    </>
  )
}
