import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography } from '@mui/material'

import { Templates } from '#app/feature/Tasks/Templates/NewTaskTemplate'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

import PageContent from '../../../components/PageContent/PageContent'

export function Header() {
  const isDesktop = useIsDesktopSize()
  const params = useParams()
  const navigate = useNavigate()
  const [openNewTemplate, setOpenNewTemplate] = useState(false)

  const viewTemplates = () => {
    setOpenNewTemplate(true)
  }

  return (
    <>
      <Templates
        setOpen={setOpenNewTemplate}
        open={openNewTemplate}
      />
      <PageContent.PageHeader>
        <Typography
          variant='h4'
          style={{
            cursor: 'pointer'
          }}
          onClick={() => navigate('/owners-tasks')}
        >
          {!isDesktop && params?.userId && <>&#60;</>}
          {' '}
          Owners Inbox
        </Typography>
        <PageContent.PageAction>
          <PageContent.PageActionButton
            label='New template'
            onClick={viewTemplates}
          />
        </PageContent.PageAction>
      </PageContent.PageHeader>
    </>
  )
}
