import { useEffect, useState } from 'react'
import { useChatContext } from 'stream-chat-react'

export default function useChannel(channelId: string) {
  const { client } = useChatContext()
  const channel = client.channel('messaging', channelId)

  const [members, setMembers] = useState(Object.values(channel.state.members))

  useEffect(() => {
    if (!members.length) {
      channel.queryMembers({}).then((val) => {
        setMembers(val.members)
      }).catch(console.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId])

  const channelMember = members.find(({ user }) => user?.id !== client.userID)

  return {
    channelMember,
    channel
  }
}
