/* eslint-disable indent */
import { useMemo } from 'react'
import { Box, SxProps } from '@mui/material'

import OwnersLogoDark from '#app/assets/svg/logo-owners-dark.svg'
import OwnersLogoLight from '#app/assets/svg/logo-owners-light.svg'

const OwnersLogo = ({ isContrast, sx, size }: { isContrast?: boolean, sx?: SxProps, size?: 'xs' | 'sm' | 'md' | 'lg' }) => {
  const widthLogo = useMemo(() => {
    switch (size) {
      case 'xs': return '100px'
      case 'sm': return '150px'
      case 'md': return '250px'
      case 'lg': return '350px'
      default: return '200px'
    }
  }, [size])

  return (
    <Box sx={sx}>
      { isContrast
      && (
        <img
          src={OwnersLogoLight}
          style={{
            width: widthLogo
          }}
        />
      )}
      { !isContrast
      && (
        <img
          src={OwnersLogoDark}
          style={{
            width: widthLogo
          }}
        />
      )}
    </Box>
  )
}

export default OwnersLogo
