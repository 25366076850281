import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Typography } from '@mui/material'

import Button from '#app/components/Buttons/Button'
import AppContext from '#app/contexts/AppContext'

export function WithoutProfile() {
  const { appState } = useContext(AppContext)
  const { t } = useTranslation()

  return (
    <Stack
      mt={4}
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={2}
    >
      <Typography variant='h5'>
        {' '}
        {t('empty.profile.title', 'Oops! It seems like your profile is empty!')}
        {' '}
      </Typography>
      <Typography color='gray'>
        {t('empty.profile.description', 'Please reach out to us, so we can fix this as soon as possible👇')}
      </Typography>
      <a
        href={`http://wa.me/${appState.supportPhone}`}
        style={{
          textDecoration: 'none'
        }}
        target='_blank'
        rel='noreferrer'
      >
        <Button>{t('routes.help.button')}</Button>
      </a>
    </Stack>
  )
}
