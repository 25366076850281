import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { Grid, Typography } from '@mui/material'
import parse from 'html-react-parser'

import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import { Timeline } from '#app/components/Timeline/Timeline'
import CustomerCard from '#app/feature/Customers/CustomerCard'
import { EditJob } from '#app/forms/Jobs/EditJob'
import { useDayJs } from '#app/hooks/useDayJs'
import { useJobStatusMap } from '#app/hooks/useJobs'
import { EntityPage } from '#app/layouts/EntityView'
import { JobHistory, JobInput, JobsQueryResult } from '#app/types/job'
import { QUERY, QUERY_GET_JOBS } from '#app/utils/graphqlQueries'

interface Props {
  showEdit?: boolean
  navigateBack: (params: any) => string
  job?: JobInput
}

export const EventDate = ({ history }: { history: JobHistory }) => {
  const { formatDateInFormat } = useDayJs()

  return (
    <span>
      { formatDateInFormat(history.created_at, 'YYYY-MM-DD HH:MM:ss') }
    </span>
  )
}

export const ReasonForChange = ({ history }: { history: JobHistory }) => {
  const statusMap = useJobStatusMap()

  return (
    <>
      <Typography
        component='span'
        variant='h6'
      >
        { history.end_status ? statusMap[history.end_status] : 'Unknown' }
      </Typography>
      <Typography>{ parse(history.metadata?.reason_for_change ?? '') }</Typography>
    </>
  )
}

export const JobDetails = ({ showEdit = true, navigateBack, job: jobProp }: Props) => {
  const [editSideBar, setEditSideBar] = useState(false)
  const { state } = useLocation()
  const [job, setCurrentJob] = useState<JobsQueryResult | undefined>()
  const statusMap = useJobStatusMap()
  const { formatDateInFormat } = useDayJs()
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()

  const [query, { data, loading }] = useLazyQuery<QUERY_GET_JOBS>(QUERY.jobs.getJobs, {
    variables: {
      where: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        id: {
          _eq: params.jobId
        },
        user: {
          status: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            _neq: 'deleted'
          }
        }
      },
      limit: 1
    }
  })

  useEffect(() => {
    void query()
  }, [])

  useEffect(() => {
    const job = data?.jobs?.[0]
    setCurrentJob(job)
  }, [data])

  const jobHistories = job
    ?.job_histories
    ?.map((history: JobHistory, idx: number) => {
      return {
        oppositeContent: <EventDate history={history} />,
        content: <ReasonForChange history={history} />
      }
    })

  const fullName = `${job?.customer?.first_name ?? ''} ${job?.customer?.last_name ?? ''}`

  const onActionClick = showEdit ? () => { setEditSideBar(true) } : undefined

  return (
    <EntityPage
      hasReturn={true}
      layout='one-column'
      pageTitle={t('jobs.jobDetails')}
      actionLabel='Edit Job'
      isLoading={loading}
      mainContent={(
        <>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
          >
            <Grid item>
              <Typography variant='h6'>
                <span>{ `${fullName}` }</span>
              </Typography>
            </Grid>
          </Grid>
          <CustomerCard customer={job?.customer} />
          <Grid
            container
            className='mt-5'
          >
            <Grid
              item
              xs={12}
            >
              { parse(job?.description ?? '') }
            </Grid>
          </Grid>
          <Grid
            container
            className='mt-3'
          >
            <Grid
              item
              xs={12}
            >
              <Timeline
                leftAligned={true}
                items={jobHistories}
              />
            </Grid>
          </Grid>
          <RightSideBar
            isOpen={editSideBar}
            closeHandler={() => setEditSideBar(false)}
          >
            <>
              {editSideBar && job && (
                <EditJob
                  submitCallback={() => {
                    setEditSideBar(false)
                  }}
                  currentJob={job}
                />
              )}
            </>
          </RightSideBar>
        </>
      )}
      onActionClick={onActionClick}
    />
  )
}
