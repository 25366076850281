export interface ClientsNotifications {
  // newClient?: boolean // You have a new customer, congrats!
  customerCallCenter?: string // We had a call with Customer [Customer Name]
}

export interface LeadsNotifications {
  newLead?: 'website' | 'call-center'
}

/*
export interface AppointmentsNotifications {
    newAppointment?: boolean // A new appointment booking has been made.
}
*/

export interface JobsNotifications {
  newJobBooking?: { // You have a new job schedule for {Friday 13 October} at {9 am} in Address {1234, City Country} for {John Doe}.
    date?: string
    time?: string
    address?: string
    client?: string
  }
}

export interface ReviewsNotifications {
  // newReview?: boolean // You have a new review in Google My Business.
  requestIt?: string // Request a Review from job [Job ID]!
}

export interface EstimateNotifications {
  // newEstimate?: boolean // A new estimate has breen created by Owners
  approvedBy?: string // [Client Name] has approved your Estimate
}

export interface InvoiceNotifications {
  approvedBy?: string // [Client Name] has approved your Invoice
}

export interface MoneyNotifications {
  sentBy?: string // [Client Name] has sent a payment
}

export interface TasksNotifications {
  newTask?: string // New task assign to you “{Find leads}”.
  completed?: string // Your “{Find leads}” Task was marked as Complete!
  // previewFiles?: string[] // Jane uploaded 3 {files} in the task “Approve logo” || Obviarlo, newMessage
  newMessage?: string // You have a new message in [Task Name]
}

export type NotificationCategory =
  | 'clients'
  | 'leads'
  | 'appointments'
  | 'jobs'
  | 'reviews'
  | 'estimates'
  | 'invoices'
  | 'money'
  | 'tasks'
  | 'chat'
  | 'callCenter'
  | 'onboarding'

export type NotificationType =
  | 'taskAssigned'
  | 'taskCompleted'
  | 'taskCommented'
  | 'unreadMessage'
  | 'onboardingCompleted'
  | 'interestedCallback'
  | 'interestedUnavailable'
  | 'potentialLeadLost'
  | 'interestedBrowsing'
  | 'spamNumber'
  | 'appointmentBooked'
  | 'appointmentFollowUp'
  | 'lookingForJob'
  | 'voicemailLeft'
  | 'rescheduling'
  | 'reschedulingNoAnswer'
// | 'newLead'
// | 'newClient'
// | 'newAppointment'
// | 'newJobBooking'
// | 'newReview'
// | 'newEstimate'
// | 'invoiceApproved'
// | 'invoiceSent'

export type Filters = NotificationCategory | 'all'

export const filters = [
  'all',
  'clients',
  'leads',
  'appointments',
  'jobs',
  'reviews',
  'estimates',
  'invoices',
  'money',
  'tasks',
  'chat',
  'onboarding',
  'callCenter'
] as Filters[]
