import { gql } from '@apollo/client'

import { JobsListFragmentDoc } from '../jobs/jobs.fragments.generated'
export type CustomerHistoriesListFragment = { __typename?: 'customer_histories', created_at: any, status?: string | undefined, metadata?: any | undefined }

export type CustomersListFragment = { __typename?: 'customers', id: any, first_name?: string | undefined, last_name?: string | undefined, email?: string | undefined, phone?: string | undefined, address?: any | undefined, lead?: string | undefined, user_id?: any | undefined, business_id?: any | undefined, search?: string | undefined, customer_histories: Array<{ __typename?: 'customer_histories', created_at: any, status?: string | undefined, metadata?: any | undefined }>, jobs: Array<{ __typename?: 'jobs', business_id?: any | undefined, created_at: any, customer_id?: any | undefined, description?: string | undefined, id: any, source?: string | undefined, status?: string | undefined, user_id?: any | undefined, job_histories: Array<{ __typename?: 'job_histories', created_at: any, end_status?: string | undefined, start_status?: string | undefined, metadata?: any | undefined }> }> }

export const CustomerHistoriesListFragmentDoc = gql`
    fragment customerHistoriesList on customer_histories {
  created_at
  status
  metadata
}
    `
export const CustomersListFragmentDoc = gql`
    fragment customersList on customers {
  id
  first_name
  last_name
  email
  phone
  address
  lead
  user_id
  business_id
  search
  customer_histories(limit: 20, order_by: {created_at: desc}) {
    ...customerHistoriesList
  }
  jobs(order_by: {created_at: desc}, limit: 10) {
    ...jobsList
  }
}
    ${CustomerHistoriesListFragmentDoc}
${JobsListFragmentDoc}`
