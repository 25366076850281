import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Stack, Typography } from '@mui/material'

import Button from '#app/components/Buttons/Button'
import AppContext from '#app/contexts/AppContext'
import { useEntityUser } from '#app/hooks/useEntityUser'
declare const window: any

export function WithoutTasks() {
  const { isAdmin } = useEntityUser()
  const { t } = useTranslation()
  const { appState } = useContext(AppContext)

  return (
    <Stack
      mt={4}
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={2}
    >
      <Typography variant='h5'>
        {' '}
        {t('empty.tasks.title', 'It seems like your inbox is empty!')}
        {' '}
      </Typography>
      {isAdmin
      && (
        <Typography color='gray'>
          There are no tasks with the last comment from the business owner, for more information visit the
          {' '}
          <Link to='/owners-tasks'>Owner Inbox</Link>
          {' '}
          to see the full timeline
        </Typography>
      )}
      {!isAdmin
      && (
        <Typography color='gray'>
          {t('empty.tasks.description', 'Our team will be sending you a couple of messages shortly! Feel free to book a call with us 👇')}
        </Typography>
      )}
      {!isAdmin
      && <Button onClick={() => window.open(`http://wa.me/${appState.supportPhone}`, '_blank')}>{t('routes.help.button')}</Button>}
    </Stack>
  )
}
