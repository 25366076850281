/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { FormControlLabel, FormGroup, Grid, Switch, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { capitalize } from 'lodash'

import { DialogDelete } from '#app/components/Dialog/DialogDelete'
import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import ProfileContext from '#app/contexts/ProfileContext'
import CreateCustomer from '#app/forms/CustomersManagement/CreateCustomer'
import useMutationWithNotification from '#app/hooks/useMutationWithNotification'
import { useShare } from '#app/hooks/useShare'
import { EntityPage } from '#app/layouts/EntityView'
import { GetCustomersDocument, useGetCustomersLastSevenDaysQuery, useGetCustomersQuery } from '#app/operations/customers/customers.queries.generated'
import { useStoreUser } from '#app/store/user'
import { CustomerInput } from '#app/types/customer'
import { MUTATION } from '#app/utils/graphqlQueries'
import { getOnlyDefined } from '#app/utils/vanilla/helpers'

import Table from '../../../components/Table/Table'
import { useIsDesktopSize } from '../../../utils/mediaQueries'

import { CustomerContent } from './CustomerDetails'
import { EmptyPlaceholder } from './CustomerList'

interface CustomerRowProps {
  currentCustomer: CustomerInput | undefined
  customer: CustomerInput
  onClick: () => void
  onDelete: () => void
}

export const CustomerRow = ({ currentCustomer, onClick, customer, onDelete }: CustomerRowProps) => {
  const { t } = useTranslation()
  const isDesktop = useIsDesktopSize()
  const fullName = `${customer.first_name ?? ''} ${customer.last_name ?? ''}`.trim() ?? capitalize(t('generic.unknown'))

  return (
    <Table.RowMobile
      className={`${(currentCustomer?.id === customer.id && isDesktop) ? 'v1-owner-row-selected' : ''} card-body pt-0`}
      role='button'
      onClick={onClick}
    >
      <Grid
        container
        wrap='nowrap'
      >
        <Grid
          item
          zeroMinWidth
          xs
        >
          <Typography noWrap>
            {fullName}
          </Typography>
          <Typography
            noWrap
            style={{
              fontSize: '0.70rem'
            }}
          >
            {customer.email ?? customer.phone }
          </Typography>
        </Grid>
      </Grid>
    </Table.RowMobile>
  )
}

export const CustomersManagement = () => {
  const isDesktop = useIsDesktopSize()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('action')
      && searchParams.get('action') === 'newClient'
      && !sideBarStatus) {
      setSideBarStatus(true)
      navigate('/customers')
    }
  }, [searchParams, navigate])

  const { getProfile: { user } } = useContext(ProfileContext)
  const [sideBarStatus, setSideBarStatus] = useState(false)
  const [currentCustomer, setCurrentCustomer] = useState<CustomerInput | undefined>()
  const [open, setOpen] = useState<boolean>(false)
  const { customerId } = useParams()
  const now = new Date()
  const sevenDaysAgo = new Date(now.getTime() - (7 * 24 * 60 * 60 * 1000))
  sevenDaysAgo.setHours(0, 0, 0, 0)
  const [isDeleting, setDeleting] = useState<boolean>(false)
  const { isLastInteractions, setLastInteractions } = useStoreUser()
  const [lastInteractionCustomers, setLastInteractionsCustomer] = useState<boolean>(isLastInteractions)
  const { t } = useTranslation()
  const information = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: '',
    lead: ''
  }
  const { data, loading, refetch, startPolling, stopPolling } = useGetCustomersQuery({
    variables: {
      query: '%',
      limit: 50
    }
  })

  const { data: lastData, loading: lastLoading, refetch: lastRefetch, startPolling: lastPolling, stopPolling: lastStopPolling } = useGetCustomersLastSevenDaysQuery({
    variables: {
      query: '%',
      limit: 50,
      SEVEN_DAYS_AGO: sevenDaysAgo
    }
  })

  const { onShare } = useShare({
    title: 'Customer Link',
    text: 'Please fill in your details and we will contact you soon',
    url: `${window.location.protocol}//${window.location.host}/public/customer/${user?.id ?? ''}`,
    successText: t('customers.copiedToClipboard', 'Your customer URL was copied to your clipboard')
  })

  const [removeCustomer] = useMutationWithNotification(MUTATION.customers.deleteCustomer, {
    update(cache, { data }) {
      // @ts-ignore
      const { customers } = cache.readQuery({
        query: GetCustomersDocument,
        variables: {
          query: '%',
          limit: 50
        }
      })

      const { deleteCustomer: { customer: deletedCustomer } } = data

      cache.writeQuery({
        query: GetCustomersDocument,
        variables: {
          query: '%',
          limit: 50
        },
        data: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          customers: customers.filter((c: CustomerInput) => c.id !== deletedCustomer.id)
        }
      })
    }
  })

  const createHandler = () => {
    setSideBarStatus(true)
  }

  const submitCallback = (customer: CustomerInput) => {
    setCurrentCustomer(customer)
    setSideBarStatus(false)
  }

  const searchUser = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = '%' + e.target.value + '%'
    await refetch({
      query,
      limit: 50
    })
    lastStopPolling()
    startPolling(5000)
  }

  const searchLastUser = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = '%' + e.target.value + '%'
    await lastRefetch({
      query,
      limit: 50,
      SEVEN_DAYS_AGO: sevenDaysAgo
    })
    stopPolling()
    lastPolling(5000)
  }

  async function getCustomers() {
    if (lastInteractionCustomers) {
      await lastRefetch({
        query: '%',
        limit: 50,
        SEVEN_DAYS_AGO: sevenDaysAgo
      })
      stopPolling()
      lastPolling(5000)
    }
    else {
      await refetch({
        query: '%',
        limit: 50
      })
      lastStopPolling()
      startPolling(5000)
    }
  }

  useEffect(() => {
    setLastInteractions(lastInteractionCustomers)
    void getCustomers()
  }, [lastInteractionCustomers])

  const filtersOptions = useMemo(() => (
    <Box p={0.5}>
      <FormGroup sx={{
        p: 1
      }}
      >
        <FormControlLabel
          control={(
            <Switch
              size='small'
              checked={lastInteractionCustomers}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLastInteractionsCustomer(event.target.checked)}
            />
          )}
          label={t('customers.lastInteraction')}
        />
      </FormGroup>
    </Box>
  ), [lastInteractionCustomers])

  useEffect(() => {
    if (!lastInteractionCustomers) {
      if (!loading && data) {
        if (!currentCustomer) {
          const currentlySelected = data
            .customers
            .find(({ id }) => id === customerId)

          const [firstCustomer] = data.customers

          if (currentlySelected) {
            setCurrentCustomer(currentlySelected)
          }
          else if (isDesktop) {
            setCurrentCustomer(firstCustomer)
          }
        }
        else {
          const updatedCustomer = data
            .customers
            .find((c, i) => c.id === currentCustomer?.id)

          if (updatedCustomer) {
            setCurrentCustomer(updatedCustomer)
          }
        }
      }
    }
    else {
      if (!lastLoading && lastData) {
        if (!currentCustomer) {
          const currentlySelected = lastData
            .customers
            .find(({ id }) => id === customerId)

          const [firstCustomer] = lastData.customers

          if (currentlySelected) {
            setCurrentCustomer(currentlySelected)
          }
          else if (isDesktop) {
            setCurrentCustomer(firstCustomer)
          }
        }
        else {
          const updatedCustomer = lastData
            .customers
            .find((c, i) => i === 0)

          if (updatedCustomer) {
            setCurrentCustomer(updatedCustomer)
          }
        }
      }
    }
  }, [data, lastData, lastInteractionCustomers])

  const [addCustomer] = useMutationWithNotification(MUTATION.customers.createCustomer, {
    update(cache, { data }) {
      // @ts-ignore
      const { customers } = cache.readQuery({
        query: GetCustomersDocument,
        variables: {
          query: '%',
          limit: 50
        }
      })

      const { createCustomer: { customer: newCustomer } } = data

      cache.writeQuery({
        query: GetCustomersDocument,
        variables: {
          query: '%',
          limit: 50
        },
        data: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          customers: [{
            ...newCustomer,
            __typename: 'customers'
          }].concat(customers)
        }
      })
    }
  })

  const onSubmit = async (data: CustomerInput, { resetForm }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const payload = getOnlyDefined({
      user_id: user?.id,
      business_id: user?.businesses?.[0]?.id,
      ...data
    })

    try {
      const result: any = await addCustomer({
        variables: payload
      })

      const { data: { createCustomer: { customer } } } = result

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      submitCallback(customer)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      resetForm()
    }
    catch (err) {
      console.error(err)
    }
  }

  const tableStructure = useMemo(() => {
    if (data && !lastInteractionCustomers) {
      const rows = data
        .customers
        .map((customer) => ({
          key: customer.id,
          columns: [
            {
              component: (
                <CustomerRow
                  customer={customer}
                  currentCustomer={currentCustomer}
                  onDelete={() => {
                    setCurrentCustomer(customer)
                    setOpen(true)
                  }}
                  onClick={() => {
                    if (!isDesktop) {
                      setLastInteractions(false)
                      navigate(`/customers/${customer?.id}`)
                    }
                    else {
                      setCurrentCustomer({
                        ...customer
                      })
                    }
                  }}
                />
              )
            }
          ]
        }))

      return {
        mobile: {
          rows
        }
      }
    }
    else if (lastData && lastInteractionCustomers) {
      const rows = lastData
        .customers
        .map((customer) => ({
          key: customer.id,
          columns: [
            {
              component: (
                <CustomerRow
                  customer={customer}
                  currentCustomer={currentCustomer}
                  onDelete={() => {
                    setCurrentCustomer(customer)
                    setOpen(true)
                  }}
                  onClick={() => {
                    if (!isDesktop) {
                      setLastInteractions(true)
                      navigate(`/customers/${customer?.id}`)
                    }
                    else {
                      setCurrentCustomer({
                        ...customer
                      })
                    }
                  }}
                />
              )
            }
          ]
        }))

      return {
        mobile: {
          rows
        }
      }
    }

    return {
      mobile: {
        rows: []
      }
    }
  }, [currentCustomer, data, lastData, lastInteractionCustomers])

  return (
    <>
      <EntityPage
        layout='two-columns'
        isLoading={loading}
        pageTitle={t('customers.title')}
        secondaryActionIcon='link'
        actionLabel={capitalize(t('generic.create'))}
        listContent={(
          <>
            {
              tableStructure.mobile.rows.length
                ? (
                  <>
                    {filtersOptions}
                    <Table
                      displayMobile={true}
                      structure={tableStructure}
                    />
                  </>
                )
                : (
                  <>
                    {filtersOptions}
                    <EmptyPlaceholder />
                  </>
                )
            }
          </>
        )}
        mainContentWithoutCard={(
          <>
            { currentCustomer ? <CustomerContent currentCustomer={currentCustomer} /> : null }
          </>
        )}
        onActionClick={createHandler}
        onSecondaryActionClick={onShare}
        onFilterChange={lastInteractionCustomers ? searchLastUser : searchUser}
      />
      <DialogDelete
        open={open}
        setOpen={setOpen}
        texts={{
          description: t('general.deleteDescription', {
            identifier: currentCustomer?.email ?? currentCustomer?.phone ?? ''
          }),
          confirm: capitalize(t('generic.confirm')),
          cancel: capitalize(t('generic.cancel'))
        }}
        isLoading={isDeleting}
        onConfirm={async () => {
          setDeleting(true)
          try {
            await removeCustomer({
              variables: {
                id: currentCustomer?.id
              }
            })
          }
          catch (err) {
            setDeleting(false)
            return
          }
          setCurrentCustomer(undefined)
          setOpen(false)
          setDeleting(false)
        }}
      />
      <RightSideBar
        isOpen={sideBarStatus}
        closeHandler={() => setSideBarStatus(false)}
      >
        <>
          {sideBarStatus && (
            <CreateCustomer
              information={information}
              onSubmit={onSubmit}
            />
          )}
        </>
      </RightSideBar>
    </>
  )
}
