import { useMemo, useState } from 'react'
import { Box, Divider, Grid, Grow, Paper, Stack, styled, Typography } from '@mui/material'

import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import { useDayJs } from '#app/hooks/useDayJs'
import { Task, User } from '#app/types'
import { tags } from '#app/utils/vanilla/data'

const Item = styled(Paper) <{ isDeleted: boolean }>`
background-color: ${(props) => props.isDeleted ? '#ffe5e5' : '#fff'};
padding: 1rem;
cursor: pointer;
`
interface TemplateCardProps {
  template: Task.Template
  initCopyTemplate: (template: Task.Template) => void
  initPreviewMode: (template: Task.Template) => void
  initTaskPreview: (template: Task.Template) => void
  cbDeleteTemplate: (template: Task.Template) => void
  currentOwner: User.UserType
}

const notAvailableToSendMsg = 'This template cannot be sent because it is not in the preferred language of the business owner.'
export function TemplateCard({ template, initCopyTemplate, initPreviewMode, initTaskPreview, cbDeleteTemplate, currentOwner }: TemplateCardProps) {
  const [hover, setHover] = useState(false)
  const { formatDateV2 } = useDayJs()

  const availableToSend = useMemo(() => {
    if (currentOwner.language) {
      if (template.content?.[currentOwner.language]) {
        return true
      }
    }
    return false
  }, [currentOwner, template])

  const isDeleted = useMemo(() => template.status === 'deleted', [template])

  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={6}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Item isDeleted={template.status === 'deleted'}>
        <Box mb={2}>
          <Typography variant='subtitle1'>
            {' '}
            {template.title}
          </Typography>
          <Typography
            variant='body1'
            color='grey'
          >
            {' '}
            {formatDateV2(template.created_at)}
          </Typography>
        </Box>

        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={1}
          mt={2}
          mb={2}
        >
          {
            template.userByCreatedBy
            && (
              <Chip.User
                user={template.userByCreatedBy}
                size='small'
              />
            )
          }
        </Stack>

        <Divider />

        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={1}
          mt={2}
          sx={{
            height: '2rem'
          }}
        >
          {
            !hover && template.content.es
            && <Chip.Language lang='es' />
          }
          {
            !hover && template.content.en
            && <Chip.Language lang='en' />
          }
          {
            !hover && template.tag_slug
            && (
              <Chip.Basic
                sx={{
                  m: 0.4
                }}
                label={tags.find((e) => e.value === template.tag_slug)?.text ?? ''}
                color='primary'
              />
            )
          }
          {hover
          && (
            <>
              <Grow
                timeout={500}
                in={true}
              >
                <Stack
                  direction='row'
                  spacing={2}
                >
                  {
                    !isDeleted
                    && (
                      <Box>
                        <Btn.IconButton.Delete
                          withoutWarning
                          onClick={() => cbDeleteTemplate(template)}
                        />
                      </Box>
                    )
                  }

                  <Box>
                    <Btn.IconButton.Info onClick={() => initPreviewMode(template)} />
                  </Box>

                  {/* {
                    !isDeleted &&
                    <Box>
                      <Btn.IconButton.Send tooltip={availableToSend ? undefined : notAvailableToSendMsg} onClick={() => {
                        if (availableToSend) {
                          initTaskPreview(template)
                        }
                      }} />
                    </Box>
                  } */}

                  {
                    !isDeleted
                    && (
                      <Box>
                        <Btn.IconButton.Send
                          tooltip={availableToSend ? undefined : notAvailableToSendMsg}
                          onClick={() => {
                            if (availableToSend) {
                              initCopyTemplate(template)
                            }
                          }}
                        />
                      </Box>
                    )
                  }
                </Stack>
              </Grow>
            </>
          )}
        </Stack>
      </Item>
    </Grid>
  )
}
