import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsCreditCard } from 'react-icons/bs'
import { useLocation } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'

import Button from '#app/components/Buttons/Button'
import Card from '#app/components/Card/Card'
import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import ProfileContext from '#app/contexts/ProfileContext'
import { BankTransfer } from '#app/feature/Banking/BankTransfer/BankTransfer'
import { CreditCard } from '#app/feature/Banking/CreditCard'
import { EntityPage } from '#app/layouts/EntityView'
import { useGetBankingDetailsQuery } from '#app/operations/banking/banking.queries.generated'
import { useBankingBottomSection } from '#app/store/banking-bottom-sections'

export const Banking = () => {
  const { t } = useTranslation()
  const { getProfile: { user } } = useContext(ProfileContext)

  const [isBankTransferOpen, setBankTransferOpen] = useState<boolean>(false)
  const [isWithdrawalOpen, setWithdrawalOpen] = useState<boolean>(false)
  const { setSelectedSection } = useBankingBottomSection((state) => state)
  const location = useLocation()

  const { data: bankingData, startPolling: startPollingBanking } = useGetBankingDetailsQuery({
    variables: {
      userId: user?.id ?? ''
    }
  })

  useEffect(() => {
    startPollingBanking(10000)
    setSelectedSection({
      url: location.pathname
    })
  }, [])

  return (
    <EntityPage
      isLoading={false}
      layout='free'
      pageTitle={t('banking.banking.title', 'Banking')}
      pageSubtitle={t('banking.banking.pageSubtitle', 'Issue a debit card to pay for your expenses or transfer money out of your Owners account')}
      mainContent={(
        <>
          <Grid
            container
            spacing={3}
            alignItems='stretch'
          >
            <Grid
              item
              xs={12}
              lg={6}
            >
              <CreditCard />
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
            >
              <Card className='v1-card-bottom-padding h-100'>
                <Card.Body>
                  <Stack spacing={3}>
                    <Typography variant='h6'>{t('banking.banking.transferTitle', 'Transfers')}</Typography>
                    <Typography>{t('banking.banking.transferDescription', 'Widthraw your money directly to your bank account or transfer money to pay contractors or providers')}</Typography>
                    <Stack spacing={1}>
                      <Button
                        disabled={false}
                        startIcon={<BsCreditCard />}
                        onClick={() => setWithdrawalOpen(true)}
                      >
                        { t('balanceDetails.withdrawal', 'Withdrawal') }
                      </Button>
                      <Button
                        disabled={false}
                        startIcon={<BsCreditCard />}
                        onClick={() => setBankTransferOpen(true)}
                      >
                        { t('balanceDetails.bankTransfer', 'Bank Transfer') }
                      </Button>
                    </Stack>
                  </Stack>
                </Card.Body>
              </Card>

            </Grid>
          </Grid>
          <RightSideBar
            isOpen={isWithdrawalOpen}
            closeHandler={() => setWithdrawalOpen(false)}
          >
            <BankTransfer
              data={bankingData}
              bankTransferType='outbound-transfers'
              onSubmitCallback={() => setWithdrawalOpen(false)}
            />
          </RightSideBar>
          <RightSideBar
            isOpen={isBankTransferOpen}
            closeHandler={() => setBankTransferOpen(false)}
          >
            <BankTransfer
              data={bankingData}
              bankTransferType='outbound-payments'
              onSubmitCallback={() => setBankTransferOpen(false)}
            />
          </RightSideBar>
        </>
      )}
    />
  )
}
