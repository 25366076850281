import { useEffect, useState } from 'react'
import { BsCheck2Circle } from 'react-icons/bs'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import { Channel as ChannelSDK } from 'stream-chat'
import { Channel, ChannelHeader, MessageInput, MessageList, StreamMessage, useChatContext } from 'stream-chat-react'
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types'
import { EmojiPicker } from 'stream-chat-react/emojis'

import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import { useEntityUserTasks } from '#app/hooks/useEntityUserTasks'
import { MUTATION } from '#app/utils/graphqlQueries'
import { tags } from '#app/utils/vanilla/data'
import { CustomMessageInput } from '#app/v2-core/pages/ChannelMessages/components/CustomMessageInput'

import AttachTaskModal from './AttachTaskModal'

function NoAvatar() {
  return <></>
}

interface Props {
  userId?: string
  channelId?: string
}

function Input() {
  return <CustomMessageInput validateParticipants />
}

export function ChatChannel(props: Readonly<Props>) {
  const urlUserId = useParams().userId
  const userId = urlUserId ?? props.userId ?? ''

  const urlChannelId = useParams().channelId
  const channelId = props?.channelId || urlChannelId || ''

  const [boId, setBoId] = useState<string>()
  const [currentMessage, setCurrentMessage] = useState<StreamMessage>()

  const [openModal, setOpenModal] = useState(false)
  const { queryGetUserTasks, queryGetUserTasksUtils } = useEntityUserTasks()

  useEffect(() => {
    if (openModal && boId) {
      void queryGetUserTasks({
        variables: {
          userID: boId,
          filter: ['processing', 'draft', 'completed', 'admin_completed']
        }
      })
    }
  }, [openModal, queryGetUserTasks, boId])

  const [channel, setChannel] = useState<ChannelSDK<DefaultStreamChatGenerics>>()

  const { client } = useChatContext()
  useEffect(() => {
    const channel = channelId
      ? client.channel('messaging', channelId)
      : client.channel('messaging', {
        members: [userId, client.userID ?? '']
      })

    channel.create().then(() => {
      setChannel(channel)

      const boId = Object.keys(channel?.state?.members ?? {}).find((id) => id !== client.userID)
      setBoId(boId)
    }).catch(console.error)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, channelId])

  const [createComment] = useMutation(MUTATION.comments.createComment)
  const { enqueueSnackbar } = useSnackbar()

  const attachMessage = async (taskId: string) => {
    if (!currentMessage) return

    await client.partialUpdateMessage(currentMessage.id, {
      set: {
        custom_message_data: {
          taskId
        }
      }
    })

    await createComment({
      variables: {
        text: currentMessage.text,
        receiver_user_id: boId,
        thread_entity_id: taskId,
        thread_entity_type: 'user_tasks',
        private_note: false,
        attachments: [],
        metadata: {
          chat_message_id: currentMessage.id,
          chat_channel_id: channel?.id,
          user_id: currentMessage.user?.id
        }
      }
    })
    enqueueSnackbar('Message attached to task', {
      variant: 'success'
    })
    setOpenModal(false)
  }

  const todoTasks = queryGetUserTasksUtils.data?.user_tasks ?? []

  const customMessageActions = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Attach to task': (message: StreamMessage) => {
      setOpenModal(true)
      setCurrentMessage(message)
    }
  }

  return (
    <Channel
      channel={channel}
      Avatar={NoAvatar}
      Input={Input}
      EmojiPicker={EmojiPicker}
    >
      <ChannelHeader />
      <MessageList customMessageActions={customMessageActions} />
      <MessageInput />
      <AttachTaskModal open={openModal}>
        <div className='flex h-full flex-col space-y-3'>
          <Typography variant='h6'>Select task to attach</Typography>
          <div className='mb-6 grow space-y-3 overflow-auto'>
            {todoTasks.map((task) => (
              <div className='flex items-center'>
                <Typography variant='body2'>
                  {' '}
                  {task.title}
                </Typography>
                {task.tag_slug && (
                  <Chip.Basic
                    sx={{
                      m: 0.4
                    }}
                    size='small'
                    label={tags.find((e) => e.value === task.tag_slug)?.text ?? ''}
                    color='success'
                  />
                )}
                <Btn.ActionButton
                  forceMobile
                  icon={<BsCheck2Circle />}
                  onClick={async () => await attachMessage(task.id)}
                >
                </Btn.ActionButton>
              </div>

            ))}
          </div>

          <div className='ml-auto'>
            <Btn.Button onClick={() => setOpenModal(false)}> Close </Btn.Button>
          </div>

        </div>

      </AttachTaskModal>
    </Channel>
  )
}
