import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type GetChatQueryVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['uuid']>
}>

export type GetChatQuery = { __typename?: 'query_root', getChat?: { __typename?: 'GetChatPayload', chat?: { __typename?: 'Chat', token?: string | undefined } | undefined } | undefined }

export type ChatListAdminsQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ChatListAdminsInput>
}>

export type ChatListAdminsQuery = { __typename?: 'query_root', chatListAdmins?: { __typename?: 'ChatListAdminsPayload', success: boolean, data?: any | undefined } | undefined }

export type ChatSyncUserQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ChatSyncUserInput>
}>

export type ChatSyncUserQuery = { __typename?: 'query_root', chatSyncUser?: { __typename?: 'ChatSyncUserPayload', success: boolean, data?: any | undefined } | undefined }

export const GetChatDocument = gql`
    query GetChat($userId: uuid) {
  getChat(input: {user_id: $userId}) {
    chat {
      token
    }
  }
}
    `

/**
 * __useGetChatQuery__
 *
 * To run a query within a React component, call `useGetChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetChatQuery(baseOptions?: Apollo.QueryHookOptions<GetChatQuery, GetChatQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetChatQuery, GetChatQueryVariables>(GetChatDocument, options)
}
export function useGetChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatQuery, GetChatQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetChatQuery, GetChatQueryVariables>(GetChatDocument, options)
}
export type GetChatQueryHookResult = ReturnType<typeof useGetChatQuery>
export type GetChatLazyQueryHookResult = ReturnType<typeof useGetChatLazyQuery>
export type GetChatQueryResult = Apollo.QueryResult<GetChatQuery, GetChatQueryVariables>
export const ChatListAdminsDocument = gql`
    query ChatListAdmins($input: ChatListAdminsInput) {
  chatListAdmins(input: $input) {
    success
    data
  }
}
    `

/**
 * __useChatListAdminsQuery__
 *
 * To run a query within a React component, call `useChatListAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatListAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatListAdminsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatListAdminsQuery(baseOptions?: Apollo.QueryHookOptions<ChatListAdminsQuery, ChatListAdminsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<ChatListAdminsQuery, ChatListAdminsQueryVariables>(ChatListAdminsDocument, options)
}
export function useChatListAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatListAdminsQuery, ChatListAdminsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<ChatListAdminsQuery, ChatListAdminsQueryVariables>(ChatListAdminsDocument, options)
}
export type ChatListAdminsQueryHookResult = ReturnType<typeof useChatListAdminsQuery>
export type ChatListAdminsLazyQueryHookResult = ReturnType<typeof useChatListAdminsLazyQuery>
export type ChatListAdminsQueryResult = Apollo.QueryResult<ChatListAdminsQuery, ChatListAdminsQueryVariables>
export const ChatSyncUserDocument = gql`
    query ChatSyncUser($input: ChatSyncUserInput) {
  chatSyncUser(input: $input) {
    success
    data
  }
}
    `

/**
 * __useChatSyncUserQuery__
 *
 * To run a query within a React component, call `useChatSyncUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatSyncUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatSyncUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatSyncUserQuery(baseOptions?: Apollo.QueryHookOptions<ChatSyncUserQuery, ChatSyncUserQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<ChatSyncUserQuery, ChatSyncUserQueryVariables>(ChatSyncUserDocument, options)
}
export function useChatSyncUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatSyncUserQuery, ChatSyncUserQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<ChatSyncUserQuery, ChatSyncUserQueryVariables>(ChatSyncUserDocument, options)
}
export type ChatSyncUserQueryHookResult = ReturnType<typeof useChatSyncUserQuery>
export type ChatSyncUserLazyQueryHookResult = ReturnType<typeof useChatSyncUserLazyQuery>
export type ChatSyncUserQueryResult = Apollo.QueryResult<ChatSyncUserQuery, ChatSyncUserQueryVariables>
