import { useCallback, useMemo, useState } from 'react'
import { CheckCircleOutlined, ExpandLess, ExpandMore } from '@mui/icons-material'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { LexoRank } from 'lexorank'

import { LoadingCard } from '#app/components/Loading/LoadingCard'
import { useWebsiteEditorGetSectionHydratedLazyQuery, useWebsiteEditorGetSectionsTemplatesQuery } from '#app/operations/website-editor/websiteeditor.queries.generated'
import useWebsiteEditorStore from '#app/store/website-editor'

import { NunjucksDefinition, SectionTemplateProps } from '../../types'

interface Props extends DrawerProps {
  placement?: string
  userId: string
}

export const DrawerNewSection = ({ open, placement, userId }: Props) => {
  const { closeNewSectionDrawer, newSectionDrawerLexorankContext, newSectionDrawer, setSectionDetails } = useWebsiteEditorStore()
  const [fetchSectionHydrated, { loading: loadingGetSection }] = useWebsiteEditorGetSectionHydratedLazyQuery({
    fetchPolicy: 'no-cache'
  })
  const { data: sectionTemplateList, loading } = useWebsiteEditorGetSectionsTemplatesQuery({
    variables: {
      input: {
        template_type: 'general_feb24'
      }
    }
  })

  const { templates, uniqueCategories } = useMemo(() => {
    const templates = sectionTemplateList?.websiteEditorGetSectionsTemplates?.website_template_sections?.filter((sectionTemplate) => sectionTemplate?.category !== 'main_container' && sectionTemplate.category !== '404')
    if (!templates) return {
      uniqueCategories: [],
      templates: []
    }

    return {
      templates,
      uniqueCategories: [...templates.map((template) => template.category).filter((value, index, self) => self.indexOf(value) === index)]
    }
  }, [sectionTemplateList])

  const [selectedCategory, setSelectedCategory] = useState({
    expanded: ''
  })

  const [selectedSectionTemplate, setSelectedTemplate] = useState<SectionTemplateProps | undefined>(undefined)

  const saveChanges = useCallback(async () => {
    if (selectedSectionTemplate) {
      const sectionTmpId = `tmp_new_section_${Date.now()}`

      const sectionHydrated = await fetchSectionHydrated({
        variables: {
          input: {
            website_template_section_id: selectedSectionTemplate?.id,
            user_id: userId
          }
        }
      })

      const getNewLexorank = () => {
        const newSectionDrawerPosition = newSectionDrawer ?? 'below'
        if (!newSectionDrawerLexorankContext) return LexoRank.middle().toString()
        const { prev, current, next } = newSectionDrawerLexorankContext || {}

        if (newSectionDrawerPosition === 'above') {
          if (prev && current) {
            return LexoRank.parse(prev).between(LexoRank.parse(current)).toString()
          }
          if (current) {
            return LexoRank.parse(current).genPrev().toString()
          }
          return LexoRank.min().toString()
        }

        if (newSectionDrawerPosition === 'below') {
          if (current && next) {
            return LexoRank.parse(current).between(LexoRank.parse(next)).toString()
          }
          if (current) {
            return LexoRank.parse(current).genNext().toString()
          }
          return LexoRank.max().toString()
        }

        return LexoRank.middle().toString()
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const secionHydrated_: any = sectionHydrated?.data?.websiteEditorGetSectionHydrated?.website_section

      setSectionDetails(sectionTmpId, {
        title: secionHydrated_?.title,
        description: secionHydrated_?.description,
        htmlCode: secionHydrated_?.html,
        isActive: secionHydrated_?.is_active_default,
        nunjucksVars: secionHydrated_?.nunjucks as NunjucksDefinition[],
        isContainer: secionHydrated_?.is_container,
        lexorank: getNewLexorank(),
        sectionId: sectionTmpId,

        category: selectedSectionTemplate?.category,
        websiteTemplateSectionId: selectedSectionTemplate?.id,
        originalSort: selectedSectionTemplate?.sort
      })

      closeNewSectionDrawer()
    }
  }, [closeNewSectionDrawer, fetchSectionHydrated, newSectionDrawer, newSectionDrawerLexorankContext, selectedSectionTemplate, setSectionDetails, userId])

  const handleExpandedClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    uniqueCatName: string,
  ) => {
    setSelectedCategory({
      expanded: uniqueCatName
    })
  }

  const handleSectionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    section: SectionTemplateProps
  ) => {
    setSelectedTemplate(section)
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      sx={{
        width: '50vw',
        ['& .MuiDrawer-paper']: {
          width: '50vw',
          boxSizing: 'border-box'
        },
      }}
      onClose={closeNewSectionDrawer}
    >
      <Stack
        p={3}
        gap={1}
        maxHeight='100svh'
      >
        <Typography
          component='h1'
          variant='h3'
        >
          Create a new section
          {' '}
          {placement}
        </Typography>
        <List
          component='nav'
          sx={{
            flexShrink: 1,
            overflow: 'scroll',
            '&& > .Mui-selected, && > .Mui-selected:hover': {
              bgcolor: '#00F700c9',
            },
          }}
        >
          {loading && <LoadingCard />}
          {!loading && uniqueCategories.map((uniqueCatName) => (
            <>
              <ListItemButton
                key={uniqueCatName}
                divider
                selected={selectedSectionTemplate?.category === uniqueCatName}
                onClick={(event) => handleExpandedClick(event, uniqueCatName)}
              >
                <ListItemText primary={uniqueCatName} />
                {selectedSectionTemplate?.category === uniqueCatName
                  ? <CheckCircleOutlined />
                  : selectedCategory?.expanded === uniqueCatName
                    ? <ExpandLess />
                    : <ExpandMore />}
              </ListItemButton>
              <Collapse
                in={selectedCategory?.expanded === uniqueCatName}
                timeout='auto'
              >
                <List
                  disablePadding
                  component='div'
                  sx={{
                    '&& .Mui-selected, && .Mui-selected:hover': {
                      bgcolor: '#00F70030',
                    },
                  }}

                >
                  {templates.filter((template) => template.category === uniqueCatName).map((section) => (
                    <ListItemButton
                      key={section.id}
                      divider
                      color='warning'
                      sx={{
                        pl: 4,
                        flexDirection: 'column',
                        gap: 1,
                      }}
                      selected={selectedSectionTemplate?.id === section.id}
                      onClick={(event) => handleSectionClick(event, section)}
                    >
                      <ListItemText

                        sx={{
                          typography: 'h2',
                          '.MuiTypography-root': {
                            fontSize: '1.75rem',
                            fontWeight: 500
                          }
                        }}
                        primary={section.title}
                      />
                      <Typography sx={{
                        fontStyle: 'italic'
                      }}
                      >
                        {section.description}
                      </Typography>
                      <img
                        src={section.thumbnail}
                        alt=''
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>

            </>
          ))}
        </List>
        <Stack
          direction='row'
          gap={2}
        >
          <Button
            variant='outlined'
            disabled={!selectedSectionTemplate || loadingGetSection}
            onClick={closeNewSectionDrawer}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            disabled={!selectedSectionTemplate || loadingGetSection}
            sx={{
              flexGrow: 1
            }}
            onClick={saveChanges}
          >
            {loadingGetSection ? 'Loading...' : 'Save'}
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  )
}
