import produce from 'immer'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

import { GetActivityCenterQuery } from '#app/operations/activitycenter/activitycenter.queries.generated'
import { User } from '#app/types'

interface State {
  key: string
  userData?: User.UserType
  isLastInteractions: boolean
  unreadMessages: number
  unreadNotifications: number
  setLastInteractions: (bool: boolean) => void
  zt_reset: () => void
  setUnreadMessages: (val: number) => void
  setUnreadNotifications: (data: GetActivityCenterQuery['activities'], unreadCount?: number) => void
}

const INITIAL = {
  userData: undefined,
  zt_test: false,
  isLastInteractions: false,
  unreadMessages: 0,
  unreadNotifications: 0
}

export const useStoreUser = create<State>()(
  devtools(
    (set) => ({
      key: 'ZT_owners_user',
      ...INITIAL,
      zt_reset: () =>
        set(
          produce((originalState) => {
            const state = originalState
            Object.keys(INITIAL).map((key) => {
              // @ts-expect-error TODO Improve clean zustand
              state[key] = INITIAL[key]
              return null
            })
          }),
          false,
          'zt_reset'
        ),
      setLastInteractions: (bool) => {
        set(
          produce((originalState) => {
            const state = originalState
            state.isLastInteractions = bool
          })
        )
      },
      setUnreadMessages: (val) => {
        set(
          produce((originalState) => {
            const state = originalState
            state.unreadMessages = val
          })
        )
      },
      setUnreadNotifications: (activities, unreadCount) => {
        set(
          produce((originalState) => {
            const state = originalState
            const count = unreadCount ?? state.unreadNotifications
            state.unreadNotifications = activities.filter((activity) => {
              if (activity.type === 'unreadMessage' && count === 0) {
                return false // Consider it read
              }
              return !activity.read
            }).length
          })
        )
      },
    }),
    {
      name: 'ZT_owners_user'
    }
  )
)
