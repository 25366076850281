import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'

import ProfileContext from '#app/contexts/ProfileContext'
import { ConnectedAccountStatus } from '#app/feature/Banking/ConnectedAccountStatus'
import { EntityPage } from '#app/layouts/EntityView'
import { useBankingBottomSection } from '#app/store/banking-bottom-sections'

import { BankingDetails } from './BankingDetails'

export const Transactions = () => {
  const { t } = useTranslation()
  const { getProfile: { user } } = useContext(ProfileContext)

  const { setSelectedSection } = useBankingBottomSection((state) => state)
  const location = useLocation()

  useEffect(() => {
    setSelectedSection({
      url: location.pathname
    })
  }, [])

  return (
    <EntityPage
      isLoading={false}
      layout='free'
      pageTitle={t('banking.transactions.pageTitle', 'Transactions')}
      pageSubtitle={t('banking.transactions.pageSubtitle', 'Check your account balance and stay on top of your transactions')}
      mainContent={(
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <ConnectedAccountStatus user={user} />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <BankingDetails user={user} />
          </Grid>
        </Grid>
      )}
    />
  )
}
