import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { NavigationBottom } from '#app/v2/components/NavigationBottom'
import { allNotifications } from '#app/v2/pages/ActivityCenter/data'
import { data, emptyTodos } from '#app/v2/pages/Home/data'
import { modalShortcuts } from '#app/v2/pages/MyProfile/data'

import { Home } from './page'

export const StoryHome = () => {
  const [searchParams] = useSearchParams()

  const storyProps = useMemo(() => {
    const scenario = searchParams.get('scenario')

    const res = {
      home: {
        profile: {
          ...data.profile
        },
        routes: data.routes,
        shortcuts: data.shortcuts,
        todos: data.todos,
        messages: data.messages,
        activities: allNotifications,
        qtyNotifications: data.qtyNotifications,
      },
      bottomNavigation: {
        isIOS: data.bottomNavigation.isIOS,
        pendingMessages: data.bottomNavigation.pendingMessages,
        routes: data.bottomNavigation.routes,
      },

    }

    switch (scenario) {
      case 'todos_done':
        res.home.todos = emptyTodos.todos
        break
      case 'no_notifications':
        res.home.activities = []
        res.home.qtyNotifications = 0
        break
      case 'no_messages':
        res.home.messages = {
          inbox: [],
          groups: []
        }
        res.bottomNavigation.pendingMessages = 0
        break
      case 'fresh_account':
        res.home.profile = {
          fullName: 'Hector Hustler',
          firstName: 'Hector',
          avatar: '',
          clientId: 456,
          services: 'Handyman',
          shortcuts: modalShortcuts.shortcuts,
          extraShortcuts: modalShortcuts.extraShortcuts,
          editProfileUrl: data.profile.editProfileUrl,
          logout: data.profile.logout
        },
        res.home.todos = []
        res.home.activities = []
        res.home.qtyNotifications = 0
        res.home.messages = {
          inbox: [],
          groups: []
        }
        break
    }

    return res
  }, [searchParams])
  return (
    <div className='min-h-screen bg-[#F7F9FB]'>
      <Home
        {...storyProps.home}
      />
      <NavigationBottom {...storyProps.bottomNavigation} />
    </div>
  )
}
