import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import AppContext from '#app/contexts/AppContext'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { useEntityUserTasks } from '#app/hooks/useEntityUserTasks'
import { EntityPage } from '#app/layouts/EntityView'
import { TaskContent } from '#app/routes/Tasks/TaskContent'
import { Task } from '#app/types'

export function TaskDetail() {
  const { appState } = useContext(AppContext)
  const { t } = useTranslation()

  const {
    queryGetUserTaskById,
    queryGetUserTaskByIdUtils
  } = useEntityUserTasks()
  const params = useParams()

  const { isAdmin } = useEntityUser()

  const currentTask: Task.UserTask | null = useMemo(() => {
    const res = queryGetUserTaskByIdUtils.data
    if (res?.user_tasks && res?.user_tasks[0]) {
      return res?.user_tasks[0]
    }
    return null
  }, [queryGetUserTaskByIdUtils])

  const initView = useCallback(async () => {
    if (params.taskId) {
      await queryGetUserTaskById({
        variables: {
          taskId: params.taskId
        }
      })
    }
  }, [params, queryGetUserTaskById])

  useEffect(() => {
    if (!queryGetUserTaskByIdUtils.called) {
      initView()
        .catch((err) => {
          console.error(err)
        })
    }
  }, [appState.isLoading, initView, queryGetUserTaskByIdUtils])

  return (
    <EntityPage
      hasReturn
      layout='one-column'
      pageTitle={t('user_tasks.taskDetails')}
      isLoading={queryGetUserTaskByIdUtils.loading}
      mainContent={(
        <>
          {
            currentTask && (!currentTask.deleted || isAdmin)
              ? (
                <TaskContent
                  adminSide={isAdmin}
                  currentTask={currentTask}
                />
              )
              : null
          }
        </>
      )}
    />
  )
}
