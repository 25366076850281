/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react'
import { Grid, Skeleton, Table as MuiTable } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { useIsDesktopSize } from '../../utils/mediaQueries'

interface Props {
  onRowClick?: (data?: any) => void
  wrapInTableRow?: boolean
  displayDesktop?: any
  displayMobile?: any
  structure?: TableStructure
  isLoading?: boolean
}

export interface TableStructure {
  mobile?: {
    rows: Array<{
      data?: any
      key?: string
      columns: Array<{
        component: JSX.Element
      }>
    }>
  }
  desktop?: {
    headers: Array<{
      text: string
      style?: { width?: string, display?: string }
    }>
    rows: Array<{
      data?: any
      key?: string
      columns: Array<{
        component: JSX.Element
      }>
    }>
  }
}

interface TableProps {
  onRowClick?: (data?: any) => void
  structure?: TableStructure
  wrapInTableRow?: boolean
  isLoading?: boolean
}

const RowMobile = ({ children, className, ...props }: PropsRowMobile) => (
  <div
    style={{
      minHeight: '70px'
    }}
    className={`w-100 border-bottom d-flex justify-content-start align-items-center ${className ?? ''}`}
    {...props}
  >
    { children }
  </div>
)

const ListSkeleton = () => {
  return (
    <Grid
      container
      rowSpacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Skeleton
          variant='rounded'
          height={60}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Skeleton
          variant='rounded'
          height={60}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Skeleton
          variant='rounded'
          height={60}
        />
      </Grid>
    </Grid>
  )
}

const Mobile = ({ structure, wrapInTableRow = false, isLoading, onRowClick }: TableProps) => (
  <div>
    {
      isLoading
        ? <ListSkeleton />
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        : (
          <TableContainer>
            <MuiTable>
              <TableHead>
                <TableRow>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  structure
                    ?.mobile
                    ?.rows
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/restrict-template-expressions
                    .map((row, idx) => {
                      if (wrapInTableRow) {
                        return (
                          <TableRow
                            key={`${row?.key ?? ''}_${idx}`}
                            style={{
                              cursor: onRowClick ? 'pointer' : ''
                            }}
                            onClick={onRowClick && (() => onRowClick(row?.data))}
                          >
                            <TableCell>
                              {
                                React.cloneElement(row.columns[0].component)
                              }
                            </TableCell>
                          </TableRow>
                        )
                      }

                      return React.cloneElement(row.columns[0].component, {
                        key: `${row?.key ?? ''}_${idx}`
                      })
                    })
                }
              </TableBody>
            </MuiTable>
          </TableContainer>
        )
    }
  </div>
)

const Desktop = ({ structure, isLoading, onRowClick }: TableProps) => (
  <TableContainer>
    <MuiTable>
      <TableHead>
        <TableRow>
          { structure?.desktop?.headers.map((element, idx) =>
            (
              <TableCell
                key={idx}
                style={{
                  ...element.style,
                  fontWeight: 800
                }}
              >
                { element.text }
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {
          isLoading
            ? (
              <TableRow>
                { structure?.desktop?.headers.map((element, idx) =>
                  (
                    <TableCell key={idx}>
                      <Skeleton
                        variant='rounded'
                        height={60}
                      />
                    </TableCell>
                  )
                )}
              </TableRow>
            )
            : structure
              ?.desktop
              ?.rows
              .map((e, i) => (
                <TableRow
                  key={i}
                  style={{
                    cursor: onRowClick ? 'pointer' : ''
                  }}
                  onClick={onRowClick && (() => onRowClick(e?.data))}
                >
                  {
                    e
                      .columns
                      .map(({ component }, index) => {
                        return (
                          <TableCell key={index}>
                            { React.cloneElement(component) }
                          </TableCell>
                        )
                      })
                  }
                </TableRow>
              )
              )
        }
      </TableBody>
    </MuiTable>
  </TableContainer>
)

const Table = ({ displayDesktop, displayMobile, structure, wrapInTableRow = false, isLoading, onRowClick }: Props) => {
  let isDesktop = useIsDesktopSize()

  if (displayDesktop) {
    isDesktop = displayDesktop
  }
  else if (displayMobile) {
    isDesktop = !displayMobile
  }

  if (isDesktop) {
    return (
      <Desktop
        isLoading={isLoading}
        structure={structure}
        onRowClick={onRowClick}
      />
    )
  }
  else {
    return (
      <Mobile
        wrapInTableRow={wrapInTableRow}
        isLoading={isLoading}
        structure={structure}
        onRowClick={onRowClick}
      />
    )
  }
}

interface PropsRowMobile {
  children?: JSX.Element
  className?: string
  props?: any
  onClick?: () => void
  role?: string
}

Table.RowMobile = RowMobile

export default Table
