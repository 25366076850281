import { Calculator1, ChatBubbleSquareWrite, DollarCoin, UserAddPlus } from '#app/v2/assets/Icons'
import Images from '#app/v2/assets/Images'
import { modalShortcuts, profileStoryData } from '#app/v2/pages/MyProfile/data'

export const data = {
  bottomNavigation: {
    routes: {
      home: '/v2-ui/home',
      messages: '/v2-ui/messages',
      tasks: '/v2-ui/tasks',
      business: '/v2-ui/business',
    },
    isIOS: false,
    pendingMessages: 109,

  },
  profile: {
    avatar: profileStoryData.profile.image,
    fullName: profileStoryData.profile.fullName,
    firstName: 'Hector',
    services: profileStoryData.profile.services,
    clientId: profileStoryData.profile.clientId,
    editProfileUrl: profileStoryData.editProfileUrl,
    shortcuts: modalShortcuts.shortcuts,
    extraShortcuts: modalShortcuts.extraShortcuts,
    logout: modalShortcuts.logout
  },
  routes: {
    profile: '/v2-ui/profile',
    editProfile: '/v2-ui/edit-profile',
    activityCenter: '/v2-ui/activity-center',
    tasks: '/v2-ui/tasks',
    messages: '/v2-ui/messages',
  },
  shortcuts: [
    {
      icon: UserAddPlus,
      url: '/v2-ui/add-client',
      description: 'Add Client',
    },
    {
      icon: Calculator1,
      url: '/v2-ui/create-estimate',
      description: 'Create Estimate',
    },
    {
      icon: DollarCoin,
      url: '/v2-ui/req-payment',
      description: 'Request Payment',
    },
    {
      icon: ChatBubbleSquareWrite,
      url: '/v2-ui/req-review',
      description: 'Request Review',
    },
  ],
  todos: [
    {
      status: 'todo',
      id: ':task-id',
      profile: {
        avatar: Images.SamBOProfile,
        initials: 'SF',
      },
      sector: 'Onboarding',
      title: 'Approve Logo',
      description: 'Complete this first step to define your business brand.',
      badges: ['task', 'comments'],
    },
    {
      status: 'todo',
      id: ':task-id',
      profile: {
        avatar: Images.SamBOProfile,
        initials: 'SF',
      },
      sector: 'Onboarding',
      title: 'Setup Banking',
      description: 'Setup your Banking so you are ready to receive payments for your work.',
      badges: ['comments'],
    },
    {
      status: 'todo',
      id: ':task-id',
      profile: {
        avatar: Images.SamBOProfile,
        initials: 'SF',
      },
      sector: 'Business Growth',
      title: 'Find Leads',
      description: 'Setup your Banking so you are ready to receive payments for your work.',
      badges: [],
    },
    {
      status: 'todo',
      id: ':task-id',
      profile: {
        avatar: Images.SamBOProfile,
        initials: 'SF',
      },
      sector: 'Networking',
      title: 'Connect with 5 BOs in your area',
      description: 'Setup your Banking so you are ready to receive payments for your work.',
      badges: [],
    },
  ],
  messages: {
    inbox: [
      {
        sender: {
          avatar: Images.SamBOProfile,
          name: 'Jane Doe',
        },
        timeStamp: '13:28',
        message: 'Yes, complete that task and we can continue Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis cum iusto at iste doloribus sint quasi incidunt nulla fugit, ratione illum numquam in iure rerum explicabo nam, labore possimus laborum.',
      },
      {
        sender: {
          avatar: Images.SamBOProfile,
          name: 'Painting in new york city',
        },
        timeStamp: '13:28',
        message: 'Mark: I need to find this specific paint, does Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis cum iusto at iste doloribus sint quasi incidunt nulla fugit, ratione illum numquam in iure rerum explicabo nam, labore possimus laborum.',
      },
      {
        sender: {
          avatar: Images.SamBOProfile,
          name: 'Josh Turner',
        },
        timeStamp: '13:28',
        message: 'If you have any questions, let me know.',
      },
    ],
    groups: [
      {
        sender: {
          avatar: Images.SamBOProfile,
          name: 'Painting Peer Group',
        },
        timeStamp: '13:28',
        message: 'Mark: I need to find this specific paint, does Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis cum iusto at iste doloribus sint quasi incidunt nulla fugit, ratione illum numquam in iure rerum explicabo nam, labore possimus laborum.',
      },
      {
        sender: {
          avatar: Images.SamBOProfile,
          name: 'FL - BO network group',
        },
        timeStamp: '13:28',
        message: 'Mark: I need to find this specific paint, does Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis cum iusto at iste doloribus sint quasi incidunt nulla fugit, ratione illum numquam in iure rerum explicabo nam, labore possimus laborum.',
      },
    ],
  },
  activities: [1, 2, 3],
  qtyNotifications: 10,
}

export const emptyTodos = {
  bottomNavigation: {
    routes: {
      home: '/v2-ui/home',
      messages: '/v2-ui/messages',
      tasks: '/v2-ui/tasks',
      business: '/v2-ui/business',
    },
    isIOS: false,
    pendingMessages: 9,

  },
  profile: {
    fullName: 'Hector Hustler',
    avatar: Images.SamBOProfile,
  },
  routes: {
    profile: '/v2-ui/profile',
    editProfile: '/v2-ui/edit-profile',
    activityCenter: '/v2-ui/activity-center',
    tasks: '/v2-ui/tasks',
    messages: '/v2-ui/messages',
  },
  shortcuts: [
    {
      icon: UserAddPlus,
      url: '/v2-ui/add-client',
      description: 'Add Client',
    },
    {
      icon: Calculator1,
      url: '/v2-ui/create-estimate',
      description: 'Create Estimate',
    },
    {
      icon: DollarCoin,
      url: '/v2-ui/req-payment',
      description: 'Request Payment',
    },
    {
      icon: ChatBubbleSquareWrite,
      url: '/v2-ui/req-review',
      description: 'Request Review',
    },
  ],
  todos: [
  ],
  messages: {
    inbox: [
      {
        sender: {
          avatar: Images.SamBOProfile,
          name: 'Jane Doe',
        },
        timeStamp: '13:28',
        message: 'Yes, complete that task and we can continue Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis cum iusto at iste doloribus sint quasi incidunt nulla fugit, ratione illum numquam in iure rerum explicabo nam, labore possimus laborum.',
      },
      {
        sender: {
          avatar: Images.SamBOProfile,
          name: 'Painting in new york city',
        },
        timeStamp: '13:28',
        message: 'Mark: I need to find this specific paint, does Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis cum iusto at iste doloribus sint quasi incidunt nulla fugit, ratione illum numquam in iure rerum explicabo nam, labore possimus laborum.',
      },
      {
        sender: {
          avatar: Images.SamBOProfile,
          name: 'Josh Turner',
        },
        timeStamp: '13:28',
        message: 'If you have any questions, let me know.',
      },
    ],
    groups: [
      {
        sender: {
          avatar: Images.SamBOProfile,
          name: 'Painting Peer Group',
        },
        timeStamp: '13:28',
        message: 'Mark: I need to find this specific paint, does Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis cum iusto at iste doloribus sint quasi incidunt nulla fugit, ratione illum numquam in iure rerum explicabo nam, labore possimus laborum.',
      },
      {
        sender: {
          avatar: Images.SamBOProfile,
          name: 'FL - BO network group',
        },
        timeStamp: '13:28',
        message: 'Mark: I need to find this specific paint, does Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis cum iusto at iste doloribus sint quasi incidunt nulla fugit, ratione illum numquam in iure rerum explicabo nam, labore possimus laborum.',
      },
    ],
  },
  activities: [],
  qtyNotifications: 10,
}
