/* eslint-disable @typescript-eslint/no-unsafe-call */

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsArrowRepeat } from 'react-icons/bs'
import { useParams, useSearchParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { Box, Stack, Typography } from '@mui/material'
import { GraphQLError } from 'graphql'

import Button from '#app/components/Buttons/Button'
import { useEntityUserTasks } from '#app/hooks/useEntityUserTasks'
import { EntityPage } from '#app/layouts/EntityView'
import { THEME } from '#app/layouts/theme'
import { usePurchaseDomainAndUpdateBusinessMutation } from '#app/operations/onboarding/onboarding.mutations.generated'
import { NameDomainSuggestion, useOnboardingNameDomain } from '#app/store/onboarding-name-domain'
import { GET_PROFILE, GET_PROFILE_TYPE } from '#app/utils/graphqlQueries'

import { GenerateOptions } from './DomainSelection/GenerateOptions'
import { SelectNameComponents } from './DomainSelection/SelectNameComponents'

interface ConfirmSelectionProps {
  userProfile?: GET_PROFILE_TYPE
}

const ConfirmSelection = ({ userProfile }: ConfirmSelectionProps) => {
  const { selectedSuggestion, generatedSuggestions, setCurrentStep, generatedDomainSuggestions, selectedDomain, task, type, setTask } = useOnboardingNameDomain()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isBackLoading, setBackLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  const [confirmSelection] = usePurchaseDomainAndUpdateBusinessMutation()
  const { mutationEditUserTask } = useEntityUserTasks()
  const [searchParams] = useSearchParams()

  const taskId = searchParams.get('taskId')

  const suggestion = generatedSuggestions[selectedSuggestion]
  const domainSuggestion = generatedDomainSuggestions[selectedDomain]

  const goBack = async () => {
    setBackLoading(true)

    const prevStep = 'select-domain-only'

    const taskMetadata = {
      ...task?.metadata,
      currentStep: prevStep
    }

    await mutationEditUserTask(
      {
        variables: {
          id: taskId ?? '',
          user_id: userProfile?.getProfile?.user?.id ?? '',
          metadata: taskMetadata
        }
      })
      .then(() => {
        if (task) {
          setTask({
            ...task,
            metadata: taskMetadata
          })
        }
        setCurrentStep(prevStep)
      })
      .finally(() => {
        setBackLoading(false)
      })
  }

  const onConfirm = () => {
    setLoading(true)

    const nextStep = 'congratulations'

    void confirmSelection({
      variables: {
        user_id: userProfile?.getProfile?.user?.id ?? '',
        name: suggestion?.name ?? '',
        domain: domainSuggestion?.domain ?? ''
      }
    })
      .then(async () => {
        await mutationEditUserTask(
          {
            variables: {
              id: taskId ?? '',
              user_id: userProfile?.getProfile?.user?.id ?? '',
              metadata: {
                ...task?.metadata,
                currentStep: nextStep
              }
            }
          })

        setCurrentStep('congratulations')
      })
      .catch((error) => {
        const failedToPurchase = error
          ?.graphQLErrors
          ?.find((err: GraphQLError) => err.extensions.code === 'owners-failed-to-purchase')

        if (failedToPurchase) {
          setCurrentStep('error-screen')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <EntityPage
      isLoading={false}
      layout='free'
      mobilePaddings={{}}
      backgroundColor='white'
      mainContent={(
        <Stack spacing={3}>
          <Typography variant='h6'>{t('onboarding.domainNameSelection.steps.confirmSelection.title', 'Confirm selection')}</Typography>
          <Typography>{t('onboarding.domainNameSelection.steps.confirmSelection.description', 'Please confirm your selection, changing this selection later will incur in extra charges. If you feel that the selection is not good for your company, press Back to return and select another suggestion')}</Typography>
          <Stack spacing={1}>
            <Box>
              <Typography
                variant='body2'
                color={THEME.COLORS.custom.grey}
              >
                {t('onboarding.domainNameSelection.steps.confirmSelection.name', 'Name')}
              </Typography>
              <Typography>{suggestion?.name ?? '-'}</Typography>
            </Box>
            <Box>
              <Typography
                variant='body2'
                color={THEME.COLORS.custom.grey}
              >
                {t('onboarding.domainNameSelection.steps.confirmSelection.domain', 'Domain')}
              </Typography>
              <Typography>{domainSuggestion?.domain ?? '-'}</Typography>
            </Box>
          </Stack>
          <Stack
            direction='row'
            justifyContent='space-between'
          >
            <Button
              disabled={isLoading || isBackLoading}
              isLoading={isBackLoading}
              primary={false}
              onClick={goBack}
            >
              { t('onboarding.domainNameSelection.steps.confirmSelection.back', 'Back') }
            </Button>
            <Button
              disabled={isLoading}
              isLoading={isLoading}
              type='button'
              onClick={onConfirm}
            >
              { t('onboarding.domainNameSelection.steps.confirmSelection.confirm', 'Confirm') }
            </Button>
          </Stack>
        </Stack>
      )}
    />
  )
}

export const Congratulations = () => {
  const { t } = useTranslation()
  const { selectedSuggestion, generatedSuggestions, selectedDomain, generatedDomainSuggestions } = useOnboardingNameDomain()

  return (
    <EntityPage
      isLoading={false}
      layout='free'
      mobilePaddings={{}}
      backgroundColor='white'
      mainContent={(
        <Stack spacing={3}>
          <Typography variant='h6'>{t('onboarding.domainNameSelection.steps.congratulations.title', 'Congratulations!')}</Typography>
          <Typography>{t('onboarding.domainNameSelection.steps.congratulations.description', 'We have successfully purchased your domain!')}</Typography>
          <Stack spacing={1}>
            <Box>
              <Typography
                variant='body2'
                color={THEME.COLORS.custom.grey}
              >
                {t('onboarding.domainNameSelection.steps.confirmSelection.name', 'Name')}
              </Typography>
              <Typography>{generatedSuggestions[selectedSuggestion]?.name ?? '-'}</Typography>
            </Box>
            <Box>
              <Typography
                variant='body2'
                color={THEME.COLORS.custom.grey}
              >
                {t('onboarding.domainNameSelection.steps.confirmSelection.domain', 'Domain')}
              </Typography>
              <Typography>{generatedDomainSuggestions[selectedDomain]?.domain ?? '-'}</Typography>
            </Box>
          </Stack>
        </Stack>
      )}
    />
  )
}

export const ErrorScreen = () => {
  const { t } = useTranslation()
  const { setCurrentStep } = useOnboardingNameDomain()

  return (
    <EntityPage
      isLoading={false}
      layout='free'
      mobilePaddings={{}}
      backgroundColor='white'
      mainContent={(
        <Stack spacing={3}>
          <Typography variant='h6'>{t('onboarding.domainNameSelection.steps.errorScreen.title', 'Something went wrong!')}</Typography>
          <Typography>{t('onboarding.domainNameSelection.steps.errorScreen.description', 'We failed to purchase the domain, please try again or reach out to support!')}</Typography>
          <Button
            startIcon={<BsArrowRepeat />}
            primary={true}
            onClick={() => setCurrentStep('select-options')}
          >
            { t('onboarding.domainNameSelection.steps.errorScreen.retry', 'Retry') }
          </Button>
        </Stack>
      )}
    />
  )
}

export const DomainSelection = () => {
  const { type } = useParams()
  const [searchParams] = useSearchParams()
  const {
    queryGetUserTaskById
  } = useEntityUserTasks()

  const taskId = searchParams.get('taskId')

  const { currentStep, setTask, setType, setCurrentStep } = useOnboardingNameDomain()

  const [fetchUser] = useLazyQuery<GET_PROFILE_TYPE>(GET_PROFILE)
  const [userProfile, setUserProfile] = useState<GET_PROFILE_TYPE>()

  const [domainName, setDomainName] = useState<NameDomainSuggestion | undefined>()
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'scrollTop'
      }
    )
  }, [currentStep])

  useEffect(() => {
    if (taskId) {
      void queryGetUserTaskById({
        variables: {
          taskId
        }
      })
        .then((data) => {
          setTask(data.data?.user_tasks?.[0])
          return data
        })
        .then(async (data) => {
          return await fetchUser({
            variables: {
              userId: data.data?.user_tasks?.[0]?.user_id
            }
          })
        })
        .then((data) => {
          setUserProfile(data?.data)
        })
        .finally(() => {
          setLoading(false)
        })
    }
    else {
      fetchUser()
        .then((data) => {
          setUserProfile(data?.data)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    setType(type ?? '')

    if (type === 'domain-only') {
      setCurrentStep('select-domain-only')
    }
  }, [])

  return (
    <EntityPage
      isLoading={isLoading}
      layout='free'
      mobilePaddings={{}}
      backgroundColor='white'
      mainContent={(
        <Stack spacing={3}>
          { currentStep === 'select-options' && <SelectNameComponents userProfile={userProfile} /> }

          { currentStep === 'generate-options' && <GenerateOptions userProfile={userProfile} /> }
          { currentStep === 'select-domain-only' && <GenerateOptions userProfile={userProfile} /> }

          { currentStep === 'confirm-selection' && <ConfirmSelection userProfile={userProfile} /> }
          { currentStep === 'congratulations' && <Congratulations /> }
          { currentStep === 'error-screen' && <ErrorScreen />}
        </Stack>
      )}
    />
  )
}
