import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import NavigationOnboarding from '#app/components/NavigationOnboarding/NavigationOnboarding'
import { useAppContext } from '#app/contexts/AppContext/useAppContext'

import Success from '../../../components/icons/Success'
import AppContext from '../../../contexts/AppContext'
import ProfileContext from '../../../contexts/ProfileContext'
import { CHANGE_ONBOARDING_STATUS } from '../../../utils/graphqlQueries'

const Congratulations = () => {
  const data = useContext(ProfileContext)
  const { appState } = useContext(AppContext)
  const { V1_SET_LOADING } = useAppContext()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [changeOnboardingStatus] = useMutation(CHANGE_ONBOARDING_STATUS)

  const profile = data.getProfile?.user
  const business = profile?.businesses?.[0]

  useEffect(() => {
    if (business && business.status === 'onboarding') {
      changeOnboardingStatus({
        variables: {
          id: business.id,
          status: 'onboarded'
        }
      })
        .then(() => {
          V1_SET_LOADING(false)
        })
    }
    else {
      if (appState.isLoading) {
        setTimeout(() => {
          V1_SET_LOADING(false)
        }, 500)
      }
    }
  }, [])

  return (
    <div className='row'>
      <div className='col-12 d-flex flex-column justify-content-start align-items-center'>
        <p>
          { t('onboarding.congratulations.message') }
        </p>
        <div className='my-5'>
          <Success />
        </div>
      </div>
      <NavigationOnboarding
        page={false}
        handleSubmit={() => { navigate('/tasks') }}
      />
    </div>
  )
}

export default Congratulations
