/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useCallback, useEffect, useMemo, useState } from 'react'
import ReactQuill from 'react-quill'
import { Alert, Box, Checkbox, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Form, Formik } from 'formik'
import { useImmer } from 'use-immer'

import Button from '#app/components/Buttons/Button'
import FormInput from '#app/components/FormInput/FormInput'
import FormSelect from '#app/components/FormSelect/FormSelect'
import { ContainerQuill } from '#app/components/RichTextArea/styled'
import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import { Nunjucks } from '#app/feature/Tasks/Nunjucks'
import { PreviewTaskContent } from '#app/feature/Tasks/ViewTask/PreviewTaskContent'
import { DEFAULT_COGNITO_KEY } from '#app/hooks/useEntityUserTasks'
import useMutationWithNotification from '#app/hooks/useMutationWithNotification'
import { Task, User } from '#app/types'
import { MUTATION } from '#app/utils/graphqlQueries'
import { createTaskSchema } from '#app/utils/validationSchemas'
import { tags } from '#app/utils/vanilla/data'

import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'

interface Props {
  isOpen: boolean
  initialValues: Task.UserTask | null
  initialMetadata: any
  setIsOpen: (val: boolean) => void
  currentOwner?: User.UserType
  cbSuccessCreate: () => void
}

const initialState = {
  title: '',
  short_description: '',
  loom_videos: [''],
  description_html: '',
  cognitoforms_key: '',
  cognitoforms_dataform: ''
}

const empty = {
  title: '',
  short_description: '',
  loom_video: '',
  cognitoforms_key: DEFAULT_COGNITO_KEY,
  cognitoforms_dataform: '',
  tag_slug: ''
}

export function CreateTask({ initialValues, initialMetadata, isOpen, setIsOpen, currentOwner, cbSuccessCreate }: Props) {
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false)
  const [correctLanguage, setCorrectLanguage] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<boolean>(false)

  const [taskContent, setTaskContent] = useImmer<Partial<Task.UserTask>>(initialState)
  const [createUserTask, { loading: isLoading }] = useMutationWithNotification(MUTATION.tasks.createUserTask)

  useEffect(() => {
    if (initialMetadata?.template_id) {
      setCorrectLanguage(true)
      setAlertMsg(false)
    }
  }, [initialMetadata])

  const initialValuesForm = useMemo(() => {
    if (initialValues) {
      const task = {
        ...empty,
        ...initialValues
      }
      if (task.loom_videos && task.loom_videos.length > 0) {
        // @ts-ignore
        task.loom_video = new URL(task.loom_videos[0])
      }
      if (task.description_html && isOpen) {
        setTaskContent((draft) => {
          draft.description_html = task.description_html
        })
      }
      return task
    }

    return empty
  }, [initialValues, setTaskContent, isOpen])

  const submitTask = useCallback(async () => {
    if (!currentOwner?.id) {
      console.error('This BO don\'t have a valid ID')
      return null
    }

    const payload = {
      ...taskContent,
      user_id: currentOwner.id,
      bo_id: currentOwner.id,
      metadata: initialMetadata
    }

    const result = await createUserTask({
      variables: payload
    })

    if (result?.data?.createUserTask?.task) {
      // Refresh to get query =>
      cbSuccessCreate()
      // Close dialog =>
      setIsOpen(false)
      // Reset this states =>
      setIsPreviewMode(false)
      setTaskContent(initialState)
    }
    else {
      console.error('We\'re having trouble completing your assignment submission')
    }
  }, [cbSuccessCreate, createUserTask, currentOwner, initialMetadata, setIsOpen, setTaskContent, taskContent])

  const alertLanguage = useMemo(() => (
    <Box mt={4}>
      {alertMsg && (
        <Box sx={{
          color: 'red',
          fontSize: '12px',
          fontWeight: 'bold'
        }}
        >
          You must remember to check and confirm that the language sent is the correct one
        </Box>
      )}
      <Alert severity='warning'>
        Please remember that the language of the message you send is exactly how the Business Owner receives it,
        {' '}
        <b>
          {currentOwner?.first_name}
          {' '}
          {currentOwner?.last_name}
          {' '}
        </b>
        {
          currentOwner?.language
          && (
            <>
              has registered
              <b>
                &apos;
                {currentOwner?.language.toUpperCase()}
                &apos;
              </b>
              {' '}
              as his preferred language
            </>
          )
        }
        {
          !currentOwner?.language
          && (
            <>
              <b>does not have a preferred language</b>
              {' '}
              registered on our platform
            </>
          )
        }
        — check it out!
        <Checkbox
          checked={correctLanguage}
          onChange={(e) => {
            setCorrectLanguage(e.target.checked)
            setAlertMsg(!e.target.checked)
          }}
        />
      </Alert>
    </Box>
  ), [alertMsg, correctLanguage, currentOwner])

  const pushNunjuck = useCallback((nunjuckVar: string) => {
    setTaskContent((draft) => {
      draft.description_html = taskContent?.description_html ? `${taskContent?.description_html}${nunjuckVar}` : nunjuckVar
    })
  }, [setTaskContent, taskContent.description_html])

  return (
    <RightSideBar
      isOpen={isOpen}
      closeHandler={() => {
        setTaskContent(initialState)
        setIsPreviewMode(false)
        setIsOpen(false)
      }}
      size='lg'
    >
      <>
        <Typography variant='h4'>
          {isPreviewMode ? 'Preview Message' : 'Create Message'}
&nbsp;
          |
          {currentOwner?.first_name}
          {' '}
          {currentOwner?.last_name}
          {' '}
          (
          {currentOwner?.email}
          )
        </Typography>
        <Box>
          <Formik
            initialValues={initialValuesForm}
            validationSchema={createTaskSchema}
            onSubmit={(data) => {
              // @ts-ignore
              setTaskContent((draft) => {
                draft.loom_videos = [data.loom_video]
                draft.title = data.title
                draft.short_description = data.short_description
                draft.cognitoforms_key = data.cognitoforms_key
                draft.tag_slug = data.tag_slug
                draft.cognitoforms_dataform = data.cognitoforms_dataform.toString()
              })
              setIsPreviewMode(true)
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box sx={{
                  m: 2
                }}
                >
                  {
                    isPreviewMode
                    && (
                      <PreviewTaskContent
                        adminSide
                        previewMode
                        task={taskContent}
                        currentOwner={currentOwner}
                      />
                    )
                  }

                  <Box sx={{
                    display: isPreviewMode ? 'none' : 'block'
                  }}
                  >
                    <Grid
                      container
                      spacing={1}
                      mb={3}
                      mt={1}
                      width='100%'
                    >
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                      >
                        <FormInput
                          label='Title (nunjucks available)'
                          name='title'
                          type='text'
                          placeholder='Write a short title to understand the task'
                        />
                      </Grid>
                      {/* <Grid item xs={6} md={6} lg={6}>
                        <FormInput
                          label={'Short Description (nunjucks available)'}
                          name={'short_description'}
                          placeholder={'Give the business owner a bit of context'}
                          type='text'
                        />
                      </Grid> */}
                      <Grid
                        item
                        xs={8}
                        md={12}
                        lg={12}
                      >
                        <FormInput
                          label='Loom Video'
                          name='loom_video'
                          type='url'
                          placeholder='https://www.loom.com/share/1b93d0f446804d0aa032787b57e5ce6c'
                        />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        md={12}
                        lg={12}
                      >
                        <FormSelect
                          label='Tag'
                          name='tag_slug'
                          options={tags}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: 'none'
                        }}
                      >
                        <FormInput
                          disabled
                          label='Cognito Form Key (Default)'
                          name='cognitoforms_key'
                          type='text'
                          placeholder={DEFAULT_COGNITO_KEY}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                      >
                        <FormInput
                          label='Cognito Form - Data-Form'
                          name='cognitoforms_dataform'
                          type='number'
                          placeholder='5'
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                      >
                        <Nunjucks onClick={pushNunjuck} />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                      >
                        <ContainerQuill>
                          <ReactQuill
                            theme='snow'
                            value={taskContent.description_html}
                            style={{
                              borderRadius: '30px'
                            }}
                            onChange={(val) => setTaskContent((draft) => { draft.description_html = val })}
                          />
                        </ContainerQuill>
                      </Grid>
                    </Grid>
                  </Box>
                  {alertLanguage}
                  <Box mt={4}>
                    <Button
                      sx={{
                        ml: 1
                      }}
                      disabled={isLoading ?? isSubmitting}
                      primary={false}
                      onClick={() => {
                        setIsOpen(false)
                        setAlertMsg(false)
                        setCorrectLanguage(false)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={isLoading ?? isSubmitting}
                      type='submit'
                      sx={{
                        float: isPreviewMode ? 'right' : 'left'
                      }}
                      onClick={(e) => {
                        if (isPreviewMode && correctLanguage) {
                          setAlertMsg(false)
                          setCorrectLanguage(false)
                          submitTask()
                        }
                        else if (isPreviewMode && !correctLanguage) {
                          e.preventDefault()
                          setAlertMsg(true)
                        }
                        return null
                      }}
                    >
                      {isPreviewMode ? 'Send message' : 'Preview message'}
                    </Button>
                    {isPreviewMode
                    && (
                      <Button
                        sx={{
                          float: 'right',
                          mr: 1
                        }}
                        disabled={isLoading}
                        onClick={() => setIsPreviewMode(false)}
                      >
                        Edit
                      </Button>
                    )}
                  </Box>
                </Box>

              </Form>
            )}
          </Formik>
        </Box>
      </>
    </RightSideBar>
  )
}
