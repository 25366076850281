/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsEyeFill, BsFillCheckCircleFill, BsFillInfoCircleFill } from 'react-icons/bs'
import ReactQuill from 'react-quill'
import { useNavigate } from 'react-router-dom'
import { Grid, IconButton, SvgIcon, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import parse from 'html-react-parser'
import { useSnackbar } from 'notistack'
import nunjucks from 'nunjucks'
import { useImmer } from 'use-immer'

import linearCircle from '#app/assets/img/linearCircle.png'
import { Btn } from '#app/components/Buttons'
import Button from '#app/components/Buttons/Button'
import { Chip } from '#app/components/Chip'
import { ChipUser } from '#app/components/Chip/ChipUser'
import { DialogDelete } from '#app/components/Dialog/DialogDelete'
import { DividerExtended } from '#app/components/Divider'
import { LoomVideo } from '#app/components/LoomVideo'
import { ContainerQuill } from '#app/components/RichTextArea/styled'
import { Cognitoform } from '#app/feature/Cognitoform'
import { CommentsThread } from '#app/feature/Comments'
import { JsonTable } from '#app/feature/JsonToHtml'
import { useDayJs } from '#app/hooks/useDayJs'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { DEFAULT_COGNITO_KEY, TASK_STATUS, useEntityUserTasks } from '#app/hooks/useEntityUserTasks'
import { useLinear } from '#app/hooks/useLinear'
import { Task, User } from '#app/types'
import { useIsDesktopSize } from '#app/utils/mediaQueries'
import { tags } from '#app/utils/vanilla/data'

import { nunjucksOptions } from '../Nunjucks'

import { PreviewRichContent } from './PreviewRichContent'

interface Config {
  isDraft: boolean
}

interface Props {
  task: Partial<Task.UserTask>
  adminSide?: boolean
  isTemplate?: boolean
  previewMode?: boolean
  chips?: string[]
  currentOwner?: User.UserType
}

export function PreviewTaskContent({ task, currentOwner, adminSide, previewMode, chips, isTemplate = false }: Props) {
  const [formRaw, setFormRaw] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [previewTag, setPreviewTag] = useState<string>('')
  const [cognitoformsAvailable, setCognitoformsAvailable] = useState<boolean>(false)
  const [taskContent, setTaskContent] = useImmer<Partial<Task.UserTask>>(task)
  const [scrollToLastComment, setScrollToLastComment] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const {
    getTextFromStatus,
    mutationReplyUserTask,
    mutation: { mutationLoading, mutationDeleteLoading },
    mutationDeleteTask,
    getColorFromStatus
  } = useEntityUserTasks(task)
  const { userId } = useEntityUser()
  const { formatDateV2 } = useDayJs()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isDesktop = useIsDesktopSize()
  const { isAdmin } = useEntityUser()
  const { linearIssueURL } = useLinear()

  const isCommentsEnabled = true
  const isTaskReadOnly = (task.deleted === true || previewMode)
  const isTaskUnblocked = (task.status !== TASK_STATUS.COMPLETED && task.status !== TASK_STATUS.ADMIN_COMPLETED && !previewMode)
  const isTaskCompleted = (task.status === TASK_STATUS.COMPLETED || task.status === TASK_STATUS.ADMIN_COMPLETED)

  const loomVideoMemo = useMemo(() => {
    if (task.loom_videos?.[0]) return task.loom_videos[0]
    return null
  }, [task])

  const prefillCognitoform: any = useMemo(() => {
    if (task?.cognitoforms_response?.raw?.data?.entry) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return task?.cognitoforms_response?.raw?.data?.entry
    }
    return {}
  }, [task])

  const cognitoFormKey = useMemo(() => {
    return task?.cognitoforms_key ?? DEFAULT_COGNITO_KEY
  }, [task])

  const cognitoFormAvailable = useMemo(() => {
    return !!task?.cognitoforms_dataform
  }, [task])

  const cogniftoFormShouldBeCompleted = useMemo(() => {
    if (!cognitoFormAvailable) return false
    return task?.cognitoforms_response === null
  }, [task, cognitoFormAvailable])

  useEffect(() => {
    if (task.tag_slug) { setPreviewTag(tags.find((e) => e.value === task.tag_slug)?.text || '') }

    const isBo = !isAdmin
    const isMobile = !isDesktop

    if (isBo && isMobile && !isTaskCompleted) {
      setScrollToLastComment(true)
    }
  }, [])

  useEffect(() => {
    if (task?.response_html && task.status === TASK_STATUS.RESPONSE_DRAFT) {
      // @ts-ignore
      setTaskContent((draft) => { draft.response_html = task?.response_html })
    }
  }, [task, setTaskContent])

  const submitReply = useCallback(async (config: Config) => {
    if (task) {
      try {
        const res = await mutationReplyUserTask({
          variables: {
            id: task.id,
            is_draft: config.isDraft,
            response_html: taskContent.response_html
          }
        })

        if (res) {
          enqueueSnackbar(t('user_tasks.snackbar.draftSaved'), {
            variant: 'success'
          })

          // if (isAdmin) {
          //   await queryGetUserTasks({
          //     variables: { userID: task?.bo_id },
          //     fetchPolicy: 'network-only'
          //   })
          // }
          if (!config.isDraft && !isDesktop && !isAdmin) {
            navigate('/tasks')
          }
        }
        else {
          enqueueSnackbar(t('user_tasks.snackbar.formError'), {
            variant: 'error'
          })
        }
      }
      catch (err) {
        enqueueSnackbar(t('user_tasks.snackbar.formError'), {
          variant: 'error'
        })
      }
    }
  }, [task, mutationReplyUserTask, taskContent.response_html, enqueueSnackbar, t, isAdmin, isDesktop, navigate])

  const submitCognitoFormReply = useCallback(async (payload: any) => {
    if (task) {
      try {
        const res = await mutationReplyUserTask({
          variables: {
            id: task.id,
            is_draft: true,
            cognitoforms_response: payload
          }
        })

        if (res) {
          enqueueSnackbar(t('user_tasks.snackbar.formSuccess'), {
            variant: 'success'
          })
        }
        else {
          enqueueSnackbar(t('user_tasks.snackbar.formError'), {
            variant: 'error'
          })
        }
      }
      catch (err) {
        enqueueSnackbar(t('user_tasks.snackbar.formError'), {
          variant: 'error'
        })
      }
    }
  }, [task, enqueueSnackbar, mutationReplyUserTask, t])

  const cbDeleteTask = useCallback(async () => {
    return await mutationDeleteTask({
      variables: {
        id: task.id
      }
    })
  }, [mutationDeleteTask, task.id])

  const nunjucksContext = useMemo(() => {
    const res = {
      bo: {},
      owners: {}
    }
    nunjucksOptions.map((nunjuck: string) => {
      if (nunjuck.startsWith('bo.')) {
        // @ts-ignore
        res.bo[nunjuck.replace(/(^bo.)/gi, '')] = `[[${nunjuck.toUpperCase()}]]`
      }
      if (nunjuck.startsWith('owners.')) {
        // @ts-ignore
        res.owners[nunjuck.replace(/(^owners.)/gi, '')] = `[[${nunjuck.toUpperCase()}]]`
      }
    })
    return res
  }, [])

  const CognitoFormMemo = useCallback(() => (
    <Cognitoform
      readMode={isAdmin}
      previewMode={isTaskReadOnly || isTaskCompleted}
      accountId={cognitoFormKey}
      formId={task?.cognitoforms_dataform as string}
      prefill={{
        ...prefillCognitoform,
        USERID: task.user_id,
        TASKID: task.id
      }}
      beforeSubmit={(data) => {
        console.info(data)
      }}
      onSubmit={async (result) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const entryNumber: string | null = result?.data?.entry?.Entry?.Number ? result?.data?.entry?.Entry?.Number.toString() : null
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const entryVersion: string | null = result?.data?.entry?.Entry?.Version ? result?.data?.entry?.Entry?.Version.toString() : null

        const mainData = {
          entryId: result?.data?.entry?.Id,
          entryNumber,
          entryVersion,
          adminLink: result?.data?.entry?.Entry?.AdminLink,
          formId: result?.data?.entry?.Form?.Id,
          raw: result
        }
        await submitCognitoFormReply(mainData)
      }}
    />
  ), [task, cognitoFormKey, isTaskReadOnly, isTaskCompleted, prefillCognitoform, submitCognitoFormReply])

  return (
    <Box>
      {adminSide
      && (
        <DialogDelete
          itemName={task.title}
          isLoading={mutationDeleteLoading}
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          onConfirm={cbDeleteTask}
        />
      )}
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        flexDirection={{
          xs: 'column',
          sm: 'row'
        }}
      >
        {
          adminSide && !previewMode
          && (
            <Grid
              item
              mt={2}
              md={6}
              xs={12}
            >
              {
                task.deleted
                && (
                  <Chip.Basic
                    sx={{
                      m: 0.4
                    }}
                    label={getTextFromStatus('deleted')}
                    color='error'
                  />
                )
              }
              {
                chips
                && (
                  <>
                    {
                      chips.map((text: string, key: number) => (
                        <Chip.Basic
                          key={key}
                          color='success'
                          label={text}
                          sx={{
                            m: 0.4
                          }}
                        />
                      ))
                    }
                  </>
                )
              }
            </Grid>
          )
        }
      </Grid>
      <Box>
        <Box sx={{
          mb: 1.5,
          float: 'right'
        }}
        >
          { // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            adminSide && task?.linear_issue_id
            && (
              <IconButton
                aria-label='custom icon link'
                onClick={() => window.open(`${linearIssueURL}/BOH-${task?.linearIssueLinked?.number ?? ''}`, '_blank')}
              >
                <img
                  src={linearCircle}
                  alt='custom icon'
                  style={{
                    width: 32,
                    height: 32
                  }}
                />
              </IconButton>
            )
          }
          {adminSide && !isTemplate && previewTag && (
            <Chip.Basic
              label={previewTag}
              color='success'
            />
          )}
        </Box>
        <Typography
          variant='h5'
          component='div'
        >
          {task.title ? parse(nunjucks.renderString(task.title, nunjucksContext)) : null}
        </Typography>
        {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {task.short_description ? parse(nunjucks.renderString(task.short_description, nunjucksContext)) : null}
          </Typography> */}
        {loomVideoMemo
        && (
          <Box mt={4}>
            <LoomVideo loomURL={loomVideoMemo} />
          </Box>
        )}
        {
          task?.linearIssueLinked?.description
          && (
            <PreviewRichContent
              withoutCardContent
              isMarkdown
              content={nunjucks.renderString(task?.linearIssueLinked?.description, nunjucksContext)}
            />
          )
        }
        {task?.description_html
        && (
          <PreviewRichContent
            withoutCardContent
            content={nunjucks.renderString(task?.description_html, nunjucksContext)}
          />
        )}
      </Box>

      {/* {task.status &&
        <Divider sx={{ mb: 4 }}>
          {
            task?.userByBo &&
            <ChipUser user={task.userByBo} />
          }
        </Divider>
      } */}
      {
        !task.response_html && !cognitoFormAvailable && isTaskCompleted
        && (
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <Chip.Basic
              icon={<BsFillCheckCircleFill />}
              sx={{
                textAlign: 'center'
              }}
              label={t('user_tasks.taskResolvedWithoutContent')}
            />
          </Grid>
        )
      }
      {cognitoFormAvailable && task && prefillCognitoform
      && (
        <Box
          mb={6}
          p={2}
        >

          {adminSide && !previewMode && task.cognitoforms_dataform
          && (
            <>
              <Typography
                color='text.secondary'
                mb={3}
              >
                {task.cognitoforms_response
                && (
                  <FormControlLabel
                    control={(
                      <Switch
                        value={formRaw}
                        onChange={() => setFormRaw(!formRaw)}
                      />
                    )}
                    label={formRaw ? <>Raw Form  &nbsp;</> : <>Cognito Form  &nbsp;</>}
                  />
                )}
                {
                  !task.cognitoforms_response
                  && <>Cognito Form  &nbsp;</>
                }
                <Tooltip title="Cognitoforms forms can be edited over the air, if the user answered the form with an old version, you will not be able to see it represented correctly and you will have to use the switch and activate the 'Raw Form' view">
                  <SvgIcon>
                    <BsFillInfoCircleFill />
                  </SvgIcon>
                </Tooltip>
              </Typography>
              {
                adminSide && task?.cognitoforms_response
                && (
                  <Box sx={{
                    width: '100%',
                    mt: 3,
                    mb: 3
                  }}
                  >
                    <Typography mb={3}>
                      Files and more info available on&nbsp;
                      <a
                        target='_blank'
                        href={task?.cognitoforms_response?.adminLink}
                        rel='noreferrer'
                      >
                        cognitoforms.com
                      </a>
&nbsp;
                      <BsEyeFill
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={() => setCognitoformsAvailable(!cognitoformsAvailable)}
                      />
                    </Typography>
                    {cognitoformsAvailable
                    && (
                      <Box
                        mb={6}
                        mt={6}
                      >
                        <iframe
                          width='100%'
                          height='900px'
                          src={task?.cognitoforms_response?.adminLink}
                        >
                        </iframe>
                      </Box>
                    )}
                    {
                      formRaw && prefillCognitoform && task?.cognitoforms_response?.raw?.data
                      && <JsonTable json={task.cognitoforms_response} />
                    }
                  </Box>
                )
              }
            </>
          )}
          <Box sx={{
            display: formRaw ? 'none' : 'block'
          }}
          >
            <CognitoFormMemo />
          </Box>
        </Box>
      )}
      {
        !isCommentsEnabled && isTaskUnblocked && !isTaskReadOnly
        && (
          <ContainerQuill>
            <ReactQuill
              theme='snow'
              value={taskContent.response_html}
              style={{
                borderRadius: '30px'
              }}
              onChange={(val) => setTaskContent((draft) => { draft.response_html = val })}
            />
          </ContainerQuill>
        )
      }
      {
        (isTaskCompleted && task.response_html)
        && (
          <>
            <Typography
              color='text.secondary'
              mb={2}
            >
              {t('user_tasks.response')}
            </Typography>
            <PreviewRichContent content={task.response_html} />
          </>
        )
      }

      {
        !isCommentsEnabled && isTaskUnblocked && !isTaskReadOnly
        && (
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            flexDirection={{
              xs: 'row',
              sm: 'row'
            }}
            sx={{
              mb: 4,
              mt: 4
            }}
          >
            <Grid sx={{
              order: {
                xs: 6,
                sm: 6
              }
            }}
            >
              {
                !cognitoFormAvailable
                // Hide the draft button when there is a form available, it is confusing because this button only works for the text editor
                && (
                  <Button
                    disabled={mutationLoading ?? taskContent.response_html === ''}
                    onClick={async () => await submitReply({
                      isDraft: true
                    })}
                  >
                    {' '}
                    {t('user_tasks.action.saveDraft')}
                  </Button>
                )
              }
            </Grid>
            <Grid sx={{
              order: {
                xs: 6,
                sm: 6
              }
            }}
            >
              <Button
                disabled={mutationLoading ?? cogniftoFormShouldBeCompleted}
                onClick={async () => await submitReply({
                  isDraft: false
                })}
              >
                {' '}
                {adminSide ? 'Complete Task' : t('user_tasks.action.send')}
                {' '}

              </Button>
            </Grid>
          </Grid>
        )
      }

      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid
          item
          xs={12}
          md={9}
          sm={12}
          lg={6}
          sx={{
            textAlign: 'center',
            mt: 8
          }}
        >
          {
            task.userByCreatedBy
            && (
              <ChipUser
                user={task.userByCreatedBy}
                sx={{
                  m: 1
                }}
              />
            )
          }
          {
            adminSide
            && (
              <Chip.Basic
                label={previewMode ? 'Preview' : getTextFromStatus(task?.status)}
                color={getColorFromStatus(task?.status)}
                sx={{
                  m: 1
                }}
              />
            )
          }
          {
            task.created_at
            && (
              <Chip.Basic
                label={formatDateV2(task.created_at)}
                sx={{
                  m: 1
                }}
              />
            )
          }
          {
            adminSide
            && (
              <Btn.IconButton.Delete
                isDeleted={task.deleted}
                onClick={() => {
                  if (!task.deleted) {
                    setOpenDeleteDialog(true)
                  }
                }}
              />
            )
          }
        </Grid>
      </Grid>
      <DividerExtended sx={{
        mt: 2
      }}
      />
      {
        task.id && userId
        && (
          <Box>
            <CommentsThread
              syncWithLinear={!!task.linear_issue_id}
              receiverUserId={userId === task.bo_id ? task.created_by : task.bo_id}
              entityId={task.id}
              entityType='user_tasks'
              isThreadClosed={isTaskCompleted || !isTaskUnblocked}
              scrollToLastComment={scrollToLastComment}
            />
          </Box>
        )
      }
    </Box>
  )
}
