import styled from '@emotion/styled'

export const PreviewModeLock = styled('div')`
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.4;
  position: absolute;
  z-index: 10;
`
