/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useContext } from 'react'
import { useLocation } from 'react-router-dom'

import AppContext from '#app/contexts/AppContext'
import { cn } from '#app/v2/libs/utils'
import { LoadingScreen } from '#app/v2/pages/LoadingScreen/page'

import { AnimatedOutlet } from './AnimatedOutlet'
import { MobileContainer } from './MobileContainer'

export const BaseLayout = () => {
  const { pathname } = useLocation()
  const { appState } = useContext(AppContext)

  if (appState?.error) {
    return <></> // Please got to "ErrorScreenAgnostic" in frontend/src/contexts/AppContext/index.tsx
  }

  return (
    <MobileContainer fullScrenLoading={appState.isLoading}>
      <LoadingScreen visible={appState.isLoading} />
      <div
        id='base-layout-outlet'
        className={cn('flex h-full grow flex-col bg-[#F9F9F9]', {
          'bg-white': pathname === '/v2/activity-center',
          hidden: appState.isLoading
        })}
      >
        <AnimatedOutlet />
      </div>
    </MobileContainer>
  )
}
