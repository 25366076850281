import { KeyboardEvent, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BiChevronDown } from 'react-icons/bi'
import { parse } from 'date-fns'
import { Field, FieldProps, Form, Formik } from 'formik'
import { Drawer } from 'vaul'
import * as Yup from 'yup'

import { CalendarEdit, HelpQuestion1 } from '#app/v2/assets/Icons'
import { PageWrapper } from '#app/v2/components/PageWrapper'
import { Button } from '#app/v2/components/ui/button'
import { Calendar } from '#app/v2/components/ui/calendar'
import { DrawerClose, DrawerContent, DrawerTrigger } from '#app/v2/components/ui/drawer'
import { Input } from '#app/v2/components/ui/input'
import { Label } from '#app/v2/components/ui/label'
import { Popover, PopoverContent, PopoverTrigger } from '#app/v2/components/ui/popover'
import { RadioGroup, RadioGroupItem } from '#app/v2/components/ui/radio-group'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '#app/v2/components/ui/select'
import { Switch } from '#app/v2/components/ui/switch'
import { cn, generateOnChange } from '#app/v2/libs/utils'

interface Props {
  clients?: string[]
  jumps?: number[]
}

const PaymentsSchema = Yup.object().shape({
  client: Yup.string().required(),
  mount: Yup.number().positive().required(),
  dueDate: Yup.string().required()

})

export const Payments = ({ clients, jumps = [0, 5, 7, 10] }: Props) => {
  const { t } = useTranslation()

  const dueDates = useMemo(() => {
    const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000
    const currentDate = new Date()
    return jumps.map((jump) => {
      const newDate = new Date(currentDate.getTime() + jump * MILLISECONDS_IN_A_DAY)
      return {
        jump: jump,
        date: newDate.toLocaleDateString(),
      }
    })
  }, [jumps])

  return (
    <PageWrapper
      pathname='/v2/payments'
      className='space-y-4 p-5 pb-24'
    >
      <section className='space-y-4'>
        <h1 className='font-semibold'>{t('v2.payments.title', 'Receive payments from your customers.')}</h1>
        <p className=' font-light'>
          <Trans
            i18nKey='v2.payments.description'
            defaults='Click the button below and <bold>{{strong}}</bold> to start collecting payments. Your payments will be reflected on your bank account balance, the transaction can take up to 5 days to show up in your transaction history.'
            values={{
              strong: t('v2.payments.descriptionStrong', 'share a link with your customers')
            }}
            components={{
              bold: <strong className='font-medium' />
            }}
          />
        </p>
      </section>
      <Formik
        validationSchema={PaymentsSchema}
        initialValues={{
          client: '',
          mount: '',
          dueDate: '',
          enabledTips: false,
          notes: ''
        }}
        onSubmit={() => { }}
      >
        {(props) => {
          const { errors, touched, values, initialValues, isValid } = props
          return (
            <Form
              className='space-y-5'
            >
              <h2 className='font-medium text-[#37528F]'>{t('v2.payments.form.title', 'Complete the optional information below')}</h2>
              <section className='space-y-4'>
                <Field
                  name='client'
                >
                  {({
                    field,
                  }: FieldProps) => (
                    <Select
                      {...field}
                      value={field.value}
                      onValueChange={generateOnChange(field.name, field.onChange)}
                    >
                      <SelectTrigger className={cn('w-full transition-colors', {
                        ' border-red-500 text-red-400': errors.client && touched.client,
                      })}
                      >
                        <SelectValue
                          placeholder={t('v2.payments.form.selectClient', 'Select a client')}

                        />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {clients && clients.map((client, i) => (
                            <SelectItem
                              key={client + i}
                              value={client}
                              onSelect={(event) => event.stopPropagation()}
                            >
                              {client}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>

                  )}
                </Field>
                <div className='relative'>
                  <span className={cn('absolute bottom-0 left-3 top-0 flex items-center text-gray-800 opacity-0 transition-opacity', {
                    'opacity-100': values.mount !== initialValues.mount
                  })}
                  >
                    $
                  </span>
                  <Input
                    placeholder={t('v2.payments.form.addAmount', 'Add amount')}
                    className={cn('transition-colors placeholder:text-gray-800', {
                      'pl-6': values.mount !== initialValues.mount,
                      'focus-visible:ring-red-500 border-red-500 placeholder:text-red-400': errors.mount && touched.mount,
                    })}
                    name='mount'
                    type='number'
                    step='0.01'
                    min={1}
                    onKeyDown={(e: KeyboardEvent) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault()
                      }
                    }}
                  />
                </div>
                <Field
                  name='dueDate'
                >
                  {({
                    field,
                  }: FieldProps) => {
                    const parsedDate = parse(field.value, 'dd/MM/yyyy', new Date())

                    return (
                      <Drawer.Root>
                        <DrawerTrigger asChild>
                          <Button
                            variant='outline'
                            className={cn('w-full justify-between border-gray-200 px-3 font-normal text-gray-800 transition-colors', {
                              'border-red-500 text-red-400': (errors.dueDate && touched.dueDate) || (touched.dueDate && !values.dueDate),
                            })}
                          >
                            {field.value === dueDates[0].date
                              ? `${t('v2.general.expiration', 'Expiration')} ${t('v2.payments.form.dueReceipt', 'on the date of issue')}`
                              : dueDates.findIndex((item) => item.date === field.value) !== -1
                                ? `${t('v2.general.expiration', 'Expiration')} ` + `${t('v2.payments.form.nextDaysSelect', 'In {{days}} days', {
                                  days: dueDates[dueDates.findIndex((item) => item.date === field.value)].jump
                                })} (${field.value})`.toLowerCase()
                                : field.value
                                  ? `${t('v2.general.expiration', 'Expiration')}: ${field.value}`
                                  : t('v2.payments.form.dueDate', 'Due date')}
                            <BiChevronDown className={cn('size-4 opacity-50')} />
                          </Button>

                        </DrawerTrigger>

                        <DrawerContent className='space-y-4 p-5'>
                          <RadioGroup
                            className='space-y-4'
                            {...field}
                            id={field.name}
                            value={field.value}
                            onValueChange={generateOnChange(field.name, field.onChange)}
                          >
                            {dueDates.map((item, i) => (
                              <DrawerClose
                                key={i}
                                asChild
                              >
                                <div
                                  className='flex items-center space-x-2'
                                >
                                  <RadioGroupItem
                                    value={item.date}
                                    id={`next-${item.jump}-days-radioinput`}
                                  />
                                  <Label htmlFor={`next-${item.jump}-days-radioinput`}>
                                    {i === 0
                                      ? t('activityCenter.groups.today', 'Today')
                                      : t('v2.payments.form.nextDaysSelect', 'In {{days}} days', {
                                        days: item.jump
                                      })}
                                  </Label>
                                </div>
                              </DrawerClose>
                            ))}
                          </RadioGroup>
                          <Drawer.NestedRoot>
                            <DrawerTrigger asChild>
                              <RadioGroup
                                {...field}
                                id={field.name}
                                value={field.value}
                                className='flex items-center'
                                onValueChange={generateOnChange(field.name, field.onChange)}
                              >
                                <RadioGroupItem
                                  id='custom-radio-date'
                                  className={cn('hidden', {
                                    block: field.value && dueDates.findIndex((item) => item.date === field.value) === -1
                                  })}
                                  value={field.value && dueDates.findIndex((item) => item.date === field.value) === -1 ? field.value : t('v2.payments.form.customDate', 'Custom')}
                                />
                                <Label
                                  id='custom-label-date'
                                  className={cn('hidden', {
                                    block: field.value && dueDates.findIndex((item) => item.date === field.value) === -1
                                  })}
                                >
                                  {field.value && dueDates.findIndex((item) => item.date === field.value) === -1 && field.value}
                                </Label>
                                <Button
                                  id='custom-button-date'
                                  variant='default'
                                  className={cn('w-fit gap-2 px-6 py-3', {
                                    'p-2 rounded-full': field.value && dueDates.findIndex((item) => item.date === field.value) === -1 && field.value
                                  })}
                                >
                                  <CalendarEdit className='size-5' />
                                  {dueDates.findIndex((item) => item.date === field.value || !field.value) !== -1 && t('v2.payments.form.customDate', 'Custom')}
                                </Button>
                              </RadioGroup>
                            </DrawerTrigger>
                            <DrawerContent>
                              <DrawerClose>
                                <Calendar
                                  className='mx-auto max-w-fit'
                                  mode='single'
                                  {...field}
                                  selected={parsedDate}
                                  disabled={(date: Date) =>
                                    date <= new Date()}
                                  onSelect={generateOnChange(field.name, field.onChange)}
                                />
                              </DrawerClose>
                            </DrawerContent>
                          </Drawer.NestedRoot>
                        </DrawerContent>
                      </Drawer.Root>
                    )
                  }}
                </Field>
              </section>
              <section className='space-y-4'>
                <h2>{t('v2.payments.form.enableTips.question', 'Would you like to enable tips?')}</h2>

                <Field
                  name='enabledTips'
                >
                  {({ field }: FieldProps) => (

                    <div className='flex items-center space-x-2'>
                      <Switch
                        checked={field.checked}
                        id='tips-mode'
                        className='h-6 w-11 *:size-5'
                        type='button'

                        onCheckedChange={generateOnChange(field.name, field.onChange)}
                      />
                      <Label
                        htmlFor='tips-mode'
                        className='text-sm font-medium text-[#454545]'
                      >
                        {t('v2.payments.form.enableTips.label', 'Enable tips')}
                      </Label>
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            className='rounded-full p-1'
                            variant='ghost'
                            type='button'
                          >
                            <HelpQuestion1 className='size-5 text-[#828282]' />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <p>{t('v2.payments.form.enableTips.tooltip', 'Allow your clients to give you tips')}</p>
                        </PopoverContent>
                      </Popover>
                    </div>
                  )}
                </Field>
                <Input
                  name='notes'
                  rows={4}
                  className='h-auto placeholder:text-gray-800'
                  as='textarea'
                  placeholder={t('v2.payments.form.addNote', 'Add note')}
                />
              </section>
              <Button
                type='submit'
                disabled={!isValid || !values.dueDate}
              >
                {t('v2.payments.form.submit', 'Create payment link')}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </PageWrapper>
  )
}
