/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Form } from 'formik'
import { capitalize } from 'lodash'

import Button from '#app/components/Buttons/Button'
import InputWithChips from '#app/components/InputWithChips/InputWithChips'
import InputWithPlaces from '#app/components/InputWithPlaces/InputWithPlaces'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

import FormInput from '../../components/FormInput/FormInput'

export interface PublicProps {
  isSubmitting: boolean
}

const CreateDemoSite = ({ isSubmitting }: PublicProps) => {
  const { t } = useTranslation()
  const isDesktop = useIsDesktopSize()
  const recurringOptions = [
    'Cleaning',
    'Painting',
    'Electrical',
    'Plumbing',
    'Landscaping',
    'Appliance Repair',
    'Carpentry',
    'Countertop Pro',
    'Fencing',
    'Flooring',
    'Foundations Pro',
    'Garage Door Pro',
    'General Contractor',
    'Handyman',
    'Home Inspector',
    'Home Security',
    'Home Theater Pro',
    'HVAC',
    'Junk Removal',
    'Trash Removal',
    'Lawn Care',
    'Locksmith',
    'Mover',
    'Pest Control',
    'Pool Cleaning',
    'Pool Contractor',
    'Remodeling',
    'Roofing',
    'Sewage System',
    'Siding Pro',
    'Snow Removal',
    'Tree Service',
    'Water Damage Service',
    'Window Service',
    'Demolition',
    'Remodeling and Renovation',
    'Moving Services'
  ]

  const gridStyle = isDesktop ? 6 : 12
  const divStyle = isDesktop
    ? {
      display: 'flex',
      justifyContent: 'flex-end'
    }
    : {
      display: 'flex',
      justifyContent: 'center'
    }
  const buttonStyle = isDesktop
    ? {
      width: '30%'
    }
    : {
      width: '95%'
    }
  const sx = {
    backgroundColor: '#F1F5F9',
    border: '1px solid #F1F5F9',
    borderRadius: '10px'
  }

  return (
    <Form>
      <div>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={gridStyle}
            justifyContent='center'
          >
            <FormInput
              name='name'
              label={capitalize(t('generic.fullName'))}
              type='text'
              sx={sx}
            />
          </Grid>
          <Grid
            item
            xs={gridStyle}
          >
            <FormInput
              name='companyName'
              type='text'
              label='Company Name'
              sx={sx}
            />
          </Grid>
          <Grid
            item
            xs={gridStyle}
          >
            <FormInput
              name='personalEmail'
              type='email'
              label='Personal Email'
              sx={sx}
            />
          </Grid>
          <Grid
            item
            xs={gridStyle}
          >
            <FormInput
              name='color1'
              type='color'
              label='Color 1'
              sx={sx}
            />
          </Grid>
          <Grid
            item
            xs={gridStyle}
          >
            <InputWithChips
              name='recurringCategory'
              options={recurringOptions}
              label='Recurring Category'
              sx={sx}
            />
          </Grid>
          <Grid
            item
            xs={gridStyle}
          >
            <InputWithPlaces
              multiple
              name='serviceAreasNames'
              label='Service Areas Names'
              sx={sx}
              types={['locality']}
              fields={['name']}
              countries={undefined}
              limitTags={5}
            />
          </Grid>
        </Grid>
      </div>
      <div
        className='div-modal-input mt-4'
        style={divStyle}
      >
        <Button
          disabled={isSubmitting}
          isLoading={isSubmitting}
          style={buttonStyle}
          type='submit'
        >
          Create Site
        </Button>
      </div>
    </Form>
  )
}
export default CreateDemoSite
