/* eslint-disable @typescript-eslint/naming-convention */
import { BsArrowRepeat, BsHourglassSplit } from 'react-icons/bs'
import { Box } from '@mui/material'

import { THEME } from '#app/layouts/theme'

export const sxIconLoading = {
  marginLeft: '5px',
  animation: 'spin 2s linear infinite',
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(360deg)'
    },
    '100%': {
      transform: 'rotate(0deg)'
    }
  }
}

export function IconLoop() {
  return (
    <Box sx={sxIconLoading}>
      <BsArrowRepeat fontSize={THEME.ICONS.size.xs} />
    </Box>
  )
}

export function IconInQueue() {
  return (
    <Box sx={sxIconLoading}>
      <BsHourglassSplit fontSize={THEME.ICONS.size.xs} />
    </Box>
  )
}
