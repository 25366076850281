import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import ProfileContext from '#app/contexts/ProfileContext'
import { Logout1 } from '#app/v2/assets/Icons'
import { CenteredNavTitle } from '#app/v2/components/CenteredNavTitle'
import { HeaderSharedNavigation } from '#app/v2/components/HeaderSharedNavigation'
import { TaskList } from '#app/v2/pages/TaskList/page'
import { useGetShortcuts } from '#app/v2-core/hooks/useGetShortcuts'
import { useUserTasks } from '#app/v2-core/hooks/useUserTasks'
import { useAppContextV2 } from '#app/v2-core/layouts/useAppContextV2'

export function CoreTaskList() {
  const { todoTasks, doneTasks, loading } = useUserTasks(true)
  const { V2_SET_LOADING, V2_LOGOUT } = useAppContextV2()
  const { t } = useTranslation()

  const profileData = useContext(ProfileContext)
  const { shortcuts, extraShortcuts } = useGetShortcuts()
  const logout = {
    label: t('v2.general.logout', 'Log out'),
    icon: Logout1,
    onClick: V2_LOGOUT
  }
  useEffect(() => {
    if (!loading) {
      V2_SET_LOADING(false)
    }
  }, [loading, V2_SET_LOADING])

  return (
    <>
      <HeaderSharedNavigation
        profile={{
          avatar: profileData?.getProfile?.user?.profile_picture_url,
          clientId: profileData?.getProfile.altBoProfile?.company_autonumber,
          fullName: `${profileData?.getProfile?.user?.first_name ?? ''} ${profileData?.getProfile?.user?.last_name ?? ''}`,
          services: profileData?.getProfile?.altBoProfile?.services,
          editProfileUrl: '/v2/edit-profile',
          shortcuts: shortcuts,
          extraShortcuts: extraShortcuts,
          logout: logout
        }}
        main={<CenteredNavTitle title={t('v2.nav.tasks', 'Tasks')} />}
      />
      <TaskList
        tasks={todoTasks}
        doneTask={doneTasks}
        onClickURL='/v2/task-detail/{{task_id}}'
      />
    </>
  )
}
