/* eslint-disable @typescript-eslint/naming-convention */

import * as React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { SxProps } from '@mui/material'
import Button from '@mui/material/Button'
import Menu, { MenuProps } from '@mui/material/Menu'
import { alpha, styled } from '@mui/material/styles'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}))

interface Props {
  color?: 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined
  primary?: boolean
  secondary?: boolean
  disabled?: boolean
  variant?: 'outlined' | 'contained'
  style?: React.CSSProperties
  label?: string
  isLoading?: boolean
  children: React.ReactNode
  sx?: SxProps
}

export const ButtonWithMenu = ({
  color,
  primary = true,
  secondary,
  disabled,
  variant = 'contained',
  isLoading = false,
  style,
  label,
  children,
  sx
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        disableElevation
        id='customized-button'
        aria-controls={open ? 'customized-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        size='large'
        endIcon={<KeyboardArrowDownIcon />}
        variant={variant}
        disabled={disabled}
        style={style}
        sx={sx}
        color={color ?? (secondary ? 'secondary' : primary ? 'primary' : 'secondary')}
        onClick={handleClick}
      >
        {
          isLoading
            ? (
              <span
                className='spinner-border spinner-border-sm'
                role='status'
                aria-hidden='true'
              >
              </span>
            )
            : label
        }
      </Button>
      <StyledMenu
        id='customized-menu'
        MenuListProps={{
          'aria-labelledby': 'customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
          React.Children.map(children, (child) => {
          // @ts-ignore
            return React.cloneElement(child, {
            // @ts-ignore
              disableRipple: true,
              onClick: () => {
                handleClose()
                // @ts-ignore
                child?.props?.onClick?.()
              }
            })
          })
        }
      </StyledMenu>
    </>
  )
}
