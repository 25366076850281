/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useMemo } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import PhoneNumberInput from '#app/components/PhoneNumberInput/PhoneNumberInput'

import Button from '../../components/Buttons/Button'
import FormInput from '../../components/FormInput/FormInput'
import ProfileContext from '../../contexts/ProfileContext'
import useMutationWithNotification from '../../hooks/useMutationWithNotification'// '#app/hooks/useMutationWithNotification'
import { ADD_USER } from '../../utils/graphqlQueries'

interface Props {
  submitCallback: (result: any, error?: any) => void
  ignorePhoneNumber?: boolean
}

interface OnSubmit {
  first_name: string
  last_name: string
  password: string
  email: string
}

const CreateUser = ({ submitCallback, ignorePhoneNumber, ...props }: Props) => {
  const { roles } = useContext(ProfileContext)
  // TOOD: Move roles to custom hook
  const adminRole = roles.find(({ slug }: { slug: string }) => slug === 'owners-admin')

  const [addUser] = useMutationWithNotification(ADD_USER)

  const onSubmit = async (data: OnSubmit, { resetForm }: any) => {
    const payload = {
      role_id: adminRole?.id,
      ...data
    }

    try {
      const result: any = await addUser({
        variables: payload
      })

      submitCallback(result)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      resetForm()
    }
    catch (err) {
      submitCallback(undefined, err)
    }
  }

  const initialValues = {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    password: ''
  }

  const schemaConfig = useMemo(() => {
    const res: any = {
      first_name: Yup
        .string()
        .required('This is a required field'),
      last_name: Yup
        .string()
        .required('This is a required field'),
      email: Yup
        .string()
        .email('Invalid email')
        .required('This is a required field'),
      password: Yup
        .string()
        .required('This is a required field')
    }

    if (!ignorePhoneNumber) {
      res.phone = Yup
        .string()
        .required('This is a required field')
        .test('isValidPhoneNumber', 'Phone number is invalid',
        // @ts-expect-error ---
          (value) => {
            if (value) {
              return isValidPhoneNumber(value)
            }
          })
    }

    return res
  }, [ignorePhoneNumber])

  const schema = Yup
    .object()
    .shape(schemaConfig)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      {...props}
    >
      {({ isSubmitting }) => (
        <Form className='row g-3 g-md-4'>
          <Typography variant='h4'>Create User</Typography>
          <div className='col-md-12'>
            <FormInput
              name='first_name'
              label='First name'
              type='text'
            />
          </div>
          <div className='col-md-12'>
            <FormInput
              name='last_name'
              label='Last name'
              type='text'
            />
          </div>
          <div className='col-md-12'>
            <FormInput
              name='email'
              type='email'
              label='Email'
            />
          </div>
          {!ignorePhoneNumber
          && (
            <div className='col-md-12'>
              <PhoneNumberInput
                label='Phone Number'
                name='phone'
              />
            </div>
          )}
          <div className='col-md-12'>
            <FormInput
              name='password'
              type='text'
              label='Password'
            />
          </div>
          <div className='div-modal-input mt-4'>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type='submit'
            >
              Sign Up
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default CreateUser
