import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { NotificationsProviderV2 } from '#app/contexts/NotificationsProvider'
import { LoadingScreen } from '#app/v2/pages/LoadingScreen/page'

export const StoryLoadingScreen = () => {
  const [searchParams] = useSearchParams()

  const storyProps = useMemo(() => {
    const scenario = searchParams.get('scenario')

    const res = {
      visible: true,
      error: false,
      errorText: ''
    }

    switch (scenario) {
      case 'error':
        res.error = true
        res.errorText = 'Wow, something went bad'
        break
    }

    return res
  }, [searchParams])

  return (
    <div className=''>
      <NotificationsProviderV2>
        <LoadingScreen
          visible={storyProps.visible}
          error={storyProps.error}
          errorText={storyProps.errorText}
        />
      </NotificationsProviderV2>
    </div>
  )
}
