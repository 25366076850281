import Images from '#app/v2/assets/Images'

export const data = {
  profile: {
    avatar: Images.SamBOProfile,
    firstName: 'Hector',
    lastName: 'Hustler',
    email: 'hector.h@gmail.com',
    phoneNumbers: ['6121234567'],
    services: ['Handyman', 'Landscaping', 'Cleaning', 'Cleaning2', 'Cleaning3'],
  },
}
