/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BsArrowClockwise } from 'react-icons/bs'
import { useLazyQuery } from '@apollo/client'
import { Grid, Stack, Typography } from '@mui/material'

import { Btn } from '#app/components/Buttons'
import { EntityPage } from '#app/layouts/EntityView'
import { GET_PROFILE, GET_PROFILE_TYPE } from '#app/utils/graphqlQueries'

export const Configuring = () => {
  const { t } = useTranslation()

  const [query, { data, loading, startPolling }] = useLazyQuery<GET_PROFILE_TYPE>(GET_PROFILE, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    startPolling(3000)
  }, [])

  useEffect(() => {
    if (data && !loading) {
      const validBusinessStatuses = ['onboarded', 'onboarding']
      if (data.getProfile.user?.status === 'signed-up' && validBusinessStatuses.includes(data.getProfile?.user?.businesses?.[0]?.status)) {
        window.location.reload()
      }
    }
  }, [data, loading])

  const onClick = () => {
    void query()
      .then((data) => {
        const validBusinessStatuses = ['onboarded', 'onboarding']
        if (data?.data?.getProfile.user?.status === 'signed-up' && validBusinessStatuses.includes(data?.data?.getProfile?.user?.businesses?.[0]?.status)) {
          window.location.reload()
        }
      })
  }

  return (
    <EntityPage
      isLoading={false}
      layout='one-column'
      pageTitle={t('configuring.title', 'We are configuring your account')}
      mainContent={(
        <>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <Stack
                spacing={2}
                sx={{
                  maxWidth: 400
                }}
              >
                <Typography>
                  {t('configuring.description', 'We are finishing some details, please wait on this page for a few seconds or click the refresh button down below...')}
                </Typography>
                <Btn.Button
                  startIcon={<BsArrowClockwise />}
                  onClick={onClick}
                >
                  Refresh
                </Btn.Button>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
    />
  )
}
