const Css = {
  jsonTr: {
    height: '25px'
  },
  jsonTd: {
    padding: '5px',
    borderSpacing: '2px',
    borderRadius: '5px'
  },
  rowSpacer: {
    height: '2px'
  },
  rootElement: {
    padding: '10px',
    borderSpacing: '2px',
    backgroundColor: '#365194',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '5px'
  },
  subElement: {
    padding: '5px',
    borderSpacing: '2px',
    backgroundColor: '#DDDDDD',
    fontWeight: 'bold',
    borderRadius: '5px'
  },
  dataCell: {
    borderSpacing: '2px',
    backgroundColor: '#F1F1F1',
    borderRadius: '5px'
  }
}

export default Css
