import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { CenteredNavTitle } from '#app/v2/components/CenteredNavTitle'
import { HeaderSharedNavigation } from '#app/v2/components/HeaderSharedNavigation'
import { NavigationBottom } from '#app/v2/components/NavigationBottom'
import { modalShortcuts, profileStoryData } from '#app/v2/pages/MyProfile/data'

import { searchData } from './data'
import { SearchBubbleUp } from './page'

export const StorySearchBubble = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const storyProps = useMemo(() => {
    const scenario = searchParams.get('scenario')

    const res = {
      myProfile: {
        clientId: profileStoryData.profile.clientId,
        avatar: profileStoryData.profile.image,
        fullName: profileStoryData.profile.fullName,
        services: profileStoryData.profile.services,
        editProfileUrl: profileStoryData.editProfileUrl,
        shortcuts: modalShortcuts.shortcuts,
        extraShortcuts: modalShortcuts.extraShortcuts,
        logout: profileStoryData.logout
      },
      search: {
        lang: searchData.lang
      },
      bottomNavigation: {
        ...profileStoryData.bottomNavigation
      }
    }

    switch (scenario) {
      default:
        break
    }

    return res
  }, [searchParams])
  return (
    <div className='min-h-screen bg-[#F7F9FB]'>
      {/* HEADER */}
      <HeaderSharedNavigation
        profile={storyProps.myProfile}
        main={<CenteredNavTitle title={t('v2.nav.search', 'Search')} />}
      />
      <SearchBubbleUp {...storyProps.search} />
      <NavigationBottom {...storyProps.bottomNavigation} />
    </div>
  )
}
