import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { CenteredNavTitle } from '#app/v2/components/CenteredNavTitle'
import { HeaderSharedNavigation } from '#app/v2/components/HeaderSharedNavigation'
import { NavigationBottom } from '#app/v2/components/NavigationBottom'
import { data } from '#app/v2/pages/TaskList/data'
import { TaskList } from '#app/v2/pages/TaskList/page'

export const StoryTaskList = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const storyProps = useMemo(() => {
    const scenario = searchParams.get('scenario')

    const res = {
      profile: data.profile,
      tasks: data.todo,
      doneTask: data.done,
    }

    switch (scenario) {
      case 'completed_todos':
        res.tasks = []
        break
      case 'empty_done_tasks':
        res.doneTask = []
        break
    }

    return res
  }, [searchParams])
  return (
    <div className='bg-[#F7F9FB]'>
      {/* HEADER */}
      <HeaderSharedNavigation
        profile={storyProps.profile}
        main={<CenteredNavTitle title={t('navigations.tasks', 'Tasks')} />}
      />
      <TaskList
        tasks={storyProps.tasks}
        doneTask={storyProps.doneTask}
        onClickURL='/v2-ui/task-detail/{{task_id}}'
      />
      <NavigationBottom
        routes={{
          home: '/v2-ui/home',
          messages: '/v2-ui/messages',
          tasks: '/v2-ui/tasks',
          business: '/v2-ui/business',
        }}
        isIOS={false}
        pendingMessages={0}
      />
    </div>
  )
}
