/* eslint-disable @typescript-eslint/no-invalid-void-type */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Stack, Typography } from '@mui/material'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { DialogConfirm } from '#app/components/Dialog/DialogConfirm'
import { CreatePublicCustomer } from '#app/forms/CustomersManagement/CreateCustomer'
import { CustomerInput } from '#app/types/customer'
import { MUTATION } from '#app/utils/graphqlQueries'
import { getOnlyDefined } from '#app/utils/vanilla/helpers'
export const PublicCustomerManagement = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: ''
  }

  const [createCustomer] = useMutation(MUTATION.customers.createPublicCustomer)

  const { t } = useTranslation()

  const { userId } = useParams()

  const onSubmit = async (data: CustomerInput, { resetForm }: any) => {
    const payload = getOnlyDefined({
      user_id: userId,
      lead: 'customer-form',
      ...data
    })
    try {
      const result: any = await createCustomer({
        variables: payload
      })
      const created = result.data.createPublicCustomer.result
      if (created) {
        setIsOpen(true)
        resetForm()
      }
    }
    catch (err) { /* empty */ }
  }

  const closePopUp = () => {
    setIsOpen(false)
  }
  const schema = Yup
    .object()
    .shape({
      first_name: Yup
        .string().required(t('validation.required')),
      last_name: Yup
        .string(),
      email: Yup
        .string()
        .email(t('validation.invalidEmail')),
      phone: Yup
        .string()
        .test('validNumber', t('validation.invalidPhone'),
          // @ts-ignore
          (value) => {
            if (value) {
              return isValidPhoneNumber(value)
            }
            else {
              return true
            }
          }).required(t('validation.required')),
      address: Yup.object({
        description: Yup.string(),
        matched_substrings: Yup.array(),
        place_id: Yup.string(),
        reference: Yup.string(),
        structured_formatting: Yup.object({
          main_text: Yup.string(),
          main_text_matched_substrings: Yup.array(),
          secondary_text: Yup.string()
        }).nullable(),
        terms: Yup.array(),
        types: Yup.array()
      }).nullable()
    })

  return (
    <Stack spacing={3}>
      <Typography>
        We'd love to connect with you and discuss how we can help you with your needs.
        Could you please fill out the quick form below? We promise to get back to you as soon as possible.
        Thanks!
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <CreatePublicCustomer isSubmitting={isSubmitting ?? undefined} />
        )}
      </Formik>
      <DialogConfirm
        withoutCancel
        open={isOpen}
        setOpen={setIsOpen}
        texts={{
          title: t('customers.createCustomer.publicThank'),
          description: t('customers.createCustomer.publicDescription'),
          confirm: t('customers.createCustomer.close')
        }}
        isLoading={false}
        onConfirm={() => closePopUp()}
      />
    </Stack>
  )
}
