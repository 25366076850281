import { ComponentType, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, useNavigate, useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { Box, Drawer, Stack, Toolbar, Typography } from '@mui/material'
import { Field, Formik } from 'formik'
import { ChannelFilters, ChannelSort } from 'stream-chat'
import { AvatarProps, ChannelList, useChatContext } from 'stream-chat-react'
import { useImmer } from 'use-immer'

import { Alert } from '#app/components/Alerts'
import { Chip } from '#app/components/Chip'
import { DividerExtended } from '#app/components/Divider'
import FormSelect from '#app/components/FormSelect/FormSelect'
import ProfileContext from '#app/contexts/ProfileContext'
import { GET_PROFILE } from '#app/utils/graphqlQueries'
import { ProfileAvatarDetailed } from '#app/v2/components/ProfileAvatarDetailed'

const Avatar = ({ image }: { image: string }) => {
  return (
    <ProfileAvatarDetailed
      onlyAvatar
      profilePictureUrl={image}
    />
  )
}

export function DrawerChannelList() {
  const { getProfile: { user } } = useContext(ProfileContext)

  const { userId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { channel, setActiveChannel } = useChatContext()

  const [loadOwner, { data }] = useLazyQuery(GET_PROFILE, {
    variables: {
      userId
    }
  })

  useEffect(() => {
    if (userId) loadOwner()
  }, [userId, loadOwner])

  useEffect(() => {
    if (channel?.id) {
      if (userId) {
        navigate(`/chat/user/${userId}?chID=${channel.id}`)
      }
      else {
        navigate(`/chat?chID=${channel.id}`)
      }
    }

    return () => {
      // setActiveChannel()
      // setChannelId(null)
    }
  }, [channel?.id, setActiveChannel, navigate, userId])

  const [channelOptions, setChannelOptions] = useImmer<{ sort: ChannelSort }>({
    sort: {
      last_message_at: -1
    }
  })

  const [channelFilters, setChannelFilters] = useImmer<ChannelFilters>({
    type: 'messaging',
    members: {
      $in: [userId ?? user?.id ?? '']
    },
    channelData: {},
    // @ts-expect-error --- Stream in its engine automatically injects the member's teams, but as BO don't work correctly
    team: userId ? {} : undefined
  })

  const [showFilters, setShowFilters] = useState(false)
  const [showThirdPartyInfo, setShowThirdPartyInfo] = useState(false)

  const titleConversations = userId ? 'chat.boChats' : 'chat.myChats'
  const nameBO = userId ? `${data?.getProfile?.user?.first_name} ${data?.getProfile?.user?.last_name}` : ''

  const makeReadable = (str: string) => {
    return str.replace(/_/g, ' ')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  return (
    <Drawer
      variant='permanent'
      anchor='left'
      sx={{
        width: 390,
        flexShrink: 0,
        ['& .MuiDrawer-paper']: {
          width: 400,
          boxSizing: 'border-box',
          backgroundColor: '#F9F9F9'
        },
      }}
    >
      <Toolbar />
      <Box
        sx={{
          overflow: 'auto',
          height: '100%'
        }}
      >
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          mx={2}
        >
          <Box>
            <Typography variant='subtitle2'>
              {t(titleConversations)}
            </Typography>
          </Box>
        </Stack>
        <DividerExtended />
        <Box px={2}>
          <Box
            sx={{
              display: showFilters ? 'block' : 'none'
            }}
          >
            <Formik
              initialValues={{
                sort: 'last_message_at',
                teams: userId ? 'all' : 'my_teams',
                customer_life_cycle: 'all'
              }}
              onSubmit={() => {
                // do nothing
              }}
            >
              {() => (
                <Form>
                  <Field
                    sx={{
                      mb: 1
                    }}
                    as={FormSelect}
                    label='Sort By'
                    name='sort'
                    options={[
                      {
                        text: 'Last Message',
                        value: 'last_message_at'
                      },
                      {
                        text: 'Last Updated',
                        value: 'last_updated_at'
                      },
                      {
                        text: 'Last Created',
                        value: 'created_at'
                      },
                      {
                        text: 'Last Unread',
                        value: 'unread_count'
                      }
                    ]}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setChannelOptions((draft) => {
                        draft.sort = {
                          [e.target.value]: -1
                        }
                      })
                    }}
                  />

                  <Field
                    as={FormSelect}
                    sx={{
                      mb: 1
                    }}
                    label='Teams'
                    name='teams'
                    options={[
                      {
                        text: userId ? 'Their Teams' : 'My Teams',
                        value: 'my_teams'
                      },
                      {
                        text: 'All Teams',
                        value: 'all'
                      }
                    ]}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      const mapValues = {
                        all: {},
                        my_teams: undefined
                      }
                      // @ts-expect-error ---
                      const newValue = mapValues[e.target.value]
                      setChannelFilters((draft) => {
                        if (newValue) {
                          draft.team = newValue
                        }
                        else {
                          delete draft.team
                        }
                      })
                    }}
                  />

                  <Field
                    as={FormSelect}
                    label='Stage Life Cycle'
                    name='stage_life_cycle'
                    options={['all', 'not_processed', 'aware', 'curious', 'exploring', 'new', 'adoption', 'repeat', 'fan', 'dormant', 'churn_risk', 'churn'].map((stage) => {
                      return {
                        text: makeReadable(stage),
                        value: stage
                      }
                    })}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setChannelFilters((draft) => {
                        // @ts-expect-error ---
                        draft.channelData.stage_life_cycle = e.target.value === 'all'
                          ? undefined
                          : {
                            $eq: e.target.value
                          }
                      })
                    }}
                  />
                </Form>
              )}
            </Formik>
          </Box>
          <Stack
            direction='row'
            spacing={1}
            mt={2}
          >
            {userId
            && (
              <>
                <Chip.Basic
                  label={`${nameBO}`}
                  color='error'
                  onClick={() => setShowFilters(!showFilters)}
                />
              </>
            )}
            <Chip.Basic
              label={!showFilters ? 'Show Filters' : 'Hide Filters'}
              color='primary'
              onClick={() => setShowFilters(!showFilters)}
            />
          </Stack>
        </Box>
        <Box sx={{
          height: 'calc(100% - 90px)'
        }}
        >
          <ChannelList
            showChannelSearch
            filters={channelFilters}
            sort={channelOptions.sort}
            Avatar={Avatar as ComponentType<AvatarProps>}
            setActiveChannelOnMount={false}
          />
        </Box>
      </Box>
      {userId
      && (
        <Alert.Basic
          sx={{
            position: 'sticky',
            bottom: 0,
            width: '100%',
            cursor: 'pointer'
          }}
          severity={!showThirdPartyInfo ? 'warning' : 'warning'}
          title='Third party conversations'
          description={!showThirdPartyInfo ? 'Click here for more information' : `You are seeing all the conversations of ${nameBO}, Please keep in mind that as an administrator, you have access to all user conversations, but this does not grant you automatic participation in any channel. To join a chat and actively participate, you must first become a member of that specific channel.`}
          onClick={() => setShowThirdPartyInfo(!showThirdPartyInfo)}
        />
      )}
    </Drawer>
  )
}
