import { motion } from 'framer-motion'

import { useExpoStatus } from '../libs/utils'

import { opacityBlurVariant, pageTransition } from './animations'

interface Props {
  title: string
}

export const CenteredNavTitle = ({ title }: Props) => {
  const isExpo = useExpoStatus()
  let centeredNavTitleVariants = {}

  if (!isExpo) {
    centeredNavTitleVariants = opacityBlurVariant
  }
  return (
    <motion.p
      variants={centeredNavTitleVariants}
      transition={pageTransition}
      className='text-center text-xl/6'
    >
      {title}
    </motion.p>
  )
}
