import { HeartsSymbol } from '#app/v2/assets/Icons'
import { Checkbox } from '#app/v2/components/ui/checkbox'
import { Label } from '#app/v2/components/ui/label'
import { RadioGroupItem } from '#app/v2/components/ui/radio-group'

// Discussion init for when the component needs
interface Props {
  id?: string // id = general-contractor
  label?: string // Naming label = General contractor
  isCheckbox?: boolean // Choose isCheckbox icons (no label needed)
  onClick?: (e: any) => void
  imageUrl?: string
  isChecked?: boolean
}

export const SquareCard = ({ id, label, isCheckbox, onClick, imageUrl, isChecked }: Props) => {
  return (
    <div className='aspect-square rounded-xl bg-white text-[#454545] shadow'>
      {isCheckbox
        ? (
          <Checkbox
            value={id ? id : 'error-id'}
            id={`square-${id}`}
            className='peer sr-only'
            checked={isChecked}
            onClick={onClick}
          />
        )
        : (
          <RadioGroupItem
            value={id ? id : 'error-id'}
            id={`square-${id}`}
            className='peer sr-only'
            checked={isChecked}
            onClick={onClick}
          />
        )}
      <Label
        htmlFor={`square-${id}`}
        className='flex h-full cursor-pointer  flex-col items-center justify-around gap-1 rounded-xl border border-transparent p-4 text-center text-xs/3 font-medium transition-colors peer-data-[state=checked]:border-[#8BC53F] sm:text-sm'
      >
        {isCheckbox
          ? (
            // TODO: IconMap with assets
            imageUrl
              ? (
                <img
                  src={imageUrl}
                  alt='image-selected'
                />
              )
              : <HeartsSymbol className='h-8 w-8 shrink-0' />
          )
          : (
            <>
              {imageUrl
                ? (
                  <img
                    src={imageUrl}
                    alt='image-selected'
                  />
                )
                : <HeartsSymbol className='h-8 w-8 shrink-0' />}
              {label}
            </>
          )}
      </Label>
    </div>
  )
}
