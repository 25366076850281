import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type GetWorkOrderAttachmentsQueryVariables = Types.Exact<{
  proxyURL: Types.Scalars['String']
  workOrderId: Types.Scalars['String']
  bidId?: Types.InputMaybe<Types.Scalars['String']>
  workOrderEvidenceId?: Types.InputMaybe<Types.Scalars['String']>
}>

export type GetWorkOrderAttachmentsQuery = { __typename?: 'query_root', getWorkOrderAttachments?: { __typename?: 'GetWorkOrderAttachmentsPayload', proxyURL?: string | undefined, resolvedURL?: string | undefined, resolvedContentType?: Array<string | undefined> | undefined } | undefined }

export type PmmarketplaceServiceRequestsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Pmmarketplace_Service_Requests_Bool_Exp>
}>

export type PmmarketplaceServiceRequestsQuery = { __typename?: 'query_root', pmmarketplace_service_requests: Array<{ __typename?: 'pmmarketplace_service_requests', additional_notes?: string | undefined, id: string, oa?: string | undefined, oa_name?: any | undefined, work_orders?: any | undefined, due_date?: any | undefined, created_at?: any | undefined, record_number?: number | undefined, title?: string | undefined, status?: string | undefined, operations_agent?: { __typename?: 'pmmarketplace_operations_agents', first_name?: string | undefined, email?: string | undefined, id: string, last_name?: string | undefined, created_at?: any | undefined, created_time?: any | undefined } | undefined, work_order: Array<{ __typename?: 'pmmarketplace_work_orders', id: string, title?: string | undefined, record_number?: number | undefined, bids?: any | undefined, due_date?: any | undefined, is_follow_up?: boolean | undefined, sp_name?: any | undefined, status?: string | undefined, sp?: string | undefined, parent_work_order?: string | undefined, additional_notes?: string | undefined, service_request_status?: any | undefined, service_request?: string | undefined, created_time?: any | undefined, created_by?: any | undefined }> }> }

export type GetWorkOrderDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['String']
}>

export type GetWorkOrderDetailsQuery = { __typename?: 'query_root', getWorkOrder?: { __typename?: 'GetPublicWorkOrderPayload', work_order?: any | undefined } | undefined }

export type PmmarketplaceBidsQueryVariables = Types.Exact<{
  work_order_id: Types.Scalars['String']
}>

export type PmmarketplaceBidsQuery = { __typename?: 'query_root', pmmarketplace_bids: Array<{ __typename?: 'pmmarketplace_bids', work_order?: string | undefined, status?: string | undefined, sp?: string | undefined, record_id?: string | undefined, price?: any | undefined, id: string, estimate?: any | undefined, date?: any | undefined, created_time?: any | undefined, additional_notes?: string | undefined, work_order_string?: string | undefined, service_provider?: { __typename?: 'pmmarketplace_service_providers', first_name?: string | undefined, phone?: string | undefined, last_name?: string | undefined, email?: string | undefined, company_name?: string | undefined, address?: string | undefined } | undefined }> }

export type PmmarketplaceWorkOrdersQueryVariables = Types.Exact<{
  work_order_id: Types.Scalars['String']
}>

export type PmmarketplaceWorkOrdersQuery = { __typename?: 'query_root', pmmarketplace_work_orders: Array<{ __typename?: 'pmmarketplace_work_orders', type_service?: string | undefined, status?: string | undefined, sp_name?: any | undefined, sp?: string | undefined, service_request_status?: any | undefined, service_request?: string | undefined, record_number?: number | undefined, record_id?: string | undefined, additional_notes?: string | undefined, title?: string | undefined, parent_work_order?: string | undefined, is_follow_up?: boolean | undefined, invitations?: any | undefined, id: string, fixed_price?: any | undefined, due_date?: any | undefined, due_date_type?: string | undefined, description?: string | undefined, created_time?: any | undefined, created_by?: any | undefined, bid_winner?: string | undefined, bids?: any | undefined, access_instructions?: string | undefined, attachments?: any | undefined }> }

export type GetWorkOrderBidsPublicQueryVariables = Types.Exact<{
  workOrderId: Types.Scalars['String']
  serviceRequestId: Types.Scalars['String']
}>

export type GetWorkOrderBidsPublicQuery = { __typename?: 'query_root', getWorkOrderBids?: { __typename?: 'GetPublicWorkOrderBidsPayload', bids?: Array<any | undefined> | undefined } | undefined }

export type GetWorkOrderEvidencesQueryVariables = Types.Exact<{
  workOrderId: Types.Scalars['String']
  serviceRequestId: Types.Scalars['String']
}>

export type GetWorkOrderEvidencesQuery = { __typename?: 'query_root', getWorkOrderEvidences?: { __typename?: 'GetWorkOrderEvidencesPayload', evidences?: Array<any | undefined> | undefined } | undefined }

export const GetWorkOrderAttachmentsDocument = gql`
    query getWorkOrderAttachments($proxyURL: String!, $workOrderId: String!, $bidId: String, $workOrderEvidenceId: String) {
  getWorkOrderAttachments(
    input: {proxyURL: $proxyURL, workOrderId: $workOrderId, bidId: $bidId, workOrderEvidenceId: $workOrderEvidenceId}
  ) {
    proxyURL
    resolvedURL
    resolvedContentType
  }
}
    `

/**
 * __useGetWorkOrderAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderAttachmentsQuery({
 *   variables: {
 *      proxyURL: // value for 'proxyURL'
 *      workOrderId: // value for 'workOrderId'
 *      bidId: // value for 'bidId'
 *      workOrderEvidenceId: // value for 'workOrderEvidenceId'
 *   },
 * });
 */
export function useGetWorkOrderAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrderAttachmentsQuery, GetWorkOrderAttachmentsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetWorkOrderAttachmentsQuery, GetWorkOrderAttachmentsQueryVariables>(GetWorkOrderAttachmentsDocument, options)
}
export function useGetWorkOrderAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderAttachmentsQuery, GetWorkOrderAttachmentsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetWorkOrderAttachmentsQuery, GetWorkOrderAttachmentsQueryVariables>(GetWorkOrderAttachmentsDocument, options)
}
export type GetWorkOrderAttachmentsQueryHookResult = ReturnType<typeof useGetWorkOrderAttachmentsQuery>
export type GetWorkOrderAttachmentsLazyQueryHookResult = ReturnType<typeof useGetWorkOrderAttachmentsLazyQuery>
export type GetWorkOrderAttachmentsQueryResult = Apollo.QueryResult<GetWorkOrderAttachmentsQuery, GetWorkOrderAttachmentsQueryVariables>
export const PmmarketplaceServiceRequestsDocument = gql`
    query pmmarketplaceServiceRequests($where: pmmarketplace_service_requests_bool_exp) {
  pmmarketplace_service_requests(where: $where) {
    additional_notes
    id
    oa
    oa_name
    work_orders
    due_date
    created_at
    record_number
    operations_agent {
      first_name
      email
      id
      last_name
      created_at
      created_time
    }
    work_order {
      id
      title
      record_number
      bids
      due_date
      is_follow_up
      sp_name
      status
      sp
      parent_work_order
      additional_notes
      service_request_status
      service_request
      created_time
      created_by
    }
    title
    status
  }
}
    `

/**
 * __usePmmarketplaceServiceRequestsQuery__
 *
 * To run a query within a React component, call `usePmmarketplaceServiceRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePmmarketplaceServiceRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePmmarketplaceServiceRequestsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePmmarketplaceServiceRequestsQuery(baseOptions?: Apollo.QueryHookOptions<PmmarketplaceServiceRequestsQuery, PmmarketplaceServiceRequestsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<PmmarketplaceServiceRequestsQuery, PmmarketplaceServiceRequestsQueryVariables>(PmmarketplaceServiceRequestsDocument, options)
}
export function usePmmarketplaceServiceRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PmmarketplaceServiceRequestsQuery, PmmarketplaceServiceRequestsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<PmmarketplaceServiceRequestsQuery, PmmarketplaceServiceRequestsQueryVariables>(PmmarketplaceServiceRequestsDocument, options)
}
export type PmmarketplaceServiceRequestsQueryHookResult = ReturnType<typeof usePmmarketplaceServiceRequestsQuery>
export type PmmarketplaceServiceRequestsLazyQueryHookResult = ReturnType<typeof usePmmarketplaceServiceRequestsLazyQuery>
export type PmmarketplaceServiceRequestsQueryResult = Apollo.QueryResult<PmmarketplaceServiceRequestsQuery, PmmarketplaceServiceRequestsQueryVariables>
export const GetWorkOrderDetailsDocument = gql`
    query getWorkOrderDetails($id: String!) {
  getWorkOrder(input: {id: $id}) {
    work_order
  }
}
    `

/**
 * __useGetWorkOrderDetailsQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkOrderDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrderDetailsQuery, GetWorkOrderDetailsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetWorkOrderDetailsQuery, GetWorkOrderDetailsQueryVariables>(GetWorkOrderDetailsDocument, options)
}
export function useGetWorkOrderDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderDetailsQuery, GetWorkOrderDetailsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetWorkOrderDetailsQuery, GetWorkOrderDetailsQueryVariables>(GetWorkOrderDetailsDocument, options)
}
export type GetWorkOrderDetailsQueryHookResult = ReturnType<typeof useGetWorkOrderDetailsQuery>
export type GetWorkOrderDetailsLazyQueryHookResult = ReturnType<typeof useGetWorkOrderDetailsLazyQuery>
export type GetWorkOrderDetailsQueryResult = Apollo.QueryResult<GetWorkOrderDetailsQuery, GetWorkOrderDetailsQueryVariables>
export const PmmarketplaceBidsDocument = gql`
    query pmmarketplaceBids($work_order_id: String!) {
  pmmarketplace_bids(where: {work_order: {_eq: $work_order_id}}) {
    work_order
    status
    sp
    record_id
    price
    id
    estimate
    date
    created_time
    additional_notes
    work_order_string
    service_provider {
      first_name
      phone
      last_name
      email
      company_name
      address
    }
  }
}
    `

/**
 * __usePmmarketplaceBidsQuery__
 *
 * To run a query within a React component, call `usePmmarketplaceBidsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePmmarketplaceBidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePmmarketplaceBidsQuery({
 *   variables: {
 *      work_order_id: // value for 'work_order_id'
 *   },
 * });
 */
export function usePmmarketplaceBidsQuery(baseOptions: Apollo.QueryHookOptions<PmmarketplaceBidsQuery, PmmarketplaceBidsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<PmmarketplaceBidsQuery, PmmarketplaceBidsQueryVariables>(PmmarketplaceBidsDocument, options)
}
export function usePmmarketplaceBidsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PmmarketplaceBidsQuery, PmmarketplaceBidsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<PmmarketplaceBidsQuery, PmmarketplaceBidsQueryVariables>(PmmarketplaceBidsDocument, options)
}
export type PmmarketplaceBidsQueryHookResult = ReturnType<typeof usePmmarketplaceBidsQuery>
export type PmmarketplaceBidsLazyQueryHookResult = ReturnType<typeof usePmmarketplaceBidsLazyQuery>
export type PmmarketplaceBidsQueryResult = Apollo.QueryResult<PmmarketplaceBidsQuery, PmmarketplaceBidsQueryVariables>
export const PmmarketplaceWorkOrdersDocument = gql`
    query pmmarketplaceWorkOrders($work_order_id: String!) {
  pmmarketplace_work_orders(where: {id: {_eq: $work_order_id}}) {
    type_service
    status
    sp_name
    sp
    service_request_status
    service_request
    record_number
    record_id
    additional_notes
    title
    parent_work_order
    is_follow_up
    invitations
    id
    fixed_price
    due_date
    due_date_type
    description
    created_time
    created_by
    bid_winner
    bids
    access_instructions
    attachments
  }
}
    `

/**
 * __usePmmarketplaceWorkOrdersQuery__
 *
 * To run a query within a React component, call `usePmmarketplaceWorkOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePmmarketplaceWorkOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePmmarketplaceWorkOrdersQuery({
 *   variables: {
 *      work_order_id: // value for 'work_order_id'
 *   },
 * });
 */
export function usePmmarketplaceWorkOrdersQuery(baseOptions: Apollo.QueryHookOptions<PmmarketplaceWorkOrdersQuery, PmmarketplaceWorkOrdersQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<PmmarketplaceWorkOrdersQuery, PmmarketplaceWorkOrdersQueryVariables>(PmmarketplaceWorkOrdersDocument, options)
}
export function usePmmarketplaceWorkOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PmmarketplaceWorkOrdersQuery, PmmarketplaceWorkOrdersQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<PmmarketplaceWorkOrdersQuery, PmmarketplaceWorkOrdersQueryVariables>(PmmarketplaceWorkOrdersDocument, options)
}
export type PmmarketplaceWorkOrdersQueryHookResult = ReturnType<typeof usePmmarketplaceWorkOrdersQuery>
export type PmmarketplaceWorkOrdersLazyQueryHookResult = ReturnType<typeof usePmmarketplaceWorkOrdersLazyQuery>
export type PmmarketplaceWorkOrdersQueryResult = Apollo.QueryResult<PmmarketplaceWorkOrdersQuery, PmmarketplaceWorkOrdersQueryVariables>
export const GetWorkOrderBidsPublicDocument = gql`
    query getWorkOrderBidsPublic($workOrderId: String!, $serviceRequestId: String!) {
  getWorkOrderBids(
    input: {serviceRequestId: $serviceRequestId, workOrderId: $workOrderId}
  ) {
    bids
  }
}
    `

/**
 * __useGetWorkOrderBidsPublicQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderBidsPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderBidsPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderBidsPublicQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *      serviceRequestId: // value for 'serviceRequestId'
 *   },
 * });
 */
export function useGetWorkOrderBidsPublicQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrderBidsPublicQuery, GetWorkOrderBidsPublicQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetWorkOrderBidsPublicQuery, GetWorkOrderBidsPublicQueryVariables>(GetWorkOrderBidsPublicDocument, options)
}
export function useGetWorkOrderBidsPublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderBidsPublicQuery, GetWorkOrderBidsPublicQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetWorkOrderBidsPublicQuery, GetWorkOrderBidsPublicQueryVariables>(GetWorkOrderBidsPublicDocument, options)
}
export type GetWorkOrderBidsPublicQueryHookResult = ReturnType<typeof useGetWorkOrderBidsPublicQuery>
export type GetWorkOrderBidsPublicLazyQueryHookResult = ReturnType<typeof useGetWorkOrderBidsPublicLazyQuery>
export type GetWorkOrderBidsPublicQueryResult = Apollo.QueryResult<GetWorkOrderBidsPublicQuery, GetWorkOrderBidsPublicQueryVariables>
export const GetWorkOrderEvidencesDocument = gql`
    query getWorkOrderEvidences($workOrderId: String!, $serviceRequestId: String!) {
  getWorkOrderEvidences(
    input: {serviceRequestId: $serviceRequestId, workOrderId: $workOrderId}
  ) {
    evidences
  }
}
    `

/**
 * __useGetWorkOrderEvidencesQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderEvidencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderEvidencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderEvidencesQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *      serviceRequestId: // value for 'serviceRequestId'
 *   },
 * });
 */
export function useGetWorkOrderEvidencesQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrderEvidencesQuery, GetWorkOrderEvidencesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetWorkOrderEvidencesQuery, GetWorkOrderEvidencesQueryVariables>(GetWorkOrderEvidencesDocument, options)
}
export function useGetWorkOrderEvidencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderEvidencesQuery, GetWorkOrderEvidencesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetWorkOrderEvidencesQuery, GetWorkOrderEvidencesQueryVariables>(GetWorkOrderEvidencesDocument, options)
}
export type GetWorkOrderEvidencesQueryHookResult = ReturnType<typeof useGetWorkOrderEvidencesQuery>
export type GetWorkOrderEvidencesLazyQueryHookResult = ReturnType<typeof useGetWorkOrderEvidencesLazyQuery>
export type GetWorkOrderEvidencesQueryResult = Apollo.QueryResult<GetWorkOrderEvidencesQuery, GetWorkOrderEvidencesQueryVariables>
