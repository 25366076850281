import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type UpdateCommentByPkMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']
  payload?: Types.InputMaybe<Types.Comments_Set_Input>
}>

export type UpdateCommentByPkMutation = { __typename?: 'mutation_root', update_comments_by_pk?: { __typename?: 'comments', created_at: any, created_by: any, updated_by?: any | undefined, updated_at: any, thread_entity_type: string, thread_entity_id: any, text: string, status: string, receiver_user_id?: any | undefined, parent_comment_id?: any | undefined, metadata?: any | undefined, id: any, attachments?: any | undefined, private_note?: boolean | undefined, linear_comment_id?: any | undefined, userByCreatedBy: { __typename?: 'users', id: any, first_name?: string | undefined, last_name?: string | undefined, profile_picture_url?: string | undefined, email: string }, linearCommentLinked?: { __typename?: 'linear_comments', original_linear_comment_id: string, original_linear_issue_id: string, user?: any | undefined } | undefined } | undefined }

export type CreateCommentMutationVariables = Types.Exact<{
  text: Types.Scalars['String']
  receiver_user_id?: Types.InputMaybe<Types.Scalars['ID']>
  thread_entity_id: Types.Scalars['ID']
  thread_entity_type: Types.Scalars['String']
  side_effect?: Types.InputMaybe<Types.Scalars['String']>
  attachments?: Types.InputMaybe<Types.Scalars['jsonb']>
  private_note?: Types.InputMaybe<Types.Scalars['Boolean']>
}>

export type CreateCommentMutation = { __typename?: 'mutation_root', createComment?: { __typename?: 'CreateCommentPayload', comment?: { __typename?: 'Comment', created_at?: string | undefined, created_by?: string | undefined, updated_by?: string | undefined, updated_at?: string | undefined, thread_entity_type: string, thread_entity_id: string, text: string, status?: string | undefined, receiver_user_id?: string | undefined, parent_comment_id?: string | undefined, metadata?: any | undefined, id: string, attachments?: any | undefined, private_note?: boolean | undefined, linear_comment_id?: string | undefined, userByCreatedBy?: { __typename?: 'User', id?: string | undefined, first_name?: string | undefined, last_name?: string | undefined, profile_picture_url?: string | undefined, email?: string | undefined } | undefined, linearCommentLinked?: { __typename?: 'LinearCommentLinked', original_linear_comment_id?: string | undefined, original_linear_issue_id?: string | undefined, user?: string | undefined } | undefined } | undefined } | undefined }

export const UpdateCommentByPkDocument = gql`
    mutation UpdateCommentByPk($id: uuid!, $payload: comments_set_input) {
  update_comments_by_pk(pk_columns: {id: $id}, _set: $payload) {
    created_at
    created_by
    updated_by
    updated_at
    thread_entity_type
    thread_entity_id
    text
    status
    receiver_user_id
    parent_comment_id
    metadata
    id
    attachments
    private_note
    linear_comment_id
    userByCreatedBy {
      id
      first_name
      last_name
      profile_picture_url
      email
    }
    linearCommentLinked {
      original_linear_comment_id
      original_linear_issue_id
      user
    }
  }
}
    `
export type UpdateCommentByPkMutationFn = Apollo.MutationFunction<UpdateCommentByPkMutation, UpdateCommentByPkMutationVariables>

/**
 * __useUpdateCommentByPkMutation__
 *
 * To run a mutation, you first call `useUpdateCommentByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentByPkMutation, { data, loading, error }] = useUpdateCommentByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateCommentByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentByPkMutation, UpdateCommentByPkMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<UpdateCommentByPkMutation, UpdateCommentByPkMutationVariables>(UpdateCommentByPkDocument, options)
}
export type UpdateCommentByPkMutationHookResult = ReturnType<typeof useUpdateCommentByPkMutation>
export type UpdateCommentByPkMutationResult = Apollo.MutationResult<UpdateCommentByPkMutation>
export type UpdateCommentByPkMutationOptions = Apollo.BaseMutationOptions<UpdateCommentByPkMutation, UpdateCommentByPkMutationVariables>
export const CreateCommentDocument = gql`
    mutation createComment($text: String!, $receiver_user_id: ID, $thread_entity_id: ID!, $thread_entity_type: String!, $side_effect: String, $attachments: jsonb, $private_note: Boolean) {
  createComment(
    input: {text: $text, receiver_user_id: $receiver_user_id, thread_entity_id: $thread_entity_id, thread_entity_type: $thread_entity_type, side_effect: $side_effect, attachments: $attachments, private_note: $private_note}
  ) {
    comment {
      created_at
      created_by
      updated_by
      updated_at
      thread_entity_type
      thread_entity_id
      text
      status
      receiver_user_id
      parent_comment_id
      metadata
      id
      attachments
      private_note
      linear_comment_id
      userByCreatedBy {
        id
        first_name
        last_name
        profile_picture_url
        email
      }
      linearCommentLinked {
        original_linear_comment_id
        original_linear_issue_id
        user
      }
    }
  }
}
    `
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      text: // value for 'text'
 *      receiver_user_id: // value for 'receiver_user_id'
 *      thread_entity_id: // value for 'thread_entity_id'
 *      thread_entity_type: // value for 'thread_entity_type'
 *      side_effect: // value for 'side_effect'
 *      attachments: // value for 'attachments'
 *      private_note: // value for 'private_note'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options)
}
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>
