import { cloneElement, FunctionComponentElement, useState } from 'react'
import { BsFunnelFill, BsSearch } from 'react-icons/bs'
import { Badge, Box, InputAdornment, styled, TextField } from '@mui/material'
import { debounce } from 'lodash'

import { THEME } from '#app/layouts/theme'

import { IconButtonStyled } from '../Buttons/IconButtonCustom'
import RightSideBar from '../RightSideBar/RightSideBar'

interface SearchInputProps {
  onFilterChange?: (e: React.ChangeEvent<HTMLInputElement>) => any
  placeholder?: string
  hasAdvancedFilters?: boolean
  filterForm?: FunctionComponentElement<{ closeHandler?: () => void } | undefined >
  advancedFiltersApplied?: number
}

const Search = styled(TextField)`
  width: 100%;
  gap: 12px;
  background: ${THEME.COLORS.light.primary};
`

const SearchInput = ({ onFilterChange, advancedFiltersApplied, placeholder, hasAdvancedFilters, filterForm: FilterForm }: SearchInputProps) => {
  const [isOpen, setOpen] = useState(false)

  // @ts-ignore
  const debouncedSearch = debounce(onFilterChange, 500)

  const closeHandler = () => {
    setOpen(false)
  }

  return (
    <Box>
      <Search
        placeholder={placeholder ?? 'Search...'}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <BsSearch />
            </InputAdornment>
          ),
          endAdornment: (hasAdvancedFilters
          && FilterForm)
            ? (
              <InputAdornment position='end'>
                <IconButtonStyled
                  sx={{
                    fontSize: '16px'
                  }}
                  onClick={() => setOpen(true)}
                >
                  <Badge
                    badgeContent={advancedFiltersApplied}
                    color='primary'
                  >
                    <BsFunnelFill fontSize={THEME.ICONS.size.sm} />
                  </Badge>
                </IconButtonStyled>
              </InputAdornment>
            )
            : null
        }}
        onChange={debouncedSearch}
      />
      {
        (hasAdvancedFilters
        && FilterForm)
        && (
          <RightSideBar
            isOpen={isOpen}
            closeHandler={closeHandler}
          >
            { cloneElement(FilterForm, {
              ...FilterForm.props,
              closeHandler
            })}
          </RightSideBar>
        )
      }
    </Box>
  )
}

export default SearchInput
