/* eslint-disable @typescript-eslint/naming-convention */
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import FormSelect from '#app/components/FormSelect/FormSelect'
import RichTextArea from '#app/components/RichTextArea/RichTextArea'
import { InputWithCustomers } from '#app/feature/InputWithCustomers/InputWithCustomers'
import { useJobStatusMap } from '#app/hooks/useJobs'
import { GetBusinessCustomersDocument } from '#app/operations/customers/customers.queries.generated'
import { CustomerInput } from '#app/types/customer'
import { BusinessWithJobsQueryResult } from '#app/types/job'
import { MUTATION } from '#app/utils/graphqlQueries'
import { getOnlyDefined } from '#app/utils/vanilla/helpers'

import Button from '../../components/Buttons/Button'
import useMutationWithNotification from '../../hooks/useMutationWithNotification'// '#app/hooks/useMutationWithNotification'

interface Props {
  submitCallback: (result: any, error?: any) => void
  currentBusiness?: BusinessWithJobsQueryResult
  currentCustomer?: CustomerInput
}

export const CreateJob = ({ submitCallback, currentBusiness, currentCustomer }: Props) => {
  const { t } = useTranslation()
  const [createJob] = useMutationWithNotification(MUTATION.jobs.createJob, {
    update(cache, { data }: any) {
      const { createJob: { job: newJob } } = data

      // @ts-ignore
      const { customers } = cache.readQuery({
        query: GetBusinessCustomersDocument,
        variables: {
          query: '%',
          limit: 50,
          businessID: currentBusiness?.id
        }
      })

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const mappedCustomers = customers
        .map((c: CustomerInput) => {
          if (c.id === newJob.customer_id) {
            const newCustomer = {
              ...c
            }
            const jobInCacheFormat = {
              ...newJob,
              __typename: 'jobs',
              customer: undefined
            }
            delete jobInCacheFormat.customer
            newCustomer.jobs = [jobInCacheFormat].concat(newCustomer.jobs)

            return newCustomer
          }

          return c
        })

      cache.writeQuery({
        query: GetBusinessCustomersDocument,
        variables: {
          query: '%',
          limit: 50,
          businessID: currentBusiness?.id
        },
        data: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          customers: mappedCustomers
        }
      })
    }
  })

  const onSubmit = async (data: any, { resetForm }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const payload = getOnlyDefined({
      user_id: currentBusiness?.user?.id,
      business_id: currentBusiness?.id,
      customer_id: data?.customer?.id,
      description: data?.description,
      status: data?.status
    })

    try {
      const result: any = await createJob({
        variables: payload
      })

      const { data: { createJob: { job } } } = result

      submitCallback(job)

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      resetForm()
    }
    catch (err: any) {
      if (err?.toString) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        console.error(err?.toString())
      }
    }
  }

  const initialValues = {
    description: '',
    status: '',
    user_id: currentBusiness?.user?.id,
    business_id: currentBusiness?.id,
    customer: currentCustomer
  }

  const schema = Yup
    .object()
    .shape({
      description: Yup
        .string()
        .required(t('validation.required')),
      status: Yup
        .string()
        .required(t('validation.required')),
      customer: Yup
        .object()
        .nullable()
        .required(t('validation.required'))
    })

  const jobMap = useJobStatusMap()

  const jobOptions = Object.keys(jobMap)
    .map((key) => {
      return {
        value: key,
        text: jobMap[key]
      }
    })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Typography variant='h4'>Create Job</Typography>
          <div className='mt-3'>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <InputWithCustomers
                  label='Customer'
                  name='customer'
                  userId={currentBusiness?.user?.id}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <RichTextArea
                  name='description'
                  label='Description'
                />
              </Grid>
              <Grid
                item
                xs
              >
                <FormSelect
                  label='Status'
                  name='status'
                  options={jobOptions}
                  popoverText={undefined}
                  placeholder={undefined}
                />
              </Grid>
            </Grid>
          </div>
          <div className='div-modal-input mt-4'>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type='submit'
            >
              Create
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
