import DatePickerInput from '#app/components/DatePickerInput/DatePickerInput'
import FormInput from '#app/components/FormInput/FormInput'
import FormLabel from '#app/components/FormLabel/FormLabel'
import FormSelect from '#app/components/FormSelect/FormSelect'
import FormTextArea from '#app/components/FormTextArea/FormTextArea'
import InputWithChips from '#app/components/InputWithChips/InputWithChips'
import InputWithPlaces from '#app/components/InputWithPlaces/InputWithPlaces'
import PhoneNumberInput from '#app/components/PhoneNumberInput/PhoneNumberInput'
import RadioButtonGroup from '#app/components/RadioButtonGroup/RadioButtonGroup'
import RichTextArea from '#app/components/RichTextArea/RichTextArea'
import SearchInput from '#app/components/SearchInput/SearchInput'

import { DateTimePickerInput } from '../DatePickerInput/DateTimePickerInput'
import FileUploadInput from '../ImageUpload/FileUploadInput'

export const Input = {
  RichTextArea, // TS - ReactQuill - Showcase
  Label: FormLabel, // TS - MUI - Showcase
  Generic: FormInput, // TS - MUI - Showcase
  Search: SearchInput, // TS - MUI - Showcase
  Select: FormSelect, // TS - MUI - Showcase
  Chips: InputWithChips, // JS! - MUI - Showcase
  Places: InputWithPlaces, // JS! - MUI - Showcase
  DatePicker: DatePickerInput, // JS! - MUI - Showcase
  DateTimePicker: DateTimePickerInput, // JS! - MUI
  Phone: PhoneNumberInput, // JS! - react-phone-number-input - Showcase
  TextArea: FormTextArea, // JS! - MUI - Showcase
  Radio: RadioButtonGroup, // JS! - Custom -  Showcase
  ImageUpload: FileUploadInput // JS! - MUI -  Showcase
  // Query: InputWithQuery, // JS! - Not sure if it should be documented here
  // Currency: CurrencyInput, // JS! - TODO: Check with Uli
}
