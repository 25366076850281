import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { Btn } from '#app/components/Buttons'

interface Texts {
  title?: string
  description?: string
  confirm?: string
  cancel?: string
}

interface Props {
  open: boolean
  setOpen: (val: boolean) => void
  onConfirm: () => Promise<object> | any
  isLoading: boolean
  itemName?: string
  texts?: Texts
}

export function DialogDelete({ itemName, isLoading, open, setOpen, onConfirm, texts }: Props) {
  const { t } = useTranslation()
  const handleClose = () => {
    if (!isLoading) {
      setOpen(false)
    }
  }

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      onClose={handleClose}
    >
      <DialogTitle id='alert-dialog-title'>
        { texts?.title ? texts?.title : t('dialogs.delete.title') }
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          { texts?.description
            ? texts.description
            : (
              <>
                { t('dialogs.delete.subtitleSuffix') }
                ,
                {' '}
                {itemName
                  ? (
                    <b>
                      &ldquo;
                      {itemName}
                      &ldquo;
                    </b>
                  )
                  : ''}
                {' '}
                { t('dialogs.delete.subtitlePrefix') }
              </>
            )}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{
        p: 2
      }}
      >
        <Btn.Button
          secondary
          disabled={isLoading}
          onClick={handleClose}
        >
          { texts?.cancel ? texts.cancel : 'Cancel'}
        </Btn.Button>
        <Btn.Button
          color='error'
          disabled={isLoading}
          onClick={async () => {
            await onConfirm()
            handleClose()
          }}
        >
          {isLoading ? t('dialogs.loading') : texts?.confirm ? texts.confirm : t('dialogs.delete.delete') }
        </Btn.Button>
      </DialogActions>
    </Dialog>
  )
}
