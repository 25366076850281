import { useMemo } from 'react'

import { Comments } from '#app/operations/autogenerate/schemas'
import { GetBusinessOwnerTasksQuery } from '#app/operations/tasks/tasks.queries.generated'
import { Comment } from '#app/types'
import { UserType } from '#app/types/user'
import { PageWrapper } from '#app/v2/components/PageWrapper'
import { cn } from '#app/v2/libs/utils'

import { Actions } from './components/Actions'
import { Content } from './components/Content'
import { Copilot } from './components/Copilot'
import { Thread } from './components/Thread'

interface Props {
  task?: GetBusinessOwnerTasksQuery['user_tasks'][0]
  comments?: Comments[]
  createComment?: (comment: string, attachments?: Comment.Attachment[]) => void
  editComment?: (commentId: string, text: string) => void
  deleteComment?: (commentId: string) => void
  updateTask?: (status: string) => void
  loading?: boolean
  author?: UserType
  rating?: {
    score: string
    explanation: string
  }
}

export const TaskDetail = ({ task, comments, createComment, editComment, deleteComment, updateTask, author, loading, rating }: Props) => {
  const wizardAvailable: string | undefined = useMemo(() => {
    const content = task?.linearIssueLinked?.description || task?.description_html || ''
    const extractURL = (text: string): string | undefined => {
      const baseURL = process.env.REACT_APP_ENV === 'development'
        ? 'http:\\/\\/localhost:4000'
        : 'https:\\/\\/app(-staging)?\\.joinowners\\.com'
      const regexPattern = `${baseURL}\\/v2\\/wizard\\/([0-9a-zA-Z\\-_]+)(?:\\?taskId=([0-9a-zA-Z\\-_]+))?`
      const matches = text.match(regexPattern)
      return matches ? matches[0] : undefined
    }

    return extractURL(content)
  }, [task])

  return (
    <PageWrapper
      pathname='/v2/task-detail/:id/'
      className={cn('flex grow flex-col bg-[#F7F9FB] pb-5 ')}
    >
      {/* Card */}
      <article className='mx-4 mt-5 rounded-xl border bg-white p-5 shadow'>
        <Content task={task} />
        <Copilot task={task} />
        <Thread
          task={task}
          comments={comments}
          author={author}
          taskStatus={task?.status}
          createComment={createComment}
          editComment={editComment}
          deleteComment={deleteComment}
          loading={loading}
          rating={rating}
        />
        {/* Bottom confirm Task action */}
      </article>
      <Actions
        wizardAvailable={wizardAvailable}
        updateTask={updateTask}
        taskStatus={task?.status}
        loading={loading}
      />
    </PageWrapper>
  )
}
