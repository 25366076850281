import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import { ConfettiCanon } from '#app/v2/assets/Icons'
import Images from '#app/v2/assets/Images'
import { Button } from '#app/v2/components/ui/button'

interface Props {
  homeUrl?: string
}

export const TasksCompletedSuccess = ({ homeUrl }: Props) => {
  const { t } = useTranslation()
  return (
    <div className=' min-h-screen  bg-gradient-to-b from-[#365193]/90 to-[#365193]/80'>
      <div className='relative pt-[10vh]'>
        <motion.div
          initial={{
            opacity: 0
          }}
          animate={{
            opacity: 1
          }}
          transition={{
            duration: 0.875
          }}
          className='absolute inset-x-0 mx-auto flex max-w-[270px] flex-col items-center gap-3 pt-[10vh] text-center text-white'
        >
          <ConfettiCanon className='h-24 w-24' />
          <h1 className='text-xl font-semibold'>{t('user_tasks.completed.title', 'Great job!')}</h1>
          <p className='text-base/5 font-medium'>
            {t('user_tasks.success.finish', 'You finished all your tasks!')}
            {' '}
            <br />
            {t('user_tasks.success.wellDone', 'Well done!')}
          </p>
          <Button
            asChild
            variant='secondary'
            className=' mt-5 rounded-lg border-2 border-[#304985] bg-white py-1.5 text-sm/5 font-bold text-[#304985]'
          >
            <Link to={homeUrl ? homeUrl : ''}>
              {t('user_tasks.success.goHome', 'Go home')}
            </Link>
          </Button>
        </motion.div>
        <motion.img
          style={{
            objectFit: 'cover'
          }}
          initial={{
            y: '90%',
            scale: 0.1,
          }}
          animate={{
            y: 0,
            scale: 1,
          }}
          transition={{
            ease: 'easeInOut',
            duration: 0.675
          }}
          src={Images.DoneTasksSuccess}
          alt=''
          className=' h-[90vh] w-full'
        />
      </div>
    </div>
  )
}
