import PhoneInput from 'react-phone-number-input'
import { useField } from 'formik'

import PhoneTextField, { PhoneTextFieldStyled } from '../PhoneTextField/PhoneTextField'

import 'react-phone-number-input/style.css'

const PhoneNumberInput = ({ label = '', disabled = false, styled = false, ...props }) => {
  const [, meta, helpers] = useField(props)

  const { setValue } = helpers

  return (
    <>
      { label && <label className='form-label'>{label}</label>}
      <PhoneInput
        international
        smartCaret
        country='US'
        defaultCountry='US'
        value={meta.value}
        disabled={disabled}
        error={meta.touched && !!meta.error}
        inputComponent={styled ? PhoneTextFieldStyled : PhoneTextField}
        onChange={setValue}
      />
      <div className='error'>{ meta.touched && meta.error }</div>
    </>
  )
}

export default PhoneNumberInput
