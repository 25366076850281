import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { CenteredNavTitle } from '#app/v2/components/CenteredNavTitle'
import { HeaderSharedNavigation } from '#app/v2/components/HeaderSharedNavigation'
import { NavigationBottom } from '#app/v2/components/NavigationBottom'
import { modalShortcuts, mvpShortcuts, profileStoryData } from '#app/v2/pages/MyProfile/data'
import { MyProfile } from '#app/v2/pages/MyProfile/page'

export const StoryMyProfile = () => {
  const [searchParams] = useSearchParams()
  const storyProps = useMemo(() => {
    const scenario = searchParams.get('scenario')

    const res = {
      headerShared: {
        backUrl: profileStoryData.bottomNavigation.routes.home
      },
      myProfile: {
        clientId: profileStoryData.profile.clientId,
        avatar: profileStoryData.profile.image,
        fullName: profileStoryData.profile.fullName,
        services: profileStoryData.profile.services,
        editProfileUrl: profileStoryData.editProfileUrl,
        shortcuts: profileStoryData.shortcuts,
        extraShortcuts: profileStoryData.extraShortcuts,
        logout: profileStoryData.logout
      },
      navigationBottom: profileStoryData.bottomNavigation
    }

    switch (scenario) {
      case 'mvp_shortcuts':
        res.myProfile.shortcuts = mvpShortcuts.shortcuts
        res.myProfile.extraShortcuts = mvpShortcuts.extraShortcuts
        break
      case 'long_name':
        res.myProfile.fullName = 'Hector Enrique Benedetto de la Rua'
        break
      case 'modal':
        res.headerShared.backUrl = ''
        res.myProfile.shortcuts = modalShortcuts.shortcuts
        res.myProfile.extraShortcuts = modalShortcuts.extraShortcuts
        break
      default:
        break
    }

    return res
  }, [searchParams])
  return (
    <div className='min-h-screen bg-[#F7F9FB]'>
      {/* HEADER */}
      <HeaderSharedNavigation
        profile={storyProps.myProfile}
        backUrl={storyProps.headerShared.backUrl}
        main={<CenteredNavTitle title='Menu' />}
      />
      {searchParams.get('scenario') !== 'modal' && (
        <MyProfile
          {...storyProps.myProfile}
        />
      ) }
      <NavigationBottom {...storyProps.navigationBottom} />
    </div>
  )
}
