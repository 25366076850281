/* eslint-disable @typescript-eslint/naming-convention */
import { useTranslation } from 'react-i18next'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { capitalize } from 'lodash'
import * as Yup from 'yup'

import FormSelect from '#app/components/FormSelect/FormSelect'
import InputWithPlaces from '#app/components/InputWithPlaces/InputWithPlaces'
import PhoneNumberInput from '#app/components/PhoneNumberInput/PhoneNumberInput'
import { useLeadsMap } from '#app/hooks/useCustomers'
import { CustomerInput } from '#app/types/customer'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

import Button from '../../components/Buttons/Button'
import FormInput from '../../components/FormInput/FormInput'

interface Props {
  // eslint-disable-next-line no-empty-pattern
  onSubmit: (data: CustomerInput, { resetForm }: any) => Promise<void>
  information: CustomerInput
  editMode?: boolean
}

interface PublicProps {
  isSubmitting: boolean
}

const CreatePublicCustomer = ({ isSubmitting }: PublicProps) => {
  const { t } = useTranslation()
  const isDesktop = useIsDesktopSize()
  const gridStyle = isDesktop ? 6 : 12
  const divStyle = isDesktop
    ? {
      display: 'flex',
      justifyContent: 'flex-end'
    }
    : {
      display: 'flex',
      justifyContent: 'center'
    }
  const buttonStyle = isDesktop
    ? {
      width: '30%'
    }
    : {
      width: '95%'
    }
  const sx = {
    backgroundColor: '#F1F5F9',
    border: '1px solid #F1F5F9',
    borderRadius: '10px'
  }
  // const parentGridStyle = !isDesktop ? { padding: '1rem', marginTop: '1rem' } : {}
  return (
    <Form>
      <div>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={gridStyle}
            justifyContent='center'
          >
            <FormInput
              name='first_name'
              label={capitalize(t('generic.firstName'))}
              type='text'
              sx={sx}
            />
          </Grid>
          <Grid
            item
            xs={gridStyle}
          >
            <FormInput
              name='last_name'
              label={capitalize(t('generic.lastName'))}
              type='text'
              sx={sx}
            />
          </Grid>
          <Grid
            item
            xs={gridStyle}
          >
            <FormInput
              name='email'
              type='email'
              label={capitalize(t('generic.email'))}
              sx={sx}

            />
          </Grid>
          <Grid
            item
            xs={gridStyle}
          >
            <InputWithPlaces
              label={capitalize(t('generic.address'))}
              sx={sx}
              name='address'
              types={undefined}
              fields={undefined}
              countries={undefined}
              multiple={undefined}
              limitTags={undefined}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <PhoneNumberInput
              name='phone'
              label={capitalize(t('generic.phone'))}
              styled={true}
            />
          </Grid>
        </Grid>
      </div>
      <div
        className='div-modal-input mt-4'
        style={divStyle}
      >
        <Button
          disabled={isSubmitting}
          isLoading={isSubmitting}
          style={buttonStyle}
          type='submit'
        >
          {t('customers.createCustomer.letsTalk')}
        </Button>
      </div>
    </Form>
  )
}

const CreateCustomer = ({ onSubmit, information, editMode }: Props) => {
  const { t } = useTranslation()
  const leadsMap = useLeadsMap()

  const leadsOptions = Object
    .keys(leadsMap)
    .map((value) => {
      // @ts-ignore
      return {
        text: leadsMap[value],
        value
      }
    })

  const initialValues = information

  const schema = Yup
    .object()
    .shape({
      first_name: Yup
        .string(),
      last_name: Yup
        .string(),
      email: Yup
        .string()
        .email(t('validation.invalidEmail')),
      phone: Yup
        .string()
        .test('validNumber', t('validation.invalidPhone'),
        // @ts-ignore
          (value) => {
            if (value) {
              return isValidPhoneNumber(value)
            }
            else {
              return true
            }
          }),
      address: Yup.object({
        description: Yup.string(),
        matched_substrings: Yup.array(),
        place_id: Yup.string(),
        reference: Yup.string(),
        structured_formatting: Yup.object({
          main_text: Yup.string(),
          main_text_matched_substrings: Yup.array(),
          secondary_text: Yup.string()
        }).nullable(),
        terms: Yup.array(),
        types: Yup.array()
      }).nullable()
    })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Typography variant='h4'>
            {editMode ? t('customers.editCustomer.header', 'Edit Customer') : t('customers.createCustomer.header')}
          </Typography>
          <div className='mt-3'>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <FormInput
                  name='first_name'
                  label={capitalize(t('generic.firstName'))}
                  type='text'
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <FormInput
                  name='last_name'
                  label={capitalize(t('generic.lastName'))}
                  type='text'
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <FormInput
                  name='email'
                  type='email'
                  label={capitalize(t('generic.email'))}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <InputWithPlaces
                  label={capitalize(t('generic.address'))}
                  name='address'
                  types={undefined}
                  fields={undefined}
                  countries={undefined}
                  multiple={undefined}
                  limitTags={undefined}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <PhoneNumberInput
                  name='phone'
                  label={capitalize(t('generic.phone'))}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <FormSelect
                  placeholder=''
                  label={capitalize(t('generic.lead'))}
                  name='lead'
                  options={leadsOptions}
                  popoverText={undefined}
                />
              </Grid>
            </Grid>
          </div>
          <div className='div-modal-input mt-4'>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type='submit'
            >
              {
                editMode
                  ? t('customers.editCustomer.saveChanges', 'Save Changes')
                  : t('customers.createCustomer.addCustomer')
              }
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export {
  CreatePublicCustomer
}

export default CreateCustomer
