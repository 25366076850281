import create from 'zustand'
import { devtools } from 'zustand/middleware'

export interface BankingBottomSectionState {
  url?: string
}

interface BankingBottomSectionActions {
  setSelectedSection: (data: BankingBottomSectionState) => void
}

const initialState: BankingBottomSectionState = {
  url: undefined
}

export const useBankingBottomSection = create<BankingBottomSectionState & BankingBottomSectionActions>()(
  devtools(
    (set) => ({
      ...initialState,
      setSelectedSection(data: BankingBottomSectionState) {
        return set((state) => {
          return {
            ...state,
            ...data
          }
        })
      }
    }),
    {
      name: 'zt_owners_banking_bottom_section'
    }
  )
)
