import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type LinkPhoneMutationVariables = Types.Exact<{
  phone: Types.Scalars['String']
  email: Types.Scalars['String']
}>

export type LinkPhoneMutation = { __typename?: 'mutation_root', linkPhone?: { __typename?: 'LinkPhonePayload', result: boolean } | undefined }

export const LinkPhoneDocument = gql`
    mutation linkPhone($phone: String!, $email: String!) {
  linkPhone(input: {phone: $phone, email: $email}) {
    result
  }
}
    `
export type LinkPhoneMutationFn = Apollo.MutationFunction<LinkPhoneMutation, LinkPhoneMutationVariables>

/**
 * __useLinkPhoneMutation__
 *
 * To run a mutation, you first call `useLinkPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkPhoneMutation, { data, loading, error }] = useLinkPhoneMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLinkPhoneMutation(baseOptions?: Apollo.MutationHookOptions<LinkPhoneMutation, LinkPhoneMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<LinkPhoneMutation, LinkPhoneMutationVariables>(LinkPhoneDocument, options)
}
export type LinkPhoneMutationHookResult = ReturnType<typeof useLinkPhoneMutation>
export type LinkPhoneMutationResult = Apollo.MutationResult<LinkPhoneMutation>
export type LinkPhoneMutationOptions = Apollo.BaseMutationOptions<LinkPhoneMutation, LinkPhoneMutationVariables>
