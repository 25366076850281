import { useCallback, useEffect, useMemo, useState } from 'react'
import { BsTrashFill, BsXCircleFill } from 'react-icons/bs'
import { Alert, AlertTitle, Divider, Grid, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import Button from '#app/components/Buttons/Button'
import { Chip } from '#app/components/Chip'
import { DialogDelete } from '#app/components/Dialog/DialogDelete'
import { Input } from '#app/components/Input'
import RightSideBar, { RightSideBarContainer } from '#app/components/RightSideBar/RightSideBar'
import { useEntityTaskTemplates } from '#app/hooks/useEntityTaskTemplates'
import { useEntityUserTasks } from '#app/hooks/useEntityUserTasks'
import { Generic, Task, User } from '#app/types'
import { languageFull } from '#app/utils/vanilla/language'

import { PreviewTaskContent } from '../ViewTask/PreviewTaskContent'

import { TemplateCard } from './TemplateCard'

interface Props {
  open: boolean
  setOpen: (val: boolean) => void
  cbCopyTemplate: (task: Task.UserTask, templateId: Generic.TaskTemplateID) => void
  currentOwner: User.UserType
}

export function TaskTemplates({ open, cbCopyTemplate, setOpen, currentOwner }: Props) {
  // Show 'new' templates and 'deleted' templates
  const [viewDeletedTemplates, setViewDeletedTemplates] = useState(false)

  // Preview template or task
  const [isPreviewActive, setIsPreviewActive] = useState(false)
  const [previewTemplate, setPreviewTemplate] = useState<null | Task.TemplateContentV1>()
  const [taskFromTemplate, setTaskFromTemplate] = useState<null | { language: string, task: Task.UserTask, id: Generic.TaskTemplateID }>()

  // Search
  const [search, setUserSearch] = useState('')

  // Delete Template
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [templateToDelete, setTemplateToDelete] = useState<null | Task.Template>(null)

  // Custom hooks
  const { queryGetTaskTemplates, mutationDeleteTaskTemplate, queryGetTaskTemplatesData } = useEntityTaskTemplates()
  const { mutationCreateUserTask, mutationCreateUserTaskLoading } = useEntityUserTasks(undefined, currentOwner.id, 'TaskTemplates')

  const resetStates = () => {
    setViewDeletedTemplates(false)
    setIsPreviewActive(false)
    setPreviewTemplate(null)
    setTaskFromTemplate(null)

    setTemplateToDelete(null)
    setOpenDeleteDialog(false)
  }

  const initView = useCallback(async () => {
    await queryGetTaskTemplates()
  }, [queryGetTaskTemplates])

  const initPreviewMode = useCallback((tmpTemplate: Task.Template) => {
    if (tmpTemplate.content) {
      setPreviewTemplate(tmpTemplate.content as Task.TemplateContentV1)
      setIsPreviewActive(true)
    }
  }, [])

  const getTaskContent = useCallback((template: Task.Template) => {
    const preferLang = currentOwner.language
    if (preferLang && template.content[preferLang]) {
      return ({
        language: preferLang,
        task: template.content[preferLang],
        id: template.id
      })
    }
    else if (template.content.en) {
      return ({
        language: 'en',
        task: template.content.en,
        id: template.id
      })
    }
    else {
      return ({
        language: 'es',
        task: template.content.es,
        id: template.id
      })
    }
  }, [currentOwner.language])

  const initCopyTemplate = useCallback((tmpTemplate: Task.Template) => {
    const template = getTaskContent(tmpTemplate)
    if (template?.task) {
      cbCopyTemplate(template.task as Task.UserTask, template.id)
    }
  }, [cbCopyTemplate, getTaskContent])

  const initTaskPreview = useCallback((tmpTemplate: Task.Template) => {
    if (tmpTemplate.content) {
      const task = getTaskContent(tmpTemplate)
      setTaskFromTemplate(task)
      setIsPreviewActive(true)
    }
  }, [getTaskContent])

  const closePreviewMode = useCallback(() => {
    setPreviewTemplate(null)
    setTaskFromTemplate(null)
    setIsPreviewActive(false)
  }, [])

  const showDialogDeleteTemplate = useCallback((templateSelected: Task.Template) => {
    setTemplateToDelete(templateSelected)
    setOpenDeleteDialog(true)
  }, [])

  const deleteTemplate = useCallback(async () => {
    return await mutationDeleteTaskTemplate({
      variables: {
        id: templateToDelete?.id
      }
    })
  }, [mutationDeleteTaskTemplate, templateToDelete])

  const sendTask = useCallback(async () => {
    if (!currentOwner?.id || !taskFromTemplate?.id) {
      console.error('This entity does not have a valid ID')
      return null
    }

    if (taskFromTemplate?.task) {
      const payload = {
        ...taskFromTemplate.task,
        metadata: {
          template_id: taskFromTemplate.id
        },
        user_id: currentOwner.id,
        bo_id: currentOwner.id
      }

      const result = await mutationCreateUserTask({
        variables: payload
      })

      if (result.data.createUserTask) {
        setOpen(false)
        closePreviewMode()
      }
    }
  }, [closePreviewMode, mutationCreateUserTask, currentOwner, taskFromTemplate, setOpen])

  const templatesList = useMemo(() => {
    if (queryGetTaskTemplatesData?.task_templates) {
      if (!search || search === '') return queryGetTaskTemplatesData?.task_templates

      return queryGetTaskTemplatesData?.task_templates.filter((template) => {
        return template?.title.toLowerCase().includes(search.toLowerCase())
      })
    }
    return []
  }, [queryGetTaskTemplatesData, search])

  useEffect(() => {
    if (open) {
      initView().catch((error) => {
        console.error(error)
      })
    }
  }, [initView, open])

  return (
    <>
      <DialogDelete
        itemName={templateToDelete?.title}
        isLoading={false}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onConfirm={deleteTemplate}
      />
      <RightSideBar
        isOpen={open}
        size='lg'
        closeHandler={() => {
          if (!mutationCreateUserTaskLoading) {
            resetStates()
            setOpen(false)
          }
        }}
      >
        <RightSideBarContainer footer={(
          <Box>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={2}
              pb={2}
              width='100%'
            >
              <Box>
                <Button
                  primary={false}
                  disabled={mutationCreateUserTaskLoading}
                  onClick={() => setOpen(false)}
                >
                  Close
                </Button>
              </Box>
              <Box>
                {
                  taskFromTemplate
                  && (
                    <Button
                      disabled={mutationCreateUserTaskLoading}
                      onClick={sendTask}
                    >
                      Send Message
                    </Button>
                  )
                }
              </Box>
            </Stack>
          </Box>
        )}
        >
          <>
            <Typography variant='h4'>Global message templates</Typography>
            <Typography variant='subtitle2'>
              You can send, view or delete templates from here, remember that already submitted tasks will not be affected if you delete the template.
            </Typography>
            <Box>
              <Box mt={4}>
                <Box
                  width='100%'
                  mb={4}
                >
                  <Input.Search
                    placeholder='Search for templates based on the template title (does not depend on the task title)'
                    onFilterChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setUserSearch(e.target.value)
                    }}
                  />
                </Box>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  spacing={2}
                >
                  <Box mb={6}>
                    <Chip.User user={currentOwner} />
                    <Chip.Language lang={currentOwner.language} />
                  </Box>

                  <Box mb={6}>
                    {
                      !isPreviewActive
                      && (
                        <Chip.Basic
                          icon={viewDeletedTemplates ? <BsXCircleFill /> : <BsTrashFill />}
                          label={`${viewDeletedTemplates ? 'Hide' : 'View'} deleted templates`}
                          onClick={() => setViewDeletedTemplates(!viewDeletedTemplates)}
                        />
                      )
                    }
                  </Box>
                </Stack>

                {templatesList.length === 0
                && (
                  <Box
                    p={12}
                    textAlign='center'
                    width='100%'
                  >
                    <Typography
                      variant='h4'
                      color='gray'
                    >
                      There are currently no templates
                    </Typography>
                  </Box>
                )}
                <Grid
                  container
                  spacing={2}
                  alignItems='stretch'
                >
                  {!isPreviewActive
                  && templatesList
                    .filter((template) => {
                      if (viewDeletedTemplates) return true
                      if (template.status === 'deleted') return false
                      return true
                    })
                    .map((template, key) => {
                      return (
                        <TemplateCard
                          key={key}
                          template={template}
                          currentOwner={currentOwner}
                          initCopyTemplate={initCopyTemplate}
                          cbDeleteTemplate={showDialogDeleteTemplate}
                          initPreviewMode={initPreviewMode}
                          initTaskPreview={initTaskPreview}
                        />
                      )
                    })}
                </Grid>
                {
                  isPreviewActive
                  && (
                    <Box mt={6}>
                      <Button onClick={closePreviewMode}>
                        &#60; Back to templates
                      </Button>

                      {/* PREVIEW TEMPLATE */}
                      {previewTemplate
                      && (
                        <>
                          {previewTemplate?.en
                          && (
                            <Box mt={6}>
                              <PreviewTaskContent
                                adminSide
                                previewMode
                                chips={['English']}
                                task={previewTemplate.en}
                              />
                              <Divider />
                            </Box>
                          )}
                          {
                            previewTemplate?.es
                            && (
                              <Box mt={6}>
                                <PreviewTaskContent
                                  adminSide
                                  previewMode
                                  chips={['Spanish']}
                                  task={previewTemplate.es}
                                />
                              </Box>
                            )
                          }
                        </>
                      )}

                      {/* PREVIEW TASK FROM TEMPLATE */}
                      {taskFromTemplate
                      && (
                        <Box mt={6}>
                          {
                            currentOwner.language !== taskFromTemplate.language
                            && (
                              <Alert severity='warning'>
                                <AlertTitle>Warning</AlertTitle>
                                You will send a message to
                                {' '}
                                {currentOwner.first_name}
                                {' '}
                                in
                                {' '}
                                <b>{languageFull(taskFromTemplate.language)}</b>
                                , and it is not the preferred language of this person. Remember that
                                {' '}
                                {currentOwner.first_name}
                                {' '}
                                will see the message exactly as you are previewing it now.
                              </Alert>
                            )
                          }
                          <PreviewTaskContent
                            adminSide
                            previewMode
                            chips={
                              [currentOwner.language
                                ? languageFull(taskFromTemplate.language, true)
                                : 'This BO does not have a preferred language']
                            }
                            task={taskFromTemplate.task}
                          />
                        </Box>
                      )}
                    </Box>
                  )
                }
              </Box>
            </Box>
          </>
        </RightSideBarContainer>
      </RightSideBar>
    </>
  )
}
