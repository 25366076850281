/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Stack, Typography } from '@mui/material'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { DialogConfirm } from '#app/components/Dialog/DialogConfirm'
import CreateDemoSite from '#app/forms/PublicDemoSite/CreateDemoSite'
import { useCreateDemoSalesSiteMutation, useGetDemoSiteQuery } from '#app/operations/businesses/businesses.queries.generated'

export interface DemoSiteData {
  name: string
  salesEmail: string
  companyName: string
  personalEmail: string
  color1: string
  recurringCategory: string[]
  serviceAreasNames: any[]
}

export const PublicGenerateSite = () => {
  const { t } = useTranslation()
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const [createDemoSite] = useCreateDemoSalesSiteMutation()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [siteId, setSiteId] = useState<string | null>(null)
  const [polling, setPolling] = useState<boolean>(true)
  const [salesEmail, setSalesEmail] = useState<string>('anonymus@joinowners.com')

  const { data, loading, error, refetch, startPolling, stopPolling } = useGetDemoSiteQuery({
    variables: {
      siteId: siteId ?? '',
      salesEmail
    },
    skip: !siteId
  })

  const initialValues = {
    name: '',
    companyName: '',
    salesEmail,
    personalEmail: '',
    color1: '#e58730',
    recurringCategory: [],
    serviceAreasNames: []
  }

  const onSubmit = async (data: DemoSiteData, { resetForm }: any) => {
    setSubmitting(true)
    const payload = {
      ...data,
      salesEmail
    }
    try {
      const result: any = await createDemoSite({
        variables: payload
      })
      const created = result.data.createDemoSalesSite.result
      if (created) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setSiteId(result.data.createDemoSalesSite.demoSiteId)
      }
    }
    catch (err) {
      console.error('Error', err)
      setSubmitting(false)
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const salesEmail = urlParams.get('salesEmail')
    if (salesEmail) {
      setSalesEmail(salesEmail)
    }
  }, [])

  useEffect(() => {
    if (siteId) {
      refetch({
        siteId,
        salesEmail
      })

      startPolling(5000)
      setPolling(true)
    }
  }, [siteId, refetch, startPolling, salesEmail])

  useEffect(() => {
    if (!polling && isOpen) {
      stopPolling()
    }
  }, [polling, stopPolling, isOpen])
  useEffect(() => {
    if (data?.getDemoSite?.result) {
      setSubmitting(false)
      setIsOpen(true)
      setPolling(false)
    }
  }, [data, siteId])

  const closePopUp = () => {
    setIsOpen(false)
    setSiteId(null)
    setPolling(false)
  }
  const schema = Yup.object().shape({
    name: Yup.string()
      .required('Full name is required')
      .min(3, 'Full name should have at least 3 characters'),

    companyName: Yup.string()
      .required('Company name is required')
      .min(3, 'Company name should have at least 3 characters'),

    personalEmail: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),

    color1: Yup.string()
      .required('Color 1 is required')
      .matches(/^#[0-9a-fA-F]{6}$/, 'Color should be in hex format'),

    recurringCategory: Yup.string()
      .required('At least one category is required'),

    serviceAreasNames: Yup.object({
      description: Yup.string(),
      matched_substrings: Yup.array(),
      place_id: Yup.string(),
      reference: Yup.string(),
      structured_formatting: Yup.object({
        main_text: Yup.string(),
        main_text_matched_substrings: Yup.array(),
        secondary_text: Yup.string()
      }).nullable(),
      terms: Yup.array(),
      types: Yup.array()
    }).nullable()
  })

  return (
    <Stack spacing={3}>
      <Typography>
        Create a demo site.
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {() => (
          <CreateDemoSite isSubmitting={isSubmitting ?? undefined} />
        )}
      </Formik>
      <DialogConfirm
        withoutCancel
        open={isOpen}
        setOpen={setIsOpen}
        texts={{
          title: 'Demo Site Created',
          description: `El sitio fue creado correctamente podras verlo en el siguiente link: https://editor.joinowners.com/preview/${siteId ?? ''}`,
          confirm: t('customers.createCustomer.close')
        }}
        extraContent={(
          <Grid
            container
            spacing={3}
            justifyContent='center'
            alignItems='center'
          >
            <Grid item>
              <Button
                sx={{
                  mt: 2,
                  mb: 2
                }}
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(`https://editor.joinowners.com/preview/${siteId ?? ''}`)
                  }
                  catch (error) {
                    console.error('Failed to copy link to clipboard:', error)
                  }
                }}
              >
                Copiar Link
              </Button>
            </Grid>
          </Grid>
        )}
        isLoading={false}
        onConfirm={() => closePopUp()}
      />
    </Stack>
  )
}
