import { useCallback, useMemo, useState } from 'react'
import { RiFullscreenFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Drawer, IconButton, Modal, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'

import { Dialog } from '#app/components/Dialog'
import { useDayJs } from '#app/hooks/useDayJs'
import { useWebsiteEditorNewDeploymentMutation, useWebsiteEditorSaveChangesMutation } from '#app/operations/website-editor/websiteeditor.mutations.generated'
import useWebsiteEditorStore from '#app/store/website-editor'

interface Props {
  htmlBlobUrl: string
  htmlPlain: string
  userId: string
  sectionVersion: string
  loadingGeneral: boolean
}

const drawerWidth = 360

export function DrawerPreviewContent({ htmlBlobUrl, htmlPlain, userId, sectionVersion, loadingGeneral }: Props) {
  const [fullScreenModal, setFullScreenModal] = useState(false)
  const handleOpen = () => setFullScreenModal(true)
  const handleClose = () => setFullScreenModal(false)

  const [openPublish, setOpenPublish] = useState(false)
  const [openGeneratePreview, setOpenGeneratePreview] = useState(false)

  const handleOpenPublish = () => setOpenPublish(true)
  const handleClosePublish = () => setOpenPublish(false)

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { formatDateInFormat } = useDayJs()
  const { lastUpdate, sections, setForceRefreshOn } = useWebsiteEditorStore()

  const [saveChanges, { loading: loadingSaveChanges }] = useWebsiteEditorSaveChangesMutation({
    fetchPolicy: 'no-cache'
  })

  const [newDeployment, { loading: loadingDeployment }] = useWebsiteEditorNewDeploymentMutation({
    fetchPolicy: 'no-cache'
  })

  const handleSaveChanges = useCallback(async (forceRefresh = false) => {
    const res = await saveChanges({
      variables: {
        input: {
          user_id: userId,
          website_sections: Object.values(sections),
          section_version: sectionVersion
        }
      }
    })

    const result = res.data?.websiteEditorSaveChanges

    if (result?.success) {
      enqueueSnackbar(`Success${result?.message ? `: ${result?.message}` : ''}`, {
        variant: 'success',
        autoHideDuration: 4000
      })
      if (forceRefresh) {
        setForceRefreshOn()
      }
      return true
    }
    else {
      console.error(res)
      enqueueSnackbar(`Error: ${result?.message}`, {
        variant: 'error'
      })
      return false
    }
  }, [enqueueSnackbar, saveChanges, sectionVersion, sections, setForceRefreshOn, userId])

  const publishLiveWebsite = useCallback(async (skipHosting: boolean = false) => {
    const success = await handleSaveChanges(false)
    if (!success) return

    const res = await newDeployment({
      variables: {
        input: {
          skip_hosting: skipHosting,
          section_version: sectionVersion,
          website_html_content: htmlPlain,
          user_id: userId
        }
      }
    })

    if (res.data?.websiteEditorNewDeployment?.success) {
      enqueueSnackbar('Success - Deployment in queue', {
        variant: 'success',
        autoHideDuration: 4000
      })

      navigate('/website-editor/' + userId)
      return true
    }
    else {
      enqueueSnackbar(`Error: ${res?.errors}`, {
        variant: 'error'
      })
      return false
    }
  }, [enqueueSnackbar, handleSaveChanges, htmlPlain, navigate, newDeployment, sectionVersion, userId])

  const loading = useMemo(() => {
    return loadingGeneral || loadingSaveChanges || loadingDeployment
  }, [loadingGeneral, loadingSaveChanges, loadingDeployment])

  return (
    <Drawer
      variant='permanent'
      anchor='right'
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        ['& .MuiDrawer-paper']: {
          width: drawerWidth,
          boxSizing: 'border-box'
        },
      }}
    >
      <Stack
        height='100svh'
        width={drawerWidth}
      >
        <iframe
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className='iframe'
          width='100%'
          height='100%'
          style={{
            position: 'relative',
            zIndex: '30'
          }}
          src={htmlBlobUrl}
        >
        </iframe>
        <Stack
          mx='1rem'
          my='0.5rem'
          gap={1}
        >
          <Stack
            direction='row'
            alignItems='center'
            gap={1}
          >
            <IconButton
              sx={{
                flexShrink: 0
              }}
              color='info'
              onClick={handleOpen}
            >
              <RiFullscreenFill />
            </IconButton>
            <Modal
              open={fullScreenModal}
              aria-labelledby='fullscreen-website'
              aria-describedby='modal-modal-description'
              onClose={handleClose}
            >
              <Box sx={{
                width: '95%',
                height: '95%',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                border: '1px solid #000',
                boxShadow: 24,
                p: 1,
              }}
              >
                <iframe
                  // eslint-disable-next-line tailwindcss/no-custom-classname
                  className='iframe'
                  width='100%'
                  height='100%'
                  style={{
                    position: 'relative',
                    zIndex: '30'
                  }}
                  src={htmlBlobUrl}
                >
                </iframe>
              </Box>
            </Modal>
            <Button
              variant='outlined'
              sx={{
                flexGrow: 1
              }}
            >
              Last Update |
              {' '}
              {formatDateInFormat(lastUpdate.toString(), 'HH:mm:ss')}
            </Button>
          </Stack>
          <LoadingButton
            variant='contained'
            color='warning'
            loading={loading}
            onClick={() => handleSaveChanges(true)}
          >
            Save Changes
          </LoadingButton>
          <LoadingButton
            variant='contained'
            color='info'
            disabled={loading}
            onClick={() => setOpenGeneratePreview(true)}
          >
            Generate Preview
          </LoadingButton>
          <LoadingButton
            variant='contained'
            color='error'
            disabled={loading}
            onClick={handleOpenPublish}
          >
            Publish LIVE
          </LoadingButton>
          <Dialog.Confirm
            open={openGeneratePreview}
            setOpen={setOpenGeneratePreview}
            isLoading={loading}
            texts={{
              title: 'Generate Preview',
              description: 'The preview process allows you to generate a preview of the website before it is sent to the hosting service (Vercel), which is linked to the client\'s actual domain. This way, you can review and validate changes with the client before proceeding with the publication. It\'s important to highlight that, unlike publications to our hosting service, which have a strict weekly limit, generating previews is not subject to any limits. This provides you with the flexibility to make all necessary revisions without restrictions.',
              confirm: 'Generate preview',
              cancel: 'Cancel'
            }}
            onConfirm={() => publishLiveWebsite(true)}
          />
          <Dialog.Confirm
            open={openPublish}
            setOpen={handleClosePublish}
            isLoading={loading}
            texts={{
              title: 'Publish the new website',
              description: 'Are you sure you want to publish the website and make changes to the site with the client\'s actual domain? Remember, we have a weekly limit on publications, so please avoid publishing more than twice for each client. Use the \'preview\' feature to generate unlimited websites previews',
              confirm: 'Yes, publish',
              cancel: 'I\'m not sure'
            }}
            onConfirm={() => publishLiveWebsite(false)}
          />
        </Stack>
      </Stack>
    </Drawer>
  )
}
