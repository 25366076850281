import { Box, styled } from '@mui/material'

import { THEME } from '#app/layouts/theme'

interface Props {
  isDesktop: boolean
}

export const ArchiveBox = styled(Box)<Props>`
  margin-bottom: 0;
  cursor: pointer;
  padding:  ${(props) => props.isDesktop ? '1rem 1rem 0rem 1rem' : '1rem 0rem 0rem 0.5rem'};
  &:hover {
    transition: 0.5s;
    background-color: ${THEME.COLORS.light.secondary};
  }
`
