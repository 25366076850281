import { useCallback, useState } from 'react'
import Highlight from 'react-highlight'
import { Card, Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'

import { Alert } from '#app/components/Alerts'
import { Btn } from '#app/components/Buttons'
import { Input } from '#app/components/Input'
import { AdvancedFilters, getNumberOfFilters, OwnersFilter } from '#app/forms/OwnersFilter'

function InputSearchDemo() {
  const [advancedFilters, setAdvancedFilters] = useState<AdvancedFilters>({
    cities: [],
    services: '',
    language: ''
  })

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.warn(e.target.value)
  }

  const onAdvancedFilterChange = (values?: AdvancedFilters, closeHandler?: () => void, query?: string) => {
    console.warn(values)
    setAdvancedFilters(values ?? {
      cities: [],
      services: '',
      language: ''
    })

    if (closeHandler) {
      closeHandler()
    }
  }

  return (
    <>
      <Typography variant='h5'>Input - Search</Typography>
      <Highlight>
        {`
import { Input } from '#app/components/Input'
import { AdvancedFilters, getNumberOfFilters, OwnersFilter } from '#app/forms/OwnersFilter'

const [advancedFilters, setAdvancedFilters] = useState<AdvancedFilters>({ cities: [], services: '', language: '' })

const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  console.info(e.target.value)
}

const onAdvancedFilterChange = (values?: AdvancedFilters, closeHandler?: () => void, query?: string) => {
  console.info(values)
  setAdvancedFilters(values ?? { cities: [], services: '', language: '' })

  if (closeHandler) {
    closeHandler()
  }
}

<Input.Search
  advancedFiltersApplied={getNumberOfFilters(advancedFilters)}
  hasAdvancedFilters={true}
  onFilterChange={onFilterChange}
  filterForm={
    <OwnersFilter
      filters={advancedFilters}
      onSubmit={onAdvancedFilterChange}
    />}
  placeholder={'Write your super search'}
/>
`}
      </Highlight>
      <Grid
        container
        mb={8}
      >
        <Grid
          item
          xs={12}
        >
          <Input.Search
            advancedFiltersApplied={getNumberOfFilters(advancedFilters)}
            hasAdvancedFilters={true}
            filterForm={(
              <OwnersFilter
                filters={advancedFilters}
                onSubmit={onAdvancedFilterChange}
              />
            )}
            placeholder='Write your super search'
            onFilterChange={onFilterChange}
          />
        </Grid>
      </Grid>
    </>
  )
}

export function InputsDemo() {
  const onSubmit = async (data: any, { resetForm }: any) => {
    console.info(data)
  }

  const InputRichText = useCallback(() => (
    <>
      <Typography variant='h5'>Input - RichTextArea</Typography>
      <Highlight>
        {`
import { Form, Formik } from 'formik'
import { Input } from '#app/components/Input'

<Formik
  initialValues={{ example: '' }}
  onSubmit={onSubmit}
>
  {({ isSubmitting }) => (
    <Form>
      <Input.RichTextArea name='example' label='Rich TextArea' />
      <Btn.Button disabled={isSubmitting} isLoading={isSubmitting} type="submit" >
        Submit
      </Btn.Button>
    </Form>
  )}
</Formik>
        `}
      </Highlight>

      <Grid
        container
        mb={8}
      >
        <Grid
          item
          xs={12}
        >
          <Formik
            initialValues={{
              example: ''
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Input.RichTextArea
                  name='example'
                  label='Rich TextArea'
                />
                <Btn.Button
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  Submit
                </Btn.Button>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  ), [])

  const InputGeneric = useCallback(() => (
    <>
      <Typography variant='h5'>Input - Generic</Typography>
      <Alert.Basic
        severity='info'
        titleJSX={(
          <>
            Please check all the types here
            <a
              href='https://www.w3schools.com/tags/att_input_type.asp'
              target='_blanket'
            >
              www.w3schools.com/tags/att_input_type
            </a>
          </>
        )}
      />
      <Highlight>
        {`
import { Form, Formik } from 'formik'
import { Input } from '#app/components/Input'

<Formik
  initialValues={{ example: '' }}
  onSubmit={onSubmit}
>
  {({ isSubmitting }) => (
    <Form>
      <Input.Generic type='text' name='example_text' label='Generic Text' />
      <Input.Generic type='number' name='example_number' label='Generic Number' />
      <Btn.Button disabled={isSubmitting} isLoading={isSubmitting} type="submit" >
        Submit
      </Btn.Button>
    </Form>
  )}
</Formik>
    `}
      </Highlight>

      <Grid
        container
        mb={8}
      >
        <Grid
          item
          xs={12}
        >
          <Formik
            initialValues={{
              example: ''
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Input.Generic
                  type='text'
                  name='example_text'
                  label='Generic Text'
                />
                <Input.Generic
                  type='number'
                  name='example_number'
                  label='Generic Number'
                />
                <Btn.Button
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  Submit
                </Btn.Button>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  ), [])

  const InputLabel = useCallback(() => (
    <>
      <Typography variant='h5'>Input - Label</Typography>
      <Highlight>
        {`
<Input.Label label="Owners" popoverText='Owners Popover' />
`}
      </Highlight>
      <Grid
        container
        mb={8}
      >
        <Grid
          item
          xs={12}
        >
          <Input.Label
            label='Owners'
            popoverText='Owners Popover'
          />
        </Grid>
      </Grid>
    </>
  ), [])

  const InputSelect = useCallback(() => (
    <>
      <Typography variant='h5'>Input - Select</Typography>
      <Highlight>
        {`
<Formik
  initialValues={{ example: ['red'] }}
  onSubmit={onSubmit}
>
{({ isSubmitting }) => (
  <Form>
    <Input.Select name='example' label="Owners" options={[{ value: 'red', text: 'Red' }, { value: 'blue', text: 'Blue' }]} />
    <Btn.Button disabled={isSubmitting} isLoading={isSubmitting} type="submit" >
      Submit
    </Btn.Button>
  </Form>
)}
</Formik>
`}
      </Highlight>
      <Grid
        container
        mb={8}
      >
        <Grid
          item
          xs={12}
        >
          <Formik
            initialValues={{
              example: ['red']
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Input.Select
                  name='example'
                  label='Owners'
                  options={[{
                    value: 'red',
                    text: 'Red'
                  }, {
                    value: 'blue',
                    text: 'Blue'
                  }]}
                />
                <Btn.Button
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  Submit
                </Btn.Button>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  ), [])

  const InputWithChips = useCallback(() => (
    <>
      <Typography variant='h5'>Input - Select Chips</Typography>
      <Highlight>
        {`
<Formik
  initialValues={{ services: ['Blue'] }}
  onSubmit={onSubmit}
>
{({ isSubmitting }) => (
  <Form>
    <Input.Chips
      name="services"
      label='Services'
      options={['Red', 'Blue', 'Green']}
      multiple={true}
      optionLabelHandler={undefined}
      limit={undefined}
    />
    <Btn.Button disabled={isSubmitting} isLoading={isSubmitting} type="submit" >
      Submit
    </Btn.Button>
  </Form>
)}
</Formik>
    `}
      </Highlight>

      <Grid
        container
        mb={8}
      >
        <Grid
          item
          xs={12}
        >
          <Formik
            initialValues={{
              services: ['Blue']
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Input.Chips
                  name='services'
                  label='Services'
                  options={['Red', 'Blue', 'Green']}
                  multiple={true}
                  optionLabelHandler={undefined}
                  limit={undefined}
                />
                <Btn.Button
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  Submit
                </Btn.Button>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  ), [])

  const InputDatePicker = useCallback(() => (
    <>
      <Typography variant='h5'>Input - Date Picker</Typography>
      <Highlight>
        {`
<Formik
  initialValues={{ event_date: null }}
  onSubmit={onSubmit}
>
  {({ isSubmitting }) => (
    <Form>
      <Input.DatePicker
        label={'Event Date'}
        name={'event_date'}
      />
      <Btn.Button disabled={isSubmitting} isLoading={isSubmitting} type="submit" >
        Submit
      </Btn.Button>
    </Form>
  )}
</Formik>
    `}
      </Highlight>

      <Grid
        container
        mb={8}
      >
        <Grid
          item
          xs={12}
        >
          <Formik
            initialValues={{
              event_date: null
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Input.DatePicker
                  label='Event Date'
                  name='event_date'
                />
                <Btn.Button
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  Submit
                </Btn.Button>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  ), [])

  const InputWithPlaces = useCallback(() => (
    <>
      <Typography variant='h5'>Input - Places</Typography>
      <Highlight>
        {`
<Formik
  initialValues={{ cities: [] }}
  onSubmit={onSubmit}
>
{({ isSubmitting }) => (
  <Form>
    <Input.Places
      label={'Cities'}
      name={'cities'}
      types={['locality']}
      limitTags={5}
      multiple
      fields={undefined}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      countries={undefined}
    />
    <Btn.Button disabled={isSubmitting} isLoading={isSubmitting} type="submit" >
      Submit
    </Btn.Button>
  </Form>
)}
</Formik>
    `}
      </Highlight>

      <Grid
        container
        mb={8}
      >
        <Grid
          item
          xs={12}
        >
          <Formik
            initialValues={{
              cities: []
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Input.Places
                  multiple
                  label='Cities'
                  name='cities'
                  types={['locality']}
                  limitTags={5}
                  fields={undefined}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                  countries={undefined}
                />
                <Btn.Button
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  Submit
                </Btn.Button>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  ), [])

  const InputPhoneNumber = useCallback(() => (
    <>
      <Typography variant='h5'>Input - Phone</Typography>
      <Highlight>
        {`
<Formik
initialValues={{ cities: [] }}
onSubmit={onSubmit}
>
{({ isSubmitting }) => (
  <Form>
    <Input.Phone
      name="phone"
      label="Personal Phone"
      styled={true}
    />
    <Btn.Button disabled={isSubmitting} isLoading={isSubmitting} type="submit" >
      Submit
    </Btn.Button>
  </Form>
)}
</Formik>
    `}
      </Highlight>

      <Grid
        container
        mb={8}
      >
        <Grid
          item
          xs={12}
        >
          <Formik
            initialValues={{
              phone: null
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Input.Phone
                  name='phone'
                  label='Personal Phone'
                  styled={true}
                />
                <Btn.Button
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  Submit
                </Btn.Button>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  ), [])

  const InputTextArea = useCallback(() => (
    <>
      <Typography variant='h5'>Input - TextArea</Typography>
      <Highlight>
        {`
<Formik
initialValues={{ cities: [] }}
onSubmit={onSubmit}
>
{({ isSubmitting }) => (
  <Form>
    <Input.Phone
      name="phone"
      label="Personal Phone"
      styled={true}
    />
    <Btn.Button disabled={isSubmitting} isLoading={isSubmitting} type="submit" >
      Submit
    </Btn.Button>
  </Form>
)}
</Formik>
    `}
      </Highlight>

      <Grid
        container
        mb={8}
      >
        <Grid
          item
          xs={12}
        >
          <Formik
            initialValues={{
              phone: null
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                {/* @ts-ignore */}
                <Input.TextArea
                  name='description'
                  label='Description'
                  minRows={4}
                  style={{
                    width: '100%'
                  }}
                />
                <Btn.Button
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  Submit
                </Btn.Button>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  ), [])

  const InputRadio = useCallback(() => (
    <>
      <Typography variant='h5'>Input - Radio Button</Typography>
      <Highlight>
        {`
<Formik
initialValues={{ phone: null }}
onSubmit={onSubmit}
>
{({ isSubmitting }) => (
  <Form>
    {/* @ts-ignore */}
    <Input.Radio name={'example'} initialValue={'yes'} label={'Create a new website?'}>
      {/* @ts-ignore */}
      <Input.Radio.RadioButton value="true">Yes</Input.Radio.RadioButton>
      {/* @ts-ignore */}
      <Input.Radio.RadioButton value="false">No</Input.Radio.RadioButton>
    </Input.Radio>
    <Btn.Button disabled={isSubmitting} isLoading={isSubmitting} type="submit" >
      Submit
    </Btn.Button>
  </Form>
)}
</Formik>
    `}
      </Highlight>

      <Grid
        container
        mb={8}
      >
        <Grid
          item
          xs={12}
        >
          <Formik
            initialValues={{
              phone: null
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                {/* @ts-ignore */}
                <Input.Radio
                  name='example'
                  initialValue='yes'
                  label='Create a new website?'
                >
                  {/* @ts-ignore */}
                  <Input.Radio.RadioButton value='true'>Yes</Input.Radio.RadioButton>
                  {/* @ts-ignore */}
                  <Input.Radio.RadioButton value='false'>No</Input.Radio.RadioButton>
                </Input.Radio>
                <Btn.Button
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  Submit
                </Btn.Button>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  ), [])

  const InputDoc = useCallback(() => (
    <>
      <Typography variant='h5'>Input - Source</Typography>
      <Highlight>
        {`
export const Input = {
  RichTextArea, // TS - Showcase
  Label: FormLabel, // TS - Showcase
  Generic: FormInput, // TS - Showcase
  Search: SearchInput, // TS - Showcase
  Select: FormSelect, // TS - Showcase
  Chips: InputWithChips, // JS! - Showcase
  Places: InputWithPlaces, // JS! - Showcase
  DatePicker: DatePickerInput, // JS! - Showcase
  Phone: PhoneNumberInput, // JS! - Showcase
  TextArea: FormTextArea, // JS! - Showcase
  Radio: RadioButtonGroup // JS! - Showcase
  // Query: InputWithQuery, // JS! - Not sure if it should be documented
  // Currency: CurrencyInput, // JS! - TODO: Check with Uli
}
    `}
      </Highlight>
      <Grid
        container
        mb={8}
      >

      </Grid>
    </>
  ), [])

  const InputImageUpload = useCallback(() => (
    <>
      <Typography variant='h5'>Input - Image Upload</Typography>
      <Highlight className='javascript'>
        {`
  <Formik
    initialValues={{ fileUrl: '' }}
    onSubmit={onSubmit}
  >
    {({ setFieldValue }) => (
      <Form>
        <Input.ImageUpload
          uploadPath="your/upload/path"
          userId="user123"
          onUploadComplete={(url) => setFieldValue('fileUrl', url)}
        />
      </Form>
    )}
  </Formik>
        `}
      </Highlight>

      <Grid
        container
        mb={8}
      >
        <Grid
          item
          xs={12}
        >
          <Formik
            initialValues={{
              fileUrl: ''
            }}
            onSubmit={onSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <Input.ImageUpload
                  uploadPath='your/upload/path'
                  userId='user123'
                  onUploadComplete={(url) => setFieldValue('fileUrl', url)}
                />
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  ), [])

  return (
    <Card sx={{
      p: 4
    }}
    >
      {InputDoc()}
      {InputLabel()}
      {InputRadio()}
      {InputGeneric()}
      {InputTextArea()}
      {InputRichText()}
      {InputSelect()}
      {InputWithChips()}
      {InputWithPlaces()}
      {InputDatePicker()}
      {InputPhoneNumber()}
      {InputImageUpload()}
      <InputSearchDemo />
    </Card>
  )
}
