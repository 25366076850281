import React, { forwardRef, InputHTMLAttributes, TextareaHTMLAttributes } from 'react'
import { Field, FieldConfig } from 'formik'
import { cn } from 'src/v2/libs/utils'

type InputProps = (InputHTMLAttributes<HTMLInputElement> | TextareaHTMLAttributes<HTMLTextAreaElement>) & {
  onEnterDown?: () => void // Custom prop to handle "Enter" key
} & FieldConfig

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, onEnterDown, disabled, name, ...props }, ref) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onEnterDown && onEnterDown()
      }
    }

    return (
      <Field
        ref={ref}
        disabled={disabled}
        type={type}
        className={cn(
          'flex h-10 w-full rounded-md border border-gray-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#304985] focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-800 dark:bg-gray-950 dark:ring-offset-gray-950 dark:placeholder:text-gray-400 dark:focus-visible:ring-gray-300',
          className,
        )}
        name={name}
        onKeyDown={handleKeyDown}
        {...props}
      />
    )
  },
)

Input.displayName = 'Input'

export { Input }
