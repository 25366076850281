import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type GetActivityCenterQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']
  category?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>
  lastWeek: Types.Scalars['timestamptz']
  read?: Types.InputMaybe<Array<Types.Scalars['Boolean']> | Types.Scalars['Boolean']>
}>

export type GetActivityCenterQuery = { __typename?: 'query_root', activities: Array<{ __typename?: 'activities', category?: string | undefined, created_at: any, created_by: any, data: any, deleted: boolean, id: any, metadata?: any | undefined, read: boolean, receiver_id?: any | undefined, source: string, type: string, updated_at: any, updated_by?: any | undefined }> }

export type GetOldActivitiesQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']
  category?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>
  lastWeek: Types.Scalars['timestamptz']
  lastMonth: Types.Scalars['timestamptz']
  limit: Types.Scalars['Int']
  offset: Types.Scalars['Int']
}>

export type GetOldActivitiesQuery = { __typename?: 'query_root', olderActivities: Array<{ __typename?: 'activities', category?: string | undefined, created_at: any, created_by: any, data: any, deleted: boolean, id: any, metadata?: any | undefined, read: boolean, receiver_id?: any | undefined, source: string, type: string, updated_at: any, updated_by?: any | undefined }> }

export type MarkActivitiesAsReadMutationVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['uuid']>
  activityIds?: Types.InputMaybe<Array<Types.Scalars['uuid']> | Types.Scalars['uuid']>
}>

export type MarkActivitiesAsReadMutation = { __typename?: 'mutation_root', update_activities_many?: Array<{ __typename?: 'activities_mutation_response', returning: Array<{ __typename?: 'activities', id: any }> } | undefined> | undefined }

export const GetActivityCenterDocument = gql`
    query getActivityCenter($userId: uuid!, $category: [String!], $lastWeek: timestamptz!, $read: [Boolean!]) {
  activities(
    where: {_and: {receiver_id: {_eq: $userId}, deleted: {_neq: true}, category: {_in: $category}, created_at: {_gte: $lastWeek}, read: {_in: $read}}}
    limit: 25
    order_by: {created_at: desc}
  ) {
    category
    created_at
    created_by
    data
    deleted
    id
    metadata
    read
    receiver_id
    source
    type
    updated_at
    updated_by
  }
}
    `

/**
 * __useGetActivityCenterQuery__
 *
 * To run a query within a React component, call `useGetActivityCenterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityCenterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityCenterQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      category: // value for 'category'
 *      lastWeek: // value for 'lastWeek'
 *      read: // value for 'read'
 *   },
 * });
 */
export function useGetActivityCenterQuery(baseOptions: Apollo.QueryHookOptions<GetActivityCenterQuery, GetActivityCenterQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetActivityCenterQuery, GetActivityCenterQueryVariables>(GetActivityCenterDocument, options)
}
export function useGetActivityCenterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityCenterQuery, GetActivityCenterQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetActivityCenterQuery, GetActivityCenterQueryVariables>(GetActivityCenterDocument, options)
}
export type GetActivityCenterQueryHookResult = ReturnType<typeof useGetActivityCenterQuery>
export type GetActivityCenterLazyQueryHookResult = ReturnType<typeof useGetActivityCenterLazyQuery>
export type GetActivityCenterQueryResult = Apollo.QueryResult<GetActivityCenterQuery, GetActivityCenterQueryVariables>
export const GetOldActivitiesDocument = gql`
    query getOldActivities($userId: uuid!, $category: [String!], $lastWeek: timestamptz!, $lastMonth: timestamptz!, $limit: Int!, $offset: Int!) {
  olderActivities: activities(
    where: {_and: {receiver_id: {_eq: $userId}, deleted: {_neq: true}, read: {_eq: true}, category: {_in: $category}, created_at: {_gte: $lastMonth, _lt: $lastWeek}}}
    limit: $limit
    offset: $offset
    order_by: {created_at: desc}
  ) {
    category
    created_at
    created_by
    data
    deleted
    id
    metadata
    read
    receiver_id
    source
    type
    updated_at
    updated_by
  }
}
    `

/**
 * __useGetOldActivitiesQuery__
 *
 * To run a query within a React component, call `useGetOldActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOldActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOldActivitiesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      category: // value for 'category'
 *      lastWeek: // value for 'lastWeek'
 *      lastMonth: // value for 'lastMonth'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetOldActivitiesQuery(baseOptions: Apollo.QueryHookOptions<GetOldActivitiesQuery, GetOldActivitiesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetOldActivitiesQuery, GetOldActivitiesQueryVariables>(GetOldActivitiesDocument, options)
}
export function useGetOldActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOldActivitiesQuery, GetOldActivitiesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetOldActivitiesQuery, GetOldActivitiesQueryVariables>(GetOldActivitiesDocument, options)
}
export type GetOldActivitiesQueryHookResult = ReturnType<typeof useGetOldActivitiesQuery>
export type GetOldActivitiesLazyQueryHookResult = ReturnType<typeof useGetOldActivitiesLazyQuery>
export type GetOldActivitiesQueryResult = Apollo.QueryResult<GetOldActivitiesQuery, GetOldActivitiesQueryVariables>
export const MarkActivitiesAsReadDocument = gql`
    mutation MarkActivitiesAsRead($userId: uuid, $activityIds: [uuid!]) {
  update_activities_many(
    updates: {where: {id: {_in: $activityIds}, receiver_id: {_eq: $userId}}, _set: {read: true}}
  ) {
    returning {
      id
    }
  }
}
    `
export type MarkActivitiesAsReadMutationFn = Apollo.MutationFunction<MarkActivitiesAsReadMutation, MarkActivitiesAsReadMutationVariables>

/**
 * __useMarkActivitiesAsReadMutation__
 *
 * To run a mutation, you first call `useMarkActivitiesAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkActivitiesAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markActivitiesAsReadMutation, { data, loading, error }] = useMarkActivitiesAsReadMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      activityIds: // value for 'activityIds'
 *   },
 * });
 */
export function useMarkActivitiesAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkActivitiesAsReadMutation, MarkActivitiesAsReadMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<MarkActivitiesAsReadMutation, MarkActivitiesAsReadMutationVariables>(MarkActivitiesAsReadDocument, options)
}
export type MarkActivitiesAsReadMutationHookResult = ReturnType<typeof useMarkActivitiesAsReadMutation>
export type MarkActivitiesAsReadMutationResult = Apollo.MutationResult<MarkActivitiesAsReadMutation>
export type MarkActivitiesAsReadMutationOptions = Apollo.BaseMutationOptions<MarkActivitiesAsReadMutation, MarkActivitiesAsReadMutationVariables>
