import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { enqueueSnackbar, SnackbarProvider } from 'notistack'

import { CenteredNavTitle } from '#app/v2/components/CenteredNavTitle'
import { HeaderSharedNavigation } from '#app/v2/components/HeaderSharedNavigation'
import { Snackbar } from '#app/v2/components/ui/snackbar'
import { data } from '#app/v2/pages/EditProfile/data'
import { EditProfile } from '#app/v2/pages/EditProfile/page'

export const StoryEditProfile = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const storyProps = useMemo(() => {
    const scenario = searchParams.get('scenario')

    const res = {
      editProfile: {
        avatar: data.profile.avatar,
        firstName: data.profile.firstName,
        lastName: data.profile.lastName,
        email: data.profile.email,
        phoneNumbers: data.profile.phoneNumbers,
        services: data.profile.services,
        onSubmit: async () => {
          await new Promise((resolve) => setTimeout(resolve, 2000))
          enqueueSnackbar('User Updated Correctly', {
            variant: 'success'
          })
        }
      },
      headerSharedNavigation: {
        unsavedChanges: false,
        backUrl: '/v2-ui/home',
        main: <CenteredNavTitle title={t('navigations.editProfile', 'Edit Profile')} />,
      },
    }

    switch (scenario) {
      case 'unsaved_changes':
        res.headerSharedNavigation.unsavedChanges = true
        break
      case 'no_picture':
        res.editProfile.avatar = ''
        break
      case 'no_services':
        res.editProfile.services = []
        break
    }

    return res
  }, [searchParams, t])

  return (
    <SnackbarProvider
      Components={{
        default: Snackbar,
        info: Snackbar,
        warning: Snackbar,
        success: Snackbar,
        error: Snackbar
      }}
    >
      <div className='min-h-screen bg-[#F7F9FB]'>
        {/* HEADER */}
        <HeaderSharedNavigation {...storyProps.headerSharedNavigation} />
        <EditProfile {...storyProps.editProfile} />
      </div>
    </SnackbarProvider>
  )
}
