import { useState } from 'react'
import Markdown from 'react-markdown'
import { useNavigate } from 'react-router-dom'
import parse, { domToReact, Element } from 'html-react-parser'
import IframeResizer from 'iframe-resizer-react'
import remarkGfm from 'remark-gfm'

import { helperRichContent, PreviewRichContentStyled } from '#app/feature/Tasks/ViewTask/PreviewRichContent'
import { ConvertPDF2 } from '#app/v2/assets/Icons'

const ParseStringHtml = ({ content }: { content: string }) => {
  const [isLoading, setIsLoading] = useState(true)
  if (!content) return <></>
  return (
    <>
      {
        parse(content, {
          replace: (node) => {
            const domNode = node as Element
            if (domNode?.name === 'p') {
              return <p className=' text-sm tracking-[0.02px] text-[#424242]'>{domToReact(domNode?.children)}</p>
            }
            if (domNode?.name === 'iframe') {
              return (
                <>
                  {isLoading
                  && (
                    <>
                      Loading...
                      {/* TODO: Implement Loading Skeleton */}
                    </>
                  )}
                  <IframeResizer
                    src={domNode?.attribs?.src}
                    style={{
                      width: '1px',
                      minWidth: '100%',
                      display: isLoading ? 'none' : 'block'
                    }}
                    heightCalculationMethod='lowestElement'
                    onLoad={() => setIsLoading(false)}
                  />
                </>
              )
            }
          },
        })
      }
    </>
  )
}

const ParseStringMarkdown = ({ markdownContent }: { markdownContent: string }) => {
  const navigate = useNavigate()

  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      components={{
        h1: function ({ ...props }) {
          return <h1 className='text-xl'>{props?.children}</h1>
        },
        h2: function ({ ...props }) {
          return <h2 className='text-lg'>{props?.children}</h2>
        },
        h3: function ({ ...props }) {
          return <h3 className='text-base'>{props?.children}</h3>
        },
        img: function ({ ...props }) {
          const source = props?.src?.replaceAll('&amp;', '&')
          return (
            <img
              className=''
              src={source}
            />
          )
        },
        p: function ({ ...props }) {
          return <p className='text-sm'>{props?.children}</p>
        },
        a: function ({ ...props }) {
          const link = String(props?.href?.replaceAll('&amp;', '&'))
          const elementName = String(props?.children)

          if (helperRichContent.isDoc(elementName ?? '')) return (
            <a
              className=' inline-flex cursor-pointer gap-0.5 border border-[#EFEFEF] bg-[#F5F5F5] p-1.5 text-xs/3 font-medium text-[#454545]'
              onClick={() => window.open(link, '_blank')}
            >
              <ConvertPDF2 className=' h-3 w-3 text-[#D85140]' />
              <span>{elementName}</span>
            </a>
          )
          if (helperRichContent.isYoutube(link ?? '')) return <PreviewRichContentStyled.Container><PreviewRichContentStyled.Video src={helperRichContent.getYoutube(link)} /></PreviewRichContentStyled.Container>
          if (helperRichContent.isVideoFile(elementName ?? '')) return (
            <video
              controls
              src={link}
              className='my-5 w-full rounded-2xl object-cover object-[50%_19.5%]'
            />
          )
          const isWizardLink = link.includes('/v2/wizard/')
          if (isWizardLink) {
            return (
              <a
                className='cursor-pointer text-blue-500 hover:underline'
                onClick={(e) => {
                  e.preventDefault() // Prevent the default link behavior
                  const url = new URL(link)
                  const path = `${url.pathname}${url.search}`
                  navigate(path)
                }}
              >
                {elementName}
              </a>
            )
          }

          return (
            <a
              className=' cursor-pointer text-blue-500 hover:underline'
              onClick={() => window.open(link, '_blank')}
            >
              {elementName}
            </a>
          )
        },
        blockquote: function ({ ...props }) {
          return (
            <blockquote className='my-4 space-y-4 border-s-4 border-[#6BE7BE] bg-[#304985]/5 p-4 text-xl font-medium italic leading-relaxed text-gray-900 '>
              {props.children}
            </blockquote>
          )
        },
        ul: function ({ ...props }) {
          return <ul className='list-[square] pl-4 text-sm  *:has-[li_ul]:list-[circle] *:has-[li_ul_li_ul]:list-disc '>{props?.children}</ul>
        },
        ol: function ({ ...props }) {
          return <ol className='list-[lower-roman] pl-4 text-sm  *:has-[li_ol]:list-[lower-latin] *:has-[li_ol_li_ol]:list-decimal '>{props?.children}</ol>
        },
        li: function ({ ...props }) {
          return <li>{props?.children}</li>
        },
        code: function ({ ...props }) {
          return <code className='bg-[#6BE7BE] p-0.5 text-[#304985]'>{props.children}</code>
        },
        pre: function ({ ...props }) {
          return <pre className=' rounded-sm bg-[#304985]/5 px-2 py-1 text-gray-900'>{props.children}</pre>
        },
        table: function ({ ...props }) {
          return <table className='w-full'>{props.children}</table>
        },
        thead: function ({ ...props }) {
          return <thead className=' pointer-events-none text-left'>{props.children}</thead>
        },
        th: function ({ ...props }) {
          return <th className=' border-b-2 border-[#304985]/10 p-2'>{props.children}</th>
        },
        tbody: function ({ ...props }) {
          return <tbody className=' divide-y divide-[#304985]/10'>{props.children}</tbody>
        },
        tr: function ({ ...props }) {
          return <tr className=' transition-colors even:bg-[#304985]/5 hover:bg-[#6BE7BE]/25'>{props.children}</tr>
        },
        td: function ({ ...props }) {
          return <td className='p-2'>{props.children}</td>
        },
      }}
    >
      {markdownContent}
    </Markdown>
  )
}

interface Props {
  htmlContent?: string
  markdownContent?: string
}

export function PreviewRichContentV2({ htmlContent, markdownContent }: Props) {
  return (
    <>
      {htmlContent && <ParseStringHtml content={htmlContent} />}
      {markdownContent && <ParseStringMarkdown markdownContent={markdownContent} />}
    </>
  )
}
