import { createContext, useEffect, useState } from 'react'
import Tracker from '@openreplay/tracker'
import * as Sentry from '@sentry/react'

interface OpenReplayContextInterface {
  tracker?: Tracker
}

const OpenReplayContext = createContext<OpenReplayContextInterface>({
  tracker: undefined
})

OpenReplayContext.displayName = 'OpenReplayContext'

interface Props {
  children: JSX.Element
}

export const OpenReplayProvider = ({ children }: Props) => {
  const [isLoading, setLoading] = useState<boolean>(true)
  const [tracker, setTracker] = useState<any>(undefined)
  useEffect(() => {
    const isProduction = process.env.REACT_APP_ENV === 'production'

    if (isLoading && !tracker && isProduction) {
      const replayTracker = new Tracker({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __DISABLE_SECURE_MODE: true,
        projectKey: 'M5an9ZtH6xJ6Anr1cWDh',
        onStart: ({ sessionToken }) => {
          Sentry.setTag('openReplaySessionToken', sessionToken)
          Sentry.setTag('openReplayUrl', replayTracker.getSessionURL())
        }
      })

      replayTracker
        .start()
        .then(() => {
          setTracker(replayTracker)
          setLoading(false)
        })
        .catch((err) => {
          console.error(err)
        })
    }

    if (!isProduction) {
      setLoading(false)
    }
  }, [])

  if (isLoading) {
    return <></>
  }

  return (
    <OpenReplayContext.Provider value={{
      tracker
    }}
    >
      { children }
    </OpenReplayContext.Provider>
  )
}

export default OpenReplayContext
