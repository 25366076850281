import { useContext } from 'react'

import ProfileContext from '#app/contexts/ProfileContext'
import { useGetChatQuery } from '#app/operations/chat/chat.queries.generated'

import { useStreamClient } from './useStreamClient'

export const useConnectStream = (userId?: string) => {
  const user = useContext(ProfileContext).getProfile?.user
  const userIdToUse = userId ?? user?.id ?? ''

  const { data } = useGetChatQuery({
    variables: {
      userId: userIdToUse
    }
  })

  const chat = data?.getChat?.chat

  const chatUser = {
    id: userIdToUse,
  }

  const client = useStreamClient({
    apiKey: process.env.REACT_APP_STREAM_API_KEY ?? '',
    user: chatUser,
    tokenOrProvider: chat?.token ?? ''
  })

  return {
    client,
    user
  }
}
