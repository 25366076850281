/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import Button from '#app/components/Buttons/Button'
import { CurrencyInput } from '#app/components/CurrencyInput/CurrencyInput'

import FormInput from '../../components/FormInput/FormInput'

export interface PaymentData {
  email: string
  amount: number
}

const CreatePayment = ({ submitCallback }: { submitCallback: (input: PaymentData) => Promise<any> }) => {
  const [isLoading, setLoading] = useState<boolean>(false)

  // eslint-disable-next-line @typescript-eslint/require-await
  const onSubmit = async (data: PaymentData, { resetForm }: any) => {
    setLoading(true)
    submitCallback(data)
      .then(() => {
        resetForm()
      })
      .catch((err) => {
        console.error(err.toString())
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const schema = Yup
    .object()
    .shape({
      email: Yup
        .string()
        .required('This is a required field')
        .email(),
      amount: Yup
        .number()
        .required('This is a required field')
    })

  const initialValues = {
    email: '',
    amount: 0
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Stack spacing={3}>
            <Typography fontWeight='bold'>
              Payment
            </Typography>
            <Typography>
              Fill in the email and amount you wish to pay me in the form below.
              This will help make sure that I receive your payment correctly and efficiently.
              Thanks so much for your cooperation!
            </Typography>
            <Stack>
              <FormInput
                type='text'
                label='Email'
                name='email'
              />
              <CurrencyInput
                label='Amount'
                name='amount'
              />
              <Button
                disabled={isLoading}
                isLoading={isLoading}
                type='submit'
              >
                Pay
              </Button>
            </Stack>
          </Stack>
        </Form>

      )}

    </Formik>
  )
}

export default CreatePayment
