/* eslint-disable @typescript-eslint/naming-convention */
// @ts-nocheck
import { createTheme } from '@mui/material'
import { blue, green, grey, red } from '@mui/material/colors'

export const MAX_WIDTH_MOBILE = '1200px'

interface COLORS_TYPE {
  dark: {
    [key: string]: string
  }
  light: {
    [key: string]: string
  }
  custom: {
    [key: string]: string
  }
}

export const COLORS: COLORS_TYPE = {
  // Ref: https://linear.app/owners/issue/ENG-947/decide-the-inputs-to-stick-with-mui
  // https://coolors.co/1f2638-495a7c-f7f9fc-22293e-dce0e6
  dark: {
    primary: '#495A7C',
    secondary: '#1F2638'
  },
  light: {
    primary: '#F7F9FC', // white
    secondary: '#f0f5f9' // grey
  },
  custom: {
    error: red[500],
    grey: grey[600],
    green: green[50],
    link: blue[600],
    audio: grey[100],
    disabled: 'rgba(0, 0, 0, 0.26)'
  }
}

const ICONS = {
  color: {
    primary: COLORS.dark.primary,
    secondary: COLORS.custom.grey,
    error: red[500]
  },
  size: {
    xs: '16px',
    sm: '20px',
    md: '26px',
    lg: '36px'
  }
}

const styleOverrides = {
  button: {
    textTransform: 'none',
    fontWeight: '700',
    fontSize: '12px',
    minHeight: '45px',
    minWidth: '150px',
    fontFamily: 'Inter',
    letterSpacing: '1px',
    wordSpacing: 'normal',
    // eslint-disable-next-line no-useless-computed-key
    [`@media (max-width: ${MAX_WIDTH_MOBILE})`]: {
      minWidth: '100px'
    }
  },
  input: {
    input: {
      fontSize: 16
    },
    textarea: {
      fontSize: 16
    }
  }
}

const MUI = {
  typography: {
    fontFamily: 'Inter, sans-serif',
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 400
  },
  palette: {
    type: 'light',
    primary: {
      main: COLORS.dark.primary,
      secondary: COLORS.dark.secondary
    }
  },
  components: {
    // MUI can't support overrides for hr with chips
    // MuiDivider: {
    //   styleOverrides: {
    //     root: {
    //       marginBottom: '1rem',
    //       marginTop: '1rem',
    //       backgroundColor: 'rgba(0, 0, 0, 0.6)',
    //       height: '1px'
    //     }
    //   }
    // },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: 'none'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: COLORS.custom.link,
            borderBottom: '1px solid ' + COLORS.custom.link
          }
        }
      },
      variants: [
        {
          props: {},
          style: {
            ...styleOverrides.button,
            marginRight: '1rem',
            minWidth: 'auto',
            color: COLORS.dark.primary
          }
        }
      ]
    },
    MuiMenuList: {
      variants: [
        {
          props: {},
          style: {
            paddingTop: '0px',
            paddingBottom: '0px'
          }
        }
      ]
    },
    MuiMenuItem: {
      variants: [
        {
          props: {},
          style: {
            paddingBottom: '0.75rem',
            paddingTop: '0.75rem'
          }
        }
      ]
    },
    MuiTypography: {
      variants: [
        {
          props: {
            variant: 'h1'
          },
          style: {
            fontSize: 48,
            fontWeight: '600',
            color: COLORS.dark.secondary,
            // eslint-disable-next-line no-useless-computed-key
            [`@media (max-width: ${MAX_WIDTH_MOBILE})`]: {
              fontSize: 38
            }
          }
        },
        {
          props: {
            variant: 'h2'
          },
          style: {
            fontSize: 36,
            fontWeight: '600',
            color: COLORS.dark.secondary,
            // eslint-disable-next-line no-useless-computed-key
            [`@media (max-width: ${MAX_WIDTH_MOBILE})`]: {
              fontSize: 32
            }
          }
        },
        {
          props: {
            variant: 'h3'
          },
          style: {
            fontSize: 28,
            fontWeight: '600',
            color: COLORS.dark.secondary,
            // eslint-disable-next-line no-useless-computed-key
            [`@media (max-width: ${MAX_WIDTH_MOBILE})`]: {
              fontSize: 24
            }
          }
        },
        {
          props: {
            variant: 'h4'
          },
          style: {
            fontSize: 22,
            fontStyle: 'normal',
            fontWeight: '500',
            color: COLORS.dark.secondary,
            // eslint-disable-next-line no-useless-computed-key
            [`@media (max-width: ${MAX_WIDTH_MOBILE})`]: {
              fontSize: 20
            }
          }
        },
        {
          props: {
            variant: 'h5'
          },
          style: {
            fontSize: 20,
            fontWeight: '500',
            color: COLORS.dark.secondary,
            // eslint-disable-next-line no-useless-computed-key
            [`@media (max-width: ${MAX_WIDTH_MOBILE})`]: {
              fontSize: 18
            }
          }
        },
        {
          props: {
            variant: 'h6'
          },
          style: {
            fontSize: 18,
            fontWeight: '500',
            color: COLORS.dark.secondary,
            // eslint-disable-next-line no-useless-computed-key
            [`@media (max-width: ${MAX_WIDTH_MOBILE})`]: {
              fontSize: 16
            }
          }
        },
        {
          props: {
            variant: 'subtitle1'
          },
          style: {
            color: COLORS.custom.grey,
            fontSize: 18
          }
        },
        {
          props: {
            variant: 'subtitle2'
          },
          style: {
            color: COLORS.custom.grey,
            fontSize: 16
          }
        },
        {
          props: {
            variant: 'button'
          },
          style: {
            color: COLORS.custom.link,
            fontSize: 16,
            textDecoration: 'underline',
            textTransform: 'initial'
          }
        }
      ]
    },
    MuiIconButton: {
      variants: [
        {
          props: {
            color: 'primary'
          },
          style: {
            backgroundColor: COLORS.dark.primary,
            color: COLORS.light.primary,
            '&:hover': {
              backgroundColor: COLORS.dark.primary,
              opacity: '0.8'
            },
            '&:disabled': {
              backgroundColor: '',
              opacity: '0.5'
            }
          }
        }
      ]
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...styleOverrides.button
        }
      },
      variants: [
        {
          props: {
            color: 'secondary',
            variant: 'contained'
          },
          style: {
            '&:hover': {
              backgroundColor: COLORS.light.primary,
              opacity: '0.9'
            },
            '&:disabled': {
              opacity: '0.9'
            },
            '&:active': {
              opacity: '0.4',
              color: COLORS.dark.secondary
            },
            backgroundColor: '#fff',
            color: COLORS.dark.primary,
            boxShadow: 'none'
          }
        },
        {
          props: {
            color: 'primary',
            variant: 'contained'
          },
          style: {
            '&:hover': {
              opacity: '0.7',
              transition: '0.5s',
              backgroundColor: COLORS.dark.primary
            }
          }
        }
      ]
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          ...styleOverrides.input
        }
      }
    }
  }
}

export const THEME = {
  MUI,
  ICONS,
  COLORS
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
export const theme = createTheme(THEME.MUI)
