import { useTranslation } from 'react-i18next'

import Images from '#app/v2/assets/Images'
import { PageWrapper } from '#app/v2/components/PageWrapper'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '#app/v2/components/ui/tabs'
import { DoneCard } from '#app/v2/pages/TaskList/components/DoneCard'
import { ToDoCard } from '#app/v2/pages/TaskList/components/ToDoCard'

import { TaskCardProps } from './types'

interface Props {
  tasks?: TaskCardProps[]
  doneTask?: TaskCardProps[]
  onClickURL: string // '/v2-ui/task-detail/{{task_id}}'
}

export const TaskList = ({ onClickURL, tasks, doneTask }: Props) => {
  const { t } = useTranslation()
  return (
    <PageWrapper pathname='/v2/tasks'>
      {/* Introduction */}
      <article className=' space-y-3 px-7 pb-6 pt-9'>
        <h1 className=' text-xl/6 font-semibold text-[#304985]'>{t('navigations.tasks', 'Tasks')}</h1>
        <h2 className=' font-medium leading-5 text-[#454545]'>{t('user_tasks.getStarted', 'Get started with your business!')}</h2>
        <p className=' text-sm/4 font-medium text-[#454545]'>{t('user_tasks.indication', 'Complete the tasks to unlock more!')}</p>
      </article>
      <Tabs
        defaultValue='to-do'
        className='min-h-[calc(100vh-268px)] px-7'
      >
        <TabsList className=' gap-3'>
          <TabsTrigger value='to-do'>{t('v2.tasks.option.todo', 'To-Do')}</TabsTrigger>
          <TabsTrigger
            value='done'
            disabled={doneTask?.length === 0}
          >
            {t('v2.tasks.option.done', 'Done')}
          </TabsTrigger>
        </TabsList>
        <TabsContent value='to-do'>
          {tasks && tasks.length > 0 && (
            <ul className='mt-5 space-y-3 pb-36'>
              {/* To-Do card */}
              {tasks?.map((task, i) => (
                <ToDoCard
                  key={i}
                  task={task}
                  onClickURL={onClickURL}
                />
              ))}
            </ul>
          )}
          {tasks && tasks.length === 0 && (
            <div className='mx-auto max-w-[17rem] text-center text-[#304985]'>
              <img
                src={Images.DoneTodoTasks}
                alt='Congratulations, no more tasks'
              />
              <h3 className=' text-xl/6 font-semibold  '>{t('user_tasks.completed.title', 'Great job!')}</h3>
              <p className='mt-2.5 text-base/5 font-medium '>{t('user_tasks.completed.description', 'You have completed all your tasks!')}</p>
            </div>
          )}
        </TabsContent>
        <TabsContent value='done'>
          {/* List of DONE cards */}
          <ul className='mt-5 space-y-3 pb-40'>
            {/* DONE card */}
            {doneTask && doneTask?.map((task, i) => (
              <DoneCard
                key={i}
                task={task}
                onClickURL={onClickURL}
              />
            ))}
          </ul>
        </TabsContent>
      </Tabs>
    </PageWrapper>
  )
}
