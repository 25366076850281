import { useTranslation } from 'react-i18next'

import { useIsDesktopSize } from '../../utils/mediaQueries'
import ArrowLeft from '../icons/ArrowLeft'
import ProgressBar from '../ProgressBar/ProgressBar'
const OnboardingHeader = ({ goBack, ...props }) => {
  const isDesktop = useIsDesktopSize()
  const { t } = useTranslation()
  const Mobile = () => (
    <div
      className='w-100 d-flex justify-content-between align-items-center flex-row flex-wrap'
      style={{
        height: '40px'
      }}
    >
      <div
        style={{
          width: '100%',
          marginBottom: '10px'
        }}
        className='d-flex justify-content-between align-items-center flex-row'
      >
        { (props.page > 1 && props.page < (props.totalPages)) && <ArrowLeft onClick={goBack} />}
        <p
          style={{
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '140%',
            color: '#4498E5',
            width: 'fit-content',
            textAlign: 'end',
            margin: '0',
            cursor: 'pointer'
          }}
          onClick={props.logout}
        >
          {t('onboarding.header.mobileSaveAndExit')}
        </p>
      </div>
      <ProgressBar
        bgcolor='#6EE39D'
        progress={props.progress}
        height='5px'
        width='90%'
      />
      <span style={{
        width: 'fit-content',
        fontSize: '12px'
      }}
      >
        {props.page}
        /
        {props.totalPages}
      </span>

    </div>
  )

  const Desktop = () => (
    <div
      className='w-100 d-flex flex-column justify-content-between align-items-center'
      style={{
        height: '70px'
      }}
    >
      <ProgressBar
        bgcolor='#6EE39D'
        progress={props.progress}
      />
      <p
        style={{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '140%',
          color: '#4498E5',
          width: '90%',
          textAlign: 'end',
          cursor: 'pointer'
        }}
        onClick={props.logout}
      >
        {t('onboarding.header.saveAndExit')}
      </p>
    </div>
  )

  if (isDesktop) { return (<Desktop />) }
  else { return (<Mobile />) }
}

export default OnboardingHeader
