import Images from '#app/v2/assets/Images'

import { modalShortcuts } from '../MyProfile/data'

export const data = {
  profile: {
    avatar: Images.SamBOProfile,
    fullName: 'Hector Hustler',
    services: 'Handyman',
    clientId: 456,
    ...modalShortcuts
  },
  todo:
    [
      // To-do
      {
        status: 'todo',
        id: ':task-id',
        profile: {
          avatar: Images.SamBOProfile,
          initials: 'SF',
        },
        sector: 'Onboarding',
        title: 'Approve Logo',
        description: '<p>Complete this first step to define your business brand.</p>',
        badges: ['New task', 'New comments'],
      },
      {
        status: 'todo',
        id: ':task-id',
        profile: {
          avatar: Images.SamBOProfile,
          initials: 'SF',
        },
        sector: 'Onboarding',
        title: 'Setup Banking',
        description: '<p>Setup your Banking so you are ready to receive payments for your work.</p>',
        badges: ['New comments'],
      },
      {
        status: 'todo',
        id: ':task-id',
        profile: {
          avatar: Images.SamBOProfile,
          initials: 'SF',
        },
        sector: 'Business Growth',
        title: 'Find Leads',
        description: 'Setup your Banking so you are ready to receive payments for your work.',
        // badges: [],
      },
      {
        status: 'todo',
        id: ':task-id',
        profile: {
          avatar: Images.SamBOProfile,
          initials: 'SF',
        },
        sector: 'Networking',
        title: 'Connect with 5 BOs in your area',
        description: 'Setup your Banking so you are ready to receive payments for your work.',
        badges: [],
      },
    ],
  done:
    // DONE
    [
      {
        status: 'done',
        id: ':task-id',
        sector: 'Onboarding',
        profile: {
          avatar: Images.SamBOProfile,
          initials: 'SF',
        },
        title: 'Approve Website',
        description: 'Let\u2019s give your business an Online Presence so your customers can find you.',
        // numberComments: '3',
        // numberFiles: '3',
        rating: undefined

      },
      {
        status: 'done',
        id: ':task-id',
        sector: 'Onboarding',
        profile: {
          avatar: Images.SamBOProfile,
          initials: 'SF',
        },
        title: 'Approve Website',
        description: 'Let\u2019s give your business an Online Presence so your customers can find you.',
        // numberComments: '3',
        // numberFiles: '3',
        rating: {
          score: '4',
          explanation: 'El video me ayudo bastante con el paso a paso.'
        }
      },
      {
        status: 'done',
        id: ':task-id',
        sector: 'Onboarding',
        profile: {
          avatar: Images.SamBOProfile,
          initials: 'SF',
        },
        title: 'Approve Website',
        description: 'Let\u2019s give your business an Online Presence so your customers can find you.',
        // numberComments: '3',
        // numberFiles: '3',
        rating: {
          score: '4',
          explanation: 'El video me ayudo bastante con el paso a paso.'
        }
      },
      {
        status: 'done',
        id: ':task-id',
        sector: 'Onboarding',
        profile: {
          avatar: Images.SamBOProfile,
          initials: 'SF',
        },
        title: 'Approve Website',
        description: 'Let\u2019s give your business an Online Presence so your customers can find you.',
        // numberComments: '3',
        // numberFiles: '3',
        rating: undefined
      },
    ],
}
