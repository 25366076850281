import create from 'zustand'
import { devtools } from 'zustand/middleware'

import { UserTask } from '#app/types/task'

export interface NameDomainSuggestion {
  name: string
  domain: string
}

export interface OnboardingNameDomainState {
  currentStep: string

  selectedSuggestion: number
  selectedDomain: number

  suggestionComponents: string[]
  generatedSuggestions: NameDomainSuggestion[]
  generatedDomainSuggestions: NameDomainSuggestion[]
  task?: UserTask
  type?: string
}

interface OnboardingNameDomainActions {
  setCurrentStep: (step: string) => void

  setSelectedSuggestion: (idx: number) => void
  setSelectedDomain: (idx: number) => void

  setGeneratedSuggestions: (data: OnboardingNameDomainState['generatedSuggestions']) => void
  setGeneratedDomainSuggestions: (data: OnboardingNameDomainState['generatedSuggestions']) => void

  setSuggestionComponents: (data: string[]) => void
  setTask: (data?: UserTask) => void
  setType: (type: string) => void
  reset: () => void
}

const initialState: OnboardingNameDomainState = {
  currentStep: 'select-options',
  selectedSuggestion: -1,
  selectedDomain: -1,
  suggestionComponents: [],
  generatedSuggestions: [],
  generatedDomainSuggestions: [],
  task: undefined,
  type: undefined
}

export const useOnboardingNameDomain = create<OnboardingNameDomainState & OnboardingNameDomainActions>()(
  devtools(
    (set) => ({
      ...initialState,

      setSelectedSuggestion(idx: number) {
        return set((state) => {
          return {
            ...state,
            selectedSuggestion: idx
          }
        })
      },

      setSelectedDomain(idx: number) {
        return set((state) => {
          return {
            ...state,
            selectedDomain: idx
          }
        })
      },

      setCurrentStep(data: string) {
        return set((state) => {
          return {
            ...state,
            currentStep: data
          }
        })
      },

      setGeneratedSuggestions(data: OnboardingNameDomainState['generatedSuggestions']) {
        return set((state) => {
          return {
            ...state,
            generatedSuggestions: data
          }
        })
      },

      setGeneratedDomainSuggestions(data: OnboardingNameDomainState['generatedSuggestions']) {
        return set((state) => {
          return {
            ...state,
            generatedDomainSuggestions: data
          }
        })
      },

      setSuggestionComponents(data: string[]) {
        return set((state) => {
          return {
            ...state,
            suggestionComponents: data
          }
        })
      },

      setTask(data) {
        return set((state) => {
          let updatedState = {
            ...state,
            task: data
          }

          if (data?.metadata?.suggestionComponents) {
            updatedState = {
              ...updatedState,
              suggestionComponents: data?.metadata?.suggestionComponents
            }
          }

          if (data?.metadata?.currentStep) {
            updatedState = {
              ...updatedState,
              currentStep: data?.metadata?.currentStep
            }
          }

          if (data?.metadata?.generatedSuggestions) {
            updatedState = {
              ...updatedState,
              generatedSuggestions: data?.metadata?.generatedSuggestions
            }
          }

          if (data?.metadata?.generatedDomainSuggestions) {
            updatedState = {
              ...updatedState,
              generatedDomainSuggestions: data?.metadata?.generatedDomainSuggestions
            }
          }

          if (data?.metadata?.selectedSuggestion) {
            updatedState = {
              ...updatedState,
              selectedSuggestion: data?.metadata?.selectedSuggestion
            }
          }

          if (data?.metadata?.selectedDomain) {
            updatedState = {
              ...updatedState,
              selectedDomain: data?.metadata?.selectedDomain
            }
          }

          return updatedState
        })
      },

      setType(type) {
        return set((state) => {
          return {
            ...state,
            type
          }
        })
      },

      reset() {
        set((state) => {
          return {
            ...state,
            ...initialState
          }
        })
      }
    }),
    {
      name: 'zt_owners_name_domain'
    }
  )
)
