/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { MutableRefObject, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { useSnackbar } from 'notistack'

import { LoadingGrid } from '#app/components/Loading/LoadingGrid'

import { PreviewModeLock } from './styles'

/*
  This component has been inspired from https://www.npmjs.com/package/@tylermenezes/cognitoforms-react

  Client-side events from Cognito here https://www.cognitoforms.com/support/62/data-integration/client-side-events
*/

/*

  How to use?

      <Cognitoform
        accountId={'tuM6Cj9OnEGTISluWGNaSQ'}
        formId={'5'}
        prefill={{
          USERID: 'test-userid',
          TASKID: taskContent.id
        }}
        beforeSubmit={(data: any) => {
          console.info('before submit', data)
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          data.preventDefault()
        }}
        onSubmit={(data: any) => {
          console.info('its submited', data)
        }}
      />

*/

/**
 * A CognitoForms iframe embed, with styling and prefills built-in!
 *
 * @param {object} params React params
 * @param {string} params.accountId           The CognitoForms account ID (the random text after /f/ in your embed code)
 * @param {string} params.formId              The form number (the integer in your embed code)
 * @param {string=} params.css                URL or CSS code to load in the form.
 * @param {object=} params.prefill            Object representing form fields to prefill on the page.
 * @param {React.Component} params.loading    React element to show when the form is loading.
 * @param {Function=} params.onReady          Function to run after the form is ready.
 * @param {Function=} params.onSubmit         Function to run after the form is submitted.
 * @param {Function=} params.onPageChange     Function to run after the page changes.
 * @returns {React.Component}                 React component.
 */

interface FormProps {
  accountId: string
  formId: string
  css?: string
  prefill?: any
  loading?: any
  onReady?: () => void
  beforeSubmit?: (data: any) => void
  onSubmit?: (data: any) => void
  onPageChange?: () => void
  previewMode?: boolean
  readMode?: boolean
}

function useId(parts: any[]) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  const genId = (parts: any[]) => 'id-' + parts.map((p) => p.toString().replace(/[^a-zA-Z0-9]/g, '')).join('-')
  return genId(parts)
}

export const Cognitoform = ({
  accountId,
  formId,
  css,
  prefill,
  loading,
  onReady,
  beforeSubmit,
  onSubmit,
  onPageChange,
  previewMode,
  readMode
}: FormProps) => {
  const SCRIPT_SRC = 'https://www.cognitoforms.com/f/seamless.js'

  const loadingFeedback = useMemo(() => {
    return (
      <>
        <LoadingGrid />
      </>
    )
  }, [])

  /**
 * Because we need to make sure the form <script> never re-renders, we need to use refs.
 */
  function useStateRef(val: any) {
    const ref = useRef(val)
    useEffect(() => { ref.current = val }, [val])
    return ref
  }

  const [isLoaded, setIsLoaded] = useState(false)
  const [isInited, setIsInited] = useState(false)
  const containerRef: MutableRefObject<{ height: number, overflow: string, style: any, children: any } | undefined> = useRef()

  // When accountId or formId changes, re-render the form.
  useEffect(() => {
    setIsLoaded(false)
    if (containerRef.current) {
      containerRef.current.height = 0
      containerRef.current.overflow = 'hidden'
    }
  }, [accountId, formId])

  // We can't use normal hooks because it would rerender the memo, so we'll periodically check
  // if the ref has initialized yet.
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-return
  const [retry, nextRetry] = useReducer((prev) => prev + 1, 0)
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (containerRef.current) return () => {}
    const retrierTimeout = setTimeout(nextRetry, 350 * retry)
    return () => clearTimeout(retrierTimeout)
  }, [retry])

  // Refs to prevent re-renders of the memo
  const setIsLoadedRef = useStateRef(setIsLoaded)
  const onReadyRef = useStateRef(onReady)
  const onSubmitRef = useStateRef(onSubmit)
  const beforeSubmitRef = useStateRef(beforeSubmit)
  const onPageChangeRef = useStateRef(onPageChange)
  const prefillRef = useStateRef(prefill)

  const { enqueueSnackbar } = useSnackbar()

  const id = useId([accountId, formId])

  useEffect(() => {
    if (!isInited) {
      setIsInited(true)
      console.info('[Cognito Owners] Form inited')
      const cfScript = document.createElement('script')
      cfScript.src = SCRIPT_SRC
      cfScript.dataset.key = accountId
      cfScript.dataset.form = formId
      cfScript.addEventListener('load', () => {
        window.Cognito
          .mount(formId.toString(), `#${id}`)
          .on('ready', () => {
            console.info('[Cognito Owners] Form ready')
            setIsLoadedRef.current(true)
            if (onReadyRef.current) onReadyRef.current()
            if (containerRef.current) {
              containerRef.current.style.height = 'auto'
              containerRef.current.style.overflow = 'initial'
            }
          })
          .on('beforeSubmit', (data: any) => {
            if (previewMode || readMode) {
              data.preventDefault()
              enqueueSnackbar('This form is in read mode', {
                variant: 'info'
              })
            }
            if (!previewMode && beforeSubmit) {
              beforeSubmitRef.current(data)
            }
          })
          .on('afterSubmit', (data: any) => {
            if (previewMode || readMode) {
              data.preventDefault()
              enqueueSnackbar('This form is in read mode', {
                variant: 'info'
              })
            }
            if (!previewMode && onSubmit) {
              onSubmitRef.current(data)
            }
          })
          .on('afterNavigate', (data: any) => {
            if (previewMode || readMode) {
              data.preventDefault()
              enqueueSnackbar('This form is in read mode', {
                variant: 'info'
              })
            }
            if (!previewMode && onPageChange) {
              onPageChangeRef.current()
            }
          })
          .prefill(prefillRef.current || {})
      })
      if (containerRef?.current?.children) {
        containerRef.current.children[0]?.appendChild(cfScript)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, accountId, containerRef.current, isInited])

  return (
    <Box sx={{
      position: 'relative'
    }}
    >
      { previewMode && <PreviewModeLock />}
      {!isLoaded && loadingFeedback}
      <style type='text/css'>{css}</style>

      <div
        /* @ts-ignore */
        ref={containerRef}
        id={useId([accountId, formId]) + '-parent'}
        style={{
          height: 0,
          overflow: 'hidden'
        }}
      >
        <div id={useId([accountId, formId])} />
      </div>
      <div style={{
        display: 'none'
      }}
      >
        {retry}
      </div>
    </Box>
  )
}
