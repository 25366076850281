import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldArray, Form, Formik, FormikProps } from 'formik'

import { Camera1, RecycleBin2 } from '#app/v2/assets/Icons'
import { PageWrapper } from '#app/v2/components/PageWrapper'
import { ProfileAvatarDetailed } from '#app/v2/components/ProfileAvatarDetailed'
import { Badge } from '#app/v2/components/ui/badge'
import { Button } from '#app/v2/components/ui/button'
import { Input } from '#app/v2/components/ui/input'
import { Label } from '#app/v2/components/ui/label'
import { cn } from '#app/v2/libs/utils'

export interface Props {
  avatar?: string
  firstName?: string
  lastName?: string
  email?: string
  notificationPhone?: string
  phoneNumbers?: string[]
  services?: string[]
  clientId?: number
  onSubmit: (data: any) => Promise<void>
  handleFileChange?: (e: ChangeEvent<HTMLInputElement>) => Promise<void>
  onChange?: (hasChanged: boolean, formikRef?: any | undefined) => void
  setSubmitForm?: (submitForm: any) => void
}

interface FormValues {
  firstName: string | undefined
  lastName: string | undefined
  email: string | undefined
  phones: string[]
  notificationPhone: string | undefined
  clientId: number | undefined
}

export const EditProfile = ({ avatar, firstName, lastName, email, phoneNumbers, services, notificationPhone, onSubmit, handleFileChange, clientId, onChange, setSubmitForm }: Props) => {
  const { t } = useTranslation()
  const formikRef = useRef<FormikProps<FormValues>>(null)

  const [initialValues, setInitialValues] = useState({
    firstName,
    lastName,
    email,
    phones: phoneNumbers || [],
    notificationPhone,
    clientId
  })

  useEffect(() => {
    setInitialValues({
      firstName,
      lastName,
      email,
      phones: phoneNumbers || [],
      notificationPhone,
      clientId
    })
  }, [firstName, lastName, email, phoneNumbers, notificationPhone, clientId])

  useEffect(() => {
    if (setSubmitForm)
      setSubmitForm(() => formikRef?.current?.submitForm)
  }, [setSubmitForm, formikRef?.current?.submitForm])

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      innerRef={formikRef}
      onSubmit={onSubmit}
    >
      {(props) => {
        const {
          values,
          /*
          initialValues,
          touched,
          errors,
          dirty,
          */
          isSubmitting,
          handleChange,
          handleSubmit,
          dirty
        } = props
        // Call onChange with the dirty state and current values
        if (onChange) {
          onChange(dirty)
        }
        return (
          <PageWrapper
            pathname='/v2/edit-profile'
            className='bg-[#F9F9F9] pb-8'
          >
            <div className='mx-7 mt-5 rounded-xl bg-white p-6 shadow'>
              <Form>
                <div className='relative mx-auto block h-28 w-28'>
                  <ProfileAvatarDetailed
                    onlyAvatar
                    fullName={`${firstName ?? ''} ${lastName ?? ''}`}
                    profilePictureUrl={avatar}
                    className='h-full w-full text-2xl'
                  />
                  <Button
                    size='icon'
                    className='absolute bottom-0 right-0 h-9 w-9 rounded-full'
                  >
                    <>
                      <Camera1 className=' h-6 w-6 stroke-white' />
                      <Input
                        name='avatar'
                        type='file'
                        accept='image/'
                        capture='environment'
                        className='absolute inset-0 z-0 opacity-0'
                        onChange={handleFileChange}
                      />
                    </>
                  </Button>
                </div>
                <section
                  id='inputs'
                  className='mt-7 space-y-4 font-medium text-[#454545]'
                >
                  { values.clientId && (
                    <div className='space-y-2'>
                      <Label
                        className='w-full'
                        htmlFor='clientId'
                      >
                        {t('v2Profile.editProfile.clientId', 'Client ID')}
                      </Label>
                      <Input
                        type='text'
                        id='clientId'
                        name='clientId'
                        value={values.clientId}
                        disabled={true}
                      />
                    </div>
                  )}
                  {/* Component for Label + Input ? */}
                  <div className='space-y-2'>
                    <Label
                      className='w-full'
                      htmlFor='firstName'
                    >
                      {t('profile.firstName', 'First Name')}
                    </Label>
                    <Input
                      type='text'
                      id='firstName'
                      name='firstName'
                      value={values.firstName}
                      placeholder={t('profile.firstName', 'First Name')}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='space-y-2'>
                    <Label
                      className='w-full'
                      htmlFor='lastName'
                    >
                      {t('profile.lastName', 'Last Name')}
                    </Label>
                    <Input
                      type='text'
                      id='lastName'
                      name='lastName'
                      value={values.lastName}
                      placeholder={t('profile.lastName', 'Last Name')}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='space-y-2'>
                    <Label
                      className='w-full'
                      htmlFor='email'
                    >
                      {t('profile.email', 'Email')}
                    </Label>
                    <Input
                      type='email'
                      id='email'
                      name='email'
                      value={values.email}
                      placeholder='Email'
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className='space-y-2'
                  >
                    <Label
                      className='w-full'
                      htmlFor='notificationPhone'
                    >
                      {`${t('profile.notificationPhoneNumber', 'Notification Phone Number')}`}
                    </Label>
                    {/*
                            - type tel for mobile browser to display only number keyboards (expo testing later)
                            TODO:
                            - Phone number formatting?
                          */}
                    <div className='flex gap-2'>
                      <Input
                        type='tel'
                        name='notificationPhone'
                        id='notificationPhone'
                        value={values.notificationPhone}
                        placeholder='612.123.4567'
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <FieldArray
                    name='phones'
                    render={(arrayHelpers) => (
                      <>
                        {values.phones && values.phones.length > 0 && values.phones.map((phoneNumber, i) => (
                          <div
                            key={i}
                            className='space-y-2'
                          >
                            <Label
                              className='w-full'
                              htmlFor={`phone${i}`}
                            >
                              {`${t('profile.phoneNumber', 'Phone Number')} #${i + 1}`}
                            </Label>
                            {/*
                            - type tel for mobile browser to display only number keyboards (expo testing later)
                            TODO:
                            - Phone number formatting?
                          */}
                            <div className='flex gap-2'>
                              <Input
                                type='tel'
                                name={`phones.${i}`}
                                id={`phone${i}`}
                                value={phoneNumber}
                                placeholder='612.123.4567'
                                disabled={phoneNumbers?.includes(phoneNumber)}
                                onChange={handleChange}
                              />
                              <Button
                                type='button'
                                className={cn('px-2 text-red-600', {
                                  hidden: values.phones?.length,
                                })}
                                variant='ghost'
                                onClick={() => arrayHelpers.remove(i)}
                              >
                                <RecycleBin2 className=' h-5 w-5 ' />
                              </Button>
                            </div>
                          </div>
                        ))}
                        <Button
                          variant='outline'
                          type='button'
                          className={cn(' rounded-lg border-[#304985] p-1.5 text-sm font-semibold text-[#304985]', {
                            hidden: values.phones?.length === 3,
                          })}
                          onClick={() => arrayHelpers.push('')}
                        >
                          {t('profile.addAnother', 'Add another')}
                        </Button>
                        {values.phones?.length === 3 && (
                          <span className=' mt-4 text-xs font-medium text-[#959595]'>
                            {t('profile.maxNumbers', 'You have reached the maximum number available')}
                          </span>
                        )}
                      </>
                    )}
                  />
                  <div className='space-y-2'>
                    <Label>{t('profile.services', 'Services')}</Label>
                    <div className='flex flex-wrap gap-x-3 gap-y-2 '>
                      {services && services.map((service) => (
                        <Badge
                          key={service}
                          variant='secondary'
                          className='bg-[#6BE7BE]/60 text-xs/4 font-bold text-[#304985]'
                        >
                          {service}
                        </Badge>
                      ))}
                    </div>

                  </div>
                </section>
              </Form>
            </div>
            <Button
              type='submit'
              className='mx-auto mt-8 block min-w-[50%] py-3.5 font-bold'
              /*
                Bug:
                  - When values change but is ''manually'' reseted, the button is still available (different pointers?)
              */
              disabled={isSubmitting}
              onClick={() => {
                handleSubmit()
              }}
            >
              {t('generic.save', 'Save')}
            </Button>
          </PageWrapper>
        )
      }}
    </Formik>

  )
}
