import { useEffect, useMemo, useState } from 'react'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { useParams, useSearchParams } from 'react-router-dom'
import { Box } from '@mui/material'
import Cookies from 'universal-cookie'
import { useAnalytics } from 'use-analytics'

import { NotFoundPage } from '#app/routes/NotFoundPage'

const GOOGLE_CONFIG = {
  COOKIE_KEY: 'rwg_token',
  URL: process.env.REACT_APP_ENV === 'production'
    ? 'https://www.google.com/maps/conversion/collect'
    : 'https://www.google.com/maps/conversion/debug/collect',
  PARTNER_ID_GOOGLE: '20002461', // prod, staging, dev
  TEST_RWG_TOKEN: 'ADQ7psRE9YyDSVR6YpfD-fYdxoFYVKS1xeTvXdSxqF8a3bnk0W62eMEnUjoNPwjhNHG0elwBnM1awTjr9vXET8yOowCeuODjwA=='
}

const cookies = new Cookies()

export function CalendlyEmbed() {
  const { calendlySlug, calendlyEventType } = useParams()
  const { track } = useAnalytics()
  const [searchParams] = useSearchParams()
  const isDev = process.env.REACT_APP_ENV !== 'production'
  const [merchantChanged, setMerchantChanged] = useState<1 | 2>(2) // https://developers.google.com/actions-center/verticals/appointments/redirect/integration-steps/conversion-tracking?hl=es-419

  const utmSource = useMemo(() => {
    if (searchParams.get('utm_source') && searchParams.get('utm_source') !== null) return searchParams.get('utm_source') as string
    return 'mini_app_owners'
  }, [searchParams])

  const rwgTokenCookie: string | undefined = useMemo(() => {
    let res = cookies.get(GOOGLE_CONFIG.COOKIE_KEY) as string
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    isDev && console.log('rwgTokenCookie: ' + res)
    if (isDev) res = GOOGLE_CONFIG.TEST_RWG_TOKEN
    return res
  }, [isDev])

  useEffect(() => {
    const cookieKey = 'googleMerchantChanged'
    const merchantChanged = cookies.get(cookieKey) as string

    if (merchantChanged && merchantChanged !== calendlySlug) {
      cookies.set(cookieKey, calendlySlug, {
        maxAge: 2592000,
        domain: window.location.hostname,
        path: '/'
      })
      setMerchantChanged(2)
    }
  }, [calendlySlug])

  useEffect(() => {
    const res = searchParams.get(GOOGLE_CONFIG.COOKIE_KEY)

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    if (res) {
      cookies.set(GOOGLE_CONFIG.COOKIE_KEY, res, {
        maxAge: 2592000,
        domain: window.location.hostname,
        path: '/'
      })
    }
  }, [searchParams])

  useCalendlyEventListener({
    onProfilePageViewed: () => {
      void track('calendly-embed-1-profile-page-viewed', {
        slug: calendlySlug,
        eventType: calendlyEventType,
        rwgToken: rwgTokenCookie,
        merchant_changed: merchantChanged
      })
      isDev && console.log('1.onProfilePageViewed')
    },
    onEventTypeViewed: () => {
      void track('calendly-embed-2-event-type-viewed', {
        slug: calendlySlug,
        eventType: calendlyEventType,
        rwgToken: rwgTokenCookie,
        merchant_changed: merchantChanged
      })
      isDev && console.log('2.onEventTypeViewed')
    },
    onDateAndTimeSelected: () => {
      void track('calendly-embed-3-date-and-time-selected', {
        slug: calendlySlug,
        eventType: calendlyEventType,
        rwgToken: rwgTokenCookie,
        merchant_changed: merchantChanged
      })
      isDev && console.log('3.onDateAndTimeSelected')
    },
    onEventScheduled: async (e) => {
      void track('calendly-embed-4-event-scheduled', {
        slug: calendlySlug,
        eventType: calendlyEventType,
        rwgToken: rwgTokenCookie,
        merchant_changed: merchantChanged
      })
      isDev && console.log('4.onEventScheduled')

      if (typeof rwgTokenCookie !== 'undefined') {
        const res = await fetch(GOOGLE_CONFIG.URL, {
          method: 'POST',
          body: JSON.stringify({
            conversion_partner_id: GOOGLE_CONFIG.PARTNER_ID_GOOGLE,
            rwg_token: rwgTokenCookie,
            merchant_changed: merchantChanged
          })
        })
        isDev && console.log(res)
      }
      // e.data.payload
    }
  })

  const fullUrl = useMemo(() => {
    let url = ''
    if (calendlySlug) url = `https://calendly.com/${calendlySlug}`
    if (calendlyEventType) url = `${url}/${calendlyEventType}`
    return url === '' ? null : url
  }, [calendlyEventType, calendlySlug])

  return (
    <Box>
      {
        !fullUrl && <NotFoundPage />
      }
      {
        fullUrl
        && (
          <InlineWidget
            url={fullUrl}
            utm={{
            // utmCampaign: 'Spring Sale 2019',
            // utmContent: 'Shoe and Shirts',
            // utmMedium: 'Ad',
            // utmTerm: 'Spring'
              utmSource
            }}
          />
        )
      }
    </Box>
  )
}
