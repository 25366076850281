import { useEffect, useRef, useState } from 'react'
import { BsArrowClockwise, BsArrowCounterclockwise, BsFillPauseFill, BsFillPlayFill } from 'react-icons/bs'
import { Grid, Slider, Typography } from '@mui/material'

import { THEME } from '#app/layouts/theme'

export interface AudioPlayerProps {
  src: string
  style: any
}

export const AudioPlayer = ({ src, style }: AudioPlayerProps) => {
  const [playbackSpeed, setPlayBackSpeed] = useState<number>(1)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [duration, setDuration] = useState<number>(0)
  const [currentTime, setCurrentTime] = useState<number>(0)
  const audioRef = useRef<any>(null)
  const animationRef = useRef<any>(null)
  const [progressBarValue, setProgressBarValue] = useState(0)

  useEffect(() => {
    const seconds = Math.floor(audioRef.current.duration)
    setDuration(seconds)
  }, [audioRef?.current?.loadedmetadata, audioRef?.current?.readyState])

  const calculateTime = (sec: number) => {
    const minutes = Math.floor(sec / 60)
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
    const seconds = Math.floor(sec % 60)
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
    return `${returnedMinutes}:${returnedSeconds}`
  }
  useEffect(() => {
    const audio = audioRef.current
    audio.playbackRate = playbackSpeed
  }, [playbackSpeed])

  useEffect(() => {
    setCurrentTime(progressBarValue)
  }, [progressBarValue])

  const changeSpeed = () => {
    if (playbackSpeed >= 2) {
      setPlayBackSpeed(0.5)
    }
    else setPlayBackSpeed(playbackSpeed + 0.5)
  }

  const jump = (time: string) => {
    const audio = audioRef.current
    if (audio.currentTime < 1) {
      audio.currentTime = 1
    }
    if (time == 'back') {
      audio.currentTime = audio.currentTime - 15
    }
    else if (time == 'fwd') {
      audio.currentTime = audio.currentTime + 15
    }
    setCurrentTime(audio.currentTime)
    setProgressBarValue(audio.currentTime)
  }

  const playAudio = () => {
    const prevValue = isPlaying
    const audio = audioRef.current
    setIsPlaying(!prevValue)
    if (!prevValue) {
      audio.play()
      animationRef.current = requestAnimationFrame(whilePlaying)
    }
    else {
      audio.pause()
      cancelAnimationFrame(animationRef.current)
    }
  }

  const changeRange = (_e: any, value: any) => {
    audioRef.current.currentTime = value
    setCurrentTime(value)
    setProgressBarValue(value)
  }

  const whilePlaying = () => {
    setProgressBarValue(audioRef.current.currentTime)
    animationRef.current = requestAnimationFrame(whilePlaying)
  }

  return (
    <Grid
      container
      sx={style}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row'
        }}
      >
        <Grid
          item
          textAlign='center'
          xs={10}
        >
          <Slider
            aria-label='time-indicator'
            defaultValue={0}
            max={isNaN(duration) ? 1 : duration}
            min={0}
            size='small'
            step={1}
            sx={{
              padding: 0
            }}
            value={progressBarValue}
            onChange={changeRange}
          />

        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          'flex-flow': 'row wrap',
          'align-items': 'center',
          'justify-content': 'center'
        }}
        xs={12}
      >
        <Grid
          item
          textAlign='left'
          xs={8}
        >
          <Typography
            fontSize={THEME.MUI.typography.fontSize}
            fontWeight={THEME.MUI.typography.fontWeightMedium}
          >
            { calculateTime(currentTime)}
          </Typography>

        </Grid>
        <audio
          ref={audioRef}
          preload='metadata'
          src={src}
        >
        </audio>
        <Grid
          item
          textAlign='center'
          xs={2}
        >
          <Typography
            fontSize={THEME.MUI.typography.fontSize}
            sx={{
              cursor: 'pointer',
              width: 'fit-content',
              margin: 'auto'
            }}
            onClick={changeSpeed}
          >
            {playbackSpeed}
            x
          </Typography>

        </Grid>
      </Grid>
      <Grid
        container
        alignItems='center'
        display='flex'
        justifyContent='space-between'
      >
        <Grid
          item
          padding='.2rem'
          textAlign='right'
          xs={4}
        >
          <BsArrowCounterclockwise
            className='v1-audio-hover p-1'
            cursor='pointer'
            fontSize={THEME.ICONS.size.md}
            onClick={() => jump('back')}
          />
        </Grid>
        <Grid
          item
          textAlign='center'
          xs={2}
        >
          {isPlaying
            ? (
              <BsFillPauseFill
                className='v1-audio-hover p-1'
                cursor='pointer'
                fontSize={THEME.ICONS.size.lg}
                onClick={() => playAudio()}
              />
            )
            : (
              <BsFillPlayFill
                className='v1-audio-hover p-1'
                cursor='pointer'
                fontSize={THEME.ICONS.size.lg}
                onClick={() => playAudio()}
              />
            )}
        </Grid>
        <Grid
          item
          textAlign='left'
          xs={4}
        >
          <BsArrowClockwise
            className='v1-audio-hover p-1'
            cursor='pointer'
            fontSize={THEME.ICONS.size.md}
            onClick={() => jump('fwd')}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
