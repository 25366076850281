import { useEffect } from 'react'
import styled from '@emotion/styled'

import { useAppContext } from '#app/contexts/AppContext/useAppContext'
import Messages from '#app/v2-core/pages/Messages/page'

export const ModalWrapper = styled.div`

  height: 70vh;

  .str-chat__modal--open .str-chat__modal__inner {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    width: 60%;
    width: -webkit-fill-available;
  }
`

export function ChatWrapper() {
  const { V1_SET_LOADING } = useAppContext()

  useEffect(() => {
    V1_SET_LOADING(false)
  }, [V1_SET_LOADING])

  return (
    <>
      <ModalWrapper className='col-12'>
        <Messages />
      </ModalWrapper>
    </>
  )
}
