import { HtmlHTMLAttributes } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { AccordionContent, AccordionItem, AccordionTrigger } from '#app/v2/components/ui/accordion'
import { Button } from '#app/v2/components/ui/button'
import { DrawerClose } from '#app/v2/components/ui/drawer'
import { Label } from '#app/v2/components/ui/label'
import { Switch } from '#app/v2/components/ui/switch'

export interface ShortcutProps {
  label?: string
  icon?: ({ className }: HtmlHTMLAttributes<HTMLOrSVGElement>) => JSX.Element
  onClick?: () => void
  url?: string
  hasSwitch?: {
    onClickSwitch?: () => void
    valueSwitch?: boolean
  }
  childrens?: ShortcutProps[]
}

export const Shortcut = ({ label, childrens, hasSwitch, icon, url, onClick }: ShortcutProps) => {
  const { pathname } = useLocation()
  if (childrens && label && icon) {
    return (
      <AccordionItem
        value={label}
        className=' border-none'
      >
        <AccordionTrigger className='px-3 py-1.5'>
          <span className=' inline-flex items-center gap-2.5'>
            {icon({
              className: 'h-6 w-6 '
            })}
            {label}
          </span>
        </AccordionTrigger>
        <AccordionContent>
          <div className=' ml-9 space-y-1.5 py-2.5 text-lg/5 text-[#304985]'>
            {childrens?.map((child) => {
              if (child.url) {
                return (
                  <Button
                    key={child.label}
                    asChild
                    variant='ghost'
                    className='block w-fit px-2.5 py-1'
                  >
                    <Link to={child.url}>
                      {child.label}
                    </Link>
                  </Button>
                )
              }
            })}
          </div>
        </AccordionContent>
      </AccordionItem>
    )
  }
  // Switch
  if (hasSwitch && icon) {
    return (
      <Button
        asChild
        variant='ghost'
        className='gap-2.5 px-3 py-1.5 text-lg/5'
      >
        <Label>
          {icon({
            className: 'h-6 w-6'
          })}
          {label}
          <Switch
            defaultChecked={hasSwitch.valueSwitch}
            onClick={hasSwitch.onClickSwitch}
          />
        </Label>
      </Button>
    )
  }
  // Single link
  if (url && icon) {
    return (
      <div>
        <DrawerClose asChild>
          <Button
            asChild
            variant='ghost'
            className='gap-2.5 px-3 py-1.5 text-lg/5'
          >
            <Link
              to={url}
              state={{
                from: pathname,
                to: url
              }}
            >
              {icon({
                className: 'h-6 w-6'
              })}
              {label}
            </Link>
          </Button>
        </DrawerClose>
      </div>
    )
  }
  // Single On click
  if (onClick && icon) {
    return (
      <div>
        <Button
          asChild
          variant='ghost'
          className='gap-2.5 px-3 py-1.5 text-lg/5'
          onClick={onClick}
        >
          <Link to='#'>
            {icon({
              className: 'h-6 w-6'
            })}
            {label}
          </Link>
        </Button>
      </div>
    )
  }

  return <>Error</>
}
