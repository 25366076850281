/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect, useState } from 'react'
import { BsArrowCounterclockwise, BsCardList, BsChatLeft, BsFillTelephonePlusFill, BsLink45Deg, BsLock, BsTrashFill, BsUnlock } from 'react-icons/bs'
import { FaChrome, FaTasks } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Avatar, Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'

import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import { AltBoProfile } from '#app/feature/AltBoProfile'
import LinkPhoneOwner from '#app/forms/OwnersManagement/LinkPhoneOwner'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { Where } from '#app/hooks/useOwners'
import { useResetSalesDemoUserMutation } from '#app/operations/users/users.mutations.generated'
import { User } from '#app/types'
import { useIsDesktopSize } from '#app/utils/mediaQueries'
import Messages from '#app/v2-core/pages/Messages/page'

import { useOnboardingFormReducer } from '../../../hooks/useOnboardingFormReducer'
import { GET_PROFILE } from '../../../utils/graphqlQueries'
import { ChatChannel } from '../Chat'

import { DialogResetDemoUser } from './DialogResetDemoUser'

const ChatContainerWrapper = styled.div`
  .str-chat__container {
    height: 90vh;
  }
`

export default function OwnerDetails({ currentOwner: owner, where, onDelete, onBlock }: { currentOwner?: User.UserType, where?: Where, onDelete?: () => void, onBlock?: () => void }) {
  const isDesktop = useIsDesktopSize()
  const { isSalesDemoAccount, currentIsSalesDemoAccount, getSalesDemoEmail, getServiceSalesDemoEmail, demoAltBoEmail, userEmail } = useEntityUser()

  const [loadOwner, { called, loading, data, refetch }] = useLazyQuery(GET_PROFILE, {
    variables: {
      userId: owner?.id
    }
  })

  // convert it to the format it was required before
  // const data = { users: [queryResults?.getProfile?.user] }

  // Demo Accounts
  const [resetSalesDemoUser, { loading: loadingResetSalesDemoUser }] = useResetSalesDemoUserMutation()
  const [openResetSalesDemoUser, setOpenResetSalesDemoUser] = useState<boolean>(false)

  const getPlanLabel = () => {
    return selectedOwner
      ?.businesses[0]
      ?.subscriptions
      ?.map((subscription) => {
        return subscription.plan
      })
      .join(' + ')
  }

  // DATA TO RENDER THE PAGE
  const [onboarding, dispatch] = useOnboardingFormReducer()
  const [selectedOwner, setSelectedOwner] = useState<User.UserType | undefined>(owner)
  // const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const [sideBarStatus, setSideBarStatus] = useState<boolean>()
  const [showCreate, setShowLinkPhone] = useState<boolean>(false)
  const [load, setLoad] = useState<boolean>(false)
  const [plan] = useState<string | undefined>(getPlanLabel())
  const [drawerOpen, setDrawerOpen] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setLoad(true)
    refetch().finally(() => {
      setTimeout(() => { setLoad(false) }, 500)
    })
  }, [selectedOwner])

  useEffect(() => {
    if (data) {
      setSelectedOwner(data?.getProfile?.user)
    }
  }, [data])

  const { enqueueSnackbar } = useSnackbar()

  if (!selectedOwner && !called && owner?.id) {
    loadOwner()
  }

  if (loading || !selectedOwner) {
    return <div className='bo-info position-relative'></div>
  }

  const linkPhoneHandler = () => {
    setShowLinkPhone(true)
    setSideBarStatus(true)
  }

  const submitCallback = (error: unknown) => {
    if (!error) {
      enqueueSnackbar('Phone linked successfully', {
        variant: 'success'
      })
      setSideBarStatus(false)
    }
  }

  if (load) return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      sx={{
        minHeight: 200
      }}
    >
      <CircularProgress />
    </Box>
  )

  return (
    <Grid
      container
      direction='column'
      spacing={4}
    >
      <Grid item>
        <Grid
          container
          direction='row'
          justifyContent={isDesktop ? 'space-between' : 'flex-start'}
          spacing={2}
        >
          <Grid item>
            <Stack
              direction='row'
              spacing={2}
              alignItems='center'
            >
              <Avatar src={selectedOwner.profile_picture_url} />
              <Stack
                direction='column'
                spacing={2}
              >
                <Typography variant='caption'>
                  {`ID: ${owner?.id}`}
                </Typography>
                <Typography variant='subtitle1'>
                  {`${selectedOwner.first_name ?? ''}` + ' ' + `${selectedOwner.last_name ?? ''}`}
                </Typography>
                <Stack
                  direction='row'
                  spacing={1}
                >
                  {owner?.status && (
                    <Box sx={{
                      maxWidth: 160
                    }}
                    >
                      <Chip.Basic
                        label={owner?.status}
                        color='warning'
                      />
                    </Box>
                  )}
                  {
                    owner?.blocked && (
                      <Box sx={{
                        maxWidth: 160
                      }}
                      >
                        <Chip.Basic
                          label={owner?.blocked && 'Blocked'}
                          color='error'
                        />
                      </Box>
                    )
                  }
                  {
                    !selectedOwner?.sync_calendar && (
                      <Box sx={{
                        maxWidth: 160
                      }}
                      >
                        <Chip.Basic
                          label='Calendar not synced'
                          color='error'
                        />
                      </Box>
                    )
                  }
                  {
                    owner?.email && isSalesDemoAccount(owner?.email) && (
                      <Box sx={{
                        maxWidth: 160
                      }}
                      >
                        <Chip.Basic
                          label='DEMO'
                          color='success'
                        />
                      </Box>
                    )
                  }
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          {
            owner?.id
            && (
              <DialogResetDemoUser
                ownerId={owner.id}
                resetSalesDemoUser={resetSalesDemoUser}
                openResetSalesDemoUser={openResetSalesDemoUser}
                setOpenResetSalesDemoUser={setOpenResetSalesDemoUser}
              />
            )
          }
          <Grid
            item
            display='flex'
            justifyContent='end'
            alignItems='center'
          >
            <Stack
              direction='row'
              spacing={1}
            >
              {
                owner?.email && isSalesDemoAccount(owner?.email)
                && (
                  <Btn.ActionButton
                    forceMobile
                    isLoading={loadingResetSalesDemoUser}
                    icon={<BsArrowCounterclockwise />}
                    onClick={() => setOpenResetSalesDemoUser(true)}
                  >
                  </Btn.ActionButton>
                )
              }
              <Btn.ActionButton
                forceMobile
                icon={<FaChrome />}
                onClick={() => navigate(`/website-editor/${owner?.id}`)}
              />
              <Btn.ActionButton
                forceMobile
                icon={<FaTasks />}
                onClick={() => navigate(`/owners-tasks/${owner?.id}`)}
              />
              <Btn.ActionButton
                forceMobile
                icon={<BsCardList />}
                onClick={() => navigate(`/chat/user/${owner?.id}`)}
              />
              <Btn.ActionButton
                forceMobile
                icon={<BsChatLeft />}
                onClick={() => setDrawerOpen(true)}
              />

              <RightSideBar
                isOpen={drawerOpen}
                closeHandler={() => setDrawerOpen(false)}
              >
                <ChatContainerWrapper>
                  <Messages isWidget>
                    <ChatChannel userId={owner?.id} />
                  </Messages>
                </ChatContainerWrapper>
              </RightSideBar>

              <Btn.ActionButton
                forceMobile
                icon={owner?.blocked ? <BsUnlock /> : <BsLock />}
                onClick={onBlock}
              >
              </Btn.ActionButton>
              <Btn.ActionButton
                forceMobile
                icon={<BsFillTelephonePlusFill />}
                onClick={linkPhoneHandler}
              >
              </Btn.ActionButton>
              <Btn.ActionButton
                forceMobile
                icon={<BsTrashFill />}
                onClick={onDelete}
              >
              </Btn.ActionButton>
              <Btn.ActionButton
                forceMobile
                icon={<BsLink45Deg />}
                onClick={() => window.open(`/public/customer/${owner?.id ?? ''}`, '_blank')}
              >
              </Btn.ActionButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <AltBoProfile
          profile={data?.getProfile ?? {}}
          currentIsSalesDemoAccount={currentIsSalesDemoAccount}
          getSalesDemoEmail={getSalesDemoEmail}
          getServiceSalesDemoEmail={getServiceSalesDemoEmail}
          demoAltBoEmail={demoAltBoEmail}
          userEmail={userEmail}
          ownerId={selectedOwner.id}
        />
      </Grid>
      <RightSideBar
        isOpen={sideBarStatus}
        closeHandler={() => setSideBarStatus(false)}
      >
        <>
          { showCreate && (
            <LinkPhoneOwner
              submitCallback={submitCallback}
              selectedOwner={selectedOwner}
            />
          ) }
        </>
      </RightSideBar>
    </Grid>
  )
}
