/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Box, Stack, Typography } from '@mui/material'

import { Btn } from '#app/components/Buttons'
import { LoadingGrid } from '#app/components/Loading/LoadingGrid'
import RightSideBar, { RightSideBarContainer } from '#app/components/RightSideBar/RightSideBar'
import { CreateOrEditInvoiceForm } from '#app/feature/Invoices/CreateOrEditInvoiceForm'
import { useDayJs } from '#app/hooks/useDayJs'
import { EntityPage } from '#app/layouts/EntityView'
import { useCreateCustomerFromWorkOrderMutation } from '#app/operations/customers/customer.mutations.generated'

import BidForm from './BidForm'

const emptyForm = {
  additional_notes: null,
  slot_1: null,
  slot_2: null,
  slot_3: null
}

export function CreateBid() {
  const [createOpen, setCreateOpen] = useState<boolean>(true)
  const [bidDetailsForm, setBidDetailsForm] = useState<any>(emptyForm)
  const [bidDetailsFormUTC, setBidDetailsFormUTC] = useState<any>()
  const [showEstimate, setShowEstimate] = useState(false)
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const { dayjs } = useDayJs()
  const [createCustomerFromWorkOrder, { loading: loadingCreateCustomerFromWorkOrder, data, ...mutationInfo }] = useCreateCustomerFromWorkOrderMutation()

  const workOrderId = searchParams.get('workOrderId')

  const checkCustomer = async () => {
    if (workOrderId && !mutationInfo.called) {
      const customer = await createCustomerFromWorkOrder({
        variables: {
          work_order_id: workOrderId
        }
      })
    }
  }

  useEffect(() => {
    void checkCustomer()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrderId])

  const onSubmitBidDetails = useCallback((data: any) => {
    const format = 'YYYY-MM-DDTHH:mm:ss'

    // You may be wondering why all this controversial conversion with dayjs.
    // Basically I can't change the timezone of mui's dete picker, and even if I define default timezone with dayjs it doesn't work either.
    // So since we handle everything with UTC, we are not calculating the timezone of the BO, we save in altBo as it is inserted by the BO, assuming that the BO and the PM are in the same time zone.
    const payload = {
      ...data,
      slot_1: dayjs.utc(`${data?.slot_1}`.substring(0, 23)).local().format(format).toString(),
      slot_2: dayjs.utc(`${data?.slot_2}`.substring(0, 23)).local().format(format).toString(),
      slot_3: dayjs.utc(`${data?.slot_3}`.substring(0, 23)).local().format(format).toString()
    }

    setBidDetailsForm(data)
    setBidDetailsFormUTC(payload)
    setShowEstimate(true)
    return true
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const bidDetailsReady = useMemo(() => {
    if (JSON.stringify(bidDetailsForm) !== JSON.stringify(emptyForm)) {
      return true
    }
    return false
  }, [bidDetailsForm])

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <EntityPage
      isLoading={false}
      layout='free'
      pageTitle={t('propertymanagers.createBid.title', 'Work Orders')}
      pageSubtitle={t('propertymanagers.createBid.subtitle', 'Create a bid for this work order')}
      actionLabel={t('propertymanagers.createBid.action', 'Create bid')}
      mainContent={(
        <Box>
          {workOrderId === ''
          && (
            <Typography>
              {t('propertymanagers.noWorkOrder', 'There is no work order')}
            </Typography>
          )}
          {
            mutationInfo.error
            && (
              <Typography>
                {t('propertymanagers.error.failCreatingCustomer', 'An error occurred in the process, and we are currently unable to process the work order')}
              </Typography>
            )
          }
          {
            loadingCreateCustomerFromWorkOrder
            && <LoadingGrid />
          }

          {!loadingCreateCustomerFromWorkOrder && workOrderId && !mutationInfo.error
          && (
            <>
              <Stack sx={{
                height: '85vh'
              }}
              >
                <iframe
                  height='100%'
                  src={(process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'development') ? `https://n8n.joinowners.co/webhook/staging/work-order?workOrderId=${workOrderId}` : `https://n8n.joinowners.co/webhook/work-order?workOrderId=${workOrderId}`}
                />
              </Stack>
              <RightSideBar
                isOpen={createOpen}
                closeHandler={() => setCreateOpen(false)}
              >
                <>
                  <RightSideBarContainer>
                    {
                      !showEstimate
                        ? (
                          <Box>
                            <Typography
                              mb={6}
                              variant='h6'
                            >
                              {t('banking.invoices.create.createEstimate', 'Create Estimate')}
                            </Typography>
                            <BidForm
                              initialValues={bidDetailsForm}
                              onSubmit={onSubmitBidDetails}
                            />
                          </Box>
                        )
                        : <></>
                    }
                    {
                      bidDetailsReady && showEstimate
                        ? (
                          <>
                            <Btn.IconButton.Back
                              sx={{
                                mt: '-5.5rem'
                              }}
                              onClick={() => setShowEstimate(false)}
                            />

                            <CreateOrEditInvoiceForm
                              workOrderId={workOrderId}
                              workOrderBidDetails={bidDetailsFormUTC}
                              customerData={data?.createCustomerFromWorkOrder?.customer}
                              onCallback={() => setCreateOpen(false)}
                            />
                          </>
                        )
                        : <></>
                    }
                  </RightSideBarContainer>
                </>
              </RightSideBar>
            </>
          )}
        </Box>
      )}
      onActionClick={() => setCreateOpen(true)}
    />
  )
}
