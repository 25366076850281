export const previousActivityHomeCardVariant = {
  initial: {
    scale: 0.7,
    top: '-2.5rem',
    opacity: 0.5
  },
  animate: {
    scale: 0.9,
    top: '-1rem',
    opacity: 1
  },
  transition: {
    type: 'spring',
    duration: 1.5,
  },
}

export const newActivityHomeCardVariant = {
  initial: {
    scale: 0.9,
    top: '-1rem',
  },
  animate: {
    scale: 1,
    top: 0,
  },
  exit: {
    filter: 'blur(4px)',
    opacity: 0, // It might still be present in the DOM if clicked to quickly
    zIndex: 999,
    y: '700%',
    scale: 1.25,
    transition: {
      duration: 1.5
    }
  },
  transition: {
    type: 'easeIn',
    duration: 1,
  }
}
