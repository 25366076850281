/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect, useMemo, useRef, useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Box, Grid, Stack, Typography } from '@mui/material'

import ActionButton from '#app/components/Buttons/ActionButton'
import { ChipBasic } from '#app/components/Chip'
import { ChipLanguage } from '#app/components/Chip/ChipLanguage'
import { ChipUser } from '#app/components/Chip/ChipUser'
import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import CreateCustomer from '#app/forms/CustomersManagement/CreateCustomer'
import { CreateJob } from '#app/forms/Jobs/CreateJob'
import useMutationWithNotification from '#app/hooks/useMutationWithNotification'
import { EntityPage } from '#app/layouts/EntityView'
import { useGetBusinessCustomersQuery } from '#app/operations/customers/customers.queries.generated'
import { CustomerContent } from '#app/routes/User/CustomersManagement/CustomerDetails'
import { CustomerInput } from '#app/types/customer'
import { BusinessWithJobsQueryResult } from '#app/types/job'
import { MUTATION, QUERY, QUERY_GET_BUSINESSES_WITH_JOBS } from '#app/utils/graphqlQueries'
import { useIsDesktopSize } from '#app/utils/mediaQueries'
import { getOnlyDefined } from '#app/utils/vanilla/helpers'

import { BusinessCustomersAndInvoices } from './BusinessCustomersAndInvoices'
interface Props {
  business?: BusinessWithJobsQueryResult
}

export const BusinessDetailsContent = ({ currentBusiness }: { currentBusiness?: any }) => {
  const ownerName = `${currentBusiness?.user?.first_name ?? ''} ${currentBusiness?.user?.last_name ?? ''}`
  const ownerEmail = currentBusiness?.user?.email ?? ''
  const [createSideBar, setCreateSideBar] = useState(false)
  const prevBusinessRef = useRef<BusinessWithJobsQueryResult | undefined>()
  const customerInformation = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: '',
    lead: ''
  }
  const navigate = useNavigate()
  const [currentCustomer, setCurrentCustomer] = useState<CustomerInput | undefined>()
  const [open, setOpen] = useState<boolean>(false)
  const isDesktop = useIsDesktopSize()
  const { data: customersData, loading: customersLoading, error: customersError, refetch: customersRefetch, startPolling: customersStartPolling } = useGetBusinessCustomersQuery({
    variables: {
      query: '%',
      limit: 50,
      businessID: currentBusiness?.id
    }
  })

  useEffect(() => {
    if (currentBusiness) {
      prevBusinessRef.current = currentBusiness
    }
  }, [])

  useEffect(() => {
    if (currentBusiness?.id && currentBusiness.id !== prevBusinessRef.current?.id) {
      setOpen(false)
    }
    void getBusinessCustomer(currentBusiness)
  }, [currentBusiness])

  const customerSearchUser = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = '%' + e.target.value + '%'
    await customersRefetch({
      query,
      limit: 50,
      businessID: currentBusiness?.id
    })
  }

  useEffect(() => {
    if (!customersLoading && customersData) {
      if (!currentCustomer) {
        if (customersData.customers.length > 0) {
          const [firstCustomer] = customersData.customers

          setCurrentCustomer(firstCustomer)
        }
      }
      else {
        const updatedCustomer = customersData
          .customers
          .find((c) => c.id === currentCustomer?.id)

        if (updatedCustomer) {
          setCurrentCustomer(updatedCustomer)
        }
      }
    }
  }, [customersData])

  const getBusinessCustomer = async (business: BusinessWithJobsQueryResult) => {
    if (business) {
      await customersRefetch({
        limit: 50,
        businessID: business?.id
      })
      customersStartPolling(5000)
      prevBusinessRef.current = business
    }
  }

  const [addCustomer] = useMutationWithNotification(MUTATION.customers.createCustomer)

  const onSubmit = async (data: CustomerInput, { resetForm }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const payload = getOnlyDefined({
      user_id: currentBusiness?.user?.id,
      business_id: currentBusiness?.id,
      ...data
    })

    try {
      await addCustomer({
        variables: payload
      })

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setCreateSideBar(false)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      resetForm()
      await customersRefetch({
        limit: 50,
        businessID: currentBusiness?.id
      })
    }
    catch (err) {
      console.error(err)
    }
  }

  const customersTableStructure = useMemo(() => {
    if (customersData?.customers?.length && customersData?.customers?.length > 0 && !customersLoading) {
      const rows = customersData?.customers
        .map((customer) => ({
          key: customer.id,
          columns: [
            {
              component: (
                <Box>
                  <ChipUser user={customer} />
                </Box>
              )
            },
            {
              component: (
                <Box>
                  <ChipBasic
                    label={`${customer.phone ?? ''}`}
                    color='success'
                  />
                </Box>
              )
            },
            {
              component: (
                <Box>
                  <span>
                    {
                      `${customer.email ?? ''}`
                    }
                  </span>
                </Box>
              )
            }
          ],
          data: customer
        }))

      return {
        rows
      }
    }

    return {
      rows: []
    }
  }, [currentCustomer, customersData, customersLoading])

  function selectCustomer(customer: any) {
    setCurrentCustomer({
      ...customer
    })
    setOpen(true)
  }

  if (!currentBusiness) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Grid
          container
          spacing={0}
        >
          <Typography
            variant='h5'
            width='100%'
            textAlign='center'
          >
            Currently there is no selected business
          </Typography>
          <Grid
            xs={12}
            textAlign='center'
          >
            <BsSearch size={32} />
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <>
      <Grid
        container
        direction='column'
        rowSpacing={5}
      >
        <Grid item>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            spacing={0}
          >
            <Grid item>
              <div className='d-flex flex-column'>
                <Grid
                  container
                  direction='row'
                >
                  <Grid item>
                    <Typography variant='h6'>
                      { currentBusiness?.name?.length > 0 ? currentBusiness?.name : 'Unknown' }
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className='ms-3'
                  >
                    <Box><ChipLanguage lang={currentBusiness?.user?.language ?? ''}></ChipLanguage></Box>
                  </Grid>
                </Grid>
                <div className='mt-3'>
                  <div>
                    <small>{ ownerName }</small>
                  </div>
                  <div>
                    <small>{ ownerEmail }</small>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item>
              { !open && (
                <Stack
                  direction='row'
                  spacing={1}
                >
                  <ActionButton onClick={() => setCreateSideBar(true)}>
                    New Customer
                  </ActionButton>
                </Stack>
              ) }
              { open && (
                <ActionButton onClick={() => setCreateSideBar(true)}>
                  New Deal
                </ActionButton>
              ) }
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <EntityPage
            layout='one-column'
            isLoading={customersLoading}
            getBackAction={() => {
              setOpen(false)
            }}
            getBack={!!(currentCustomer && open)}
            mainContentWithoutCard={(
              <>
                { currentCustomer && !open && (
                  <BusinessCustomersAndInvoices
                    userId={currentBusiness?.user?.id}
                    businessId={currentBusiness?.id}
                    customersTableStructure={customersTableStructure}
                    onRowClick={selectCustomer}
                  />
                ) }
                { currentCustomer && open && (
                  <CustomerContent
                    currentCustomer={currentCustomer}
                    userId={currentBusiness?.user?.id}
                  />
                )}
              </>
            )}
            onFilterChange={customerSearchUser}
          />
        </Grid>
      </Grid>
      <RightSideBar
        isOpen={createSideBar}
        closeHandler={() => setCreateSideBar(false)}
      >
        <>
          {currentCustomer && open && createSideBar && (
            <CreateJob
              submitCallback={() => { setCreateSideBar(false) }}
              currentBusiness={currentBusiness}
              currentCustomer={currentCustomer}
            />
          )}
          {!open && createSideBar && (
            <CreateCustomer
              information={customerInformation}
              onSubmit={onSubmit}
            />
          )}
        </>
      </RightSideBar>
    </>
  )
}

export const BusinessDetails = (props: Props) => {
  const params = useParams()
  const [currentBusiness, setCurrentBusiness] = useState<BusinessWithJobsQueryResult | undefined>()
  const [isLoading, setLoading] = useState<boolean>(true)
  const { data, loading } = useQuery<QUERY_GET_BUSINESSES_WITH_JOBS>(QUERY.jobs.getBusinessesWithJobs, {
    variables: {
      where: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        id: {
          _eq: params.businessId
        },
        user: {
          status: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            _neq: 'deleted'
          }
        }
      },
      limit: 1
    }
  })

  useEffect(() => {
    if (data && !loading) {
      setCurrentBusiness(data?.businesses[0])
      setLoading(false)
    }
  }, [data, loading])

  return (
    <EntityPage
      hasReturn={true}
      pageTitle='Business Details'
      layout='one-column'
      isLoading={isLoading}
      mainContent={<BusinessDetailsContent currentBusiness={currentBusiness} />}
    />
  )
}
