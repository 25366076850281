import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BsCreditCard, BsFillCaretDownFill, BsFillCaretUpFill, BsInfoCircleFill, BsPersonCircle, BsReceipt } from 'react-icons/bs'
import { NumericFormat } from 'react-number-format'
import { Box, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from '@mui/material'
import { t } from 'i18next'

import { BasicAlertProps } from '#app/components/Alerts/BasicAlert'
import { Alert } from '#app/components/Alerts/index'
import { useDayJs } from '#app/hooks/useDayJs'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { THEME } from '#app/layouts/theme'
import { useGetBankCardsLazyQuery } from '#app/operations/banking/banking.queries.generated'

import { StatusChip } from './StatusChip'
import { TransactionRowData } from './TransactionsTable'
import { TypeIcon } from './TypeIcon'

interface Props {
  transactionDetails: TransactionRowData
}

const CustomerPayment = ({ transactionDetails }: Props) => {
  const { t } = useTranslation()

  const totalAmount = {
    type: 'increment',
    label: t('customerPayment.youCharged', 'You charged'),
    helper: '',
    amount: (Number(transactionDetails?.metadata?.metadata?.total_amount) / 100) || 0
  }

  const charges = []

  try {
    charges
      .push(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
        ...JSON.parse(transactionDetails?.metadata?.metadata?.fee_details ?? {})
          .map((details: { amount?: number }) => {
            return {
              type: 'decrement',
              label: t('customerPayment.platformFee.label', 'Platform fee'),
              helper: t('customerPayment.platformFee.helperText', 'The cost applied to your operation'),
              amount: (Number(details?.amount) / 100) || 0
            }
          })
      )
  }
  catch (err) {
    console.error('Failed to parse charges')
  }

  const breakDown = [totalAmount, ...charges]

  return (
    <Stack spacing={3}>
      <Typography style={{
        fontWeight: 'bold'
      }}
      >
        { t('customerPayment.youWerePaid', 'You were paid by') }
      </Typography>
      <Stack
        direction='row'
        alignItems='center'
        spacing={3}
      >
        <BsPersonCircle size={THEME.ICONS.size.md} />
        <Stack>
          <Typography>{transactionDetails?.metadata?.metadata?.customer_name ?? ''}</Typography>
          <Typography variant='caption'>{transactionDetails?.metadata?.metadata?.customer_email ?? ''}</Typography>
        </Stack>
      </Stack>
      <List>
        {
          breakDown
            .map((listItem, idx) => {
              return (
                <>
                  {idx === 0 && <Divider />}
                  <ListItem secondaryAction={(
                    <NumericFormat
                      thousandSeparator=','
                      style={{
                        fontWeight: 'bold'
                      }}
                      displayType='text'
                      value={listItem?.amount}
                      prefix='$'
                    />
                  )}
                  >
                    <ListItemIcon>
                      {
                        listItem?.type === 'increment'
                          ? (
                            <BsFillCaretUpFill style={{
                              color: 'var(--green)'
                            }}
                            />
                          )
                          : (
                            <BsFillCaretDownFill style={{
                              color: 'var(--error-color)'
                            }}
                            />
                          )
                      }
                    </ListItemIcon>
                    <ListItemText primary={(
                      <Stack
                        direction='row'
                        alignItems='center'
                        spacing={1}
                      >
                        {listItem.label}
                        {listItem.helper && (
                          <Tooltip title={listItem.helper}>
                            <IconButton>
                              <BsInfoCircleFill size={15} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Stack>
                    )}
                    />

                  </ListItem>
                  <Divider />
                </>
              )
            })
        }
        <ListItem secondaryAction={(
          <NumericFormat
            thousandSeparator=','
            style={{
              fontWeight: 'bold',
              fontSize: 20
            }}
            displayType='text'
            value={((transactionDetails?.amount ?? 0) / 100)}
            prefix='$'
          />
        )}
        >
          <ListItemText primary={(
            <Stack>
              <Typography style={{
                fontSize: 20
              }}
              >
                {t('customerPayment.total', 'Total')}
              </Typography>
              <Typography variant='caption'>
                {t('customerPayment.caption', 'This is the amount you receive')}
              </Typography>
            </Stack>
          )}
          />
        </ListItem>
      </List>
    </Stack>
  )
}

const IssuingTransaction = ({ transactionDetails }: Props) => {
  const isApproved = transactionDetails.type === 'treasury.received_debit' || transactionDetails?.metadata?.approved
  const card = transactionDetails?.metadata?.card

  const [getBankCards, { data: dataBankCards }] = useGetBankCardsLazyQuery()
  const { userId } = useEntityUser()

  const initView = useCallback(async () => {
    if (userId) {
      await getBankCards({
        variables: {
          userId
        }
      })
    }
  }, [getBankCards, userId])

  const cardInfoById = (id: string) => {
    let res = null
    dataBankCards?.bank_cards.map((bankCard: any) => {
      if (bankCard?.stripe_card_id === id) {
        res = `${bankCard?.card?.brand as string} - Ending in ${bankCard?.card?.last4 as string}`
      }
    })
    return res
  }

  useEffect(() => {
    void initView()
  }, [initView, userId])

  return (
    <Stack spacing={3}>
      <Typography sx={{
        fontWeight: 'bold'
      }}
      >
        Payment method
      </Typography>
      <Stack
        direction='row'
        alignItems='center'
        spacing={3}
      >
        <Stack
          direction='row'
          spacing={1}
        >
          <BsCreditCard size={THEME.ICONS.size.md} />
          <Typography>
            {!card
            && cardInfoById(transactionDetails?.metadata?.initiating_payment_method_details?.issuing_card as string)}
            {
              card
              && (
                <>
                  {card?.brand}
                  {' '}
                  -
                  {card?.last4 && `Ending in ${card?.last4 as string}`}
                </>
              )
            }
          </Typography>
        </Stack>
        <Stack>
          <Typography>{transactionDetails?.metadata?.destination_payment_method_details?.billing_details?.name ?? ''}</Typography>
          <Typography variant='caption'>{transactionDetails?.metadata?.destination_payment_method_details?.billing_details?.email ?? ''}</Typography>
        </Stack>
      </Stack>
      {
        isApproved
        && (
          <Alert.Basic
            severity='success'
            description={t('banking.paymentProcessed')}
          />
        )
      }
      {
        !isApproved
        && (
          <Alert.Basic
            severity='error'
            description={t('banking.paymentDeclined')}
          />
        )
      }
    </Stack>
  )
}

const BankTransfer = ({ transactionDetails }: Props) => {
  const { formatDateInFormat } = useDayJs()
  const { t } = useTranslation()

  interface MessageByStatus {
    posted: {
      message: string
      variant: BasicAlertProps['severity']
    }
    failed: {
      message: string
      variant: BasicAlertProps['severity']
    }
    returned: {
      message: string
      variant: BasicAlertProps['severity']
    }
    canceled: {
      message: string
      variant: BasicAlertProps['severity']
    }
    processing: {
      message: string
      variant: BasicAlertProps['severity']
    }
  }

  const messagesByStatus: MessageByStatus = {
    posted: {
      message: t('bankTransferDetails.success', 'Transfer has been sent and arrived to the selected account, your balance has been modified accordingly.'),
      variant: 'success'
    },
    failed: {
      message: t('bankTransferDetails.error', 'Transfer has failed, no changes have been made to your bank accounts.'),
      variant: 'error'
    },
    returned: {
      message: t('bankTransferDetails.returned', 'Transfer has been returned, no changes have been made to your bank account.'),
      variant: 'info'
    },
    canceled: {
      message: t('bankTransferDetails.cancelled', 'Transfer has been canceled, no changes have been made to your bank account.'),
      variant: 'info'
    },
    processing: {
      message: t('bankTansferDetails.processing', 'Transfer is processing, no changes have been made to your bank account balance.'),
      variant: 'warning'
    }
  }

  const alertSeverity = transactionDetails?.status && messagesByStatus[transactionDetails?.status as keyof MessageByStatus]?.variant || 'info'
  let alertMessage = messagesByStatus[transactionDetails?.status as keyof MessageByStatus]?.message

  if (transactionDetails?.status === 'processing') {
    alertMessage = t('bankTransferDetails.arrivalDate',
      '{{alertMessage}} Expect transfer to arrive by {{date}}',
      {
        alertMessage,
        date: formatDateInFormat(new Date(transactionDetails?.metadata?.expected_arrival_date * 1000).toISOString(), 'YYYY-MM-DD')
      })
  }

  return (
    <Stack spacing={3}>
      <Typography style={{
        fontWeight: 'bold'
      }}
      >
        {t('bankTransferDetails.transferredTo', 'You transferred to')}
      </Typography>
      <Stack
        direction='row'
        alignItems='center'
        spacing={3}
      >
        <BsPersonCircle size={THEME.ICONS.size.md} />
        <Stack>
          <Typography>{transactionDetails?.metadata?.destination_payment_method_details?.billing_details?.name ?? ''}</Typography>
          <Typography variant='caption'>{transactionDetails?.metadata?.destination_payment_method_details?.billing_details?.email ?? ''}</Typography>
        </Stack>
      </Stack>
      <Alert.Basic
        severity={alertSeverity}
        description={alertMessage}
      />
    </Stack>
  )
}

export const TransactionDetails = ({ transactionDetails }: Props) => {
  const { formatDateInFormat } = useDayJs()
  const { t } = useTranslation()

  const isCustomerPayment = !!transactionDetails?.metadata?.metadata?.customer_email

  const isIssuing = [
    'treasury.received_debit',
    'issuing.authorization']
    .includes(transactionDetails?.type)

  const isBankTransfer = !isCustomerPayment && !isIssuing

  return (
    <Stack
      spacing={4}
      sx={{
        mt: 3
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
      >
        <Stack>
          <Typography variant='h6'>{ t('transactionDetails.title', 'Transaction Details')}</Typography>
          <Typography variant='caption'>
            { t('transactionDetails.createdAt', 'Created at') }
            {' '}
            {formatDateInFormat(transactionDetails?.date, 'YYYY-MM-DD')}
          </Typography>
        </Stack>
        <Stack>
          {transactionDetails && <StatusChip data={transactionDetails}></StatusChip>}
        </Stack>
      </Stack>
      <Box sx={{
        borderRadius: 1,
        padding: 2,
        backgroundColor: 'var(--main-content-bg-color)'
      }}
      >
        <Stack
          alignItems='center'
          spacing={1}
        >
          <Typography
            sx={{
              textAlign: 'center'
            }}
            variant='h1'
          >
            <NumericFormat
              thousandSeparator=','
              style={{
                fontWeight: 'bold',
                letterSpacing: 2
              }}
              displayType='text'
              value={(transactionDetails?.amount ?? 0) / 100}
              prefix='$'
            />
          </Typography>
          <Stack
            direction='row'
            alignItems='center'
            spacing={1}
          >
            {transactionDetails && <TypeIcon data={transactionDetails} />}
            <Typography sx={{
              textAlign: 'center',
              fontWeight: 'bold'
            }}
            >
              { t('transactionDetails.balanceUpdate', 'Balance Update') }
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Stack>
        {
          isCustomerPayment && <CustomerPayment transactionDetails={transactionDetails} />
        }
        {
          isBankTransfer && <BankTransfer transactionDetails={transactionDetails} />
        }
        {
          isIssuing && <IssuingTransaction transactionDetails={transactionDetails} />
        }
      </Stack>
      {
        transactionDetails?.metadata?.hosted_regulatory_receipt_url
        && (
          <Box
            display='flex'
            justifyContent='center'
          >
            <Stack
              style={{
                cursor: 'pointer'
              }}
              direction='row'
              spacing={2}
              onClick={() => window.open(String(transactionDetails?.metadata?.hosted_regulatory_receipt_url || ''), '_blank')}
            >
              <BsReceipt size={THEME.ICONS.size.md} />
              <Typography>{ t('transactionDetails.seeReceipt', 'See receipt') }</Typography>
            </Stack>
          </Box>
        )
      }
    </Stack>
  )
}
