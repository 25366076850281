declare module 'notistack' {
  interface VariantOverrides {
    // removes the `warning` variant
    // warning: false
    // adds `myCustomVariant` variant
    // myCustomVariant: true
    // adds `error` variant (already exists) and specifies the
    // "undo" props it takes in options of `enqueueSnackbar`
    default: {
      undo?: boolean // Optional if not V1 snackbars crash
    }
    info: {
      undo?: boolean // Optional if not V1 snackbars crash
    }
    success: {
      undo?: boolean // Optional if not V1 snackbars crash
    }
    warning: {
      undo?: boolean // Optional if not V1 snackbars crash
    }
    error: {
      undo?: boolean // Optional if not V1 snackbars crash
    }
  }
}

import { forwardRef, useCallback } from 'react'
import { cva } from 'class-variance-authority'
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack'

import { CheckSquare, Delete1, InformationCircle, WarningTriangle } from '#app/v2/assets/Icons'
import { Button } from '#app/v2/components/ui/button'
import { cn } from '#app/v2/libs/utils'

interface ExtraProps extends CustomContentProps {
  undo: boolean
}

export const snackbarVariants = cva(
  'rounded border  p-3.5 ',
  {
    variants: {
      variant: {
        default: 'border-[#313131] bg-[#E6E6E6] text-[#313131]',
        error: 'border-[#E60B22] bg-[#FCE2E4] text-[#E60B22]',
        success: 'border-[#00A339] bg-[#E0F4E7] text-[#00A339]',
        warning: 'border-[#FF9100] bg-[#FFF2E0] text-[#FF9100]',
        info: 'border-[#0099FA] bg-[#E0F3FE] text-[#0099FA]',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

const mapIcon = {
  default: null,
  error: <Delete1 />,
  success: <CheckSquare />,
  warning: <WarningTriangle />,
  info: <InformationCircle />
}
export const Snackbar = forwardRef<HTMLDivElement, ExtraProps>((props, ref) => {
  const { id, undo, message, variant, ...other } = props
  const { closeSnackbar } = useSnackbar()

  const handleDismiss = useCallback(() => {
    closeSnackbar(id)
  }, [id, closeSnackbar])

  return (
    <SnackbarContent
      ref={ref}
      role='alert'
      className={cn(snackbarVariants({
        variant,
      }))}
    >
      <div className='flex w-full justify-between gap-4'>
        <div className='flex items-center gap-5 text-xs'>
          <div className={cn('h-5 w-5', {
            hidden: variant === 'default'
          })}
          >
            {mapIcon[variant]}
          </div>
          {message}
        </div>
        {undo && (
          <Button
            variant='ghost'
            className='p-0 text-sm'
            onClick={handleDismiss}
          >
            OK
          </Button>
        )}
      </div>
    </SnackbarContent>
  )
})
