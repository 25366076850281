const ProgressBar = ({ bgcolor, progress, height, width = '100%' }) => {
  const Parentdiv = {
    height,
    width,
    backgroundColor: '#ECF3FC',
    borderRadius: 40
  }

  const Childdiv = {
    height: '5px',
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: 'right'
  }

  const progresstext = {
    padding: 10,
    color: 'black',
    fontWeight: 900
  }

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <span style={progresstext}></span>
      </div>
    </div>
  )
}

export default ProgressBar
