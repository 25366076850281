import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { useSnackbar } from 'notistack'

import { Dialog } from '#app/components/Dialog'

interface Props {
  ownerId: string
  resetSalesDemoUser: any
  openResetSalesDemoUser: boolean
  setOpenResetSalesDemoUser: Dispatch<SetStateAction<boolean>>
  refreshPage?: boolean
}

export function DialogResetDemoUser({ ownerId, resetSalesDemoUser, openResetSalesDemoUser, setOpenResetSalesDemoUser, refreshPage = false }: Props) {
  const { enqueueSnackbar } = useSnackbar()
  const [resetSalesDemoUserOnlyClean, setResetSalesDemoUserOnlyClean] = useState<boolean>(false)

  const onResetSalesDemoAccount = useCallback(() => {
    if (ownerId) {
      enqueueSnackbar('Please wait, this action may take a few seconds.', {
        variant: 'info'
      })
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      resetSalesDemoUser({
        variables: {
          id: ownerId,
          only_clean: resetSalesDemoUserOnlyClean
        }
      }).then(() => {
        enqueueSnackbar(`Done${refreshPage ? ', refreshing this page...' : ''}`, {
          variant: 'success'
        })
        setTimeout(() => {
          if (refreshPage) {
            window.location.reload()
          }
        }, 3000)
      })
    }
    else {
      console.error('There is no owner identification.', ownerId)
    }
  }, [enqueueSnackbar, ownerId, refreshPage, resetSalesDemoUser, resetSalesDemoUserOnlyClean])

  return (
    <Dialog.Confirm
      open={openResetSalesDemoUser}
      setOpen={setOpenResetSalesDemoUser}
      extraContent={(
        <FormGroup>
          <FormControlLabel
            control={(
              <Checkbox
                checked={resetSalesDemoUserOnlyClean}
                onChange={(event) => setResetSalesDemoUserOnlyClean(event.target.checked)}
              />
            )}
            label='Clear user without creating dummy data'
          />
        </FormGroup>
      )}
      texts={{
        title: 'Restore demo account',
        description: 'Are you sure you want to restore this demo account? This action may take a few seconds.',
        confirm: 'Yes',
        cancel: 'No'
      }}
      onConfirm={onResetSalesDemoAccount}
    />
  )
}
