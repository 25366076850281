import { useContext, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import AppContext from '../../contexts/AppContext'

export function ForceLogout(props) {
  const { appState, setAppState } = useContext(AppContext)
  const { logout } = useAuth0()

  useEffect(() => {
    if (appState?.forceLogout) {
      logout({
        returnTo: window.location.origin
      })
    }
  }, [appState, logout, setAppState])

  return (
    <>
      {props.children}
    </>
  )
}
