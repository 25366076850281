import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography } from '@mui/material'

import { Btn } from '#app/components/Buttons'
import Card from '#app/components/Card/Card'
import { LoadingCard } from '#app/components/Loading/LoadingCard'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { useGetBankCardsLazyQuery, useGetPersonsBankingLazyQuery } from '#app/operations/banking/banking.queries.generated'

import { CardControl } from './CardControl'
import { CardPreview } from './CardPreview'
import { CardStatus } from './CardStatus'
import { RequestCard } from './RequestCard'
import { EmptyCreditCard } from './styled'

export function CreditCard() {
  const [getBankCards, { loading: loadingGetBankCards, data: dataBankCards }] = useGetBankCardsLazyQuery()
  const [getPersonsBanking, { loading: loadingGetPersonsBanking, data: dataPersonsBanking }] = useGetPersonsBankingLazyQuery()
  const { userId } = useEntityUser()
  const { t } = useTranslation()

  const [openVerify, setOpenVerify] = useState(false)

  const initView = useCallback(async () => {
    if (userId) {
      await getBankCards({
        variables: {
          userId
        }
      })
      await getPersonsBanking({
        variables: {
          allPersons: false
        }
      })
    }
  }, [getBankCards, getPersonsBanking, userId])

  useEffect(() => {
    void initView()
  }, [initView, userId])

  const existCreditCard = useMemo(() => {
    return dataBankCards?.bank_cards && dataBankCards?.bank_cards.length > 0
  }, [dataBankCards])

  return (
    <>
      <RequestCard
        openVerify={openVerify}
        setOpenVerify={setOpenVerify}
        loadingGetPersonsBanking={loadingGetPersonsBanking}
        dataPersonsBanking={dataPersonsBanking}
      />
      <Card>
        <Card.Body>
          <Stack spacing={2}>
            <Stack
              direction='row'
              justifyContent='space-between'
            >
              <Typography variant='h6'>{t('banking.myCards')}</Typography>
              <Box>
                {existCreditCard && dataBankCards?.bank_cards.map((cardRow) => {
                  return (
                    <CardControl cardRow={cardRow} />
                  )
                })}
              </Box>
            </Stack>
            {loadingGetBankCards && (
              <LoadingCard sx={{
                height: '150px'
              }}
              />
            )}
            {!loadingGetBankCards
            && (
              <>
                {existCreditCard
                && (
                  <>
                    {
                      dataBankCards?.bank_cards.map((cardRow) => {
                        return (
                          <>
                            <CardPreview cardRow={cardRow} />
                            <CardStatus cardRow={cardRow} />
                          </>
                        )
                      })
                    }
                  </>
                )}
                {!existCreditCard
                && (
                  <EmptyCreditCard onClick={() => setOpenVerify(true)}>
                    <Stack>
                      <Box>
                        <Btn.ActionButton forceMobile></Btn.ActionButton>
                      </Box>
                      <Typography
                        variant='subtitle2'
                        mt={1}
                      >
                        {' '}
                        {t('banking.addNew')}
                        {' '}
                      </Typography>
                    </Stack>
                  </EmptyCreditCard>
                )}
              </>
            )}
          </Stack>
        </Card.Body>
      </Card>
    </>
  )
}
