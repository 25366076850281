import { useTranslation } from 'react-i18next'

import { ConfettiCanon } from '#app/v2/assets/Icons'

export const FinishedTasks = () => {
  const { t } = useTranslation()
  return (
    <div className='flex items-center gap-1.5 rounded-xl bg-[#304985] p-2'>
      <ConfettiCanon className='h-[70px] w-[70px] shrink-0' />
      <div className=' space-y-3.5 text-white'>
        <h3 className=' text-lg/5 font-semibold'>{t('home.tasksDone.title', 'Great job!')}</h3>
        <p className=' text-base/5 font-medium'>{t('home.tasksDone.description', 'You finished all your tasks!')}</p>
      </div>
    </div>
  )
}
