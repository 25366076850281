import { useCallback, useContext, useEffect, useRef } from 'react'

import AppContext from '.'

export function useAppContext() {
  const { appState, setAppState } = useContext(AppContext)

  const isLoadingRef = useRef(appState.isLoading)

  useEffect(() => {
    isLoadingRef.current = appState.isLoading
  }, [appState.isLoading])

  const setLoading = useCallback((val: boolean) => {
    if (isLoadingRef.current !== val) {
      setTimeout(() => {
        setAppState((prevAppState) => ({
          ...prevAppState,
          isLoading: false
        }))
      }, 2000)
    }
  }, [setAppState])

  const setError = useCallback((err: string) => {
    setAppState({
      ...appState,
      isLoading: false,
      error: err
    })
  }, [appState, setAppState])

  return {
    isLoading: appState?.isLoading,
    V1_LOGOUT: () => setAppState((prevState) => ({
      ...prevState,
      forceLogout: true
    })),
    V1_SET_LOADING: setLoading,
    V1_SET_ERROR: setError,
    appState
  }
}
