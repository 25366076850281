import { cn } from '#app/v2/libs/utils'

export const SectionDescription = ({ text }: { text?: string }) => {
  return (
    <p className={cn('text-base/5 font-medium text-[#454545]', {
      hidden: text === ''
    })}
    >
      {text}
    </p>
  )
}
