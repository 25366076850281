import { useTranslation } from 'react-i18next'
import { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export function LoadingCard({ sx }: { sx?: SxProps }) {
  const { t } = useTranslation()

  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      textAlign: 'center',
      ...sx
    }}
    >
      <Stack
        direction='column'
        justifyContent='center'
        alignItems='center'
        spacing={2}
        height='100%'
      >
        <Stack>
          <CircularProgress sx={{
            margin: '0 auto'
          }}
          />
          <Typography mt={2}>{t('dialogs.loading')}</Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
