import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { ChannelList } from 'stream-chat-react'

import OwnersLogo from '#app/assets/img/credit_card_owners_v2.png'
import ProfileContext from '#app/contexts/ProfileContext'
import { ENVIROMENT } from '#app/store/enviroment'
import { Logout1 } from '#app/v2/assets/Icons'
import { CenteredNavTitle } from '#app/v2/components/CenteredNavTitle'
import { EmptyMessageList } from '#app/v2/components/EmptyMessageList'
import { HeaderSharedNavigation } from '#app/v2/components/HeaderSharedNavigation'
import { PageWrapper } from '#app/v2/components/PageWrapper'
import { Button } from '#app/v2/components/ui/button'
import { Skeleton } from '#app/v2/components/ui/skeleton'
import { cn } from '#app/v2/libs/utils'
import { useGetShortcuts } from '#app/v2-core/hooks/useGetShortcuts'
import { useStreamChatList } from '#app/v2-core/hooks/useStreamChatList'
import { useAppContextV2 } from '#app/v2-core/layouts/useAppContextV2'
import { WrapperChat } from '#app/v2-core/pages/Home'

import 'stream-chat-react/dist/css/v2/index.css'

function ChatList() {
  const { t } = useTranslation()
  const { filters, sort, loading, Avatar } = useStreamChatList({
    channelUrl: '/v2/messages/{{channelId}}'
  })
  const { state } = useLocation()
  const { V2_SET_LOADING, V2_LOGOUT } = useAppContextV2()

  // |-----> Start Profile (grab Ulises implementation)
  const profileData = useContext(ProfileContext)
  const { shortcuts, extraShortcuts } = useGetShortcuts()
  const logout = {
    label: t('v2.general.logout', 'Log out'),
    icon: Logout1,
    onClick: V2_LOGOUT
  }
  // <-----| END Profile
  useEffect(() => {
    if (!loading) {
      V2_SET_LOADING(false)
    }
  }, [V2_SET_LOADING, loading])

  return (
    <>
      <HeaderSharedNavigation
        profile={{ // Header can now have MyProfile page inside a Modal = we need all its context until
          avatar: profileData?.getProfile?.user?.profile_picture_url,
          clientId: profileData?.getProfile.altBoProfile?.company_autonumber,
          fullName: `${profileData?.getProfile?.user?.first_name ?? ''} ${profileData?.getProfile?.user?.last_name ?? ''}`,
          services: profileData?.getProfile?.altBoProfile?.services,
          editProfileUrl: '/v2/edit-profile',
          shortcuts: shortcuts,
          extraShortcuts: extraShortcuts,
          logout: logout
        }}
        main={<CenteredNavTitle title={t('chat.messages', 'Messages')} />}
      />
      <PageWrapper
        pathname='/v2/messages'
        className=' mt-4 px-7 pb-24'
      >
        {!loading
          ? (
            <>
              <h1 className='mb-3 text-lg font-semibold text-[#304985]'>{t('v2.messages.inboxTitle', 'My Inbox')}</h1>
              <WrapperChat>
                <ChannelList
                  filters={filters}
                  sort={sort}
                  Avatar={Avatar}
                  setActiveChannelOnMount={false}
                  EmptyStateIndicator={() => (
                    <EmptyMessageList />
                  )}
                  LoadingIndicator={() => (
                    <div className='mb-5 mt-3 space-y-5'>
                      {[0, 1].map((val) => // N of chats
                        (
                          <Skeleton
                            key={val}
                            className='flex h-[70px] gap-2 rounded-xl px-2 py-3 last:mb-5'
                          >
                            <Skeleton className=' h-[46px] w-[46px] shrink-0 rounded-full bg-gray-200' />
                            <div className='flex w-full flex-col justify-center gap-0.5'>
                              <Skeleton className=' h-5 w-[50%] bg-gray-200' />
                              <Skeleton className=' h-6 bg-gray-200' />
                            </div>
                          </Skeleton>
                        )
                      )}
                    </div>
                  )}
                />
                <Button
                  className={cn('mx-0.5 w-[calc(100%-4px)] justify-start gap-2 rounded-xl bg-white p-2 shadow')}
                  variant='ghost'
                  onClick={() => {
                    window.open(`${ENVIROMENT.support_whatsapp_link}`, '_blank')
                  }}
                >
                  <div className='flex h-[46px] w-[46px] shrink-0 items-center justify-center rounded-full bg-[#304985]'>
                    <img
                      src={OwnersLogo}
                      alt='Owners logo'
                      className='h-7 w-7'
                    />
                  </div>
                  {t('v2.messages.contactSupport', 'Contact Owners Support')}
                </Button>
              </WrapperChat>
            </>
          )
          : (
            <>
              <Skeleton className=' mb-3 h-7 w-full' />
              <div className='space-y-5'>
                {[0, 1, 2].map((val) => // N of chats + contact owner
                  (
                    <Skeleton
                      key={val}
                      className=' group flex h-[70px] gap-2 rounded-xl  px-2 py-3 last:h-[62px] last:py-2'
                    >
                      <Skeleton className=' h-[46px] w-[46px] shrink-0 rounded-full bg-gray-200' />
                      <div className='flex w-full flex-col justify-center gap-0.5'>
                        <Skeleton className=' h-5 w-[50%] bg-gray-200' />
                        <Skeleton className=' h-6 w-full bg-gray-200 group-last:hidden' />

                      </div>
                    </Skeleton>
                  )
                )}
              </div>
            </>
          )}
      </PageWrapper>
    </>

  )
}

export default ChatList
