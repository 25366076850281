import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type WebsiteEditorGetWebsitesQueryVariables = Types.Exact<{
  section_version?: Types.InputMaybe<Types.Scalars['ID']>
  user_id?: Types.InputMaybe<Types.Scalars['ID']>
}>

export type WebsiteEditorGetWebsitesQuery = { __typename?: 'query_root', websiteEditorGetWebsites?: { __typename?: 'WebsiteEditorGetWebsitesPayload', websites?: Array<any | undefined> | undefined } | undefined }

export type WebsiteEditorGetSectionsQueryVariables = Types.Exact<{
  section_version?: Types.InputMaybe<Types.Scalars['ID']>
  user_id?: Types.InputMaybe<Types.Scalars['ID']>
}>

export type WebsiteEditorGetSectionsQuery = { __typename?: 'query_root', websiteEditorGetSections?: { __typename?: 'WebsiteEditorGetSectionsPayload', website_sections?: Array<any | undefined> | undefined } | undefined }

export type WebsiteEditorGetStaticVariablesQueryVariables = Types.Exact<{
  user_id?: Types.InputMaybe<Types.Scalars['uuid']>
}>

export type WebsiteEditorGetStaticVariablesQuery = { __typename?: 'query_root', websiteEditorGetStaticVariables?: { __typename?: 'WebsiteEditorGetStaticVariablesPayload', altBoProfile?: any | undefined, altBoData?: any | undefined, user?: { __typename?: 'User', id?: string | undefined, first_name?: string | undefined, last_name?: string | undefined, profile_picture_url?: string | undefined, email?: string | undefined, phone?: string | undefined, status?: string | undefined, blocked?: boolean | undefined, stripe_connected_id?: string | undefined, stripe_banking_status?: string | undefined, stripe_connected_acc?: any | undefined, created_at?: string | undefined, cal_email?: string | undefined, cal_hashed_password?: string | undefined, sync_calendar?: boolean | undefined, linked_phones?: any | undefined, language?: string | undefined, role?: { __typename?: 'Role', slug?: string | undefined } | undefined, businesses?: Array<{ __typename?: 'Business', status?: string | undefined, stripe_payment_link?: string | undefined, onboarding?: any | undefined, id?: string | undefined, subscriptions?: Array<{ __typename?: 'Subscription', id?: string | undefined, status?: string | undefined, fee?: number | undefined } | undefined> | undefined } | undefined> | undefined } | undefined } | undefined }

export type WebsiteEditorAiPromptQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.WebsiteEditorAiPromptInput>
}>

export type WebsiteEditorAiPromptQuery = { __typename?: 'query_root', websiteEditorAIPrompt?: { __typename?: 'WebsiteEditorAIPromptPayload', result: string, success: boolean } | undefined }

export type WebsiteEditorGetSectionsTemplatesQueryVariables = Types.Exact<{
  input: Types.WebsiteEditorGetSectionsTemplatesInput
}>

export type WebsiteEditorGetSectionsTemplatesQuery = { __typename?: 'query_root', websiteEditorGetSectionsTemplates?: { __typename?: 'WebsiteEditorGetSectionsTemplatesPayload', website_template_sections?: Array<any | undefined> | undefined } | undefined }

export type WebsiteEditorGetSectionHydratedQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.WebsiteEditorGetSectionHydratedInput>
}>

export type WebsiteEditorGetSectionHydratedQuery = { __typename?: 'query_root', websiteEditorGetSectionHydrated?: { __typename?: 'WebsiteEditorGetSectionHydratedPayload', website_section?: any | undefined } | undefined }

export type WebsiteDeploymentsByUserQueryVariables = Types.Exact<{
  user_id?: Types.InputMaybe<Types.Scalars['uuid']>
}>

export type WebsiteDeploymentsByUserQuery = { __typename?: 'query_root', website_deployments: Array<{ __typename?: 'website_deployments', user_id: any, updated_at: any, status?: string | undefined, section_version: any, metadata?: any | undefined, id_deployment_provider: string, id: any, deployment_url?: string | undefined, domain?: string | undefined, deployment_provider?: string | undefined, deployment_project?: string | undefined, deployment_org_id?: string | undefined, created_by?: any | undefined, created_at: any, bucket_url?: string | undefined, bucket_provider?: string | undefined, bucket_org_id?: string | undefined, bucket_account_id?: string | undefined, userByCreatedBy?: { __typename?: 'users', first_name?: string | undefined, last_name?: string | undefined, email: string } | undefined }> }

export type PublicWebsiteDeploymentByIdQueryVariables = Types.Exact<{
  deployment_id?: Types.InputMaybe<Types.Scalars['uuid']>
}>

export type PublicWebsiteDeploymentByIdQuery = { __typename?: 'query_root', website_deployments: Array<{ __typename?: 'website_deployments', domain?: string | undefined, status?: string | undefined, bucket_url?: string | undefined, deployment_url?: string | undefined, created_at: any, updated_at: any, created_by?: any | undefined, id: any, section_version: any, user_id: any }> }

export type WebsiteEditorGetAssetsIssuesQueryVariables = Types.Exact<{ [key: string]: never }>

export type WebsiteEditorGetAssetsIssuesQuery = { __typename?: 'query_root', websiteEditorGetAssetsIssues?: { __typename?: 'websiteEditorGetAssetsIssuesPayload', data?: any | undefined } | undefined }

export const WebsiteEditorGetWebsitesDocument = gql`
    query WebsiteEditorGetWebsites($section_version: ID, $user_id: ID) {
  websiteEditorGetWebsites(
    input: {section_version: $section_version, user_id: $user_id}
  ) {
    websites
  }
}
    `

/**
 * __useWebsiteEditorGetWebsitesQuery__
 *
 * To run a query within a React component, call `useWebsiteEditorGetWebsitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteEditorGetWebsitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteEditorGetWebsitesQuery({
 *   variables: {
 *      section_version: // value for 'section_version'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useWebsiteEditorGetWebsitesQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteEditorGetWebsitesQuery, WebsiteEditorGetWebsitesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<WebsiteEditorGetWebsitesQuery, WebsiteEditorGetWebsitesQueryVariables>(WebsiteEditorGetWebsitesDocument, options)
}
export function useWebsiteEditorGetWebsitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteEditorGetWebsitesQuery, WebsiteEditorGetWebsitesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<WebsiteEditorGetWebsitesQuery, WebsiteEditorGetWebsitesQueryVariables>(WebsiteEditorGetWebsitesDocument, options)
}
export type WebsiteEditorGetWebsitesQueryHookResult = ReturnType<typeof useWebsiteEditorGetWebsitesQuery>
export type WebsiteEditorGetWebsitesLazyQueryHookResult = ReturnType<typeof useWebsiteEditorGetWebsitesLazyQuery>
export type WebsiteEditorGetWebsitesQueryResult = Apollo.QueryResult<WebsiteEditorGetWebsitesQuery, WebsiteEditorGetWebsitesQueryVariables>
export const WebsiteEditorGetSectionsDocument = gql`
    query WebsiteEditorGetSections($section_version: ID, $user_id: ID) {
  websiteEditorGetSections(
    input: {section_version: $section_version, user_id: $user_id}
  ) {
    website_sections
  }
}
    `

/**
 * __useWebsiteEditorGetSectionsQuery__
 *
 * To run a query within a React component, call `useWebsiteEditorGetSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteEditorGetSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteEditorGetSectionsQuery({
 *   variables: {
 *      section_version: // value for 'section_version'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useWebsiteEditorGetSectionsQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteEditorGetSectionsQuery, WebsiteEditorGetSectionsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<WebsiteEditorGetSectionsQuery, WebsiteEditorGetSectionsQueryVariables>(WebsiteEditorGetSectionsDocument, options)
}
export function useWebsiteEditorGetSectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteEditorGetSectionsQuery, WebsiteEditorGetSectionsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<WebsiteEditorGetSectionsQuery, WebsiteEditorGetSectionsQueryVariables>(WebsiteEditorGetSectionsDocument, options)
}
export type WebsiteEditorGetSectionsQueryHookResult = ReturnType<typeof useWebsiteEditorGetSectionsQuery>
export type WebsiteEditorGetSectionsLazyQueryHookResult = ReturnType<typeof useWebsiteEditorGetSectionsLazyQuery>
export type WebsiteEditorGetSectionsQueryResult = Apollo.QueryResult<WebsiteEditorGetSectionsQuery, WebsiteEditorGetSectionsQueryVariables>
export const WebsiteEditorGetStaticVariablesDocument = gql`
    query websiteEditorGetStaticVariables($user_id: uuid) {
  websiteEditorGetStaticVariables(input: {user_id: $user_id}) {
    altBoProfile
    altBoData
    user {
      id
      first_name
      last_name
      profile_picture_url
      email
      phone
      status
      blocked
      stripe_connected_id
      stripe_banking_status
      stripe_connected_acc
      created_at
      cal_email
      cal_hashed_password
      sync_calendar
      linked_phones
      role {
        slug
      }
      businesses {
        status
        stripe_payment_link
        subscriptions {
          id
          status
          fee
        }
        onboarding
        id
      }
      language
    }
  }
}
    `

/**
 * __useWebsiteEditorGetStaticVariablesQuery__
 *
 * To run a query within a React component, call `useWebsiteEditorGetStaticVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteEditorGetStaticVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteEditorGetStaticVariablesQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useWebsiteEditorGetStaticVariablesQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteEditorGetStaticVariablesQuery, WebsiteEditorGetStaticVariablesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<WebsiteEditorGetStaticVariablesQuery, WebsiteEditorGetStaticVariablesQueryVariables>(WebsiteEditorGetStaticVariablesDocument, options)
}
export function useWebsiteEditorGetStaticVariablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteEditorGetStaticVariablesQuery, WebsiteEditorGetStaticVariablesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<WebsiteEditorGetStaticVariablesQuery, WebsiteEditorGetStaticVariablesQueryVariables>(WebsiteEditorGetStaticVariablesDocument, options)
}
export type WebsiteEditorGetStaticVariablesQueryHookResult = ReturnType<typeof useWebsiteEditorGetStaticVariablesQuery>
export type WebsiteEditorGetStaticVariablesLazyQueryHookResult = ReturnType<typeof useWebsiteEditorGetStaticVariablesLazyQuery>
export type WebsiteEditorGetStaticVariablesQueryResult = Apollo.QueryResult<WebsiteEditorGetStaticVariablesQuery, WebsiteEditorGetStaticVariablesQueryVariables>
export const WebsiteEditorAiPromptDocument = gql`
    query websiteEditorAIPrompt($input: WebsiteEditorAIPromptInput) {
  websiteEditorAIPrompt(input: $input) {
    result
    success
  }
}
    `

/**
 * __useWebsiteEditorAiPromptQuery__
 *
 * To run a query within a React component, call `useWebsiteEditorAiPromptQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteEditorAiPromptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteEditorAiPromptQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWebsiteEditorAiPromptQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteEditorAiPromptQuery, WebsiteEditorAiPromptQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<WebsiteEditorAiPromptQuery, WebsiteEditorAiPromptQueryVariables>(WebsiteEditorAiPromptDocument, options)
}
export function useWebsiteEditorAiPromptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteEditorAiPromptQuery, WebsiteEditorAiPromptQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<WebsiteEditorAiPromptQuery, WebsiteEditorAiPromptQueryVariables>(WebsiteEditorAiPromptDocument, options)
}
export type WebsiteEditorAiPromptQueryHookResult = ReturnType<typeof useWebsiteEditorAiPromptQuery>
export type WebsiteEditorAiPromptLazyQueryHookResult = ReturnType<typeof useWebsiteEditorAiPromptLazyQuery>
export type WebsiteEditorAiPromptQueryResult = Apollo.QueryResult<WebsiteEditorAiPromptQuery, WebsiteEditorAiPromptQueryVariables>
export const WebsiteEditorGetSectionsTemplatesDocument = gql`
    query websiteEditorGetSectionsTemplates($input: WebsiteEditorGetSectionsTemplatesInput!) {
  websiteEditorGetSectionsTemplates(input: $input) {
    website_template_sections
  }
}
    `

/**
 * __useWebsiteEditorGetSectionsTemplatesQuery__
 *
 * To run a query within a React component, call `useWebsiteEditorGetSectionsTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteEditorGetSectionsTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteEditorGetSectionsTemplatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWebsiteEditorGetSectionsTemplatesQuery(baseOptions: Apollo.QueryHookOptions<WebsiteEditorGetSectionsTemplatesQuery, WebsiteEditorGetSectionsTemplatesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<WebsiteEditorGetSectionsTemplatesQuery, WebsiteEditorGetSectionsTemplatesQueryVariables>(WebsiteEditorGetSectionsTemplatesDocument, options)
}
export function useWebsiteEditorGetSectionsTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteEditorGetSectionsTemplatesQuery, WebsiteEditorGetSectionsTemplatesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<WebsiteEditorGetSectionsTemplatesQuery, WebsiteEditorGetSectionsTemplatesQueryVariables>(WebsiteEditorGetSectionsTemplatesDocument, options)
}
export type WebsiteEditorGetSectionsTemplatesQueryHookResult = ReturnType<typeof useWebsiteEditorGetSectionsTemplatesQuery>
export type WebsiteEditorGetSectionsTemplatesLazyQueryHookResult = ReturnType<typeof useWebsiteEditorGetSectionsTemplatesLazyQuery>
export type WebsiteEditorGetSectionsTemplatesQueryResult = Apollo.QueryResult<WebsiteEditorGetSectionsTemplatesQuery, WebsiteEditorGetSectionsTemplatesQueryVariables>
export const WebsiteEditorGetSectionHydratedDocument = gql`
    query WebsiteEditorGetSectionHydrated($input: WebsiteEditorGetSectionHydratedInput) {
  websiteEditorGetSectionHydrated(input: $input) {
    website_section
  }
}
    `

/**
 * __useWebsiteEditorGetSectionHydratedQuery__
 *
 * To run a query within a React component, call `useWebsiteEditorGetSectionHydratedQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteEditorGetSectionHydratedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteEditorGetSectionHydratedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWebsiteEditorGetSectionHydratedQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteEditorGetSectionHydratedQuery, WebsiteEditorGetSectionHydratedQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<WebsiteEditorGetSectionHydratedQuery, WebsiteEditorGetSectionHydratedQueryVariables>(WebsiteEditorGetSectionHydratedDocument, options)
}
export function useWebsiteEditorGetSectionHydratedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteEditorGetSectionHydratedQuery, WebsiteEditorGetSectionHydratedQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<WebsiteEditorGetSectionHydratedQuery, WebsiteEditorGetSectionHydratedQueryVariables>(WebsiteEditorGetSectionHydratedDocument, options)
}
export type WebsiteEditorGetSectionHydratedQueryHookResult = ReturnType<typeof useWebsiteEditorGetSectionHydratedQuery>
export type WebsiteEditorGetSectionHydratedLazyQueryHookResult = ReturnType<typeof useWebsiteEditorGetSectionHydratedLazyQuery>
export type WebsiteEditorGetSectionHydratedQueryResult = Apollo.QueryResult<WebsiteEditorGetSectionHydratedQuery, WebsiteEditorGetSectionHydratedQueryVariables>
export const WebsiteDeploymentsByUserDocument = gql`
    query WebsiteDeploymentsByUser($user_id: uuid) {
  website_deployments(where: {user_id: {_eq: $user_id}}) {
    user_id
    userByCreatedBy {
      first_name
      last_name
      email
    }
    updated_at
    status
    section_version
    metadata
    id_deployment_provider
    id
    deployment_url
    domain
    deployment_provider
    deployment_project
    deployment_org_id
    created_by
    created_at
    bucket_url
    bucket_provider
    bucket_org_id
    bucket_account_id
  }
}
    `

/**
 * __useWebsiteDeploymentsByUserQuery__
 *
 * To run a query within a React component, call `useWebsiteDeploymentsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteDeploymentsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteDeploymentsByUserQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useWebsiteDeploymentsByUserQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteDeploymentsByUserQuery, WebsiteDeploymentsByUserQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<WebsiteDeploymentsByUserQuery, WebsiteDeploymentsByUserQueryVariables>(WebsiteDeploymentsByUserDocument, options)
}
export function useWebsiteDeploymentsByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteDeploymentsByUserQuery, WebsiteDeploymentsByUserQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<WebsiteDeploymentsByUserQuery, WebsiteDeploymentsByUserQueryVariables>(WebsiteDeploymentsByUserDocument, options)
}
export type WebsiteDeploymentsByUserQueryHookResult = ReturnType<typeof useWebsiteDeploymentsByUserQuery>
export type WebsiteDeploymentsByUserLazyQueryHookResult = ReturnType<typeof useWebsiteDeploymentsByUserLazyQuery>
export type WebsiteDeploymentsByUserQueryResult = Apollo.QueryResult<WebsiteDeploymentsByUserQuery, WebsiteDeploymentsByUserQueryVariables>
export const PublicWebsiteDeploymentByIdDocument = gql`
    query PublicWebsiteDeploymentById($deployment_id: uuid) {
  website_deployments(where: {id: {_eq: $deployment_id}}) {
    domain
    status
    bucket_url
    deployment_url
    created_at
    updated_at
    created_by
    id
    section_version
    user_id
  }
}
    `

/**
 * __usePublicWebsiteDeploymentByIdQuery__
 *
 * To run a query within a React component, call `usePublicWebsiteDeploymentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicWebsiteDeploymentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicWebsiteDeploymentByIdQuery({
 *   variables: {
 *      deployment_id: // value for 'deployment_id'
 *   },
 * });
 */
export function usePublicWebsiteDeploymentByIdQuery(baseOptions?: Apollo.QueryHookOptions<PublicWebsiteDeploymentByIdQuery, PublicWebsiteDeploymentByIdQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<PublicWebsiteDeploymentByIdQuery, PublicWebsiteDeploymentByIdQueryVariables>(PublicWebsiteDeploymentByIdDocument, options)
}
export function usePublicWebsiteDeploymentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicWebsiteDeploymentByIdQuery, PublicWebsiteDeploymentByIdQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<PublicWebsiteDeploymentByIdQuery, PublicWebsiteDeploymentByIdQueryVariables>(PublicWebsiteDeploymentByIdDocument, options)
}
export type PublicWebsiteDeploymentByIdQueryHookResult = ReturnType<typeof usePublicWebsiteDeploymentByIdQuery>
export type PublicWebsiteDeploymentByIdLazyQueryHookResult = ReturnType<typeof usePublicWebsiteDeploymentByIdLazyQuery>
export type PublicWebsiteDeploymentByIdQueryResult = Apollo.QueryResult<PublicWebsiteDeploymentByIdQuery, PublicWebsiteDeploymentByIdQueryVariables>
export const WebsiteEditorGetAssetsIssuesDocument = gql`
    query WebsiteEditorGetAssetsIssues {
  websiteEditorGetAssetsIssues {
    data
  }
}
    `

/**
 * __useWebsiteEditorGetAssetsIssuesQuery__
 *
 * To run a query within a React component, call `useWebsiteEditorGetAssetsIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteEditorGetAssetsIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteEditorGetAssetsIssuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWebsiteEditorGetAssetsIssuesQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteEditorGetAssetsIssuesQuery, WebsiteEditorGetAssetsIssuesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<WebsiteEditorGetAssetsIssuesQuery, WebsiteEditorGetAssetsIssuesQueryVariables>(WebsiteEditorGetAssetsIssuesDocument, options)
}
export function useWebsiteEditorGetAssetsIssuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteEditorGetAssetsIssuesQuery, WebsiteEditorGetAssetsIssuesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<WebsiteEditorGetAssetsIssuesQuery, WebsiteEditorGetAssetsIssuesQueryVariables>(WebsiteEditorGetAssetsIssuesDocument, options)
}
export type WebsiteEditorGetAssetsIssuesQueryHookResult = ReturnType<typeof useWebsiteEditorGetAssetsIssuesQuery>
export type WebsiteEditorGetAssetsIssuesLazyQueryHookResult = ReturnType<typeof useWebsiteEditorGetAssetsIssuesLazyQuery>
export type WebsiteEditorGetAssetsIssuesQueryResult = Apollo.QueryResult<WebsiteEditorGetAssetsIssuesQuery, WebsiteEditorGetAssetsIssuesQueryVariables>
