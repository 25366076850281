/* eslint-disable @typescript-eslint/naming-convention */
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { capitalize } from 'lodash'

import { Chip } from '#app/components/Chip'
import { Table } from '#app/components/Table'
import { useJobStatusMap } from '#app/hooks/useJobs'
import { JobInput } from '#app/types/job'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

interface Props {
  hasCustomerName: boolean
  jobs?: JobInput[]
  statusValues?: string[]
  navigateTo: (job: JobInput) => string
  headers?: any
  tab?: string
}

export const JobsTable = ({ hasCustomerName = true, jobs, navigateTo, statusValues, headers, tab }: Props) => {
  const { t } = useTranslation()
  const statusMap = useJobStatusMap()
  const isDesktop = useIsDesktopSize()

  const defaultHeaders = [{
    text: capitalize(t('jobs.jobs')),
    style: {
      width: hasCustomerName ? '75%' : '50%',
      display: 'flex'
    }
  },
  {
    text: capitalize(t('generic.status')),
    style: {
      width: '25%',
      display: 'flex',
      justifyContent: 'start'
    }
  }]

  if (headers) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    headers = defaultHeaders.map((title, i) => {
      return {
        ...title,
        ...headers[i]
      }
    })
  }
  else {
    headers = defaultHeaders
  }

  if (hasCustomerName) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    headers.splice(1, 0, {
      text: capitalize(t('generic.customer')),
      style: {
        width: '25%',
        display: 'flex'
      }
    })

    headers[0].style.width = '50%'
  }

  let filteredJobs: JobInput[] = []
  if (jobs?.length) {
    // @ts-ignore
    filteredJobs = jobs.filter((job) => statusValues?.includes(job.status))
  }

  const desktopRows = filteredJobs?.length
    ? filteredJobs
      .map((job) => {
        const columns = [
          {
            component: (
              <Box>
                <Link
                  to={navigateTo(job)}
                  state={{
                    job
                  }}
                  style={{
                    width: '90%',
                    textDecoration: 'none',
                    color: 'unset'
                  }}
                >
                  {
                    isDesktop
                      ? <span>{ (` ${new Date(job.created_at ?? '').toLocaleDateString()}`) }</span>
                      : (
                        <Typography variant='body1'>
                          {
                            job.created_at
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            && (`${tab} - ${new Date(job.created_at).toLocaleDateString()}`)
                          }
                        </Typography>
                      )
                  }
                </Link>
              </Box>
            )
          },
          {
            component: (
              <Box>
                <Chip.Basic
                  label={job.status ? statusMap[job.status] : 'unknown'}
                  color='success'
                />
              </Box>
            )
          }
        ]

        if (hasCustomerName && isDesktop) {
          columns.splice(1, 0, {
            component: (
              <Box>
                { job.customer && <Chip.User user={job.customer} /> }
              </Box>
            )
          })
        }

        const row = {
          key: job.id,
          columns
        }

        return row
      })
    : []

  const structure = {
    rows: desktopRows
  }

  return (
    <Table.V2
      mobileMergeColumns
      mobileMergeExludeLastColumn
      headers={headers}
      // @ts-ignore
      structure={structure}
    />
  )
}
