/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react'

export const ownerServices = [
  'Other Services',
  'Swimming Pools, Spas, Hot Tubs & Saunas',
  'Siding',
  'Windows & Doors',
  'Tile & Stone',
  'Household Chores',
  'Moving & Storage',
  'Roofing',
  'Kitchen Remodeling',
  'Painting & Staining',
  'Plumbing',
  'Landscape, Yard & Garden',
  'Home Inspectors & Appraisers',
  'Home Theater',
  'Home Winterization',
  'Home Construction',
  'Electrical & Computers',
  'Fences',
  'Handyman Services',
  'Foundations',
  'Heating & Furnaces',
  'Energy Efficiency',
  'Flooring',
  'Drywall & Insulation',
  'Green Home Improvement Projects',
  'Decorators & Designers',
  'Countertops',
  'Concrete, Brick & Stone',
  'Air Conditioning & Cooling',
  'Cleaning Services',
  'Cabinets',
  'Architects, Builders & Engineers',
  'Bathroom Remodeling',
  'Carpentry & Woodworking',
  'Additions & Remodels'
]

export interface Where {
  user?: any
  services?: any
  cities?: any
  language?: string
}

export const getWhereClause = (userString: string, services?: string, cities?: any[], language?: string, exisingWhere?: Where | undefined | any, linkedPhone?: string) => {
  const defaultWhere = {
    user: {
      _or: [
        {
          search: {
            _ilike: `${userString}`
          }
        },
        {
          phone: {
            _ilike: `${userString}`
          }
        }
      ],
      role: {
        name: {
          _eq: 'User'
        }
      },
      status: {
        _neq: 'deleted'
      }
    }
  }
  let where: Where = exisingWhere ?? defaultWhere

  if (language) {
    where.user.language = {
      _eq: language
    }
  }

  if (linkedPhone) {
    where.user.linked_phones = {
      _contains: [linkedPhone]
    }
  }

  if (services) {
    where = {
      ...where,
      services: {
        _contains: [services]
      }
    }
  }

  if (cities?.length) {
    where = {
      ...where,
      cities: {
        _contains: cities
      }
    }
  }

  return where
}

export const useBusinessWhere = (userString: string, services?: '', cities?: []) => {
  const [whereClause, _setWhereClause] = useState<Where>(getWhereClause(userString, services, cities))

  const setWhereClause = (userString: string, services?: '', cities?: [], language?: string, existingWhere?: Where, linkedPhone?: string) => {
    _setWhereClause(getWhereClause(userString, services, cities, language, existingWhere, linkedPhone))
  }

  return [whereClause, setWhereClause]
}
