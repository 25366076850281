import { useEffect } from 'react'
import { NumericFormat } from 'react-number-format'
import { TextField } from '@mui/material'
import { SxProps } from '@mui/system'
import { useField } from 'formik'

import FormLabel from '../FormLabel/FormLabel'

interface Props {
  placeholder?: string
  popoverText?: string
  sx?: SxProps
  label?: string
  name: string
  disabled?: boolean
  defaultValue?: string
  variant?: 'outlined' | 'standard' | 'filled'
  onChange?: (value: number | undefined) => void
}

export const CurrencyInput = ({ placeholder, label, popoverText, sx, name, defaultValue, disabled = false, variant = 'outlined', onChange }: Props) => {
  const [, meta, helpers] = useField({
    name
  })

  const { setValue } = helpers

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue, setValue])

  return (
    <>
      { label && (
        <FormLabel
          label={label}
          popoverText={popoverText}
        />
      )}
      <NumericFormat
        fullWidth
        customInput={TextField}
        placeholder={placeholder}
        variant={variant}
        sx={sx}
        name={name}
        disabled={disabled}
        value={meta.value}
        error={meta.touched && !!meta.error}

        valueIsNumericString={true}
        allowLeadingZeros={false}
        allowNegative={false}
        allowedDecimalSeparators={['.']}
        decimalScale={2}
        prefix='$'
        onValueChange={(values, sourceInfo) => {
          // pass string if undefined to prevent breaking form validation
          setValue(values.floatValue ?? '')
          if (onChange) {
            onChange(values.floatValue)
          }
        }}
      />
      <div className='error'>{ meta.touched && meta.error }</div>
    </>
  )
}
