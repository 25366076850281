import segmentPlugin from '@analytics/segment'
import Analytics from 'analytics'
import { AnalyticsProvider } from 'use-analytics'

const SegmentContext = ({ children }) => {
  const plugins = []
  if (process.env.REACT_APP_ENV === 'production') {
    plugins.push(segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY
    }))
  }
  const analytics = Analytics({
    app: 'Owners_development',
    // enable debug mode
    debug: process.env.REACT_APP_ENV !== 'production',
    plugins
  })

  return (
    <AnalyticsProvider instance={analytics}>
      { children }
    </AnalyticsProvider>
  )
}

export default SegmentContext
