import { ChangeEvent, useState } from 'react'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getInitials(input: string) {
  const aux = input.split(' ')
  const firstNameInitial = aux[0].split('')[0]
  const lastNameInitial = aux[aux.length - 1].split('')[0]

  return firstNameInitial + lastNameInitial
}

export const generateOnChange = <T extends string | number | boolean | Date | undefined>(
  fieldName: string,
  fieldOnChange: (event: ChangeEvent<{ name: string, value: T }>) => void
) => (
  value: T
) => {
  const event = {
    target: {
      name: fieldName,
      value: (value instanceof Date ? value.toLocaleDateString() : value)
    },
  } as ChangeEvent<{ name: string, value: T }>
  fieldOnChange(event)
}

export const useExpoStatus = () => {
  const [disabledAnimations, setDisabledAnimations] = useState(true)

  return disabledAnimations
}
