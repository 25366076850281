import { cloneElement, useMemo } from 'react'
import { Box, Grid, SxProps } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { useIsDesktopSize } from '#app/utils/mediaQueries'

import { DividerExtended } from '../Divider'

export interface TableStructureV2Row {
  key: string
  columns: Array<{
    component: JSX.Element
    align?: 'right' | 'left' | 'center'
  }>
  data?: any
}
export interface TableStructureV2 {
  rows: TableStructureV2Row[] | []
}

interface Props {
  forceMobile?: boolean
  headers: Array<{
    text: string
    align?: 'right' | 'left' | 'center'
  }>
  structure?: TableStructureV2
  mobileColumns?: number[]
  mobileMergeColumns?: boolean
  mobileMergeExludeLastColumn?: boolean
  sx?: SxProps
  onRowClick?: (data?: any) => void
}

export function TableV2(props: Props) {
  const { mobileColumns, headers, forceMobile, structure, mobileMergeColumns, mobileMergeExludeLastColumn, sx, onRowClick } = props

  const isDesktop = useIsDesktopSize()

  const rowsDesktop = useMemo(() => {
    return structure?.rows ?? []
  }, [structure?.rows])

  const rowsMobile = useMemo(() => {
    if (structure?.rows.length === 0) return []
    if (!mobileColumns) return structure?.rows
    return structure?.rows.map((element, index) => {
      return {
        ...element,
        columns: element.columns.filter((column, index) => mobileColumns?.includes(index + 1))
      }
    })
  }, [mobileColumns, structure?.rows])

  const columnsMobileQty = useMemo(() => {
    if (rowsMobile?.length === 0) return 0
    if (rowsMobile?.[0].columns) {
      return rowsMobile[0].columns.length
    }
    return 0
  }, [rowsMobile])

  return (
    <>
      {(isDesktop && !forceMobile)
      && (
        <TableContainer sx={sx}>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((element, idx) =>
                  (
                    <TableCell
                      key={idx}
                      align={element.align}
                      sx={{
                        fontWeight: '800'
                      }}
                    >
                      {element.text}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsDesktop.map((element, i) => (
                <TableRow
                  key={i}
                  style={{
                    cursor: onRowClick ? 'pointer' : ''
                  }}
                  onClick={onRowClick && (() => onRowClick(element?.data))}
                >
                  {element.columns.map(({ component, align }, index) => {
                    return (
                      <TableCell
                        key={index}
                        align={align}
                      >
                        {cloneElement(component, {
                          key: index
                        })}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {(!isDesktop || forceMobile)
      && (
        <Box
          width='100%'
          sx={sx}
        >
          {rowsMobile?.map((element, i) => (
            <>
              <Grid
                key={i}
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                spacing={4}
                mt={1}
                mb={1}
                onClick={onRowClick && (() => onRowClick(element?.data))}
              >
                {!mobileMergeColumns && element.columns.map(({ component, align }, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={Math.floor(12 / columnsMobileQty)}
                    >
                      <Box sx={columnsMobileQty === index + 1
                        ? {
                          float: 'right'
                        }
                        : null}
                      >
                        {cloneElement(component, {
                          key: index
                        })}
                      </Box>
                    </Grid>
                  )
                })}

                {mobileMergeColumns
                && (
                  <>
                    <Grid
                      item
                      xs={mobileMergeExludeLastColumn ? 9 : 12}
                    >
                      {element.columns.map(({ component, align }, index) => {
                        if (element.columns.length !== index + 1 || !mobileMergeExludeLastColumn) {
                          return (
                            <Box
                              key={index}
                              sx={columnsMobileQty === index + 1
                                ? {
                                  float: 'right'
                                }
                                : null}
                              mt={1}
                            >
                              {cloneElement(component, {
                                key: index
                              })}
                            </Box>
                          )
                        }
                      })}
                    </Grid>
                    {mobileMergeExludeLastColumn && element?.columns[columnsMobileQty - 1]?.component
                    && (
                      <Grid
                        item
                        xs={3}
                      >
                        <Box
                          sx={{
                            float: 'right'
                          }}
                          mt={1}
                        >
                          {cloneElement(element.columns[columnsMobileQty - 1].component, {
                            key: '000'
                          })}
                        </Box>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
              <DividerExtended />
            </>
          )
          )}
        </Box>
      )}
    </>
  )
}
