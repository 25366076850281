import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsDownload, BsEnvelope, BsEye, BsMailbox, BsShare, BsTrash } from 'react-icons/bs'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, CircularProgress, Divider, MenuItem, Stack, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'

import { Btn } from '#app/components/Buttons'
import { DialogConfirm } from '#app/components/Dialog/DialogConfirm'
import ProfileContext from '#app/contexts/ProfileContext'
import { useShare, UseShareProps } from '#app/hooks/useShare'
import { COLORS } from '#app/layouts/theme'
import { Invoice } from '#app/operations/autogenerate/schemas'
import { useCreateInvoiceMutation, useDeleteInvoiceMutation, useEditInvoiceMutation, useShareInvoiceMutation } from '#app/operations/invoices/invoices.mutations.generated'
import { GetInvoicesDocument, GetInvoicesQuery } from '#app/operations/invoices/invoices.queries.generated'
import { InvoiceDetails, InvoiceState, useInvoiceStore } from '#app/store/invoice'
import { User } from '#app/types'
import { UserType } from '#app/types/user'

import { InputWithCustomers } from '../InputWithCustomers/InputWithCustomers'

import { CreateLineItem } from './Invoice/CreateLinteItem'
import { InvoiceLineItems } from './Invoice/InvoiceLineItems'
import { InvoiceTotal } from './Invoice/InvoiceTotal'
import { EstimateChip } from './InvoicesTable/EstimateChip'
import { StatusChip } from './InvoicesTable/StatusChip'
import { UpdateCustomerMissingInformation } from './UpdateCustomerMissingInformation'

interface ClearConfirmationProps {
  resetHandler: () => void
  cancelHandler: () => void
  isEstimate?: boolean
}

const ClearConfirmation = ({ resetHandler, cancelHandler, isEstimate }: ClearConfirmationProps) => {
  const { t } = useTranslation()
  return (
    <Stack spacing={2}>
      {
        isEstimate
          ? (
            <Typography
              variant='h6'
              align='center'
            >
              {t('banking.invoices.create.estimate.clearConfirmationHeader', 'You are about to clear the current estimate')}
            </Typography>
          )
          : (
            <Typography
              variant='h6'
              align='center'
            >
              {t('banking.invoices.create.clearConfirmationHeader', 'You are about to clear the current invoice')}
            </Typography>
          )
      }
      {
        isEstimate
          ? <Typography align='center'>{t('banking.invoices.create.estimate.clearConfirmationText', 'The current estimate is in draft, if you clear it you lose the current information')}</Typography>
          : <Typography align='center'>{t('banking.invoices.create.clearConfirmationText', 'The current invoice is in draft, if you clear it you lose the current information')}</Typography>
      }
      <Stack spacing={2}>
        <Btn.Button
          type='button'
          onClick={resetHandler}
        >
          {
            isEstimate
              ? t('banking.invoices.create.clearEstimate', 'Clear Estimate')
              : t('banking.invoices.create.clearInvoice')
          }
        </Btn.Button>
        <Btn.Button
          primary={false}
          onClick={cancelHandler}
        >
          {t('banking.invoices.create.cancelClear', 'Cancel')}
        </Btn.Button>
      </Stack>
    </Stack>
  )
}

interface ShareButtonProps {
  isSharing: boolean
  primary?: boolean
  onShareAction: (opt: 'email' | 'sms' | 'other') => Promise<boolean | undefined>
}

const ShareButton = ({ isSharing, onShareAction, primary = false }: ShareButtonProps) => {
  const { t } = useTranslation()

  return (
    <Btn.ButtonWithMenu
      isLoading={isSharing}
      disabled={isSharing}
      primary={primary}
      label={t('banking.invoices.create.share.sendAs', 'Send as...')}
    >
      <MenuItem onClick={async () => await onShareAction('sms')}>
        <BsEnvelope />
        <Box sx={{
          ml: 1
        }}
        >
          { t('banking.invoices.create.share.smsShare', 'SMS') }
        </Box>
      </MenuItem>
      <MenuItem onClick={async () => await onShareAction('email')}>
        <BsMailbox />
        <Box sx={{
          ml: 1
        }}
        >
          { t('banking.invoices.create.share.emailShare', 'Email') }
        </Box>
      </MenuItem>
      <MenuItem onClick={async () => await onShareAction('other')}>
        <BsShare />
        <Box sx={{
          ml: 1
        }}
        >
          { t('banking.invoices.create.share.otherShare', 'Other') }
        </Box>
      </MenuItem>
    </Btn.ButtonWithMenu>
  )
}

interface ShareConfirmationProps {
  onContinue: () => void
  isSharing: boolean
  updateCustomer: 'email' | 'phone' | undefined
  onShare: (opt: 'email' | 'sms' | 'other') => Promise<boolean | undefined>
  invoice: InvoiceDetails
  user?: User.UserType
  setUpdateCustomer: (type: 'email' | 'phone' | undefined) => void
  isEstimate?: boolean
}

const ShareConfirmation = ({ onContinue, onShare, isSharing, isEstimate, updateCustomer, invoice, user, setUpdateCustomer }: ShareConfirmationProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const shareAndClose = async (param: 'email' | 'sms' | 'other') => {
    const res = await onShare(param)
    if (res) {
      onContinue()
    }

    return res
  }

  if (updateCustomer) {
    return (
      <UpdateCustomerMissingInformation
        invoice={invoice}
        user={user}
        type={updateCustomer}
        onSubmit={onContinue}
        onCancel={() => setUpdateCustomer(undefined)}
      />
    )
  }

  return (
    <Stack spacing={2}>
      <Typography
        variant='h6'
        align='center'
      >
        {
          isEstimate
            ? t('banking.invoices.create.estimate.shareConfirmationTitle', 'Your estimate was created')
            : t('banking.invoices.create.shareConfirmationTitle', 'Your invoice was created')
        }
      </Typography>
      <Typography align='center'>
        {
          isEstimate
            ? t('banking.invoices.create.estimate.shareConfirmationText', 'Your bid was sent successfully')
            : t('banking.invoices.create.shareConfirmationText', 'You can share your invoice with the button below now or continue and do it later')
        }
      </Typography>
      {
        isEstimate
        && (
          <Stack>
            <Btn.Button onClick={() => navigate('/profile')}>
              {t('banking.invoices.create.estimate.okay', 'Okay')}
            </Btn.Button>
          </Stack>
        )
      }
      {
        !isEstimate
        && (
          <Stack spacing={2}>
            <ShareButton
              isSharing={isSharing}
              primary={true}
              onShareAction={shareAndClose}
            />
            <Btn.Button
              primary={false}
              onClick={onContinue}
            >
              {t('banking.invoices.create.shareLater', 'Share later')}
            </Btn.Button>
          </Stack>
        )
      }
    </Stack>
  )
}

interface CreateInvoiceFormProps {
  onCallback: () => void
  invoice?: InvoiceState['editInvoice'] | InvoiceState['createInvoice']
  asUser?: UserType
  // PM Pilot
  workOrderId?: string
  workOrderBidDetails?: any
  customerData?: Invoice['customer']
}

export const CreateOrEditInvoiceForm = ({
  onCallback,
  invoice: existingInvoice,
  asUser,
  workOrderId,
  workOrderBidDetails,
  customerData
}: CreateInvoiceFormProps) => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState<boolean>(true)
  const { getProfile: { user: userFromProfile } } = useContext(ProfileContext)
  const isEdit = !!existingInvoice
  const navigate = useNavigate()
  const location = useLocation()
  const selectedState = isEdit ? 'edit' : 'create'
  const [updateCustomer, setUpdateCustomer] = useState<'email' | 'phone' | undefined>(undefined)
  const { reset, invoiceQuery, createInvoice, editInvoice } = useInvoiceStore()
  const { setCustomer, addLineItem, removeLineItem, setInvoice } = useInvoiceStore((state) => state[selectedState])
  const invoice = isEdit ? editInvoice : createInvoice

  const isEstimate = useMemo(() => {
    if (workOrderId && workOrderBidDetails) {
      if (customerData) {
        setCustomer(customerData)
      }
      return true
    }
    return false
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrderId, workOrderBidDetails, customerData])

  const isInmmutableInvoice = useMemo(() => {
    return (invoice?.metadata?.workOrderId && invoice?.metadata?.isEstimate)
  }, [invoice])

  const user = asUser ?? userFromProfile

  const [shareData, setShareData] = useState<UseShareProps>()

  const { onShare } = useShare(shareData)

  useEffect(() => {
    isEdit && setInvoice(existingInvoice)
    // adds fake loading time to force a re-render and show the customer from invoice state
    setTimeout(() => setLoading(false), 500)
  }, [])

  useEffect(() => {
    setShareData({
      title: user?.businesses?.[0]?.name ? `${user?.businesses?.[0]?.name} | Invoice Link` : 'Invoice Link',
      text: 'Please accept and pay the invoice through the following link',
      url: `${window.location.protocol}//${window.location.host}/public/${user?.businesses?.[0]?.id ?? ''}/invoices/${invoice?.id ?? ''}`,
      successText: t('banking.invoices.copiedToClipboard', 'Your invoice URL was copied to your clipboard')
    })
  }, [invoice])

  const [createInvoiceMutation] = useCreateInvoiceMutation({
    update(cache, { data }) {
      const cacheQuery = cache.readQuery<GetInvoicesQuery>({
        query: GetInvoicesDocument,
        variables: invoiceQuery
      })

      const existingInvoices = cacheQuery?.invoices ?? []

      const allInvoices = [{
        ...data?.createInvoice?.invoice,
        __typename: 'invoices',
        customer: {
          ...data?.createInvoice?.invoice.customer,
          __typename: 'customers'
        }
      }, ...existingInvoices]

      cache.writeQuery({
        query: GetInvoicesDocument,
        variables: invoiceQuery,
        data: {
          invoices: allInvoices
        }
      })
    }
  })

  const [shareInvoice] = useShareInvoiceMutation()

  const [editInvoiceMutation] = useEditInvoiceMutation({
    update(cache, { data }) {
      const cacheQuery = cache.readQuery<GetInvoicesQuery>({
        query: GetInvoicesDocument,
        variables: invoiceQuery
      })

      const existingInvoices = cacheQuery?.invoices ?? []

      const allInvoices = existingInvoices
        .map((invoice) => {
          if (invoice.id === data?.editInvoice?.invoice.id) {
            return {
              ...invoice,
              ...data?.editInvoice?.invoice,
              __typename: 'invoices',
              customer: {
                ...invoice.customer,
                ...data?.editInvoice?.invoice.customer,
                __typename: 'customers'
              }
            }
          }
          else {
            return invoice
          }
        })

      cache.writeQuery({
        query: GetInvoicesDocument,
        variables: invoiceQuery,
        data: {
          invoices: allInvoices
        }
      })
    }
  })

  const { enqueueSnackbar } = useSnackbar()
  const [isSubmitting, setSubmitting] = useState(false)
  const [clearConfirmation, setClearConfirmation] = useState(false)
  const [shareConfirmation, setShareConfirmation] = useState(false)

  const onCreateOrEditInvoice = () => {
    if (!invoice.customer?.id) {
      enqueueSnackbar(t('banking.invoices.create.noCustomer', 'You need to select a customer'), {
        variant: 'error'
      })
      return
    }

    if (invoice.metadata?.lineItems?.length === 0) {
      enqueueSnackbar(t('banking.invoices.create.noLineItems', 'You need to add at least one item'), {
        variant: 'error'
      })
      return
    }

    if (invoice.total === 0) {
      enqueueSnackbar(t('banking.invoices.create.totalZero', 'Minimum invoice total is 1 dollar'), {
        variant: 'error'
      })
      return
    }

    setSubmitting(true)

    const mutation = isEdit ? editInvoiceMutation : createInvoiceMutation

    let metadata: any = {
      lineItems: invoice
        .metadata
        ?.lineItems
        ?.map((li) => {
          const liCopy = {
            ...li
          }
          liCopy.unitCost = (li?.unitCost ?? 0)
          liCopy.total = (li?.total ?? 0)
          return liCopy
        })
    }

    if (isEstimate && workOrderId) {
      metadata = {
        ...metadata,
        isEstimate,
        workOrderId,
        workOrderBidDetails
      }
    }

    void mutation({
      variables: {
        id: invoice?.id,
        user_id: user?.id,
        business_id: user?.businesses[0]?.id,
        customer_id: invoice.customer?.id,
        status: invoice.status,
        total: (invoice.total ?? 0),
        metadata
      }
    }).then((res) => {
      if (!isEdit) {
        isEstimate
          ? enqueueSnackbar(t('banking.invoices.estimate.create.createdSuccessfully', 'Estimate created successfully'), {
            variant: 'success'
          })
          : enqueueSnackbar(t('banking.invoices.create.createdSuccessfully', 'Invoice created successfully'), {
            variant: 'success'
          })

        reset()

        // @ts-ignore
        const invoiceId = res.data?.createInvoice?.invoice?.id ?? ''

        setTimeout(() => {
          setShareData((previous) => ({
            ...previous,
            url: `${window.location.protocol}//${window.location.host}/public/${user?.businesses?.[0]?.id ?? ''}/invoices/${String(invoiceId)}`
          }))

          // @ts-ignore
          setInvoice(res.data?.createInvoice?.invoice as InvoiceDetails)
        }, 0)

        setShareConfirmation(true)
      }
      else {
        enqueueSnackbar(t('banking.invoices.create.editedSuccessfully', 'Invoice edited successfully'), {
          variant: 'success'
        })
        onCallback()
      }
    }).finally(() => {
      setSubmitting(false)
    })
  }

  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false)
  const [deleteInvoice, { loading: deleteLoading }] = useDeleteInvoiceMutation({
    update(cache, { data }) {
      const cacheQuery = cache.readQuery<GetInvoicesQuery>({
        query: GetInvoicesDocument,
        variables: invoiceQuery
      })

      const existingInvoices = [...(cacheQuery?.invoices ?? [])]

      cache.writeQuery({
        query: GetInvoicesDocument,
        variables: invoiceQuery,
        data: {
          invoices: existingInvoices.filter((inv) => inv.id !== data?.deleteInvoice?.invoice.id)
        }
      })
    }
  })

  const onDelete = () => {
    void deleteInvoice(
      {
        variables: {
          id: invoice?.id,
          user_id: user?.id
        }
      })
      .then(() => {
        enqueueSnackbar(t('banking.invoices.invoicesTable.deleteSuccess', 'Invoice successfully deleted'), {
          variant: 'success'
        })
      })
      .finally(onCallback)
  }

  const onDownload = () => {
    const link = document.createElement('a')
    link.href = String(invoice?.download_link)
    document.body.appendChild(link)
    link.click()
  }

  const onPreviewAsClient = () => {
    let urlInvoice = `/public/${user?.businesses?.[0]?.id ?? ''}/invoices/${String(invoice?.id)}?returnTo=${location.pathname}`
    if (isInmmutableInvoice) {
      urlInvoice = urlInvoice + '&hidePay=true'
    }
    navigate(urlInvoice)
  }

  const clearHandler = () => {
    reset()
    onCallback()
  }

  const [isSharing, setSharing] = useState(false)

  const onShareAction = async (channel: string) => {
    let promise
    switch (channel) {
      case 'sms': {
        if (invoice?.customer?.phone) {
          promise = shareInvoice({
            variables: {
              invoiceId: invoice.id,
              channel: 'sms',
              userId: user?.id
            }
          })
        }
        else {
          setUpdateCustomer('phone')
          return false
        }
        break
      }
      case 'email': {
        if (invoice?.customer?.email) {
          promise = shareInvoice({
            variables: {
              invoiceId: invoice.id,
              channel: 'email',
              userId: user?.id
            }
          })
        }
        else {
          setUpdateCustomer('email')
          return false
        }
        break
      }
      case 'other': {
        promise = onShare()
        break
      }
    }

    setSharing(true)

    return await promise
      ?.then(() => {
        enqueueSnackbar(t('banking.invoices.share.shareSuccess', 'Invoice successfully shared!'), {
          variant: 'success'
        })
      })
      ?.then(() => {
        return true
      })
      .finally(() => {
        setSharing(false)
      })
  }

  const schema = Yup
    .object()
    .shape({
      customer: Yup
        .object()
        .required(t('validation.required'))
    })

  const initialValues = {
    customer: isEstimate ? customerData : invoice.customer
  }

  // confirmation displayed before clearing
  if (clearConfirmation) {
    return (
      <ClearConfirmation
        isEstimate={isEstimate}
        resetHandler={clearHandler}
        cancelHandler={() => setClearConfirmation(false)}
      />
    )
  }

  // share screen displayed after creating an invoice
  if (shareConfirmation) {
    return (
      <ShareConfirmation
        invoice={invoice}
        user={user}
        isSharing={isSharing}
        updateCustomer={updateCustomer}
        setUpdateCustomer={setUpdateCustomer}
        isEstimate={isEstimate}
        onContinue={clearHandler}
        onShare={onShareAction}
      />
    )
  }

  // displayed when we need more information after editing
  if (updateCustomer) {
    return (
      <UpdateCustomerMissingInformation
        invoice={invoice}
        user={user}
        type={updateCustomer}
        onSubmit={onCallback}
        onCancel={() => setUpdateCustomer(undefined)}
      />
    )
  }

  if (isLoading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Stack spacing={4}>
        <Box
          display='flex'
          justifyContent='space-between'
        >
          <Stack spacing={1}>
            { isEstimate && <Typography variant='h6'>{t('banking.invoices.create.createEstimate', 'Create Estimate')}</Typography>}
            { !isEstimate && (isEdit
              ? <Typography variant='h6'>{ t('banking.invoices.editInvoice', 'Invoice details') }</Typography>
              : (
                <Typography variant='h6'>
                  { t('banking.invoices.createInvoice') }
                </Typography>
              ))}
            { existingInvoice && <StatusChip data={existingInvoice} /> }
            { isInmmutableInvoice && <EstimateChip />}
          </Stack>
          <Box>
            {
              isEdit && (
                <ShareButton
                  isSharing={isSharing}
                  onShareAction={onShareAction}
                />
              )
            }
          </Box>
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onCreateOrEditInvoice}
        >
          {() => {
            return (
              <Form id='create-invoice'>
                <Stack spacing={4}>
                  <InputWithCustomers
                    label={t('banking.invoices.create.customer', 'Customer')}
                    name='customer'
                    disabled={invoice.status !== 'created' || !!workOrderId}
                    userId={user?.id}
                    onChange={(customer) => {
                      setCustomer(customer as Invoice['customer'])
                    }}
                  />
                  <InvoiceLineItems
                    invoice={invoice}
                    onRemoveLineItem={removeLineItem}
                  />
                </Stack>
              </Form>
            )
          }}
        </Formik>
        {
          invoice.status === 'created' && !isInmmutableInvoice
            ? <CreateLineItem onSubmit={addLineItem} />
            : (
              <Divider sx={{
                margin: 0,
                borderStyle: 'dashed',
                borderColor: COLORS.dark.primary
              }}
              />
            )
        }
        <InvoiceTotal
          total={(invoice?.total ?? 0) / 100}
          isEstimate={isEstimate}
        />
        <Stack spacing={2}>
          {
            (!isEdit || invoice.status === 'created') && !isInmmutableInvoice
            && (
              <Btn.Button
                disabled={isSubmitting}
                isLoading={isSubmitting}
                primary={!isEdit}
                form='create-invoice'
                type='submit'
              >
                {!isEstimate && (isEdit
                  ? t('banking.invoices.create.editInvoice', 'Edit invoice')
                  : t('banking.invoices.create.createInvoice', 'Create invoice'))}
                {
                  isEstimate
                  && t('banking.invoices.create.createEstimate', 'Create Estimate')
                }
              </Btn.Button>
            )
          }
          {
            isEdit && (
              <Btn.ButtonWithMenu label={t('banking.invoices.create.preview', 'Preview')}>
                <MenuItem onClick={onPreviewAsClient}>
                  <BsEye />
                  <Box sx={{
                    ml: 1
                  }}
                  >
                    { t('banking.invoices.create.previewAsClient', 'Preview as client') }
                  </Box>
                </MenuItem>
                <MenuItem onClick={onDownload}>
                  <BsDownload />
                  <Box sx={{
                    ml: 1
                  }}
                  >
                    { t('banking.invoices.create.downloadInvoice', 'Download') }
                  </Box>
                </MenuItem>
              </Btn.ButtonWithMenu>
            )
          }
          {
            isEdit && invoice.status === 'created' && !isInmmutableInvoice && (
              <Btn.Button
                primary={false}
                startIcon={<BsTrash color='red' />}
                sx={{
                  color: 'red'
                }}
                onClick={() => setDeleteConfirmation(true)}
              >
                {t('banking.invoices.create.deleteInvoice', 'Delete')}
              </Btn.Button>
            )
          }
          {
            !isEdit && (
              <Btn.Button
                primary={false}
                disabled={isSubmitting}
                onClick={() => setClearConfirmation(true)}
              >
                {t('banking.invoices.create.clearInvoice', 'Clear')}
              </Btn.Button>
            )
          }
          {
            invoice.status !== 'created' && (
              <Btn.Button
                primary={false}
                onClick={() => onCallback()}
              >
                {t('banking.invoices.create.cancelInvoice', 'Cancel')}
              </Btn.Button>
            )
          }
        </Stack>
      </Stack>
      <DialogConfirm
        isLoading={deleteLoading}
        setOpen={(status) => setDeleteConfirmation(status)}
        open={deleteConfirmation}
        texts={{
          title: t('banking.invoices.deleteInvoiceTitle', 'Do you want to delete this invoice?'),
          description: t('banking.invoices.deleteInvoiceDescription', 'This process is irreversible and the invoice data will be lost.')
        }}
        onConfirm={onDelete}
      />
    </>
  )
}
