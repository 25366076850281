import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'

import Table from '#app/components/Table/Table'
import ProfileContext from '#app/contexts/ProfileContext'
import { useDayJs } from '#app/hooks/useDayJs'
import { TASK_STATUS, useEntityUserTasks } from '#app/hooks/useEntityUserTasks'
import { EntityPage } from '#app/layouts/EntityView'
import { Generic, Task } from '#app/types'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

import { TaskContent } from './TaskContent'
import { WithoutTasks } from './WithoutTasks'

interface PropsRow {
  task: Task.UserTask
  currentTask?: Task.UserTask
  openTask: (task: Task.UserTask) => void
}

function TaskRow({ task, openTask, currentTask }: PropsRow) {
  const isDesktop = useIsDesktopSize()
  const { formatShortDate } = useDayJs()

  const isTaskActive = useMemo(() => {
    if (task?.id === currentTask?.id && isDesktop) return true
    return false
  }, [currentTask?.id, isDesktop, task?.id])

  return (
    <Table.RowMobile className={`${isTaskActive ? 'v1-owner-row-selected' : ''} card-body pt-0`}>
      <div
        className='d-flex justify-content-start align-items-center w-100 h-100'
        role='button'
        onClick={() => openTask(task)}
      >
        {
          ![
            TASK_STATUS.COMPLETED,
            TASK_STATUS.ADMIN_COMPLETED,
            TASK_STATUS.BO_REPLIED
          ].includes(task?.status)
          && <div className='v1-status-indicator' />
        }
        <div className='owner-identifier flex-grow-1 ps-3'>
          <div className='fw-bold'>
            {task.title}
          </div>
          <small className={`${isTaskActive ? 'text-white' : 'text-muted'}`}>
            {formatShortDate(task.created_at)}
          </small>
        </div>
      </div>
    </Table.RowMobile>
  )
}
interface Props {
  taskList: Task.UserTask[]
  isLoading: boolean
  filtersOptions?: JSX.Element
  isCalled?: boolean
  refetch?: () => Promise<void>
}
export const UserTasks = ({ taskList, isLoading, filtersOptions }: Props) => {
  const isDesktop = useIsDesktopSize()
  const { t } = useTranslation()

  const { getTaskStatusOrder } = useEntityUserTasks()
  const { getProfile: { user } } = useContext(ProfileContext)
  const navigate = useNavigate()
  const params = useParams()

  const urlTaskID = useMemo(() => {
    if (params?.taskId) {
      return params.taskId
    }
    return null
  }, [params])

  const [currentTask, setCurrentTask] = useState<Task.UserTask>()

  const firstTask = useMemo(() => {
    if (taskList ?? taskList[0]) return taskList[0]
    return null
  }, [taskList])

  // Fn: setCurrentTask based on CTA or url
  const openTask = useCallback((task: Task.UserTask | null) => {
    if (task) {
      if (isDesktop) {
        setCurrentTask(task)
      }
      else {
        navigate(`/tasks/${task.id}`)
      }
    }
  }, [isDesktop, navigate])

  // Fn: Get task by id based on current apollo query
  const taskByID = useCallback((taskId: Generic.UserTaskID) => {
    const task = taskList.filter((task) => task.id === taskId)
    if (task?.[0]) {
      return task[0]
    }
    return null
  }, [taskList])

  // Side-Effect: Update current task in case of user edit or change in 'queryData'
  // useEffect(() => {
  //   if (currentTask?.id && taskList) {
  //     const taskUpdated = taskList.filter((task: Task.UserTask) => task.id === currentTask.id)
  //     if (!isEqual(taskUpdated[0], currentTask)) {
  //       setCurrentTask(taskUpdated[0])
  //     }
  //   }
  // }, [currentTask, taskList])

  // Side-Effect: Open task if url param change
  useEffect(() => {
    if (urlTaskID) {
      const task = taskByID(urlTaskID)
      if (task) {
        openTask(taskByID(urlTaskID))
      }
      else if (firstTask) {
        // probably the task of the url does not exist, in that case open the first one in the list
        openTask(firstTask)
      }
    }
  }, [urlTaskID, taskByID, openTask, firstTask])

  // Side-Effect: Load user tasks after being able to get their profile
  useEffect(() => {
    if (taskList && isDesktop && !currentTask) {
      openTask(taskList[0])
    }
  }, [user, openTask, taskList, isDesktop, urlTaskID, currentTask])

  // useEffect(() => {
  //   if (user?.id) {
  //     queryGetUserTasks({ variables: { userID: user.id } }).then((response) => {
  //       const { data } = response
  //       if (isDesktop && data?.user_tasks && Array.isArray(data?.user_tasks) && data?.user_tasks[0]) {
  //         if (urlTaskID) {
  //           const taskById = data?.user_tasks.filter((task) => task.id === urlTaskID)
  //           if (taskById[0]) {
  //             openTask(taskById[0])
  //           }
  //         } else if (data?.user_tasks && data?.user_tasks[0]) {
  //           openTask(data?.user_tasks[0])
  //         }
  //       }
  //     }).catch((err) => console.error(err))
  //   }
  // }, [user, openTask, queryGetUserTasks, isDesktop, urlTaskID])

  // List of Tasks
  const tableData = useMemo(() => {
    if (taskList) {
      let userTasks
      try {
        userTasks = [...taskList].sort((taskA, taskB) => getTaskStatusOrder(taskA.status) - getTaskStatusOrder(taskB.status))
      }
      catch (err) {
        console.error(err)
        userTasks = taskList
      }
      if (userTasks) {
        return userTasks
          .map((task) => ({
            key: task.id,
            columns: [
              {
                component: (
                  <TaskRow
                    task={task}
                    currentTask={currentTask}
                    openTask={openTask}
                  />
                )
              }
            ]
          }))
      }
    }
    return []
  }, [taskList, getTaskStatusOrder, currentTask, openTask])

  // List of Tasks
  const tableStructure = {
    mobile: {
      rows: tableData
    }
  }

  if (isDesktop) {
    return (
      <EntityPage
        pageTitle={t('user_tasks.inbox')}
        isLoading={isLoading}
        awaitLoad={true}
        layout='two-columns'
        listContent={(
          <>
            {filtersOptions}
            {tableStructure.mobile.rows.length !== 0 && (
              <Table
                displayMobile={true}
                structure={tableStructure}
              />
            )}
          </>
        )}
        mainContent={tableStructure.mobile.rows.length === 0
          ? (
            <>
              <Grid
                container
                flex='column'
                justifyContent='center'
                alignItems='center'
                className='h-100'
                sx={{
                  pl: 1,
                  pr: 1,
                  mt: 0
                }}
              >
                <Grid
                  item
                  xs={12}
                >
                  <WithoutTasks />
                </Grid>
              </Grid>
            </>
          )
          : currentTask ? <TaskContent currentTask={currentTask} /> : <></>}
      />
    )
  }

  return (
    <EntityPage
      pageTitle={t('user_tasks.inbox')}
      isLoading={isLoading}
      awaitLoad={true}
      layout='two-columns'
      listContent={(
        <>
          {filtersOptions}
          {tableStructure.mobile.rows.length !== 0
            ? (
              <Table
                displayMobile={true}
                structure={tableStructure}
              />
            )
            : (
              <Grid
                container
                flex='column'
                justifyContent='center'
                alignItems='center'
                className='h-100'
                sx={{
                  pl: 1,
                  pr: 1,
                  mt: 0
                }}
              >
                <Grid
                  item
                  xs={12}
                >
                  <WithoutTasks />
                </Grid>
              </Grid>
            )}
        </>
      )}
      mainContent={tableStructure.mobile.rows.length === 0 ? <></> : currentTask ? <TaskContent currentTask={currentTask} /> : <></>}
    />
  )
}
