import { useMemo } from 'react'
import { Card, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import { Alert } from '#app/components/Alerts'
import { Sequence } from '#app/types'

import { JsonTable } from '../JsonToHtml'

export function AudienceConditions({ audience }: { audience: Sequence.Audience }) {
  const withoutFilter = useMemo(() => {
    const altBoConditions: Record<string, never> = audience?.conditions?.altBO || {}
    if (!audience?.conditions?.altBO) return true
    if (Object.keys(altBoConditions).length === 0) return true
    return false
  }, [audience?.conditions?.altBO])

  return (
    <Card sx={{
      p: 2
    }}
    >
      {/* <Box mb={2}>
        <Typography mb={1}> Cities </Typography>
        <Chip.Group sx={{ m: 1 }} list={parseCities(audience.conditions)} />
        {!audience?.conditions?.cities &&
          <Chip.Basic label='All the cities' />
        }
      </Box>
      <Box mb={2}>
        <Typography mb={1}> Services </Typography>
        {audience?.conditions?.services
          ? <Chip.Group sx={{ m: 1 }} list={audience?.conditions?.services} />
          : <Chip.Basic label='All the services' />
        }
      </Box>
      <Box mb={2}>
        <Typography mb={1}> Languages </Typography>
        {audience?.conditions?.languages && audience?.conditions?.languages.length > 0
          ? <Chip.Group sx={{ m: 1 }} list={audience?.conditions?.languages} />
          : <Chip.Basic label='All the languages' />
        }
      </Box> */}
      <Box mb={2}>
        <Typography mb={1}> altBO </Typography>
        {audience?.conditions?.altBO
        && (
          <Box sx={{
            zoom: '0.75'
          }}
          >
            <JsonTable json={audience?.conditions?.altBO} />
          </Box>
        )}
        {withoutFilter
        && (
          <Alert.Basic
            severity='warning'
            title='There are no filters on this audience'
            description='This audience covers 100% of the business owners registered in altBO'
          />
        )}
      </Box>
    </Card>
  )
}
