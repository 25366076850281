import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'

import { Btn } from '#app/components/Buttons'
import { Input } from '#app/components/Input'
import { useCreateWorkOrderMutation } from '#app/operations/pmmarketplace/pmmarketplace.mutations.generated'
import { PmmarketplaceServiceRequestsQuery } from '#app/operations/pmmarketplace/pmmarketplace.queries.generated'
import { ENVIROMENT } from '#app/store/enviroment'

interface Props {
  serviceRequest: PmmarketplaceServiceRequestsQuery['pmmarketplace_service_requests'][0]
  closeSidePanel?: () => void
}

export function CreateWorkOrder({ serviceRequest, closeSidePanel }: Props) {
  const [createWorkOrder] = useCreateWorkOrderMutation()
  const [airtableForm] = useState(true)

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const workOrderSchema = Yup.object().shape({
    title: Yup.string()
      .required(t('validation.required')),
    due_date: Yup.date()
      .required(t('validation.required')),
    is_follow_up: Yup.string()
      .required(t('validation.required')),
    fixed_price: Yup
      .number()
      .min(0, t('validation.minimum', {
        value: 0
      }))
      .required(t('validation.required')),
    description: Yup.string()
      .required(t('validation.required')),
    additional_notes: Yup.string()
  })

  const onSubmit = useCallback(async (data: any) => {
    const res = await createWorkOrder({
      variables: {
        ...data,
        is_follow_up: data.is_follow_up === 'true',
        service_request_id: serviceRequest.id
      }
    })

    if (res.data?.createWorkOrder?.workOrder.id) {
      const recordNumber = res.data?.createWorkOrder?.workOrder?.fields?.record_number as string
      enqueueSnackbar(`Work order #${recordNumber} created successfully`, {
        variant: 'success'
      })
      if (closeSidePanel) {
        closeSidePanel()
      }
    }
    else {
      enqueueSnackbar('There was a problem, please check in airtable if the work order was generated', {
        variant: 'error'
      })
    }
  }, [createWorkOrder, serviceRequest.id, closeSidePanel, enqueueSnackbar])

  return (
    <Box>
      <Typography variant='h4'>
        {t('workorder.createWorkOrder', 'Create work order')}
      </Typography>
      <Box mt={4}>
        <Card sx={{
          p: 1
        }}
        >
          <Typography variant='subtitle2'>
            Service Request:
            {' '}
            <br />
            <br />

            {serviceRequest.title}
            {' '}
            <br />
            {t('workorder.dueDate', 'Due date')}
            :
            {serviceRequest.due_date}
          </Typography>
        </Card>
      </Box>
      {
        airtableForm
        && (
          <Box mt={4}>
            <iframe
              className='airtable-embed airtable-dynamic-height'
              src={`${ENVIROMENT.airtable_create_work_order}?prefill_service_request=${serviceRequest.id}`}
              frameBorder='0'
              width='100%'
              height='1300px'
            >
            </iframe>
          </Box>
        )
      }

      {!airtableForm
      && (
        <Grid
          container
          mt={4}
        >
          <Grid
            item
            xs={12}
          >
            <Formik
              initialValues={{
                is_follow_up: 'false',
                title: '',
                fixed_price: '',
                due_date: '',
                description: '',
                additional_notes: ''
              }}
              validationSchema={workOrderSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  {/* <Input.Select
                  name='type_service'
                  label='Type of service'
                  options={[
                    { value: 'Cleaning', text: 'Cleaning' },
                    { value: 'Plumbing', text: 'Plumbing' },
                    { value: 'Electrical', text: 'Electrical' },
                    { value: 'Painting', text: 'Painting' },
                    { value: 'Landscaping', text: 'Landscaping' },
                    { value: 'Deck Building', text: 'Deck Building' },
                    { value: 'Other', text: 'Other' }
                  ]}
                /> */}
                  <Input.Generic
                    type='text'
                    label={t('workorder.create.title', 'Title')}
                    name='title'
                  />
                  <Input.Generic
                    type='number'
                    label={t('workorder.create.fixed_price', 'Fixed Price ($)')}
                    name='fixed_price'
                  />
                  <Input.DateTimePicker
                    disablePast={true}
                    label={t('workorder.create.due_date', 'Due Date')}
                    name='due_date'
                  />
                  <Input.Select
                    name='is_follow_up'
                    label={t('workorder.create.is_follow_up', 'Is follow up?')}
                    options={[
                      {
                        text: t('booleans.no'),
                        value: 'false'
                      },
                      {
                        text: t('booleans.yes'),
                        value: 'true'
                      }
                    ]}
                  />
                  <Input.RichTextArea
                    label={t('workorder.create.description', 'Description')}
                    name='description'
                  />
                  <Input.TextArea
                    label={t('workorder.create.additional_notes', 'Additional notes')}
                    name='additional_notes'
                    style={{
                      width: '100%',
                      height: '100px'
                    }}
                  />
                  <Btn.Button
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    type='submit'
                  >
                    {t('workorder.create.btn', 'Create')}
                  </Btn.Button>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}
