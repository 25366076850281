import React from 'react'
function loadScript(src: string, position: any, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  script.defer = true
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  position.appendChild(script)
}

export const ProgressierScript = ({ children }: { children: JSX.Element }) => {
  const loaded = React.useRef(false)

  if (typeof window !== 'undefined' && !loaded.current) {
    // @ts-ignore
    if (document.querySelector('#progressier') == null) {
      const tmp: string = process.env.REACT_APP_PROGRESSIER_ID ? process.env.REACT_APP_PROGRESSIER_ID : ''

      loadScript(
        `https://progressier.com/client/script.js?id=${tmp}`,
        document.querySelector('head'),
        'progressier'
      )
    }

    loaded.current = true
  }

  return (
    <div>
      { children }
    </div>
  )
}

export default ProgressierScript
