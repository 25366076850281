import { ComponentType, useCallback, useContext, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { renderString } from 'nunjucks'
import { ChannelFilters, ChannelSort, User } from 'stream-chat'
import { AvatarProps, useChatContext } from 'stream-chat-react'

import ProfileContext from '#app/contexts/ProfileContext'
import { useGetChatQuery } from '#app/operations/chat/chat.queries.generated'
import { ProfileAvatarDetailed } from '#app/v2/components/ProfileAvatarDetailed'
import { useStreamClient } from '#app/v2-core/hooks/useStreamClient'

export function useStreamChatList(opts: { channelUrl: string }) {
  const { getProfile: { user } } = useContext(ProfileContext)
  const { channel, setActiveChannel } = useChatContext()
  const navigate = useNavigate()

  const { data, loading: chatQueryLoading } = useGetChatQuery({
    variables: {
      userId: user?.id ?? ''
    }
  })

  const chat = data?.getChat?.chat

  useEffect(() => {
    if (channel?.id) {
      navigate(renderString(opts.channelUrl, {
        channelId: channel.id
      }))
    }

    return () => {
      setActiveChannel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel?.id])

  const chatUser: User = useMemo(() => {
    return {
      id: user?.id ?? '',
      name: `${user?.first_name ?? ''} ${user?.last_name ?? ''}`,
      image: user?.profile_picture_url ?? null
    }
  }, [user])

  const chatClient = useStreamClient({
    apiKey: process.env.REACT_APP_STREAM_API_KEY ?? '',
    user: chatUser,
    tokenOrProvider: chat?.token ?? ''
  })

  const filtersAsBO: ChannelFilters = {
    type: 'messaging',
    members: {
      $in: [user?.id ?? '']
    },
    owners_visible_from_bo_side: {
      $nin: ['hidden']
    }
  }

  const sort: ChannelSort = {
    last_message_at: -1
  }

  const AvatarComponent = useCallback(({ image }: { image: string }) => {
    return (
      <ProfileAvatarDetailed
        onlyAvatar
        profilePictureUrl={image}
      />
    )
  }, [])

  return {
    Avatar: AvatarComponent as ComponentType<AvatarProps>,
    sort,
    filters: filtersAsBO,
    chatClient,
    loading: chatQueryLoading || chatClient === undefined
  }
}
