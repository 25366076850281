import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, capitalize, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'

import { Alert } from '#app/components/Alerts'
import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import FormInput from '#app/components/FormInput/FormInput'
import InputWithPlaces from '#app/components/InputWithPlaces/InputWithPlaces'
import PhoneNumberInput from '#app/components/PhoneNumberInput/PhoneNumberInput'
import RightSideBar, { RightSideBarContainer } from '#app/components/RightSideBar/RightSideBar'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { useCreateIssuingCardMutation } from '#app/operations/banking/banking.mutations.generated'
import { GetBankCardsDocument, GetBankCardsQuery, GetPersonsBankingQuery } from '#app/operations/banking/banking.queries.generated'

interface Props {
  openVerify: boolean
  setOpenVerify: Dispatch<SetStateAction<boolean>>
  loadingGetPersonsBanking: boolean
  dataPersonsBanking: GetPersonsBankingQuery | undefined
}

export function RequestCard({
  openVerify,
  setOpenVerify,
  loadingGetPersonsBanking,
  dataPersonsBanking
}: Props) {
  const { userId, stripeConnectedAcc } = useEntityUser()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [createIssuingCard, { loading: createIssuingCardLoading }] = useCreateIssuingCardMutation({
    update(cache, { data }) {
      const cacheQuery = cache.readQuery<GetBankCardsQuery>({
        query: GetBankCardsDocument,
        variables: {
          userId
        }
      })

      const newCard = data?.createIssuingCard
      const currentCards = cacheQuery?.bank_cards ? cacheQuery?.bank_cards : []
      const cards: any[] = [...currentCards, newCard]

      cache.writeQuery({
        query: GetBankCardsDocument,
        variables: {
          userId
        },
        data: {
          bank_cards: cards
        }
      })
    }
  })

  const personConnectedAccount = useMemo(() => {
    if (dataPersonsBanking) {
      const person = dataPersonsBanking?.getPersonsBanking?.persons?.[0]
      if (person) {
        return `${person.first_name as string} ${person.last_name as string}`
      }
    }
    return null
  }, [dataPersonsBanking])

  const emailConnectedAccount = useMemo(() => {
    if (dataPersonsBanking) {
      const person = dataPersonsBanking?.getPersonsBanking?.persons?.[0]
      if (person) {
        return person.email as string
      }
    }
    return null
  }, [dataPersonsBanking])

  const updateState = useCallback((formValues: any, setValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
    const statefromConnect = stripeConnectedAcc?.company?.address?.state
    const stateFromCity = formValues?.city?.terms ? formValues?.city?.terms[1] : null

    if (formValues.state === '') {
      setValue('state', statefromConnect, true)
    }
    else if (stateFromCity?.value && formValues.state !== stateFromCity?.value) {
      const stateVal = stateFromCity?.value
      setValue('state', stateVal ?? '', true)
    }
  }, [stripeConnectedAcc])

  return (
    <RightSideBar
      isOpen={openVerify}
      closeHandler={() => setOpenVerify(false)}
    >
      <RightSideBarContainer>
        <>
          <Typography variant='h4'>
            {t('banking.verifyTitle')}
          </Typography>
          <Typography
            variant='subtitle2'
            mt={2}
            mb={4}
          >
            {t('banking.verifySubtitle2')}
          </Typography>
          <Typography
            variant='subtitle2'
            mb={6}
          >
            <Chip.Support />
          </Typography>
          {
            !personConnectedAccount
            && (
              <>
                <Alert.Basic
                  severity='error'
                  title='Please contact support'
                  description='An error occurred trying to obtain your data to request a new card, to continue you need to contact support.'
                />
              </>
            )
          }
          {
            personConnectedAccount
            && (
              <Formik
                initialValues={{
                  name: personConnectedAccount,
                  email: emailConnectedAccount,
                  phone_number: stripeConnectedAcc?.company?.phone,
                  line1: stripeConnectedAcc?.company?.address?.line1,
                  postal_code: stripeConnectedAcc?.company?.address?.postal_code,
                  city: stripeConnectedAcc?.company?.address?.city,
                  state: ''
                }}
                onSubmit={async (data) => {
                  const response = await createIssuingCard({
                    variables: {
                      billingData: {
                        ...data,
                        city: data?.city?.structured_formatting?.main_text ?? data?.city
                      }
                    }
                  })
                  if (response.data?.createIssuingCard) {
                    enqueueSnackbar(`${t('banking.cardIssuedSuccess')}`, {
                      variant: 'success'
                    })
                    setOpenVerify(false)
                  }
                }}
              >
                {({ isSubmitting, setFieldValue, values }) => {
                  updateState(values, setFieldValue)
                  return (
                    <Form>
                      <FormInput
                        disabled
                        label={capitalize(t('generic.fullName'))}
                        name='name'
                        type='text'
                      />
                      <FormInput
                        disabled
                        label={capitalize(t('generic.email'))}
                        name='email'
                        type='text'
                      />
                      <PhoneNumberInput
                        label={capitalize(t('generic.phone'))}
                        name='phone_number'
                      />
                      <FormInput
                        label={capitalize(t('generic.address'))}
                        name='line1'
                        type='text'
                      />
                      <FormInput
                        label={capitalize(t('generic.postalCode'))}
                        name='postal_code'
                        type='number'
                      />
                      {/* @ts-ignore */}
                      <InputWithPlaces
                        onlyMainText
                        label={capitalize(t('generic.city'))}
                        name='city'
                        types={['locality']}
                        limitTags={5}
                        fields={undefined}
                        countries={undefined}
                      />
                      <Box display='none'>
                        <FormInput
                          disabled
                          label={capitalize(t('generic.state'))}
                          name='state'
                          type='text'
                        />
                      </Box>
                      <Btn.Button
                        type='submit'
                        disabled={isSubmitting || loadingGetPersonsBanking || createIssuingCardLoading}
                      >
                        {capitalize(t('generic.confirm'))}
                      </Btn.Button>
                    </Form>
                  )
                }}
              </Formik>
            )
          }
        </>
      </RightSideBarContainer>
    </RightSideBar>
  )
}
