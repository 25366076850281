import { useEffect, useState } from 'react'
import { json } from '@codemirror/lang-json'
import { Box, Drawer, Stack, Toolbar, Typography } from '@mui/material'
import { vscodeDark } from '@uiw/codemirror-theme-vscode'
import CodeMirror from '@uiw/react-codemirror'
import { ChannelData, ChannelMemberResponse, ChannelResponse, DefaultGenerics, StreamChat } from 'stream-chat'

import { Btn } from '#app/components/Buttons'
import Card from '#app/components/Card/Card'
import { Chip } from '#app/components/Chip'
import { DividerExtended } from '#app/components/Divider'
import { useDayJs } from '#app/hooks/useDayJs'

import { AssignChannel } from './Toolkit/AssignChannel'
import { StatusChannel, StatusChannelEnum } from './Toolkit/StatusChannel'

export function DrawerToolKit({ chatClient, channelId }: { channelId: string | null, chatClient: StreamChat<DefaultGenerics> | undefined }) {
  const [channelInfo, setChannelInfo] = useState<ChannelData<DefaultGenerics> | ChannelResponse<DefaultGenerics> | undefined>()
  const [channelMembers, setChannelMembers] = useState<ChannelMemberResponse<DefaultGenerics>[]>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [metadataInspector, setMetadataInspector] = useState<any | null>(null)
  const [openAssignChannel, setOpenAssignChannel] = useState(false)
  const { formatDateV2 } = useDayJs()

  useEffect(() => {
    setChannelInfo(undefined)
    setChannelMembers(undefined)
  }, [])

  useEffect(() => {
    const fetchChannelInfo = async () => {
      if (chatClient && channelId) {
        try {
          const channels = await chatClient.queryChannels({
            id: {
              $eq: channelId
            },
            // @ts-expect-error ---
            team: {}
          }, [{
            last_message_at: -1
          }], {
            watch: false,
            state: true,
          })

          channels.map((channel) => {
            setChannelInfo(channel.data)
            // eslint-disable-next-line no-console
            console.log('Channel: ' + channel?.cid, channel?.data)
          })

          if (channels.length === 0) {
            setChannelInfo(undefined)
          }
        }
        catch (error) {
          console.error('Error fetching channel info:', error)
          setChannelInfo(undefined)
        }
      }
    }

    const fetchMembers = async () => {
      try {
        if (chatClient && channelId) {
          const channel = chatClient.channel('messaging', channelId)

          const membersResponse = await channel.queryMembers({}, {}, {
            limit: 100,
            offset: 0
          })

          setChannelMembers(membersResponse?.members)
        }
      }
      catch (error) {
        console.error('Error retrieving channel members:', error)
        throw error
      }
    }

    fetchChannelInfo()
    fetchMembers()
  }, [channelId, chatClient])

  return (
    <>
      <Drawer
        variant='temporary'
        open={!!metadataInspector}
        anchor='right'
        onClose={() => setMetadataInspector(null)}
      >
        <Box
          mt={8}
          mx={2}
          width='400px'
        >
          <Btn.Button
            sx={{
              mb: 2
            }}
            onClick={() => setMetadataInspector(null)}
          >
            Close
          </Btn.Button>
          {metadataInspector
          && (
            <CodeMirror
              readOnly
              theme={vscodeDark}
              extensions={[json()]}
              value={JSON.stringify(metadataInspector, null, 2)}
              height='85vh'
            />
          )}
        </Box>
      </Drawer>
      <Drawer
        variant='permanent'
        anchor='right'
        sx={{
          width: 390,
          flexShrink: 0,
          ['& .MuiDrawer-paper']: {
            width: 400,
            boxSizing: 'border-box',
            backgroundColor: '#F9F9F9'
          },
        }}
      >
        <Toolbar />
        <Box
          sx={{
            overflow: 'auto',
            height: '100%'
          }}
        >
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            mx={2}
          >
            <Box>
              <Typography variant='h5'>
                Información
              </Typography>
            </Box>
          </Stack>
          {
            channelInfo
            && channelMembers
            && (
              <>
                <Stack
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  mx={2}
                >
                  <Box
                    mt={1}
                    width='100%'
                  >
                    <Card>
                      <Card.Body>
                        <Stack
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                          spacing={2}
                          mb={2}
                        >
                          <Typography
                            variant='h6'
                          >
                            General
                          </Typography>
                          <Btn.IconButton.Info onClick={() => setMetadataInspector(channelInfo)} />
                        </Stack>
                        <DividerExtended />
                        <>
                          {channelInfo && channelId && chatClient
                          && (
                            <StatusChannel
                              currentStatus={channelInfo?.owners_channel_status as StatusChannelEnum}
                              channelId={channelId}
                              chatClient={chatClient}
                            />
                          )}
                        </>
                        <DividerExtended />
                        <Box>
                          <Typography variant='overline'>Name: &nbsp;</Typography>
                          <Typography variant='overline'><b>{channelInfo?.name as string}</b></Typography>
                        </Box>
                        <Box>
                          <Typography variant='overline'>Created at: &nbsp;</Typography>
                          <Typography variant='overline'>
                            <b>
                              {formatDateV2(channelInfo?.created_at as string)}
                              {' '}
                            </b>
                          </Typography>
                        </Box>
                        <DividerExtended />
                        <Stack spacing={1}>
                          <Box>
                            <Typography variant='overline'>Channel Team: &nbsp;</Typography>
                            <Chip.Basic label={channelInfo?.team as string} />
                            <Chip.Basic
                              sx={{
                                ml: 0.5
                              }}
                              label='Change team'
                              color='secondary'
                              onClick={() => setOpenAssignChannel(true)}
                            />
                          </Box>
                          <Box>
                            <Typography variant='overline'>
                              Assignee: &nbsp;
                            </Typography>
                            <Chip.Clipboard
                              hideIcon
                              label={channelInfo?.owners_admin_assignee_name as string ?? 'Unassigned'}
                              clipboard={channelInfo?.owners_admin_assignee_user_id as string ?? 'ERROR USER ID'}
                            />
                            <Chip.Basic
                              sx={{
                                ml: 0.5
                              }}
                              label='Reassign'
                              color='secondary'
                              onClick={() => setOpenAssignChannel(true)}
                            />
                          </Box>
                          <Box>
                            <Typography variant='overline'>Original Team: &nbsp;</Typography>
                            <Chip.Basic label={channelInfo?.owners_original_team as string} />
                          </Box>
                        </Stack>
                        <DividerExtended />
                        <Stack direction='row'>
                          <Chip.Clipboard
                            label={'BO: ' + channelInfo?.owners_bo_user_fullname as string || channelInfo?.owners_bo_user_id as string}
                            clipboard={channelInfo?.owners_bo_user_id as string}
                          />
                          <Chip.Basic
                            sx={{
                              ml: 0.5
                            }}
                            label='Open'
                            color='success'
                            onClick={channelInfo?.owners_bo_user_id
                              ? () => {
                                if (window?.open) {
                                  window.open(`http://app.joinowners.com/owners-management/${channelInfo?.owners_bo_user_id}`, '_blank')
                                }
                              }
                              : undefined}
                          />
                        </Stack>
                        <Stack mt={0.5}>
                          <Chip.Clipboard
                            label={'Channel: ' + channelInfo?.id as string}
                            clipboard={channelInfo?.id as string}
                          />
                        </Stack>
                      </Card.Body>
                    </Card>
                  </Box>

                  <Box
                    mt={1}
                    width='100%'
                  >
                    <Card>
                      <Card.Body>
                        <Stack
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                          spacing={2}
                          mb={2}
                        >
                          <Typography
                            variant='h6'
                          >
                            Participants (
                            {channelMembers?.length}
                            )
                          </Typography>
                          <Btn.IconButton.Info onClick={() => setMetadataInspector(channelMembers)} />
                        </Stack>
                        <Stack
                          direction='column'
                          spacing={1}
                        >
                          {
                            channelMembers?.map((member, key) => {
                              return (
                                <Chip.Clipboard
                                  key={key}
                                  hideIcon
                                  label={member?.user?.name}
                                  clipboard={member?.user_id ?? 'ERROR USER ID'}
                                />
                              )
                            })
                          }
                        </Stack>
                      </Card.Body>
                    </Card>
                  </Box>

                  <Box
                    mt={1}
                    width='100%'
                  >
                    <Card>
                      <Card.Body>
                        <Stack
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                          spacing={2}
                          mb={2}
                        >
                          <Typography
                            variant='h6'
                          >
                            Created By
                          </Typography>
                          <Btn.IconButton.Info onClick={() => setMetadataInspector(channelInfo?.created_by)} />
                        </Stack>
                        <p>
                          <Typography variant='overline'>Name: &nbsp;</Typography>
                          <Chip.Clipboard
                          // @ts-expect-error ---
                            label={channelInfo?.created_by?.name ? `${channelInfo?.created_by?.name as string} (${channelInfo?.created_by?.role})` : `ID: ${channelInfo?.created_by?.id} (${channelInfo?.created_by?.role})`}
                            // @ts-expect-error ---
                            clipboard={channelInfo?.created_by?.id as string}
                          />
                        </p>
                        <DividerExtended />
                        <>
                          <Typography variant='overline'>Teams: &nbsp;</Typography>
                          {
                          // @ts-expect-error ---
                            channelInfo?.created_by?.teams?.map((team, key) => {
                              return (
                                <Chip.Basic
                                  key={key}
                                  label={team as string}
                                />
                              )
                            })
                          }
                        </>
                        <DividerExtended />
                        <p>
                          <Chip.Clipboard
                          // @ts-expect-error ---
                            label={'ID: ' + channelInfo?.created_by?.id as string}
                            // @ts-expect-error ---
                            clipboard={channelInfo?.created_by?.id as string}
                          />
                        </p>
                      </Card.Body>
                    </Card>
                  </Box>
                </Stack>
              </>
            )
          }
        </Box>
      </Drawer>
      <AssignChannel
        chatClient={chatClient}
        open={openAssignChannel}
        channelTeam={channelInfo?.team as string}
        channelId={channelInfo?.id as string}
        setOpen={setOpenAssignChannel}
      />
    </>
  )
}
