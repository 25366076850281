import { useCallback } from 'react'
import { BsFillCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs'
import { useParams, useSearchParams } from 'react-router-dom'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'

import CreatePayment, { PaymentData } from '#app/forms/PublicPayment/CreatePayment'
import { useCreateConnectedCheckoutSessionMutation } from '#app/operations/banking/banking.mutations.generated'

const SuccessMessage = () => (
  <Stack
    spacing={3}
    alignItems='center'
  >
    <Stack
      direction='row'
      spacing={1}
    >
      <BsFillCheckCircleFill
        size={25}
        color='var(--green)'
      />
      <Typography variant='h4'>Success</Typography>
    </Stack>
    <Typography style={{
      textAlign: 'center'
    }}
    >
      Thank you for your payment!
      {' '}
      <br />
      Your transaction has been completed and we greatly appreciate your support.
      {' '}
      <br />
      Thank you for being our valued customer.
    </Typography>
  </Stack>
)

const CancelledMessage = () => (
  <Stack
    spacing={3}
    alignItems='center'
  >
    <Stack
      direction='row'
      spacing={1}
    >
      <BsFillXCircleFill
        size={25}
        color='var(--error-color)'
      />
      <Typography variant='h4'>Canceled</Typography>
    </Stack>
    <Typography style={{
      textAlign: 'center'
    }}
    >
      We&apos;re sorry to hear that you canceled your payment.
      <br />
      If you have any questions or need help, please don&apos;t hesitate to reach out.
      {' '}
      <br />
      We&apos;re here to help, find the contact information down below!
    </Typography>
  </Stack>
)

export const Payments = () => {
  const { businessId } = useParams()
  const [searchParam] = useSearchParams()

  const [createSession] = useCreateConnectedCheckoutSessionMutation()

  const onSubmit = useCallback(async (data: PaymentData) => {
    const payload = {
      businessId,
      amount: data.amount * 100,
      email: data.email
    }

    return await createSession({
      variables: payload
    })
      .then((result) => {
        const url = result?.data?.createConnectedCheckoutSession?.url

        if (url) {
          window.location.assign(url)
        }

        return true
      })
  }, [])

  return (
    <Stack spacing={5}>
      {
        searchParam?.get('payment_status') === 'success' && <SuccessMessage />
      }
      {
        searchParam?.get('payment_status') === 'cancelled' && <CancelledMessage />
      }
      {
        !searchParam?.get('payment_status') && <CreatePayment submitCallback={onSubmit} />
      }
    </Stack>
  )
}
