import { useTranslation } from 'react-i18next'
import { ApolloCache, DefaultContext, DocumentNode, MutationHookOptions, MutationTuple, OperationVariables, TypedDocumentNode, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

const useMutationWithNotification = (mutation: DocumentNode | TypedDocumentNode<any, OperationVariables>, options?: MutationHookOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined): MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>> => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const [mutateFunction, rest] = useMutation(mutation, {
    ...options,
    onCompleted() {
      enqueueSnackbar(t('general.dataUpdated'), {
        variant: 'success'
      })
    }
  })

  return [mutateFunction, rest]
}

export default useMutationWithNotification
