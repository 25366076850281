import LogoOwners from '#app/assets/svg/logo-owners-light.svg'
import { HeaderSharedNavigation } from '#app/v2/components/HeaderSharedNavigation'

import { CounterSteps } from './components/CounterSteps'
import { WizardContainer } from './page'

export const StoryWizardContainer = () => {
  return (
    <div className='min-h-screen bg-[#F7F9FB]'>
      <HeaderSharedNavigation
        backUrl='/v2-ui/home'
        main={(
          <img
            src={LogoOwners}
            className=' h-6 w-32 justify-self-center'
          />
        )}
        extraActions={(
          <CounterSteps
            currentStep='1'
            totalSteps='6'
          />
        )}
      />
      <WizardContainer>
        <div className='h-20 w-full rounded-lg bg-white shadow-lg'></div>
      </WizardContainer>
    </div>
  )
}
