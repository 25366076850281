import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { motion } from 'framer-motion'

import { TaillessLineArrowRight1 } from '#app/v2/assets/Icons'
import { PageWrapper } from '#app/v2/components/PageWrapper'
import { Button } from '#app/v2/components/ui/button'

interface Props {
  lang?: string
}

export const SearchBubbleUp = ({ lang }: Props) => {
  const { t } = useTranslation()
  // const { state } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const handleButtonClick = (value: string) => {
    setSearchParams({
      type: value
    })
  }
  return (
    <PageWrapper
      pathname='/v2/search'
      className='flex min-h-[calc(100vh-150px)] flex-col gap-4 p-5'
    >
      <p className='px-1 text-sm text-[#454545]'>{t('v2.search.description', 'You can get lists of property managers, constructors, and other potential commercial clients.')}</p>
      {!searchParams.get('type')
      && (
        <>
          <Button
            className='flex w-full justify-between border-transparent text-lg'
            variant='outline'
            onClick={() => handleButtonClick('clients')}
          >
            {t('v2.search.searchCTA', {
              what: t('v2.search.searchItems.clients', 'clients')
            })}
            <TaillessLineArrowRight1 className='size-6' />
          </Button>
          <Button
            className='flex w-full justify-between border-transparent text-lg'
            variant='outline'
            onClick={() => handleButtonClick('companies')}
          >
            {t('v2.search.searchCTA', {
              what: t('v2.search.searchItems.companies', 'companies')
            })}
            <TaillessLineArrowRight1 className='size-6' />
          </Button>
        </>
      )}
      {searchParams.get('type') === 'companies' && (
        <motion.iframe
          initial={{
            animation: 'pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite'
          }}
          animate={{
            animation: 'pulse 1s cubic-bezier(0.4, 0, 0.6, 1) 0s'
          }}
          transition={{
            delay: 0.3
          }}
          className='grow bg-slate-200'
          src={`https://owners-tools.bubbleapps.io/business_search_dev?source=mini-app&lang=${lang}`}
        />
      )}
      {searchParams.get('type') === 'clients' && (
        <motion.iframe
          initial={{
            animationIterationCount: 'infinite',
            animationName: 'pulse',
            animationDuration: '1s',
            animationTimingFunction: 'cubic-bezier(0.4, 0, 0.6, 1)',
            animationPlayState: 'infinite',

          }}
          animate={{
            animationIterationCount: 'unset'
          }}
          transition={{
            delay: 0.3
          }}
          className='grow bg-slate-200'
          src={`https://owners-tools.bubbleapps.io/zip_search?source=mini-app&lang=${lang}`}
        />
      )}
    </PageWrapper>
  )
}
