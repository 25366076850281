import { useCallback, useMemo } from 'react'

import usersAdmins from '#app/store/altbo_admins.json'
import usersAdminsDev from '#app/store/altbo_admins_dev.json'

export function useAdmins() {
  // SELECT "email", "zendesk_user_id", "linear_user_id", "title", "first_name", "last_name", "type" FROM "altbo"."admins" WHERE ("status" = 'active')

  const list = process.env.REACT_APP_ENV !== 'development' ? usersAdmins : usersAdminsDev

  const admins = useMemo(() => list.reduce((acc, admin) => {
    if (admin.linear_user_id) acc[admin.linear_user_id] = admin
    return acc
  }, {} as Record<string, typeof list[0]>), []) // eslint-disable-line react-hooks/exhaustive-deps

  const getAdminNameByLinearUserId = useCallback((linearUserId?: string, mandatory = false) => {
    const defaultName = mandatory ? undefined : 'Owners Staff'
    if (!linearUserId) return defaultName

    const admin = admins[linearUserId]
    if (admin) return `${admin.first_name} ${admin.last_name}`
    return defaultName
  }, [admins])

  const getAdminEmailByLinearUserId = useCallback((linearUserId?: string) => {
    const defaultEmail = 'system@joinowners.com'
    if (!linearUserId) return defaultEmail

    return admins[linearUserId]?.email ?? defaultEmail
  }, [admins])

  const getAdminByLinearUserId = useCallback((linearUserId?: string) => {
    if (!linearUserId) return undefined
    return admins[linearUserId]
  }, [admins])

  return ({
    getAdminNameByLinearUserId,
    getAdminEmailByLinearUserId,
    getAdminByLinearUserId
  })
}
