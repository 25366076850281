import { useEffect, useState } from 'react'

export function useStates() {
  const [states, setStates] = useState()
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    new Promise((resolve, reject) => {
      fetch('/states.json', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
        .then((res) => res.json())
        .then((data) => {
          setStates(data)
          resolve(true)
        }).finally(() => {
          setLoading(false)
        })
    })
  }, [])

  const statesData = states
  return {
    statesData,
    isLoading
  }
}
