import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsFillExclamationOctagonFill, BsGearFill, BsLockFill, BsTrashFill } from 'react-icons/bs'
import { MdOutlineSecurity } from 'react-icons/md'
import { capitalize, Divider, FormControlLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Switch, Typography } from '@mui/material'

import { Alert } from '#app/components/Alerts'
import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import { Dialog } from '#app/components/Dialog'
import RightSideBar, { RightSideBarContainer } from '#app/components/RightSideBar/RightSideBar'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { COLORS, THEME } from '#app/layouts/theme'
import { useUpdateStatusIssuingCardMutation } from '#app/operations/banking/banking.mutations.generated'
import { GetBankCardsDocument, GetBankCardsQuery } from '#app/operations/banking/banking.queries.generated'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

export function CardControl({ cardRow }: { cardRow?: GetBankCardsQuery['bank_cards'][0] }) {
  const isDesktop = useIsDesktopSize()
  const { userId } = useEntityUser()
  const { t } = useTranslation()

  const isCanceled = useMemo(() => {
    if (cardRow?.card?.status === 'canceled') {
      return true
    }
    return false
  }, [cardRow])

  const [confirmDialog, setConfirmDialog] = useState(false)
  const [cardLocked, setCardLocked] = useState(cardRow?.card?.status === 'inactive' || cardRow?.card?.status === 'canceled')
  const [sidebar, setSidebar] = useState<boolean>(false)
  const [cancellationReasonTmp, setCancellationReasonTmp] = useState<string | null>(null)

  const [updateIssuingCard, { loading: updateIssuingCardLoading }] = useUpdateStatusIssuingCardMutation({
    update(cache, { data }) {
      const cacheQuery = cache.readQuery<GetBankCardsQuery>({
        query: GetBankCardsDocument,
        variables: {
          userId
        }
      })

      const updatedCard = data?.updateStatusIssuingCard
      const currentCards = cacheQuery?.bank_cards

      const bankCards = currentCards?.map((card) => {
        if (card.stripe_card_id === updatedCard?.stripe_card_id && updatedCard?.status && updatedCard.card) {
          const res = {
            ...card,
            status: updatedCard.status,
            card: updatedCard.card
          }
          return res
        }
      })

      cache.writeQuery({
        query: GetBankCardsDocument,
        variables: {
          userId
        },
        data: {
          bank_cards: bankCards
        }
      })
    }
  })

  const cancellationReason = useMemo(() => {
    if (cardRow?.card.status === 'canceled') {
      if (cardRow?.card?.cancellation_reason) {
        switch (cardRow?.card?.cancellation_reason) {
          case 'lost': return t('banking.issuing.cancelationReason.lost')
          case 'stolen': return t('banking.issuing.cancelationReason.stolen')
        }
      }
      return t('banking.issuing.status.canceled')
    }
    return ''
  }, [cardRow?.card, t])

  const updateCardLock = useCallback(async (status: string) => {
    if (cardRow?.stripe_card_id && !updateIssuingCardLoading) {
      const payload: any = {
        cardId: cardRow?.stripe_card_id,
        status
      }
      if (status === 'canceled') {
        payload.cancellationReason = cancellationReasonTmp
      }
      await updateIssuingCard({
        variables: payload
      })
      setCardLocked(!cardLocked)
    }
  }, [cardLocked, cardRow?.stripe_card_id, updateIssuingCard, updateIssuingCardLoading, cancellationReasonTmp])

  const confirmCancelCard = useCallback((cancellationReason: string) => {
    setCancellationReasonTmp(cancellationReason)
    setConfirmDialog(true)
  }, [])

  return (
    <>
      <BsGearFill
        size={20}
        color={COLORS.dark.primary}
        style={{
          cursor: 'pointer'
        }}
        onClick={() => setSidebar(true)}
      >
        { t('banking.issuing.cardControl.cardControl', 'Card Control') }
      </BsGearFill>

      <Dialog.Confirm
        open={confirmDialog}
        setOpen={setConfirmDialog}
        onConfirm={async () => await updateCardLock('canceled')}
        onClose={() => setCancellationReasonTmp(null)}
      />

      <RightSideBar
        isOpen={sidebar}
        closeHandler={() => setSidebar(false)}
      >
        <RightSideBarContainer footer={(
          <Btn.Button
            primary
            onClick={() => setSidebar(false)}
          >
            {capitalize(t('generic.close'))}
          </Btn.Button>
        )}
        >
          <>
            <Typography variant='h4'>{ t('banking.issuing.cardControl.cardControl', 'Card Control') }</Typography>
            <Typography
              variant='subtitle2'
              mb={4}
            >
              {cardRow?.card.brand}
              {' '}
              - ****
              {cardRow?.card.last4}
              {
                isCanceled
                && (
                  <Chip.Basic
                    sx={{
                      ml: 2
                    }}
                    color='warning'
                    label={cancellationReason}
                  />
                )
              }
            </Typography>
            {
              isCanceled
              && (
                <Alert.Basic
                  severity='info'
                  title={t('banking.issuing.cancelationAlert.title')}
                  descriptionJSX={(
                    <>
                      {t('banking.issuing.cancelationAlert.description')}
                      {' '}
                      <br />
                      <Chip.Support sx={{
                        mt: 2
                      }}
                      />
                    </>
                  )}
                />
              )
            }
            <MenuList sx={{
              ml: 0,
              mr: 0
            }}
            >
              <MenuItem
                disabled={isCanceled}
                onClick={async () => await updateCardLock(cardLocked ? 'active' : 'inactive')}
              >
                <ListItemIcon>
                  <BsLockFill fontSize={THEME.ICONS.size.md} />
                </ListItemIcon>
                <ListItemText>
                  {t('banking.issuing.cardControl.lockCard.title')}
                  <Typography
                    variant='body2'
                    color='text.secondary'
                  >
                    {t('banking.issuing.cardControl.lockCard.description')}
                  </Typography>
                  <FormControlLabel
                    control={<Switch disabled={updateIssuingCardLoading} />}
                    label={cardLocked ? t('banking.issuing.locked') : t('banking.issuing.unlocked')}
                    checked={cardLocked}
                  />
                </ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem
                disabled={cardLocked || isCanceled || updateIssuingCardLoading}
                onClick={() => confirmCancelCard('lost')}
              >
                <ListItemIcon>
                  <BsFillExclamationOctagonFill fontSize={THEME.ICONS.size.md} />
                </ListItemIcon>
                <ListItemText>
                  {t('banking.issuing.cardControl.lostCard.title')}
                  <Typography
                    variant='body2'
                    color='text.secondary'
                  >
                    {t('banking.issuing.cardControl.lostCard.description')}
                  </Typography>
                </ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem
                disabled={cardLocked || isCanceled || updateIssuingCardLoading}
                onClick={() => confirmCancelCard('stolen')}
              >
                <ListItemIcon>
                  <MdOutlineSecurity fontSize={THEME.ICONS.size.md} />
                </ListItemIcon>
                <ListItemText>
                  {t('banking.issuing.cardControl.stolenCard.title')}
                  <Typography
                    variant='body2'
                    color='text.secondary'
                  >
                    {t('banking.issuing.cardControl.stolenCard.description')}
                  </Typography>
                </ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem
                disabled={cardLocked || isCanceled || updateIssuingCardLoading}
                onClick={() => confirmCancelCard('none')}
              >
                <ListItemIcon>
                  <BsTrashFill fontSize={THEME.ICONS.size.md} />
                </ListItemIcon>
                <ListItemText>
                  {t('banking.issuing.cardControl.deleteCard.title')}
                  <Typography
                    variant='body2'
                    color='text.secondary'
                  >
                    {t('banking.issuing.cardControl.deleteCard.description')}
                  </Typography>
                </ListItemText>
              </MenuItem>
            </MenuList>
          </>
        </RightSideBarContainer>
      </RightSideBar>
    </>
  )
}
