import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'

import Button from '#app/components/Buttons/Button'
import FormSelect from '#app/components/FormSelect/FormSelect'
import InputWithChips from '#app/components/InputWithChips/InputWithChips'
import InputWithPlaces from '#app/components/InputWithPlaces/InputWithPlaces'
import PhoneNumberInput from '#app/components/PhoneNumberInput/PhoneNumberInput'
import { ownerServices } from '#app/hooks/useOwners'

export interface AdvancedFilters {
  cities: any[]
  services: string
  language: string
  linkedPhone?: string
}

interface OwnersFilterProps {
  closeHandler?: () => void
  filters?: AdvancedFilters | undefined
  onSubmit?: (values: any, closeHandler?: () => void) => void
}

export const getNumberOfFilters = (filters: AdvancedFilters | undefined): number => {
  let count = 0

  if (filters?.cities?.length) count++
  if (filters?.services) count++
  if (filters?.language) count++
  if (filters?.linkedPhone) count++

  return count
}

export const OwnersFilter = ({ closeHandler, filters, onSubmit }: OwnersFilterProps) => {
  const initialValues = {
    cities: filters?.cities ?? [],
    services: filters?.services ?? '',
    language: filters?.language ?? '',
    linkedPhone: filters?.linkedPhone ?? ''
  }

  const resetAndSubmit = (resetForm: (arg: any) => void) => {
    const resetValues = {
      cities: [],
      services: '',
      language: '',
      linkedPhone: ''
    }

    resetForm({
      values: resetValues
    })

    if (onSubmit) {
      onSubmit(resetValues, closeHandler)
    }
  }

  if (!onSubmit) {
    return <></>
  }

  return (
    <div>
      <Typography variant='h4'>Filter Owners</Typography>
      <div className='mt-3'>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => onSubmit(values, closeHandler)}
        >
          {({ isSubmitting, resetForm }) => (
            <Form>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <InputWithPlaces
                    multiple
                    label='Cities'
                    name='cities'
                    types={['locality']}
                    limitTags={5}
                    fields={undefined}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    countries={undefined}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <InputWithChips
                    name='services'
                    label='Services'
                    options={ownerServices}
                    optionLabelHandler={undefined}
                    multiple={undefined}
                    limit={undefined}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormSelect
                    label='Language'
                    name='language'
                    options={[
                      {
                        text: 'English',
                        value: 'en'
                      },
                      {
                        text: 'Español',
                        value: 'es'
                      }
                    ]}
                    placeholder={undefined}
                    popoverText={undefined}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <PhoneNumberInput
                    name='linkedPhone'
                    label='Linked Phone'
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <div className='d-flex flex-column flex-lg-row'>
                    <Button
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                      type='submit'
                    >
                      Filter
                    </Button>
                    <div className='ms-lg-3 text-lg-justify mt-lg-0 mt-3 text-center'>
                      <Button
                        primary={false}
                        onClick={() => resetAndSubmit(resetForm)}
                      >
                        Clear all
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
