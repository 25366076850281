import { useTranslation } from 'react-i18next'
import { Alert } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { Btn } from '#app/components/Buttons'

interface Texts {
  title?: string
  description?: string
  warning?: string
  confirm?: string
  cancel?: string
}
interface Props {
  open: boolean
  extraContent?: JSX.Element
  setOpen: (val: boolean) => void
  onClose?: () => void
  onConfirm: () => Promise<object> | any
  withoutCancel?: boolean
  isLoading?: boolean
  texts?: Texts
}

export function DialogConfirm({ isLoading, extraContent = <></>, open, texts, withoutCancel, setOpen, onConfirm, onClose }: Props) {
  const { t } = useTranslation()

  const handleClose = () => {
    if (!isLoading) {
      onClose?.()
      setOpen(false)
    }
  }

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      onClose={handleClose}
    >
      <DialogTitle id='alert-dialog-title'>
        {texts?.title ?? t('dialogs.confirm.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {texts?.description ?? t('dialogs.confirm.subtitle')}
        </DialogContentText>
        {texts?.warning
        && (
          <Alert
            severity='warning'
            sx={{
              mt: 4
            }}
          >
            {texts.warning}
          </Alert>
        )}
        { extraContent }
      </DialogContent>
      <DialogActions sx={{
        p: 2
      }}
      >
        {!withoutCancel
        && (
          <Btn.Button
            secondary
            disabled={isLoading}
            onClick={handleClose}
          >
            {texts?.cancel ?? t('dialogs.confirm.cancel')}
          </Btn.Button>
        )}
        <Btn.Button
          primary
          disabled={isLoading}
          onClick={async () => {
            await onConfirm()
            handleClose()
          }}
        >
          {isLoading ? t('dialogs.loading') : texts?.confirm ?? t('dialogs.confirm.confirm')}
        </Btn.Button>
      </DialogActions>
    </Dialog>
  )
}
