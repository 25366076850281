/* eslint-disable quote-props */

import { FormFlowQuestions } from '../../../feature/FormFlow'

/* eslint-disable @typescript-eslint/quotes */

export const welcomeCallQuestions: FormFlowQuestions = {
  questions: [
    {
      question_es: '👩 Al día de hoy: ¿ya empezaste con tu emprendimiento/ compañía o estás por empezar?',
      question_key: 'question_company_started',
      options: [
        {
          option_es: 'Ya empecé mi emprendimiento',
          option_key: 'option_already_started',
          subquestions: [
            {
              question_es: '👩 ¿Tu empresa tiene un logo?',
              question_key: 'question_company_has_logo',
              options: [
                {
                  option_es: 'Si',
                  option_key: 'option_has_logo',
                  option_bot_conclusion: '👩 ¡Genial! Te vamos a pedir tu logo en formato de imagen (PNG).'
                },
                {
                  option_es: 'No',
                  option_key: 'option_without_logo',
                  subquestions: [
                    {
                      question_es: '👩 ¿Tienes alguna sugerencia en cuánto a colores, objetos, etc.?',
                      question_key: 'question_logo_suggestions',
                      options: [
                        {
                          option_es: 'Si',
                          option_key: 'option_logo_suggestions_yes',
                          option_bot_conclusion: '👩 Para poder crear un logo que represente tanto a ti como a tu compañía, te pediremos que nos des detalles sobre la visión de tu empresa. Luego te daremos opciones de logos, ¡para que puedas elegir el que más te guste!'
                        },
                        {
                          option_es: 'No',
                          option_key: 'option_logo_suggestions_no',
                          option_bot_conclusion: '👩 ¡No te preocupes! Durante tu llamada con el ejecutivo de cuenta tendrás tiempo para revisar los detalles.'
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              question_es: '👩 ¿Tu compañía está registrada legalmente?',
              question_key: 'question_company_registered_legally',
              options: [{
                option_es: 'Si',
                option_key: 'option_company_registered',
                subquestions: [
                  {
                    question_es: '👩 ¿Tienes tu EIN a mano? (Lo necesitarás para abrir tu cuenta bancaria)',
                    question_key: 'question_have_ein_available',
                    options: [
                      {
                        option_es: 'Si',
                        option_key: 'option_ein_available',
                        subquestions: [
                          {
                            question_es: '👩 ¡Perfecto! Escríbelo aquí abajo',
                            question_key: 'question_get_ein',
                            options: [
                              {
                                option_es: 'Ingresa tu EIN',
                                option_key: 'option_ein_freeinput',
                                option_type: 'free_input'
                              },
                              {
                                option_es: 'Llenar más tarde',
                                option_key: 'option_ein_no_input',
                                option_bot_conclusion: 'No te preocupes, es posible que tu ejecutivo de cuentas te pida el EIN más adelante para poder empezar la apertura de tu cuenta Owners Banking, incluida en tu plan.'
                              }
                            ]
                          }
                        ]
                      },
                      {
                        option_es: 'No',
                        option_key: 'option_ein_not_available',
                        option_bot_conclusion: '👩 No te preocupes, es posible que tu ejecutivo de cuentas te pida el EIN más adelante para poder empezar la apertura de tu cuenta Owners Banking, incluida en tu plan.'
                      }]
                  },
                  {
                    question_es: '👩 ¿Cual es el nombre legal de tu negocio?',
                    question_key: 'question_legal_business_name',
                    options: [
                      {
                        option_es: 'Ingrese el nombre legal de su negocio',
                        option_key: 'option_legal_business_freeinput',
                        option_type: 'free_input'
                      },
                      {
                        option_es: 'Completar más tarde',
                        option_key: 'option_define_later'
                      }
                    ]
                  }
                ]
              },
              {
                option_es: 'No',
                option_key: 'option_company_no_registered',
                option_bot_conclusion: 'No te preocupes, durante tu sesión con el ejecutivo de cuenta tendrán tiempo para revisar los detalles.'
              }]
            },
            {
              question_es: '👩 ¿Cuentas con algun seguro para tu compañía?',
              question_key: 'question_company_insurance',
              options: [
                {
                  option_es: 'Si',
                  option_key: 'option_company_with_insurance',
                  subquestions: [
                    {
                      question_es: 'Seguro de vehículo 🚗',
                      question_key: 'question_car_insurance',
                      options: [
                        {
                          option_es: 'Si tengo',
                          option_key: 'option_with_car_insurance'
                        },
                        {
                          option_es: 'No tengo, me interesa conocer más de si es importante y por qué',
                          option_key: 'option_without_car_insurance'
                        }
                      ]
                    },
                    {
                      question_es: 'Seguro de responsabilidad civil (\'liability insurance\') 🤝',
                      question_key: 'question_liability_insurance',
                      options: [
                        {
                          option_es: 'Si tengo',
                          option_key: 'option_with_liability_insurance'
                        },
                        {
                          option_es: 'No tengo, me interesa conocer más de si es importante y por qué',
                          option_key: 'option_without_liability_insurance'
                        }
                      ]
                    },
                    {
                      question_es: 'Seguro para mis trabajadores (workers compensation) 👷‍♀️',
                      question_key: 'question_workers_compensation',
                      options: [
                        {
                          option_es: 'Si tengo',
                          option_key: 'option_with_workers_compensation'
                        },
                        {
                          option_es: 'No tengo, me interesa conocer más de si es importante y por qué',
                          option_key: 'option_without_workers_compensation'
                        }
                      ]
                    }
                  ]
                },
                {
                  option_es: 'No',
                  option_key: 'option_company_without_insurance'
                },
                {
                  option_es: 'No estoy seguro, prefiero conversarlo con mi Business Coach en Owners',
                  option_key: 'option_skip_insurance_info'
                }
              ]
            },
            {
              question_es: '👩 ¿Tu compañía tiene sitio web?',
              question_key: 'question_company_website',
              options: [
                {
                  option_es: 'Si',
                  option_key: 'option_company_with_website',
                  subquestions: [
                    {
                      question_es: '👩 Genial. ¿Podrías compartirnos la URL?',
                      question_key: 'question_website_url',
                      options: [
                        {
                          option_es: 'Ingresa la URL de tu sitio web aquí',
                          option_key: 'option_website_url_freeinput',
                          option_type: 'free_input'
                        },
                        {
                          option_es: 'Más tarde',
                          option_key: 'option_skip_website_url'
                        }
                      ]
                    }
                  ]
                },
                {
                  option_es: 'No',
                  option_key: 'option_company_without_website',
                  option_bot_conclusion: '👩 ¡No te preocupes! Durante tu llamada con el ejecutivo de cuenta tendrás tiempo para revisar los detalles sobre la creación de tu sitio web.'
                }
              ]
            },
            {
              question_es: '👩 ¿Tienes un e-mail exclusivo para cuestiones relacionadas con tu negocio?',
              question_key: 'question_business_email',
              options: [
                {
                  option_es: 'Si',
                  option_key: 'option_business_with_email',
                  subquestions: [
                    {
                      question_es: '👩 Genial. ¿Podrías compartirnos ese e-mail?',
                      question_key: 'question_business_email_share',
                      options: [
                        {
                          option_es: 'Ingresa tu dirección de correo electrónico aquí',
                          option_key: 'option_business_email_freeinput',
                          option_type: 'free_input'
                        },
                        {
                          option_es: 'Más tarde',
                          option_key: 'option_skip_business_email'
                        }
                      ]
                    }
                  ]
                },
                {
                  option_es: 'No',
                  option_key: 'option_business_without_email',
                  option_bot_conclusion: '👩 No te preocupes, durante tu sesión con el ejecutivo de cuenta tendrán tiempo para revisar los detalles.'
                }
              ]
            },
            {
              question_es: '👩 ¿Tienes redes sociales para tu compañía?',
              question_key: 'question_company_social_media',
              options: [
                {
                  option_es: 'Si',
                  option_key: 'option_company_with_social_media',
                  subquestions: [
                    {
                      question_es: '👩 ¿Actualmente, que redes sociales tienes activas en nombre de tu compañia?',
                      question_key: 'question_active_social_media',
                      question_type: 'checkbox',
                      options: [
                        {
                          option_key: 'option_facebook',
                          option_es: 'Facebook'
                        },
                        {
                          option_key: 'option_instagram',
                          option_es: 'Instagram'
                        },
                        {
                          option_key: 'option_google_maps',
                          option_es: 'Google my Business (Google Maps)'
                        },
                        {
                          option_key: 'option_yelp',
                          option_es: 'Yelp'
                        },
                        {
                          option_key: 'option_other_social_media',
                          option_es: 'Otras'
                        }
                      ]
                    }
                  ]
                },
                {
                  option_es: 'No',
                  option_key: 'option_company_without_social_media',
                  option_bot_conclusion: '👩 No te preocupes, más adelante te compartiremos material de apoyo para que puedas crear tus perfiles de redes sociales  con tu ejecutivo de cuentas.'
                }
              ]
            }
          ]
        },
        {
          option_es: 'Contraté Owners para empezar mi emprendimiento',
          option_key: 'option_not_started',
          subquestions: [
            {
              question_es: '👩 ¿Trabajarás en tu negocio a tiempo completo o medio tiempo?',
              question_key: 'question_work_time',
              options: [
                {
                  option_es: 'Tiempo completo',
                  option_key: 'option_work_time_full'
                },
                {
                  option_es: 'Medio Tiempo',
                  option_key: 'option_work_time_part'
                }
              ]
            },
            {
              question_es: '👩 ¿Tienes ya un nombre definido para tu negocio?',
              question_key: 'question_business_name_defined',
              options: [
                {
                  option_es: 'Si',
                  option_key: 'option_business_name_defined_yes',
                  subquestions: [
                    {
                      question_es: '¿Genial, ¿cuál sería el nombre que tienes en mente?',
                      question_key: 'question_business_name_suggestion',
                      options: [
                        {
                          option_es: 'Insertar nombre o sugerencia',
                          option_key: 'option_business_name_freeinput',
                          option_type: 'free_input'
                        },
                        {
                          option_es: 'Definir más tarde',
                          option_key: 'option_define_later'
                        }
                      ]
                    }
                  ]
                },
                {
                  option_es: 'No',
                  option_key: 'option_business_name_defined_no',
                  option_bot_conclusion: 'No te preocupes, durante tu sesión con el ejecutivo de cuenta tendrán tiempo para revisar los detalles.'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      question_es: '👩 ¿Quién es o será parte de este emprendimiento?',
      question_key: 'question_qty_owners',
      options: [
        {
          option_es: 'Solo tú, con la ayuda de Owners',
          option_key: 'option_one_owner'
        },
        {
          option_es: 'Tú y una persona más, con la ayuda de Owners',
          option_key: 'option_two_owners'
        },
        {
          option_es: 'Tú y dos personas más, con la ayuda de Owners',
          option_key: 'option_three_owners'
        },
        {
          option_es: 'Tú y tres personas más, con la ayuda de Owners',
          option_key: 'option_four_owners'
        },
        {
          option_type: 'free_input',
          option_es: 'Otra opción',
          option_key: 'option_multiples_owners_freeinput'
        }
      ]
    },
    {
      question_es: '👩 ¿Qué servicios ofreces o quieres ofrecer? (3 principales)',
      question_key: 'question_services_offered',
      options: [
        {
          option_es: 'Separe los servicios mediante una coma (,)',
          option_key: 'option_services_offered_freeinput',
          option_type: 'free_input',
          option_bot_conclusion: '👩 Perfecto, vamos a analizar esta informacion'
        },
        {
          option_es: 'Completar más tarde',
          option_key: 'option_define_later'
        }
      ]
    },
    {
      question_es: '👩 ¿Cuál es tu domicilio?',
      question_key: 'question_address',
      options: [
        {
          option_es: 'Inserte el domicilio completo',
          option_key: 'option_address_entered_freeinput',
          option_type: 'free_input'
        },
        {
          option_es: 'Completar más tarde',
          option_key: 'option_define_later'
        }
      ]
    },
    {
      question_es: '👩 ¿Tienes una cartera de clientes o contactos de trabajos que ya has hecho anteriormente?',
      question_key: 'question_have_client_portfolio',
      options: [
        {
          option_es: 'Si',
          option_key: 'option_have_client_portfolio_yes',
          subquestions: [
            {
              question_es: '👩¿Cuántos contactos tienes en tu cartera?',
              question_key: 'question_portfolio_contacts_number',
              options: [
                {
                  option_es: '1-5',
                  option_key: 'option_portfolio_contacts_one_to_five'
                },
                {
                  option_es: '6-15',
                  option_key: 'option_portfolio_contacts_six_to_fifteen'
                },
                {
                  option_es: '16+',
                  option_key: 'option_portfolio_contacts_16_plus'
                }
              ]
            }
          ]
        },
        {
          option_es: 'No',
          option_key: 'option_have_client_portfolio_no'
        }
      ]
    },
    {
      question_es: '👩 ¿Tienes disponibilidad para tener una llamada telefonica con uno de nuestros ejecutivos?',
      question_key: 'question_call_availability',
      options: [
        {
          option_es: 'Si',
          option_key: 'option_call_availability_yes'
        },
        {
          option_es: 'No por el momento',
          option_key: 'option_call_availability_no'
        }
      ]
    }
  ]
}
