import { CheckSquare } from '#app/v2/assets/Icons'
import { Label } from '#app/v2/components/ui/label'
import { RadioGroupItem } from '#app/v2/components/ui/radio-group'

// Discussion init for when the component needs
interface Props {
  label?: string
  examples?: string
  isChecked?: boolean // url strings? direct icons? string[] ?
  onClick?: (e: any) => void
}

export const ConfirmationCard = ({ label, examples, onClick, isChecked }: Props) => {
  return (
    <div className='rounded-xl bg-white text-[#454545] shadow'>
      <RadioGroupItem
        value={label ? label : 'error_label_no_value'}
        id={label}
        className='peer sr-only'
        autoFocus={true}
        checked={isChecked}
        onClick={onClick}
      />
      <Label
        htmlFor={label}
        className='group flex w-full cursor-pointer items-center justify-between rounded-xl border border-transparent p-4 font-medium transition-colors peer-data-[state=checked]:border-[#8BC53F]'
      >
        {label}
        {examples
          ? (
            <img
              className='h-9'
              src={examples}
              alt='3 options of how your logo could look like'
            />
          )
          : (
            <CheckSquare className='h-6 w-6 text-[#8BC53F] opacity-0 transition-opacity peer-data-[state=checked]:group-[]:opacity-100' />

          )}
      </Label>
    </div>
  )
}
