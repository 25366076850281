import { Task } from '#app/types'

import { PreviewTaskContent } from '../../feature/Tasks/ViewTask/PreviewTaskContent'
interface Props {
  currentTask: Task.UserTask
  adminSide?: boolean
  // mutationReplyUserTask: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>
  // isLoading: boolean
}

export function TaskContent({ currentTask, adminSide = false }: Props) {
  return (
    <PreviewTaskContent
      adminSide={adminSide}
      task={currentTask}
    />
  )
}
