import { useState } from 'react'

import OwnersLogo from '#app/components/OwnersLogo'

import Burguer from '../../components/icons/Burguer'
import DashboardNav from '../DashboardNav/DashboardNav'

export const Navbar = ({ routes }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <nav
        className='navbar fixed-top bg-white shadow-sm'
        style={{
          zIndex: isOpen ? 1200 : 1201
        }}
      >
        <div className='container-fluid d-flex'>
          <div onClick={() => setOpen(true)}>
            <Burguer />
          </div>
          <div className='flex-grow-1 ms-3'>
            <OwnersLogo
              isContrast
              size='sm'
            />
          </div>
        </div>
      </nav>
      <div className='container-fluid d-flex flex-column h-100'>
        <DashboardNav
          isOpen={isOpen}
          routes={routes}
          closeHandler={() => setOpen(false)}
        />
      </div>
    </>
  )
}
