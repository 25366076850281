import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'src/v2/libs/utils'

const dotVariants = cva(
  'absolute flex h-4 w-4 justify-center items-center rounded-full bg-[#6BE7BE] text-[#304985] text-[10px] font-medium leading-none',
  {
    variants: {
      variant: {
        default:
          'right-0 top-0 ',
        activityCenter:
          '-right-1.5 bottom-0 ',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface NotificationDotProps
  extends React.HTMLAttributes<HTMLDivElement>,
  VariantProps<typeof dotVariants> {
  quantity?: number
}

function NotificationDot({ className, variant, children, quantity, ...props }: NotificationDotProps) {
  const resolvedQuantity = React.useMemo(() => {
    if (quantity && quantity > 9) {
      return '9+'
    }
    return quantity
  }
  , [quantity])
  return (
    <div className='relative'>
      {children}
      <div
        className={cn(dotVariants({
          variant
        }), className, {
          hidden: quantity === 0,
          'h-[1.125rem] w-[1.125rem]': resolvedQuantity === '9+'
        })}
        {...props}
      >
        {resolvedQuantity}
      </div>
    </div>
  )
}

export { NotificationDot, dotVariants }
