/* eslint-disable @typescript-eslint/no-unsafe-argument */
// @ts-nocheck
import Highlight from 'react-highlight'
import { BsApple } from 'react-icons/bs'
import { Box, Card, Grid, Paper, Stack, Typography } from '@mui/material'
import { startCase } from 'lodash'

import { THEME } from '#app/layouts/theme'

export function ThemingDemo() {
  const ColorsTheme = THEME.COLORS
  const IconsTheme = THEME.ICONS

  return (
    <Card sx={{
      p: 4
    }}
    >
      <Typography variant='h5'>Theming - Colors</Typography>
      <Highlight>
        {`
 import { THEME } from '#app/layouts/theme'

// THEME.COLORS =>
${JSON.stringify(ColorsTheme, null, 2)}
        `}
      </Highlight>
      {
        Object.keys(ColorsTheme).map((key: string) => {
          return (
            <Grid
              container
              spacing={2}
              mt={1}
              mb={8}
            >
              <Grid
                item
                xs={12}
              >
                <Typography variant='h4'>
                  {' '}
                  {startCase(key)}
                  {' '}
                </Typography>
              </Grid>
              {Object.keys(ColorsTheme[key]).map((key2: string) => {
                return (
                  <Grid item>
                    <Paper
                      elevation={2}
                      sx={{
                        p: 2
                      }}
                    >
                      <Stack>
                        <Box sx={{
                          backgroundColor: ColorsTheme[key][key2],
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                          mr: 4,
                          mb: 2
                        }}
                        />
                        <Typography>
                          <b>{ColorsTheme[key][key2]}</b>
                        </Typography>
                        <Typography>
                          THEME.COLORS.
                          {key}
                          .
                          {key2}
                        </Typography>
                      </Stack>
                    </Paper>
                  </Grid>
                )
              })}
            </Grid>
          )
        })
      }

      <Typography variant='h5'>Theming - Icons</Typography>
      <Highlight>
        {`
 import { THEME } from '#app/layouts/theme'
 import { BsApple } from 'react-icons/bs'

 <BsApple fontSize={THEME.ICONS.size.md} color={THEME.ICONS.color.primary}/>

// THEME.ICONS =>
${JSON.stringify(IconsTheme, null, 2)}
        `}
      </Highlight>

      <Grid
        container
        spacing={4}
        mb={4}
      >
        <Grid item>
          <BsApple
            fontSize={THEME.ICONS.size.xs}
            color={THEME.ICONS.color.primary}
          />
        </Grid>
        <Grid item>
          <BsApple
            fontSize={THEME.ICONS.size.sm}
            color={THEME.ICONS.color.primary}
          />
        </Grid>
        <Grid item>
          <BsApple
            fontSize={THEME.ICONS.size.md}
            color={THEME.ICONS.color.primary}
          />
        </Grid>
        <Grid item>
          <BsApple
            fontSize={THEME.ICONS.size.lg}
            color={THEME.ICONS.color.primary}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={4}
        mb={8}
      >
        <Grid item>
          <BsApple
            fontSize={THEME.ICONS.size.xs}
            color={THEME.ICONS.color.secondary}
          />
        </Grid>
        <Grid item>
          <BsApple
            fontSize={THEME.ICONS.size.sm}
            color={THEME.ICONS.color.secondary}
          />
        </Grid>
        <Grid item>
          <BsApple
            fontSize={THEME.ICONS.size.md}
            color={THEME.ICONS.color.secondary}
          />
        </Grid>
        <Grid item>
          <BsApple
            fontSize={THEME.ICONS.size.lg}
            color={THEME.ICONS.color.secondary}
          />
        </Grid>
      </Grid>
    </Card>
  )
}
