const Success = () => (<svg width="223" height="223" viewBox="0 0 223 223" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle opacity="0.1" cx="111.5" cy="111.5" r="111.5" fill="#AEBED3"/>
  <mask id="mask0_2041_12575" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="223" height="223">
    <circle cx="111.5" cy="111.5" r="111.5" fill="#AEBED3"/>
  </mask>
  <g mask="url(#mask0_2041_12575)">
    <g clipPath="url(#clip0_2041_12575)">
      <path d="M109.532 150.441C109.53 143.62 111.603 136.96 115.475 131.341L121.377 122.779L123.33 134.53L109.532 150.441Z" fill="#F6B174"/>
      <path d="M103.173 47.1109L114.773 34.8685C117.338 32.1609 120.876 30.5816 124.608 30.4781C128.34 30.3746 131.961 31.7554 134.673 34.3167V34.3167C137.385 36.8782 138.967 40.4105 139.071 44.1364C139.175 47.8624 137.792 51.4769 135.226 54.1848L129.857 59.8512C124.632 65.3657 128.134 74.4653 135.715 75.067L135.775 75.072" stroke="#F7A44A" strokeWidth="6" strokeMiterlimit="10"/>
      <path d="M54.0777 98.9302L42.4767 111.17C39.9113 113.878 38.5283 117.492 38.632 121.218C38.7357 124.944 40.3174 128.476 43.0295 131.038V131.038C45.7419 133.6 49.3624 134.98 53.0945 134.877C56.8267 134.773 60.3648 133.194 62.9306 130.486L68.2997 124.822C73.525 119.308 82.8201 122.295 83.8442 129.817L83.8517 129.878" stroke="#F7BF4A" strokeWidth="6" strokeMiterlimit="10"/>
      <path d="M167.041 146.358L156.774 157.194L121.473 119.105L126.99 113.284L167.041 146.358Z" fill="url(#paint0_linear_2041_12575)"/>
      <path d="M181.976 135.279L146.524 172.694C145.284 171.522 144.561 169.907 144.514 168.203C144.467 166.499 145.1 164.846 146.274 163.608L172.874 135.531C174.048 134.293 175.667 133.572 177.373 133.525C179.08 133.478 180.736 134.11 181.976 135.282V135.279Z" fill="url(#paint1_linear_2041_12575)"/>
      <path d="M129.108 120.8C118.321 132.183 100.891 134.161 87.8251 125.473L42.1392 95.1125C41.9368 94.9779 41.7664 94.8008 41.6397 94.5936C41.513 94.3864 41.4332 94.1541 41.4058 93.9128C41.3784 93.6716 41.4041 93.4273 41.4811 93.1971C41.5581 92.9668 41.6845 92.7561 41.8516 92.5797L96.0396 35.3895C96.2068 35.2133 96.4104 35.0756 96.6364 34.9861C96.8624 34.8966 97.1052 34.8575 97.3479 34.8714C97.5906 34.8853 97.8273 34.9519 98.0416 35.0667C98.2558 35.1814 98.4424 35.3414 98.5882 35.5356L131.49 79.3896C140.899 91.9316 139.893 109.416 129.108 120.8Z" fill="url(#paint2_linear_2041_12575)"/>
      <path d="M112.28 104.905C104.835 112.761 92.4195 113.106 84.5499 105.673L80.461 101.812C72.5906 94.383 72.2498 81.9888 79.6912 74.1315C87.136 66.275 99.5508 65.9306 107.421 73.363L111.509 77.2244C119.382 84.656 119.725 97.051 112.28 104.905Z" fill="url(#paint3_linear_2041_12575)"/>
      <path d="M84.8958 94.882L100.733 78.1675" stroke="#F7B05A" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="square"/>
      <path d="M95.6689 98.3052L104.754 88.7168" stroke="#F7B05A" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="square"/>
      <path opacity="0.42" d="M126.031 129.648L120.616 135.474L48.1561 87.8123L60.2255 74.8276L126.031 129.648Z" fill="url(#paint4_linear_2041_12575)"/>
      <path d="M160.587 189.97L200.033 148.34L181.972 135.277L146.52 172.692L160.587 189.97Z" fill="#2D75EA"/>
      <path d="M161.209 135.964L159.431 135.652L160.235 134.018L156.345 126.196L154.814 126.158L155.331 124.157L152.186 117.833L152.284 116.643L151.524 116.504L151.026 115.502L142.712 115.992L136.916 126.476L154.058 143.666L161.209 135.964Z" fill="#DE7B3A"/>
      <path d="M60.0378 222.997L110.188 161.378L109.364 152.303C108.78 145.886 110.762 139.5 114.878 134.538L122.911 124.849C124.769 122.608 126.79 120.508 128.958 118.566L131.312 116.459C133.442 114.551 135.708 112.799 138.092 111.218L139.921 110.006C141.175 109.174 142.631 108.696 144.135 108.623C145.638 108.55 147.134 108.885 148.463 109.591L148.573 109.649C149.822 110.309 150.843 111.329 151.506 112.575C152.168 113.821 152.44 115.237 152.287 116.64V116.64L149.627 116.688C148.735 116.704 147.873 117.016 147.177 117.573L139.127 124.023L147.229 118.506C148.039 117.954 148.945 117.558 149.901 117.337L150.088 117.294C151.079 117.065 152.117 117.149 153.059 117.532C154.001 117.916 154.801 118.581 155.349 119.437C155.371 119.471 155.394 119.506 155.415 119.541C156.104 120.614 156.363 121.906 156.144 123.161C155.924 124.417 155.241 125.544 154.229 126.321L148.505 130.719L147.324 130.59L148.475 131.513L154.765 126.67C155.186 126.347 155.67 126.115 156.186 125.991C156.702 125.868 157.239 125.854 157.76 125.952C158.282 126.05 158.777 126.256 159.213 126.558C159.649 126.861 160.017 127.251 160.291 127.705L160.354 127.81C161.114 129.06 161.401 130.541 161.162 131.984C160.924 133.427 160.175 134.738 159.053 135.678L154.295 139.655L153.169 139.021L154.445 140.354L158.736 136.703C159.104 136.39 159.53 136.152 159.99 136.004C160.449 135.856 160.934 135.801 161.415 135.84C161.897 135.88 162.365 136.014 162.795 136.236C163.224 136.457 163.605 136.761 163.916 137.13V137.13C164.493 137.815 164.799 138.687 164.775 139.581C164.752 140.476 164.4 141.331 163.788 141.984L161.45 146.202C161.247 146.567 161.011 146.913 160.744 147.234L137.728 174.89L132.028 179.88L103.599 223.003L60.0378 222.997Z" fill="#F4BD8E"/>
      <path style={{ mixBlendMode: 'screen' }} opacity="0.29" d="M60.0378 222.997L110.188 161.378L109.364 152.303C108.78 145.886 110.762 139.5 114.878 134.538L122.911 124.849C124.769 122.608 126.79 120.508 128.958 118.566L131.312 116.459C133.442 114.551 135.708 112.799 138.092 111.218L139.921 110.006C141.175 109.174 142.631 108.696 144.135 108.623C145.638 108.55 147.134 108.885 148.463 109.591L148.573 109.649C149.822 110.309 150.843 111.329 151.506 112.575C152.168 113.821 152.44 115.237 152.287 116.64V116.64L149.627 116.688C148.735 116.704 147.873 117.016 147.177 117.573L139.127 124.023L147.229 118.506C148.039 117.954 148.945 117.558 149.901 117.337L150.088 117.294C151.079 117.065 152.117 117.149 153.059 117.532C154.001 117.916 154.801 118.581 155.349 119.437C155.371 119.471 155.394 119.506 155.415 119.541C156.104 120.614 156.363 121.906 156.144 123.161C155.924 124.417 155.241 125.544 154.229 126.321L148.505 130.719L147.324 130.59L148.475 131.513L154.765 126.67C155.186 126.347 155.67 126.115 156.186 125.991C156.702 125.868 157.239 125.854 157.76 125.952C158.282 126.05 158.777 126.256 159.213 126.558C159.649 126.861 160.017 127.251 160.291 127.705L160.354 127.81C161.114 129.06 161.401 130.541 161.162 131.984C160.924 133.427 160.175 134.738 159.053 135.678L154.295 139.655L153.169 139.021L154.445 140.354L158.736 136.703C159.104 136.39 159.53 136.152 159.99 136.004C160.449 135.856 160.934 135.801 161.415 135.84C161.897 135.88 162.365 136.014 162.795 136.236C163.224 136.457 163.605 136.761 163.916 137.13V137.13C164.493 137.815 164.799 138.687 164.775 139.581C164.752 140.476 164.4 141.331 163.788 141.984L160.291 145.713L137.728 174.89L132.028 179.88L103.599 223.003L60.0378 222.997Z" fill="url(#paint5_linear_2041_12575)"/>
      <path style={{ mixBlendMode: 'multiply' }} opacity="0.25" d="M59.9938 222.936L110.144 161.314L109.319 152.239C108.736 145.822 110.718 139.436 114.833 134.474L122.866 124.785C124.724 122.544 126.745 120.444 128.914 118.502L131.267 116.395C133.398 114.487 135.664 112.735 138.047 111.154L139.876 109.942C141.13 109.11 142.586 108.632 144.09 108.559C145.593 108.486 147.089 108.821 148.418 109.527L148.527 109.586C149.776 110.245 150.798 111.265 151.46 112.511C152.122 113.757 152.395 115.173 152.242 116.576V116.576L149.582 116.624C148.69 116.64 147.828 116.952 147.132 117.509L139.081 123.959L147.184 118.442C147.994 117.89 148.9 117.494 149.855 117.274L150.042 117.23C151.033 117.002 152.071 117.085 153.013 117.468C153.956 117.852 154.756 118.517 155.304 119.372L155.37 119.476C156.058 120.549 156.318 121.841 156.098 123.097C155.879 124.352 155.196 125.48 154.184 126.257L148.46 130.655L147.279 130.526L148.43 131.449L154.721 126.606C155.141 126.283 155.625 126.051 156.141 125.928C156.657 125.804 157.194 125.79 157.715 125.888C158.237 125.986 158.732 126.192 159.168 126.494C159.604 126.797 159.972 127.187 160.246 127.641L160.309 127.746C161.069 128.996 161.356 130.477 161.117 131.92C160.878 133.364 160.13 134.674 159.007 135.614L154.248 139.594L153.123 138.959L154.4 140.293L158.69 136.641C159.057 136.328 159.483 136.091 159.943 135.943C160.403 135.795 160.888 135.739 161.369 135.779C161.851 135.818 162.319 135.953 162.749 136.174C163.178 136.396 163.559 136.7 163.87 137.069V137.069C164.447 137.753 164.753 138.625 164.729 139.52C164.705 140.414 164.354 141.269 163.741 141.923L161.465 146.155C161.251 146.551 160.998 146.925 160.709 147.271L137.683 174.823L131.983 179.812L103.554 222.936H59.9938Z" fill="url(#paint6_linear_2041_12575)"/>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.54">
        <path d="M163.786 141.981L161.448 146.199C161.245 146.564 161.009 146.909 160.742 147.231L137.726 174.887L132.026 179.876L103.597 223H95.3929L126.141 182.3C127.374 180.267 129.072 178.557 131.096 177.308V177.308C133.17 176.028 135.026 174.428 136.596 172.567C136.596 172.567 165.054 140.628 163.786 141.981Z" fill="#F5D0AE"/>
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.89">
        <path d="M131.312 116.459C133.442 114.551 135.708 112.799 138.092 111.217L139.921 110.006C141.175 109.174 142.631 108.696 144.135 108.623C145.638 108.55 147.134 108.885 148.463 109.591L148.573 109.649C149.822 110.309 150.843 111.329 151.506 112.575C152.168 113.821 152.44 115.237 152.287 116.64V116.64L149.627 116.688C148.735 116.704 147.873 117.016 147.177 117.573L139.127 124.023L147.229 118.506C148.04 117.954 148.945 117.558 149.901 117.337L150.088 117.294C151.079 117.066 152.117 117.149 153.059 117.532C154.001 117.916 154.801 118.581 155.349 119.436L155.415 119.54C156.103 120.613 156.363 121.905 156.144 123.161C155.924 124.416 155.241 125.544 154.229 126.321L148.505 130.719L147.324 130.59L148.475 131.513L154.766 126.67C155.187 126.347 155.671 126.115 156.187 125.991C156.703 125.868 157.239 125.854 157.761 125.952C158.282 126.05 158.778 126.256 159.214 126.558C159.65 126.861 160.017 127.251 160.291 127.705L160.354 127.81C161.114 129.06 161.401 130.541 161.162 131.984C160.924 133.427 160.175 134.738 159.053 135.678L154.295 139.655L153.169 139.021L154.445 140.354L158.736 136.703C159.104 136.39 159.53 136.152 159.989 136.004C160.449 135.856 160.934 135.801 161.415 135.84C161.897 135.88 162.365 136.014 162.795 136.236C163.224 136.457 163.605 136.761 163.916 137.13V137.13C164.493 137.815 164.799 138.687 164.775 139.581C164.752 140.476 164.4 141.331 163.788 141.984L160.291 145.713L160.086 144.856C159.809 143.695 159.189 142.644 158.307 141.839C157.424 141.034 156.32 140.512 155.137 140.341L153.689 140.132L153.477 137.869C153.365 136.669 152.891 135.531 152.118 134.605C151.345 133.68 150.309 133.01 149.146 132.685L146.708 132.003L145.733 128.902C145.436 127.953 144.906 127.094 144.191 126.402C143.476 125.71 142.6 125.208 141.641 124.941L138.976 124.199L138.612 121.724C138.42 120.411 137.793 119.2 136.832 118.283C135.872 117.366 134.632 116.795 133.309 116.662L131.312 116.459Z" fill="#F6C69B"/>
      </g>
      <path style={{ mixBlendMode: 'multiply' }} opacity="0.5" d="M131.312 116.459C133.442 114.551 135.708 112.799 138.092 111.217L139.921 110.006C141.175 109.174 142.631 108.696 144.135 108.623C145.638 108.55 147.134 108.885 148.463 109.591L148.573 109.649C149.822 110.309 150.843 111.329 151.506 112.575C152.168 113.821 152.44 115.237 152.287 116.64V116.64L149.627 116.688C148.735 116.704 147.873 117.016 147.177 117.573L139.127 124.023L147.229 118.506C148.04 117.954 148.945 117.558 149.901 117.337L150.088 117.294C151.079 117.066 152.117 117.149 153.059 117.532C154.001 117.916 154.801 118.581 155.349 119.436L155.415 119.54C156.103 120.613 156.363 121.905 156.144 123.161C155.924 124.416 155.241 125.544 154.229 126.321L148.505 130.719L147.324 130.59L148.475 131.513L154.766 126.67C155.187 126.347 155.671 126.115 156.187 125.991C156.703 125.868 157.239 125.854 157.761 125.952C158.282 126.05 158.778 126.256 159.214 126.558C159.65 126.861 160.017 127.251 160.291 127.705L160.354 127.81C161.114 129.06 161.401 130.541 161.162 131.984C160.924 133.427 160.175 134.738 159.053 135.678L154.295 139.655L153.169 139.021L154.445 140.354L158.736 136.703C159.104 136.39 159.53 136.152 159.989 136.004C160.449 135.856 160.934 135.801 161.415 135.84C161.897 135.88 162.365 136.014 162.795 136.236C163.224 136.457 163.605 136.761 163.916 137.13V137.13C164.493 137.815 164.799 138.687 164.775 139.581C164.752 140.476 164.4 141.331 163.788 141.984L160.291 145.713L160.086 144.856C159.809 143.695 159.189 142.644 158.307 141.839C157.424 141.034 156.32 140.512 155.137 140.341L153.689 140.132L153.477 137.869C153.365 136.669 152.891 135.531 152.118 134.605C151.345 133.68 150.309 133.01 149.146 132.685L146.708 132.003L145.733 128.902C145.436 127.953 144.906 127.094 144.191 126.402C143.476 125.71 142.6 125.208 141.641 124.941L138.976 124.199L138.612 121.724C138.42 120.411 137.793 119.2 136.832 118.283C135.872 117.366 134.632 116.795 133.309 116.662L131.312 116.459Z" fill="url(#paint7_linear_2041_12575)"/>
      <path d="M94.3447 163.306L87.0612 174.669C86.7287 175.185 86.0055 174.9 85.4818 174.046C84.9589 173.202 84.8417 172.143 85.2233 171.722L93.7196 162.301C93.8559 162.15 94.1186 162.301 94.284 162.623C94.4103 162.876 94.4378 163.163 94.3447 163.306Z" fill="#DAE2EF"/>
      <path d="M86.2042 155.964L75.6081 161.311C75.1268 161.554 74.3887 160.953 73.9946 159.998C73.6056 159.053 73.712 158.146 74.2291 158.006L85.7328 154.837C85.9174 154.787 86.1742 155.042 86.2848 155.385C86.3721 155.654 86.3388 155.896 86.2042 155.964Z" fill="#DAE2EF"/>
      <path d="M84.3031 146.254L72.0894 147.439C71.535 147.493 70.8242 146.717 70.5366 145.744C70.2532 144.782 70.4992 144.018 71.0778 144.065L83.9581 145.101C84.1642 145.118 84.4053 145.428 84.4751 145.765C84.5333 146.036 84.4618 146.239 84.3031 146.254Z" fill="#DAE2EF"/>
      <path d="M153.482 92.7933L156.967 77.5941C157.125 76.9036 158.025 76.9302 158.933 77.6547C159.832 78.37 160.373 79.4754 160.123 80.0845L154.56 93.6439C154.471 93.8613 154.127 93.8008 153.821 93.5136C153.579 93.2871 153.438 92.9858 153.482 92.7933Z" fill="#DAE2EF"/>
      <path d="M165.208 97.6089L174.647 87.667C175.076 87.2155 176.114 87.584 176.915 88.467C177.707 89.3433 177.946 90.3757 177.44 90.7276L166.161 98.6546C165.981 98.7815 165.601 98.6048 165.347 98.2745C165.146 98.0122 165.088 97.7359 165.208 97.6089Z" fill="#DAE2EF"/>
      <path d="M171.066 107.435L183.888 101.39C184.47 101.115 185.546 101.682 186.239 102.629C186.923 103.565 186.954 104.492 186.307 104.667L171.892 108.557C171.661 108.619 171.278 108.375 171.069 108.033C170.904 107.761 170.903 107.512 171.066 107.435Z" fill="#DAE2EF"/>
    </g>
  </g>
  <defs>
    <linearGradient id="paint0_linear_2041_12575" x1="137.36" y1="189.48" x2="146.697" y2="125.014" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFE887"/>
      <stop offset="0.14" stopColor="#FDDE7B"/>
      <stop offset="0.4" stopColor="#F9C55D"/>
      <stop offset="0.74" stopColor="#F39C2B"/>
      <stop offset="1" stopColor="#ED7900"/>
    </linearGradient>
    <linearGradient id="paint1_linear_2041_12575" x1="148.53" y1="123.636" x2="171.796" y2="165.457" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFDE59"/>
      <stop offset="0.21" stopColor="#FDD251"/>
      <stop offset="0.57" stopColor="#F6B43B"/>
      <stop offset="1" stopColor="#ED881D"/>
    </linearGradient>
    <linearGradient id="paint2_linear_2041_12575" x1="21.5957" y1="57.0429" x2="140.027" y2="95.7944" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFE887"/>
      <stop offset="0.17" stopColor="#FDDE7B"/>
      <stop offset="0.49" stopColor="#F8C25D"/>
      <stop offset="0.9" stopColor="#EF952B"/>
      <stop offset="1" stopColor="#ED881D"/>
    </linearGradient>
    <linearGradient id="paint3_linear_2041_12575" x1="66.2851" y1="80.3192" x2="188.635" y2="116.28" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFE887"/>
      <stop offset="0.17" stopColor="#FDDF7B"/>
      <stop offset="0.49" stopColor="#F8C65D"/>
      <stop offset="0.9" stopColor="#EF9E2B"/>
      <stop offset="1" stopColor="#ED931D"/>
    </linearGradient>
    <linearGradient id="paint4_linear_2041_12575" x1="116.314" y1="130.191" x2="55.4428" y2="68.7789" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFFD25"/>
    </linearGradient>
    <linearGradient id="paint5_linear_2041_12575" x1="107.267" y1="228.168" x2="120.629" y2="105.965" gradientUnits="userSpaceOnUse">
      <stop/>
      <stop offset="0.1" stopColor="#121212"/>
      <stop offset="0.31" stopColor="#424242"/>
      <stop offset="0.6" stopColor="#8E8E8E"/>
      <stop offset="0.97" stopColor="#F6F6F6"/>
      <stop offset="1" stopColor="white"/>
    </linearGradient>
    <linearGradient id="paint6_linear_2041_12575" x1="105.263" y1="166.956" x2="125.019" y2="181.349" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="0.3" stopColor="#FFFEFC"/>
      <stop offset="0.49" stopColor="#FEF9F4"/>
      <stop offset="0.65" stopColor="#FEF1E5"/>
      <stop offset="0.8" stopColor="#FCE7D0"/>
      <stop offset="0.93" stopColor="#FBD9B5"/>
      <stop offset="1" stopColor="#FAD0A4"/>
    </linearGradient>
    <linearGradient id="paint7_linear_2041_12575" x1="141.213" y1="135.433" x2="155.017" y2="122.343" gradientUnits="userSpaceOnUse">
      <stop offset="0.05" stopColor="white"/>
      <stop offset="0.33" stopColor="#FFFEFC"/>
      <stop offset="0.51" stopColor="#FEF9F4"/>
      <stop offset="0.67" stopColor="#FEF1E5"/>
      <stop offset="0.81" stopColor="#FCE7D0"/>
      <stop offset="0.94" stopColor="#FBD9B5"/>
      <stop offset="1" stopColor="#FAD0A4"/>
    </linearGradient>
    <clipPath id="clip0_2041_12575">
      <rect width="164.472" height="195.588" fill="white" transform="translate(35.5615 27.4121)"/>
    </clipPath>
  </defs>
</svg>
)

export default Success
