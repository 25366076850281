import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { data } from './data'
import { Invoice } from './page'

export const StoryInvoice = () => {
  const [searchParams] = useSearchParams()

  const storyProps = useMemo(() => {
    const scenario = searchParams.get('scenario')

    const res = {
      logo: data.logo,
      companyName: data.companyName,
      address: data.address,
      email: data.email,
      phoneNumber: data.phoneNumber,
      invoiceNumber: data.invoiceNumber,
      date: data.date,
      dueDate: data.dueDate,
      services: data.services,
      enabledTips: data.enabledTips,
      primaryColor: data.primaryColor,

    }

    switch (scenario) {
      case 'no_tips':
        res.enabledTips = false
        break
      default:
        break
    }

    return res
  }, [searchParams])
  return (
    <Invoice {...storyProps} />
  )
}
