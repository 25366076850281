// @ts-nocheck
import Highlight from 'react-highlight'
import { Box, Card } from '@mui/material'
import Typography from '@mui/material/Typography'

import { THEME } from '#app/layouts/theme'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

export function TypographyDemo() {
  const isDesktop = useIsDesktopSize()

  return (
    <Card sx={{
      p: 4
    }}
    >
      <Typography variant='h5'>Typography</Typography>
      <Highlight>
        {`
import Typography from '@mui/material/Typography'

<Typography variant="h1" gutterBottom>
  h1. Heading
</Typography>
<Typography variant="h2" gutterBottom>
  h2. Heading
</Typography>
<Typography variant='h3' gutterBottom>
  h3. Heading
</Typography>
<Typography variant="h4" gutterBottom>
  h4. Heading
</Typography>
<Typography variant="h5" gutterBottom>
  h5. Heading
</Typography>
<Typography variant="h6" gutterBottom>
  h6. Heading
</Typography>
        `}
      </Highlight>

      <Box
        sx={{
          width: '100%'
        }}
        mb={8}
      >
        {THEME.MUI.components.MuiTypography.variants.map((variant) => {
          if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(variant.props.variant)) {
            const fontMobile = variant.style['@media (max-width: 1200px)'].fontSize as string
            return (
              <Typography
                variant={variant.props.variant}
                sx={{
                  mb: 3
                }}
              >
                {variant.props.variant}
                {' '}
                Heading
                {
                  isDesktop
                    ? (
                      <>
                        (
                        {variant.style.fontSize}
                        px -
                        {variant.style.fontWeight}
                        )
                      </>
                    )
                    : (
                      <>
                        (
                        {fontMobile}
                        px -
                        {variant.style.fontWeight}
                        )
                      </>
                    )
                }
              </Typography>
            )
          }
        })}
      </Box>

      <Highlight>
        {
          `
import Typography from '@mui/material/Typography'

<Typography variant="subtitle1" gutterBottom>
  subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
  blanditiis tenetur
</Typography>
<Typography variant="subtitle2" gutterBottom>
  subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
  blanditiis tenetur
</Typography>
<Typography variant="body1" gutterBottom>
  body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
  blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
  neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
  quasi quidem quibusdam.
</Typography>
<Typography variant="body2" gutterBottom>
  body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
  blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
  neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
  quasi quidem quibusdam.
</Typography>
<Typography variant="button" display="block" gutterBottom>
  button text
</Typography>
<Typography variant="caption" display="block" gutterBottom>
  caption text
</Typography>
<Typography variant="overline" display="block" gutterBottom>
  overline text
</Typography>  
  `
        }
      </Highlight>

      <Box sx={{
        width: '100%'
      }}
      >
        <Typography
          gutterBottom
          variant='subtitle1'
        >
          subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur
        </Typography>
        <Typography
          gutterBottom
          variant='subtitle2'
        >
          subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur
        </Typography>
        <Typography
          gutterBottom
          variant='body1'
        >
          body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
          neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
          quasi quidem quibusdam.
        </Typography>
        <Typography
          gutterBottom
          variant='body2'
        >
          body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
          neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
          quasi quidem quibusdam.
        </Typography>
        <Typography
          gutterBottom
          variant='button'
          display='block'
        >
          button text
        </Typography>
        <Typography
          gutterBottom
          variant='caption'
          display='block'
        >
          caption text
        </Typography>
        <Typography
          gutterBottom
          variant='overline'
          display='block'
        >
          overline text
        </Typography>
      </Box>

    </Card>
  )
}
