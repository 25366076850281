import { useState } from 'react'
import { FaArrowDown } from 'react-icons/fa'
import { json } from '@codemirror/lang-json'
import { Accordion, AccordionDetails, AccordionSummary, Box, Switch } from '@mui/material'
import CodeMirror from '@uiw/react-codemirror'

import { WebsiteEditorGetStaticVariablesQuery } from '#app/operations/website-editor/websiteeditor.queries.generated'

interface Obj {
  [key: string]: unknown
}

interface Props {
  nunjucksContext: Obj
  nunjucksStaticContext: WebsiteEditorGetStaticVariablesQuery | undefined
}

export function NunjucksPreviewContext({ nunjucksContext, nunjucksStaticContext }: Props) {
  const [flatModeStatic, setFlatModeStatic] = useState(true)
  const [flatModeDynamic, setFlatModeDynamic] = useState(true)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const flattenObject = (obj: any, parentKey = '', result: Record<string, any> = {}): Record<string, any> => {
    try {
      Object.entries(obj).forEach(([key, value]) => {
        const fullKey = parentKey ? `${parentKey}.${key}` : key

        if (value && typeof value === 'object' && !Array.isArray(value)) {
          flattenObject(value, fullKey, result)
        }
        else if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if (item && typeof item === 'object') {
              flattenObject(item, `${fullKey}[${index}]`, result)
            }
            else {
              result[`${fullKey}[${index}]`] = item
            }
          })
        }
        else {
          result[fullKey] = value
        }
      })
    }
    catch (error) {
      console.error('Error flattening object:', error)
      return obj
    }

    return result
  }

  return (
    <Box mb={2}>
      <Accordion>
        <AccordionSummary expandIcon={<FaArrowDown />}>
          New Variables (Static Variables)
        </AccordionSummary>
        <AccordionDetails>
          <Switch onChange={() => { setFlatModeStatic(!flatModeStatic) }} />
          <CodeMirror
            readOnly
            extensions={[json()]}
            value={flatModeStatic ? JSON.stringify(nunjucksContext, null, 2) : JSON.stringify(flattenObject(nunjucksContext), null, 2)}
            height='500px'
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<FaArrowDown />}>
          altBo Airtable (Dynamic Variables)
        </AccordionSummary>
        <AccordionDetails>
          <Switch onChange={() => { setFlatModeDynamic(!flatModeDynamic) }} />
          {nunjucksStaticContext?.websiteEditorGetStaticVariables && (
            <CodeMirror
              readOnly
              extensions={[json()]}
              value={flatModeDynamic ? JSON.stringify(nunjucksStaticContext.websiteEditorGetStaticVariables, null, 2) : JSON.stringify(flattenObject(nunjucksStaticContext.websiteEditorGetStaticVariables), null, 2)}
              height='500px'
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
