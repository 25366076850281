import { useMemo } from 'react'
import { Stack } from '@mui/system'

import { LoadingGrid } from '#app/components/Loading/LoadingGrid'
import WorkOrdersDetails from '#app/feature/WorkOrders/WorkOrdersDetails/WorkOrdersDetails'
import { useGetWorkOrderDetailsQuery } from '#app/operations/pmmarketplace/pmmarketplace.queries.generated'

export const WorkOrderDetails = () => {
  // Get the query parameters from the URL
  const queryParams = new URLSearchParams(window.location.search)
  // Get the value of a specific query parameter
  const jobsId = queryParams.get('work_order_id')
  const spId = queryParams.get('sp_id')
  // const candidateId = queryParams.get('candidate_id')

  const { data, loading, error } = useGetWorkOrderDetailsQuery({
    variables: {
      id: jobsId ?? ''
    }
  })

  const workOrderData: any = useMemo(() => {
    const fields = data?.getWorkOrder?.work_order.pmmarketplace_work_orders_by_pk

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return fields || {}
  }, [data])

  const generateBid = () => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    window.location.href = `/public/service-providers/work-order/bid?work_order_id=${jobsId}&sp_id=${spId}`
  }

  return (
    <Stack spacing={5}>
      {
        loading
        && (
          <Stack sx={{
            minWidth: '300px'
          }}
          >
            <LoadingGrid />
          </Stack>
        )
      }
      {
        !loading
        && (
          <WorkOrdersDetails
            status={workOrderData?.status}
            workOrderId={data?.getWorkOrder?.work_order.pmmarketplace_work_orders_by_pk.id}
            loading={loading}
            onClick={generateBid}
            {...workOrderData}
          />
        )
      }
    </Stack>
  )
}
