import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type UpdateBotFlowResultsMutationVariables = Types.Exact<{
  flow_name: Types.Scalars['String']
  company_id: Types.Scalars['String']
  contact_id: Types.Scalars['String']
  payload: Types.Scalars['jsonb']
}>

export type UpdateBotFlowResultsMutation = { __typename?: 'mutation_root', updateOnboardingWelcomeFlow?: { __typename?: 'UpdateOnboardingWelcomeFlowOutput', data: any, success: boolean } | undefined }

export const UpdateBotFlowResultsDocument = gql`
    mutation UpdateBotFlowResults($flow_name: String!, $company_id: String!, $contact_id: String!, $payload: jsonb!) {
  updateOnboardingWelcomeFlow(
    input: {flow_name: $flow_name, company_id: $company_id, contact_id: $contact_id, payload: $payload}
  ) {
    data
    success
  }
}
    `
export type UpdateBotFlowResultsMutationFn = Apollo.MutationFunction<UpdateBotFlowResultsMutation, UpdateBotFlowResultsMutationVariables>

/**
 * __useUpdateBotFlowResultsMutation__
 *
 * To run a mutation, you first call `useUpdateBotFlowResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBotFlowResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBotFlowResultsMutation, { data, loading, error }] = useUpdateBotFlowResultsMutation({
 *   variables: {
 *      flow_name: // value for 'flow_name'
 *      company_id: // value for 'company_id'
 *      contact_id: // value for 'contact_id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateBotFlowResultsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBotFlowResultsMutation, UpdateBotFlowResultsMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<UpdateBotFlowResultsMutation, UpdateBotFlowResultsMutationVariables>(UpdateBotFlowResultsDocument, options)
}
export type UpdateBotFlowResultsMutationHookResult = ReturnType<typeof useUpdateBotFlowResultsMutation>
export type UpdateBotFlowResultsMutationResult = Apollo.MutationResult<UpdateBotFlowResultsMutation>
export type UpdateBotFlowResultsMutationOptions = Apollo.BaseMutationOptions<UpdateBotFlowResultsMutation, UpdateBotFlowResultsMutationVariables>
