import React from 'react'

import JsonToHtml from './JsonToHtml'

class JsonTable extends React.Component {
  render() {
    const { json, css, indent } = this.props

    if (typeof json !== 'object' || Array.isArray(json)) {
      console.log(json)
      return <div>Invalid JSON object provided</div>
    }

    const htmlTable = JsonToHtml.getTable(json, css, indent)

    return (
      <div dangerouslySetInnerHTML={{
        __html: htmlTable
      }}
      >
      </div>
    )
  }
}

export default JsonTable
