import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type GetBankDetailsAndTransactionsQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']
}>

export type GetBankDetailsAndTransactionsQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', stripe_financial_acc?: any | undefined }>, bank_transactions: Array<{ __typename?: 'bank_transactions', id: any, business_id?: any | undefined, user_id?: any | undefined, stripe_transaction_id?: string | undefined, status?: string | undefined, amount?: number | undefined, type?: string | undefined, metadata?: any | undefined, created_at: any, updated_at: any }> }

export type GetBankingDetailsQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']
}>

export type GetBankingDetailsQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', stripe_financial_acc?: any | undefined }> }

export type GetTransactionsQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetTransactionsQuery = { __typename?: 'query_root', bank_transactions: Array<{ __typename?: 'bank_transactions', id: any, business_id?: any | undefined, user_id?: any | undefined, stripe_transaction_id?: string | undefined, status?: string | undefined, amount?: number | undefined, type?: string | undefined, metadata?: any | undefined, created_at: any, updated_at: any }> }

export type GetBankAccountsQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']
}>

export type GetBankAccountsQuery = { __typename?: 'query_root', bank_accounts: Array<{ __typename?: 'bank_accounts', stripe_bank_id?: string | undefined, type?: string | undefined, metadata?: any | undefined, identifier?: string | undefined }> }

export type GetBankCardsQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']
}>

export type GetBankCardsQuery = { __typename?: 'query_root', bank_cards: Array<{ __typename?: 'bank_cards', user_id: any, updated_by?: any | undefined, updated_at: any, stripe_card_id: string, stripe_card_holder_id: string, id: any, status: string, created_by: any, created_at: any, card_holder: any, card: any, business_id: any }> }

export type GetPersonsBankingQueryVariables = Types.Exact<{
  allPersons: Types.Scalars['Boolean']
}>

export type GetPersonsBankingQuery = { __typename?: 'query_root', getPersonsBanking?: { __typename?: 'getPersonsBankingPayload', persons?: Array<any | undefined> | undefined } | undefined }

export type GetBankingItemsQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']
}>

export type GetBankingItemsQuery = { __typename?: 'query_root', bank_accounts: Array<{ __typename?: 'bank_accounts', status?: string | undefined }>, bank_cards: Array<{ __typename?: 'bank_cards', status: string }> }

export const GetBankDetailsAndTransactionsDocument = gql`
    query getBankDetailsAndTransactions($userId: uuid!) {
  users(where: {id: {_eq: $userId}}) {
    stripe_financial_acc
  }
  bank_transactions(order_by: {created_at: desc}, limit: 20) {
    id
    business_id
    user_id
    stripe_transaction_id
    status
    amount
    type
    metadata
    created_at
    updated_at
  }
}
    `

/**
 * __useGetBankDetailsAndTransactionsQuery__
 *
 * To run a query within a React component, call `useGetBankDetailsAndTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankDetailsAndTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankDetailsAndTransactionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetBankDetailsAndTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetBankDetailsAndTransactionsQuery, GetBankDetailsAndTransactionsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetBankDetailsAndTransactionsQuery, GetBankDetailsAndTransactionsQueryVariables>(GetBankDetailsAndTransactionsDocument, options)
}
export function useGetBankDetailsAndTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBankDetailsAndTransactionsQuery, GetBankDetailsAndTransactionsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetBankDetailsAndTransactionsQuery, GetBankDetailsAndTransactionsQueryVariables>(GetBankDetailsAndTransactionsDocument, options)
}
export type GetBankDetailsAndTransactionsQueryHookResult = ReturnType<typeof useGetBankDetailsAndTransactionsQuery>
export type GetBankDetailsAndTransactionsLazyQueryHookResult = ReturnType<typeof useGetBankDetailsAndTransactionsLazyQuery>
export type GetBankDetailsAndTransactionsQueryResult = Apollo.QueryResult<GetBankDetailsAndTransactionsQuery, GetBankDetailsAndTransactionsQueryVariables>
export const GetBankingDetailsDocument = gql`
    query getBankingDetails($userId: uuid!) {
  users(where: {id: {_eq: $userId}}) {
    stripe_financial_acc
  }
}
    `

/**
 * __useGetBankingDetailsQuery__
 *
 * To run a query within a React component, call `useGetBankingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankingDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetBankingDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetBankingDetailsQuery, GetBankingDetailsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetBankingDetailsQuery, GetBankingDetailsQueryVariables>(GetBankingDetailsDocument, options)
}
export function useGetBankingDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBankingDetailsQuery, GetBankingDetailsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetBankingDetailsQuery, GetBankingDetailsQueryVariables>(GetBankingDetailsDocument, options)
}
export type GetBankingDetailsQueryHookResult = ReturnType<typeof useGetBankingDetailsQuery>
export type GetBankingDetailsLazyQueryHookResult = ReturnType<typeof useGetBankingDetailsLazyQuery>
export type GetBankingDetailsQueryResult = Apollo.QueryResult<GetBankingDetailsQuery, GetBankingDetailsQueryVariables>
export const GetTransactionsDocument = gql`
    query getTransactions {
  bank_transactions(order_by: {created_at: desc}, limit: 20) {
    id
    business_id
    user_id
    stripe_transaction_id
    status
    amount
    type
    metadata
    created_at
    updated_at
  }
}
    `

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options)
}
export function useGetTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options)
}
export type GetTransactionsQueryHookResult = ReturnType<typeof useGetTransactionsQuery>
export type GetTransactionsLazyQueryHookResult = ReturnType<typeof useGetTransactionsLazyQuery>
export type GetTransactionsQueryResult = Apollo.QueryResult<GetTransactionsQuery, GetTransactionsQueryVariables>
export const GetBankAccountsDocument = gql`
    query getBankAccounts($userId: uuid!) {
  bank_accounts(where: {user_id: {_eq: $userId}}) {
    stripe_bank_id
    type
    metadata
    identifier
  }
}
    `

/**
 * __useGetBankAccountsQuery__
 *
 * To run a query within a React component, call `useGetBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccountsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetBankAccountsQuery(baseOptions: Apollo.QueryHookOptions<GetBankAccountsQuery, GetBankAccountsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetBankAccountsQuery, GetBankAccountsQueryVariables>(GetBankAccountsDocument, options)
}
export function useGetBankAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBankAccountsQuery, GetBankAccountsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetBankAccountsQuery, GetBankAccountsQueryVariables>(GetBankAccountsDocument, options)
}
export type GetBankAccountsQueryHookResult = ReturnType<typeof useGetBankAccountsQuery>
export type GetBankAccountsLazyQueryHookResult = ReturnType<typeof useGetBankAccountsLazyQuery>
export type GetBankAccountsQueryResult = Apollo.QueryResult<GetBankAccountsQuery, GetBankAccountsQueryVariables>
export const GetBankCardsDocument = gql`
    query getBankCards($userId: uuid!) {
  bank_cards(where: {user_id: {_eq: $userId}}) {
    user_id
    updated_by
    updated_at
    stripe_card_id
    stripe_card_holder_id
    id
    status
    created_by
    created_at
    card_holder
    card
    business_id
  }
}
    `

/**
 * __useGetBankCardsQuery__
 *
 * To run a query within a React component, call `useGetBankCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankCardsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetBankCardsQuery(baseOptions: Apollo.QueryHookOptions<GetBankCardsQuery, GetBankCardsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetBankCardsQuery, GetBankCardsQueryVariables>(GetBankCardsDocument, options)
}
export function useGetBankCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBankCardsQuery, GetBankCardsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetBankCardsQuery, GetBankCardsQueryVariables>(GetBankCardsDocument, options)
}
export type GetBankCardsQueryHookResult = ReturnType<typeof useGetBankCardsQuery>
export type GetBankCardsLazyQueryHookResult = ReturnType<typeof useGetBankCardsLazyQuery>
export type GetBankCardsQueryResult = Apollo.QueryResult<GetBankCardsQuery, GetBankCardsQueryVariables>
export const GetPersonsBankingDocument = gql`
    query getPersonsBanking($allPersons: Boolean!) {
  getPersonsBanking(input: {allPersons: $allPersons}) {
    persons
  }
}
    `

/**
 * __useGetPersonsBankingQuery__
 *
 * To run a query within a React component, call `useGetPersonsBankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonsBankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonsBankingQuery({
 *   variables: {
 *      allPersons: // value for 'allPersons'
 *   },
 * });
 */
export function useGetPersonsBankingQuery(baseOptions: Apollo.QueryHookOptions<GetPersonsBankingQuery, GetPersonsBankingQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetPersonsBankingQuery, GetPersonsBankingQueryVariables>(GetPersonsBankingDocument, options)
}
export function useGetPersonsBankingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonsBankingQuery, GetPersonsBankingQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetPersonsBankingQuery, GetPersonsBankingQueryVariables>(GetPersonsBankingDocument, options)
}
export type GetPersonsBankingQueryHookResult = ReturnType<typeof useGetPersonsBankingQuery>
export type GetPersonsBankingLazyQueryHookResult = ReturnType<typeof useGetPersonsBankingLazyQuery>
export type GetPersonsBankingQueryResult = Apollo.QueryResult<GetPersonsBankingQuery, GetPersonsBankingQueryVariables>
export const GetBankingItemsDocument = gql`
    query getBankingItems($userId: uuid!) {
  bank_accounts(
    where: {user_id: {_eq: $userId}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    status
  }
  bank_cards(
    where: {user_id: {_eq: $userId}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    status
  }
}
    `

/**
 * __useGetBankingItemsQuery__
 *
 * To run a query within a React component, call `useGetBankingItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankingItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankingItemsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetBankingItemsQuery(baseOptions: Apollo.QueryHookOptions<GetBankingItemsQuery, GetBankingItemsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetBankingItemsQuery, GetBankingItemsQueryVariables>(GetBankingItemsDocument, options)
}
export function useGetBankingItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBankingItemsQuery, GetBankingItemsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetBankingItemsQuery, GetBankingItemsQueryVariables>(GetBankingItemsDocument, options)
}
export type GetBankingItemsQueryHookResult = ReturnType<typeof useGetBankingItemsQuery>
export type GetBankingItemsLazyQueryHookResult = ReturnType<typeof useGetBankingItemsLazyQuery>
export type GetBankingItemsQueryResult = Apollo.QueryResult<GetBankingItemsQuery, GetBankingItemsQueryVariables>
