import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'

export const useIsDesktopSize = () => {
  return useMediaQuery({
    minWidth: 1200
  })
}

export const useForceMobile = () => {
  const location = useLocation()

  const forceMobile = useMemo(() => {
    const mobileRoutes = ['/website-editor/', '/chat']
    const shouldForceMobile = mobileRoutes.some((prefix) => location.pathname.startsWith(prefix))
    if (shouldForceMobile) return true
    return false
  }, [location.pathname])

  return forceMobile
}
