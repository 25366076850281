import { useCallback } from 'react'
import { ApolloCache, useLazyQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { Sequence } from '#app/types'
import { MUTATION, QUERY, QUERY_GET_AUDIENCES } from '#app/utils/graphqlQueries'

import useMutationWithNotification from './useMutationWithNotification'

// TODO Fix this with a global interface
interface City {
  placeId: string
  description: string
}

export function useEntityAudiences() {
  const mainQuery = QUERY.audiences.getAudiences
  const { enqueueSnackbar } = useSnackbar()

  const [
    queryGetAudiences,
    { data: queryGetAudiencesData, ...queryGetAudiencesUtils }
  ] = useLazyQuery<QUERY_GET_AUDIENCES>(mainQuery, {
    pollInterval: 5000
  })

  const parseCities = useCallback<(conditions: any) => string[]>((conditions) => {
    if (!conditions?.cities) return []

    try {
      const cities: City[] = conditions?.cities
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return cities.map((city: City) => city.description)
    }
    catch (error) {
      return ['error']
    }
  }, [])

  const updateCacheApollo = useCallback((mutationName: string, cache: ApolloCache<any>, data: any) => {
    const cacheQuery = cache.readQuery<QUERY_GET_AUDIENCES>({
      query: mainQuery
    })

    if (cacheQuery?.audiences && mutationName) {
      try {
        const audienceData = data[mutationName]?.audience
        const audienceUpdated: Sequence.Audience = {
          ...audienceData,
          __typename: 'audiences'
        }

        let audiencesUpdated: Sequence.Audience[]

        if (mutationName === 'createAudience') {
          audiencesUpdated = [...(cacheQuery?.audiences ?? []), audienceUpdated]
        }
        else {
          audiencesUpdated = cacheQuery?.audiences.map((audienceTmp) => {
            if (audienceTmp?.id === audienceUpdated.id) {
              return audienceUpdated
            }
            return audienceTmp
          })
        }

        cache.writeQuery({
          query: mainQuery,
          data: {
            audiences: audiencesUpdated
          }
        })
      }
      catch (err) {
        enqueueSnackbar('We have an error updating the screen', {
          variant: 'error'
        })
      }
    }
  }, [enqueueSnackbar, mainQuery])

  const [mutationCreateAudience, mutationCreateAudienceUtils] = useMutationWithNotification(MUTATION.audiences.createAudience, {
    update(cache, { data }) {
      updateCacheApollo('createAudience', cache, data)
    }
  })

  return {
    parseCities,
    // GraphQL Query
    queryGetAudiences,
    queryGetAudiencesData,
    queryGetAudiencesUtils,
    // GraphQL Mutations
    mutationCreateAudience,
    mutationCreateAudienceUtils
  }
}
