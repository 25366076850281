import { forwardRef } from 'react'
import { TextField } from '@mui/material'

const PhoneTextField = (prop, ref) => {
  return (
    <TextField
      fullWidth
      {...prop}
      inputRef={ref}
    />
  )
}

export const PhoneTextFieldStyled = forwardRef((prop, ref) => {
  return (
    <TextField
      fullWidth
      style={{
        backgroundColor: '#F1F5F9',
        border: '1px solid #F1F5F9'
      }}
      {...prop}
      inputRef={ref}
    />
  )
})

export default forwardRef(PhoneTextField)
