import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

// @ts-ignore
export const Authentication = ({ children }) => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0()
  const currentPath = window.location.pathname + window.location.search
  const deepLinking = localStorage.getItem('deeplinking') as string
  const navigate = useNavigate()

  if (!isAuthenticated && !isLoading) {
    const url = new URL(window.location.href)
    const email = url.searchParams.get('email')
    const connectionType = url.searchParams.get('connectionType')
    const phoneSignin = url.searchParams.get('phoneSignin')
    const signUp = Boolean(url.searchParams.get('signup'))

    let redirectOpts = {
      screen_hint: signUp ? 'signup' : 'login',
      connectionType: connectionType ?? (process.env.REACT_APP_ENV === 'development'
        ? 'password'
        : 'passwordless')
    }

    if (phoneSignin && phoneSignin) {
      redirectOpts.connectionType = 'passwordless'
    }

    if (email) {
      redirectOpts = {
        ...redirectOpts,
        // @ts-ignore
        login_hint: email
      }
    }

    if (currentPath && currentPath !== '/') {
      localStorage.setItem('deeplinking', currentPath)
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loginWithRedirect(redirectOpts)

    return <></>
  }
  else if (deepLinking && deepLinking !== '/' && isAuthenticated && !isLoading) {
    localStorage.removeItem('deeplinking')
    navigate(deepLinking)
  }

  if (isLoading) {
    return <></>
  }

  return (
    <>
      {children}
    </>
  )
}
