/* eslint-disable @stylistic/jsx-closing-tag-location */
import { useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { json } from '@codemirror/lang-json'
import { Box, Link, Stack, Typography } from '@mui/material'
import { vscodeDark } from '@uiw/codemirror-theme-vscode'
import CodeMirror from '@uiw/react-codemirror'

import { Alert } from '#app/components/Alerts'
import { Chip } from '#app/components/Chip'
import { DividerExtended } from '#app/components/Divider'
import { LoadingGrid } from '#app/components/Loading/LoadingGrid'
import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import { Table } from '#app/components/Table'
import { TableStructureV2, TableStructureV2Row } from '#app/components/Table/TableV2'
import { JsonTable } from '#app/feature/JsonToHtml'
import { useDayJs } from '#app/hooks/useDayJs'
import { WebsiteDeploymentsByUserDocument, WebsiteDeploymentsByUserQuery } from '#app/operations/website-editor/websiteeditor.queries.generated'
import { ENVIROMENT } from '#app/store/enviroment'

interface Props {
  boId: string
}

export function WebsiteDeployments({ boId }: Props) {
  const { formatShortDate } = useDayJs()

  const { data, loading } = useQuery<WebsiteDeploymentsByUserQuery>(WebsiteDeploymentsByUserDocument, {
    variables: {
      user_id: boId
    },
    pollInterval: 1000,
    fetchPolicy: 'network-only'
  })

  const [previewMetadata, setPreviewMetadata] = useState(undefined)

  const tableStructure: TableStructureV2 = useMemo(() => {
    const structure = {
      rows: []
    }

    data?.website_deployments
      .sort((a, b) => {
        const dateA = new Date(a.created_at)
        const dateB = new Date(b.created_at)
        return dateB.getTime() - dateA.getTime()
      })
      .map((deployment) => {
        const row: TableStructureV2Row = {
          key: '1',
          columns: [
            {
              align: 'left',
              component: <>
                <Link
                  href={`/public/b/deployment/${deployment?.id}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {deployment?.id}
                </Link>
              </>
            },
            {
              align: 'left',
              component: <>
                {deployment?.status === 'success'
                && (
                  <Stack
                    direction='column'
                    spacing={1}
                  >
                    <Chip.SequenceStatus status='completed' />
                    <Chip.Basic
                      label='View Preview'
                      color='primary'
                      onClick={() => window.open(`/public/b/deployment/${deployment?.id}`, '_blank')}
                    />
                    <Chip.Clipboard
                      label='Copy URL Preview'
                      color='secondary'
                      clipboard={`${ENVIROMENT.front_url}/public/b/deployment/${deployment?.id}`}
                      onClick={() => {}}
                    />
                  </Stack>
                )}
                {
                  deployment?.status === 'queued'
                  && <Chip.SequenceStatus status='in_queue' />
                }
                {
                  (deployment?.status !== 'success' && deployment?.status !== 'queued')
                  && (
                    <>
                      <Chip.SequenceStatus status={deployment?.status as string} />
                    </>
                  )
                }
              </>
            },
            {
              align: 'left',
              component: <>
                {`${deployment.userByCreatedBy?.first_name} ${deployment.userByCreatedBy?.last_name} (${deployment.userByCreatedBy?.email})`}
              </>
            },
            {
              align: 'left',
              component: <>
                <Box sx={{
                  zoom: '0.75'
                }}
                >
                  {`${formatShortDate(deployment.created_at)}`}
                </Box>
              </>
            },
            {
              align: 'left',
              component: <>
                <NavLink to={`/website-editor/${deployment?.user_id}`}>
                  <Chip.Basic
                    label={deployment?.user_id}
                    onClick={() => {}}
                  />
                </NavLink>
              </>
            },
            {
              align: 'left',
              component: <>
                <NavLink to={`/website-editor/${deployment?.user_id}/${deployment?.section_version}`}>
                  <Chip.Basic
                    label={deployment?.section_version}
                    onClick={() => {}}
                  />
                </NavLink>
              </>
            },
            {
              align: 'left',
              component: <>
                { !deployment?.metadata?.skipHosting
                && (
                  <Stack
                    direction='column'
                    spacing={1}
                  >
                    <Link
                      target='_blank'
                      href={`https://vercel.com/${process.env.REACT_APP_ENV === 'staging' ? 'owners-platform-staging' : 'owners-platform-production'}/${deployment?.deployment_project}`}
                      rel='noreferrer'
                    >
                      <Chip.Basic
                        color='primary'
                        label={deployment?.deployment_provider}
                        onClick={() => {}}
                      />
                    </Link>
                    <Link
                      target='_blank'
                      href={`https://vercel.com/${process.env.REACT_APP_ENV === 'staging' ? 'owners-platform-staging' : 'owners-platform-production'}/${deployment?.deployment_project}/settings/domains`}
                      rel='noreferrer'
                    >
                      <Chip.Basic
                        color='primary'
                        label='settings'
                        onClick={() => {}}
                      />
                    </Link>
                  </Stack>
                )}
              </>
            },
            {
              align: 'left',
              component: <>
                <Link
                  target='_blank'
                  href={`https://${deployment?.deployment_url}`}
                  rel='noreferrer'
                >
                  {deployment?.deployment_url}
                </Link>
              </>
            },
            {
              align: 'left',
              component: <>
                <Link
                  target='_blank'
                  href={`${deployment?.bucket_url}`}
                  rel='noreferrer'
                >
                  {deployment?.bucket_url}
                </Link>
              </>
            },
            {
              align: 'left',
              component: <>
                { !deployment?.metadata?.skipHosting
                && (
                  <Link
                    target='_blank'
                    href={`${deployment?.domain}`}
                    rel='noreferrer'
                  >
                    {deployment?.domain}
                  </Link>
                )}
              </>
            },
            {
              align: 'left',
              component: <>
                <Chip.Basic
                  label='View Metadata'
                  onClick={() => { setPreviewMetadata(deployment?.metadata) }}
                />
              </>
            }
          ]
        }

        // @ts-expect-error ---
        structure.rows.push(row)
      })

    return structure
  }, [data?.website_deployments, formatShortDate])

  return (
    <Box
      mx={4}
      my={6}
    >
      <RightSideBar
        size='lg'
        isOpen={!!previewMetadata}
        closeHandler={() => setPreviewMetadata(undefined)}
      >
        <>
          <Typography
            variant='h4'
            mb={4}
          >
            Deployment Metadata
          </Typography>
          <CodeMirror
            readOnly
            theme={vscodeDark}
            extensions={[json()]}
            value={JSON.stringify(previewMetadata, null, 2)}
            height='500px'
          />
          <DividerExtended />
          <JsonTable json={previewMetadata} />
        </>
      </RightSideBar>
      <Typography
        gutterBottom
        variant='h6'
      >
        Deployments - All sites by user
      </Typography>
      {
        loading
        && <LoadingGrid />
      }
      <Alert.Basic
        severity='info'
        title='What does the Domain column mean?'
        description='The value displayed in the domain column refers to the domain that the BO had configured on the date the deployment was carried out, but this does not ensure its correct functioning until the DNS is configured in the correct provider.'
      />

      {
        !loading

        && (
          <Table.V2
            sx={{
              maxWidth: '75vw',
              overflowX: 'auto'
            }}
            headers={[
              {
                text: 'ID',
                align: 'left'
              },
              {
                text: 'Status',
                align: 'left'
              },
              {
                text: 'Created By',
                align: 'left'
              },
              {
                text: 'Created At',
                align: 'left'
              },
              {
                text: 'User (BO)',
                align: 'left'
              },
              {
                text: 'Version',
                align: 'left'
              },
              {
                text: 'Provider',
                align: 'left'
              },
              {
                text: 'Deployment URL',
                align: 'left'
              },
              {
                text: 'Bucket',
                align: 'left'
              },
              {
                text: 'Domain ',
                align: 'left'
              },
              {
                text: 'Metadata ',
                align: 'left'
              }
            ]}
            mobileColumns={[1, 2, 3, 4, 5]}
            structure={tableStructure}
          />
        )
      }
    </Box>
  )
}
