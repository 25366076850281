import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type GetOnboardingWelcomeFlowQueryVariables = Types.Exact<{
  company_id: Types.Scalars['String']
  contact_id: Types.Scalars['String']
}>

export type GetOnboardingWelcomeFlowQuery = { __typename?: 'query_root', getOnboardingWelcomeFlow?: { __typename?: 'GetOnboardingWelcomeFlowPayload', is_form_completed: boolean } | undefined }

export const GetOnboardingWelcomeFlowDocument = gql`
    query getOnboardingWelcomeFlow($company_id: String!, $contact_id: String!) {
  getOnboardingWelcomeFlow(
    input: {company_id: $company_id, contact_id: $contact_id}
  ) {
    is_form_completed
  }
}
    `

/**
 * __useGetOnboardingWelcomeFlowQuery__
 *
 * To run a query within a React component, call `useGetOnboardingWelcomeFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingWelcomeFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingWelcomeFlowQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      contact_id: // value for 'contact_id'
 *   },
 * });
 */
export function useGetOnboardingWelcomeFlowQuery(baseOptions: Apollo.QueryHookOptions<GetOnboardingWelcomeFlowQuery, GetOnboardingWelcomeFlowQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetOnboardingWelcomeFlowQuery, GetOnboardingWelcomeFlowQueryVariables>(GetOnboardingWelcomeFlowDocument, options)
}
export function useGetOnboardingWelcomeFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOnboardingWelcomeFlowQuery, GetOnboardingWelcomeFlowQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetOnboardingWelcomeFlowQuery, GetOnboardingWelcomeFlowQueryVariables>(GetOnboardingWelcomeFlowDocument, options)
}
export type GetOnboardingWelcomeFlowQueryHookResult = ReturnType<typeof useGetOnboardingWelcomeFlowQuery>
export type GetOnboardingWelcomeFlowLazyQueryHookResult = ReturnType<typeof useGetOnboardingWelcomeFlowLazyQuery>
export type GetOnboardingWelcomeFlowQueryResult = Apollo.QueryResult<GetOnboardingWelcomeFlowQuery, GetOnboardingWelcomeFlowQueryVariables>
