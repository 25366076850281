import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { formatDistanceToNow } from 'date-fns'
import { StringMap } from 'i18next'
import { Channel, MessageInput, MessageList } from 'stream-chat-react'
import { EmojiPicker } from 'stream-chat-react/emojis'

import { BackAction } from '#app/v2/components/BackAction'
import { ProfileAvatarDetailed } from '#app/v2/components/ProfileAvatarDetailed'
import { useAppContextV2 } from '#app/v2-core/layouts/useAppContextV2'

import { CustomMessageInput } from './components/CustomMessageInput'
import useChannel from './hooks/useChannel'

function NoAvatar() {
  return <></>
}

function Input() {
  return <CustomMessageInput validateParticipants={false} />
}

function ChannelMessages() {
  const channelId = useParams().channelId ?? ''

  const { channelMember, channel } = useChannel(channelId)
  const { V2_SET_LOADING } = useAppContextV2()

  const user = channel.data?.name
    ? {
      name: channel.data.name,
      image: channel.data.image,
      online: true,
      last_active: null
    }
    : channelMember?.user

  useEffect(() => {
    V2_SET_LOADING(false)
  }, [V2_SET_LOADING])

  const { t } = useTranslation()

  const timeAgo = user?.last_active
    ? formatDistanceToNow(new Date(user.last_active), {
      addSuffix: true
    })
    : null

  const [count, unit] = timeAgo?.split(' ') ?? ['', '']

  const interpolation: StringMap = {
    count,
    unit
  }
  const lastSeenTranslation = t('chat.lastSeen', interpolation)
  const onlineTranslation = t('chat.online', 'Online')

  return (
    <Channel
      channel={channel}
      Avatar={NoAvatar}
      Input={Input}
      EmojiPicker={EmojiPicker}
    >
      <div className='grid max-h-20 grid-cols-4 bg-white p-4 text-[#304985]'>
        <BackAction
          backUrl='/v2/messages'
          className='max-w-[70px] self-center'
        />
        <div className='col-span-2 place-self-center text-center'>
          <div className='flex items-center justify-center space-x-2'>
            <ProfileAvatarDetailed
              fullName={user?.name}
              profilePictureUrl={user?.image}
              indicator={user?.online}
            >
              <div className='flex flex-col'>
                <p className='text-center text-sm font-semibold'>{user?.name}</p>
                {timeAgo && (
                  <span className='text-xs text-gray-600'>
                    {' '}
                    { user?.online ? onlineTranslation : lastSeenTranslation}
                  </span>
                )}
              </div>

            </ProfileAvatarDetailed>

          </div>
        </div>
      </div>
      <MessageList />
      <MessageInput />
    </Channel>
  )
}

export default ChannelMessages
