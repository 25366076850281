import { useTranslation } from 'react-i18next'
import { ChipProps } from '@mui/material'
import { capitalize } from 'lodash'

import { Chip } from '#app/components/Chip'
import { InvoiceState } from '#app/store/invoice'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

export const StatusChip = ({ data }: { data?: InvoiceState['editInvoice'] | InvoiceState['createInvoice'] }) => {
  const { t } = useTranslation()
  const isDesktop = useIsDesktopSize()

  const goodStatuses = [
    'paid'
  ]

  const inPorgress = [
    'created',
    'pending'
  ]

  let color: ChipProps['color']

  if (goodStatuses.includes(data?.status ?? '')) {
    color = 'success'
  }
  else if (inPorgress.includes(data?.status ?? '')) {
    color = 'warning'
  }
  else {
    color = 'error'
  }

  const translationsByStatus = {
    created: t('banking.invoices.invoicesTable.rows.status.awaitingPayment', 'awaiting payment'),
    paid: t('banking.invoices.invoicesTable.rows.status.paid', 'paid'),
    pending: t('banking.invoices.invoicesTable.rows.status.pending', 'pending'),
    failed: t('banking.invoices.invoicesTable.rows.status.failed', 'failed')
  }

  // @ts-ignore
  const label = data.status ? capitalize(String(translationsByStatus[data.status])) : ''

  return (
    <Chip.Basic
      size={!isDesktop ? 'small' : 'medium'}
      label={label}
      color={color}
      onClick={data?.status === 'paid' || data?.status === 'failed' ? () => { console.warn() } : undefined}
    />
  )
}
