import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Grid, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'

import Button from '#app/components/Buttons/Button'
import { CurrencyInput } from '#app/components/CurrencyInput/CurrencyInput'
import FormInput from '#app/components/FormInput/FormInput'
import FormSelect from '#app/components/FormSelect/FormSelect'
import InputWithChips from '#app/components/InputWithChips/InputWithChips'
import InputWithPlaces from '#app/components/InputWithPlaces/InputWithPlaces'
import PhoneNumberInput from '#app/components/PhoneNumberInput/PhoneNumberInput'
import RadioButtonGroup from '#app/components/RadioButtonGroup/RadioButtonGroup'
import ProfileContext from '#app/contexts/ProfileContext'
import { useStates } from '#app/hooks/useStates'
import { useCreateBankTransferMutation } from '#app/operations/banking/banking.mutations.generated'
import { GetBankingDetailsQuery, useGetBankAccountsQuery } from '#app/operations/banking/banking.queries.generated'
import { BankTransferState, useBankTransferStore } from '#app/store/bank-transfer'

import { CurrentBankBalance } from '../CurrentBankBalance'

import { BankTransferConfirmation } from './BankTransferConfirmation'

interface Props {
  bankTransferType?: 'outbound-transfers' | 'outbound-payments'
  data?: GetBankingDetailsQuery
  onSubmitCallback: () => void
}

export const BankTransfer = ({ data, onSubmitCallback, bankTransferType }: Props) => {
  const profileData = useContext(ProfileContext)
  const { t } = useTranslation()

  const { statesData, isLoading } = useStates()
  const transferDetails = useBankTransferStore((state) => state)
  const setTransferDetails = useBankTransferStore((state) => state.setBankTransferDetails)
  const resetTransferDetails = useBankTransferStore((state) => state.reset)
  const [jsonData, setJsonData] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const [bankTransfer] = useCreateBankTransferMutation()

  const { data: banksData, loading: loadingBanks, refetch } = useGetBankAccountsQuery({
    variables: {
      userId: profileData?.getProfile?.user?.id ?? ''
    }
  })

  const defaultBankAcc = {
    value: 'new',
    text: t('bankTransfer.addBankAccount', 'Add bank account')
  }

  const [bankSelect, setBankSelect] = useState<Array<{ value: string, text: string }>>([defaultBankAcc])

  const [isConfirmation, setConfirmation] = useState<boolean>(false)

  useEffect(() => {
    if (banksData?.bank_accounts?.length) {
      const newAccs = banksData
        .bank_accounts
        .filter((acc) => {
          if (bankTransferType === 'outbound-transfers') {
            return !acc?.type || acc.type === bankTransferType
          }

          return acc?.type === bankTransferType
        })
        .map((acc) => {
          return {
            text: acc.identifier ?? '',
            value: acc.stripe_bank_id ?? ''
          }
        })

      setBankSelect([defaultBankAcc, ...newAccs])
    }
  }, [banksData])

  const onSubmit = (formValues: BankTransferState) => {
    setConfirmation(true)
    setTransferDetails({
      ...formValues,
      transferType: bankTransferType
    })
  }

  useEffect(() => {
    if (statesData && jsonData.length < 1) {
      setJsonData(statesData)
    }
  }, [statesData])

  const onCancel = () => {
    resetTransferDetails()
    onSubmitCallback()
  }

  const onConfirmationSubmit = async () => {
    const amount = transferDetails.amountSetting === 'custom'
      && transferDetails.amount
      ? transferDetails.amount * 100
      : data?.users[0]?.stripe_financial_acc?.balance?.cash?.usd

    await bankTransfer({
      variables: {
        bank: transferDetails.bank ?? '',
        accountHolderName: transferDetails.accountHolderName,
        accountType: transferDetails.accountType,
        routingNumber: transferDetails.routingNumber,
        accountNumber: transferDetails.accountNumber,
        amount,

        email: transferDetails?.email,
        phone: transferDetails?.phone,
        addressCity: transferDetails.addressCity?.description,
        addressStreet: transferDetails.addressStreet?.description,
        addressState: transferDetails.addressState?.state_code,
        addressPostalCode: transferDetails.addressPostalCode,

        transferType: bankTransferType
      }
    })

    enqueueSnackbar('Transfer started', {
      variant: 'success'
    })

    if (transferDetails.bank === 'new') {
      await refetch()
    }

    resetTransferDetails()
    onSubmitCallback()
  }

  const initialValues = {
    bank: '',
    accountHolderName: `${profileData?.getProfile?.user?.first_name ?? ''} ${profileData?.getProfile?.user?.last_name ?? ''}`.trim() ?? '',
    accountType: '',
    routingNumber: '',
    accountNumber: '',
    amount: undefined,
    amountSetting: 'custom' as BankTransferState['amountSetting'],
    email: '',
    phone: '',
    addressCity: null,
    addressStreet: null,
    addressState: null,
    addressPostalCode: '',
    ...transferDetails
  }

  const outboundPaymentsSchema = {
    email: Yup
      .string()
      .when('bank', {
        is: (bank: string) => {
          return bank === 'new'
        },
        then: (schema) => schema
          .email()
          .required(t('validation.required')),
        otherwise: (schema) => schema
      }),
    phone: Yup
      .string()
      .when('bank', {
        is: (bank: string) => {
          return bank === 'new'
        },
        then: (schema) => schema
          .required(t('validation.required'))
          .test('isValidPhoneNumber', t('validation.invalidPhone'),
            // @ts-ignore
            (value) => {
              if (value) {
                return isValidPhoneNumber(value)
              }
            }),
        otherwise: (schema) => schema
      }),
    addressStreet: Yup
      .object({
        description: Yup.string(),
        matched_substrings: Yup.array(),
        place_id: Yup.string(),
        reference: Yup.string(),
        structured_formatting: Yup
          .object({
            main_text: Yup.string(),
            main_text_matched_substrings: Yup.array(),
            secondary_text: Yup.string()
          })
          .nullable(),
        terms: Yup.array(),
        types: Yup.array()
      })
      .nullable()
      .when('bank', {
        is: (bank: string) => {
          return bank === 'new'
        },
        then: (schema) => schema
          .required(t('validation.required')),
        otherwise: (schema) => schema
      }),
    addressCity: Yup
      .object({
        description: Yup.string(),
        matched_substrings: Yup.array(),
        place_id: Yup.string(),
        reference: Yup.string(),
        structured_formatting: Yup.object({
          main_text: Yup.string(),
          main_text_matched_substrings: Yup.array(),
          secondary_text: Yup.string()
        }).nullable(),
        terms: Yup.array(),
        types: Yup.array()
      })
      .nullable()
      .when('bank', {
        is: (bank: string) => {
          return bank === 'new'
        },
        then: (schema) => schema.required(t('validation.required')),
        otherwise: (schema) => schema
      }),
    addressPostalCode: Yup
      .string()
      .when('bank', {
        is: (bank: string) => {
          return bank === 'new'
        },
        then: (schema) => schema.required(t('validation.required')),
        otherwise: (schema) => schema
      }),
    addressState: Yup
      .object({
        name: Yup.string(),
        state_code: Yup.string()
      })
      .nullable()
      .when('bank', {
        is: (bank: string) => {
          return bank === 'new'
        },
        then: (schema) => schema.required(t('validation.required')),
        otherwise: (schema) => schema
      })
  }

  let schema = {
    accountHolderName: Yup
      .string()
      .when('bank', {
        is: (bank: string) => {
          return bank === 'new'
        },
        then: (schema) => schema.required(t('validation.required')),
        otherwise: (schema) => schema
      }),
    accountType: Yup
      .string()
      .when('bank', {
        is: (bank: string) => {
          return bank === 'new'
        },
        then: (schema) => schema
          .required(t('validation.required'))
          .oneOf(['individual', 'company']),
        otherwise: (schema) => schema
      }),
    routingNumber: Yup
      .string()
      .when('bank', {
        is: (bank: string) => {
          return bank === 'new'
        },
        then: (schema) => schema
          .required(t('validation.required')),
        otherwise: (schema) => schema
      }),
    accountNumber: Yup
      .string()
      .when('bank', {
        is: (bank: string) => {
          return bank === 'new'
        },
        then: (schema) => schema
          .required(t('validation.required')),
        otherwise: (schema) => schema
      }),
    bank: Yup
      .string()
      .required(t('validation.required')),
    amount: Yup
      .number()
      .when('amountSetting', {
        is: (setting: string) => {
          return setting === 'custom'
        },
        then: (schema) => schema
          .positive(t('validation.positive'))
          .min(1, t('validation.minimum', {
            value: 1
          }))
          .max(data?.users[0]?.stripe_financial_acc?.balance?.cash?.usd / 100 ?? 0, t('validation.maximum', {
            value: data?.users[0]?.stripe_financial_acc?.balance?.cash?.usd / 100 ?? 0
          }))
          .required(t('validation.required')),
        otherwise: (schema) => schema
      }),
    amountSetting: Yup
      .string()
      .required(t('validation.required'))
  }

  if (bankTransferType === 'outbound-payments') {
    schema = {
      ...schema,
      ...outboundPaymentsSchema
    }
  }

  const validationSchema = Yup
    .object()
    .shape(schema)

  // Confirmation screen
  if (isConfirmation) {
    return (
      <BankTransferConfirmation
        bankingDetails={data}
        banks={banksData}
        transferDetails={transferDetails}
        onSubmit={onConfirmationSubmit}
        onCancel={() => setConfirmation(false)}
      />
    )
  }

  const title = bankTransferType === 'outbound-payments' ? t('balanceDetails.bankTransfer') : t('balanceDetails.withdrawal')
  const description = bankTransferType === 'outbound-payments' ? t('bankTransfer.toThirdParties', 'Transfer money to a third party account') : t('bankTransfer.toWithdrawalAcc', 'Get access to your funds in 3-5 business days with no fees, the bank account must belong to you.')

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const states = jsonData
    .map((e: { name: string, state_code: string }) => {
      return {
        name: e.name,
        state_code: e.state_code
      }
    })

  const statesOptionHandler = (state: { name: string, state_code: string }) => {
    return state.name
  }

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid item>
        <Stack spacing={2}>
          <Typography variant='h6'>{title}</Typography>
          <Typography>
            { description }
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Box sx={{
          borderRadius: 1,
          padding: 2,
          backgroundColor: 'var(--main-content-bg-color)'
        }}
        >
          <CurrentBankBalance amount={data?.users[0]?.stripe_financial_acc?.balance?.cash?.usd / 100 ?? 0} />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {
            ({ values }) => {
              return (
                <Form>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      {/* @ts-ignore */}
                      <RadioButtonGroup
                        name='amountSetting'
                        initialValue={values.amountSetting}
                        label='Amount to transfer'
                      >
                        {/* @ts-ignore */}
                        <RadioButtonGroup.RadioButton value='total'>{ t('bankTransfer.totalBalance', 'Total balance') }</RadioButtonGroup.RadioButton>
                        {/* @ts-ignore */}
                        <RadioButtonGroup.RadioButton value='custom'>{ t('bankTransfer.custom', 'Custom') }</RadioButtonGroup.RadioButton>
                      </RadioButtonGroup>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <FormSelect
                        label={t('bankTransfer.bank', 'Banco')}
                        disabled={loadingBanks}
                        name='bank'
                        options={bankSelect}
                      />
                      {
                        values.bank === 'new'
                        && (
                          <Grid
                            container
                            spacing={2}
                          >
                            <Grid
                              item
                              xs={12}
                            >
                              <FormInput
                                sx={{
                                  backgroundColor: 'var(--main-content-bg-color)'
                                }}
                                name='accountHolderName'
                                type='text'
                                placeholder={t('bankTransfer.newBank.accountHolder', 'Account holder name')}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                            >
                              <FormSelect
                                sx={{
                                  backgroundColor: 'var(--main-content-bg-color)'
                                }}
                                name='accountType'
                                options={[{
                                  value: 'individual',
                                  text: t('bankTransfer.newBank.individual', 'Individual')
                                }, {
                                  value: 'company',
                                  text: t('bankTransfer.newBank.company', 'Company')
                                }]}
                                placeholder={t('bankTransfer.newBank.typePlaceholder', 'What type of bank account is this?')}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                            >
                              <FormInput
                                sx={{
                                  backgroundColor: 'var(--main-content-bg-color)'
                                }}
                                name='routingNumber'
                                type='text'
                                placeholder={t('bankTransfer.newBank.routingNumber', 'Routing number')}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                            >
                              <FormInput
                                sx={{
                                  backgroundColor: 'var(--main-content-bg-color)'
                                }}
                                name='accountNumber'
                                type='text'
                                placeholder={t('bankTransfer.newBank.accountNumber', 'Account number')}
                              />
                            </Grid>
                            {
                              bankTransferType === 'outbound-payments' && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                  >
                                    <FormInput
                                      sx={{
                                        backgroundColor: 'var(--main-content-bg-color)'
                                      }}
                                      name='email'
                                      type='text'
                                      placeholder={t('bankTransfer.newBank.email', 'Email')}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                  >
                                    <PhoneNumberInput
                                      styled
                                      label=''
                                      name='phone'
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                  >
                                    <InputWithPlaces
                                      label={undefined}
                                      placeholder={t('bankTransfer.newBank.address', 'Address')}
                                      sx={{
                                        backgroundColor: 'var(--main-content-bg-color)'
                                      }}
                                      name='addressStreet'
                                      types={undefined}
                                      fields={undefined}
                                      countries={undefined}
                                      multiple={undefined}
                                      limitTags={undefined}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                  >
                                    <InputWithPlaces
                                      label={undefined}
                                      sx={{
                                        backgroundColor: 'var(--main-content-bg-color)'
                                      }}
                                      placeholder={t('bankTransfer.newBank.city', 'City')}
                                      name='addressCity'
                                      types={['locality']}
                                      fields={undefined}
                                      countries={undefined}
                                      multiple={undefined}
                                      limitTags={undefined}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                  >
                                    <InputWithChips
                                      sx={{
                                        backgroundColor: 'var(--main-content-bg-color)'
                                      }}
                                      placeholder={t('bankTransfer.newBank.state', 'State')}
                                      name='addressState'
                                      options={states}
                                      disabled={isLoading ?? states.length === 0}
                                      label={undefined}
                                      optionLabelHandler={statesOptionHandler}
                                      multiple={undefined}
                                      limit={undefined}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                  >
                                    <FormInput
                                      sx={{
                                        backgroundColor: 'var(--main-content-bg-color)'
                                      }}
                                      name='addressPostalCode'
                                      type='text'
                                      placeholder={t('bankTransfer.newBank.zipCode', 'Zip code')}
                                    />
                                  </Grid>
                                </>
                              )
                            }
                          </Grid>
                        )
                      }
                    </Grid>
                    {
                      values.amountSetting === 'custom'
                      && (
                        <Grid
                          item
                          xs={12}
                        >
                          <CurrencyInput
                            label={t('bankTransfer.amount', 'Amount')}
                            name='amount'
                          />
                        </Grid>
                      )
                    }
                    <Grid
                      item
                      xs={12}
                    >
                      <Stack
                        direction='row'
                        spacing={1}
                      >
                        <Button type='submit'>
                          { t('bankTransfer.button.transfer', 'Transfer') }
                        </Button>
                        <Button
                          primary={false}
                          onClick={onCancel}
                        >
                          { t('bankTransfer.button.cancel', 'Cancel') }
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Form>
              )
            }
          }
        </Formik>
      </Grid>
    </Grid>
  )
}
