import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsDashSquareFill } from 'react-icons/bs'
import { gql, useApolloClient } from '@apollo/client'
import { Box, Grid, Link as LinkMUI, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import { useImmer } from 'use-immer'

import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import { DividerExtended } from '#app/components/Divider'
import { LoadingCard } from '#app/components/Loading/LoadingCard'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { THEME } from '#app/layouts/theme'
import { AltBoUserProfileQuery } from '#app/operations/altbo/altbo.queries.generated'
import { useGetBankingItemsLazyQuery } from '#app/operations/banking/banking.queries.generated'
import { GetProxyResolverSequinDocument, GetProxyResolverSequinQuery } from '#app/operations/proxyresolver/proxyresolver.queries.generated'
import { useUnlinkPhoneMutation } from '#app/operations/users/users.mutations.generated'
import { ENVIROMENT } from '#app/store/enviroment'
import { JSONB } from '#app/types/generic'
import { useIsDesktopSize } from '#app/utils/mediaQueries'
import { isValidUrl } from '#app/utils/vanilla/helpers'

import { WithoutProfile } from './WithoutProfile'

const SEQUIN_PROXY_BASE_URL = 'https://proxy.sequin.io'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(3),
  color: theme.palette.text.secondary,
  ...theme.typography.body2
}))

const ItemImg = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  color: theme.palette.text.secondary,
  ...theme.typography.body2
}))

const IframePDF = styled('iframe')`
  min-width: 250px;
  width: 80%;
  max-width: 500px;
  height: 100%;
`

export interface AltBoProfileProps {
  profile: JSONB
  currentIsSalesDemoAccount: boolean
  getSalesDemoEmail: (email: string) => string
  getServiceSalesDemoEmail: (email: string) => string
  demoAltBoEmail: string
  userEmail: string | null
  ownerId?: string | null
}

export function AltBoProfile({
  profile,
  currentIsSalesDemoAccount,
  getSalesDemoEmail,
  getServiceSalesDemoEmail,
  demoAltBoEmail,
  userEmail,
  ownerId
}: AltBoProfileProps) {
  const { t } = useTranslation()
  const { isAdmin } = useEntityUser()

  const { altBoProfile, user: userProfile } = profile

  const [dataUserProfile] = useState<AltBoUserProfileQuery>({
    altbo_app_user_profile_view: [altBoProfile || {}]
  })

  const [fetchData, { data: bankingItems }] = useGetBankingItemsLazyQuery({
    variables: {
      userId: ownerId
    }
  })

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (ownerId) {
      void fetchData()
    }
  }, [ownerId, fetchData])

  const activeCard = bankingItems?.bank_cards?.[0]
  const bankingStatus = userProfile?.stripe_banking_status

  const client = useApolloClient()
  const [sequinFiles, setSequinFiles] = useImmer<{ files: { [key: string]: GetProxyResolverSequinQuery['getProxyResolverSequin'] } }>({
    files: {}
  })

  const [unlinkPhone, { loading: unlinkingPhone }] = useUnlinkPhoneMutation()

  const activeIcon = useRef<Record<number, boolean>>({})

  const handleDelete = useCallback((phone: string, idx: number) => {
    activeIcon.current[idx] = true
    void unlinkPhone({
      variables: {
        id: userProfile?.id,
        phone
      },
      update(cache) {
        const cacheId = `User:${userProfile?.id}`
        const data: { linked_phones: [] } | null = cache.readFragment({
          id: cacheId,
          fragment: gql`
          fragment _ on User {
            linked_phones
          }
        `,
        })
        const newLinkedPhones = data?.linked_phones.filter((p: any) => p !== phone)
        cache.writeFragment({
          id: cacheId,
          fragment: gql`
          fragment _ on User {
            linked_phones
          }
        `,
          data: {
            linked_phones: newLinkedPhones,
          },
        })
      },
    })
      .then(() => enqueueSnackbar('Phone deleted successfully', {
        variant: 'success'
      })).finally(() => {
        activeIcon.current[idx] = false
      })
  }, [userProfile?.id, unlinkPhone, enqueueSnackbar])

  const initView = useCallback(() => {
    let email = userEmail ?? ''
    if (currentIsSalesDemoAccount) {
      const service = getServiceSalesDemoEmail(email) // chris+painting_demo_bo@joinowners.com -> "painting"
      email = service ? getSalesDemoEmail(email) : demoAltBoEmail
    }
    // await getUserProfile({ variables: { email }, pollInterval: 10000 })
  }, [userEmail, currentIsSalesDemoAccount, getSalesDemoEmail, getServiceSalesDemoEmail, demoAltBoEmail])

  const fetchAttachments = useCallback(async () => {
    if (dataUserProfile?.altbo_app_user_profile_view[0]) {
      const boData = dataUserProfile?.altbo_app_user_profile_view[0]
      await Promise.all(Object.keys(boData).map(async (key) => {
        // @ts-ignore
        const valField = boData[key] as string
        if (valField && typeof valField === 'string' && valField.startsWith(SEQUIN_PROXY_BASE_URL)) {
          const res = await client.query({
            query: GetProxyResolverSequinDocument,
            variables: {
              proxyURL: valField,
              tableOrigin: 'altbo_app_user_profile_view',
              columnOrigin: key
            }
          })
          setSequinFiles((draft) => {
            // @ts-ignore
            draft.files[valField] = res.data?.getProxyResolverSequin
          })
        }
      }))
    }
  }, [client, dataUserProfile?.altbo_app_user_profile_view, setSequinFiles])

  useEffect(() => {
    void fetchAttachments()
    // console. log(dataUserProfile?.altbo_app_user_profile_view)
  }, [dataUserProfile, fetchAttachments])

  const getAttachment = useCallback((proxySequinURL: string) => {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (sequinFiles && sequinFiles.files[proxySequinURL]) {
      return sequinFiles.files[proxySequinURL]
    }
    return null
  }, [sequinFiles])

  const isDesktop = useIsDesktopSize()

  const withoutProfile = useMemo(() => {
    if (!dataUserProfile || dataUserProfile?.altbo_app_user_profile_view?.length === 0) {
      return true
    }
    return false
  }, [dataUserProfile])

  useEffect(() => {
    void initView()
  }, [initView])

  const TwoColumns = useCallback(({ children }: { children: JSX.Element[] }) => (
    <>
      <Grid
        container
        direction='row'
        width='100%'
      >
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          mt={4}
        >
          {children[0]}
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          mt={4}
        >
          {children[1]}
        </Grid>
      </Grid>
    </>
  ), [])

  const LinkOptional = useCallback(({ val, children }: { val?: string | ReactNode, children: JSX.Element }) => {
    if (val && typeof val === 'string' && isValidUrl(val)) {
      return (
        <LinkMUI
          href={val}
          target='_blank'
        >
          {children}
        </LinkMUI>
      )
    }
    return <>{children}</>
  }
  , [])

  const InfoField = useCallback(({ label, companyVal, withoutMargin, withChip }: { label: string, companyVal?: string | ReactNode, withoutMargin?: boolean, withChip?: boolean }) => (
    <Box mt={withoutMargin ? 0 : 4}>
      <Typography variant='inherit'>{label}</Typography>
      <Typography
        variant='body1'
        mt={1}
        sx={{
          lineBreak: 'anywhere'
        }}
        color={THEME.COLORS.dark.secondary}
      >
        <LinkOptional val={companyVal}>
          {
            withChip && typeof companyVal === 'string'
              ? (
                <Chip.Basic
                  color='primary'
                  label={companyVal ?? '—'}
                />
              )
              : <>{companyVal ?? '—'}</>
          }
        </LinkOptional>
      </Typography>
    </Box>
  ), [LinkOptional])

  const download = useCallback((name: string, attachment: GetProxyResolverSequinQuery['getProxyResolverSequin']) => {
    if (attachment?.resolvedURL) {
      const linkAnchor = document.createElement('a')
      linkAnchor.href = attachment?.resolvedURL
      linkAnchor.download = name
      document.body.appendChild(linkAnchor)
      linkAnchor.click()
      document.body.removeChild(linkAnchor)
    }
  }, [])

  const AttachmentField = useCallback(({ label, companyVal, withoutMargin }: { label: string, companyVal?: string, withoutMargin?: boolean }) => {
    if (companyVal && typeof companyVal === 'string' && companyVal.startsWith(SEQUIN_PROXY_BASE_URL)) {
      const attachment = getAttachment(companyVal)
      const isImage = attachment?.resolvedContentType ? attachment.resolvedContentType.some((val) => val ? ['image/png'].includes(val) : false) : false
      const isPDF = attachment?.resolvedContentType ? attachment.resolvedContentType.some((val) => val ? ['application/pdf'].includes(val) : false) : false
      const isLoading = !attachment || !attachment?.resolvedURL

      return (
        <Box mt={withoutMargin ? 0 : 4}>
          <Typography
            variant='inherit'
            mb={2}
          >
            {label}
          </Typography>
          {
            companyVal && typeof companyVal === 'string' && companyVal !== ''
            && (
              <>
                {
                  !isLoading
                    ? (
                      <>
                        {isImage
                        && (
                          <ItemImg sx={{
                            m: isDesktop ? 2 : 1,
                            p: 2,
                            width: 'auto',
                            maxWidth: '600px'
                          }}
                          >
                            <img
                              style={{
                                width: '100%'
                              }}
                              src={attachment?.resolvedURL}
                            />
                          </ItemImg>
                        )}
                        {
                          isPDF
                          && (
                            <>
                              <Box
                                sx={{
                                  height: '50vh'
                                }}
                                pb={3}
                              >
                                <IframePDF src={attachment?.resolvedURL} />
                              </Box>
                            </>
                          )
                        }
                        <Btn.Button onClick={() => download(label, attachment)}>
                          {t('user_tasks.download')}
                        </Btn.Button>
                      </>
                    )
                    : <Box p={2}><LoadingCard /></Box>
                }
              </>
            )
          }
        </Box>
      )
    }

    return (
      <Box mt={withoutMargin ? 0 : 4}>
        <Typography variant='inherit'>{label}</Typography>
        <Typography
          variant='body1'
          mt={1}
          color={THEME.COLORS.dark.secondary}
        >
          {' '}
          —
        </Typography>
      </Box>
    )
  }, [getAttachment, isDesktop])

  return (
    <Box>
      {withoutProfile
      && <WithoutProfile />}
      {dataUserProfile?.altbo_app_user_profile_view?.map((altBo, index) => (
        <Grid
          key={index}
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
            lg={6}
            md={6}
            width='100%'
            style={{
              display: 'flex'
            }}
          >
            <Item style={{
              width: '100%'
            }}
            >
              <Typography variant='h5'>{t('profile.companyInformation', 'Company Information')}</Typography>
              <DividerExtended />
              <Stack>
                <InfoField
                  label={t('profile.companyAutonumber', 'Company Autonumber')}
                  companyVal={altBo.company_autonumber}
                />
                <InfoField
                  label={t('profile.companyName', 'Company Name')}
                  companyVal={altBo.company_name}
                />

                {
                  isAdmin
                  && (
                    <InfoField
                      label='Airtable'
                      companyVal={(
                        <>
                          <a
                            target='_blank'
                            /* @ts-ignore */
                            href={`${ENVIROMENT?.airtable_altbo_companies}/${altBo?.company_id as string}`}
                            rel='noreferrer'
                          >
                            {' '}
                            {/* @ts-ignore */}
                            {altBo?.company_id as string ?? 'ERROR'}
                            {' '}
                          </a>
                        </>
                      )}
                    />
                  )
                }

                <TwoColumns>
                  <InfoField
                    withoutMargin
                    withChip
                    label={t('profile.companyStatus', 'Company Status')}
                    companyVal={altBo.status}
                  />
                  <InfoField
                    withoutMargin
                    withChip
                    label='Plan'
                    companyVal={altBo.plan}
                  />
                </TwoColumns>
                <InfoField
                  label={t('profile.businessAddress', 'Business Address')}
                  companyVal={altBo.business_address}
                />
                <InfoField
                  withChip
                  label={t('profile.services', 'Services')}
                  companyVal={altBo.services}
                />
                <InfoField
                  label={t('profile.category', 'Category')}
                  companyVal={altBo.category}
                />
              </Stack>
            </Item>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            md={6}
            width='100%'
            style={{
              display: 'flex'
            }}
          >
            <Item style={{
              width: '100%'
            }}
            >
              <Typography variant='h5'>{t('profile.contactInformation', 'Contact Information')}</Typography>
              <DividerExtended />
              <>
                <TwoColumns>
                  <InfoField
                    withoutMargin
                    label={t('profile.firstName', 'First name')}
                    companyVal={altBo.first_name}
                  />
                  <InfoField
                    withoutMargin
                    label={t('profile.lastName', 'Last name')}
                    companyVal={altBo.last_name}
                  />
                </TwoColumns>
                {
                  isAdmin
                  && (
                    <InfoField
                      label='Airtable'
                      companyVal={(
                        <>
                          <a
                            target='_blank'
                            /* @ts-ignore */
                            href={`${ENVIROMENT?.airtable_altbo_contacts}/${altBo?.first_contact_record_id as string}`}
                            rel='noreferrer'
                          >
                            {' '}
                            {/* @ts-ignore */}
                            {altBo?.first_contact_record_id as string ?? 'ERROR'}
                            {' '}
                          </a>
                        </>
                      )}
                    />
                  )
                }
                <InfoField
                  label={t('profile.address', 'Address')}
                  companyVal={altBo.personal_address}
                />
                <InfoField
                  label={t('profile.dateOfBirth', 'Date of birth')}
                  companyVal={altBo.date_of_birth}
                />
                <InfoField
                  label={t('profile.countryOfOrigin', 'Country of origin')}
                  companyVal={altBo.country_of_origin}
                />
                <TwoColumns>
                  <InfoField
                    withoutMargin
                    label={t('profile.city', 'City')}
                    companyVal={altBo.city}
                  />
                  <InfoField
                    withoutMargin
                    label={t('profile.state', 'State')}
                    companyVal={altBo.state}
                  />
                </TwoColumns>
                <TwoColumns>
                  <InfoField
                    withoutMargin
                    label={t('profile.email', 'Email')}
                    companyVal={currentIsSalesDemoAccount ? `${altBo?.first_name?.toLowerCase() ?? '-'}@gmail.com` : altBo.email}
                  />
                  <InfoField
                    withoutMargin
                    label={t('profile.phoneNumber', 'Phone Number')}
                    companyVal={altBo.phone_number}
                  />
                </TwoColumns>
                <InfoField
                  label={t('profile.tShirtSize', 'T-shirt size')}
                  companyVal={altBo.t_shirt_size}
                />
              </>
            </Item>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            md={6}
            width='100%'
            style={{
              display: 'flex'
            }}
          >
            <Item style={{
              width: '100%'
            }}
            >
              <Typography variant='h5'>{t('profile.legal', 'Legal')}</Typography>
              <DividerExtended />
              <>
                <InfoField
                  label={t('profile.legalCompanyName', 'Legal Company Name')}
                  companyVal={altBo.legal_company_name}
                />
                <TwoColumns>
                  <InfoField
                    withoutMargin
                    label={t('profile.legalEntity', 'Legal Entity')}
                    companyVal={altBo.legal_entity}
                  />
                  <InfoField
                    withoutMargin
                    withChip
                    label={t('profile.legalEntityStatus', 'Legal Entity Status')}
                    companyVal={altBo.legal_entitystatus}
                  />
                </TwoColumns>
                <InfoField
                  label={t('profile.employerIdentificationNumber', 'Employer Identification Number')}
                  companyVal={altBo.employer_identificationnumber}
                />
                <InfoField
                  withChip
                  label={t('profile.liabilityInsuranceStatus', 'Liability Insurance Status')}
                  companyVal={altBo.liability_insurance_status}
                />
              </>
            </Item>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            md={6}
            width='100%'
            style={{
              display: 'flex'
            }}
          >
            <Item style={{
              width: '100%'
            }}
            >
              <Typography variant='h5'>{t('profile.grow', 'Grow')}</Typography>
              <DividerExtended />
              <>
                <Stack>
                  <TwoColumns>
                    <InfoField
                      withoutMargin
                      label={t('profile.googleMyBusinessURL', 'Google My Business URL')}
                      companyVal={altBo.google_my_business_url}
                    />
                    <InfoField
                      withoutMargin
                      withChip
                      label={t('profile.googleMyBusinessStatus', 'Google My Business Status')}
                      companyVal={altBo.google_my_business_status}
                    />
                  </TwoColumns>
                  <TwoColumns>
                    <InfoField
                      withoutMargin
                      label={t('profile.googleLocalServicesAccountNumber', 'Google Local Services Account Number')}
                      companyVal={altBo.google_local_services_account_number}
                    />
                    <InfoField
                      withoutMargin
                      withChip
                      label={t('profile.googleLocalServicesStatus', 'Google Local Services Status')}
                      companyVal={altBo.google_local_services_status}
                    />
                  </TwoColumns>
                  <TwoColumns>
                    <InfoField
                      withoutMargin
                      label={t('profile.facebookProfileURL', 'Facebook Profile URL')}
                      companyVal={altBo.facebook_profile_url}
                    />
                    <InfoField
                      withoutMargin
                      withChip
                      label={t('profile.facebookStatus', 'Facebook Status')}
                      companyVal={altBo.facebook_status}
                    />
                  </TwoColumns>
                  <TwoColumns>
                    <InfoField
                      withoutMargin
                      label={t('profile.instagramProfileURL', 'Instagram Profile URL')}
                      companyVal={altBo.instagram_profile_url}
                    />
                    <InfoField
                      withoutMargin
                      withChip
                      label={t('profile.instagramStatus', 'Instagram Status')}
                      companyVal={altBo.instagram_status}
                    />
                  </TwoColumns>
                </Stack>
              </>
            </Item>
          </Grid>
          {ownerId
          && (
            <Grid
              item
              xs={12}
              lg={6}
              md={6}
              width='100%'
              style={{
                display: 'flex'
              }}
            >
              <Item style={{
                width: '100%'
              }}
              >
                <Typography variant='h5'>{t('profile.banking.title', 'Banking')}</Typography>
                <DividerExtended />
                <TwoColumns>
                  <InfoField
                    withoutMargin
                    label={t('profile.banking.activeAccount')}
                    withChip={!!bankingStatus}
                    companyVal={bankingStatus ?? <BsDashSquareFill color='gray' />}
                  />
                  <InfoField
                    withoutMargin
                    label={t('profile.banking.activeCard')}
                    withChip={!!activeCard}
                    companyVal={activeCard?.status ?? <BsDashSquareFill color='gray' />}
                  />
                </TwoColumns>

              </Item>
            </Grid>
          )}

          {ownerId
          && (
            <Grid
              item
              xs={12}
              lg={6}
              md={6}
              width='100%'
              style={{
                display: 'flex'
              }}
            >
              <Item style={{
                width: '100%'
              }}
              >
                <Typography variant='h5'>{t('profile.access.title', 'Access Management')}</Typography>
                <DividerExtended />
                {userProfile?.linked_phones?.length > 0
                  ? (
                    userProfile.linked_phones.map((phone: string, index: number) => (
                      <Box
                        key={index}
                        mt={4}
                      >
                        <Typography variant='inherit'>{t('profile.phone', 'Phone')}</Typography>
                        <div className='flex items-center'>
                          <Typography
                            variant='body1'
                            mt={1}
                            color={THEME.COLORS.dark.secondary}
                          >
                            {phone}
                          </Typography>
                          <Btn.IconButton.Delete
                            loading={unlinkingPhone && activeIcon.current[index]}
                            onClick={() => handleDelete(phone, index)}
                          />

                        </div>

                      </Box>
                    ))
                  )
                  : (
                    <Typography variant='body1'>{t('profile.access.noPhones', 'No additional phones')}</Typography>
                  )}
              </Item>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            lg={6}
            md={6}
            width='100%'
            style={{
              display: 'flex'
            }}
          >
            <Item style={{
              width: '100%'
            }}
            >
              <Typography variant='h5'>{t('profile.branding', 'Branding')}</Typography>
              <DividerExtended />
              <>
                <Stack>
                  <AttachmentField
                    label='Logo'
                    companyVal={altBo?.logo}
                  />
                  <InfoField
                    withChip
                    label={t('profile.logoStatus', 'Logo Status')}
                    companyVal={altBo.logo_status}
                  />
                  <InfoField
                    withChip
                    label={t('profile.marketingMaterialStatus', 'Marketing Material Status')}
                    companyVal={altBo.marketing_material_status}
                  />
                  <InfoField
                    withChip
                    label={t('profile.websiteStatus', 'Website Status')}
                    companyVal={altBo.website_status}
                  />
                  <TwoColumns>
                    <InfoField
                      withoutMargin
                      label={t('profile.domain', 'Domain')}
                      companyVal={altBo.comain}
                    />
                    <InfoField
                      withoutMargin
                      withChip
                      label={t('profile.domainStatus', 'Domain Status')}
                      companyVal={altBo.domain_status}
                    />
                  </TwoColumns>
                  <TwoColumns>
                    <InfoField
                      withoutMargin
                      label={t('profile.companyEmail', 'Company Email')}
                      companyVal={currentIsSalesDemoAccount ? 'me@company.com' : altBo.company_email}
                    />
                    <InfoField
                      withoutMargin
                      withChip
                      label={t('profile.companyEmailStatus', 'Company Email Status')}
                      companyVal={altBo.company_email_status}
                    />
                  </TwoColumns>
                  <TwoColumns>
                    <InfoField
                      withoutMargin
                      label={t('profile.callCenterPhone', 'Call Center Phone')}
                      companyVal={altBo.call_center_phone_number}
                    />
                    <InfoField
                      withoutMargin
                      withChip
                      label={t('profile.callCenterPhoneStatus', 'Call Center Phone Status')}
                      companyVal={altBo.call_center_status}
                    />
                  </TwoColumns>
                </Stack>
              </>
            </Item>
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
            md={6}
            width='100%'
            style={{
              display: 'flex'
            }}
          >
            <Item style={{
              width: '100%'
            }}
            >
              <Typography variant='h5'>{t('profile.additionalCompanyInfo', 'Additional Company Info')}</Typography>
              <DividerExtended />
              <>
                <InfoField
                  label={t('profile.serviceAreas', 'Service Areas')}
                  companyVal={altBo.service_areas}
                />
                <InfoField
                  label={t('profile.fileStorageFolder', 'File Storage Folder')}
                  companyVal={altBo.file_storage_folder}
                />
                <AttachmentField
                  label={t('profile.certificateOfInsurance', 'Certificate of Insurance')}
                  companyVal={altBo.certificate_of_insurance}
                />
              </>
            </Item>
          </Grid>
        </Grid>
      ))}
    </Box>
  )
}
