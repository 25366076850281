import React, { useCallback, useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { Alert } from '#app/components/Alerts'
import { Btn } from '#app/components/Buttons'
import { Input } from '#app/components/Input'

interface RowData {
  [key: string]: unknown
}

interface EditableTableProps {
  jsonData: string
  onJsonUpdate: (updatedJson: string) => void
  mandatory: boolean
}

const WarningMessage = () => (
  <Alert.Basic
    severity='warning'
    title='Warning: This variable was not created with the intention of being a list'
    description='The list entered is not valid, but you should not display this variable as a list anyway because it was not created with that intention.'
  />
)

const EditableTable: React.FC<EditableTableProps> = ({ jsonData, onJsonUpdate, mandatory }) => {
  const [data, setData] = useState<RowData[]>([])
  const [isValidJson, setIsValidJson] = useState<boolean>(true)

  useEffect(() => {
    try {
      const parsedData: RowData[] = JSON.parse(jsonData)
      if (Array.isArray(parsedData) && parsedData.length > 0) {
        const allKeysSet = parsedData.reduce<Set<string>>((acc, cur) => {
          Object.keys(cur).forEach((key) => {
            acc.add(key)
          })
          return acc
        }, new Set<string>())

        const allKeys = Array.from(allKeysSet)

        const unifiedData = parsedData.map((obj) => {
          const unifiedObj: RowData = {}
          allKeys.forEach((key) => {
            unifiedObj[key] = Object.prototype.hasOwnProperty.call(obj, key) ? obj[key] : ''
          })
          return unifiedObj
        })

        setData(unifiedData)
      }
      else {
        throw new Error('The JSON must be an array of objects')
      }
    }
    catch (error) {
      console.error(error)
      setIsValidJson(false)
    }
  }, [jsonData])

  useEffect(() => {
    try {
      if (Array.isArray(data) && data.length > 0) {
        const updatedJson = JSON.stringify(data)
        if (updatedJson !== jsonData) {
          onJsonUpdate(updatedJson)
        }
      }
    }
    catch (err) {
      console.error('Error converting table to json')
      console.error(err)
    }
  }, [data, jsonData, onJsonUpdate])

  const handleChange = (newValue: string, rowIndex: number, key: string) => {
    const newData = data.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          [key]: newValue
        }
      }
      return row
    })
    setData(newData)
  }

  const pushRow = useCallback(() => {
    const lastElement: RowData = data[data.length - 1]
    setData([...data, lastElement])
  }, [data])

  const deleteRow = useCallback((index: number) => {
    setData((prevData) => prevData.filter((_, i) => i !== index))
  }, [])

  if (!isValidJson && mandatory) {
    return (
      <Alert.Basic
        severity='error'
        title='Error in this variable, we do not recommend that you publish this version.'
        description='We cannot convert the value of the variable into a table because it is not in the correct format, you have to edit it again in text and configure a valid value. To find examples we recommend that you review the original template.'
      />
    )
  }

  if (!isValidJson && !mandatory) {
    return (
      <WarningMessage />
    )
  }

  return (
    <>
      <TableContainer component={Paper}>
        {
          !mandatory
          && <WarningMessage />
        }
        <Table aria-label='editable table'>
          <TableHead>
            <TableRow>
              {data.length > 0 && Object.keys(data[0]).map((key) => (
                <TableCell key={key}>{key}</TableCell>
              ))}
              <TableCell>options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {Object.keys(row).map((key) => (
                  <TableCell key={key}>
                    {/* <TextField
                      value={row[key]}
                      variant='outlined'
                      size='small'
                      onChange={(event) => handleChange(event?.target?.value, rowIndex, key)}
                    /> */}
                    <Input.ImageUpload
                      isTextArea
                      uploadPath='website-editor'
                      inputValue={row[key] as string}
                      sx={{
                        flexGrow: 1
                      }}
                      onUploadComplete={(url) => handleChange(url, rowIndex, key)}
                      onChange={(valx) => handleChange(valx, rowIndex, key)}
                    />
                  </TableCell>
                ))}
                <TableCell>
                  <Btn.IconButton.Close
                    tooltip='Remove'
                    onClick={() => deleteRow(rowIndex)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Stack
          direction='row'
          justifyContent='flex-end'
        >
          <Btn.IconButton.Plus onClick={pushRow} />
        </Stack>
      </TableContainer>
    </>
  )
}

export default EditableTable
