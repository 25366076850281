import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box, Stack, Typography } from '@mui/material'
import { useImmer } from 'use-immer'

import rabbit from '#app/assets/img/rabbit.png'
import { DividerExtended } from '#app/components/Divider'
import { LoadingCard } from '#app/components/Loading/LoadingCard'
import { useUpdateBotFlowResultsMutation } from '#app/operations/botflow/botflow.mutations.generated'
import { useGetOnboardingWelcomeFlowLazyQuery } from '#app/operations/onboarding/onboarding.queries.generated'

import { FormFlow } from '../../../feature/FormFlow'

import { welcomeCallQuestions } from './questionsV1'

export function WelcomeForm() {
  const [searchParams] = useSearchParams()

  const [contactId] = useState<string>(searchParams.get('contact_id') ?? '')
  const [companyId] = useState<string>(searchParams.get('company_id') ?? '')
  const [by_user] = useState<string>(searchParams.get('by_user') ?? '')
  const [updateBotResults] = useUpdateBotFlowResultsMutation()
  const [getstatus] = useGetOnboardingWelcomeFlowLazyQuery()
  const [statusPage, setStatusPage] = useImmer({
    loading: true,
    disabled: false
  })

  const [activeTime, setActiveTime] = useState(0)

  useEffect(() => {
    const validateView = async () => {
      setStatusPage((draft) => { draft.loading = true })
      const res = await getstatus({
        variables: {
          contact_id: contactId,
          company_id: companyId
        }
      })

      if (res.data?.getOnboardingWelcomeFlow?.is_form_completed) {
        setStatusPage((draft) => {
          draft.loading = false
          draft.disabled = true
        })
      }
      else {
        setStatusPage((draft) => {
          draft.loading = false
          draft.disabled = false
        })
      }
    }

    void validateView()
  }, [contactId, companyId, getstatus, setStatusPage])

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTime((prevTime) => prevTime + 1)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const callbackComplete = useCallback(async (data: any) => {
    const res = await updateBotResults({
      variables: {
        flow_name: 'onboarding_welcome_call',
        company_id: companyId,
        contact_id: contactId,
        payload: {
          completion_time_seconds: activeTime,
          questions: data,
          by_user
        }
      }
    }).catch((err) => {
      console.error(err)
    })

    return res
  }, [activeTime, companyId, contactId, updateBotResults, by_user])

  return (
    <>
      {(!statusPage.loading)
      && (
        <>
          {statusPage.disabled
          && (
            <>
              <Box sx={{
                width: '90vw',
                height: '80vh',
                maxWidth: '600px',
                overflow: 'auto'
              }}
              >
                <Stack
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  spacing={8}
                >
                  <Box>
                    <Typography
                      mt={2}
                      variant='h3'
                    >
                      Formulario completado
                    </Typography>
                    <Typography mt={2}> Ya ha completado el formulario de bienvenida, si tiene alguna pregunta no dude en contactarnos! </Typography>
                  </Box>
                  <img src={rabbit} />
                </Stack>
              </Box>
            </>
          )}
          {!statusPage.disabled
          && (
            <>
              {
                (contactId && companyId)
                  ? (
                    <FormFlow
                      callbackComplete={callbackComplete}
                      formFlowData={welcomeCallQuestions}
                    />
                  )
                  : <Typography> Welcome Flow: This URL is invalid because the company and contact data are needed, visit altBo to get the correct URL. </Typography>
              }
            </>
          )}
        </>
      )}
      {
        (statusPage.loading)
        && (
          <>
            <LoadingCard />
            <DividerExtended />
            <Typography>Estamos cargando la pantalla para brindarte la mejor experiencia.</Typography>
          </>
        )
      }
    </>
  )
}
