import { ReactElement, useContext } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { BsClipboard, BsFillExclamationCircleFill, BsFillXCircleFill, BsPaperclip } from 'react-icons/bs'
import { IoLogoWhatsapp } from 'react-icons/io'
import { Chip as ChipMUI, SxProps } from '@mui/material'
import { useSnackbar } from 'notistack'

import AppContext from '#app/contexts/AppContext'

import { ChipGroup } from './ChipGroup'
import { ChipLanguage } from './ChipLanguage'
import { ChipSequenceStatus } from './ChipSequenceStatus'
import { ChipTaskTemplate } from './ChipTaskTemplate'
import { ChipUser } from './ChipUser'
import { ChipUserAvatar } from './ChipUserAvatar'
import { ChipUserEmail } from './ChipUserEmail'

export interface ChipProps {
  color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined
  label?: string
  onClick?: () => void
  sx?: SxProps
  icon?: ReactElement
  size?: 'small' | 'medium' | undefined
}

interface PropsFilter extends Omit<ChipProps, 'onClick'> {
  onDelete: () => void
  deleteIcon?: ReactElement
}

export const ChipBasic = ({ label, color, sx, icon, size, onClick }: ChipProps) => {
  return (
    <ChipMUI
      label={label}
      sx={sx}
      color={color}
      icon={icon}
      size={size}
      variant={onClick ? 'filled' : 'outlined'}
      onClick={onClick}
    />
  )
}

export const ChipFilter = ({ label, color, sx, onDelete, deleteIcon }: PropsFilter) => {
  return (
    <ChipMUI
      variant='outlined'
      deleteIcon={deleteIcon ?? <BsFillXCircleFill />}
      label={label}
      sx={sx}
      color={color}
      onDelete={onDelete}
    />
  )
}

export const ChipError = ({ sx, onClick }: ChipProps) => {
  return (
    <ChipMUI
      sx={sx}
      variant={onClick ? 'filled' : 'outlined'}
      icon={<BsFillExclamationCircleFill />}
      label='Error'
      color='error'
    />
  )
}

export const ChipAttachment = ({ sx, onClick }: ChipProps) => {
  const { t } = useTranslation()

  return (
    <ChipMUI
      sx={sx}
      variant={onClick ? 'filled' : 'outlined'}
      icon={<BsPaperclip />}
      label={t('user_tasks.attached')}
      color='primary'
      onClick={onClick}
    />
  )
}

export const ChipClipboard = ({ sx, onClick, clipboard, label, color, hideIcon }: ChipProps & { clipboard: string, hideIcon?: boolean }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <CopyToClipboard
      text={clipboard}
      onCopy={() => enqueueSnackbar('Copied to your clipboard!', {
        variant: 'info',
        autoHideDuration: 4000
      })}
    >
      <ChipMUI
        sx={sx}
        variant={onClick ? 'filled' : 'outlined'}
        icon={hideIcon ? <></> : <BsClipboard />}
        label={label ?? 'Copy to Clipboard'}
        color={color ?? 'primary'}
        onClick={onClick}
      />
    </CopyToClipboard>
  )
}

export const ChipSupport = ({ sx }: ChipProps) => {
  const { t } = useTranslation()
  const { appState } = useContext(AppContext)

  return (
    <a
      href={`http://wa.me/${appState.supportPhone}`}
      style={{
        textDecoration: 'none'
      }}
      target='_blank'
      rel='noreferrer'
    >
      <ChipMUI
        sx={sx}
        variant='filled'
        icon={<IoLogoWhatsapp />}
        label={t('generic.support')}
        color='primary'
        onClick={() => null}
      />
    </a>
  )
}

export const Chip = {
  Clipboard: ChipClipboard,
  Attachment: ChipAttachment,
  Support: ChipSupport,
  Basic: ChipBasic,
  Error: ChipError,
  Filter: ChipFilter,
  Group: ChipGroup,
  Language: ChipLanguage,
  SequenceStatus: ChipSequenceStatus,
  TaskTemplate: ChipTaskTemplate,
  User: ChipUser,
  UserAvatar: ChipUserAvatar,
  UserEmail: ChipUserEmail
}
