import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'

import linearCircle from '#app/assets/img/linearCircle.png'
import { Btn } from '#app/components/Buttons'
import { ChipUserAvatar } from '#app/components/Chip/ChipUserAvatar'
import ProfileContext from '#app/contexts/ProfileContext'
import { Attachments } from '#app/feature/Comments/Attachments'
import { useAdmins } from '#app/hooks/useAdmins'
import { useDayJs } from '#app/hooks/useDayJs'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { Comment, Generic, User } from '#app/types'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

import { PreviewRichContent } from '../Tasks/ViewTask/PreviewRichContent'

interface CommentMetadata { chat_message_id: string, chat_channel_id: string }
interface Props {
  cbDelete: (id: Generic.CommentID) => void
  comment: Comment.CommentType
}
export function CommentBox({ cbDelete, comment }: Props) {
  const isDesktop = useIsDesktopSize()
  const { isAdmin } = useEntityUser()
  const { formatShortDate } = useDayJs()
  const [hover, setHover] = useState(false)
  const [searchParams] = useSearchParams()
  const { getAdminEmailByLinearUserId } = useAdmins()

  const { t } = useTranslation()

  const data = useContext(ProfileContext)
  const profile: User.UserType | null = useMemo(() => {
    if (data.getProfile.user) return data.getProfile.user
    return null
  }, [data])

  const highlightComment = useMemo(() => {
    if (searchParams.get('comment')) {
      return searchParams.get('comment')
    }
    return null
  }, [searchParams])

  const determineBackgroundColor = () => {
    if (comment.private_note && comment.id === highlightComment) {
      return '#FFFF00'
    }
    if (comment.private_note) {
      return '#FFFBEB'
    }
    if (comment.id === highlightComment) {
      return 'rgb(232, 246, 233)'
    }
    return undefined
  }

  const commentAuthor = useMemo(() => {
    if (comment?.linearCommentLinked) return `${comment?.linearCommentLinked?.user?.name}`
    if (comment?.userByCreatedBy) return `${comment?.userByCreatedBy?.first_name} ${comment?.userByCreatedBy?.last_name}`
    return 'Owners Team'
  }, [comment?.linearCommentLinked, comment?.userByCreatedBy])

  const commentEmail = useMemo(() => {
    return getAdminEmailByLinearUserId(comment?.linearCommentLinked?.user?.id)
  }, [comment?.linearCommentLinked, getAdminEmailByLinearUserId])

  const navigate = useNavigate()

  const goToChat = (metadata: CommentMetadata) => {
    navigate(`/chat/channel/${metadata.chat_channel_id}?message_id=${metadata.chat_message_id}`)
  }

  return (
    <Box
      sx={{
        borderRadius: '10px',
        width: '100%'
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Stack
        direction={comment.created_by !== profile?.id ? 'row-reverse' : 'row'}
        sx={{
          width: '100%'
        }}
        spacing={1}
      >
        {
          comment.userByCreatedBy
          && (
            <ChipUserAvatar
              email={commentEmail}
              fullname={commentAuthor}
            />
          )
        }
        <Box sx={{
          width: isDesktop ? '65%' : '85%'
        }}
        >
          <PreviewRichContent
            withCard
            sx={{
              backgroundColor: determineBackgroundColor()
            }}
            header={(
              <>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='stretch'
                  spacing={1}
                >
                  <Box>
                    {comment?.metadata?.chat_message_id
                    && (
                      <Stack
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                        spacing={2}
                      >
                        <Button onClick={() => goToChat(comment.metadata as CommentMetadata)}>
                          <Typography
                            variant='subtitle2'
                            color='gray'
                          >
                            {t('comment.chat_message', 'From Chat Message')}
                          </Typography>
                        </Button>
                      </Stack>
                    )}
                  </Box>
                </Stack>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='stretch'
                  spacing={1}
                >
                  <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    spacing={2}
                  >
                    <Typography
                      variant='subtitle2'
                      color='gray'
                    >
                      {commentAuthor}
                      {' '}
&nbsp;
                      (
                      {formatShortDate(comment.created_at)}
                      )
                    </Typography>
                  </Stack>
                  <Box>
                    {
                      isAdmin && comment?.linearCommentLinked?.original_linear_issue_id
                      && (
                        <IconButton aria-label='custom icon link'>
                          <img
                            src={linearCircle}
                            alt='custom icon'
                            style={{
                              width: 32,
                              height: 32
                            }}
                          />
                        </IconButton>
                      )
                    }
                    {
                      (comment.thread_entity_type === 'user_tasks' ? (hover && isAdmin) : true) && comment.created_by === profile?.id
                      && (
                        <Btn.IconButton.Delete
                          withoutWarning
                          onClick={() => cbDelete(comment.id)}
                        />
                      )
                    }
                  </Box>
                </Stack>
              </>
            )}
            isMarkdown={!!comment.linear_comment_id}
            content={comment.text}
          />
          {comment.attachments
          && <Attachments attachments={comment.attachments} />}
        </Box>
      </Stack>
    </Box>
  )
}
