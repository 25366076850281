import { Box, Grid } from '@mui/material'

import Button from '#app/components/Buttons/Button'
import OwnersLogo from '#app/components/OwnersLogo'
import ProgressierScript from '#app/contexts/ProgressierScript'

export const PublicInstall = () => {
  return (
    <ProgressierScript>
      <Box height='100vh'>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={0}
          sx={{
            backgroundColor: 'var(--secondary-bg-color)',
            height: '100%'
          }}
        >
          <Grid
            item
            xs={6}
            display='flex'
            alignItems='flex-end'
          >
            <OwnersLogo
              size='md'
              sx={{
                mb: 4
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Button onClick={() => {
              location.href = '/'
            }}
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ProgressierScript>
  )
}
