/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApolloClient } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { useImmer } from 'use-immer'

import { Btn } from '#app/components/Buttons'
import { ChipAttachment } from '#app/components/Chip'
import { LoadingCard } from '#app/components/Loading/LoadingCard'
import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import { GetWorkOrderAttachmentsDocument, GetWorkOrderAttachmentsQuery } from '#app/operations/pmmarketplace/pmmarketplace.queries.generated'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

export function useWorkOrders(workOrderId?: string, bidId?: string) {
  const client = useApolloClient()
  const isDesktop = useIsDesktopSize()
  const [loadingFiles, setLoadingFiles] = useState(true)
  const [sequinFiles, setSequinFiles] = useImmer<{ files: { [key: string]: GetWorkOrderAttachmentsQuery['getWorkOrderAttachments'] } }>({
    files: {}
  })

  const ItemMedia = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    color: theme.palette.text.secondary,
    ...theme.typography.body2
  }))

  const IframePDF = styled('iframe')`
    min-width: 250px;
    width: 80%;
    max-width: 500px;
    height: 100%;
  `

  const fetchEvidences = useCallback(async (workOrderEvidenceId: string, attachments: string[]) => {
    await Promise.all(attachments.map(async (attachment, key) => {
      const res = await client.query({
        query: GetWorkOrderAttachmentsDocument,
        variables: {
          proxyURL: attachment,
          workOrderId,
          workOrderEvidenceId
        }
      })
      setSequinFiles((draft) => {
        // @ts-ignore
        draft.files[attachment] = res.data?.getWorkOrderAttachments
      })
    }))
    setLoadingFiles(true)
  }, [client, setSequinFiles, workOrderId])

  const fetchAttachments = useCallback(async (attachments: string[]) => {
    await Promise.all(attachments.map(async (attachment, key) => {
      const res = await client.query({
        query: GetWorkOrderAttachmentsDocument,
        variables: {
          proxyURL: attachment,
          workOrderId,
          bidId
        }
      })
      setSequinFiles((draft) => {
        // @ts-ignore
        draft.files[attachment] = res.data?.getWorkOrderAttachments
      })
    }))
    setLoadingFiles(true)
  }, [bidId, client, setSequinFiles, workOrderId])

  const download = useCallback((attachment: GetWorkOrderAttachmentsQuery['getWorkOrderAttachments'], name?: string) => {
    if (attachment?.resolvedURL) {
      const linkAnchor = document.createElement('a')
      linkAnchor.href = attachment?.resolvedURL
      linkAnchor.download = name ?? 'file'
      document.body.appendChild(linkAnchor)
      linkAnchor.click()
      document.body.removeChild(linkAnchor)
    }
  }, [])
  const { t } = useTranslation()
  const [currentFile, openFile] = useState<boolean>(false)

  const AttachmentSequin = useCallback(({ label, attachmentUrl, withoutPreview, withoutDownload }: { label?: string, attachmentUrl: string, withoutPreview?: boolean, withoutDownload?: boolean }) => {
    const attachment = sequinFiles.files[attachmentUrl]
    const isImage = attachment?.resolvedContentType ? attachment.resolvedContentType.some((val) => val ? ['image/png', 'image/jpeg'].includes(val) : false) : false
    const isVideo = attachment?.resolvedContentType ? attachment.resolvedContentType.some((val) => val ? ['video/mp4'].includes(val) : false) : false
    const isPDF = attachment?.resolvedContentType ? attachment.resolvedContentType.some((val) => val ? ['application/pdf'].includes(val) : false) : false
    const isLoading = !attachment || !attachment?.resolvedURL

    const PreviewMedia = ({ fullSize }: { fullSize: boolean }) => useMemo(() => (
      <>
        {isImage
        && (
          <ItemMedia sx={{
            m: isDesktop ? 2 : 1,
            p: 2,
            width: 'auto',
            maxWidth: fullSize ? '100%' : '600px'
          }}
          >
            <img
              style={{
                width: '100%'
              }}
              src={attachment?.resolvedURL}
            />
          </ItemMedia>
        )}
        {
          isPDF
          && (
            <>
              <ItemMedia sx={{
                m: isDesktop ? 2 : 1,
                p: 2,
                width: 'auto',
                height: fullSize ? '80vh' : '600px',
                maxWidth: fullSize ? '100%' : '600px',
                textAlign: 'center'
              }}
              >
                <IframePDF
                  src={attachment?.resolvedURL}
                  style={{
                    width: '100%'
                  }}
                />
              </ItemMedia>
            </>
          )
        }
        {
          isVideo
          && (
            <ItemMedia sx={{
              m: isDesktop ? 2 : 1,
              p: 2,
              width: 'auto',
              maxWidth: fullSize ? '100%' : '600px'
            }}
            >
              <video
                controls
                style={{
                  width: '100%'
                }}
                src={attachment?.resolvedURL}
              />
            </ItemMedia>
          )
        }
      </>
    )
    , [fullSize])

    return (
      <Box>
        <Typography
          variant='inherit'
          mb={2}
        >
          {label}
        </Typography>
        {
          withoutPreview
          && (
            <RightSideBar
              size='lg'
              isOpen={currentFile}
              closeHandler={() => openFile(false)}
            >
              <>
                <Typography variant='h4'> Preview </Typography>
                <PreviewMedia fullSize />
                <Btn.Button onClick={() => download(attachment, label)}>
                  {t('user_tasks.download')}
                </Btn.Button>
              </>
            </RightSideBar>
          )
        }
        {
          !isLoading
            ? (
              <>
                {!withoutPreview
                && <PreviewMedia fullSize={false} />}
                {
                  withoutPreview && attachment?.resolvedURL
                  && <ChipAttachment onClick={() => openFile(true)} />
                }
                {
                  !withoutDownload
                  && (
                    <Btn.Button onClick={() => download(attachment, label)}>
                      {t('user_tasks.download')}
                    </Btn.Button>
                  )
                }
              </>
            )
            : <Box p={2}><LoadingCard /></Box>
        }
      </Box>
    )
  }, [IframePDF, ItemMedia, isDesktop, sequinFiles])

  const statusToString = useCallback((status: string | undefined) => {
    const validStatus: { [key: string]: string } = {
      unanswered: 'Unanswered',
      open: 'Open',
      job_confirmed: 'Job Confirmed',
      job_due: 'Job Due',
      evidence_submitted: 'Evidence Submitted',
      completed: 'Completed',
      canceled: 'Canceled'
    }

    if (!status) return 'No Status'
    return validStatus[status] || status
  }, [])

  return {
    sequinFiles,
    fetchAttachments,
    fetchEvidences,
    AttachmentSequin,
    loadingFiles,
    statusToString
  }
}
