import { useContext, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

import { useAppContext } from '#app/contexts/AppContext/useAppContext'

import AppContext from '../../../contexts/AppContext'
import PersonalInfoForm from '../../../forms/Onboarding/PersonalInfoForm'
import { getFormValues } from '../../../hooks/useOnboardingFormReducer'

const PersonalInformation = () => {
  const { appState } = useContext(AppContext)
  const { V1_SET_LOADING } = useAppContext()

  const {
    onboardingReducer: [onboarding, dispatch],
    loadingState: [, setLoading]
  } = useOutletContext()

  useEffect(() => {
    if (appState.isLoading) {
      setTimeout(() => {
        V1_SET_LOADING(false)
      }, 500)
    }
  })

  const dispatchAction = async (values) => {
    setLoading(true)
    await dispatch({
      type: 'SET_PERSONAL_INFORMATION',
      values
    })
  }

  return (
    <PersonalInfoForm
      information={getFormValues(onboarding.sections.personalInformation)}
      onSubmit={dispatchAction}
    />
  )
}

export default PersonalInformation
