import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'
import { useSnackbar } from 'notistack'

import LogoOwners from '#app/assets/svg/logo-owners-dark.svg'
import { useStoreCustomerJourney } from '#app/store/customer-journey'
import { ArrowAroundRight, Logout1 } from '#app/v2/assets/Icons'
import Images from '#app/v2/assets/Images'
import { Button } from '#app/v2/components/ui/button'

interface PhraseProps {
  phrase: string
}

const AnimatedPhrase = ({ phrase }: PhraseProps) => {
  return (
    <motion.p
      initial={{
        y: '100%',
        opacity: 0
      }}
      animate={{
        y: 0,
        opacity: 1
      }}
      exit={{
        y: '-100%',
        opacity: 0
      }}
      transition={{
        duration: 0.7
      }}
      className='absolute inset-0 mx-auto h-full max-w-xs text-center text-xl/6 font-semibold text-white [text-wrap:balance] sm:max-w-sm'
    >
      {phrase}

    </motion.p>
  )
}

export const LoadingScreen = ({ visible, error, errorText, errorSignout }: { visible: boolean, error?: boolean, errorText?: string, errorSignout?: () => void }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0)
  const { t } = useTranslation()
  const { newFatalError } = useStoreCustomerJourney()
  const hasSnackbarBeenEnqueued = useRef(false)

  useEffect(() => {
    if (error && errorText && !hasSnackbarBeenEnqueued.current) {
      const errorMessage = errorText || t('v2.loadingFullScreen.errorToast', 'Oops! Something didn\'t work as expected. Please, help us by reporting this URL to our support team so we can fix it quickly. Thank you for your help and patience!')

      newFatalError(errorMessage)

      enqueueSnackbar(errorMessage, {
        variant: 'error',
        persist: true,
        undo: false
      })

      hasSnackbarBeenEnqueued.current = true
    }
  }, [error, errorText, t, enqueueSnackbar, newFatalError])

  const phrases = [
    t('v2.loadingFullScreen.phrase2', 'Your path to success starts here!'),
    t('v2.loadingFullScreen.phrase3', 'Everything you do now is for your future.'),
    t('v2.loadingFullScreen.phrase4', 'Dreams don’t work unless you do.'),
    t('v2.loadingFullScreen.phrase1', 'Get ready to become your own boss!'),
  ]

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the current phrase index in a circular manner
      setCurrentPhraseIndex((prevIndex) =>
        (prevIndex + 1) % phrases.length,
      )
    }, 4000) // Change the interval as needed

    return () => clearInterval(intervalId) // Cleanup on component unmount
  }, [phrases.length])
  return (
    <>
      {visible
      && (
        <div className='flex min-h-screen flex-col items-center justify-center gap-14 bg-[#304985] pb-[30%] sm:pb-[15%]'>
          <section
            id='intro'
            className=' flex flex-col items-center gap-11'
          >
            <img
              src={LogoOwners}
              className=' h-12 w-full'
              alt=''
            />
            {!error && (
              <img
                src={Images.LoaderSpinner}
                className=' aspect-square h-10 w-10 animate-spin'
                alt='Loading indicator spinning'
              />
            )}
            {error && (
              <div className='flex w-full flex-col gap-5'>
                <Button
                  variant='secondary'
                  className='w-full gap-2.5 py-3 text-sm text-[#304985]'
                  onClick={errorSignout}
                >
                  <Logout1 className='h-5 w-5' />
                  {t('v2.general.logout', 'Log out')}
                </Button>
                <Button
                  className='w-full gap-2.5 border border-white py-3 text-sm'
                  onClick={() => window.location.reload()}
                >
                  <ArrowAroundRight className='h-5 w-5' />
                  {t('v2.general.refresh', 'Refresh')}
                </Button>
              </div>
            )}
          </section>
          {!error && (
            <motion.section
              layout
              id='inspiration'
              className='relative h-14 w-full overflow-hidden'
            >
              <AnimatePresence>
                <AnimatedPhrase
                  key={phrases[currentPhraseIndex]}
                  phrase={phrases[currentPhraseIndex]}
                />
              </AnimatePresence>
            </motion.section>

          )}
        </div>
      )}
    </>
  )
}
