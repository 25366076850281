import { KeyboardEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'

import { GetCommentsQuery } from '#app/operations/comments/comments.queries.generated'
import { removeHTMLFromString } from '#app/utils/vanilla/helpers'
import { ConvertPDF2, HorizontalMenuCircle } from '#app/v2/assets/Icons'
import { FiveStars } from '#app/v2/components/FiveStars'
import { PreviewRichContentV2 } from '#app/v2/components/PreviewRichContent'
import { ProfileAvatarDetailed } from '#app/v2/components/ProfileAvatarDetailed'
import { RateTaskDialog } from '#app/v2/components/RateTaskDialog'
import { Button } from '#app/v2/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from '#app/v2/components/ui/dialog'
import Dropdown from '#app/v2/components/ui/dropdown-menu'
import { Input } from '#app/v2/components/ui/input'
import { cn } from '#app/v2/libs/utils'
/*
Attachment[] example:
[
    {
        "url": "https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/comments%2F426c4143-3ef8-4b0f-832a-c0608a30f296%2F07c2e05b-2a45-40f7-8c73-cda48f22f73d.jpeg?alt=media&token=525f1834-f28b-4b16-aa6c-2bc1ec3bcaf0",
        "type": "image/jpeg",
        "filename": "1697728507709 (1).jpeg"
    }
]
*/
interface Attachment {
  filename?: string
  url?: string
  type?: string
}
export interface TaskFeedProps {
  comment?: GetCommentsQuery['comments'][0]
  isAuthor?: boolean
  editComment?: (commentId: string, text: string) => void
  deleteComment?: (commentId: string) => void
  urlChat?: string
  rating?: {
    score: string
    explanation: string
  }
}
export const TaskActivity = ({ comment, isAuthor, urlChat, editComment, deleteComment, rating }: TaskFeedProps) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [commentText, setCommentText] = useState(comment?.text)

  enum IMAGE_TYPES {
    'image/jpeg',
    'image/webp',
    'image/svg+xml',
    'image/png',
    'image/gif',
    'image/avif'
  }
  const { imageAttachments, docAttachments } = useMemo(() => {
    const attachments = (comment?.attachments ?? []) as Attachment[]
    return attachments.reduce((acc, file) => {
      const isImage = IMAGE_TYPES[file.type as keyof typeof IMAGE_TYPES] != null
      if (isImage) {
        acc.imageAttachments.push(file)
        return acc
      }

      if (file.type === 'application/pdf') {
        acc.docAttachments.push(file)
      }
      return acc
    }, {
      imageAttachments: [] as Attachment[],
      docAttachments: [] as Attachment[]
    })
  }, [comment?.attachments])

  const loading = useMemo(() => {
    return formLoading
  }, [formLoading])
  if (isDeleted) {
    return <></>
  }
  return (
  // eslint-disable-next-line tailwindcss/no-custom-classname
    <section
      className='space-y-3 @container'
      id='acvitity_card1'
    >
      <div className='flex items-center justify-between'>
        <div className='flex w-2/3 gap-4 @xs:w-3/4 @md:w-4/5 md:w-10/12'>
          <ProfileAvatarDetailed
            linearUserId={comment?.linearCommentLinked?.user?.id}
            fullName={comment?.linearCommentLinked?.user?.name}
            firstName={comment?.userByCreatedBy?.first_name}
            lastName={comment?.userByCreatedBy?.last_name}
            email={comment?.userByCreatedBy?.email}
            profilePictureUrl={comment?.userByCreatedBy.profile_picture_url}
            urlChat={urlChat}
            rating={!!rating}
          />
        </div>
        {
          (isAuthor /* TODO: implement  || urlChat */) && (
            <Dialog>
              <Dropdown.Menu>
                <Dropdown.Trigger asChild>
                  <Button
                    variant='ghost'
                    className=' h-full rounded-full p-1'
                  >
                    <HorizontalMenuCircle className='h-6 w-6 text-[#212121]' />
                  </Button>
                </Dropdown.Trigger>
                <Dropdown.Content align='end'>
                  {/* TODO: Implement navigation based on comment metadata
                             {urlChat &&
                                <Dropdown.Item className=' text-sm font-medium text-[#334155]'>{t('v2.tasks.comment.options.viewInChat','View in Chat')}</Dropdown.Item>
                            }  */}
                  {isAuthor && !rating
                  && (
                    <>
                      <Dropdown.Item
                        className=' text-sm font-medium text-[#334155]'
                        onClick={() => setEditMode(!editMode)}
                      >
                        {!editMode ? t('v2.tasks.comment.options.edit', 'Edit') : t('v2.tasks.comment.options.cancelEdit', 'Cancel Edit')}
                      </Dropdown.Item>
                      <Dropdown.Item
                        className=' text-sm font-medium text-[#334155]'
                        onClick={async () => {
                          deleteComment && await deleteComment(comment?.id)
                          setIsDeleted(true)
                        }}
                      >
                        {t('v2.tasks.comment.options.delete', 'Delete')}
                      </Dropdown.Item>
                    </>
                  )}
                  {rating && (
                    <>
                      <DialogTrigger asChild>
                        <Dropdown.Item className=' text-sm font-medium text-[#334155]'>
                          {t('v2.tasks.rating.edit', 'Edit rating')}
                        </Dropdown.Item>
                      </DialogTrigger>

                    </>
                  )}
                </Dropdown.Content>
              </Dropdown.Menu>
              <DialogContent className='max-w-sm'>
                <DialogHeader className='text-sm sm:text-center'>
                  {t('v2.tasks.rating.qualifyExperience', 'Rate your experience with this task')}
                </DialogHeader>
                <RateTaskDialog rating={rating} />
              </DialogContent>
            </Dialog>
          )
        }
      </div>
      {/* We can't render directly the text, is html or markdown content */}
      {/* <p className=' text-sm tracking-[0.02px] text-[#424242]'>{comment?.text}</p> */}
      {rating && (
        <div className='space-y-3'>
          <FiveStars rating={rating} />
          <p className=' text-sm tracking-[0.02px] text-[#424242]'>{rating.explanation}</p>
        </div>
      )}
      {
        !editMode
        && (
          <PreviewRichContentV2
            htmlContent={!comment?.linear_comment_id ? commentText : undefined}
            markdownContent={comment?.linear_comment_id ? commentText : undefined}
          />
        )
      }
      {
        editMode && comment?.text
        && (
          <Formik
            initialValues={{
              commentEdited: removeHTMLFromString(comment?.text)
            }}
            onSubmit={async (values, { resetForm }) => {
              setFormLoading(true)
              editComment && await editComment(comment?.id, values?.commentEdited)
              setCommentText(`<p>${values?.commentEdited}</p>`)
              setFormLoading(false)
              setEditMode(false)
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form className='flex w-full items-center gap-2.5'>
                <div className={cn('relative grow', {
                  'animate-pulse pointer-events-none': loading
                })}
                >
                  <Input
                    component='textarea'
                    autoComplete='off'
                    disabled={loading}
                    className={cn('h-full w-full rounded-xl border-0 bg-[#EFEFEF] px-5 py-4 text-base text-[#8097A1] placeholder:font-medium placeholder:text-[#8097A1]', {
                      'placeholder:invisible': loading
                    })}
                    name='commentEdited'
                    placeholder='Edit comment...'
                    onKeyDown={(e: KeyboardEvent) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault()
                        handleSubmit()
                      }
                    }}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )
      }
      { /* Docs links a */}
      { docAttachments?.length > 0 && (
        <div className='flex snap-x snap-proximity gap-2 overflow-x-auto pb-2'>
          {docAttachments.map((doc, i) => (
            <a
              key={i}
              className=' inline-flex min-w-[30%] cursor-pointer snap-start gap-0.5 border border-[#EFEFEF] bg-[#F5F5F5] p-1.5 text-xs/3 font-medium text-[#454545]'
              onClick={() => window.open(doc.url, '_blank')}
            >
              <ConvertPDF2 className=' h-3 w-3 shrink-0 text-[#D85140]' />
              <span className='line-clamp-2'>{doc.filename}</span>
            </a>
          ))}
        </div>
      )}
      {/* Image content attached || How the grid works  */}
      {
        imageAttachments?.length > 0 && (
          <div className={cn('grid h-64 grid-flow-col gap-4',
            {
              // How the grid works
              'grid-cols-1 grid-rows-1': imageAttachments.length === 1,
              'grid-cols-2 grid-rows-1': imageAttachments.length === 2,
              'grid-cols-[minmax(150px,1fr),1fr] grid-rows-2  @md:grid-cols-3 @md:grid-rows-1': imageAttachments.length >= 3
            })}
          >
            {/* In the view we slice the Array and show the first 3 previews */}
            <Dialog>
              {imageAttachments.slice(0, 3).map((attachment: Attachment, i: number) => (
                <div
                  key={i}
                  className={cn('relative',
                    {
                      'row-span-2 @md:row-span-1': imageAttachments.length >= 3 && i === 0
                    }
                  )}
                >
                  <img
                    src={attachment.url}
                    className='h-full w-full rounded-xl object-cover'
                    alt={`Img attached n${i + 1}`}
                  />
                  {/* If +3 images are attached, then we use a button to access the complete view. Similar to Twitter old feed
                                - Update: access all of the previews in a dialog full screen view
                            */}
                  <DialogTrigger asChild>
                    <Button
                      variant='secondary'
                      className={cn('absolute inset-0 opacity-0', {
                        'text-xl opacity-70': imageAttachments.length > 3 && i === 2
                      })}
                    >
                      {imageAttachments.length > 3 && i === 2 ? `+${imageAttachments.length - 3}` : ''}
                    </Button>
                  </DialogTrigger>
                </div>
              ))}
              <DialogContent className='h-screen'>
                <div className='flex w-full snap-x snap-mandatory gap-4 overflow-x-scroll'>
                  {imageAttachments.map((attachment: Attachment, i: number) => (
                    <img
                      key={i}
                      src={attachment.url}
                      className='inline-block w-full shrink-0 grow snap-center object-scale-down'
                      alt={`Img attached n${i + 1}`}
                    />
                  ))}
                </div>
              </DialogContent>
            </Dialog>
          </div>
        )
      }
      {/* Likes, replies, time */}
      {/*
                -- Removed from Figma
                <div className='flex items-center gap-6'>
                    { REACTIONS_FEATURE &&
                        <>
                            // Heart Symbol by Core Line with text-color & fill to fully transform it
                            <div className='flex items-center gap-1 text-[#3B4B52]'>
                                <HeartsSymbol className={`h-6 w-6 ${numberLikes > 0 ? 'fill-[#F9595F] text-[#F9595F]' : ''}`} />
                                <span className=' text-sm font-medium '>{numberLikes}</span>
                            </div>
                        </>
                    }
                    {REPLY_FEATURE &&
                        <>
                            {numberComments && numberComments > 0 ? (
                                <div className='flex items-center gap-1 text-[#3B4B52]'>
                                    <ChatBubbleTypingOval className=' h-6 w-6' />
                                    <span className=' text-sm font-medium'>{numberComments}</span>
                                </div>
                            ) : (<Button variant='ghost' className='p-1 text-xs text-[#212121]'>Reply</Button>)}
                        </>
                    }
                    <p className=' text-xs/tight text-[#616161]'>
                        {formatShortDate(comment?.created_at)}
                    </p>
                </div>
            */}
    </section>
  )
}
