import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { renderString } from 'nunjucks'

import { getInitialsUser } from '#app/components/Chip/ChipUser'
import { useUIStories } from '#app/hooks/useUIStories'
import { ENVIROMENT } from '#app/store/enviroment'
import { ChatBubbleTypingOval, HorizontalMenuCircle, LinkChain } from '#app/v2/assets/Icons'
import { FiveStars } from '#app/v2/components/FiveStars'
import { PropagationStopper } from '#app/v2/components/PropagationStopper'
import { RateTaskDialog } from '#app/v2/components/RateTaskDialog'
import { Avatar, AvatarFallback, AvatarImage } from '#app/v2/components/ui/avatar'
import { Badge } from '#app/v2/components/ui/badge'
import { Button } from '#app/v2/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from '#app/v2/components/ui/dialog'
import Dropdown from '#app/v2/components/ui/dropdown-menu'
import { Separator } from '#app/v2/components/ui/separator'

import { TaskCardProps } from '../types'

interface Props {
  task: TaskCardProps
  onClickURL: string
}

export const DoneCard = ({ onClickURL, task }: Props) => {
  const { t } = useTranslation()
  const { rating, sector, profile, id, title, numberComments, numberFiles } = task
  const { isStoryMode } = useUIStories()
  const cardURL = `${renderString(onClickURL, {
    task_id: id
  })}`
  const copyURL = `${ENVIROMENT.front_url}${cardURL}`
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }
  return (
    <Link
      to={cardURL}
      state={{
        from: '/v2/tasks',
        to: cardURL
      }}
      className='block'
    >
      <li
        className='space-y-2 rounded-xl bg-white px-6 py-4 shadow'
        onClick={handleClick}
      >
        <div className='flex justify-between'>
          <div className=' flex items-center gap-2'>
            {profile
              ? (
                <Avatar className='h-6 w-6'>
                  <AvatarImage
                    src={profile?.avatar}
                    className='object-cover'
                  />
                  <AvatarFallback className='bg-[#E9F0FF] text-xs text-[#304985]'>{getInitialsUser(`${profile?.firstName} ${profile?.lastName}`)}</AvatarFallback>
                </Avatar>
              )
              : null}
            <Badge
              variant='secondary'
              className='h-fit border-0 bg-[#6BE7BE]/60 text-xs/4 text-[#304985]'
            >
              {sector ?? 'General'}
            </Badge>
          </div>
          <Dialog>
            <Dropdown.Menu>
              <Dropdown.Trigger asChild>
                <Button
                  variant='outline'
                  className='rounded-full border-0 p-1.5'
                  onClick={handleClick}
                >
                  <HorizontalMenuCircle className='h-6 w-6 text-[#70828A]' />
                </Button>
              </Dropdown.Trigger>
              <Dropdown.Content align='end'>
                <CopyToClipboard text={copyURL}>
                  <Dropdown.Item onClick={handleClick}>
                    {t('v2.tasks.option.copyLink', 'Copy link')}
                  </Dropdown.Item>
                </CopyToClipboard>
                {rating && isStoryMode && (
                  <Dropdown.Item
                    asChild
                  >
                    <DialogTrigger>
                      {t('v2.tasks.rating.edit', 'Edit rating')}
                    </DialogTrigger>
                  </Dropdown.Item>
                )}
              </Dropdown.Content>
              <DialogContent
                className='max-w-sm'
              >
                <DialogHeader className='text-sm sm:text-center'>
                  {t('v2.tasks.rating.qualifyExperience', 'Rate your experience with this task')}
                </DialogHeader>
                <RateTaskDialog rating={rating} />
              </DialogContent>
            </Dropdown.Menu>
          </Dialog>
        </div>
        <h2 className='text-sm font-semibold text-[#3B4B52]'>{title}</h2>
        {/* Description */}
        {/* <p className=' max-w-[34ch] text-xs text-[#454545]'>{task?.description}</p> */}
        {/* <PreviewRichContentV2 htmlContent={description} /> */}
        {/* FOOTER Icons */}
        {!isStoryMode && ( // Esto se va cuando Rating esté en prod (no existe más en Figma)
          <div className=' flex w-full justify-end gap-2 text-[#3B4B52]'>
            <span className='flex items-center gap-1 text-xs'>
              <ChatBubbleTypingOval className='h-3 w-3' />
              {numberComments}
            </span>
            <span className='flex items-center gap-1 text-xs'>
              <LinkChain className='h-3 w-3' />
              {numberFiles}
            </span>
          </div>
        )}
        {rating && isStoryMode
          ? (
            <>
              <Separator />
              <FiveStars rating={rating} />
              <p className='text-xs tracking-[0.02px] text-[#424242]'>{rating.explanation}</p>
            </>
          )
          : isStoryMode
            ? (
              <>
                <Separator />
                <div className='flex flex-wrap items-center justify-between gap-2'>
                  <div className='space-y-2'>
                    <p className='text-xs font-medium text-[#454545]'>{t('v2.tasks.rating.rateTitle', 'Rate this task!')}</p>
                    <FiveStars />
                  </div>
                  <Dialog>
                    <PropagationStopper>
                      <DialogTrigger
                        asChild
                      >
                        <Button
                          variant='secondary'
                        >
                          {t('v2.tasks.rating.rateIt', 'Rate this task')}
                        </Button>
                      </DialogTrigger>
                    </PropagationStopper>
                    <DialogContent
                      className='max-w-sm'
                    >
                      <DialogHeader className='text-sm sm:text-center'>
                        {t('v2.tasks.rating.qualifyExperience', 'Rate your experience with this task')}
                      </DialogHeader>
                      <RateTaskDialog />
                    </DialogContent>
                  </Dialog>
                </div>
              </>
            )
            : null }
      </li>
    </Link>
  )
}
