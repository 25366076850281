import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Grid, Tab, Typography } from '@mui/material'
import { Form, Formik } from 'formik'

import Button from '#app/components/Buttons/Button'
import { DialogConfirm } from '#app/components/Dialog/DialogConfirm'
import { DividerExtended } from '#app/components/Divider'
import FormInput from '#app/components/FormInput/FormInput'
import FormSelect from '#app/components/FormSelect/FormSelect'
import InputWithChips from '#app/components/InputWithChips/InputWithChips'
import InputWithPlaces from '#app/components/InputWithPlaces/InputWithPlaces'
import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import { AudienceConditions } from '#app/feature/Audience/AudienceConditions'
import { useEntityAudiences } from '#app/hooks/useEntityAudiences'
import { useEntitySequence } from '#app/hooks/useEntitySequence'
import { useEntityTaskTemplates } from '#app/hooks/useEntityTaskTemplates'
import { ownerServices } from '#app/hooks/useOwners'
import { Sequence } from '#app/types'
import { createSequenceAndAudienceSchema, createSequenceSchema } from '#app/utils/validationSchemas'

interface Props {
  open: boolean
  setOpen: (val: boolean) => void
}
const initialValues = {
  title: '',
  template_id: '',
  audience_id: ''
}

export function NewBulkTask({ open, setOpen }: Props) {
  const { queryGetTaskTemplates, queryGetTaskTemplatesData } = useEntityTaskTemplates()
  const { mutationCreateSequence, mutationCreateSequenceUtils } = useEntitySequence({
    variables: {
      type: 'bulk-tasks'
    }
  })
  const { queryGetAudiences, queryGetAudiencesData } = useEntityAudiences()

  const [confirmAction, setConfirmAction] = useState(false)
  const [sequenceTmp, setSequenceTmp] = useState<any>()

  const [audienceMethod, setAudienceMethod] = useState('existingAudience')
  const [audienceSelectedTmp, setAudienceSelectedTmp] = useState<Sequence.Audience | null>(null)

  const templatesOptions = useMemo(() => {
    if (!queryGetTaskTemplatesData?.task_templates) return []
    return queryGetTaskTemplatesData?.task_templates
      .filter((template) => template.status !== 'deleted')
      .map((template) => {
        const title = `${template.title} - ${template.content?.en?.title ? '(EN)' : ''} ${template.content?.es?.title ? '(ES)' : ''}`
        return ({
          text: title,
          value: template.id
        })
      })
  }, [queryGetTaskTemplatesData])

  const audienceOptions = useMemo(() => {
    if (!queryGetAudiencesData?.audiences) return []
    return queryGetAudiencesData?.audiences
      .filter((audience) => audience.status !== 'deleted' && audience.metadata?.source === 'new-audience-altbo-form')
      .map((audience) => ({
        text: audience.title,
        value: audience.id
      }))
  }, [queryGetAudiencesData])

  const relevantFiltersSelected = useMemo(() => {
    if (audienceMethod === 'existingAudience') {
      return !!(audienceSelectedTmp?.conditions?.cities || audienceSelectedTmp?.conditions?.services)
    }
    else if (audienceMethod === 'newAudience') {
      return !!(sequenceTmp?.cities || sequenceTmp?.services)
    }
  }, [audienceSelectedTmp, sequenceTmp, audienceMethod])

  const initView = useCallback(async () => {
    await queryGetTaskTemplates()
    await queryGetAudiences()
  }, [queryGetAudiences, queryGetTaskTemplates])

  const showDialogConfirm = useCallback((data: any) => {
    setSequenceTmp(data)
    setConfirmAction(true)

    return true
  }, [])

  const closeDrawer = useCallback(() => {
    setOpen(false)
    setAudienceMethod('existingAudience')
    setAudienceSelectedTmp(null)
  }, [setOpen])

  const previewAudience = useCallback((audienceId: string) => {
    if (!queryGetAudiencesData?.audiences) return null
    const res = queryGetAudiencesData.audiences.filter((audience) => {
      if (audience?.id === audienceId) return true
      return false
    })

    if (res?.[0]) setAudienceSelectedTmp(res[0])
  }, [queryGetAudiencesData?.audiences])

  const createSequence = useCallback(async (data: any) => {
    const cities = data?.cities?.length
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      ? data.cities?.map((city: any) => {
        if (city) {
          return {
            place_id: city.place_id,
            coordinates: city.coordinates,
            description: city.description
          }
        }
      })
      : undefined

    const payload: any = {
      title: data.title,
      payload: {
        template_id: data.template_id
      },
      type: 'bulk-tasks'
    }

    if (audienceMethod === 'existingAudience') {
      payload.audience_id = data.audience_id
    }
    else {
      payload.audience_conditions = {
        cities,
        languages: data.languages,
        services: data.services,
        user_role: ['owners-user']
      }
    }

    await mutationCreateSequence({
      variables: payload
    })

    closeDrawer()

    return true
  }, [audienceMethod, closeDrawer, mutationCreateSequence])

  useEffect(() => {
    void initView()
  }, [initView])

  return (
    <>
      <DialogConfirm
        isLoading={mutationCreateSequenceUtils.loading}
        open={confirmAction}
        setOpen={setConfirmAction}
        texts={{
          title: 'Do you want to send this sequence?',
          description: 'No further step is needed here, if you accept, this will get sent to the audience selected.',
          warning: relevantFiltersSelected ? undefined : 'You did not select any relevant filter, that means many people were sent this task. This action cannot be undone, you have to be sure before creating this sequence.'
        }}
        onConfirm={async () => await createSequence(sequenceTmp)}
      />
      <RightSideBar
        closeHandler={() => closeDrawer()}
        isOpen={open}
      >
        <>
          <Typography variant='h4'>New Sequence</Typography>
          <Box mt={4}>
            <Formik
              initialValues={initialValues}
              validationSchema={audienceMethod === 'existingAudience' ? createSequenceSchema : createSequenceAndAudienceSchema}
              onSubmit={(data: any) => {
                showDialogConfirm(data)
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Box sx={{
                    m: 1
                  }}
                  >
                    <Box>
                      <Grid
                        container
                        spacing={1}
                        mb={3}
                        mt={1}
                        width='100%'
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                        >
                          <FormInput
                            label='Title (Private)'
                            name='title'
                            type='text'
                            placeholder='Write a short title to understand the sequence'
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                        >
                          <FormSelect
                            label='Template'
                            name='template_id'
                            options={templatesOptions}
                          />
                          <DividerExtended />
                        </Grid>
                        <TabContext value={audienceMethod}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                          >
                            <TabList
                              aria-label='Audience Method'
                              onChange={(event: SyntheticEvent, newValue: string) => {
                                setAudienceMethod(newValue)
                              }}
                            >
                              <Tab
                                label='Existing audience'
                                value='existingAudience'
                              />
                              {/* <Tab label="New audience" value="newAudience" disabled/> */}
                            </TabList>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                          >
                            <TabPanel value='existingAudience'>
                              <FormSelect
                                label='Audience'
                                name='audience_id'
                                options={audienceOptions}
                                onChange={(e) => {
                                  previewAudience(e.target.value)
                                }}
                              />
                              {
                                audienceSelectedTmp
                                && <AudienceConditions audience={audienceSelectedTmp} />
                              }
                            </TabPanel>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                          >
                            <TabPanel value='newAudience'>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                              >
                                <InputWithPlaces
                                  multiple
                                  label='Cities'
                                  name='cities'
                                  types={['locality']}
                                  limitTags={5}
                                  fields={undefined}
                                  countries={undefined}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                              >
                                <InputWithChips
                                  multiple
                                  name='services'
                                  label='Services'
                                  options={ownerServices}
                                  optionLabelHandler={undefined}
                                  limit={undefined}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                              >
                                <InputWithChips
                                  multiple
                                  name='languages'
                                  label='Language'
                                  options={['EN', 'ES']}
                                  optionLabelHandler={undefined}
                                  limit={undefined}
                                />
                              </Grid>
                            </TabPanel>
                          </Grid>
                        </TabContext>
                      </Grid>
                    </Box>
                    <Button
                      sx={{
                        ml: 1
                      }}
                      primary={false}
                      disabled={isSubmitting}
                      onClick={() => closeDrawer()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type='submit'
                      style={{
                        float: 'left'
                      }}
                      disabled={isSubmitting}
                    >
                      Create Sequence
                    </Button>
                  </Box>

                </Form>
              )}
            </Formik>
          </Box>
        </>
      </RightSideBar>
    </>
  )
}
