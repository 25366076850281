/* eslint-disable @typescript-eslint/naming-convention */
import { useTranslation } from 'react-i18next'
import { capitalize } from 'lodash'

interface Map {
  [key: string]: string
}

export const useCustomerEventsMap: () => Map = () => {
  const { t } = useTranslation()

  const eventsMap = {
    unscheduled: t('jobs.statusMap.unscheduled'),
    'ongoing-connected': t('jobs.statusMap.ongoing-connected'),
    'ongoing-no-answer': t('jobs.statusMap.ongoing-no-answer'),
    'ongoing-rescheduling': t('jobs.statusMap.ongoing-rescheduling'),
    'appointment-booked': t('jobs.statusMap.appointment-booked'),
    'appointment-given': t('jobs.statusMap.appointment-given'),
    'not-interested-no-project': t('jobs.statusMap.not-interested-no-project'),
    'not-interested-prices': t('jobs.statusMap.not-interested-prices'),
    'credit-refund-to-submit': t('jobs.statusMap.credit-refund-to-submit'),
    'credit-refund-submitted': t('jobs.statusMap.credit-refund-submitted'),
    spam: 'Spam',
    other: capitalize(t('generic.other'))
  }

  return eventsMap
}

export const useLeadsMap: () => Map = () => {
  const { t } = useTranslation()

  const leadsMap = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'home-advisor': 'Home Advisor',
    google: 'Google',
    thumbstack: 'Thumbstack',
    yelp: 'Yelp',
    porch: 'Porch',
    facebook: 'Facebook',
    'google-local-services': 'Google Local Services',
    'google-my-business': 'Google My Business',
    referral: capitalize(t('generic.referral')),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'in-person': capitalize(t('generic.inPerson')),
    other: capitalize(t('generic.other')),
    web: capitalize(t('generic.web'))
  }

  return leadsMap
}
