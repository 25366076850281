import { useTranslation } from 'react-i18next'

import { Chip } from '#app/components/Chip'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

export function EstimateChip() {
  const { t } = useTranslation()
  const isDesktop = useIsDesktopSize()

  return (
    <Chip.Basic
      size={!isDesktop ? 'small' : 'medium'}
      label={t('propertymanagers.estimateChip', 'Estimate')}
    />
  )
}
