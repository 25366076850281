import { SyntheticEvent, useContext, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import Box from '@mui/material/Box'

import AppContext from '#app/contexts/AppContext'
import { useAppContext } from '#app/contexts/AppContext/useAppContext'

import { AlertsDemo } from './AlertsDemo'
import { ButtonsDemo } from './ButtonsDemo'
import { ChipsDemo } from './ChipsDemo'
import { DialogDemo } from './DialogsDemo'
import { DrawersDemo } from './DrawersDemo'
import { InputsDemo } from './InputsDemo'
import { TablesDemo } from './TablesDemo'
import { ThemingDemo } from './ThemingDemo'
import { TypographyDemo } from './TypographyDemo'

import './vs2015.css'

export function ShowcaseComponents() {
  const { appState } = useContext(AppContext)
  const { V1_SET_LOADING } = useAppContext()

  const [searchParams, setSearchParams] = useSearchParams()

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setSearchParams({
      tab: newValue
    })
  }

  useEffect(() => {
    if (appState.isLoading) {
      setTimeout(() => {
        V1_SET_LOADING(false)
      }, 500)
    }
  }, [V1_SET_LOADING, appState.isLoading])

  const sectionTab = useMemo(() => {
    return searchParams.get('tab') ?? 'buttons'
  }, [searchParams])

  return (
    <Box
      p={4}
      mt={6}
      width='100%'
    >
      <TabContext value={sectionTab}>
        <Box>
          <TabList onChange={handleChange}>
            <Tab
              label='Theming'
              value='theming'
            />
            <Tab
              label='Buttons'
              value='buttons'
            />
            <Tab
              label='Drawers'
              value='drawers'
            />
            <Tab
              label='Dialogs'
              value='dialogs'
            />
            <Tab
              label='Chips'
              value='chips'
            />
            <Tab
              label='Tables'
              value='tables'
            />
            <Tab
              label='Alerts'
              value='alerts'
            />
            <Tab
              label='Typography'
              value='typography'
            />
            <Tab
              label='Forms'
              value='forms'
            />
          </TabList>
        </Box>
        <TabPanel value='buttons'><ButtonsDemo /></TabPanel>
        <TabPanel value='drawers'><DrawersDemo /></TabPanel>
        <TabPanel value='dialogs'><DialogDemo /></TabPanel>
        <TabPanel value='chips'><ChipsDemo /></TabPanel>
        <TabPanel value='tables'><TablesDemo /></TabPanel>
        <TabPanel value='theming'><ThemingDemo /></TabPanel>
        <TabPanel value='alerts'><AlertsDemo /></TabPanel>
        <TabPanel value='typography'><TypographyDemo /></TabPanel>
        <TabPanel value='forms'><InputsDemo /></TabPanel>
      </TabContext>
    </Box>
  )
}
