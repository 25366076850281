import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'

import { useAdmins } from '#app/hooks/useAdmins'
import { useUIStories } from '#app/hooks/useUIStories'
import useUploadResource from '#app/hooks/useUploadResource'
import { Comments } from '#app/operations/autogenerate/schemas'
import { GetBusinessOwnerTasksQuery } from '#app/operations/tasks/tasks.queries.generated'
import { ENVIROMENT } from '#app/store/enviroment'
import { Comment } from '#app/types'
import { UserType } from '#app/types/user'
import { Delete1, MailSendEmailMessage, Paperclip1 } from '#app/v2/assets/Icons'
import Images from '#app/v2/assets/Images'
import { RateTaskDialog } from '#app/v2/components/RateTaskDialog'
import { Button } from '#app/v2/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from '#app/v2/components/ui/dialog'
import { Input } from '#app/v2/components/ui/input'
import { cn } from '#app/v2/libs/utils'
import { TaskActivity } from '#app/v2/pages/TaskDetail/components/TaskActivity'

import { useInputFocus } from '../store'

interface ThreadProps {
  task?: GetBusinessOwnerTasksQuery['user_tasks'][0]
  comments?: Comments[]
  createComment?: (comment: string, attachments?: Comment.Attachment[]) => void
  loading?: boolean
  author?: UserType
  taskStatus?: string
  editComment?: (commentId: string, text: string) => void
  deleteComment?: (commentId: string) => void
  rating?: {
    score: string
    explanation: string
  }
}

const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/tiff', 'image/webp']

export function Thread({
  task,
  comments,
  loading,
  taskStatus,
  author,
  rating,
  createComment,
  deleteComment,
  editComment,
}: ThreadProps) {
  const { getAdminNameByLinearUserId } = useAdmins()
  const { uploadResource } = useUploadResource()
  const { isStoryMode } = useUIStories()
  const [disabled, setDisabled] = useState(false)
  const [attachments, setAttachments] = useState<Comment.Attachment[]>([])
  const [attachmentURLs, setAttachmentURLs] = useState<string[]>([])

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && author?.id) {
      const files = Array.from(e.target.files)
      setDisabled(true)
      const filesList: Comment.Attachment[] = await Promise.all(
        files.map(async (file) => {
          const path: string = await uploadResource(author.id, 'comments', file)
          return {
            url: path,
            filename: file.name,
            type: file.type,
          }
        }),
      )
      setDisabled(false)
      setAttachments(filesList)
    }
  }

  const onImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newImages = [...Array.from(e.target.files)]

      const newImageUrls: string[] = newImages.map((image) =>
        allowedImageTypes.includes(image.type)
          ? URL.createObjectURL(image)
          : Images.GenericAttachment,
      )

      setAttachmentURLs(newImageUrls)
      handleFileChange(e)
    }
  }

  const handleFilterAttachment = (indexAttachment: number) => {
    setAttachments((state) => state.filter((attachment, i) => i !== indexAttachment))
    setAttachmentURLs((state) => state.filter((attachment, i) => i !== indexAttachment))
  }

  const resetViewport = () => {
    setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top: document.body.scrollHeight
      })
    }, 50)
  }

  const { setFocusOff, setFocusOn } = useInputFocus()

  const generateComment = async (comment: string) => {
    if (createComment) {
      await createComment(comment, attachments)
    }
  }

  const { t } = useTranslation()

  return (
    <>
      {/* Activity */}
      <h3 className='mb-1.5 text-sm/tight font-semibold text-[#454545]'>{t('v2.tasks.activity.title', 'Activity')}</h3>
      <section
        className=' space-y-6'
        id='activity'
      >
        {comments && comments?.length > 0
          ? comments?.map((comment, i) => (
            <TaskActivity
              key={i}
              comment={comment}
              isAuthor={isStoryMode ? i % 2 === 0 : author?.id === comment?.created_by}
              urlChat={isStoryMode && i % 2 === 1 ? 'asdasdasdasd' : comment?.metadata?.chat_message_id}
              editComment={editComment}
              deleteComment={deleteComment}
            />
          ))
          : comments?.length === 0
            ? (
              <div className=' space-y-3 py-8 text-center'>
                <h4 className='text-base/5 font-semibold text-[#304985]'>{t('user_tasks.startConversation.title', 'Start a conversation')}</h4>
                <p className='text-sm/4 text-[#454545] [text-wrap:balance]'>{t('user_tasks.startConversation.description', 'Start by asking a question or leave a message to your copilot in this task')}</p>
              </div>
            )
            : <>{t('v2.tasks.activity.error', 'Error loading comments')}</>}
        {/* RATING Review */}
        {rating && isStoryMode
          ? (
            <TaskActivity
              isAuthor
              rating={rating}
              // Chris/Joan
              // This component doesn't receive a 'comment' === doesnt have a profile picture (user for avatar or email for gravatar).
              // TODO?: consume profile context?
            />
          )
          : (taskStatus === 'completed' || taskStatus === 'admin_completed') && isStoryMode
            ? (
              <Dialog>
                <DialogTrigger asChild>
                  <Button className='w-full bg-[#E9F0FF] text-[#304985] hover:bg-[#304985]/25'>{t('v2.tasks.rating.rateIt', 'Rate this task')}</Button>
                </DialogTrigger>
                <DialogContent className='max-w-sm'>
                  <DialogHeader className='text-sm sm:text-center'>
                    {t('v2.tasks.rating.qualifyExperience', 'Rate your experience with this task')}
                  </DialogHeader>
                  <RateTaskDialog />
                </DialogContent>
              </Dialog>
            )
            : null}
        {/* Preview Files to send */}
        {attachmentURLs && attachmentURLs.length > 0
          ? (
            <div className='flex w-full gap-3 overflow-x-auto pt-2'>
              {attachmentURLs.map((localImage, i) => (
                <div
                  key={localImage}
                  className='relative aspect-square h-12 w-12'
                >
                  <img
                    src={localImage}
                    alt='not found'
                    className='h-full w-full rounded-xl object-cover'
                  />
                  <Button
                    size='icon'
                    className='absolute -right-1 -top-1 h-fit w-fit p-1'
                    onClick={() => handleFilterAttachment(i)}
                  >
                    <Delete1 className='h-2 w-2' />
                  </Button>
                </div>
              ))}
            </div>
          )
          : null}
        {/* BO Add comment */}
        {
          (taskStatus !== 'completed' && taskStatus !== 'admin_completed')
          && (
            <div className='flex items-center gap-4'>
              {/*
              <ProfileAvatarDetailed
                firstName={author?.first_name}
                lastName={author?.last_name}
                email={author?.email}
                profilePictureUrl={author?.profile_picture_url}
                onlyAvatar
              />
            */}

              <Formik
                initialValues={{
                  newComment: '',
                  newFile: '',
                  // newPhotos: ''
                }}
                onSubmit={async (values, { resetForm }) => {
                  await generateComment(values.newComment)
                  resetForm()
                  setAttachmentURLs([])
                  setAttachments([])
                }}
              >

                {(props) => {
                  const {
                    values,
                  } = props

                  return (
                    <Form className='flex w-full items-end gap-2.5'>
                      <div className={cn('relative grow', {
                        'animate-pulse pointer-events-none': loading
                      })}
                      >
                        <div
                          data-replicated-value={values.newComment}
                          className={cn('grid rounded-md bg-[#EFEFEF] px-5 py-4 pr-12 after:invisible after:max-h-[200px] after:whitespace-pre-wrap after:break-all after:[grid-area:1/1/2/2] ',
                            'after:!content-[attr(data-replicated-value)]'
                          )}
                        >
                          <Input
                            as='textarea'
                            autoComplete='off'
                            rows={1}
                            disabled={loading}
                            className={cn('block h-auto max-h-[200px] resize-none whitespace-pre-wrap break-words rounded-none border-0 bg-transparent p-0 text-base text-[#454545] [grid-area:1/1/2/2] placeholder:font-medium placeholder:text-[#8097A1] focus-visible:ring-0 focus-visible:ring-offset-0', {
                              'placeholder:invisible': loading
                            })}
                            name='newComment'
                            placeholder={t('v2.tasks.newMessage', 'Message')}
                            onFocus={setFocusOn}
                            onBlur={() => {
                              setTimeout(() => { // It will always lose focus when clicking the other inputs. This setTimeout allows the click to other elements to continue.
                                setFocusOff()
                                resetViewport()
                              }, 100) // 50 is too short for Desktop dont know why but its ok for touch
                            }}
                          />
                        </div>
                        <div className='absolute inset-y-0 bottom-2.5 right-2 flex cursor-pointer items-end gap-2'>
                          {/* FILE */}
                          <Button
                            type='button'
                            disabled={loading}
                            size='icon'
                            className='h-9 w-9 rounded-full  bg-[#EFEFEF] hover:bg-[#EFEFEF] group-focus-within:invisible '
                          >
                            <>
                              <Paperclip1 className='h-6  w-6 text-[#8097A1]' />
                              <Input
                                multiple
                                value={values.newFile}
                                name='newFile'
                                type='file'
                                className='absolute z-0  h-9 w-9 rounded-full opacity-0 file:pointer-events-none'
                                onChange={onImageChange}
                                onBlur={resetViewport}
                              />
                            </>
                          </Button>
                          {/* PHOTO */}
                          {/* <Button
                        type='button'
                        disabled={loading}
                        size='icon'
                        className='h-9 w-9 cursor-pointer rounded-full bg-[#EFEFEF] hover:bg-[#EFEFEF]'
                      >
                        <>
                          <Camera1 className='h-6 w-6 text-[#8097A1]' />
                          <Input
                            multiple
                            name='newPhotos'
                            type='file'
                            capture='environment'
                            className='absolute z-0 h-9 w-9 rounded-full opacity-0'
                            onChange={onImageChange}
                            onBlur={resetViewport}
                          />
                        </>
                      </Button> */}
                        </div>
                      </div>
                      <div className='py-2'>
                        <Button
                          disabled={disabled}
                          type='submit'
                          className={cn('flex rounded-full bg-[#304985] p-2.5', {
                            'bg-[#EFEFEF] animate-pulse pointer-events-none': loading,
                          })}
                        >
                          <MailSendEmailMessage className={cn('h-6 w-6 text-white', {
                            'text-[#8097A1] ': loading,
                          })}
                          />
                        </Button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          )
        }
        {/* BO Contact support */}
        {
          (taskStatus === 'completed' || taskStatus === 'admin_completed')
          && (
            <div className='flex items-center justify-between gap-2.5 rounded bg-[#F5F5F5] p-2.5'>
              <span className='inline-block shrink text-sm font-medium text-[#454545]'>
                {taskStatus === 'completed'
                  ? t('v2.tasks.activity.taskDone.bo', 'You can’t comment on this task because you marked it as complete. Please reopen it if you want to make comments.')
                  : t('v2.tasks.activity.taskDone.admin', {
                    creator: getAdminNameByLinearUserId(task?.linearIssueLinked?.creator_id, true) || task?.userByCreatedBy.first_name
                  })}
              </span>
              <Button
                className={cn('whitespace-nowrap rounded-xl p-1 px-1.5 text-xs/5',
                  {
                    hidden: taskStatus === 'completed'
                  }
                )}
                onClick={() => {
                  window.open(`${ENVIROMENT.support_whatsapp_link}`, '_blank')
                }}
              >
                {t('v2.tasks.activity.contactSupport', 'Contact support')}
              </Button>
            </div>
          )
        }
      </section>
    </>
  )
}
