import * as Yup from 'yup'

export const adminSchema = Yup
  .object()
  .shape({
    first_name: Yup
      .string()
      .required('This is a required field'),
    last_name: Yup
      .string()
      .required('This is a required field'),
    email: Yup
      .string()
      .email('Invalid email')
      .required('This is a required field'),
    password: Yup
      .string()
      .required('This is a required field')
  })

export const ownerSchema = Yup
  .object()
  .shape({
    email: Yup
      .string()
      .email('Invalid email')
      .required('This is a required field'),
    signup_fee: Yup
      .number('Invalid number')
      .required('This is a required field')
      .min(0, 'Minimum fee is $0')
  })

export const createAudienceSchema = Yup
  .object()
  .shape({
    title: Yup
      .string()
      .required('This is a required field'),
    industries: Yup
      .array(),
    services: Yup
      .array(),
    languages: Yup
      .array(),
    cities: Yup.array().of(
      Yup.object().shape({
        description: Yup.string(),
        matched_substrings: Yup.array(),
        place_id: Yup.string(),
        reference: Yup.string(),
        structured_formatting: Yup.object({
          main_text: Yup.string(),
          main_text_matched_substrings: Yup.array(),
          secondary_text: Yup.string()
        }).nullable(),
        terms: Yup.array(),
        types: Yup.array()
      }))
  })

export const createSequenceSchema = Yup
  .object()
  .shape({
    title: Yup
      .string()
      .required('This is a required field'),
    template_id: Yup
      .string()
      .required('This is a required field'),
    audience_id: Yup
      .string()
      .required('This is a required field')
  })

export const createSequenceAndAudienceSchema = Yup
  .object()
  .shape({
    title: Yup
      .string()
      .required('This is a required field'),
    template_id: Yup
      .string()
      .required('This is a required field'),
    industries: Yup
      .array(),
    services: Yup
      .array(),
    languages: Yup
      .array(),
    cities: Yup.array().of(
      Yup.object().shape({
        description: Yup.string(),
        matched_substrings: Yup.array(),
        place_id: Yup.string(),
        reference: Yup.string(),
        structured_formatting: Yup.object({
          main_text: Yup.string(),
          main_text_matched_substrings: Yup.array(),
          secondary_text: Yup.string()
        }).nullable(),
        terms: Yup.array(),
        types: Yup.array()
      }))
  })

export const createTaskSchema = Yup
  .object()
  .shape({
    title: Yup
      .string()
      .required('This is a required field'),
    short_description: Yup
      .string(),
    loom_video: Yup
      .string()
      .url()
      .typeError('Loom must to be a URL'),
    cognitoforms_key: Yup
      .string(),
    cognitoforms_dataform: Yup
      .string(),
    tag_slug: Yup
      .string()
      .required('This is a required field')
  })

export const createTaskTemplateSchema = Yup
  .object()
  .shape({
    title: Yup
      .string()
      .required('This is a required field'),
    short_description: Yup
      .string(),
    loom_video: Yup
      .string()
      .url()
      .typeError('Loom must to be a URL'),
    cognitoforms_key: Yup
      .string(),
    cognitoforms_dataform: Yup
      .string()
  })
