import { useTranslation } from 'react-i18next'

import { useUIStories } from '#app/hooks/useUIStories'
import { GetBusinessOwnerTasksQuery } from '#app/operations/tasks/tasks.queries.generated'
import { ENVIROMENT } from '#app/store/enviroment'
import { ButtonPlay } from '#app/v2/assets/Icons'
import Images from '#app/v2/assets/Images'
import { PreviewRichContentV2 } from '#app/v2/components/PreviewRichContent'
import { Badge } from '#app/v2/components/ui/badge'
import { Button } from '#app/v2/components/ui/button'

interface Props {
  task?: GetBusinessOwnerTasksQuery['user_tasks'][0]
}

export function Content({ task }: Props) {
  const { isStoryMode } = useUIStories()
  const { t } = useTranslation()

  return (
    <div className='mb-10'>
      <h1 className='text-xl/6 font-semibold text-[#304985]'>{task?.title}</h1>
      <div className='mb-3 mt-2 flex items-center justify-between'>
        <Badge
          variant='secondary'
          className='bg-[#6BE7BE]/60 text-sm/4 text-[#304985]'
        >
          {task?.tag_slug}
        </Badge>
        <Button
          variant='link'
          className='p-0 text-xs font-medium text-[#BDBFBE] underline'
          onClick={() => window.open(`${ENVIROMENT.support_whatsapp_link}`, '_blank')}
        >
          {t('v2.tasks.help', 'Need help with this task?')}
        </Button>
      </div>
      <h2 className='text-base/6 font-semibold text-zinc-700'>{t('v2.tasks.whyThisMatters', 'Why this matters')}</h2>

      <div className='space-y-4 overflow-auto break-words text-[#424242]'>
        <PreviewRichContentV2
          htmlContent={task?.description_html}
          markdownContent={task?.linearIssueLinked?.description}
        />
      </div>

      {/* Video player */}
      {
        isStoryMode
        && (
          <div className='relative mt-3'>
            <video
              poster={Images.MichVideoPoster}
              className='aspect-video w-full rounded-2xl object-cover object-[50%_19.5%]'
            >
            </video>
            {/* border opacity not working with background */}
            <div className='absolute left-[50%] top-[50%] z-10 flex aspect-square translate-x-[-50%] translate-y-[-50%] items-center justify-center rounded-full bg-white/50 p-1.5'>
              <Button
                variant='secondary'
                className='z-20 aspect-square rounded-full bg-white'
              >
                <ButtonPlay className='h-5 w-5 fill-black' />
              </Button>
            </div>
          </div>
        )
      }
    </div>
  )
}
