import { useEffect, useState } from 'react'
import { MdChat, MdDoNotDisturbOn, MdHourglassEmpty, MdSnooze } from 'react-icons/md'
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material'
import { DefaultGenerics, StreamChat } from 'stream-chat'

export enum StatusChannelEnum {
  Open = 'open',
  Closed = 'closed',
  Snoozed = 'snoozed',
  Waiting = 'waiting'
}

export function StatusChannel({ currentStatus, channelId, chatClient }: { currentStatus: StatusChannelEnum, channelId: string, chatClient: StreamChat<DefaultGenerics> }) {
  const [status, setStatus] = useState<StatusChannelEnum>(currentStatus)
  const [errorText, setErrorText] = useState<string | undefined>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setStatus(currentStatus)
  }, [currentStatus])

  const handleStatusChange = async (event: SelectChangeEvent<string>) => {
    const newStatus = event.target.value as StatusChannelEnum
    setLoading(true)
    try {
      const channel = chatClient.channel('messaging', channelId)
      await channel.updatePartial({
        set: {
          owners_channel_status: newStatus,
        }
      })
      setStatus(newStatus)
      setLoading(false)
    }
    catch (err) {
      setStatus(currentStatus)
      setLoading(false)
    }
    setErrorText(undefined)
  }

  const statusIcons = {
    [StatusChannelEnum.Open]: <MdChat />,
    [StatusChannelEnum.Closed]: <MdDoNotDisturbOn />,
    [StatusChannelEnum.Snoozed]: <MdSnooze />,
    [StatusChannelEnum.Waiting]: <MdHourglassEmpty />
  }

  const options = [
    {
      value: StatusChannelEnum.Open,
      text: 'Open',
      icon: statusIcons[StatusChannelEnum.Open]
    },
    {
      value: StatusChannelEnum.Closed,
      text: 'Closed',
      icon: statusIcons[StatusChannelEnum.Closed]
    },
    {
      value: StatusChannelEnum.Snoozed,
      text: 'Snoozed',
      icon: statusIcons[StatusChannelEnum.Snoozed]
    },
    {
      value: StatusChannelEnum.Waiting,
      text: 'Waiting',
      icon: statusIcons[StatusChannelEnum.Waiting]
    }
  ]

  return (
    <Stack
      direction='row'
      justifyContent='center'
      alignItems='center'
      spacing={1}
    >
      {status
      && (
        <Box mt={1}>
          {statusIcons[status]}
        </Box>
      )}
      <FormControl
        fullWidth
        error={Boolean(errorText)}
        variant='standard'
        size='small'
      >
        <InputLabel id='status-channel-label'>Channel Status</InputLabel>
        <Select
          disabled={loading}
          labelId='status-channel-label'
          value={status}
          error={Boolean(errorText)}
          onChange={handleStatusChange}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.text}
            </MenuItem>
          ))}
        </Select>
        {errorText && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    </Stack>
  )
}
