import { useEffect, useMemo, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { SxProps } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import ChipMUI from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'

import { User } from '#app/types'
import { CustomerInput } from '#app/types/customer'

export const getInitialsUserFull = (first?: string, last?: string) => {
  return getInitialsUser(`${first ?? '?'} ${last ?? '?'}`)
}

export const getInitialsUser = (fullName: string) => {
  if (!fullName) return ''

  const nameParts = fullName.split(' ')
  let initials = ''

  for (let i = 0; i < 2 && i < nameParts.length; i++) {
    const namePart = nameParts[i]
    if (namePart) {
      initials += namePart[0].toUpperCase()
    }
  }

  return initials
}

export function ChipUser({ user, size, sx }: { user: User.UserType | CustomerInput, sx?: SxProps, size?: 'small' | 'medium' | undefined }) {
  const [showEmail, setShowEmail] = useState(false)
  const [copied, setCopied] = useState(false)
  const { t } = useTranslation()

  const userData = useMemo(() => {
    if (user?.email === 'system@joinowners.com') {
      return {
        ...user,
        first_name: 'Tony',
        last_name: 'Owners',
        email: 'tony@joinowners.com'
      }
    }
    return user
  }, [user])

  const fullName: string = useMemo(() => {
    let res = ''
    if (userData.first_name) res += userData.first_name
    if (userData.last_name) res += ' ' + userData.last_name
    return res
  }, [userData])

  const initialsUser: string = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return getInitialsUser(`${userData?.first_name} ${userData?.last_name}`)
  }, [userData])

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    }
  }, [copied])

  // @ts-ignore
  const avatarVariant = userData?.profile_picture_url
    ? (
      <Avatar
        alt={fullName}
        // @ts-ignore
        src={userData.profile_picture_url}
      />
    )
    : <Avatar>{initialsUser || '?'}</Avatar>

  return (
    <CopyToClipboard text={userData?.email ?? ''}>
      <Tooltip title={copied ? t('preferences.emailCopied') : null}>
        <ChipMUI
          variant='outlined'
          sx={sx}
          avatar={avatarVariant}
          label={showEmail ? userData?.email : fullName || userData?.email}
          size={size ?? 'medium'}
          color='default'
          onClick={() => {
            setShowEmail(!showEmail)
            setCopied(true)
          }}
        />
      </Tooltip>
    </CopyToClipboard>
  )
}
