import { useContext, useEffect, useState } from 'react'
import { useAnalytics } from 'use-analytics'

import ProfileContext from './ProfileContext'

interface Props {
  children: JSX.Element
}

export const SessionDetectContext = ({ children }: Props) => {
  const { identify, track } = useAnalytics()
  const { getProfile } = useContext(ProfileContext)

  const [visible, setVisibility] = useState<boolean>(false)

  document.addEventListener('visibilitychange', function () {
    if (document.visibilityState === 'hidden' && visible) {
      setVisibility(false)
    }
    else if (document.visibilityState === 'visible' && !visible) {
      setVisibility(true)
    }
  })

  useEffect(() => {
    if (getProfile?.user && !visible) {
      setVisibility(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (getProfile?.user && visible && getProfile?.user?.role?.slug !== 'owners-admin') {
      void identify(getProfile?.user?.id ?? '', {
        email: getProfile?.user?.email
      }, () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-floating-promises
        void track('user-session-started', {
          userEmail: getProfile?.user?.email
        })
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  return (
    <>
      {children}
    </>
  )
}

export default SessionDetectContext
