import { cloneElement } from 'react'
import { useLocation, useOutlet } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

export const AnimatedOutlet = () => {
  const location = useLocation()
  const element = useOutlet()

  return (
    <AnimatePresence mode='wait'>
      {element && cloneElement(element, {
        key: location.pathname
      })}
    </AnimatePresence>
  )
}
