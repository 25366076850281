import { Navigate, Outlet } from 'react-router-dom'

import { RouteOwners } from '#app/routes/useRoutes'

import { CoreActivityCenter } from '../pages/ActivityCenter/page'
import ChatList from '../pages/ChannelList/page'
import ChannelMessages from '../pages/ChannelMessages/page'
import { ProfileInformation } from '../pages/EditProfile'
import { CoreHome } from '../pages/Home'
import Messages from '../pages/Messages/page'
import { Profile } from '../pages/MyProfile/page'
import { CoreSearch } from '../pages/Search'
import { CoreTaskDetail } from '../pages/TaskDetail'
import { CoreTaskList } from '../pages/TaskList'
import { CoreTemplateCallback } from '../pages/TemplateCallback'
import UserWizard from '../pages/Wizard/page'

export const routesV2: RouteOwners[] = [
  {
    path: '',
    element: <Outlet />,
    role: ['owners-user'],
    status: ['signed-up', 'onboarded'],
    children: [
      {
        index: true,
        element: (
          <Navigate
            replace
            to='/v2/home'
          />
        )
      },
      {
        path: '/v2/home',
        element: <CoreHome />
      },
      {
        path: '/v2/activity-center',
        element: <CoreActivityCenter />
      },
      {
        path: '/v2/profile',
        element: <Profile />
      },
      {
        path: '/v2/edit-profile',
        element: <ProfileInformation />
      },
      {
        path: '/v2/tasks',
        element: <CoreTaskList />
      },
      {
        path: '/v2/task-detail/:taskId',
        element: <CoreTaskDetail />,
        pattern: /\/task-detail\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/
      },
      {
        path: '/v2/task-template/:linearTemplateId',
        element: <CoreTemplateCallback />,
        pattern: /.*/
      },
      {
        path: '/v2/search',
        element: <CoreSearch />
      },
      {
        path: '/v2/business',
        element: <> Business Blank </>
      },
      {
        path: '/v2/messages',
        element: <Messages />,
        children: [
          {
            element: <ChatList />,
            index: true
          },
          {
            path: '/v2/messages/:channelId',
            pattern: /\/v2\/messages\/\S+/,
            element: <ChannelMessages />
          }
        ]
      },
      {
        path: '/v2/wizard/:wizardId',
        element: <UserWizard />,
        pattern: /\/wizard\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/
      }
    ]
  }
]
