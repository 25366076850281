/* eslint-disable tailwindcss/no-custom-classname */
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'

import { ProfileProps } from '#app/v2/pages/MyProfile/page'

import { useExpoStatus } from '../libs/utils'

import { pageTransition } from './animations'
import { BackAction } from './BackAction'
import { SideMenuProfile } from './SideMenuProfile'

interface SharedHeaderProps {
  backUrl?: string
  profile?: ProfileProps
  main: JSX.Element
  extraActions?: JSX.Element
  unsavedChanges?: boolean
  backFunction?: () => void
  saveChanges?: () => void
}

export const HeaderSharedNavigation = ({ backUrl, profile, main, extraActions, unsavedChanges, backFunction, saveChanges }: SharedHeaderProps) => {
  const { state } = useLocation()

  const isExpo = useExpoStatus()
  let headerVariants = {}
  if (!isExpo) {
    headerVariants = {
      initial: {
        translateY: state?.from === '/v2/home' ? '-100%' : '0',
        zIndex: '20',
      },
      animate: {
        translateY: '0',
      },
      exit: {
        zIndex: '10',
        translateY: state?.to === '/v2/home' ? '-100%' : '0',
      }
    }
  }
  return (
    <motion.header
      variants={headerVariants}
      transition={pageTransition}
      className='sticky inset-x-0 top-0 z-30 bg-white px-7 py-6 font-semibold text-[#304985]'
    >
      <nav className=' grid-cols-shared-header grid items-center'>
        {backFunction || backUrl
          ? (
            <BackAction
              unsavedChanges={unsavedChanges}
              backUrl={backUrl}
              backFunction={backFunction}
              saveChanges={saveChanges}
            />
          )
          : (
            <SideMenuProfile {...profile} />
          )}
        {main}
        {!extraActions
          ? (
        /* Invisible content for centered main title in layout, this extra action should be the same size as the Back Action */
        // extraActions={<p className='min-w-[70px]'>ALGO</p>}
            <BackAction
              backUrl=''
              className='invisible'
              backFunction={backFunction}
            />
          )
          : extraActions}
      </nav>
    </motion.header>
  )
}
