import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SxProps } from '@mui/material'
import ChipMUI from '@mui/material/Chip'

import { useEntityTaskTemplates } from '#app/hooks/useEntityTaskTemplates'
import { Task } from '#app/types'

export function ChipTaskTemplate({ templateID, sx }: { templateID: string, sx?: SxProps }) {
  const { queryGetTaskTemplatesData, queryGetTaskTemplates } = useEntityTaskTemplates()
  const { t } = useTranslation()

  useEffect(() => {
    void queryGetTaskTemplates()
  }, [queryGetTaskTemplates])

  const template: Partial<Task.Template> | null = useMemo(() => {
    if (queryGetTaskTemplatesData) {
      let res = {
        title: 'error template'
      }
      queryGetTaskTemplatesData?.task_templates.map((template: Task.Template) => {
        if (template.id === templateID) res = template
      })
      return res
    }
    return null
  }, [queryGetTaskTemplatesData, templateID])

  return (
    <>
      {
        templateID
        && (
          <ChipMUI
            sx={sx}
            variant='outlined'
            label={template ? template.title : t('dialogs.loading')}
          />
        )
      }
    </>
  )
}
