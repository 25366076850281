import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Stack, Typography } from '@mui/material'

import { Alert } from '#app/components/Alerts'
import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import { PreviewRichContent } from '#app/feature/Tasks/ViewTask/PreviewRichContent'
import { useWorkOrders } from '#app/hooks/useWorkOrders'
import { THEME } from '#app/layouts/theme'

interface WorkOrderProps {
  workOrderId: string
  title?: string
  due_date?: string
  due_date_type?: string
  fixed_price?: string
  notes?: string
  loading?: boolean
  description?: string
  onClick?: () => void
  hideCreateBid?: boolean
  status: string
  attachments?: string[]
}

const WorkOrdersDetails = ({ workOrderId, due_date_type, title, attachments, due_date, fixed_price, notes, status, onClick, loading, description, hideCreateBid }: WorkOrderProps) => {
  let formattedDate = ''
  let formattedTime = ''

  const { t } = useTranslation()
  const { fetchAttachments, AttachmentSequin, statusToString } = useWorkOrders(workOrderId)

  if (due_date) {
    const date = new Date(due_date)
    const dateOptions = {
      year: 'numeric',
      month: 'long',
      day: '2-digit'
    }
    // @ts-ignore
    formattedDate = date.toLocaleDateString(undefined, dateOptions)
    formattedTime = date.toLocaleTimeString()
  }

  useEffect(() => {
    if (attachments) {
      void fetchAttachments(attachments)
    }
  }, [attachments, fetchAttachments])

  const validStatus = useMemo(() => status === 'open' || status === 'unanswered', [status])

  return (
    <Grid
      item
      xs={12}
    >
      <Stack spacing={3}>
        {title && (
          <Typography
            variant='h5'
            fontWeight='bold'
          >
            {title}
            {' '}
            { status && (
              <Chip.Basic
                color='info'
                label={statusToString(status)}
              />
            )}
          </Typography>
        )}
        <Box sx={{
          borderRadius: 1,
          padding: 2,
          backgroundColor: 'var(--main-content-bg-color)'
        }}
        >
          {due_date_type && (
            <>
              <Typography variant='body1'>
                <b>
                  {due_date_type === 'due-date' && t('workOrders.dueDate')}
                  {due_date_type === 'fixed-date' && t('workOrders.fixedDate', 'Fixed Date')}
                  :
                </b>
                {' '}
                {formattedDate}
                ,
                {' '}
                {formattedTime}
              </Typography>
            </>
          )}

          {fixed_price && (
            <Typography variant='body1'>
              <b>
                {t('workOrders.fixedPrice')}
                :
              </b>
              {' '}
              $
              {fixed_price}
            </Typography>
          )}
        </Box>

        {description && (
          <Box sx={{
            borderRadius: 1,
            padding: 2,
            backgroundColor: 'var(--main-content-bg-color)'
          }}
          >
            <Typography
              variant='body2'
              color={THEME.COLORS.custom.black}
            >
              <b>
                {t('workOrders.description')}
                :
              </b>
            </Typography>
            <Typography variant='body1'>
              <PreviewRichContent
                withoutCardContent
                content={description}
              />
            </Typography>
          </Box>
        )}

        {notes && (
          <Box sx={{
            borderRadius: 1,
            padding: 2,
            backgroundColor: 'var(--main-content-bg-color)'
          }}
          >
            <Typography
              variant='body2'
              color={THEME.COLORS.custom.black}
            >
              <b>
                {t('workOrders.additionalNotes')}
                :
              </b>
            </Typography>
            <Typography variant='body1'>
              {notes}
            </Typography>
          </Box>
        )}

        {attachments && attachments?.length > 0 && (
          <Box sx={{
            borderRadius: 1,
            padding: 2,
            backgroundColor: 'var(--main-content-bg-color)'
          }}
          >
            <Typography
              variant='body2'
              color={THEME.COLORS.custom.black}
            >
              <b>
                {t('workOrders.attachments', 'Attachments')}
                :
              </b>
            </Typography>
            <Typography variant='body1'>
              {attachments.map((attachmentUrl, key) => {
                return (
                  <AttachmentSequin
                    key={key}
                    withoutDownload
                    attachmentUrl={attachmentUrl}
                  />
                )
              })}
            </Typography>
          </Box>
        )}

        {!hideCreateBid
        && (
          <Stack spacing={3}>
            <Btn.Button
              disabled={!validStatus}
              isLoading={loading}
              onClick={onClick}
            >
              {t('workOrders.bid')}
            </Btn.Button>
            {
              !validStatus
              && (
                <Alert.Basic
                  title='This work order is no longer available.'
                  severity='warning'
                />
              )
            }
          </Stack>
        )}
      </Stack>
    </Grid>
  )
}

export default WorkOrdersDetails
