import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import ProfileContext from '#app/contexts/ProfileContext'
import { Logout1 } from '#app/v2/assets/Icons'
import { CenteredNavTitle } from '#app/v2/components/CenteredNavTitle'
import { HeaderSharedNavigation } from '#app/v2/components/HeaderSharedNavigation'
import { SearchBubbleUp } from '#app/v2/pages/Search/page'
import { useGetShortcuts } from '#app/v2-core/hooks/useGetShortcuts'
import { useAppContextV2 } from '#app/v2-core/layouts/useAppContextV2'

export const CoreSearch = () => {
  const { t } = useTranslation()
  const { V2_LOGOUT, V2_SET_LOADING } = useAppContextV2()
  // |-----> Start Profile (grab Ulises implementation)
  const profileData = useContext(ProfileContext)
  const { shortcuts, extraShortcuts } = useGetShortcuts()
  const logout = {
    label: t('v2.general.logout', 'Log out'),
    icon: Logout1,
    onClick: V2_LOGOUT
  }
  // <-----| END Profile

  useEffect(() => {
    V2_SET_LOADING(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const lang = useMemo(() => profileData.getProfile.user?.language === 'en' ? 'en_us' : 'es_es', [profileData.getProfile.user?.language])

  return (
    <>
      <HeaderSharedNavigation
        profile={{ // Header can now have MyProfile page inside a Modal = we need all its context until
          avatar: profileData?.getProfile?.user?.profile_picture_url,
          clientId: profileData?.getProfile.altBoProfile?.company_autonumber,
          fullName: `${profileData?.getProfile?.user?.first_name ?? ''} ${profileData?.getProfile?.user?.last_name ?? ''}`,
          services: profileData?.getProfile?.altBoProfile?.services,
          editProfileUrl: '/v2/edit-profile',
          shortcuts: shortcuts,
          extraShortcuts: extraShortcuts,
          logout: logout
        }}
        main={<CenteredNavTitle title={t('v2.nav.search', 'Search')} />}
      />
      <SearchBubbleUp lang={lang} />
    </>
  )
}
