/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'

import { useWebsiteEditorAiPromptLazyQuery } from '#app/operations/website-editor/websiteeditor.queries.generated'
import useWebsiteEditorStore from '#app/store/website-editor'

import { NunjucksDefinition } from '../../../types'

import { NunjuckVariable } from './NunjucksVariable'

interface Props {
  nunjucksVars: NunjucksDefinition[] | undefined // Deprecated
  sectionId: string
}

function useDebouncedUpdate(sectionId: string) {
  const { sections, updateSectionDetails } = useWebsiteEditorStore()

  const debouncedUpdate = useCallback((index: number, valueObj: string, keyObj: string) => {
    const vars = sections[sectionId]?.nunjucksVars

    if ((vars && valueObj && keyObj) || (vars && keyObj === 'keyValue')) {
      const nunjucksVariables_: NunjucksDefinition[] = vars.map((nun, ind) => {
        if (ind === index) {
          return {
            ...nun,
            [keyObj]: valueObj ?? '',
          } as NunjucksDefinition
        }
        return nun
      })

      // console.log('[updateSectionDetails] NunjucksEditor - debouncedUpdate()')
      updateSectionDetails(sectionId, {
        nunjucksVars: nunjucksVariables_,
      })
    }
  }, [sections, updateSectionDetails, sectionId]) // Dependencias para useCallback

  return debouncedUpdate
}

export function NunjucksEditor({ sectionId }: Props) {
  const { sections, updateSectionDetails } = useWebsiteEditorStore()
  const [getTextFromAIPrompt, { loading: loadingGetTextFromAIPrompt }] = useWebsiteEditorAiPromptLazyQuery({
    fetchPolicy: 'network-only'
  })

  const debouncedUpdate = useDebouncedUpdate(sectionId)

  const [loadingItem, setLoadingItem] = useState<null | number>(null)

  const nunjucksVariables = useMemo(() => {
    if (sections && sections[sectionId]?.nunjucksVars) {
      return sections[sectionId]?.nunjucksVars
    }
    return []
  }, [sectionId, sections])

  const onChangeVariableName = useCallback((index: number, val: string) => {
    debouncedUpdate(index, val, 'keyNunjuck')
  }, [debouncedUpdate])

  const onChangeVariableValue = useCallback((index: number, val: string) => {
    debouncedUpdate(index, val, 'keyValue')
  }, [debouncedUpdate])

  const onChangeAiPrompt = useCallback((index: number, val: string) => {
    debouncedUpdate(index, val, 'aiPrompt')
  }, [debouncedUpdate])

  const deleteVariable = useCallback((index: number) => {
    if (nunjucksVariables) {
      const isLastVariable = nunjucksVariables.length === 1

      const nunjucksVariables_ = nunjucksVariables.filter((nun, ind) => {
        return ind !== index
      })

      // console.log('[updateSectionDetails] NunjucksEditor - deleteVariable()')
      updateSectionDetails(sectionId, {
        nunjucksVars: isLastVariable ? [] : nunjucksVariables_,
      })
    }
  }, [nunjucksVariables, sectionId, updateSectionDetails])

  const resolvePrompt = useCallback(async (index: number, aiPrompt: string) => {
    setLoadingItem(index)

    const res = await getTextFromAIPrompt({
      variables: {
        input: {
          ai_prompt: aiPrompt
        }
      }
    })

    if (res.data?.websiteEditorAIPrompt?.success) {
      onChangeVariableValue(index, res.data?.websiteEditorAIPrompt?.result)
    }
    else {
      onChangeVariableValue(index, 'Ocurrio un error con OpenAI')
    }
  }, [getTextFromAIPrompt, onChangeVariableValue])

  return (
    <Grid
      display='flex'
      flexDirection='column'
      gap={2}
      sx={{
        width: '100%'
      }}
    >
      {/* New variable */}
      {nunjucksVariables && Array.isArray(nunjucksVariables) && nunjucksVariables.map((item, i) => (
        <NunjuckVariable
          key={i}
          indexItem={i}
          item={item}
          resolvePrompt={resolvePrompt}
          deleteVariable={deleteVariable}
          loadingItem={loadingItem}
          loadingGetTextFromAIPrompt={loadingGetTextFromAIPrompt}
          onChangeVariableName={onChangeVariableName}
          onChangeVariableValue={onChangeVariableValue}
          onChangeAiPrompt={onChangeAiPrompt}
        />
      )
      )}
    </Grid>
  )
}
