import { useEffect, useState } from 'react'
import { StreamChat, TokenOrProvider, User } from 'stream-chat'
export interface UseClientOptions {
  apiKey: string
  user: User
  tokenOrProvider: TokenOrProvider
}

export const useStreamClient = ({
  apiKey,
  user,
  tokenOrProvider
}: UseClientOptions): StreamChat | undefined => {
  const [chatClient, setChatClient] = useState<StreamChat>()

  useEffect(() => {
    const client = new StreamChat(apiKey, {
      timeout: 10000
    })
    // prevents application from setting stale client (user changed, for example)
    let didUserConnectInterrupt = false

    let connectionPromise: Promise<void>

    if (tokenOrProvider && user?.id) {
      connectionPromise = client
        .connectUser(user, tokenOrProvider)
        .then(() => {
          // Sentry.captureException(new Error('Stream client connected'), {
          //   extra: {
          //     user
          //   }
          // })

          if (!didUserConnectInterrupt) {
            setChatClient(client)
          }
        })
    }

    return () => {
      didUserConnectInterrupt = true
      setChatClient(undefined)
      // wait for connection to finish before initiating closing sequence
      connectionPromise
        ?.then(async () => await client.disconnectUser())
        ?.catch((err) => {
          console.error('error closing connection', err)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- should re-run only if user.id changes
  }, [apiKey, user.id, tokenOrProvider])

  return chatClient
}
