export const pageTransition = {
  type: 'spring',
  damping: 8,
  mass: 0.25,
  stiffness: 75,
}

export const opacityBlurVariant = {
  initial: {
    opacity: 0,
    filter: 'blur(12px)'
  },
  animate: {
    opacity: 1,
    filter: 'blur(0px)'
  },
  exit: {
    opacity: 0,
    filter: 'blur(12px)'
  }
}
