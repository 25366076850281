/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { BsArrowRepeat } from 'react-icons/bs'
import { Box, Stack } from '@mui/material'

import { useIsDesktopSize } from '../../utils/mediaQueries'
import ActionButton from '../Buttons/ActionButton'

const PageContent = ({ children, ...props }: any) => {
  const isDesktop = useIsDesktopSize()

  const paddingStyles = isDesktop
    ? (props.desktopPaddings ?? {
      padding: '1.875rem'
    })
    : (props.mobilePaddings ?? {
      paddingTop: '5rem',
      paddingBottom: '5rem'
    })

  let sx

  if (props.backgroundColor) {
    sx = {
      backgroundColor: props.backgroundColor
    }
  }

  return (
    <Box sx={{
      ...sx,
      ...paddingStyles,
      minHeight: '100vh'
    }}
    >
      { children }
    </Box>
  )
}

PageContent.PageHeader = ({ children, ...props }: any) => (
  <div
    className='page-header d-flex justify-content-between align-items-center mb-4'
    style={{
      minHeight: 45
    }}
  >
    {children}
  </div>
)

PageContent.PageAction = ({ children, ...props }: any) => (
  <Stack
    direction='row'
    sx={{
      paddingLeft: 1
    }}
  >
    { children}
  </Stack>
)

PageContent.PageActionButton = ({ label, onClick, icon }: any) => {
  return (
    <ActionButton
      icon={icon}
      onClick={onClick}
    >
      {label}
    </ActionButton>
  )
}

PageContent.PageActionSecondaryButton = ({ label, onClick }: any) => {
  return (
    <ActionButton onClick={onClick}>
      { `${String(label)}` }
    </ActionButton>
  )
}

const PageRefreshButton = ({ ...props }: any) => {
  const isDesktop = useIsDesktopSize()

  return (
    <div
      className='v1-action-button d-flex justify-content-center align-items-center'
      onClick={props.onClick}
    >
      { !isDesktop && (
        <span>
          <BsArrowRepeat />
        </span>
      )}
      { isDesktop
      && (
        <span className='v1-action-button'>
          <BsArrowRepeat />
        </span>
      )}
    </div>
  )
}

PageContent.PageRefreshButton = PageRefreshButton

export default PageContent
