import { KeyboardEvent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Field, FieldProps, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { DownloadThickBottom, Lock1, Lock6, StripeTextLogo } from '#app/v2/assets/Icons'
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogTrigger } from '#app/v2/components/ui/alert-dialog'
import { Button } from '#app/v2/components/ui/button'
import { Input } from '#app/v2/components/ui/input'
import { Label } from '#app/v2/components/ui/label'
import { RadioGroup, RadioGroupItem } from '#app/v2/components/ui/radio-group'
import { Separator } from '#app/v2/components/ui/separator'
import { cn } from '#app/v2/libs/utils'

interface Props {
  logo?: string
  companyName?: string
  address?: string
  email?: string
  phoneNumber?: string
  invoiceNumber?: string
  date?: string
  dueDate?: string
  services?: {
    amount: number
    price: number
    description: string
  }[]
  enabledTips?: boolean
  primaryColor?: string
}

export const Invoice = (props: Props) => {
  const { logo, companyName, address, email, phoneNumber, invoiceNumber, date, dueDate, services, enabledTips, primaryColor } = props
  const { t } = useTranslation()
  const TipSchema = Yup.object().shape({
    customTip: Yup.number()
      .positive(t('v2.invoice.errors.negativeInput', 'Custom tip can not be negative!'))
      .integer(t('v2.invoice.errors.integerCustomTip', 'Custom tip must be integer!'))
      .max(999, t('v2.invoice.errors.maxCustomTip', 'Custom tip can not be higher than 999%!'))
  })

  const StyledDiv = styled.div`
    border-color: ${primaryColor};
    color: ${primaryColor};
    &:has([data-state="checked"]) {
      background-color: ${primaryColor};
      color: white
    }
  `

  const subtotal = useMemo(() => {
    return services?.reduce((total, service) => {
      return total + (service.price * service.amount)
    }, 0) ?? 0
  }, [services])

  return (
    <div className='min-h-screen bg-[#FFFFFF] px-5 py-9 text-[#413D45] xl:container'>
      <header className=' mb-6 items-end justify-between max-md:space-y-6 md:flex'>
        <nav className='flex gap-5'>
          <img
            src={logo}
            alt='Company logo'
            className='max-w-32 object-contain md:max-w-36'
          />
          <Separator
            className='hidden h-auto md:block'
            orientation='vertical'
            style={{
              backgroundColor: primaryColor
            }}
          />
          <div className='space-y-4 text-sm'>
            <p
              className='text-xl font-semibold md:text-2xl'
              style={{
                color: primaryColor
              }}
            >
              {companyName}
            </p>
            <p>{address}</p>
            <p>{phoneNumber}</p>
            <p>{email}</p>
          </div>
        </nav>
        <div className='flex items-center justify-between'>
          <div className='flex flex-col gap-2 md:items-end'>
            <p className='font-semibold'>
              {t('v2.invoice.invoiceNumber', 'Invoice number')}
              {' '}
              <span className='font-normal'>
                #
                {invoiceNumber}
              </span>
            </p>
            <p className='font-semibold'>
              {t('v2.invoice.date', 'Date')}
              :
              {' '}
              <span className='font-normal'>{date}</span>
            </p>
            <p className='font-semibold'>
              {t('v2.invoice.dueDate', 'Due date')}
              :
              {' '}
              <span className='font-normal'>{dueDate}</span>
            </p>
            <Button
              variant='outline'
              className='hidden gap-2 md:flex'
              style={{
                color: primaryColor,
                borderColor: primaryColor
              }}
            >
              {t('v2.invoice.download', 'Download')}
              <DownloadThickBottom className=' size-6' />
            </Button>
          </div>
          <Button
            variant='outline'
            className='p-3 md:hidden'
            style={{
              color: primaryColor,
              borderColor: primaryColor
            }}
          >
            <DownloadThickBottom className=' size-6' />
          </Button>
        </div>
      </header>
      <Formik
        initialValues={{
          tip: '15',
          customTip: 10
        }}
        validationSchema={TipSchema}
        onSubmit={() => { }}
      >
        {(props) => {
          const { values, errors, handleChange } = props
          const invalidCustomTip = errors.customTip && values.tip === 'custom'

          function calculateTotal() {
            if (invalidCustomTip) {
              return 'Error on tip'
            }

            if (enabledTips) {
              if (values.tip === 'custom') {
                return '$' + (subtotal + (subtotal * values.customTip / 100)).toFixed(2)
              }

              if (values.tip !== 'custom') {
                return '$' + (subtotal + (subtotal * parseInt(values.tip) / 100)).toFixed(2)
              }
            }

            return '$' + subtotal.toFixed(2)
          }

          return (
            <section className='max-md:space-y-4 md:grid md:grid-cols-2 md:items-start md:gap-4'>
              <section
                id='left-panel'
                className='grid gap-4 md:gap-8'
              >
                <div className='space-y-2 rounded-xl bg-white px-4 py-5 shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]'>
                  <h1
                    className='text-lg font-medium'
                    style={{
                      color: primaryColor
                    }}
                  >
                    {t('v2.invoice.services.serviceDetails', 'Service Details')}
                    :
                  </h1>
                  <div
                    className='flex justify-between font-medium'
                    style={{
                      color: primaryColor
                    }}
                  >
                    <p>{t('v2.invoice.services.description', 'Description')}</p>
                    <p>{t('v2.invoice.services.subtotal', 'Subtotal')}</p>
                  </div>
                  <div className=' space-y-4 divide-y'>
                    {services?.map((service, i) => (
                      <div
                        key={i}
                        className='flex justify-between gap-4 pt-4'
                      >
                        <div className='w-3/5 max-w-96 space-y-2 text-sm'>
                          <p>{service.description}</p>
                          <p className=' font-medium'>
                            {service.amount}
                            {' '}
                            x
                            {' $'}
                            {service.price.toFixed(2)}
                          </p>
                        </div>
                        <p className='font-medium'>
                          $
                          {(service.price * service.amount).toFixed(2)}
                        </p>
                      </div>
                    ))}
                  </div>
                  <Separator />
                  <div className='flex justify-between font-bold'>
                    <p>{t('v2.invoice.services.subtotal', 'Subtotal')}</p>
                    <p>
                      $
                      {subtotal?.toFixed(2)}
                    </p>
                  </div>
                </div>
                <div className='hidden gap-3 rounded-xl bg-white px-4 py-5 shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] md:flex'>
                  <Button style={{
                    backgroundColor: primaryColor
                  }}
                  >
                    {t('v2.invoice.support.makeBooking', 'Make another booking')}
                  </Button>
                  <Button
                    variant='ghost'
                    style={{
                      color: primaryColor
                    }}
                  >
                    {t('v2.invoice.support.contactCTA', 'Contact support')}
                  </Button>

                </div>
                <p
                  className='hidden md:block'
                  style={{
                    color: primaryColor
                  }}
                >
                  {t('v2.invoice.footer', 'Thank you for your business. Please contact us with any questions regarding this invoice.')}
                </p>
              </section>
              <section
                id='right-panel'
                className='gap-4 max-md:space-y-4 md:grid md:gap-5'
              >
                {enabledTips && (
                  <div className='rounded-xl bg-white px-4 py-5 shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]'>
                    <h1
                      className='mb-5 font-medium'
                      style={{
                        color: primaryColor
                      }}
                    >
                      {t('v2.invoice.tips.title', 'Would you like to add a tip?')}
                    </h1>
                    <Form>

                      <Field name='tip'>
                        {({
                          field,
                        }: FieldProps) => {
                          function displayTip() {
                            if (field.value === 'custom' && !values.customTip) {
                              return '0'
                            }

                            if (field.value === 'custom') {
                              return values.customTip
                            }
                            return field.value
                          }
                          return (
                            <RadioGroup
                              {...field}
                              value={field.value}
                              id='tip-radio'
                              className='relative grid grid-cols-2 gap-x-4 gap-y-3 min-[420px]:grid-cols-3 lg:grid-cols-4'
                              onValueChange={(value: string) => {
                                const event = {
                                  target: {
                                    name: field.name,
                                    value: value
                                  }
                                } as React.ChangeEvent<{ name: string, value: string }>
                                field.onChange(event)
                              }}
                            >
                              <StyledDiv
                                className='rounded-xl border border-transparent bg-white text-[#454545]'
                              >
                                <RadioGroupItem
                                  value='0'
                                  id='0'
                                  className='sr-only'
                                />
                                <Label
                                  htmlFor='0'
                                  className='group flex cursor-pointer justify-center rounded-xl py-2 font-medium transition-colors'
                                >
                                  {t('v2.invoice.tips.noTip', 'No tip')}
                                </Label>
                              </StyledDiv>
                              <StyledDiv
                                className='rounded-xl border border-transparent bg-white text-[#454545]'
                              >
                                <RadioGroupItem
                                  value='10'
                                  id='10%'
                                  className='sr-only'
                                />
                                <Label
                                  htmlFor='10%'
                                  className='group flex cursor-pointer justify-center rounded-xl py-2 font-medium transition-colors'
                                >
                                  10%
                                </Label>
                              </StyledDiv>
                              <StyledDiv
                                className='rounded-xl border border-transparent bg-white text-[#454545]'
                              >
                                <RadioGroupItem
                                  value='15'
                                  id='15%'
                                  className='sr-only'
                                />
                                <Label
                                  htmlFor='15%'
                                  className='group flex cursor-pointer justify-center rounded-xl py-2 font-medium transition-colors'
                                >
                                  15%
                                </Label>
                              </StyledDiv>
                              <StyledDiv
                                className='rounded-xl border border-transparent bg-white text-[#454545]'
                              >
                                <RadioGroupItem
                                  value='20'
                                  id='20%'
                                  className='sr-only'
                                />
                                <Label
                                  htmlFor='20%'
                                  className='group flex cursor-pointer justify-center rounded-xl py-2 font-medium transition-colors'
                                >
                                  20%
                                </Label>
                              </StyledDiv>
                              <StyledDiv
                                className='peer rounded-xl border border-transparent bg-white text-[#454545]'
                              >
                                <RadioGroupItem
                                  value='custom'
                                  id='custom'
                                  className='sr-only'
                                />
                                <Label
                                  htmlFor='custom'
                                  className='flex cursor-pointer justify-center rounded-xl py-2 font-medium transition-colors'
                                >
                                  {t('v2.invoice.tips.custom', 'Custom')}
                                </Label>
                              </StyledDiv>
                              <div
                                className='invisible relative opacity-0 transition-all duration-300 peer-has-[[data-state=checked]]:visible peer-has-[[data-state=checked]]:opacity-100'
                              >
                                <Input
                                  name='customTip'
                                  component='input'
                                  value={values.customTip}
                                  min={1}
                                  max={1000}
                                  type='number'
                                  placeholder={t('v2.invoice.tips.customTipPlaceholder', 'Enter your custom tip.')}
                                  id='custom-tip'
                                  className={cn('h-8 pr-6 text-right text-sm/none [appearance:textfield] placeholder:text-sm/none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none', {
                                    ' focus-visible:ring-red-500 border-red-500': errors.customTip
                                  })}
                                  onChange={handleChange}
                                  onKeyDown={(e: KeyboardEvent) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.' || e.key === 'Period') {
                                      e.preventDefault()
                                    }
                                    if (errors.customTip && e.key !== 'Backspace' && !e.key.includes('Arrow')) {
                                      e.preventDefault()
                                    }
                                  }}
                                />
                                <span className='absolute inset-y-0 right-2 border border-transparent py-2 text-sm/none'>%</span>
                              </div>
                              <p className=' col-start-1 font-semibold'>
                                {t('v2.invoice.tips.tip', 'Tip')}
                                :
                              </p>
                              <p className='text-right font-semibold min-[420px]:col-span-2 lg:col-span-3'>

                                {invalidCustomTip ? errors.customTip : '$' + (subtotal * (parseFloat(displayTip())) / 100).toFixed(2)}
                              </p>
                            </RadioGroup>
                          )
                        }}
                      </Field>
                    </Form>
                  </div>
                )}
                <div className='flex flex-col gap-8 rounded-xl bg-white px-4 pb-6 pt-8 shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]'>
                  <div className='flex justify-between text-lg font-semibold'>
                    <h2>{t('v2.invoice.payment.titleAmount', 'Amount to pay')}</h2>
                    <p className={cn('', {
                      'text-red-500': invalidCustomTip
                    })}
                    >
                      {calculateTotal()}
                    </p>
                  </div>
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <Button
                        disabled={!!invalidCustomTip}
                        type='submit'
                        className={cn('min-h-12 w-full gap-3', {})}
                        style={{
                          backgroundColor: primaryColor
                        }}
                      >
                        {t('v2.invoice.payment.payNow', 'Pay now')}
                        <Lock6 className=' size-5' />
                      </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogCancel>
                        Stripe goes here
                      </AlertDialogCancel>
                    </AlertDialogContent>
                  </AlertDialog>

                </div>
                <div className='flex items-center justify-between gap-1 rounded-xl bg-[#FBFBFB] p-4'>
                  <p className='flex items-center gap-1 font-semibold text-[#989898]'>
                    <Lock1 className='size-5 shrink-0' />
                    <span>{t('v2.invoice.stripe.securePayment', 'Secure payment')}</span>
                  </p>
                  <p className='flex items-center gap-1 whitespace-nowrap rounded-xl border-2 border-[#685BFF] p-2 text-sm font-medium text-[#685BFF]'>
                    Powered by
                    <StripeTextLogo className='h-6 shrink-0' />
                  </p>
                </div>
                <div className='flex flex-col items-center gap-3 rounded-xl bg-white px-4 py-5 shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] md:hidden'>
                  <Button
                    className='px-8'
                    style={{
                      backgroundColor: primaryColor
                    }}
                  >
                    {t('v2.invoice.support.makeBooking', 'Make another booking')}
                  </Button>
                  <Button
                    variant='ghost'
                    style={{
                      color: primaryColor
                    }}
                  >
                    {t('v2.invoice.support.contactCTA', 'Contact support')}
                  </Button>
                </div>
                <p
                  className='block text-sm md:hidden'
                  style={{
                    color: primaryColor
                  }}
                >
                  {t('v2.invoice.footer', 'Thank you for your business. Please contact us with any questions regarding this invoice.')}
                </p>
              </section>
            </section>
          )
        }}
      </Formik>
    </div>
  )
}
