import { useSnackbar } from 'notistack'

export interface UseShareProps {
  title?: string
  text?: string
  url?: string
  successText?: string
}

export function useShare(shareProps: UseShareProps | undefined) {
  const { enqueueSnackbar } = useSnackbar()

  const shareData = {
    title: shareProps?.title,
    text: shareProps?.text,
    url: shareProps?.url
  }

  const copyToClipboard = async () => {
    await navigator
      .clipboard
      .writeText(shareProps?.url ?? '')

    if (shareProps?.successText) {
      enqueueSnackbar(shareProps.successText, {
        variant: 'success'
      })
    }
  }

  return {
    onShare: async () => {
      if (!shareProps) return

      if (navigator.share) {
        await navigator
          ?.share(shareData)
          .catch(async () => {
            await copyToClipboard()
          })
      }
      else {
        await copyToClipboard()
      }
    }
  }
}
