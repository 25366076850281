/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ReactNode, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsArrowCounterclockwise, BsBoxArrowRight, BsClockHistory, BsFillChatLeftDotsFill, BsTranslate } from 'react-icons/bs'
import { NavLink } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Grid } from '@mui/material'

import AppContext from '#app/contexts/AppContext'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { useUserPreferences } from '#app/hooks/useUserPreferences'
import { THEME } from '#app/layouts/theme'
import { useResetSalesDemoUserMutation } from '#app/operations/users/users.mutations.generated'
import { DialogResetDemoUser } from '#app/routes/Admin/OwnersManagement/DialogResetDemoUser'
import { RouteOwners } from '#app/routes/useRoutes'
import { useForceMobile, useIsDesktopSize } from '#app/utils/mediaQueries'

import Burguer from '../../components/icons/Burguer'
import OwnersLogo from '../../components/OwnersLogo'

import { DrawerNavigation, DrawerNavigationMobile } from './Styles'

import './DashboardNav.css'

const excludePaths = ['Onboarding']

interface DashboardLinkProps {
  title: string
  path?: string
  img?: string
  icon?: ReactNode
}

interface DashboardItemProps {
  img?: string
  icon?: ReactNode
  title: string
}

export function Content({ routes, logOutUser, closeHandler }: { routes: RouteOwners[], logOutUser: () => void, closeHandler?: () => void }) {
  const { t } = useTranslation()
  const { setLanguageGlobal, currentLanguage } = useUserPreferences()
  const { currentIsSalesDemoAccount, userId, isAdmin } = useEntityUser()
  const { appState } = useContext(AppContext)

  // Demo Accounts
  const [resetSalesDemoUser, { loading: loadingResetSalesDemoUser }] = useResetSalesDemoUserMutation()
  const [openResetSalesDemoUser, setOpenResetSalesDemoUser] = useState<boolean>(false)

  const DashboardItem = ({ img, icon, title }: DashboardItemProps) => {
    return (
      <div className='d-flex justify-content-between h-100'>
        <div className='d-flex justify-content-center align-items-center'>
          { !icon
          && <span className='material-icons'>{img}</span>}
          { icon && <>{icon}</> }
        </div>
        <div className='flex-grow-1 dashboard-link-title d-flex justify-content-start align-items-center ms-3'>
          <span>{title}</span>
        </div>
      </div>
    )
  }

  const DashboardLink = ({ title, path, icon, img }: DashboardLinkProps) => (
    <NavLink
      className={({ isActive }) => (`dashboard-link ${isActive ? 'dashboard-link-selected' : ''} mt-4`)}
      to={path ?? '#'}
      onClick={() => closeHandler ? closeHandler() : null}
    >
      <DashboardItem
        img={img ?? ''}
        icon={icon}
        title={title}
      />
    </NavLink>
  )

  return (
    <div
      className='h-100 container'
      style={{
        width: 275
      }}
    >
      <div className='row h-100 py-3'>
        <div className='col-12 d-flex flex-column justify-content-between h-100'>
          <div className='w-100 top-section d-flex flex-column justify-content-center align-items-center'>
            <div className='w-100 d-flex flex-column'>
              {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                routes
                  .filter((route) => route.navBar)
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  .filter((element) => !excludePaths.includes(element.title ?? ''))
                  .map((element) => {
                    return (
                      <DashboardLink
                        key={element.path}
                        path={element.path}
                        img={element.img}
                        icon={element.icon}
                        title={element.title ?? ''}
                      />
                    )
                  })
              }
              {!isAdmin && (
                <NavLink
                  className={({ isActive }) => ('dashboard-link mt-4')}
                  to='#'
                  onClick={() => {
                    window.open(`http://wa.me/${appState.supportPhone}`, '_blank')
                  }}
                >
                  <DashboardItem
                    icon={<BsFillChatLeftDotsFill fontSize={THEME.ICONS.size.sm} />}
                    title={t('navigations.chatWithUs').toUpperCase()}
                  />
                </NavLink>
              )}
            </div>
          </div>

          <div className='w-100 bottom-section d-flex flex-column justify-content-center align-items-center'>
            {
              (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'development') && currentIsSalesDemoAccount
              && (
                <>
                  {
                    userId
                    && (
                      <DialogResetDemoUser
                        refreshPage
                        ownerId={userId}
                        resetSalesDemoUser={resetSalesDemoUser}
                        openResetSalesDemoUser={openResetSalesDemoUser}
                        setOpenResetSalesDemoUser={setOpenResetSalesDemoUser}
                      />
                    )
                  }
                  <div
                    role='button'
                    className='d-flex dashboard-link w-100 mt-3 flex-row'
                    onClick={() => {
                      if (!loadingResetSalesDemoUser) {
                        setOpenResetSalesDemoUser(true)
                      }
                    }}
                  >
                    <DashboardItem
                      icon={loadingResetSalesDemoUser ? <BsClockHistory /> : <BsArrowCounterclockwise fontSize={THEME.ICONS.size.sm} />}
                      title={loadingResetSalesDemoUser ? 'RESTARTING USER...' : 'RESET DEMO USER'}
                    />
                  </div>
                </>
              )
            }
            <div
              role='button'
              className='d-flex dashboard-link w-100 mt-3 flex-row'
              onClick={async () => await setLanguageGlobal(currentLanguage === 'en' ? 'es' : 'en')}
            >
              <DashboardItem
                icon={<BsTranslate fontSize={THEME.ICONS.size.sm} />}
                title={currentLanguage === 'en' ? 'ENGLISH' : 'ESPAÑOL'}
              />
            </div>
            <div
              role='button'
              className='d-flex dashboard-link w-100 mt-3 flex-row'
              onClick={logOutUser}
            >
              <DashboardItem
                icon={<BsBoxArrowRight fontSize={THEME.ICONS.size.sm} />}
                title={t('preferences.logOut').toUpperCase()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface Props {
  routes: RouteOwners[]
  closeHandler: () => void
  isOpen?: boolean
}

const DashboardNav = (props: Props) => {
  const { logout } = useAuth0()

  const isDesktopVal = useIsDesktopSize()
  const forceMobile = useForceMobile()
  const isDesktop = forceMobile ? false : isDesktopVal

  const logOutUser = useCallback(() => {
    logout({
      returnTo: window.location.origin
    })
  }, [logout])

  if (!isDesktop) {
    return (
      <DrawerNavigationMobile
        open={props.isOpen}
        onClose={props.closeHandler}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='start'

        >
          <div
            style={{
              paddingTop: 8
            }}
            className='container-fluid d-flex'
          >
            <div
              className='d-flex align-items-center'
              onClick={props.closeHandler}
            >
              <Burguer />
            </div>
            <div className='flex-grow-1 ms-3'>
              <OwnersLogo size='sm' />
            </div>
          </div>
        </Grid>
        <Content
          closeHandler={props.closeHandler}
          routes={props.routes || []}
          logOutUser={logOutUser}
        />
      </DrawerNavigationMobile>
    )
  }

  return (
    <DrawerNavigation
      isDesktop
      variant='persistent'
      open={isDesktop}
      anchor='left'
    >
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='start'
      >
        <Grid
          item
          sx={{
            marginTop: 5
          }}
        >
          <OwnersLogo size='sm' />
        </Grid>
      </Grid>
      <Content
        routes={props.routes || []}
        logOutUser={logOutUser}
      />
    </DrawerNavigation>
  )
}

export default DashboardNav
