import styled from '@emotion/styled'
import Drawer, { DrawerProps } from '@mui/material/Drawer'

import { THEME } from '#app/layouts/theme'
interface DrawerNavigationProps {
  isDesktop?: boolean
}

export const DrawerNavigation = styled(Drawer, {
  shouldForwardProp: (propName) => propName !== 'isDesktop',
})<DrawerNavigationProps & DrawerProps>`
  background-color: ${THEME.COLORS.dark.secondary};
  width: ${(props) => props.isDesktop ? '275px' : '0px'};
  flex-shrink: 0;
  & .MuiDrawer-paper {
    box-sizing: border-box;
    background-color:${THEME.COLORS.dark.secondary};
  }
`

export const DrawerNavigationMobile = styled(Drawer)<DrawerNavigationProps>`
flex-shrink: 0;
& .MuiDrawer-paper {
  box-sizing: border-box;
  background-color: ${THEME.COLORS.dark.secondary};
}
`
