import { useTranslation } from 'react-i18next'

import OwnersLogo from '#app/components/OwnersLogo'

import { useUserPreferences } from '../../hooks/useUserPreferences'
import { useIsDesktopSize } from '../../utils/mediaQueries'
import BasketBall from '../icons/BasketBall'
import GreenTick from '../icons/Greentick'
import Phone from '../icons/Phone'

import './OnboardingNav.css'

const OnboardingNav = () => {
  const { t } = useTranslation()
  const { changeLanguage, currentLanguage } = useUserPreferences()

  const Desktop = ({ children }) => {
    const isDesktop = useIsDesktopSize()
    return isDesktop ? children : null
  }

  const Mobile = ({ children }) => {
    const isMobile = !useIsDesktopSize()
    return isMobile ? children : null
  }

  return (
    <>
      <Desktop>
        <div
          style={{
            height: '100vh',
            width: '45vw',
            paddingTop: '1rem'
          }}
          className='d-flex flex-column align-items-center justify-content-between onboard-nav-content'
        >
          <nav
            className='d-flex justify-content-between align-items-center'
            style={{
              listStyle: 'none',
              width: '85%',
              zIndex: '1'
            }}
          >
            <li>
              <OwnersLogo />
            </li>
            <div
              className='d-flex w-50 justify-content-between align-items center'
              style={{
                color: '#FFFFFF',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '140%'
              }}
            >
              <li
                className='d-flex justify-content-evenly'
                style={{
                  width: '30%'
                }}
              >
                <select
                  value={currentLanguage}
                  className='languageSelector'
                  onChange={changeLanguage}
                >
                  <option value='es'>Español</option>
                  <option value='en'>English</option>
                </select>
                <BasketBall />
              </li>
              <li className='w-50 d-flex justify-content-evenly'>
                <a
                  href='tel:+1305912-9689'
                  style={{
                    color: '#FFFFFF',
                    textDecoration: 'none'
                  }}
                >
                  +1 (305) 912-9689
                </a>
                <Phone />
              </li>
            </div>
          </nav>
          <footer
            className='d-flex flex-column'
            style={{
              width: '85%',
              color: '#FFFFFF',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '150%',
              paddingBottom: '4rem',
              zIndex: '1'
            }}
          >
            <h3 style={{
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: '150%'
            }}
            >
              {t('navigations.onboarding.footerTitle')}
            </h3>
            <div>
              <div className='d-flex v1-w-45'>
                <GreenTick />
                <p style={{
                  marginLeft: '20px'
                }}
                >
                  {t('navigations.onboarding.footerAd')}
                </p>
              </div>
              <div className='d-flex v1-w-45'>
                <GreenTick />
                <p style={{
                  marginLeft: '20px'
                }}
                >
                  {t('navigations.onboarding.launch')}
                </p>
              </div>
              <div className='d-flex v1-w-45'>
                <GreenTick />
                <p style={{
                  marginLeft: '20px'
                }}
                >
                  {t('navigations.onboarding.platform')}
                </p>
              </div>
            </div>
          </footer>
        </div>
      </Desktop>
      <Mobile>
        <div style={{
          order: 0,
          marginBottom: '20px'
        }}
        >
          <nav
            className='d-flex justify-content-between align-items-center'
            style={{
              listStyle: 'none',
              width: '100%',
              zIndex: '1',
              margin: 0
            }}
          >
            <li>
              <OwnersLogo isContrast />
            </li>
            <li className='d-flex justify-content-evenly'>
              <a
                href='tel:+1305912-9689'
                style={{
                  color: 'var(--ter-color)',
                  textDecoration: 'none',
                  fontSize: '14px',
                  marginRight: '5px'
                }}
              >
                +1 (920) 526-1978
              </a>
              <Phone style={{
                width: '14px'
              }}
              />
            </li>
            <div
              className='d-flex justify-content-between align-items center'
              style={{
                color: '#FFFFFF',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '140%',
                width: '20%'
              }}
            >
              <li
                className='d-flex justify-content-between'
                style={{
                  width: '100%',
                  color: 'var(--ter-color)'
                }}
              >
                <select
                  value={currentLanguage}
                  className='languageSelector v1-lightblue'
                  onChange={changeLanguage}
                >
                  <option value='es'>Español</option>
                  <option value='en'>English</option>
                </select>
                <BasketBall color='#C8D6E9' />
              </li>
            </div>
          </nav>
        </div>
      </Mobile>
    </>
  )
}

export default OnboardingNav
