import { ActionButton } from './ActionButton'
import { Button } from './Button'
import { ButtonWithMenu } from './ButtonWithMenu'
import { IconButton } from './IconButtonCustom'
import { MenuButton } from './MenuButton'

export const Btn = {
  Button,
  ActionButton,
  IconButton,
  MenuButton,
  ButtonWithMenu
}
