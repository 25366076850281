/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react'
import { QueryHookOptions } from '@apollo/client'

import ProfileContext from '#app/contexts/ProfileContext'
import { GetActivityCenterQuery, useGetActivityCenterQuery, useGetOldActivitiesLazyQuery, useMarkActivitiesAsReadMutation } from '#app/operations/activitycenter/activitycenter.queries.generated'
import { Exact, InputMaybe } from '#app/operations/autogenerate/schemas'
import { Filters } from '#app/v2/pages/ActivityCenter/types'

import { getLastMonth, getLastWeek } from '../utils/dates'

export function useActivityCenter(categories: Filters[], limit = 20, onlyUnread?: boolean) {
  const { getProfile: { user } } = useContext(ProfileContext)
  const config: QueryHookOptions<GetActivityCenterQuery, Exact<{ userId: any, category?: InputMaybe<string | string[]>, lastWeek: any, read?: InputMaybe<boolean | boolean[]> }>> = {
    variables: {
      userId: user?.id,
      category: categories,
      lastWeek: onlyUnread ? getLastMonth() : getLastWeek(),
      read: onlyUnread ? [false] : [false, true]
    },
    fetchPolicy: 'cache-and-network'
  }

  const { data: activitiesDate, loading } = useGetActivityCenterQuery(config)

  const [getOldActivities, { loading: loadingGetOldActivities }] = useGetOldActivitiesLazyQuery()

  const [markActivitiesAsRead] = useMarkActivitiesAsReadMutation({
    variables: {
      userId: user?.id
    }
  })

  const activities = activitiesDate?.activities ?? []

  const fetchMore = async (offset: number) => {
    return getOldActivities({
      variables: {
        userId: user?.id,
        category: categories,
        lastWeek: getLastWeek(),
        lastMonth: getLastMonth(),
        limit,
        offset
      }
    })
  }

  return {
    activities,
    activitiesLoading: loading,
    fetchMore,
    fetchMoreLoading: loadingGetOldActivities,
    markActivitiesAsRead
  }
}
