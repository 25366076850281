import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box, Stack, Typography } from '@mui/material'
import produce from 'immer'
import { useImmer } from 'use-immer'

import rabbit from '#app/assets/img/rabbit.png'
import { Btn } from '#app/components/Buttons'
import { DividerExtended } from '#app/components/Divider'
import { LoadingGrid } from '#app/components/Loading/LoadingGrid'

import { CircularProgressWithLabel } from './CircularProgressWithLabel'
import { FreeCheckbox } from './FreeCheckbox'
import { InputWithSubmitButton } from './FreeText'

export interface FormFlowQuestions {
  questions: Question[]
}
export interface Question {
  question_es: string
  question_key: string
  question_type?: string // checkbox
  options: Option[]
  response?: string
}

export interface Option {
  option_es: string
  option_key: string
  option_bot_conclusion?: string
  option_type?: string // free_input
  subquestions?: Question[]
  response_special?: ResponseSpecial
}

export interface ResponseSpecial {
  type: string
  text?: string
  action?: string
  data?: any
}
interface Response {
  [question_key: string]: {
    question_es: string
    question_key: string
    response: string
    response_key: string
  }
}

interface Props {
  formFlowData: FormFlowQuestions
  callbackComplete: (data: any) => Promise<any>
}

export function FormFlow({ formFlowData, callbackComplete }: Props): JSX.Element {
  const questionsFormFlow = formFlowData.questions

  const [questionStack, setQuestionStack] = useState<Question[]>([...questionsFormFlow])
  const [summaryResponses, setSummaryResponses] = useImmer<Response>({})
  const [showOptionConclusion, setShowOptionConclusion] = useState<string | null>(null)
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [progressUI, setProgressUI] = useState<number>(0)

  const [searchParams] = useSearchParams()
  const [withoutConclusion] = useState<boolean>(!!searchParams.get('withoutConclusion'))

  const [questionStackHistory, setQuestionStackHistory] = useState<Question[][]>([])

  // Utiliza el primer elemento del stack como la pregunta actual.
  const currentQuestion = questionStack[0]

  const handleBack = (): void => {
    if (questionStackHistory.length > 0) {
      const newQuestionStackHistory = [...questionStackHistory]
      const lastQuestionStack = newQuestionStackHistory.pop()

      setQuestionStackHistory(newQuestionStackHistory)
      setQuestionStack(lastQuestionStack as Question[])
    }
  }

  const handleOptionClick = async (option: Option, optionResult: string) => {
    const updatedSummaryResponses = produce(summaryResponses, (draft) => {
      draft[currentQuestion.question_key] = {
        question_es: currentQuestion.question_es,
        question_key: currentQuestion.question_key,
        response: optionResult,
        response_key: option.option_key
      }
    })

    // Update the state with the newly created object
    setSummaryResponses(updatedSummaryResponses)
    setQuestionStackHistory((prevHistory) => [...prevHistory, questionStack])

    if (option.option_bot_conclusion && !withoutConclusion) {
      const timeoutVariable = option.option_bot_conclusion.length * 50
      setShowOptionConclusion(option.option_bot_conclusion)

      const increment = 100 / (timeoutVariable / 50)
      const interval = setInterval(() => {
        setProgressUI((prevProgress) => {
          const newProgress = prevProgress + increment
          if (newProgress >= 100) {
            clearInterval(interval)
            return 100
          }
          return newProgress
        })
      }, 50)

      setTimeout(() => {
        setShowOptionConclusion(null)
        clearInterval(interval)
        setProgressUI(0)
      }, timeoutVariable)
    }

    if (option.subquestions && option.subquestions.length > 0) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      setQuestionStack((prevStack) => [...option.subquestions, ...prevStack.slice(1)])
    }
    else {
      await moveToNextQuestion(updatedSummaryResponses)
    }
  }

  const moveToNextQuestion = async (latesResponses: Response) => {
    setQuestionStack((prevStack) => prevStack.slice(1))

    if (questionStack.length === 1) {
      setIsCompleted(true)
      setLoading(true)
      await callbackComplete(latesResponses).then(() => {
        setLoading(false)
      })
    }
  }

  return (
    <Box sx={{
      width: '90vw',
      height: '80vh',
      maxWidth: '600px',
      overflow: 'auto'
    }}
    >
      {!isCompleted && (
        <>
          {
            showOptionConclusion
            && (
              <Stack
                spacing={3}
                mt={1}
              >
                <Typography variant='h4'>{showOptionConclusion}</Typography>
              </Stack>
            )
          }
          {!showOptionConclusion
            ? (
              <>
                <Stack
                  direction='column'
                  justifyContent='space-between'
                  sx={{
                    height: '100%'
                  }}
                >
                  <Box>
                    <Stack
                      spacing={3}
                      mt={1}
                    >
                      <Typography variant='h5'>{currentQuestion?.question_es}</Typography>

                      {
                        currentQuestion.question_type === 'checkbox'
                        && (
                          <FreeCheckbox
                            currentQuestion={currentQuestion}
                            onSubmit={handleOptionClick}
                          />
                        )
                      }
                      {currentQuestion.question_type !== 'checkbox' && currentQuestion?.options.map((option) => {
                        if (option?.option_type === 'free_input') {
                          return (
                            <InputWithSubmitButton
                              label={option?.option_es}
                              variant='outlined'
                              onSubmit={async (newOptionSelected) => await handleOptionClick(option, newOptionSelected)}
                            />
                          )
                        }
                        return (
                          <Btn.Button
                            key={option.option_es}
                            variant='contained'
                            onClick={async () => await handleOptionClick(option, option.option_es)}
                          >
                            {option.option_es}
                          </Btn.Button>
                        )
                      })}
                    </Stack>
                  </Box>
                  {
                    !isCompleted
                    && !showOptionConclusion
                    && questionStackHistory.length > 0
                    && (
                      <Box>
                        <DividerExtended />
                        <Btn.Button
                          sx={{
                            width: '100%'
                          }}
                          variant='outlined'
                          onClick={handleBack}
                        >
                          Retroceder un paso
                        </Btn.Button>
                      </Box>
                    )
                  }
                </Stack>
              </>
            )
            : (
              <>
                <Typography
                  variant='h5'
                  mb={6}
                >
                  {/* @ts-ignore */}
                  {(summaryResponses && currentQuestion) ? currentQuestion.options[summaryResponses?.[currentQuestion?.question_es]]?.response : ''}
                </Typography>
                <CircularProgressWithLabel value={progressUI} />
              </>
            )}
        </>
      )}
      {isCompleted
      && (
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={8}
        >
          { !loading
            ? (
              <Box>
                <Typography variant='h3'>Formulario completado!</Typography>
                <Typography> Puedes cerrar esta ventana y volver a Whatsapp</Typography>
              </Box>
            )
            : (
              <Box>
                <Typography variant='h3'>Un momento por favor</Typography>
                <Typography> Estamos analizando tus respuestas... </Typography>
              </Box>
            )}
          {
            loading
              ? <LoadingGrid />
              : <img src={rabbit} />
          }
        </Stack>
      )}
    </Box>
  )
}
