/* eslint-disable @typescript-eslint/naming-convention */
import { BsPersonCircle } from 'react-icons/bs'
import { gql, useLazyQuery } from '@apollo/client'

import InputWithQuery from '#app/components/InputWithQuery/InputWithQuery'
import { CustomerInput } from '#app/types/customer'

interface Props {
  userId?: string
  label: string
  name: string
  onChange?: (selectedCustomer: CustomerInput) => void
  disabled?: boolean
}

export const InputWithCustomers = ({ userId, label, name, disabled = false, onChange }: Props) => {
  const getQueryVariables = (stringInput?: string) => {
    let where: any = {
      _and: [
        {
          status: {
            _neq: 'deleted'
          }
        },
        {
          _or: [
            {
              first_name: {
                _is_null: false
              }
            },
            {
              last_name: {
                _is_null: false
              }
            }
          ]
        }
        // Esta query es para que no aparezcan los usuarios que no tienen nombre ni apellido. Confundiendo asi a los usuarios.
        // Me faltaria ver la manera de poder hacer query de los usuarios sin nombre mail, telefono en el crm de la APP y ahi poder editarlos para que aparezcan en el invoice.
      ]
    }

    if (userId) {
      where = {
        ...where,
        user_id: {
          _eq: userId
        }
      }
    }

    if (stringInput) {
      // @ts-ignore
      where = {
        ...where,
        // @ts-ignore
        search: {
          _ilike: `%${stringInput}%`
        }
      }
    }

    return {
      where
    }
  }

  const [fetchData, { loading, data, error }] = useLazyQuery(gql(`
    query GetNotDeletedCustomers($where: customers_bool_exp!) {
      customers(where: $where, limit: 5) {
        email
        first_name
        last_name
        id
      }
    }`))

  const getOptions = (data?: { customers?: Array<{ email?: string, first_name?: string, last_name?: string }> }): any[] => {
    if (!data) {
      return []
    }

    if (!data.customers) {
      return []
    }

    return data
      .customers
  }

  const getPrimaryText = (value: { first_name?: string, last_name?: string, email?: string }): string => {
    const name = `${value?.first_name ?? ''} ${value?.last_name ?? ''}`
    const email = value?.email
    const displayValue = name.trim().length ? name : email
    return displayValue ?? ''
  }

  const getSecondaryText = (value: { email?: string }): string => {
    return value?.email ?? ''
  }

  const isOptionEqualToValue = (option: CustomerInput, value: CustomerInput) => {
    if (option && !value) return true
    return option.id === value.id
  }

  return (
    <InputWithQuery
      disabled={disabled}
      isOptionEqualToValue={isOptionEqualToValue}
      label={label}
      name={name}
      getPrimaryText={getPrimaryText}
      getSecondaryText={getSecondaryText}
      icon={BsPersonCircle}
      fetchData={fetchData}
      loading={loading}
      data={data}
      error={error}
      getOptions={getOptions}
      getQueryVariables={getQueryVariables}
      onChange={onChange}
    />
  )
}
