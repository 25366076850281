import { SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react'
import { BsBox } from 'react-icons/bs'
import { RiDragMove2Fill } from 'react-icons/ri'
import { useNavigate, useParams } from 'react-router-dom'
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Typography } from '@mui/material'
import { LexoRank } from 'lexorank'
import { renderString } from 'nunjucks'

import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import { DividerExtended } from '#app/components/Divider'
import { LoadingCard } from '#app/components/Loading/LoadingCard'
import AppContext from '#app/contexts/AppContext'
import { useAppContext } from '#app/contexts/AppContext/useAppContext'
import { useWebsiteEditorGetSectionsQuery, useWebsiteEditorGetStaticVariablesQuery } from '#app/operations/website-editor/websiteeditor.queries.generated'
import useWebsiteEditorStore, { resetStore } from '#app/store/website-editor'

import { convertToNestedObject } from '../helpers'
import { NunjucksDefinition, SectionProps } from '../types'

import { DrawerNewSection } from './Components/DrawerNewSection'
import { DrawerPreviewContent } from './Components/DrawerPreviewWebsite'
import { NunjucksPreviewContext } from './Components/NunjucksPreviewContext'
import { SectionEditor } from './SectionEditor'
import { MainContainer } from './styled'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  padding: 8 * 2,
  margin: `0 0 ${8}px 0`,
  borderRadius: '1rem',
  background: isDragging ? '#6d7a96' : '#dadee4',
  ...draggableStyle
})

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#FFF' : '#FFF',
  padding: 8,
})

function AccordionSectionWeb({ section }: { section: SectionProps }) {
  const [expanded, setExpanded] = useState<string | false>(false)
  const { updateSectionDetails } = useWebsiteEditorStore()

  const handleChange
    = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <Box
      px={1}
      py={1}
    >
      <Grid
        container
      >
        <Grid xs={1}>
          {!section?.isContainer && (
            <Btn.ActionButton
              forceMobile
              icon={<RiDragMove2Fill />}
            />
          )}
        </Grid>
        <Grid xs={11}>
          <Accordion
            expanded={expanded === section?.sectionId}
            onChange={handleChange(section?.sectionId)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={1}
              >
                {section?.isActive
                  ? (
                    <Chip.Basic
                      label='Active'
                      color='success'
                      onClick={() => {
                        // console.log('[updateSectionDetails] AccordionSectionWeb - Chip.Basic()')
                        updateSectionDetails(section?.sectionId, {
                          isActive: false
                        })
                      }}
                    />
                  )
                  : (
                    <Chip.Basic
                      label='Hidden'
                      color='error'
                      onClick={() => {
                        // console.log('[updateSectionDetails] AccordionSectionWeb - Chip.Basic()')
                        updateSectionDetails(section?.sectionId, {
                          isActive: true
                        })
                      }}
                    />
                  )}
                <Typography mr={1}>
                  {section?.title ?? 'Empty Title'}
                </Typography>
                {section?.isContainer && <BsBox />}
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {
                expanded === section?.sectionId
                && (
                  <SectionEditor
                    lexorank={section.lexorank}
                    sectionId={section.sectionId}
                    title={section?.title ?? 'Empty Title'}
                    description={section?.description ?? 'Empty Description'}
                    htmlCode={section?.htmlCode}
                    isContainer={section?.isContainer}
                    isActive={section?.isActive}
                    nunjucksVars={section?.nunjucksVars}
                  />
                )
              }
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  )
}

export function VersionEditor() {
  const { appState } = useContext(AppContext)
  const { userId, websiteVersion } = useParams()
  const navigate = useNavigate()
  const { sections,
    refreshSections,
    setSectionDetails,
    forceRefresh,
    setForceRefreshOff,
    newSectionDrawer,
    updateSectionDetails } = useWebsiteEditorStore()

  useEffect(() => {
    resetStore()
  }, [])

  const { data, refetch, loading: loadingSections } = useWebsiteEditorGetSectionsQuery({
    variables: {
      user_id: userId,
      section_version: websiteVersion
    },
    fetchPolicy: 'network-only'
  })

  const { data: dataStaticVariables, refetch: refetchVariables, loading: loadingStaticVariables } = useWebsiteEditorGetStaticVariablesQuery({
    variables: {
      user_id: userId
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    // The objective of this flag that is triggered when the changes are saved,
    // is that when sections are generated from the FE they do not have a real ID
    // until the changes are saved, that is why you have to consult to have the real ID
    // and that the backend does not detect it as a new section with a temporary ID.
    if (forceRefresh) {
      refetch()
      setForceRefreshOff()
    }
  }, [forceRefresh, refetch, setForceRefreshOff])

  const { fullHtml, error, nunjucksContext } = useMemo(() => {
    const containers = Object.values(sections).filter((section) => section.isContainer)
    const nonContainers = Object.values(sections)
      .filter((section) => !section.isContainer && section.isActive)
      .sort((a, b) => a.lexorank.localeCompare(b.lexorank))

    // Nunjucks ==>
    const allNunjucks = Object.values(sections).reduce((acc: NunjucksDefinition[], section: SectionProps) => {
      if (section.nunjucksVars) {
        acc = acc.concat(section.nunjucksVars)
      }
      return acc
    }, [])
    const dynamicNunjucksFullContext = convertToNestedObject(allNunjucks)
    const nunjuckFullContext = {
      ...dynamicNunjucksFullContext,
      ...dataStaticVariables?.websiteEditorGetStaticVariables,
      website: {
        section_version: websiteVersion
      }
    }
    // <== Nunjucks

    if (containers.length > 1) {
      return {
        error: 'Más de un contenedor encontrado.',
        fullHtml: null
      }
    }
    else if (containers.length === 0) {
      return {
        error: 'No se encontró un contenedor.',
        fullHtml: null
      }
    }
    else {
      const containerHtml = containers[0].htmlCode
      const nonContainerHtml = nonContainers.reduce((acc: string, currentSection: SectionProps) => acc + currentSection.htmlCode, '')

      if (!containerHtml) {
        return {
          error: 'Error usando el container',
          fullHtml: null,
          nunjucksContext: dynamicNunjucksFullContext
        }
      }

      try {
        const htmlWithNunjucks = containerHtml.replace('{{ new.html_code }}', nonContainerHtml)
        const fullHtml = renderString(htmlWithNunjucks, nunjuckFullContext)

        return {
          error: null,
          fullHtml,
          nunjucksContext: dynamicNunjucksFullContext
        }
      }
      catch (err) {
        console.error(err)
        return {
          error: 'Existen variables mal configuradas en el codigo HTML',
          fullHtml: null,
          nunjucksContext: dynamicNunjucksFullContext
        }
      }
    }
  }, [dataStaticVariables, sections])

  const htmlBlobUrl = useMemo(() => {
    const htmlToRender = fullHtml ?? `<!DOCTYPE html><html><body><h1>${error}</h1></body></html>`
    const blob = new Blob([htmlToRender], {
      type: 'text/html'
    })
    return URL.createObjectURL(blob)
  }, [error, fullHtml])

  // ===

  const { V1_SET_LOADING } = useAppContext()

  const loadingGeneral = useMemo(() => {
    return loadingSections || loadingStaticVariables
  }, [loadingSections, loadingStaticVariables])

  useEffect(() => {
    if (appState.isLoading
      && loadingSections == false
      && loadingStaticVariables == false) {
      V1_SET_LOADING(false)
    }
  }, [V1_SET_LOADING, appState.isLoading, loadingSections, loadingStaticVariables])

  const [orderedSections, setOrderedSections] = useState<SectionProps[]>([])
  const [containerSections, setContainerSections] = useState<SectionProps[]>([])

  useEffect(() => {
    refreshSections()
  }, [refreshSections])

  useEffect(() => {
    if (data?.websiteEditorGetSections?.website_sections) {
      data?.websiteEditorGetSections?.website_sections.map((section) => {
        if (!sections[section?.id]) {
          setSectionDetails(section?.id, {
            sectionId: section?.id,
            title: section?.title,
            lexorank: section?.lexorank,
            htmlCode: section?.html ?? '<div></div>',
            nunjucksVars: section?.nunjucks,
            description: section?.description,
            isContainer: section?.is_container,
            isActive: section?.is_active
          })
        }
      })
    }
  }, [data, sections, setSectionDetails])

  useEffect(() => {
    if (sections) {
      const sectionsParsed = Object.values(sections)
      const sortedSections = [...sectionsParsed].sort((a, b) => {
        return a.lexorank.localeCompare(b.lexorank)
      })
      setOrderedSections(sortedSections.filter((section) => section?.isContainer !== true))
      setContainerSections(sortedSections.filter((section) => section?.isContainer))
    }
  }, [sections])

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result

    if (!destination) {
      return
    }
    const sectionId = result?.draggableId
    const items = [...orderedSections]
    const [reorderedItem] = items.splice(source.index, 1)
    items.splice(destination.index, 0, reorderedItem)
    const prevItemLexoRank = items[destination.index - 1]?.lexorank
    const nextItemLexoRank = items[destination.index + 1]?.lexorank

    let newRank: LexoRank

    if (prevItemLexoRank && nextItemLexoRank) {
      const prevRank = LexoRank.parse(prevItemLexoRank)
      const nextRank = LexoRank.parse(nextItemLexoRank)
      newRank = prevRank.between(nextRank)
    }
    else if (prevItemLexoRank) {
      const prevRank = LexoRank.parse(prevItemLexoRank)
      newRank = prevRank.genNext()
    }
    else if (nextItemLexoRank) {
      newRank = LexoRank.max()
    }
    else {
      newRank = LexoRank.min()
    }
    reorderedItem.lexorank = newRank.toString()

    // console.log('[updateSectionDetails] VersionEditor - onDragEnd()')
    updateSectionDetails(sectionId, {
      lexorank: reorderedItem.lexorank
    })
    setOrderedSections(items)
  }
  return (
    <>
      {userId
      && (
        <DrawerNewSection
          open={!!newSectionDrawer}
          userId={userId}
          placement={newSectionDrawer}
        />
      )}
      { userId && websiteVersion && fullHtml
      && (
        <DrawerPreviewContent
          userId={userId}
          htmlBlobUrl={htmlBlobUrl}
          htmlPlain={fullHtml}
          sectionVersion={websiteVersion}
          loadingGeneral={loadingGeneral}
        />
      )}
      <MainContainer sx={{
        p: 2
      }}
      >
        <Stack>
          <Box>
            <Chip.Basic
              label='← Back to Websites Editor - Sites by user'
              onClick={() => navigate(`/website-editor/${userId}`)}
            />
            <Chip.Basic
              sx={{
                ml: 2
              }}
              label={loadingStaticVariables ? 'Loading' : 'Refresh dynamic variables'}
              color='primary'
              onClick={loadingStaticVariables ? undefined : () => { if (!loadingStaticVariables) { refetchVariables() } }}
            />
          </Box>
          <DividerExtended />
          {' '}
        </Stack>
        {
          nunjucksContext
          && (
            <NunjucksPreviewContext
              nunjucksContext={nunjucksContext}
              nunjucksStaticContext={dataStaticVariables}
            />
          )
        }
        {
          containerSections.map((section, key) => (
            <AccordionSectionWeb
              key={key}
              section={section}
            />
          )
          )
        }
        {
          orderedSections?.length > 1
          && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='websiteEditorSections'>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {
                      orderedSections.map((section, key) => {
                        return (
                          <Draggable
                            key={section.sectionId}
                            draggableId={section.sectionId}
                            index={key}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                {
                                  loadingGeneral
                                    ? <LoadingCard />
                                    : <AccordionSectionWeb section={section} />
                                }
                              </div>
                            )}
                          </Draggable>
                        )
                      })
                    }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )
        }
      </MainContainer>
    </>
  )
}
