import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Grid, Stack, Typography } from '@mui/material'

import Card from '#app/components/Card/Card'
import RightSideBar, { RightSideBarContainer } from '#app/components/RightSideBar/RightSideBar'
import { CreateOrEditInvoiceForm } from '#app/feature/Invoices/CreateOrEditInvoiceForm'
import { InvoicesTable } from '#app/feature/Invoices/InvoicesTable/InvoicesTable'
import { EntityPage } from '#app/layouts/EntityView'
import { useGetInvoicesLazyQuery } from '#app/operations/invoices/invoices.queries.generated'
import { useBankingBottomSection } from '#app/store/banking-bottom-sections'
import { useInvoiceStore } from '#app/store/invoice'

export const Invoices = () => {
  const { t } = useTranslation()

  const [createOpen, setCreateOpen] = useState<boolean>(false)
  const { setSelectedSection } = useBankingBottomSection()
  const { invoiceQuery } = useInvoiceStore()
  const navigate = useNavigate()

  const [,{ data, loading, startPolling }] = useGetInvoicesLazyQuery({
    variables: invoiceQuery
  })

  const location = useLocation()

  useEffect(() => {
    setSelectedSection({
      url: location.pathname
    })
    startPolling(10000)
  }, [])

  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('action')
      && searchParams.get('action') === 'createInvoice'
      && !createOpen) {
      navigate('/payments/invoices')
      setCreateOpen(true)
    }
  }, [createOpen, navigate, searchParams])

  return (
    <EntityPage
      isLoading={loading}
      layout='free'
      pageTitle={t('banking.invoices.title', 'Invoices')}
      pageSubtitle={t('banking.invoices.subTitle', 'Generate invoices and share them with your customers')}
      actionLabel={t('banking.invoices.createInvoice', 'Create invoice')}
      mainContent={(
        <>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <Card>
                <Card.Body className='v1-card-bottom-padding'>
                  <Stack spacing={2}>
                    <Typography variant='h6'>
                      {t('banking.invoices.title')}
                    </Typography>
                    <InvoicesTable
                      loading={loading}
                      data={data}
                    />
                  </Stack>
                </Card.Body>
              </Card>
            </Grid>
          </Grid>
          <RightSideBar
            isOpen={createOpen}
            closeHandler={() => setCreateOpen(false)}
          >
            <RightSideBarContainer>
              <CreateOrEditInvoiceForm onCallback={() => setCreateOpen(false)} />
            </RightSideBarContainer>
          </RightSideBar>
        </>
      )}
      onActionClick={() => setCreateOpen(true)}
    />
  )
}
