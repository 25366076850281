import { useEffect, useRef } from 'react'
import TextField from '@mui/material/TextField'
import { SxProps } from '@mui/system'
import { useField } from 'formik'

import FormLabel from '../FormLabel/FormLabel'
interface Props {
  placeholder?: string
  popoverText?: string
  sx?: SxProps
  label?: string
  type: string
  name: string
  disabled?: boolean
  defaultValue?: string
  variant?: 'outlined' | 'standard' | 'filled'
  onChange?: (value: any) => void
}

const FormInput = ({ placeholder, label, popoverText, sx, name, type, defaultValue = '', variant = 'outlined', disabled = false, onChange }: Props) => {
  const [, meta, helpers] = useField({
    name,
    type
  })
  const fieldRef = useRef<HTMLElement>(null)

  const { setValue } = helpers

  useEffect(() => {
    if (fieldRef && type === 'number') {
      fieldRef
        ?.current
        ?.addEventListener('wheel', (e: WheelEvent) => e.preventDefault())
    }

    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [])

  return (
    <>
      { label && (
        <FormLabel
          label={label}
          popoverText={popoverText}
        />
      )}
      <TextField
        fullWidth
        inputRef={fieldRef}
        sx={sx}
        disabled={disabled}
        error={meta.touched && !!meta.error}
        variant={variant}
        value={meta.value}
        inputProps={{
          pattern: type === 'number' ? '[0-9]*' : undefined
        }}
        placeholder={placeholder}
        type={type || 'text'}
        onChange={(e) => {
          let val
          if (type === 'number') {
            val = !e.target.value ? '' : Number(e.target.value)
          }
          else {
            val = e.target.value
          }

          setValue(val)
          if (onChange) {
            onChange(val)
          }
        }}
      />
      <div className='error'>{ meta.touched && meta.error }</div>
    </>
  )
}

export default FormInput
