import { useCallback } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'

import Button from '#app/components/Buttons/Button'
import { DividerExtended } from '#app/components/Divider'
import FormInput from '#app/components/FormInput/FormInput'
import InputWithChips from '#app/components/InputWithChips/InputWithChips'
import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import { useEntityAudiences } from '#app/hooks/useEntityAudiences'
import { createAudienceSchema } from '#app/utils/validationSchemas'

interface Props {
  open: boolean
  setOpen: (val: boolean) => void
}

const initialValues = {
  title: ''
}

const altBoFields = {
  phase: ['Onboarding', 'Adoption', 'Success', 'Churn risk', 'Churn'], // TODO
  welcome_call_status: ['Pending', 'Scheduled', 'Completed'], // TODO
  annual_revenue: ['$0 - $10,000', '$10,000 - $24,999', '$25,000 - $49,999', '$50,000 - $99,999', '$100,000 - $199,999', '$200,000 - $499,999'], // TODO
  customer_fit: ['Good fit', 'Stretch fit', 'Bad fit'], // TODO
  english_level: ['Yes', 'No', 'Basic', 'Has someone'], // TODO
  status: ['Trial', 'Subscribed', 'Churn', 'No Subscription'],
  plan: ['Grow', 'Start + Grow', 'Start'],
  legal_entitystatus: ['Completed', 'Provided', 'In Progress'],
  legal_entity: ['LLC', 'C Corp', 'Sole Propiertorship'],
  liability_insurance_status: ['Completed', 'Requested', 'Not Started', 'Provided'],
  call_center_status: ['Not applicable', 'Not Started', 'In Progress', 'Waiting for Approval', 'IVR Recorded', 'Completed'],
  marketing_material_status: ['Not applicable', 'Not Started', 'In Progress', 'Waiting for Approval', 'Purchased'],
  domain_status: ['Not Started', 'In Progress', 'Waiting for Approval', 'Purchased', 'Provided'],
  website_status: ['Not Started', 'In Progress', 'Waiting for Approval', 'Complted', 'Provided']
}

export function CreateAudience({ open, setOpen }: Props) {
  const { mutationCreateAudience } = useEntityAudiences()

  const createAudience = useCallback(async (data: { [key: string]: string[] }) => {
    // const cities = data?.cities?.length
    // // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    //   ? data.cities?.map((city: any) => {
    //     if (city) {
    //       return {
    //         place_id: city.place_id,
    //         coordinates: city.coordinates,
    //         description: city.description
    //       }
    //     }
    //   })
    //   : undefined

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const altBOFields: { [key: string]: string[] } = {}
    Object.keys(data).map((key: string) => {
      if (key.startsWith('altBO_')) {
        altBOFields[key.replace('altBO_', '')] = data[key] as unknown as string[]
      }
      return null
    })

    await mutationCreateAudience({
      variables: {
        title: data.title,
        conditions: {
          // cities,
          // languages: data.languages,
          // services: data.services,
          altBO: altBOFields,
          user_role: ['owners-user']
        }
      }
    })
    setOpen(false)

    return true
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationCreateAudience, setOpen])

  return (
    <RightSideBar
      closeHandler={() => setOpen(false)}
      isOpen={open}
    >
      <>
        <Typography
          variant='h4'
          mb={4}
        >
          New Audience
        </Typography>
        <Box>
          <Formik
            initialValues={initialValues}
            validationSchema={createAudienceSchema}
            onSubmit={(data: any) => {
              void createAudience(data)
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box sx={{
                  m: 1
                }}
                >
                  <Box>
                    <Grid
                      container
                      spacing={1}
                      mb={3}
                      mt={1}
                      width='100%'
                    >
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                      >
                        <FormInput
                          label='Name'
                          name='title'
                          type='text'
                          placeholder='Write a short title to understand the Audience'
                        />
                      </Grid>
                      {/* <Grid item xs={12} md={12} lg={12}>
                        <InputWithPlaces
                          label={'Cities'}
                          name={'cities'}
                          types={['locality']}
                          limitTags={5}
                          multiple
                          fields={undefined}
                          countries={undefined}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <InputWithChips
                          name="services"
                          label='Services'
                          multiple
                          options={ownerServices}
                          optionLabelHandler={undefined}
                          limit={undefined} />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <InputWithChips
                          name="languages"
                          label='Language'
                          multiple
                          options={['EN', 'ES']}
                          optionLabelHandler={undefined}
                          limit={undefined} />
                      </Grid> */}
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                      >
                        <DividerExtended />
                        <Typography variant='subtitle2'>
                          AltBO -
                          {' '}
                          <a
                            href='https://airtable.com/app8KfWDtTwDckSBT'
                            target='_blank'
                            rel='noreferrer'
                          >
                            Airtable
                          </a>
                        </Typography>
                        <DividerExtended />
                        {
                          Object.keys(altBoFields).map((key) => {
                            return (
                              <InputWithChips
                                multiple
                                name={`altBO_${key}`}
                                label={key}
                                optionLabelHandler={undefined}
                                limit={undefined}
                                // @ts-ignore
                                options={altBoFields[key]}
                              />
                            )
                          })
                        }
                      </Grid>
                    </Grid>
                  </Box>
                  <Button
                    sx={{
                      ml: 1
                    }}
                    primary={false}
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    disabled={isSubmitting}
                    style={{
                      float: 'left'
                    }}
                  >
                    Create Audience
                  </Button>
                </Box>

              </Form>
            )}
          </Formik>
        </Box>
      </>
    </RightSideBar>
  )
}
