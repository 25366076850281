export function enviromentDomain() {
  const textEnv = {
    dev: 'development',
    staging: 'staging',
    prod: 'production'
  }

  const currentDomain = document.domain
  let result = textEnv.dev

  if (currentDomain === 'app-staging.joinowners.com') result = textEnv.staging
  if (currentDomain === 'app.joinowners.com') result = textEnv.prod

  return result
}
