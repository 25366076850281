import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import ProfileContext from '#app/contexts/ProfileContext'
import { Logout1 } from '#app/v2/assets/Icons'
import { CenteredNavTitle } from '#app/v2/components/CenteredNavTitle'
import { HeaderSharedNavigation } from '#app/v2/components/HeaderSharedNavigation'
import { MyProfile } from '#app/v2/pages/MyProfile/page'
import { useGetShortcuts } from '#app/v2-core/hooks/useGetShortcuts'
import { useAppContextV2 } from '#app/v2-core/layouts/useAppContextV2'

export function Profile() {
  const { t } = useTranslation()
  const profileData = useContext(ProfileContext)
  const { shortcuts, extraShortcuts } = useGetShortcuts()
  const { V2_SET_LOADING, V2_LOGOUT } = useAppContextV2()

  useEffect(() => {
    V2_SET_LOADING(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logout = {
    label: t('v2.general.logout', 'Log out'),
    icon: Logout1,
    onClick: V2_LOGOUT
  }

  return (
    <>
      <HeaderSharedNavigation
        backUrl='/v2/home'
        main={<CenteredNavTitle title={t('v2Profile.myProfile.label', 'Menu')} />}
      />
      <MyProfile
        avatar={profileData?.getProfile?.user?.profile_picture_url}
        clientId={profileData?.getProfile.altBoProfile?.company_autonumber}
        fullName={`${profileData?.getProfile?.user?.first_name ?? ''} ${profileData?.getProfile?.user?.last_name ?? ''}`}
        services={profileData?.getProfile?.altBoProfile?.services}
        editProfileUrl='/v2/edit-profile'
        shortcuts={shortcuts}
        extraShortcuts={extraShortcuts}
        logout={logout}
      />
    </>
  )
}
