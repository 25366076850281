// @ts-ignore

import OwnersErrorBoundary from '#app/components/OwnersErrorBoundary'
import { AppProvider } from '#app/contexts/AppContext'
import { NotificationsProviderV1 } from '#app/contexts/NotificationsProvider'
import { ProfileProvider } from '#app/contexts/ProfileContext'
import ProgressierScript from '#app/contexts/ProgressierScript'
import SegmentContext from '#app/contexts/SegmentContext'
import SessionDetectContext from '#app/contexts/SessionDetectContext'
import { ForceLogout } from '#app/feature/ForceLogout'
import DashboardView from '#app/layouts/DashboardView/DashboardView'
import { ApolloProviderWithClient } from '#app/root/ApolloProviderWithClient'
import { Auth0ProviderWithRedirectCallback } from '#app/root/Auth0ProviderWithRedirectCallback'

import { Authentication } from './Authentication'
import { Authorization } from './Authorization'

export const ProtectedRoutesWrapper = () => (
  <div className='v1-root-container'>
    <AppProvider>
      <SegmentContext>
        <ProgressierScript>
          <OwnersErrorBoundary>
            <Auth0ProviderWithRedirectCallback
              // @ts-expect-error type env
              domain={process.env.REACT_APP_AUTH0_DOMAIN}
              clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
              redirectUri={window.location.origin}
              cacheLocation='localstorage'
              audience='backend-api'
              useRefreshTokens={true}
            >
              <ForceLogout>
                <NotificationsProviderV1
                  maxSnack={3}
                  autoHideDuration={3000}
                >
                  <Authentication>
                    <ApolloProviderWithClient>
                      <ProfileProvider>
                        <SessionDetectContext>
                          <Authorization>
                            <DashboardView />
                          </Authorization>
                        </SessionDetectContext>
                      </ProfileProvider>
                    </ApolloProviderWithClient>
                  </Authentication>
                </NotificationsProviderV1>
              </ForceLogout>
            </Auth0ProviderWithRedirectCallback>
          </OwnersErrorBoundary>
        </ProgressierScript>
      </SegmentContext>
    </AppProvider>
  </div>
)
