import { FormFlowQuestions } from '../../../feature/FormFlow'

export const customerSuccessQuestions: FormFlowQuestions = {
  questions: [
    {
      question_es: '👩 Hola! Quiero armar tu plan de marketing antes de nuestra llamada. Tengo una preguntas bien simples para que marques las opciones que te interesan así puedo preparar el plan para la reunión. Recuerda que sin esfuerzo no hay éxito, y que acá estamos para apoyarte en cada momento del camino.',
      question_key: 'question_introductory',
      options: [
        {
          option_es: 'Entendido, comencemos!',
          option_key: 'option_begin'
        }
      ]
    },
    {
      question_es: '👩 ¿Te puedes comprometer a que si agendamos una llamada conmigo y el coach de negocios podrás asistir a la hora acordada desde una tableta o computadora?',
      question_key: 'question_commitment_call',
      options: [
        {
          option_es: 'Sí',
          option_key: 'option_yes'
        },
        {
          option_es: 'No, porque no tengo tablet o computadora',
          option_key: 'option_no_tablet_computer'
        },
        {
          option_es: 'No estoy seguro',
          option_key: 'option_not_sure'
        }
      ]
    },
    {
      question_es: '👩 ¿Tienes disponible $500 por mes para invertir en publicidad con Google? Recomendamos no invertir en Google hasta que no tengas tus primeros 10 clientes que te puedan dejar reseñas en tu perfil en Google My Business',
      question_key: 'question_google_ad_budget',
      options: [
        {
          option_es: 'Si',
          option_key: 'option_yes'
        },
        {
          option_es: 'No',
          option_key: 'option_no'
        }
      ]
    },
    {
      question_es: '👩 ¿Tienes al menos 10 reseñas positivas en tu perfil de Google My Business?',
      question_key: 'question_positive_reviews',
      options: [
        {
          option_es: 'Sí',
          option_key: 'option_yes'
        },
        {
          option_es: 'No, pero puedo conseguirlas',
          option_key: 'option_no_but_can_get'
        },
        {
          option_es: 'No, porque mi perfil no está activo',
          option_key: 'option_no_inactive_profile'
        },
        {
          option_es: 'No estoy seguro',
          option_key: 'option_not_sure'
        }
      ]
    },
    {
      question_es: '👩 ¿Has podido subir los posteos que te mandamos a tu perfil de Instagram y Facebook?',
      question_key: 'question_social_media_posts',
      options: [
        {
          option_es: 'Sí',
          option_key: 'option_yes'
        },
        {
          option_es: 'No, porque mis cuentas en redes sociales no están activas',
          option_key: 'option_no_inactive_accounts'
        },
        {
          option_es: 'No, porque no sé cómo hacerlo',
          option_key: 'option_no_dont_know_how'
        },
        {
          option_es: 'No, porque no los recibí',
          option_key: 'option_no_didnt_receive'
        }
      ]
    },
    {
      question_es: '👩 ¿Ya recibiste tus tarjetas personales?',
      question_key: 'question_received_personal_cards',
      options: [
        {
          option_es: 'Si',
          option_key: 'option_yes'
        },
        {
          option_es: 'No',
          option_key: 'option_no'
        },
        {
          option_es: 'No estoy seguro',
          option_key: 'option_not_sure'
        }
      ]
    },
    {
      question_es: '👩 ¿Tienes carro para ir a repartir tarjetas a administradores de propiedades?',
      question_key: 'question_car_for_distribution',
      options: [
        {
          option_es: 'Sí',
          option_key: 'option_yes'
        },
        {
          option_es: 'No',
          option_key: 'option_no'
        },
        {
          option_es: 'No, pero puedo conseguir quien me lleve',
          option_key: 'option_no_but_can_get_ride'
        }
      ]
    },
    {
      question_es: '👩 Cada semana, Chris Lee y Michel Mosse comparten consejos y herramientas para conseguir clientes y crecer tu negocio. ¿Podés responder algunas preguntas para poder mandarte los mejores consejos?',
      question_key: 'question_weekly_tips',
      options: [
        {
          option_es: 'Si',
          option_key: 'option_weekly_tips_yes',
          subquestions: [
            {
              question_es: '👩 ¿A quién admiras más en tu industria? Es decir, un influencer, un propietario de un negocio, YouTuber, podcaster, escritor...',
              question_key: 'question_weekly_tips_admire',
              options: [
                {
                  option_es: 'Inserte a quiénes admira',
                  option_key: 'option_weekly_tips_admire_free_input',
                  option_type: 'free_input'
                },
                {
                  option_es: 'Omitir',
                  option_key: 'option_weekly_tips_admire_skip'
                }
              ]
            },
            {
              question_es: '👩 Si esta persona estuviera frente a ti, ¿qué preguntas le harías? Enumera al menos 4 o 5 preguntas.',
              question_key: 'question_weekly_tips_admire_questions',
              options: [
                {
                  option_es: 'Inserte las preguntas que haría',
                  option_key: 'option_weekly_tips_admire_questions_free_input',
                  option_type: 'free_input'
                },
                {
                  option_es: 'Omitir',
                  option_key: 'option_weekly_tips_admire_questions_skip'
                }
              ]
            },
            {
              question_es: '👩 ¿Qué canal prefieres para aprender cosas nuevas sobre tu trabajo? Blogs, YouTube, Instagram, Facebook, Twitter, Podcast',
              question_key: 'question_weekly_tips_admire_questions_channels',
              question_type: 'checkbox',
              options: [
                {
                  option_es: 'Articulos',
                  option_key: 'option_weekly_tips_admire_questions_channels_articles'
                },
                {
                  option_es: 'Podcast',
                  option_key: 'option_weekly_tips_admire_questions_channels_podcast'
                },
                {
                  option_es: 'Youtube',
                  option_key: 'option_weekly_tips_admire_questions_channels_youtube'
                },
                {
                  option_es: 'Webinars',
                  option_key: 'option_weekly_tips_admire_questions_channels_webinars'
                },
                {
                  option_es: 'Twitter',
                  option_key: 'option_weekly_tips_admire_questions_channels_twitter'
                },
                {
                  option_es: 'Cuentas de Instagram o Facebook',
                  option_key: 'option_weekly_tips_admire_questions_channels_instagram_facebook'
                },
                {
                  option_es: 'Newsletters',
                  option_key: 'option_weekly_tips_admire_questions_channels_newsletters'
                },
                {
                  option_es: 'Otro',
                  option_key: 'option_weekly_tips_admire_questions_channels_other'
                }
              ]
            }
          ]
        },
        {
          option_es: 'No',
          option_key: 'option_have_client_portfolio_no'
        }
      ]
    }
  ]
}
