import { createContext, useRef, useState } from 'react'
import { ThemeProvider } from '@mui/material'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import OwnersLogo from '#app/components/OwnersLogo'
import { theme } from '#app/layouts/theme'
import { ENVIROMENT } from '#app/store/enviroment'
import { LoadingScreen } from '#app/v2/pages/LoadingScreen/page'

import { V1Dependencies } from './V1'

interface mainState {
  isLoading: boolean
  error: string | boolean
  forceLogout: boolean
  signIn: boolean
  supportPhone: string
}

interface AppContextInterface {
  appState: mainState
  setAppState: React.Dispatch<React.SetStateAction<mainState>> | (() => void)
}

const AppContext = createContext<AppContextInterface>({
  appState: {
    isLoading: true,
    error: false,
    forceLogout: false,
    signIn: false,
    supportPhone: '13059129689'
  },
  setAppState: () => null
})

AppContext.displayName = 'AppContext'

function loadScript(src: string, position: any, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  position.appendChild(script)
}

const ErrorScreenAgnostic = ({ error, logout }: { error: string, logout: () => void }) => {
  return (
    <LoadingScreen
      visible
      error
      errorText={error}
      errorSignout={logout}
    />
  )
}

export const AppProvider = ({ children, agnostic }: { children: JSX.Element, agnostic?: boolean }) => {
  const [appState, setAppState] = useState<mainState>({
    isLoading: true,
    error: false,
    forceLogout: false,
    signIn: false,
    supportPhone: ENVIROMENT.support_whatsapp
  })
  const [loadingLogout, setLoadingLogout] = useState(false)
  const loaded = useRef(false)

  const loadingVisible = (!!appState.isLoading || !!appState.error)

  const backgroundStyle = {
    position: 'absolute',
    zIndex: 9999,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#304985',
    visibility: loadingVisible ? 'visible' : 'hidden'
  }

  if (typeof window !== 'undefined' && !loaded.current) {
    // this function is to satisfy the google api library, it needs a callback
    // @ts-ignore
    window.onMapsComplete = () => console.log('complete maps')

    if (document.querySelector('#google-maps') == null) {
      const tmp: string = process.env.REACT_APP_FIREBASE_API_KEY ? process.env.REACT_APP_FIREBASE_API_KEY : 'ERROR_KEY'
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${tmp}&libraries=places&callback=onMapsComplete`,
        document.querySelector('head'),
        'google-maps'
      )
    }

    loaded.current = true
  }

  if (agnostic) {
    return (
      <AppContext.Provider value={{
        appState,
        setAppState
      }}
      >
        <>
          { appState?.error
          && (
            <ErrorScreenAgnostic
              error={appState?.error as string}
              logout={() => setAppState((prevState) => ({
                ...prevState,
                forceLogout: true
              }))}
            />
          )}
          {children}
        </>
      </AppContext.Provider>
    )
  }

  const LoadingContainer = () => (
    // @ts-ignore
    <div style={backgroundStyle}>
      <div className='d-flex flex-column justify-content-center align-items-center h-100 container-fluid'>
        <OwnersLogo
          size='md'
          sx={{
            mb: 4
          }}
        />
        {
          appState.error
            ? (
              <div
                className='alert alert-danger text-center'
                role='alert'
              >
                {appState.error}
              </div>
            )
            : (
              <div
                className='spinner-border text-light'
                role='status'
              >
                <span className='visually-hidden'>Loading...</span>
              </div>
            )
        }
        {/* Force logout alternative */}
        {appState.error && !loadingLogout
        && (
          <div
            className='admin-nav-link-section'
            onClick={() => {
              setLoadingLogout(true)
              setAppState((prevState) => ({
                ...prevState,
                forceLogout: true
              }))
            }}
          >
            <span className='material-icons'>logout</span>
            <a
              href='#'
              className='admin-nav-link'
            >
              Log Out
            </a>
          </div>
        )}
        {appState.error && loadingLogout
        && (
          <Box mt={2}>
            <CircularProgress />
          </Box>
        )}
      </div>
    </div>
  )

  return (
    <V1Dependencies>
      <AppContext.Provider value={{
        appState,
        setAppState
      }}
      >
        <ThemeProvider theme={theme}>
          <LoadingContainer />
          <div style={{
            visibility: !loadingVisible ? 'visible' : 'hidden',
            minHeight: '100vh'
          }}
          >
            {children}
          </div>
        </ThemeProvider>
      </AppContext.Provider>
    </V1Dependencies>
  )
}

export default AppContext
