import { HtmlHTMLAttributes } from 'react'
import { Link } from 'react-router-dom'

import { Button } from '#app/v2/components/ui/button'

export interface ShortcutGridBtnProps {
  url?: string
  icon?: ({ className }: HtmlHTMLAttributes<HTMLOrSVGElement>) => JSX.Element
  description?: string
}

export const ShortcutGridBtn = ({ url, icon, description }: ShortcutGridBtnProps) => {
  return (
    <Button
      asChild
      className='justify-start gap-3 py-3 text-xs font-medium'
    >
      <Link to={url ? url : ''}>
        {icon
          ? icon({
            className: 'h-6 w-6 shrink-0'
          })
          : ''}
        {description}
      </Link>
    </Button>
  )
}
