import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Link } from '@mui/material'
import capitalize from '@mui/utils/capitalize'
import { useSnackbar } from 'notistack'

import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import { Table } from '#app/components/Table'
import AppContext from '#app/contexts/AppContext'
import { EntityPage } from '#app/layouts/EntityView'
import { useGetWizardTemplatesLazyQuery } from '#app/operations/wizards/wizards.queries.generated'
import { WizardType } from '#app/types/wizard'

import { CreateWizard } from './CreateWizard'
import { EditWizard } from './EditWizard'

interface TableRow {
  key: string
  columns: Array<{
    component: JSX.Element
  }>
}

const Wizards = () => {
  const { appState, setAppState } = useContext(AppContext)
  const [openCreateWizard, setOpenCreateWizard] = useState(false)
  const [openEditWizard, setOpenEditWizard] = useState(false)
  const [currentWizard, setCurrentWizard] = useState({})
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [getWizardTemplates, { data: dataWizardTemplates, loading: loadingMainQuery, refetch }] = useGetWizardTemplatesLazyQuery()

  const editHandler = (wizard: WizardType) => {
    setCurrentWizard(wizard)
    setOpenEditWizard(true)
    setOpenCreateWizard(false)
  }

  useEffect(() => {
    if (appState.isLoading) {
      setAppState((previousState) => ({
        ...previousState,
        isLoading: false
      }))
    }
  }, [appState.isLoading, setAppState])

  const createHandler = useCallback(() => {
    setOpenCreateWizard(true)
  }, [])

  const mainFetch = useCallback(async () => {
    await getWizardTemplates()
  }, [getWizardTemplates])

  useEffect(() => {
    void mainFetch()
  }, [mainFetch])

  const LinkToClipboard = ({ wizardId }: { wizardId: string }) => {
    const copyLinkToClipboard = async () => {
      const link = `${window.location.origin}/v2/wizard/${wizardId}`
      try {
        await navigator.clipboard.writeText(link)
        enqueueSnackbar('Link Copied to clipboard', {
          variant: 'success'
        })
      }
      catch (err) {
        console.error('Failed to copy: ', err)
      }
    }

    return (
      <Box>
        <Button onClick={copyLinkToClipboard}>Copy Link to Wizard</Button>
      </Box>
    )
  }

  const structure = useMemo(() => {
    const rows: TableRow[] = []

    if (dataWizardTemplates?.wizard_templates) {
      dataWizardTemplates?.wizard_templates?.map((wizard, index) => {
        const row: TableRow = {
          key: `row-${index}`,
          columns: [
            {
              component: <Box>{wizard.title}</Box>
            },
            {
              component: <Box>
                <Chip.Basic
                  label={wizard?.status}
                  color='success'
                />
              </Box>
            },
            {
              component: (
                <Box>
                  <Link
                    href={`/wizards/demo/${wizard.id}`}
                    target='_blank'
                  >
                    Link to Wizard Demo

                  </Link>
                </Box>
              )
            },
            {
              component: (
                <LinkToClipboard wizardId={wizard.id} />
              )
            },
            {
              component: (
                (
                  <Box>
                    <Btn.MenuButton
                      onEdit={() => {
                        editHandler(wizard)
                      }}
                    />
                  </Box>
                )
              )
            }
          ]
        }

        rows.push(row)
      })
    }

    return {
      rows
    }
  }, [dataWizardTemplates, openEditWizard, currentWizard])

  return (
    <EntityPage
      pageTitle='Wizards'
      pageSubtitle='Generate Wizards and manage them in this view.'
      actionLabel={capitalize(t('generic.create'))}
      layout='one-column'
      isLoading={false}
      mainContent={(
        <>
          <CreateWizard
            isOpen={openCreateWizard}
            setIsOpen={setOpenCreateWizard}
            initialValues={null}
            initialMetadata={undefined}
          />
          <EditWizard
            isOpen={openEditWizard}
            setIsOpen={setOpenEditWizard}
            initialValues={currentWizard}
            initialMetadata={undefined}
          />
          <Table.V2
            mobileMergeColumns
            mobileMergeExludeLastColumn
            headers={[
              {
                text: 'Name',
                align: 'left'
              },
              {
                text: 'Status',
                align: 'left'
              },
              {
                text: 'Demo Link',
                align: 'left'
              },
              {
                text: 'Link to Wizard',
                align: 'left'
              },
              {
                text: 'Edit',
                align: 'left'
              }
            ]}
            mobileColumns={[1, 2, 3, 4, 5]}
            structure={structure}
          />
        </>
      )}
      onActionClick={createHandler}
    />
  )
}

export default Wizards
