import { AdvertisingMegaphone, BagSuitcase2, BlankCalendar, BookBookPages, CashPaymentBills, Cog, CoinShare, Diamond2, DollarCoin, HelpQuestion1, Invoice, Logout1, MoneyBagDollar, MultipleManWoman1, NotesCash, RatingStarBubble, SingleNeutralHome, ToolBox1, Web } from '#app/v2/assets/Icons'
import Images from '#app/v2/assets/Images'
export const profileStoryData = {
  bottomNavigation: {
    routes: {
      home: '/v2-ui/home',
      messages: '/v2-ui/messages',
      tasks: '/v2-ui/tasks',
      business: '/v2-ui/business',
    },
    isIOS: false,
    pendingMessages: 5,
  },
  profile: {
    image: Images.SamBOProfile,
    fullName: 'Hector Hustler',
    services: 'Handyman',
    clientId: 456
  },
  shortcuts: [
    {
      label: 'My Business',
      icon: BagSuitcase2,
      childrens: [
        {
          label: 'Insights',
          url: '/v2-ui/insights',
        },
        {
          label: 'Jobs',
          url: '/v2-ui/jobs',
        },
        {
          label: 'Reviews',
          url: '/v2-ui/reviews',
        },
        {
          label: 'Customers',
          url: '/v2-ui/customers',
        },
        {
          label: 'Estimates',
          url: '/v2-ui/estimates',
        },
        {
          label: 'Invoices',
          url: '/v2-ui/invoices',
        },
        {
          label: 'Subcontractors',
          url: '/v2-ui/subcontractors',
        },
        {
          label: 'Pricing book',
          url: '/v2-ui/pricing-book',
        },
      ],
    },
    {
      label: 'My Money',
      icon: DollarCoin,
      url: '/v2-ui/my-money',
    },
    {
      label: 'My Brand',
      icon: Diamond2,
      childrens: [
        {
          label: 'Design & Branding',
          url: '/v2-ui/design-branding',
        },
        {
          label: 'Photos',
          url: '/v2-ui/photos',
        },
        {
          label: 'My Website',
          url: '/v2-ui/website',
        },
        {
          label: 'Social Media',
          url: '/v2-ui/social-media',
        },
        {
          label: 'Print Store',
          url: '/v2-ui/print-store',
        },
      ],
    },
    {
      label: 'Booking',
      icon: BlankCalendar,
      url: '/v2-ui/booking',
    },
  ],
  extraShortcuts: [
    {
      label: 'Refearn & Earn',
      icon: CoinShare,
      url: '/v2-ui/referral',
    },
    {
      label: 'Settings',
      icon: Cog,
      url: '/v2-ui/settings',
    },
    {
      label: 'Help',
      icon: HelpQuestion1,
      url: '/v2-ui/help',
    },
    {
      label: 'In English',
      icon: Web,
      hasSwitch: {
        valueSwitch: true,
        onClickSwitch: () => { console.log('click dummy') },
      },
    },
  ],
  logout: {
    label: 'Logout',
    icon: Logout1,
    url: '/v2-ui/home',
  },
  editProfileUrl: '/v2-ui/edit-profile',
}

export const mvpShortcuts = {
  shortcuts: [
    {
      label: 'My Business',
      icon: BagSuitcase2,
      childrens: [
        {
          label: 'Jobs',
          url: '/v2-ui/jobs',
        },
        {
          label: 'Customers',
          url: '/v2-ui/customers',
        },
        {
          label: 'Invoices',
          url: '/v2-ui/invoices',
        },
      ],
    },
    {
      label: 'My Money',
      icon: DollarCoin,
      url: '/v2-ui/my-money',
    },
    {
      label: 'Booking',
      icon: BlankCalendar,
      url: '/v2-ui/booking',
    },
  ],
  extraShortcuts: [
    {
      label: 'Help',
      icon: HelpQuestion1,
      url: '/v2-ui/help',
    },
    {
      label: 'In English',
      icon: Web,
      hasSwitch: {
        valueSwitch: true,
        onClickSwitch: () => { console.log('click dummy') },
      },
    },
  ],
}

export const modalShortcuts = {
  shortcuts: [
    {
      label: 'Jobs',
      icon: ToolBox1,
      url: '/v2-ui/jobs',
    },
    {
      label: 'Leads',
      icon: AdvertisingMegaphone,
      url: '/v2-ui/leads',
    },
    {
      label: 'Clients',
      icon: SingleNeutralHome,
      url: '/v2-ui/clients',
    },
    {
      label: 'Invoices',
      icon: Invoice,
      url: '/v2-ui/invoices',
    },
    {
      label: 'Estimates',
      icon: NotesCash,
      url: '/v2-ui/estimates',
    },
    {
      label: 'Buscador',
      icon: NotesCash,
      url: '/v2-ui/search',
    },
    {
      label: 'Reviews',
      icon: RatingStarBubble,
      url: '/v2-ui/reviews',
    },
    {
      label: 'Subcontractors',
      icon: MultipleManWoman1,
      url: '/v2-ui/subcontractors',
    },
    {
      label: 'Money',
      icon: MoneyBagDollar,
      url: '/v2-ui/banking',
    },
    {
      label: 'Payments',
      icon: CashPaymentBills,
      url: '/v2-ui/payments'
    },
    {
      label: 'Pricing Book',
      icon: BookBookPages,
      url: '/v2-ui/pricing-book',
    },
  ],
  extraShortcuts: [
    {
      label: 'Settings',
      icon: Cog,
      url: '/v2-ui/settings',
    },
    {
      label: 'Help',
      icon: HelpQuestion1,
      url: '/v2-ui/help',
    },
  ],
  logout: {
    label: 'Logout',
    icon: Logout1,
    url: '/v2-ui/home',
  },
  editProfileUrl: '/v2-ui/edit-profile',
}
