import { useCallback, useEffect, useMemo, useState } from 'react'
import { BsArrowUpRightSquareFill, BsEnvelopeFill, BsLaptop, BsLink, BsNewspaper } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { Box, Card, Divider, Grid, Grow, IconButton } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import { useImmer } from 'use-immer'

import linearCircle from '#app/assets/img/linearCircle.png'
import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import { TaskTemplates } from '#app/feature/Tasks/Templates/TaskTemplates'
import { USER_SYSTEM_OWNERS } from '#app/hooks/useEntityUser'
import { useLinear } from '#app/hooks/useLinear'
import { Generic, Task, User } from '#app/types'
import { tags } from '#app/utils/vanilla/data'

import { LoadingGrid } from '../../../components/Loading/LoadingGrid'
import PageContent from '../../../components/PageContent/PageContent'
import { CreateTask } from '../../../feature/Tasks/CreateTask'
import { ViewTask } from '../../../feature/Tasks/ViewTask'
import { PreviewTaskContent } from '../../../feature/Tasks/ViewTask/PreviewTaskContent'
import { useDayJs } from '../../../hooks/useDayJs'
import { useEntityUserTasks } from '../../../hooks/useEntityUserTasks'

import { DomainGenerator } from './DomainGenerator'
import { TimelineDotExtended } from './styles'
import { WebsiteBuilder } from './WebsiteBuilder'
interface ItemTimeline {
  task: Task.UserTask
  datePoint: string
  status?: string
}

export function TasksTimeline({ currentOwner }: { currentOwner?: User.UserType }) {
  return (
    <>
      {currentOwner
      && <TasksTimelineContent currentOwner={currentOwner} />}
    </>
  )
}
export function TasksTimelineContent({ currentOwner }: { currentOwner: User.UserType }) {
  const [openCreate, setOpenCreate] = useState<boolean>(false)
  const [createInitialValues, setCreateInitialValues] = useState<Task.UserTask | null>(null)
  const [createMetadata, setCreateMetadata] = useState<any>(null)

  const [openViewTask, setOpenViewTask] = useState<boolean>(false)
  const [selectedTask, setSelectedTask] = useState<Task.UserTask | null>(null)

  const [showMetadata, setShowMetadata] = useState<boolean>(false)

  const [openNewTaskTemplate, setOpenNewTaskTemplate] = useState<boolean>(false)

  const [domainGenerator, setOpenDomainGenerator] = useState<boolean>(false)

  const [openWebsiteBuilder, setOpenWebsiteBuilder] = useState<boolean>(false)

  const [inlineSelectedTask, setInlineSelectedTask] = useImmer<{ arrayKey: string[] }>({
    arrayKey: []
  })

  const { linearIssueURL } = useLinear()

  const {
    queryGetUserTasks, queryGetUserTasksUtils,
    getIconByStatus, getTextFromStatus, getColorFromStatus, getIconByMetadata
  } = useEntityUserTasks(undefined, currentOwner.id, 'TasksTimelineContent')

  const navigate = useNavigate()
  const { dayjs } = useDayJs()
  const { enqueueSnackbar } = useSnackbar()

  const tasksTimeline: ItemTimeline[] = useMemo(() => {
    let tmpRes: ItemTimeline[] = []
    if (queryGetUserTasksUtils?.data?.user_tasks) {
      const allTasks = queryGetUserTasksUtils?.data?.user_tasks ?? []

      // Duplicate completed tasks to show in different positions in the timeline
      allTasks.map((task) => {
        tmpRes.push({
          datePoint: task.response_at ?? task.created_at,
          status: task.status,
          task
        })

        // TODO: Ask about this duplicated entry in the timeline
        // if (task.response_at) {
        //   tmpRes.push({
        //     datePoint: task.response_at,
        //     status: task.status,
        //     task
        //   })
        // }

        // tmpRes.push({
        //   datePoint: task.created_at,
        //   status: TASK_STATUS.ASSIGNED,
        //   task
        // })
      })

      try {
        tmpRes = tmpRes.sort((a, b) => {
          return dayjs(a.datePoint).isBefore(dayjs(b.datePoint)) ? 1 : -1
        })
      }
      catch (err) {
        console.error(err)
      }
    }

    return tmpRes
  }, [queryGetUserTasksUtils, dayjs])

  const initComponent = useCallback(async () => {
    await queryGetUserTasks({
      variables: {
        userID: currentOwner?.id
      }
    })
    return true
  }, [currentOwner?.id, queryGetUserTasks])

  const refreshView = async () => {
    await queryGetUserTasksUtils.refetch()
    enqueueSnackbar('List updated successfully')
    return null
  }

  useEffect(() => {
    if (currentOwner && !queryGetUserTasksUtils.error) {
      initComponent().then(() => {
        return true
      }).catch((err) => {
        console.error(err)
        return null
      })
    }
  }, [currentOwner, initComponent, queryGetUserTasksUtils.error])

  const openTaskByID = useCallback((task: Task.UserTask, mode: string, key: number) => {
    const keyString = key.toString()

    if (mode === 'dialog') {
      setSelectedTask(task)
      setOpenViewTask(true)
    }
    else if (mode === 'inline') {
      if (inlineSelectedTask.arrayKey.includes(keyString)) {
        setInlineSelectedTask((draft) => {
          draft.arrayKey = inlineSelectedTask.arrayKey.filter((keyVal) => keyVal !== keyString)
        })
      }
      else {
        setInlineSelectedTask((draft) => {
          draft.arrayKey.push(keyString)
        })
      }
    }
  }, [inlineSelectedTask, setInlineSelectedTask])

  const cbCopyTemplate = useCallback((task: Task.UserTask, templateId: Generic.UserTaskID) => {
    setCreateInitialValues(task)
    setCreateMetadata({
      template_id: templateId
    })
    setOpenNewTaskTemplate(false)
    setOpenCreate(true)
  }, [])

  return (
    <>
      <ViewTask
        currentOwner={currentOwner}
        task={selectedTask}
        isOpen={openViewTask}
        setIsOpen={setOpenViewTask}
      />
      <CreateTask
        initialValues={createInitialValues}
        initialMetadata={createMetadata}
        currentOwner={currentOwner}
        isOpen={openCreate}
        setIsOpen={setOpenCreate}
        cbSuccessCreate={refreshView}
      />
      {currentOwner
      && (
        <TaskTemplates
          cbCopyTemplate={cbCopyTemplate}
          currentOwner={currentOwner}
          open={openNewTaskTemplate}
          setOpen={setOpenNewTaskTemplate}
        />
      )}

      <DomainGenerator
        isOpen={domainGenerator}
        setIsOpen={setOpenDomainGenerator}
        userId={currentOwner.id}
      />

      <WebsiteBuilder
        isOpen={openWebsiteBuilder}
        setIsOpen={setOpenWebsiteBuilder}
        userId={currentOwner.id}
      />

      <Box mb={6}>
        <Box sx={{
          width: '100%',
          height: '200px'
        }}
        >
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box>
              <Btn.IconButton.Sync onClick={refreshView} />
              <Btn.IconButton.Collapse onClick={() => {
                setInlineSelectedTask((draft) => {
                  draft.arrayKey = []
                })
              }}
              />
              <Btn.IconButton.Info onClick={() => setShowMetadata(!showMetadata)} />
            </Box>
            <Box>
              <Grid
                container
                direction='row'
                spacing={2}
                columns={16}
              >
                <Grid
                  item
                  xs={4}
                >
                  <PageContent.PageActionButton
                    icon={<BsLaptop />}
                    label='Domain Generator'
                    onClick={() => {
                      setOpenDomainGenerator(true)
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                >
                  <PageContent.PageActionButton
                    icon={<BsArrowUpRightSquareFill />}
                    label='Website Builder'
                    onClick={() => {
                      setOpenWebsiteBuilder(true)
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                >
                  <PageContent.PageActionButton
                    icon={<BsEnvelopeFill />}
                    label='New Request'
                    onClick={() => {
                      setCreateInitialValues(null)
                      setCreateMetadata(null)
                      setOpenCreate(true)
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                >
                  <PageContent.PageActionButton
                    icon={<BsNewspaper />}
                    label='Send template'
                    onClick={() => setOpenNewTaskTemplate(true)}
                  />
                </Grid>
              </Grid>
            </Box>

          </Grid>
        </Box>
        {!queryGetUserTasksUtils.loading
        && (
          <Timeline position='alternate'>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDotExtended
                  color='primary'
                  onClick={() => setOpenCreate(true)}
                >
                  {getIconByStatus('create')}
                </TimelineDotExtended>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{
                py: '12px',
                px: 2
              }}
              >
              </TimelineContent>
            </TimelineItem>
            {
              tasksTimeline.map((entrypoint, key) => {
                return (
                  <div key={key}>
                    <TimelineItem>
                      <TimelineOppositeContent
                        sx={{
                          m: 'auto 0'
                        }}
                        variant='body2'
                        color='text.secondary'
                      >
                        {entrypoint.datePoint ? dayjs(entrypoint.datePoint).format('dddd, MMMM D, YYYY h:mm A') : ''}
                        {' '}
                        <br />
                        {entrypoint.datePoint ? `(${dayjs(entrypoint.datePoint).fromNow()})` : ''}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDotExtended
                          color={entrypoint.task.deleted ? 'inherit' : getColorFromStatus(entrypoint.status)}
                          onClick={() => openTaskByID(entrypoint.task, 'inline', key)}
                        >
                          {
                            entrypoint.task.deleted
                              ? getIconByStatus('deleted')
                              : getIconByStatus(entrypoint.status ?? '')
                          }
                          {
                            entrypoint.task.userByCreatedBy.email === USER_SYSTEM_OWNERS
                            && getIconByStatus('system')
                          }
                          {
                            entrypoint.task.metadata?.template_id
                            && getIconByMetadata('template_id')
                          }
                          {
                            entrypoint.task.metadata?.sequence_id
                            && getIconByMetadata('sequence_id')
                          }

                        </TimelineDotExtended>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{
                        py: '12px',
                        px: 2
                      }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              cursor: 'pointer',
                              marginBottom: 2
                            }}
                            variant='h6'
                            component='span'
                            onClick={() => openTaskByID(entrypoint.task, 'dialog', key)}
                          >
                            {entrypoint.task?.title}
                          </Typography>
                          <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          >
                            <IconButton
                              aria-label='task link'
                              onClick={() => navigate('/task/' + entrypoint.task.id)}
                            >
                              <BsLink />
                            </IconButton>
                            { // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                              entrypoint?.task?.linear_issue_id && (
                                <IconButton
                                  aria-label='custom icon link'
                                  onClick={() => window.open(`${linearIssueURL}/BOH-${entrypoint?.task?.linearIssueLinked?.number ?? ''}`, '_blank')}
                                >
                                  <img
                                    src={linearCircle}
                                    alt='custom icon'
                                    style={{
                                      width: 32,
                                      height: 32
                                    }}
                                  />
                                </IconButton>
                              )
                            }
                          </Box>
                        </Box>
                        <Box>
                          {
                            entrypoint.task.deleted
                            && (
                              <Chip.Basic
                                sx={{
                                  m: 0.4
                                }}
                                size='small'
                                label={getTextFromStatus('deleted')}
                                color='default'
                              />
                            )
                          }
                          <Chip.Basic
                            sx={{
                              m: 0.4
                            }}
                            size='small'
                            label={getTextFromStatus(entrypoint.status)}
                            color={getColorFromStatus(entrypoint.status)}
                          />
                          { entrypoint.task.tag_slug && (
                            <Chip.Basic
                              sx={{
                                m: 0.4
                              }}
                              size='small'
                              label={tags.find((e) => e.value === entrypoint.task.tag_slug)?.text ?? ''}
                              color='success'
                            />
                          )}
                          {
                            entrypoint.task.status !== entrypoint.status
                            && (
                              <Chip.Basic
                                sx={{
                                  m: 0.4
                                }}
                                size='small'
                                label={getTextFromStatus(entrypoint.task.status)}
                                color={getColorFromStatus(entrypoint.task.status)}
                              />
                            )
                          }
                          {
                            entrypoint.task?.metadata?.sequence_id
                            && (
                              <Chip.Basic
                                sx={{
                                  m: 0.4
                                }}
                                size='small'
                                label='Sequence'
                                icon={<BsLink />}
                                onClick={() => navigate(`/sequences?sequenceId=${entrypoint.task.metadata?.sequence_id as string}&sequenceExecution=${entrypoint.task.metadata?.sequence_execution_id as string}`)}
                              />
                            )
                          }
                          {
                            entrypoint.task.metadata?.template_id && !entrypoint.task.metadata?.sequence_id
                            && (
                              <Chip.Basic
                                sx={{
                                  m: 0.4
                                }}
                                size='small'
                                label='Template'
                                color='info'
                              />
                            )
                          }
                          {
                            showMetadata
                            && (
                              <>
                                <Divider sx={{
                                  m: 0.4
                                }}
                                />
                                <Chip.Basic
                                  sx={{
                                    m: 0.4
                                  }}
                                  size='small'
                                  label={`${entrypoint.task.id}`}
                                  color='default'
                                />
                                <Chip.User
                                  sx={{
                                    m: 0.4
                                  }}
                                  size='small'
                                  user={entrypoint.task.userByCreatedBy}
                                />
                              </>
                            )
                          }
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                    <Grow
                      in={inlineSelectedTask?.arrayKey.includes(key.toString())}
                      timeout={1000}
                    >
                      <Box>
                        {
                          inlineSelectedTask?.arrayKey.includes(key.toString())
                          && (
                            <Card sx={{
                              padding: '2rem'
                            }}
                            >
                              <Grid
                                container
                                justifyContent='flex-end'
                                style={{
                                  width: '100%'
                                }}
                              >
                                <Grid
                                  item
                                  xs
                                >
                                  <Btn.IconButton.Close
                                    sx={{
                                      float: 'right'
                                    }}
                                    onClick={() => {
                                      openTaskByID(entrypoint.task, 'inline', key)
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <PreviewTaskContent
                                adminSide
                                task={entrypoint.task}
                              />
                            </Card>
                          )
                        }
                      </Box>
                    </Grow>
                  </div>
                )
              })
            }
            <TimelineItem>
              <TimelineOppositeContent
                sx={{
                  m: 'auto 0'
                }}
                variant='body2'
                color='text.secondary'
              >
                {currentOwner?.created_at ? dayjs(currentOwner.created_at).format('dddd, MMMM D, YYYY h:mm A') : ''}
                {' '}
                <br />
                {currentOwner?.created_at ? `(${dayjs(currentOwner.created_at).fromNow()})` : ''}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDotExtended color='primary'>
                  {getIconByStatus('bo_created')}
                </TimelineDotExtended>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{
                py: '12px',
                px: 2
              }}
              >
                <Typography
                  variant='h6'
                  component='span'
                >
                  User Created
                </Typography>
                <Box>
                  <Chip.Basic
                    size='small'
                    label='app.joinowners.com'
                  />
                </Box>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        )}
        {
          queryGetUserTasksUtils.loading
          && <LoadingGrid />
        }
      </Box>
    </>
  )
}
