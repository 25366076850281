import { SxProps } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useField } from 'formik'

import FormLabel from '../FormLabel/FormLabel'

interface Props {
  placeholder?: string
  label?: string
  popoverText?: string
  options?: Array<{ text: string, value: string }>
  name?: string
  props?: any
  sx?: SxProps
  onChange?: (e: SelectChangeEvent) => void
  disabled?: boolean
}

const FormSelect = ({ placeholder = '', label, popoverText, sx = {}, onChange, ...props }: Props) => {
  // @ts-ignore
  const [, meta, helpers] = useField(props)
  const { setValue } = helpers
  const selectDisabled = props?.disabled

  const allOptions = props
    ?.options
    ?.map((e: { value: string, text: string, disabled?: boolean }) => {
      return {
        value: e.value,
        text: e.text,
        disabled: e.disabled === undefined ? false : e.disabled
      }
    })

  if (placeholder) {
    allOptions
      ?.unshift({
        value: '',
        text: placeholder,
        disabled: false
      })
  }

  return (
    <>
      {label && (
        <FormLabel
          label={label}
          popoverText={popoverText}
        />
      )}
      <Select
        fullWidth
        displayEmpty
        sx={sx}
        disabled={selectDisabled}
        value={meta.value}
        error={meta.touched && !!meta.error}
        onChange={(e) => {
          setValue(e.target.value)
          if (onChange) {
            onChange(e)
          }
        }}
      >
        {
          allOptions
            ?.map((e: { value: string, text: string, disabled?: boolean }) => {
              return (
                <MenuItem
                  key={`${e.value}-${e.text}`}
                  disabled={e.disabled}
                  value={e.value}
                >
                  {e.text}
                </MenuItem>
              )
            })
        }
      </Select>
      <div className='error'>{meta.touched && meta.error}</div>
    </>
  )
}

export default FormSelect
