import { Avatar, SxProps } from '@mui/material'
import ChipMUI from '@mui/material/Chip'

export function ChipLanguage({ lang, size, sx }: { lang: string, sx?: SxProps, size?: 'small' | 'medium' | undefined }) {
  return (
    <>
      {lang === 'es'
      && (
        <ChipMUI
          variant='outlined'
          avatar={<Avatar>ES</Avatar>}
          label='Spanish'
          sx={sx}
          size={size ?? 'medium'}
        />
      )}
      {lang === 'en'
      && (
        <ChipMUI
          variant='outlined'
          avatar={<Avatar>EN</Avatar>}
          label='English'
          sx={sx}
          size={size ?? 'medium'}
        />
      )}
      {
        lang !== 'es' && lang !== 'en'
        && (
          <ChipMUI
            variant='outlined'
            avatar={<Avatar>?</Avatar>}
            label='Language Error'
            sx={sx}
            size={size ?? 'medium'}
          />
        )
      }
    </>
  )
}
