import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import { capitalize } from 'lodash'

import CreateOwner from '#app/forms/OwnersManagement/CreateOwner'
import { getWhereClause } from '#app/hooks/useOwners'
import { ENVIROMENT } from '#app/store/enviroment'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

import PageContent from '../../../components/PageContent/PageContent'
import RightSideBar from '../../../components/RightSideBar/RightSideBar'
import { GET_BUSINESS_TYPE, GET_BUSINESSES } from '../../../utils/graphqlQueries'

export function Header() {
  const [sideBarStatus, setSideBarStatus] = useState<boolean>()
  const [showCreate, setShowCreate] = useState<boolean>(false)
  const isDesktop = useIsDesktopSize()
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { refetch } = useQuery<GET_BUSINESS_TYPE>(GET_BUSINESSES, {
    variables: {
      where: getWhereClause('%'),
      limit: 50
    }
  })

  // @ts-ignore
  const submitCallback = (result, error) => {
    if (error) {
      console.error(error)
    }
    else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      refetch()
      setSideBarStatus(false)
    }
  }

  const createHandler = () => {
    // setShowEdit(false)
    setShowCreate(true)
    setSideBarStatus(true)
  }

  return (
    <>
      <PageContent.PageHeader>
        <Typography
          variant='h4'
          sx={{
            cursor: 'pointer'
          }}
          onClick={() => navigate('/owners-management')}
        >
          {!isDesktop && params?.userId && <>&#60;</>}
          {' '}
          Owners Management
        </Typography>
        <PageContent.PageAction>
          <PageContent.PageActionButton
            label={capitalize(t('generic.create'))}
            onClick={createHandler}
          />
        </PageContent.PageAction>
      </PageContent.PageHeader>
      <RightSideBar
        isOpen={sideBarStatus}
        closeHandler={() => setSideBarStatus(false)}
      >
        <Box sx={{
          height: '90vh'
        }}
        >
          <iframe
            className='airtable-embed'
            src={ENVIROMENT.airtable_create_bo}
            frameBorder='0'
            width='100%'
            height='100%'
            style={{
              background: 'transparent'
            }}
          >
          </iframe>
          {
            process.env.REACT_APP_ENV === 'development'
            && <CreateOwner submitCallback={submitCallback} />
          }
        </Box>
      </RightSideBar>
    </>
  )
}
