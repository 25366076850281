import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { Box, Grid, Stack, Typography } from '@mui/material'

import Button from '#app/components/Buttons/Button'
import { GetBankAccountsQuery, GetBankingDetailsQuery } from '#app/operations/banking/banking.queries.generated'
import { BankTransferState } from '#app/store/bank-transfer'

interface Props {
  transferDetails: BankTransferState
  bankingDetails?: GetBankingDetailsQuery
  banks?: GetBankAccountsQuery
  onSubmit: () => Promise<void>
  onCancel: () => void
}

export const BankTransferConfirmation = ({ transferDetails, onCancel, banks, onSubmit, bankingDetails }: Props) => {
  const [isLoading, setLoading] = useState<boolean>()
  const { t } = useTranslation()

  const onSubmitWithLoading = async () => {
    setLoading(true)

    try {
      await onSubmit()
    }
    catch (err) {
      // do nothing
    }
    finally {
      setLoading(false)
    }
  }

  const amount = transferDetails.amountSetting === 'total'
    ? ((bankingDetails?.users[0]?.stripe_financial_acc?.balance?.cash?.usd ?? 0) / 100)
    : (transferDetails.amount ?? 0)

  const selectedBank = banks?.bank_accounts
    ?.find((bank) => bank.stripe_bank_id === transferDetails?.bank)

  const bank = transferDetails?.bank === 'new'
    ? {
      holderName: transferDetails.accountHolderName ?? '',
      accountNumber: transferDetails.accountNumber ?? '',
      routingNumber: transferDetails.routingNumber ?? ''
    }
    : {
      holderName: selectedBank?.metadata?.account_holder_name ?? '',
      accountNumber: selectedBank?.metadata?.metadata?.accountNumber ?? '',
      routingNumber: selectedBank?.metadata?.metadata?.routingNumber ?? ''
    }

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid item>
        <Stack spacing={2}>
          <Typography variant='h6'>{ t('bankTransferConfirmation.title', 'Confirmation') }</Typography>
          <Typography>
            { t('bankTransferConfirmation.verifyInformation', 'Verify the information is correct') }
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Box sx={{
          borderRadius: 1,
          padding: 2,
          backgroundColor: 'var(--main-content-bg-color)'
        }}
        >
          <Stack>
            <Typography
              sx={{
                textAlign: 'center'
              }}
              variant='h1'
            >
              <NumericFormat
                thousandSeparator=','
                style={{
                  fontWeight: 'bold',
                  letterSpacing: 2
                }}
                displayType='text'
                value={amount}
                prefix='$'
              />
            </Typography>
            <Typography sx={{
              textAlign: 'center',
              fontWeight: 'bold'
            }}
            >
              { t('bankTransferConfirmation.withdrawalAmount', 'Withdrawal Amount') }
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Stack spacing={1}>
          <Stack
            direction='row'
            spacing={1}
          >
            <Typography>
              { t('bankTransferConfirmation.bankAccountHolder', 'Bank Account Holder:') }
            </Typography>
            <Typography style={{
              fontWeight: 'bold'
            }}
            >
              { bank.holderName }
            </Typography>
          </Stack>
          <Stack
            direction='row'
            spacing={1}
          >
            <Typography>
              { t('bankTransferConfirmation.bankRoutingNumber', 'Bank Routing Number:') }
            </Typography>
            <Typography style={{
              fontWeight: 'bold',
              letterSpacing: 2
            }}
            >
              { bank.routingNumber }
            </Typography>
          </Stack>
          <Stack
            direction='row'
            spacing={1}
          >
            <Typography>
              { t('bankTransferConfirmation.bankAccountNumber', 'Bank Account Number:') }
            </Typography>
            <Typography style={{
              fontWeight: 'bold',
              letterSpacing: 2
            }}
            >
              { bank.accountNumber }
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Stack
          direction='row'
          spacing={1}
        >
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            onClick={onSubmitWithLoading}
          >
            { t('bankTransferConfirmation.transfer', 'Transfer') }
          </Button>
          <Button
            primary={false}
            onClick={onCancel}
          >
            { t('bankTransferConfirmation.cancel', 'Cancel') }
          </Button>
        </Stack>
      </Grid>
    </Grid>
  )
}
