import { useMemo } from 'react'
import Gravatar from 'react-gravatar'
import { BsPersonFill } from 'react-icons/bs'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import { deepPurple } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'

import { User } from '#app/types'

import { getInitialsUser } from './ChipUser'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{
      popper: className
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // @ts-ignore
    backgroundColor: theme?.palette?.common?.white,
    color: 'rgba(0, 0, 0, 0.87)',
    // @ts-ignore
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))

export function ChipUserAvatar({ user, email, fullname }: { user?: User.UserType, email?: string, fullname?: string }) {
  const userName = useMemo(() => {
    if (user) return `${user?.first_name} ${user?.last_name}`
    if (fullname) return fullname
    if (email) return email
    return 'Owners'
  }, [email, fullname, user])

  return (
    <Box>
      <LightTooltip title={userName}>
        <>
          {
            email && (
              <Gravatar
                email={email}
                size={40}
                style={{
                  borderRadius: '50%'
                }}
              />
            )
          }
          { !email
          && (
            <Avatar sx={{
              bgcolor: deepPurple[500]
            }}
            >
              {userName ? getInitialsUser(userName) : <BsPersonFill />}
            </Avatar>
          )}
        </>
      </LightTooltip>
    </Box>
  )
}
