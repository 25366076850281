import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type WebsiteEditorCreateMutationVariables = Types.Exact<{
  config?: Types.InputMaybe<Types.Scalars['jsonb']>
  user_id?: Types.InputMaybe<Types.Scalars['ID']>
}>

export type WebsiteEditorCreateMutation = { __typename?: 'mutation_root', websiteEditorCreate?: { __typename?: 'WebsiteEditorCreatePayload', success: boolean, isNew: boolean } | undefined }

export type WebsiteEditorSaveChangesMutationVariables = Types.Exact<{
  input: Types.WebsiteEditorSaveChangesInput
}>

export type WebsiteEditorSaveChangesMutation = { __typename?: 'mutation_root', websiteEditorSaveChanges?: { __typename?: 'WebsiteEditorSaveChangesPayload', success: boolean, message?: string | undefined } | undefined }

export type WebsiteEditorNewDeploymentMutationVariables = Types.Exact<{
  input: Types.WebsiteEditorNewDeploymentInput
}>

export type WebsiteEditorNewDeploymentMutation = { __typename?: 'mutation_root', websiteEditorNewDeployment?: { __typename?: 'WebsiteEditorNewDeploymentPayload', success?: number | undefined, data?: any | undefined } | undefined }

export const WebsiteEditorCreateDocument = gql`
    mutation WebsiteEditorCreate($config: jsonb, $user_id: ID) {
  websiteEditorCreate(input: {config: $config, user_id: $user_id}) {
    success
    isNew
  }
}
    `
export type WebsiteEditorCreateMutationFn = Apollo.MutationFunction<WebsiteEditorCreateMutation, WebsiteEditorCreateMutationVariables>

/**
 * __useWebsiteEditorCreateMutation__
 *
 * To run a mutation, you first call `useWebsiteEditorCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebsiteEditorCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [websiteEditorCreateMutation, { data, loading, error }] = useWebsiteEditorCreateMutation({
 *   variables: {
 *      config: // value for 'config'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useWebsiteEditorCreateMutation(baseOptions?: Apollo.MutationHookOptions<WebsiteEditorCreateMutation, WebsiteEditorCreateMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<WebsiteEditorCreateMutation, WebsiteEditorCreateMutationVariables>(WebsiteEditorCreateDocument, options)
}
export type WebsiteEditorCreateMutationHookResult = ReturnType<typeof useWebsiteEditorCreateMutation>
export type WebsiteEditorCreateMutationResult = Apollo.MutationResult<WebsiteEditorCreateMutation>
export type WebsiteEditorCreateMutationOptions = Apollo.BaseMutationOptions<WebsiteEditorCreateMutation, WebsiteEditorCreateMutationVariables>
export const WebsiteEditorSaveChangesDocument = gql`
    mutation WebsiteEditorSaveChanges($input: WebsiteEditorSaveChangesInput!) {
  websiteEditorSaveChanges(input: $input) {
    success
    message
  }
}
    `
export type WebsiteEditorSaveChangesMutationFn = Apollo.MutationFunction<WebsiteEditorSaveChangesMutation, WebsiteEditorSaveChangesMutationVariables>

/**
 * __useWebsiteEditorSaveChangesMutation__
 *
 * To run a mutation, you first call `useWebsiteEditorSaveChangesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebsiteEditorSaveChangesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [websiteEditorSaveChangesMutation, { data, loading, error }] = useWebsiteEditorSaveChangesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWebsiteEditorSaveChangesMutation(baseOptions?: Apollo.MutationHookOptions<WebsiteEditorSaveChangesMutation, WebsiteEditorSaveChangesMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<WebsiteEditorSaveChangesMutation, WebsiteEditorSaveChangesMutationVariables>(WebsiteEditorSaveChangesDocument, options)
}
export type WebsiteEditorSaveChangesMutationHookResult = ReturnType<typeof useWebsiteEditorSaveChangesMutation>
export type WebsiteEditorSaveChangesMutationResult = Apollo.MutationResult<WebsiteEditorSaveChangesMutation>
export type WebsiteEditorSaveChangesMutationOptions = Apollo.BaseMutationOptions<WebsiteEditorSaveChangesMutation, WebsiteEditorSaveChangesMutationVariables>
export const WebsiteEditorNewDeploymentDocument = gql`
    mutation WebsiteEditorNewDeployment($input: WebsiteEditorNewDeploymentInput!) {
  websiteEditorNewDeployment(input: $input) {
    success
    data
  }
}
    `
export type WebsiteEditorNewDeploymentMutationFn = Apollo.MutationFunction<WebsiteEditorNewDeploymentMutation, WebsiteEditorNewDeploymentMutationVariables>

/**
 * __useWebsiteEditorNewDeploymentMutation__
 *
 * To run a mutation, you first call `useWebsiteEditorNewDeploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebsiteEditorNewDeploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [websiteEditorNewDeploymentMutation, { data, loading, error }] = useWebsiteEditorNewDeploymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWebsiteEditorNewDeploymentMutation(baseOptions?: Apollo.MutationHookOptions<WebsiteEditorNewDeploymentMutation, WebsiteEditorNewDeploymentMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<WebsiteEditorNewDeploymentMutation, WebsiteEditorNewDeploymentMutationVariables>(WebsiteEditorNewDeploymentDocument, options)
}
export type WebsiteEditorNewDeploymentMutationHookResult = ReturnType<typeof useWebsiteEditorNewDeploymentMutation>
export type WebsiteEditorNewDeploymentMutationResult = Apollo.MutationResult<WebsiteEditorNewDeploymentMutation>
export type WebsiteEditorNewDeploymentMutationOptions = Apollo.BaseMutationOptions<WebsiteEditorNewDeploymentMutation, WebsiteEditorNewDeploymentMutationVariables>
