import { useContext, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import LogoOwners from '#app/assets/svg/logo-owners-light.svg'
import ProfileContext from '#app/contexts/ProfileContext'
import { useGetUserWizardByIdQuery } from '#app/operations/wizards/wizards.queries.generated'
import { WizardContainer } from '#app/v2/pages/Wizard/page'

const UserWizard = () => {
  const { wizardId } = useParams()
  const [searchParams] = useSearchParams()
  const [currentStep, setCurrentStep] = useState<string>('1')
  const taskId = searchParams.get('taskId')
  const user = useContext(ProfileContext).getProfile?.user
  const { data: wizardData, loading } = useGetUserWizardByIdQuery({
    variables: {
      wizardId,
      userId: user?.id,
      taskId
    }
  })

  useEffect(() => {
    if (wizardData?.getUserWizardById?.wizard?.question_stack?.length && wizardData?.getUserWizardById?.wizard?.wizard_flow?.length) {
      setCurrentStep(`${wizardData?.getUserWizardById?.wizard?.current_step}`)
    }
  }, [wizardData])

  return (
    <WizardContainer
      wizardData={wizardData?.getUserWizardById?.wizard?.wizard_flow}
      wizardMetadata={wizardData?.getUserWizardById?.wizard?.metadata}
      wizardQuestionHistory={wizardData?.getUserWizardById?.wizard?.question_stack_history}
      wizardQuestionStack={wizardData?.getUserWizardById?.wizard?.question_stack}
      isWizardCompleted={wizardData?.getUserWizardById?.wizard?.status === 'completed'}
      loading={loading}
      backUrl='/v2-ui/home'
      logo={LogoOwners}
      currentStep={currentStep}
      initialStepTitle={wizardData?.getUserWizardById?.wizard?.title}
      initialStepSubtitle={wizardData?.getUserWizardById?.wizard?.subtitle}
      wizardId={wizardData?.getUserWizardById?.wizard?.id}
      userId={user?.id}
      taskId={taskId ?? undefined}
      textEnd1={wizardData?.getUserWizardById?.wizard?.text_end_1}
      textEnd2={wizardData?.getUserWizardById?.wizard?.text_end_2}
    />
  )
}

export default UserWizard
