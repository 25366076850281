import { useTranslation } from 'react-i18next'

import { ChatTwoBubblesOval } from '../assets/Icons'

export const EmptyMessageList = () => {
  const { t } = useTranslation()
  return (
    <div className='my-10 space-y-3 text-center text-[#304985]'>
      <ChatTwoBubblesOval className='mx-auto h-12 w-12' />
      <h2 className='font-semibold'>{t('v2.messages.emptyList.title', 'No messages')}</h2>
      <p className=' text-sm text-[#454545]'>{t('v2.messages.emptyList.description', 'You don\'t have any messages yet.')}</p>
    </div>
  )
}
