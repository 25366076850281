import { BusinessOwnerModular } from '../../../feature/BusinessOwnerModular'

import { Header } from './Header'
import { TasksTimeline } from './TasksTimeline'

export function OwnersTasks() {
  return (
    <BusinessOwnerModular
      title='Owners Inbox'
      pathname='owners-tasks'
      header={Header}
      contentByOwner={TasksTimeline}
    />
  )
}
