import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Alert, AlertTitle, Box, Link } from '@mui/material'

import { Chip } from '#app/components/Chip'
import { ChipUserEmail } from '#app/components/Chip/ChipUserEmail'
import { Table } from '#app/components/Table'
import { TableStructureV2Row } from '#app/components/Table/TableV2'
import { useEntitySequenceExecutions } from '#app/hooks/useEntitySequenceExecutions'
import { Generic, Sequence } from '#app/types'

interface UserDataLog {
  id: Generic.UserID
  email: string
}

export function SequenceExecutions({ sequenceId }: { sequenceId: Generic.SequenceID }) {
  const {
    queryGetSequenceExecutions, queryGetSequenceExecutionsData
  } = useEntitySequenceExecutions({
    variables: {
      sequence_id: sequenceId
    }
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()

  const sequenceExecutionFilter = useMemo(() => {
    return searchParams.get('sequenceExecution')
  }, [searchParams])

  const initView = useCallback(async () => {
    await queryGetSequenceExecutions()
  }, [queryGetSequenceExecutions])

  useEffect(() => {
    void initView()
  }, [initView])

  const navigate = useNavigate()

  const getUsersPayload = useCallback((sequenceExecution: Sequence.SequenceExecution) => {
    if (sequenceExecution?.payload?.users) {
      return sequenceExecution?.payload?.users as UserDataLog[]
    }
    return []
  }, [])

  const getInfoMessages = useCallback((sequenceExecution: Sequence.SequenceExecution) => {
    if (sequenceExecution?.metadata?.info) {
      return sequenceExecution?.metadata?.info as Array<{ user: UserDataLog, data?: any, message: string, type: string }>
    }
    return []
  }, [])

  const getErrorMessages = useCallback((sequenceExecution: Sequence.SequenceExecution) => {
    if (sequenceExecution?.metadata?.errors) {
      return sequenceExecution?.metadata?.errors as Array<{ user: UserDataLog, error?: string, message: string, type: string }>
    }
    return []
  }, [])

  const tableStructure = useMemo(() => {
    const structure: { rows: TableStructureV2Row[] } = {
      rows: []
    }

    queryGetSequenceExecutionsData
      ?.sequence_executions
      ?.filter((sequenceExecution) => sequenceExecutionFilter ? sequenceExecutionFilter === sequenceExecution.id : true)
      .map((sequenceExecution) => {
        const usersPayload = getUsersPayload(sequenceExecution)
        const infoMessages = getInfoMessages(sequenceExecution)
        const errorMessages = getErrorMessages(sequenceExecution)

        const row: TableStructureV2Row = {
          key: sequenceExecution.id,
          columns: [
            {
              component: (
                <Chip.Basic
                  sx={{
                    m: 1
                  }}
                  label={sequenceExecution.type}
                />
              )
            },
            {
              component: (
                <Alert
                  sx={{
                    m: 1,
                    mb: 2
                  }}
                  severity='info'
                >
                  <AlertTitle>
                    Users Involved
                    {usersPayload?.length && (
                      <>
                        &#40;
                        {usersPayload?.length}
                        &#41;
                      </>
                    )}
                  </AlertTitle>
                  {usersPayload.map((user) => {
                    return (
                      <ChipUserEmail
                        sx={{
                          m: 1
                        }}
                        userData={user}
                      />
                    )
                  })}
                </Alert>
              )
            },
            {
              component:
  <Box sx={{
    m: 1
  }}
  >
    {infoMessages.map((info) => {
      return (
        <Alert
          sx={{
            mb: 1
          }}
          severity={info.type === 'success' ? 'success' : 'info'}
        >
          {info.user && <ChipUserEmail userData={info.user} />}
          {info.message && ` ${info.message}`}
          {info?.data?.task_id
          && (
            <Box>
              task ID:
              {' '}
              {info.data.task_id as string}
&nbsp;
              <Link
                component='button'
                onClick={() => navigate(`/task/${info.data.task_id as string}`)}
              >
                more info
              </Link>
            </Box>
          )}
        </Alert>
      )
    })}

    {errorMessages.map((error) => {
      return (
        <Alert
          sx={{
            mb: 1
          }}
          severity='error'
        >
          {error.user && <ChipUserEmail userData={error.user} />}
          {error.message && ` ${error.message}`}
          {error.error && JSON.stringify(error.error)}
        </Alert>
      )
    })}
  </Box>
            },
            {
              component: (
                <Chip.SequenceStatus
                  sx={{
                    m: 1
                  }}
                  status={sequenceExecution.status}
                />
              )
            }
          ]
        }

        structure.rows.push(row)
      })

    return structure
  }, [getErrorMessages, getInfoMessages, getUsersPayload, navigate, queryGetSequenceExecutionsData?.sequence_executions, sequenceExecutionFilter])

  return (
    <>
      <Chip.Filter
        sx={{
          m: 1
        }}
        label={`Sequence: ${sequenceId}`}
        onDelete={() => {
          searchParams.delete('sequenceId')
          setSearchParams(searchParams)
        }}
      />
      {
        sequenceExecutionFilter
        && (
          <Chip.Filter
            sx={{
              m: 1
            }}
            label={`Execution: ${sequenceExecutionFilter}`}
            onDelete={() => {
              searchParams.delete('sequenceExecution')
              setSearchParams(searchParams)
            }}
          />
        )
      }
      <Table.V2
        mobileMergeColumns
        headers={[
          {
            text: 'Type'
          },
          {
            text: 'Palyoad'
          },
          {
            text: 'Metadata'
          },
          {
            text: 'Status'
          }
        ]}
        structure={tableStructure}
      />
    </>
  )
}
