import { useTranslation } from 'react-i18next'
import { IoMenuOutline } from 'react-icons/io5'
import { Link, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Drawer } from 'vaul'

import { useUIStories } from '#app/hooks/useUIStories'
import { MoneyBagDollar, Pen, QrCode } from '#app/v2/assets/Icons'
import { cn, useExpoStatus } from '#app/v2/libs/utils'
import { Shortcut, ShortcutProps } from '#app/v2/pages/MyProfile/components/Shortcut'
import { ProfileProps } from '#app/v2/pages/MyProfile/page'

import { Button } from './ui/button'
import { Separator } from './ui/separator'
import { opacityBlurVariant, pageTransition } from './animations'
import { ProfileAvatarDetailed } from './ProfileAvatarDetailed'

export const SideMenuProfile = (props: ProfileProps) => {
  const { avatar, fullName, services, editProfileUrl, shortcuts, extraShortcuts, logout, clientId } = props
  const { t } = useTranslation()
  const { isStoryMode } = useUIStories()
  const { pathname } = useLocation()
  const isExpo = useExpoStatus()
  let sideMenuProfileVariants = {}

  if (!isExpo) {
    sideMenuProfileVariants = opacityBlurVariant
  }
  return (
    <Drawer.Root
      direction='left'
    >
      <Drawer.Trigger asChild>
        <motion.div
          variants={sideMenuProfileVariants}
          transition={pageTransition}
          className='inline-flex'
        >
          <Button
            className='w-fit p-0'
            variant='ghost'
          >
            <IoMenuOutline className=' h-6 w-6' />
          </Button>
        </motion.div>
      </Drawer.Trigger>
      <Drawer.Portal>
        <Drawer.Overlay className='fixed inset-0 z-40 bg-black/25' />
        <Drawer.Content className='fixed inset-y-0 left-0 z-50 flex h-full w-[340px] flex-col bg-white shadow'>
          <div className='flex h-full flex-1 flex-col bg-[#F9F9F9] pt-8'>
            <div className='flex items-center gap-2 px-5'>
              <ProfileAvatarDetailed
                onlyAvatar
                profilePictureUrl={avatar}
                fullName={fullName}
                className=' h-16 w-16'
              />
              <div className='space-y-1 font-semibold'>
                <h4 className='text-xl/5 text-[#304985]'>{fullName}</h4>
                <p className='text-sm/4 text-[#454545]'>{services}</p>
              </div>
              <Button
                className={cn('rounded-lg bg-white p-2 text-[#304985]', {
                  hidden: !isStoryMode
                })}
              >
                <QrCode className='h-5 w-5' />
              </Button>
              <Button
                asChild
                className=' rounded-lg p-2'
              >
                <Drawer.Close asChild>
                  <Link
                    to={editProfileUrl ? editProfileUrl : ''}
                    state={{
                      from: pathname,
                      to: editProfileUrl ?? ''
                    }}
                  >
                    <Pen className='h-5 w-5' />
                  </Link>
                </Drawer.Close>
              </Button>
            </div>
            <p className='mb-3 ml-16 mt-4 px-5 pl-2 text-sm/4 text-[#BDBDBD]'>
              {t('v2Profile.editProfile.clientId', 'Client ID')}
              :
              {' '}
              {clientId}
            </p>
            <div className='flex-1 space-y-2 overflow-auto px-5 pb-6 text-[#304985]'>
              {shortcuts
                ? (shortcuts.map((shortcut) =>
                  (
                    <Shortcut
                      key={shortcut.label}
                      label={shortcut.label}
                      icon={shortcut.icon}
                      url={shortcut.url}

                      onClick={shortcut.onClick}
                    />
                  )))
                : null}
              <Separator className='bg-[#304985]/40' />
              {extraShortcuts
                ? extraShortcuts.map((shortcut: ShortcutProps) => (
                  <Shortcut
                    key={shortcut.label}
                    label={shortcut.label}
                    icon={shortcut.icon}
                    url={shortcut.url}

                    hasSwitch={shortcut.hasSwitch}
                    onClick={shortcut.onClick}
                  />
                ),
                )
                : null}
              {logout?.url && logout?.icon
                ? (
                  <div className='text-[#D81C1C]'>
                    <Shortcut
                      label={logout.label}
                      icon={logout.icon}
                      url={logout.url}
                    />
                  </div>
                )
                : logout?.onClick && logout?.icon
                  ? (
                    <div className='text-[#D81C1C]'>
                      <Shortcut
                        label={logout.label}
                        icon={logout.icon}
                        onClick={logout.onClick}
                      />
                    </div>
                  )
                  : <>Logout</>}
            </div>
            <div className={cn('flex w-full items-center gap-5 bg-[#6BE7BE] px-4 py-5 pl-8 text-[#304985]', {
              hidden: !isStoryMode
            })}
            >
              <MoneyBagDollar
                fillPath='#304985'
                className=' h-14 w-10 shrink-0 text-[#102770]'
                extraClassName='text-white'
              />
              <p className='text-xl font-medium'>
                Refer friends & earn a
                {' '}
                <span className='font-semibold'>$250</span>
                {' '}
                reward!
              </p>
            </div>
          </div>
          <div className={cn('flex w-full items-center gap-5 bg-[#6BE7BE] px-4 py-5 pl-8 text-[#304985]', {
            hidden: !isStoryMode
          })}
          >
            <MoneyBagDollar
              fillPath='#304985'
              className=' h-14 w-10 shrink-0 text-[#102770]'
              extraClassName='text-white stroke-1'
            />
            <p className='text-xl font-medium'>
              Refer friends & earn a
              {' '}
              <span className='font-semibold'>$250</span>
              {' '}
              reward!
            </p>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  )
}
