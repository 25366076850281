// @ts-nocheck
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { SentryLink } from 'apollo-link-sentry'
import { useSnackbar } from 'notistack'

export const ApolloProviderWithClient = ({ children, isPublic = false, ...props }: { children?: JSX.Element, isPublic?: boolean, props?: any }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const [graphQl, setGraphQl] = useState()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const errorLink = onError(({ graphQLErrors, response, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map((error) => {
        const { message, extensions } = error
        const errorTranslationKey = `errors.${String(extensions?.code)}`
        const translatedError = t(`errors.${String(extensions?.code)}`)

        const errMessage = errorTranslationKey && errorTranslationKey !== translatedError ? translatedError : message
        enqueueSnackbar(errMessage, {
          variant: 'error'
        })
        // @ts-ignore
        Sentry.captureException(message)
      })
    }

    // only report this if the user on-line
    if (networkError && navigator.onLine) {
      // I want to keep monitoring this for a while before preventing these errors from getting to sentry
      // const isFailedToFetchOrToLoad = networkError
      //   .toString()
      //   ?.toLowerCase()
      //   ?.includes('failed to fetch') ||
      //   networkError
      //     .toString()
      //     ?.toLowerCase()
      //     ?.includes('load failed')

      // if (isFailedToFetchOrToLoad && !response?.data) {
      //   // if it is a failed to fetch or to load error and there is no data then we don't want to report it
      //   // if there was a true backend error (and it wasn't a connection error due to slow network / high latency) it will get reported through GCP
      //   console. log('[Owners - Network error]: Skipping reporting failed to load or fetch')
      //   return
      // }

      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const msgNetwork = `[Owners - Network error]: ${networkError.toString()}`
      Sentry.captureException(msgNetwork)
    }
  })
  // <==== Sentry

  useEffect(() => {
    if (!isAuthenticated && !isPublic) return

    if (graphQl) return

    const onlineMiddleware = new ApolloLink((operation, forward) => {
      const operationType = operation?.query?.definitions?.[0]?.operation

      // only try this when online
      if (!navigator.onLine) {
        if (operationType === 'mutation') {
          enqueueSnackbar(t('general.offline'), {
            variant: 'error'
          })
        }

        return null
      }

      return forward(operation)
    })

    const authMiddleware = new ApolloLink(async (operation, forward) => {
      const token = await getAccessTokenSilently({
        scope: 'offline_access'
      })

      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : ''
        }
      })

      return forward(operation)
    })

    const httpLink = createHttpLink({
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      uri: `${process.env.REACT_APP_OWNERS_HASURA}/v1/graphql`
    })

    const linksChain = [onlineMiddleware]

    if (!isPublic) {
      linksChain.push(authMiddleware)
    }
    // export const defaultOptions = {
    //   shouldHandleOperation: undefined,
    //   uri: undefined,
    //   setTransaction: true,
    //   setFingerprint: true,

    //   attachBreadcrumbs: {
    //     includeQuery: false,
    //     includeVariables: false,
    //     includeFetchResult: false,
    //     includeError: false,
    //     includeCache: false,
    //     includeContext: false,
    //     transform: undefined,
    //   },
    // } as const;

    const sentryLink = new SentryLink({
      attachBreadcrumbs: {
        includeQuery: true,
        includeVariables: true,
        includeFetchResult: true,
        includeError: true,
        includeCache: false,
        includeContext: ['headers'],
        transform: undefined
      }
    })

    linksChain.push(errorLink, sentryLink, httpLink)

    const client = new ApolloClient({
      link: ApolloLink.from(linksChain),
      cache: new InMemoryCache({
        typePolicies: {
          GetUserWizardByIdPayload: {
            keyFields: (obj) => {
              console.log(obj)
              return `${obj.wizard.user_id}${obj.wizard.task_id}`
            }
          }
        }
      }),
      connectToDevTools: process.env.REACT_APP_ENV !== 'production'
    })

    // @ts-ignore
    setGraphQl(client)
  }, [errorLink, getAccessTokenSilently, graphQl, isAuthenticated])

  if (!graphQl) {
    return <></>
  }

  return (
    <ApolloProvider
      client={graphQl}
      {...props}
    >
      {children}
    </ApolloProvider>
  )
}
