import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type CreateWizardTemplateMutationVariables = Types.Exact<{
  wizard_flow?: Types.InputMaybe<Types.Scalars['jsonb']>
  title: Types.Scalars['String']
  subtitle?: Types.InputMaybe<Types.Scalars['String']>
  text_end_1: Types.Scalars['String']
  text_end_2: Types.Scalars['String']
}>

export type CreateWizardTemplateMutation = { __typename?: 'mutation_root', createWizardTemplate?: { __typename?: 'CreateWizardTemplateOutput', result: boolean, wizard?: any | undefined } | undefined }

export type EditWizardTemplateMutationVariables = Types.Exact<{
  wizard_flow?: Types.InputMaybe<Types.Scalars['jsonb']>
  title: Types.Scalars['String']
  subtitle?: Types.InputMaybe<Types.Scalars['String']>
  id: Types.Scalars['uuid']
  text_end_1?: Types.InputMaybe<Types.Scalars['String']>
  text_end_2?: Types.InputMaybe<Types.Scalars['String']>
}>

export type EditWizardTemplateMutation = { __typename?: 'mutation_root', editWizardTemplate?: { __typename?: 'EditWizardTemplateOutput', result: boolean, wizard?: any | undefined } | undefined }

export type EditUserWizardProgressMutationVariables = Types.Exact<{
  metadata?: Types.InputMaybe<Types.Scalars['jsonb']>
  questionStackHistory?: Types.InputMaybe<Types.Scalars['jsonb']>
  questionStack?: Types.InputMaybe<Types.Scalars['jsonb']>
  status?: Types.InputMaybe<Types.Scalars['String']>
  wizardId: Types.Scalars['uuid']
  userId: Types.Scalars['uuid']
  taskId?: Types.InputMaybe<Types.Scalars['uuid']>
  currStep?: Types.InputMaybe<Types.Scalars['Int']>
}>

export type EditUserWizardProgressMutation = { __typename?: 'mutation_root', editUserWizardProgress?: { __typename?: 'EditUserWizardProgressPayload', result: boolean, wizard?: any | undefined } | undefined }

export const CreateWizardTemplateDocument = gql`
    mutation createWizardTemplate($wizard_flow: jsonb, $title: String!, $subtitle: String, $text_end_1: String!, $text_end_2: String!) {
  createWizardTemplate(
    input: {wizard_flow: $wizard_flow, title: $title, subtitle: $subtitle, text_end_1: $text_end_1, text_end_2: $text_end_2}
  ) {
    result
    wizard
  }
}
    `
export type CreateWizardTemplateMutationFn = Apollo.MutationFunction<CreateWizardTemplateMutation, CreateWizardTemplateMutationVariables>

/**
 * __useCreateWizardTemplateMutation__
 *
 * To run a mutation, you first call `useCreateWizardTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWizardTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWizardTemplateMutation, { data, loading, error }] = useCreateWizardTemplateMutation({
 *   variables: {
 *      wizard_flow: // value for 'wizard_flow'
 *      title: // value for 'title'
 *      subtitle: // value for 'subtitle'
 *      text_end_1: // value for 'text_end_1'
 *      text_end_2: // value for 'text_end_2'
 *   },
 * });
 */
export function useCreateWizardTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateWizardTemplateMutation, CreateWizardTemplateMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<CreateWizardTemplateMutation, CreateWizardTemplateMutationVariables>(CreateWizardTemplateDocument, options)
}
export type CreateWizardTemplateMutationHookResult = ReturnType<typeof useCreateWizardTemplateMutation>
export type CreateWizardTemplateMutationResult = Apollo.MutationResult<CreateWizardTemplateMutation>
export type CreateWizardTemplateMutationOptions = Apollo.BaseMutationOptions<CreateWizardTemplateMutation, CreateWizardTemplateMutationVariables>
export const EditWizardTemplateDocument = gql`
    mutation editWizardTemplate($wizard_flow: jsonb, $title: String!, $subtitle: String, $id: uuid!, $text_end_1: String, $text_end_2: String) {
  editWizardTemplate(
    input: {wizard_flow: $wizard_flow, title: $title, subtitle: $subtitle, id: $id, text_end_1: $text_end_1, text_end_2: $text_end_2}
  ) {
    result
    wizard
  }
}
    `
export type EditWizardTemplateMutationFn = Apollo.MutationFunction<EditWizardTemplateMutation, EditWizardTemplateMutationVariables>

/**
 * __useEditWizardTemplateMutation__
 *
 * To run a mutation, you first call `useEditWizardTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWizardTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWizardTemplateMutation, { data, loading, error }] = useEditWizardTemplateMutation({
 *   variables: {
 *      wizard_flow: // value for 'wizard_flow'
 *      title: // value for 'title'
 *      subtitle: // value for 'subtitle'
 *      id: // value for 'id'
 *      text_end_1: // value for 'text_end_1'
 *      text_end_2: // value for 'text_end_2'
 *   },
 * });
 */
export function useEditWizardTemplateMutation(baseOptions?: Apollo.MutationHookOptions<EditWizardTemplateMutation, EditWizardTemplateMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<EditWizardTemplateMutation, EditWizardTemplateMutationVariables>(EditWizardTemplateDocument, options)
}
export type EditWizardTemplateMutationHookResult = ReturnType<typeof useEditWizardTemplateMutation>
export type EditWizardTemplateMutationResult = Apollo.MutationResult<EditWizardTemplateMutation>
export type EditWizardTemplateMutationOptions = Apollo.BaseMutationOptions<EditWizardTemplateMutation, EditWizardTemplateMutationVariables>
export const EditUserWizardProgressDocument = gql`
    mutation editUserWizardProgress($metadata: jsonb, $questionStackHistory: jsonb, $questionStack: jsonb, $status: String, $wizardId: uuid!, $userId: uuid!, $taskId: uuid, $currStep: Int) {
  editUserWizardProgress(
    input: {metadata: $metadata, wizardId: $wizardId, userId: $userId, questionStackHistory: $questionStackHistory, questionStack: $questionStack, status: $status, taskId: $taskId, currStep: $currStep}
  ) {
    result
    wizard
  }
}
    `
export type EditUserWizardProgressMutationFn = Apollo.MutationFunction<EditUserWizardProgressMutation, EditUserWizardProgressMutationVariables>

/**
 * __useEditUserWizardProgressMutation__
 *
 * To run a mutation, you first call `useEditUserWizardProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserWizardProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserWizardProgressMutation, { data, loading, error }] = useEditUserWizardProgressMutation({
 *   variables: {
 *      metadata: // value for 'metadata'
 *      questionStackHistory: // value for 'questionStackHistory'
 *      questionStack: // value for 'questionStack'
 *      status: // value for 'status'
 *      wizardId: // value for 'wizardId'
 *      userId: // value for 'userId'
 *      taskId: // value for 'taskId'
 *      currStep: // value for 'currStep'
 *   },
 * });
 */
export function useEditUserWizardProgressMutation(baseOptions?: Apollo.MutationHookOptions<EditUserWizardProgressMutation, EditUserWizardProgressMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<EditUserWizardProgressMutation, EditUserWizardProgressMutationVariables>(EditUserWizardProgressDocument, options)
}
export type EditUserWizardProgressMutationHookResult = ReturnType<typeof useEditUserWizardProgressMutation>
export type EditUserWizardProgressMutationResult = Apollo.MutationResult<EditUserWizardProgressMutation>
export type EditUserWizardProgressMutationOptions = Apollo.BaseMutationOptions<EditUserWizardProgressMutation, EditUserWizardProgressMutationVariables>
