/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'

import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import { useEditWizardTemplateMutation } from '#app/operations/wizards/wizards.mutations.generated'
import { GET_WIZARD_TEMPLATES, GET_WIZARD_TEMPLATES_TYPE } from '#app/utils/graphqlQueries'

import { WizardState } from './CreateWizard'
import WizardEditor from './WizardEditor'

// Import Quill CSS
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'

interface Props {
  isOpen: boolean
  initialValues: WizardState | null
  initialMetadata: any
  setIsOpen: (val: boolean) => void
}

interface Option {
  label: string
  option_text: string
  option_key: string
  option_type?: string
  option_bot_conclusion?: string
  subquestions?: Question[]
}

interface Question {
  question_text: string
  question_key: string
  options: Option[]
  subquestions?: Question[]
}

interface Step {
  title: string
  subtitle: string
  questions: Question[]
}

const getDefaultInitialValues = (): any => {
  return {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    title: 'Wizard',
    properties: {
      title: {
        type: 'string'
      },
      subtitle: {
        type: 'string'
      },
      questions: {
        type: 'array',
        items: {
          $ref: '#/definitions/question'
        }
      },
      text_end_1: {
        type: 'string'
      },
      text_end_2: {
        type: 'string'
      }
    },
    definitions: {
      question: {
        type: 'object',
        properties: {
          title: {
            type: 'string'
          },
          subtitle: {
            type: 'string'
          },
          question_text: {
            type: 'string'
          },
          question_key: {
            type: 'string'
          },
          is_multiple: {
            type: 'boolean',
            options: {
              dependencies: {
                'root.options.option_type': 'select'
              }
            }
          },
          isLine: {
            type: 'boolean',
          },
          is_subquestion: {
            type: 'boolean'
          },
          isForm: {
            type: 'boolean'
          },
          step_video: {
            type: 'string'
          },
          options: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                option_type: {
                  type: 'string',
                  enum: ['TwoRowCard', 'SquareCard', 'ConfirmationCard', 'InputForm']
                },
                option_key: {
                  type: 'string'
                },
                option_attributes: {
                  type: 'object',
                  properties: {
                    placeholder: {
                      type: 'string',
                      options: {
                        dependencies: {
                          'root.options.option_type': 'text'
                        }
                      }
                    },
                    option_text: {
                      title: 'Write the text you want the user to see',
                      type: 'string'
                    },
                    with_image: {
                      title: 'Is it with image?',
                      type: 'boolean',
                    },
                    fullWidth: {
                      type: 'boolean'
                    },
                    image_url: {
                      title: 'Image URL',
                      type: 'string',
                      options: {
                        dependencies: {
                          'root.options.with_image': true
                        }
                      }
                    },
                    other: {
                      title: 'Is other?',
                      type: 'boolean',
                      options: {
                        dependencies: {
                          'root.options.with_image': true
                        }
                      }
                    },
                    description: {
                      title: 'Option Description',
                      type: 'string'
                    },
                    colors: {
                      type: 'array',
                      items: {
                        type: 'string',
                        text: 'Elegi un color'
                      }
                    }
                  }
                },
                subquestions: {
                  type: 'array',
                  items: {
                    $ref: '#/definitions/question'
                  }
                }
              },
              required: ['option_type']
            }
          }
        },
        required: ['question_text', 'options']
      }
    }
  }
}

export function EditWizard({ initialValues, initialMetadata, isOpen, setIsOpen }: Props) {
  const [effectiveInitialValues] = useState(getDefaultInitialValues())
  const [editWizardTemplate] = useEditWizardTemplateMutation({
    update(cache, { data }) {
      if (!data?.editWizardTemplate?.result) return

      // Read the current wizard templates from the cache
      const cacheData = cache.readQuery<GET_WIZARD_TEMPLATES_TYPE>({
        query: GET_WIZARD_TEMPLATES
      })

      console.log(data.editWizardTemplate)
      if (!cacheData || !cacheData.wizard_templates) return

      // Get the updated wizard template from the mutation response
      const updatedWizard = data.editWizardTemplate.wizard[1][0] // Adjust based on your response structure

      // Create a new array with updated wizard data
      const updatedWizards = cacheData.wizard_templates.map((wizard) =>
        wizard.id === updatedWizard.id
          ? {
            ...wizard,
            ...updatedWizard,
            __typename: 'wizard_templates'
          }
          : wizard
      )

      // Write the updated data back to the cache
      cache.writeQuery({
        query: GET_WIZARD_TEMPLATES,
        data: {
          ...cacheData,
          wizard_templates: updatedWizards
        }
      })
    }
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  console.log(effectiveInitialValues)
  const editWizardMethod = async (data: any) => {
    setIsSubmitting(true)
    setSubmitError('')
    const { title, subtitle, questions, text_end_1: textEnd1, text_end_2: textEnd2 } = data

    try {
      await editWizardTemplate({
        variables: {
          wizard_flow: questions,
          title,
          subtitle,
          id: initialValues?.id,
          text_end_1: textEnd1,
          text_end_2: textEnd2
        }
      })
      enqueueSnackbar('Wizard Edited succesfully', {
        variant: 'success'
      })
      setSubmitError('')
      setIsOpen(false)
    }
    catch (error) {
      console.error(error)
      enqueueSnackbar('Error Editing wizard', {
        variant: 'error'
      })
      setSubmitError('Error occurred while creating the wizard')
    }
    finally {
      setIsSubmitting(false)
    }
  }

  return (
    <RightSideBar
      isOpen={isOpen}
      closeHandler={() => setIsOpen(false)}
      size='lg'
    >
      <>
        <Typography variant='h4'>
          Edit Wizard
&nbsp;
        </Typography>
        <Box mt={3}>
          <WizardEditor
            schema={effectiveInitialValues}
            isSubmitting={isSubmitting}
            initialValues={initialValues}
            onSubmit={editWizardMethod}
          />
          {submitError.length > 0 && (
            <p>
              Error:
              {submitError}
            </p>
          )}
        </Box>
      </>
    </RightSideBar>
  )
}
