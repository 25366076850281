import { useState } from 'react'
import { useParams } from 'react-router-dom'

import LogoOwners from '#app/assets/svg/logo-owners-light.svg'
import { useGetWizardByIdQuery } from '#app/operations/wizards/wizards.queries.generated'
import { WizardContainer } from '#app/v2/pages/Wizard/page'

const WizardDemo = () => {
  const { wizardId } = useParams()
  const { data, loading } = useGetWizardByIdQuery({
    variables: {
      wizardId
    }
  })
  const [goBack, setGoBack] = useState(false)

  return (
    <WizardContainer
      wizardData={data?.wizard_templates?.[0]?.wizard_flow}
      loading={loading}
      backUrl='/v2-ui/home'
      logo={LogoOwners}
      totalSteps='6'
      currentStep='1'
      isDemo={true}
      textEnd1={data?.wizard_templates?.[0]?.text_end_1 ?? ''}
      textEnd2={data?.wizard_templates?.[0]?.text_end_2 ?? ''}
    />
  )
}

export default WizardDemo
