import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { AnimatePresence } from 'framer-motion'

import { useUIStories } from '#app/hooks/useUIStories'
import { Activities } from '#app/operations/autogenerate/schemas'
import { ENVIROMENT } from '#app/store/enviroment'
import { MagnifyingGlass, RingingBellNotification, WhatsApp } from '#app/v2/assets/Icons'
import { EmptyMessageList } from '#app/v2/components/EmptyMessageList'
import { PageWrapper } from '#app/v2/components/PageWrapper'
import { ProfileAvatarDetailed } from '#app/v2/components/ProfileAvatarDetailed'
import { SideMenuProfile } from '#app/v2/components/SideMenuProfile'
import { Button } from '#app/v2/components/ui/button'
import { NotificationDot } from '#app/v2/components/ui/dot-notification'
import { Input } from '#app/v2/components/ui/input'
import { FinishedTasks } from '#app/v2/pages/Home/components/FinishedTasks'
import { HeaderPlusView } from '#app/v2/pages/Home/components/HeaderPlusView'
import { NewActivityCard, PreviousActivityCard } from '#app/v2/pages/Home/components/HomeActivityCard'
import { MessageCard, MessageCardProps } from '#app/v2/pages/Home/components/MessageCard'
import { ShortcutGridBtn, ShortcutGridBtnProps } from '#app/v2/pages/Home/components/ShortcutGridBtn'
import { ProfileProps } from '#app/v2/pages/MyProfile/page'
import { ToDoCard } from '#app/v2/pages/TaskList/components/ToDoCard'
import { TaskCardProps } from '#app/v2/pages/TaskList/types'

interface Props {
  profile?: ProfileProps
  shortcuts?: ShortcutGridBtnProps[]
  routes?: {
    profile: string
    editProfile: string
    activityCenter: string
    tasks: string
    messages: string
  }
  todos?: TaskCardProps[]
  activities?: Partial<Activities>[]
  qtyNotifications?: number
  messages?: {
    inbox?: MessageCardProps[]
    groups?: MessageCardProps[]
  }
  channelListStream?: JSX.Element
  markActivityAsRead?: (id: string) => void
}

export const Home = ({ profile, routes, shortcuts, todos, channelListStream, messages, activities, markActivityAsRead, qtyNotifications }: Props) => {
  const { isStoryMode } = useUIStories()
  const { t } = useTranslation()
  const { pathname, state } = useLocation()

  const [currentActivities, setCurrentActivities] = useState<Partial<Activities>[]>([])

  const handleHomeCard = () => {
    const selectedActivityId = currentActivities?.[0]?.id ?? null

    const updatedActivities = currentActivities.slice(1)
    setCurrentActivities(updatedActivities)

    selectedActivityId && markActivityAsRead && markActivityAsRead(selectedActivityId)
  }

  useEffect(() => {
    if (activities) setCurrentActivities(activities)
  }, [activities])

  return (
    <PageWrapper
      pathname='/v2/home'
      className='bg-[#F9F9F9] px-7 pb-20 pt-6'
    >
      {/* Header */}
      <header className='mb-4 flex items-center justify-between text-[#304985]'>
        <SideMenuProfile {...profile} />
        <div className=' flex items-center gap-7'>
          <Link
            to={routes ? routes.activityCenter : ''}
            state={{
              from: pathname,
              to: routes?.activityCenter
            }}
          >
            <NotificationDot
              variant='activityCenter'
              quantity={qtyNotifications}
            >
              <RingingBellNotification className='h-7 w-7' />
            </NotificationDot>
          </Link>
          <Link
            to={routes ? routes.editProfile : ''}
            state={{
              from: pathname,
              to: routes?.editProfile
            }}
          >
            <ProfileAvatarDetailed
              onlyAvatar
              profilePictureUrl={profile?.avatar}
              fullName={profile?.fullName}
            />
          </Link>
        </div>
      </header>
      {/* TITLE INTRO */}
      <section id='intro'>
        <h1 className='text-[28px] font-medium leading-tight '>
          {t('v2.generic.hi', 'Hi')}
          ,&nbsp;
          <span className=' text-[#304985]'>{profile?.fullName}</span>
          <br />
          {t('v2.home.intro', 'Let\'s Get to Work')}
        </h1>
        <Button
          variant='link'
          className='mt-2 gap-1 p-0 text-xs font-medium text-[#BDBFBE] underline'
          onClick={() => {
            window.open(`${ENVIROMENT.support_whatsapp_link}`, '_blank')
          }}
        >
          <WhatsApp className=' h-5 w-5' />
          {t('v2.generic.helpQ', 'Need help?')}
        </Button>
      </section>
      {/* SEARCH INPUT
            - Might need update due to it needs guide: https://ui.shadcn.com/docs/components/combobox possible solution proposed to UX.
        */}
      {
        isStoryMode
        && (
          <section
            id='search-input'
            className='relative my-6 text-[#304985]'
          >
            <Formik
              initialValues={{
                generalSearch: ''
              }}
              onSubmit={() => { /*  not implemented */ }}
            >
              <Form>
                <Input
                  name='generalSearch'
                  placeholder='Search tasks, messages, clients...'
                  className=' rounded-xl border-[#304985]/40 px-9 placeholder:text-[#304985] placeholder:text-opacity-70 '
                />
              </Form>
            </Formik>
            <MagnifyingGlass className=' absolute left-3 top-2.5 h-5 w-5 ' />
          </section>
        )
      }
      {/* QUICK ACCESS grid */}
      <section
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className='@container'
        id='quick-access'
      >
        <h2 className=' mt-6 text-lg font-semibold text-[#304985]'>{t('v2.home.quickAccess', 'Quick access')}</h2>
        <div className=' @lg:grid-cols-4 my-6 grid grid-cols-2 gap-x-3 gap-y-2 '>
          {shortcuts && shortcuts?.length > 0
            ? shortcuts.map((btn, i) => (
              <ShortcutGridBtn
                key={i}
                {...btn}
              />
            )
            )
            : null}
        </div>
      </section>
      {/* NOTIFICATIONS CARDs */}
      {
        currentActivities
        && (
          <section id='card-notifications'>
            {/* Title - action */}
            <HeaderPlusView
              title='Notifications'
              viewAllUrl={routes ? routes?.activityCenter : ''}
            />
            <div className='relative mt-6 h-48'>
              <AnimatePresence>
                <PreviousActivityCard
                  key={currentActivities[1]?.id ?? 'back-last-card'}
                  totalActivities={currentActivities.length}
                  activity={currentActivities[1]}
                />
                <NewActivityCard
                  key={currentActivities[0]?.id ?? 'front-last-card'}
                  totalActivities={currentActivities.length}
                  activity={currentActivities[0]}
                  handleHomeCard={handleHomeCard}
                />
              </AnimatePresence>
            </div>
          </section>
        )
      }
      {/* Tasks */}
      {
        todos
        && (
          <section id='tasks'>
            {/* Title - action */}
            <HeaderPlusView
              title={t('v2.home.toDo', 'To do\'s')}
              viewAllUrl={routes ? routes.tasks : ''}
            />
            <ul className=' mb-6 mt-8 space-y-5'>
              {/* To-Do cards */}
              {todos && todos?.length > 0
                ? todos.map((task, i) => (
                  <ToDoCard
                    key={i}
                    task={task}
                    onClickURL={`/v2/task-detail/${task.id}`}
                  />
                ))
                : <FinishedTasks />}
            </ul>
          </section>
        )
      }
      {/* INBOX */}
      {messages?.inbox
      && (
        <section id='inbox'>
          {/* Title - action */}
          <HeaderPlusView
            title={t('v2.home.inbox', 'Inbox')}
            viewAllUrl={routes ? routes.messages : ''}
          />
          <ul className=' mb-7 mt-3 space-y-3'>
            {/* INBOX Card */}
            {messages?.inbox && messages?.inbox?.length > 0
              ? messages.inbox.map((card, i) => (
                <MessageCard
                  key={i}
                  {...card}
                />
              ))
              : <EmptyMessageList />}
          </ul>
        </section>
      )}
      {/* CHANNEL LIST STREAM */}
      {
        channelListStream
        && (
          <section id='inbox'>
            {/* Title - action */}
            <HeaderPlusView
              title={t('v2.home.inbox', 'Inbox')}
              viewAllUrl={routes ? routes.messages : ''}
            />
            <div className=' mb-7 mt-3 space-y-3'>
              {channelListStream}
            </div>
          </section>
        )

      }
      {/* GROUPS */}
      {
        isStoryMode
        && (
          <section id='groups'>
            <h2 className=' text-lg font-semibold text-[#304985]'>My groups</h2>
            <ul className=' mb-7 mt-3 space-y-3'>
              {/* INBOX Card */}
              {messages?.groups && messages?.groups?.length > 0
                ? messages.groups.map((card, i) => (
                  <MessageCard
                    key={i}
                    {...card}
                  />
                ))
                : <EmptyMessageList />}
            </ul>
          </section>
        )
      }
    </PageWrapper>
  )
}
