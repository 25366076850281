import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BsLockFill } from 'react-icons/bs'
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { capitalize } from 'lodash'

import { Chip } from '#app/components/Chip'
import { GetBankCardsQuery } from '#app/operations/banking/banking.queries.generated'

export function CardStatus({ cardRow }: { cardRow?: GetBankCardsQuery['bank_cards'][0] }) {
  const { t } = useTranslation()

  const shippingStatus = useCallback((shippingStatus: string) => {
    let res
    switch (shippingStatus) {
      case 'pending': res = t('banking.issuing.shipping.pending'); break
      case 'shipped': res = t('banking.issuing.shipping.shipped'); break
      case 'delivered': res = t('banking.issuing.shipping.delivered'); break
      case 'returned': res = t('banking.issuing.shipping.returned'); break
      case 'failure': res = t('banking.issuing.shipping.failure'); break
      default: res = shippingStatus
    }

    return res
  }, [t])

  const cardActive = useMemo(() => {
    const status = cardRow?.card?.status as string
    let res
    switch (status) {
      case 'active': res = t('banking.issuing.status.active'); break
      case 'inactive': res = t('banking.issuing.status.inactive'); break
      case 'canceled': res = t('banking.issuing.status.canceled'); break
      default: res = status
    }

    return res
  }, [t, cardRow])

  return (
    <Box width='100%'>
      { cardRow
      && (
        <Stack
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={2}
          mb={2}
        >
          {
            cardRow?.card?.status === 'inactive'
            && (
              <Chip.Basic
                icon={<BsLockFill />}
                color='primary'
                label={t('banking.issuing.locked')}
              />
            )
          }
          {
            cardRow?.card?.status
            && (
              <Chip.Basic
                color={cardRow?.card?.status === 'inactive' ? 'error' : 'success'}
                label={cardActive}
              />
            )
          }
          {
            cardRow?.card?.shipping?.status
            && (
              <Tooltip title={(
                <>
                  <Typography
                    color='inherit'
                    variant='h6'
                  >
                    {t('banking.issuing.shipingLabel')}
                  </Typography>
                  <Typography
                    color='inherit'
                    variant='body1'
                  >
                    <b>
                      {t('banking.issuing.statusLabel')}
                      :
                    </b>
                    {' '}
                    {shippingStatus(cardRow?.card?.shipping?.status as string)}
                    {' '}
                    <br />
                    <b>
                      {t('banking.issuing.carrierLabel')}
                      :
                    </b>
                    {' '}
                    {cardRow?.card?.shipping?.carrier}
                    {' '}
                    <br />
                    <b>
                      {t('banking.issuing.trackingLabel')}
                      :
                    </b>
                    {' '}
                    {cardRow?.card?.shipping?.tracking_number || capitalize(t('generic.notAvailable'))}
                    {' '}
                    <br />
                  </Typography>
                </>
              )}
              >
                <Box>
                  <Chip.Basic
                    color='primary'
                    label={shippingStatus(cardRow?.card?.shipping?.status as string)}
                  />
                </Box>
              </Tooltip>
            )
          }
        </Stack>
      )}
    </Box>
  )
}
