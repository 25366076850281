import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, CardContent, Stack, Typography } from '@mui/material'

import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import { DividerExtended } from '#app/components/Divider'
import { useDayJs } from '#app/hooks/useDayJs'
import { useWorkOrders } from '#app/hooks/useWorkOrders'
import { ENVIROMENT } from '#app/store/enviroment'

export function WorkOrderEvidence({ evidence }: { evidence: any }) {
  const { formatDateV2 } = useDayJs()
  const { t } = useTranslation()
  const { fetchEvidences, AttachmentSequin } = useWorkOrders(evidence.work_order_id as string)

  useEffect(() => {
    if (evidence?.sp_proof_job_completion) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      void fetchEvidences(evidence.id, evidence?.sp_proof_job_completion)
    }
  }, [evidence, fetchEvidences])

  const csatData = useMemo(() => {
    if (evidence.sp_csat) {
      return {
        role: 'Service Provider',
        csat: evidence.sp_csat_en,
        comments: evidence.sp_csat_comments
      }
    }

    return {
      role: 'Property Manager',
      csat: evidence.pm_csat,
      comments: evidence.pm_csat_comments
    }
  }, [evidence])

  return (
    <Card sx={{
      mb: 4,
      mt: 4
    }}
    >
      <CardContent>
        <Stack
          sx={{
            position: 'relative',
            mb: 2
          }}
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
        >
          <Chip.Basic
            label={evidence.id}
            size='small'
          />
          <Btn.MenuButton onView={() => window.open(`${ENVIROMENT.airtable_table_work_order_evidence}/${evidence.id as string}`)} />
        </Stack>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box>
            <Typography variant='h4'>
              {csatData.role}
            </Typography>
            <Typography variant='subtitle1'>
              {csatData.csat}
            </Typography>
            <DividerExtended />
            <Typography variant='subtitle2'>
              Comments:
              {' '}
              {csatData.comments}
            </Typography>
            {
              evidence.sp_proof_job_completion && evidence.sp_proof_job_completion.length > 0
              && (
                <>
                  <DividerExtended />
                  <Stack
                    direction='row'
                    mt={-2}
                    spacing={1}
                  >
                    {
                      evidence.sp_proof_job_completion
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                        ? evidence.sp_proof_job_completion.map((attachmentUrl: string, key: string) => {
                          return (
                            <AttachmentSequin
                              key={key}
                              withoutPreview
                              withoutDownload
                              attachmentUrl={attachmentUrl}
                            />
                          )
                        })
                        : <></>
                    }
                  </Stack>
                </>
              )
            }
            <DividerExtended />
            <Typography
              variant='caption'
              mt={4}
            >
              {t('workOrders.date', 'Date')}
              :
              {evidence?.created_at ? formatDateV2(evidence?.created_at as string) : 'N/A'}
              {' '}
              <br />
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}
