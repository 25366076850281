import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsPlusCircle } from 'react-icons/bs'
import { NumericFormat } from 'react-number-format'
import { Box, Stack, Typography } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import { uniqueId } from 'lodash'
import * as Yup from 'yup'

import { Btn } from '#app/components/Buttons'
import { CurrencyInput } from '#app/components/CurrencyInput/CurrencyInput'
import FormInput from '#app/components/FormInput/FormInput'
import { InvoiceLineItem } from '#app/store/invoice'

interface Props {
  onSubmit: (values: InvoiceLineItem) => void
}

export const CreateLineItem = ({ onSubmit }: Props) => {
  const { t } = useTranslation()

  const initialValues = {
    description: '',
    quantity: 0,
    unitCost: 0,
    total: 0
  }

  const [quantity, setQuantity] = useState(initialValues.quantity)
  const [unitCost, setUnitCost] = useState(initialValues.unitCost)
  const [total, setTotal] = useState(initialValues.total)

  useEffect(() => {
    setTotal((Number(quantity) * Number(unitCost)) ?? 0)
  }, [quantity, unitCost])

  const onFormSubmit = (values: InvoiceLineItem, helpers: FormikHelpers<InvoiceLineItem>) => {
    helpers.resetForm()
    setTotal(initialValues.total)
    setQuantity(initialValues.quantity)
    setUnitCost(initialValues.unitCost)

    values.id = uniqueId()
    values.total = total
    onSubmit(values)
  }

  const schema = Yup
    .object()
    .shape({
      description: Yup
        .string()
        .required(t('validation.required')),
      quantity: Yup
        .number()
        .min(0, t('validation.minimum', {
          value: 0
        }))
        .required(t('validation.required'))
        .test(
          'integer-quantity',
          t('banking.invoices.validation.onlyIntegers', 'Quantity must be an integer number'),
          (value) => Number.isInteger(value)
        ),
      unitCost: Yup
        .number()
        .min(0, t('validation.minimum', {
          value: 0
        }))
        .required(t('validation.required'))
    })

  return (
    <Stack spacing={2}>
      <Typography fontWeight='bold'>{t('banking.invoices.create.lineItems', 'Line items')}</Typography>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={onFormSubmit}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <Stack spacing={1}>
                <FormInput
                  type='text'
                  variant='standard'
                  name='description'
                  placeholder={t('banking.invoices.create.lineDescription', 'Item description')}
                />
                <Stack spacing={1}>
                  <Stack
                    direction='row'
                    display='flex'
                    alignItems='center'
                    spacing={1}
                    justifyContent='space-between'
                  >
                    <Box sx={{
                      flexGrow: 1
                    }}
                    >
                      <Typography>
                        {t('banking.invoices.create.lineQty', 'Quantity')}
                      </Typography>
                    </Box>
                    <Box>
                      <FormInput
                        variant='standard'
                        type='number'
                        name='quantity'
                        onChange={(val) => {
                          setQuantity(Number(val ?? 0))
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    direction='row'
                    display='flex'
                    alignItems='center'
                    spacing={1}
                    justifyContent='space-between'
                  >
                    <Box sx={{
                      flexGrow: 1
                    }}
                    >
                      <Typography>
                        {t('banking.invoices.create.unitCost', 'Unit Cost')}
                      </Typography>
                    </Box>
                    <Box>
                      <CurrencyInput
                        variant='standard'
                        name='unitCost'
                        onChange={(val) => {
                          setUnitCost(Number(val ?? 0))
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    direction='row'
                    display='flex'
                    alignItems='center'
                    spacing={1}
                    justifyContent='space-between'
                    style={{
                      height: 50
                    }}
                  >
                    <Box sx={{
                      flexGrow: 1
                    }}
                    >
                      <Typography>
                        {t('banking.invoices.create.total', 'Total')}
                      </Typography>
                    </Box>
                    <Box>
                      <NumericFormat
                        fixedDecimalScale={true}
                        decimalScale={2}
                        thousandSeparator=','
                        style={{
                          fontWeight: 'bold',
                          letterSpacing: 2
                        }}
                        displayType='text'
                        value={total}
                        prefix='$'
                      />
                    </Box>
                  </Stack>
                </Stack>
                <Btn.Button
                  disabled={isSubmitting}
                  type='submit'
                  startIcon={<BsPlusCircle />}
                  variant='outlined'
                >
                  { t('banking.invoices.create.addLineItem', 'Add line item') }
                </Btn.Button>
              </Stack>
            </Form>
          )
        }}
      </Formik>
    </Stack>
  )
}
