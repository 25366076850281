import { Avatar, AvatarFallback, AvatarImage } from '#app/v2/components/ui/avatar'

import { cn } from '../libs/utils'

interface Props {
  image?: string
  gravatar?: JSX.Element
  initials?: string
  fullName?: string
  className?: string
}

export const ProfileAvatar = ({ image, initials, fullName, gravatar, className }: Props) => {
  return (
    <Avatar className={cn('h-12 w-12 border border-[#304985]', className)}>
      {image
        ? (
          <AvatarImage
            src={image}
            className='object-cover'
          />
        )
        : gravatar}
      <AvatarFallback>{initials}</AvatarFallback>
      <p style={{
        display: 'none'
      }}
      >
        {fullName}
      </p>
    </Avatar>
  )
}
