import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsFillSignpost2Fill, BsLink45Deg } from 'react-icons/bs'
import { useSearchParams } from 'react-router-dom'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import capitalize from '@mui/utils/capitalize'

import { Chip } from '#app/components/Chip'
import { Table } from '#app/components/Table'
import { JsonTable } from '#app/feature/JsonToHtml'
import { NewBulkTask } from '#app/feature/Tasks/BulkTask/NewBulkTask'
import { useEntityAudiences } from '#app/hooks/useEntityAudiences'
import { useEntitySequence } from '#app/hooks/useEntitySequence'
import { EntityPage } from '#app/layouts/EntityView'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

import { SequenceExecutions } from './SequenceExecutions'

export function SequencesTable() {
  const isDesktop = useIsDesktopSize()
  const { t } = useTranslation()
  const [openBulkTask, setOpenNewBulkTask] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const { parseCities } = useEntityAudiences()

  const {
    queryGetSequences, queryGetSequencesData, queryGetSequencesUtils
  } = useEntitySequence({
    variables: {
      type: 'bulk-tasks'
    }
  })

  const createHandler = useCallback(() => {
    setOpenNewBulkTask(true)
  }, [])

  const initView = useCallback(async () => {
    await queryGetSequences({
      variables: {
        type: 'bulk-tasks'
      }
    })
  }, [queryGetSequences])

  useEffect(() => {
    void initView()
  }, [initView])

  const openSequenceId = useMemo(() => {
    return searchParams.get('sequenceId')
  }, [searchParams])

  const tableStructure = useMemo(() => {
    const structure = {
      rows: []
    }
    queryGetSequencesData?.sequences.map((sequence) => {
      const row = {
        key: sequence.id,
        columns: [
          {
            align: 'left',
            component:
  <Stack
    direction='row'
    justifyContent='left'
    alignItems='center'
    sx={{
      m: 2
    }}
  >
    <Typography>
      {sequence.title}
    </Typography>
    <IconButton
      aria-label='delete'
      onClick={() => setSearchParams({
        sequenceId: sequence.id
      })}
    >
      <BsLink45Deg />
    </IconButton>
  </Stack>
          },
          {
            align: 'left',
            component: (
              <Typography sx={{
                m: 1
              }}
              >
                {sequence.audience?.title}
              </Typography>
            )
          },
          {
            align: 'left',
            component: (
              <Chip.TaskTemplate
                sx={{
                  m: 1
                }}
                templateID={sequence.payload?.template_id}
              />
            )
          },
          {
            align: 'left',
            component: (
              <Box>
                <JsonTable json={sequence.audience.conditions} />
              </Box>
            )
          },
          {
            align: 'left',
            component: (
              <Box sx={{
                m: 1
              }}
              >
                {isDesktop
                && (
                  <Typography>
                    {sequence?.metadata?.affected_users}
                    {!sequence?.metadata?.affected_users && sequence?.metadata?.affected_users !== 0 && 'unknown'}
                  </Typography>
                )}
                {!isDesktop
                && <Chip.Basic label={`Affected Users: ${sequence?.metadata?.affected_users as string}`} />}
              </Box>)
          },
          {
            align: 'right',
            component: (
              <Stack
                direction='column'
                justifyContent='center'
                alignItems='flex-end'
                spacing={1}
              >
                <Chip.SequenceStatus
                  status={sequence.status}
                  onClick={() => setSearchParams({
                    sequenceId: sequence.id
                  })}
                />
                {sequence?.metadata?.has_errors
                && <Chip.Error />}
                {sequence?.metadata?.last_sequence_execution
                && (
                  <Chip.Basic
                    icon={<BsFillSignpost2Fill />}
                    color='info'
                    label='Last execution'
                    onClick={() => setSearchParams({
                      sequenceId: sequence.id,
                      sequenceExecution: sequence?.metadata?.last_sequence_execution
                    })}
                  />
                )}
              </Stack>)
          }
        ]
      }
      // @ts-ignore
      structure.rows.push(row)
    })
    return structure
  }, [isDesktop, parseCities, queryGetSequencesData?.sequences, setSearchParams])

  return (
    <EntityPage
      pageTitle='Sequences'
      pageSubtitle='Sequences are asynchronous and can take time to complete, please never generate a duplicate bulk.'
      actionLabel={capitalize(t('generic.create'))}
      layout='one-column'
      isLoading={queryGetSequencesUtils.loading}
      mainContent={(
        <>
          <>
            {!searchParams.get('sequenceId')
            && (
              <Table.V2
                mobileMergeColumns
                mobileMergeExludeLastColumn
                headers={[
                  {
                    text: 'Name',
                    align: 'left'
                  },
                  {
                    text: 'Template',
                    align: 'left'
                  },
                  {
                    text: 'Audience',
                    align: 'left'
                  },
                  {
                    text: 'Audience Conditions',
                    align: 'left'
                  },
                  {
                    text: 'Users',
                    align: 'left'
                  },
                  {
                    text: 'Status',
                    align: 'right'
                  }
                ]}
                mobileColumns={[1, 3, 7, 8]}
                structure={tableStructure}
              />
            )}
            {
              openSequenceId
              && <SequenceExecutions sequenceId={openSequenceId} />
            }
          </>
          <NewBulkTask
            setOpen={setOpenNewBulkTask}
            open={openBulkTask}
          />
        </>
      )}
      onActionClick={createHandler}
    />
  )
}
