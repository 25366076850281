export const DirectionForm = () => {
  return (
    <form className='space-y-2.5 rounded-xl bg-white px-3.5 py-5 shadow'>
      <label htmlFor='direccion'>Añade la dirección de tu empresa</label>
      <div className='grid grid-cols-2 grid-rows-3 gap-3'>
        <input
          type='text'
          placeholder='Dirección de la empresa*'
          className='col-span-2 rounded-lg border border-[#D9D9D9] px-4 py-2.5 text-[#0F172A] placeholder:text-[#454545]/40 focus-visible:outline-[#8BC53F]'
        />
        <input
          type='text'
          placeholder='Ciudad*'
          className='rounded-lg border border-[#D9D9D9] px-4 py-2.5 text-[#0F172A] placeholder:text-[#454545]/40 focus-visible:outline-[#8BC53F]'
        />
        <input
          type='text'
          placeholder='Estado*'
          className='rounded-lg border border-[#D9D9D9] px-4 py-2.5 text-[#0F172A] placeholder:text-[#454545]/40 focus-visible:outline-[#8BC53F]'
        />
        <input
          type='text'
          placeholder='Código Postal*'
          className='col-span-2 rounded-lg border border-[#D9D9D9] px-4 py-2.5 text-[#0F172A] placeholder:text-[#454545]/40 focus-visible:outline-[#8BC53F]'
        />
      </div>
    </form>
  )
}
