import { ReactNode, useContext, useLayoutEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { User } from 'stream-chat'
import { Chat } from 'stream-chat-react'

import ProfileContext from '#app/contexts/ProfileContext'
import { useGetChatQuery } from '#app/operations/chat/chat.queries.generated'
import { useStreamClient } from '#app/v2-core/hooks/useStreamClient'

import 'stream-chat-react/dist/css/v2/index.css'
import './styles.css'

interface Props {
  isWidget?: boolean
  children?: ReactNode
}

function Messages({ isWidget, children }: Props) {
  const { getProfile: { user } } = useContext(ProfileContext)

  const { data } = useGetChatQuery({
    variables: {
      userId: user?.id ?? ''
    }
  })

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const messageId = queryParams.get('message_id')

  const chat = data?.getChat?.chat

  const chatUser: User = {
    id: user?.id ?? '',
    name: `${user?.first_name ?? ''} ${user?.last_name ?? ''}`,
    language: user?.language
  }

  const chatClient = useStreamClient({
    apiKey: process.env.REACT_APP_STREAM_API_KEY ?? '',
    user: chatUser,
    tokenOrProvider: chat?.token ?? ''
  })

  useLayoutEffect(() => {
    const observer = new MutationObserver((_, observer) => {
      if (messageId && chatClient) {
        const message = document.querySelector(`[data-message-id="${messageId}"]`)

        if (message) {
          const messageBubble = message.querySelector('.str-chat__message-bubble')
          messageBubble?.scrollIntoView({
            behavior: 'smooth'
          })
          messageBubble?.classList.add('text-white', 'border', 'border-yellow-500', '!bg-yellow-500', 'bg-opacity-50')

          setTimeout(() => {
            messageBubble?.classList.add('transition', 'transition-background', 'duration-1000', 'ease-out')
            messageBubble?.classList.remove('text-white', 'border', 'border-yellow-500', '!bg-yellow-500', 'bg-opacity-50')
          }, 1500)
          observer.disconnect()
        }
      }
    })

    observer.observe(document, {
      childList: true,
      subtree: true
    })

    return () => observer.disconnect() // clean up on component unmount
  }, [messageId, chatClient])
  if (!chatClient) return null

  return (
    <Chat
      client={chatClient}
      theme='str-chat__theme-light'
      // NOTE: Default language is overwritten by browsers language settings
      defaultLanguage={user?.language}
    >
      {isWidget ? children : <Outlet />}
    </Chat>
  )
}

export default Messages
