import { useState } from 'react'
import Highlight from 'react-highlight'
import { Alert, Card, Grid, Typography } from '@mui/material'

import { Btn } from '#app/components/Buttons'
import RightSideBar, { RightSideBarContainer } from '#app/components/RightSideBar/RightSideBar'

export function DrawersDemo() {
  const [size, setSize] = useState<string | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)

  return (
    <Card sx={{
      p: 4
    }}
    >
      <Typography variant='h5'> Right Sidebar </Typography>
      <Highlight>
        {`
import RightSideBar from '#app/components/RightSideBar/RightSideBar'

<RightSideBar size={size} isOpen={open} closeHandler={() => setOpen(false)} >
<>
  <Typography variant='h4'> Title </Typography>
  <Typography variant='subtitle2'> Subittle </Typography>
</>
</RightSideBar>
<Btn.Button primary onClick={() => { setSize(undefined); setOpen(true) }}>Default 450px</Btn.Button>
<Btn.Button primary onClick={() => { setSize('md'); setOpen(true) }}>md 600px</Btn.Button>
<Btn.Button primary onClick={() => { setSize('lg'); setOpen(true) }}>lg 900px</Btn.Button>
<Btn.Button primary onClick={() => { setSize('full'); setOpen(true) }}>full 100vw</Btn.Button>
        `}
      </Highlight>
      <Grid
        container
        spacing={2}
        mt={1}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          <RightSideBar
            size={size}
            isOpen={open}
            closeHandler={() => setOpen(false)}
          >
            <>
              <Typography variant='h4'> Title </Typography>
              <Typography variant='subtitle2'> Subittle </Typography>
            </>
          </RightSideBar>
          <Btn.Button
            primary
            sx={{
              m: 1
            }}
            onClick={() => { setSize(undefined); setOpen(true) }}
          >
            Default 450px
          </Btn.Button>
          <Btn.Button
            primary
            sx={{
              m: 1
            }}
            onClick={() => { setSize('md'); setOpen(true) }}
          >
            md 600px
          </Btn.Button>
          <Btn.Button
            primary
            sx={{
              m: 1
            }}
            onClick={() => { setSize('lg'); setOpen(true) }}
          >
            lg 900px
          </Btn.Button>
          <Btn.Button
            primary
            sx={{
              m: 1
            }}
            onClick={() => { setSize('full'); setOpen(true) }}
          >
            full 100vw
          </Btn.Button>
        </Grid>
      </Grid>

      <Typography
        variant='h5'
        mb={2}
      >
        {' '}
        Right Sidebar Container
      </Typography>
      <Alert severity='info'>
        This container is a flexbox that places the footer attached to the bottom of the drawer, in the future it may be a standard, for the moment it is recommended for drawers with dynamic information and the buttons on top look bad when there is no content
      </Alert>
      <Highlight>
        {`
import RightSideBar, { RightSideBarContainer } from '#app/components/RightSideBar/RightSideBar'

<RightSideBar isOpen={open} closeHandler={() => setOpen(false)}>
  <RightSideBarContainer footer={<Btn.Button primary onClick={() => setOpen(false)}>Close</Btn.Button>}>
    <>
      <Typography variant='h4'> Title </Typography>
      <Typography variant='subtitle2'> Subittle </Typography>
    </>
  </RightSideBarContainer>
</RightSideBar>
<Btn.Button primary onClick={() => setOpen(true)}>Open Drawer</Btn.Button>
        `}
      </Highlight>
      <Grid
        container
        spacing={2}
        mt={1}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          <RightSideBar
            isOpen={open2}
            closeHandler={() => setOpen2(false)}
          >
            <RightSideBarContainer footer={(
              <Btn.Button
                secondary
                onClick={() => setOpen2(false)}
              >
                Close
              </Btn.Button>
            )}
            >
              <>
                <Typography variant='h4'> Title </Typography>
                <Typography variant='subtitle2'> Subittle </Typography>
              </>
            </RightSideBarContainer>
          </RightSideBar>
          <Btn.Button
            primary
            onClick={() => setOpen2(true)}
          >
            Open Drawer
          </Btn.Button>
        </Grid>
      </Grid>
    </Card>
  )
}
