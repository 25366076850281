import { CSSProperties } from 'react'
import { Button as ButtonMui, SxProps } from '@mui/material'
interface Props {
  onClick?: (args: any) => any
  type?: 'submit' | 'reset' | 'button' | undefined
  color?: 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined
  primary?: boolean
  secondary?: boolean
  isLoading?: boolean
  disabled?: boolean
  children?: any
  variant?: 'outlined' | 'contained'
  style?: CSSProperties
  sx?: SxProps
  form?: string
  startIcon?: React.ReactNode
  props?: any
}

export const Button = ({
  onClick,
  color,
  children,
  style,
  sx,
  form,
  variant = 'contained',
  type = 'button',
  secondary,
  primary = true,
  isLoading = false,
  disabled = false,
  startIcon: StartIcon,
  ...props
}: Props) => {
  // TODO: replace with MUI if needed
  return (
    <ButtonMui
      variant={variant}
      size='large'
      disabled={disabled}
      type={type}
      style={style}
      sx={sx}
      form={form}
      startIcon={StartIcon}
      color={color ?? (secondary ? 'secondary' : primary ? 'primary' : 'secondary')}
      {...props}
      onClick={onClick}
    >
      {
        isLoading
          ? (
            <span
              className='spinner-border spinner-border-sm'
              role='status'
              aria-hidden='true'
            >
            </span>
          )
          : children
      }
    </ButtonMui>
  )
}

export default Button
