import { useEffect } from 'react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import 'dayjs/locale/es'

import { useUIStories } from './useUIStories'
import { useUserPreferences } from './useUserPreferences'

export function useDayJs() {
  const { isStoryMode } = useUIStories()
  const { currentLanguage } = useUserPreferences()
  let defaultLanguage = 'es'

  if (!isStoryMode) {
    defaultLanguage = currentLanguage
  }

  dayjs.extend(relativeTime)
  dayjs.extend(localizedFormat)
  dayjs.extend(utc)
  dayjs.extend(timezone)

  useEffect(() => {
    if (defaultLanguage) {
      defineLocale(defaultLanguage)
    }
  }, [defaultLanguage])

  const defineLocale = (lang = 'en') => {
    if (lang === 'es' || lang === 'en') {
      dayjs.locale(lang)
    }
  }

  const formatDateV2 = (plainDate: string | null | undefined) => {
    if (!plainDate) return ''
    return `${plainDate ? dayjs(plainDate).format('MMMM D, YYYY h:mm A') : ''}`
  }

  const formatDate = (plainDate: string | null | undefined) => {
    if (!plainDate) return ''
    return `${plainDate ? dayjs(plainDate).format('dddd, MMMM D, YYYY h:mm A') : ''} ${plainDate ? `(${dayjs(plainDate).fromNow()})` : ''}`
  }

  const formatDateCal = (plainDate: any, timezone: any) => {
    if (!plainDate || !timezone) return ''
    return `${plainDate ? dayjs(plainDate as string).tz(timezone as string).format('ddd, DD MMMM') : ''} ${plainDate ? `(${dayjs(plainDate).fromNow()})` : ''}`
  }

  const formatTimeCal = (plainDate: any, timezone: any) => {
    if (!plainDate || !timezone) return ''
    return `${plainDate ? `${dayjs.utc(plainDate as string).tz(timezone as string).format('LT')}` : ''}`
  }

  const formatTime = (plainDate: any) => {
    if (!plainDate) return ''
    return `${plainDate ? `${dayjs(plainDate as string).format('LT')}` : ''}`
  }

  const formatShortDate = (plainDate: string | null | undefined) => {
    if (!plainDate) return ''
    return `${plainDate ? `${dayjs(plainDate).fromNow()}` : ''}`
  }

  const formatDateInFormat = (plainDate: string | null | undefined, format: any) => {
    if (!plainDate || !format) return ''
    return `${plainDate ? dayjs(plainDate).format(format) : ''}`
  }

  return {
    formatDate,
    formatDateV2,
    formatDateCal,
    formatTimeCal,
    formatShortDate,
    defineLocale,
    formatDateInFormat,
    formatTime,
    dayjs
  }
}
