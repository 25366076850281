import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type GetInvoicesQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Invoices_Bool_Exp>
  limit?: Types.InputMaybe<Types.Scalars['Int']>
}>

export type GetInvoicesQuery = { __typename?: 'query_root', invoices: Array<{ __typename?: 'invoices', id: any, created_at: any, user_id?: any | undefined, business_id?: any | undefined, total?: number | undefined, job_id?: any | undefined, status?: string | undefined, metadata?: any | undefined, download_link?: string | undefined, customer?: { __typename?: 'customers', id: any, first_name?: string | undefined, last_name?: string | undefined, phone?: string | undefined, address?: any | undefined, email?: string | undefined, source?: string | undefined } | undefined }> }

export type GetPublicInvoiceQueryVariables = Types.Exact<{
  businessId: Types.Scalars['uuid']
  invoiceId: Types.Scalars['uuid']
}>

export type GetPublicInvoiceQuery = { __typename?: 'query_root', invoices: Array<{ __typename?: 'invoices', id: any, public_invoice?: any | undefined, status?: string | undefined, download_link?: string | undefined }>, businesses: Array<{ __typename?: 'businesses', email?: string | undefined, id: any, name?: string | undefined, phone?: string | undefined }> }

export const GetInvoicesDocument = gql`
    query getInvoices($where: invoices_bool_exp, $limit: Int) {
  invoices(limit: $limit, where: $where, order_by: {created_at: desc}) {
    id
    created_at
    user_id
    business_id
    total
    job_id
    status
    metadata
    download_link
    customer {
      id
      first_name
      last_name
      phone
      address
      email
      source
    }
  }
}
    `

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options)
}
export function useGetInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options)
}
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>
export type GetInvoicesQueryResult = Apollo.QueryResult<GetInvoicesQuery, GetInvoicesQueryVariables>
export const GetPublicInvoiceDocument = gql`
    query getPublicInvoice($businessId: uuid!, $invoiceId: uuid!) {
  invoices(where: {id: {_eq: $invoiceId}}, limit: 1) {
    id
    public_invoice
    status
    download_link
  }
  businesses(where: {id: {_eq: $businessId}}) {
    email
    id
    name
    phone
  }
}
    `

/**
 * __useGetPublicInvoiceQuery__
 *
 * To run a query within a React component, call `useGetPublicInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicInvoiceQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetPublicInvoiceQuery(baseOptions: Apollo.QueryHookOptions<GetPublicInvoiceQuery, GetPublicInvoiceQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetPublicInvoiceQuery, GetPublicInvoiceQueryVariables>(GetPublicInvoiceDocument, options)
}
export function useGetPublicInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicInvoiceQuery, GetPublicInvoiceQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetPublicInvoiceQuery, GetPublicInvoiceQueryVariables>(GetPublicInvoiceDocument, options)
}
export type GetPublicInvoiceQueryHookResult = ReturnType<typeof useGetPublicInvoiceQuery>
export type GetPublicInvoiceLazyQueryHookResult = ReturnType<typeof useGetPublicInvoiceLazyQuery>
export type GetPublicInvoiceQueryResult = Apollo.QueryResult<GetPublicInvoiceQuery, GetPublicInvoiceQueryVariables>
