import { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import { useField } from 'formik'

import FormLabel from '../FormLabel/FormLabel'

interface Props {
  label?: string
  name: string
  defaultValue?: string
}

const RichTextArea = ({ label, name, defaultValue }: Props) => {
  const [, meta, helpers] = useField({
    name
  })
  const [style, setStyle] = useState<{ borderRadius: string, borderColor?: string, border: string }>({
    borderRadius: '10px',
    border: '1px solid var(--bs-border-color)'
  })

  const { setValue } = helpers

  useEffect(() => {
    if (meta.error && meta.touched) {
      setStyle((previous) => {
        return {
          ...previous,
          borderColor: 'red'
        }
      })
    }
    else if (!meta.error && meta.touched) {
      setStyle((previous) => {
        return {
          ...previous,
          borderColor: 'var(--bs-border-color)'
        }
      })
    }
  }, [meta.error, meta.touched])

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [])

  return (
    <>
      { label && <FormLabel label={label} />}
      <ReactQuill
        theme='snow'
        value={meta.value}
        style={style}
        onChange={(val) => setValue(val)}
      />
      <div className='error'>{ meta.touched && meta.error }</div>
    </>
  )
}

export default RichTextArea
