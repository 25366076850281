import { useCallback, useContext, useEffect, useMemo } from 'react'

import ProfileContext from '#app/contexts/ProfileContext'
import { GetBusinessOwnerTasksQuery, useGetBusinessOwnerTasksLazyQuery } from '#app/operations/tasks/tasks.queries.generated'

type MappedTask = {
  id: string | undefined
  title: string
  description: string | undefined
  sector: string | undefined
  badges: string[] | undefined
  profile: {
    evatar: string | undefined
    firstName: string | undefined
    lastName: string | undefined
  }
}

export function useUserTasks(includeDoneTasks = false) {
  const { getProfile: { user } } = useContext(ProfileContext)
  const [getTasks, { data, loading }] = useGetBusinessOwnerTasksLazyQuery()

  const minTasks = useCallback((getTasks?: GetBusinessOwnerTasksQuery) => {
    return (getTasks?.user_tasks ?? []).reduce(
      (acc, task) => {
        const mappedTask: MappedTask = {
          id: task?.id,
          title: task.title,
          description: task.short_description,
          sector: task.tag_slug,
          badges: task.status ? [task.status] : undefined,
          profile: {
            evatar: task?.userByCreatedBy?.profile_picture_url,
            firstName: task?.userByCreatedBy?.first_name,
            lastName: task?.userByCreatedBy?.last_name,
          },
        }

        if (task?.status === 'completed' || task?.status === 'admin_completed') {
          acc.doneTasks.push(mappedTask)
        }
        else {
          acc.todoTasks.push(mappedTask)
        }

        return acc
      },
      {
        todoTasks: [] as MappedTask[],
        doneTasks: [] as MappedTask[],
      }
    )
  }, [])

  const { doneTasks, todoTasks } = useMemo(() => minTasks(data), [data, minTasks])

  const initView = useCallback(() => {
    if (user?.id) {
      void getTasks ({
        variables: {
          userId: user?.id,
          status: [
            'assigned',
            'replied',
            'response_draft',
            ...(includeDoneTasks ? ['completed', 'admin_completed'] : [])
          ]
        },
      })
    }
  }, [getTasks, includeDoneTasks, user?.id])

  useEffect(() => {
    initView()
  }, [initView])

  return {
    todoTasks,
    loading,
    doneTasks,
  }
}
