import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import { User } from '#app/types'

import ProfileContext from '../contexts/ProfileContext'
import { UPDATE_USER_PREFERENCES } from '../utils/graphqlQueries'

export function useUserPreferences() {
  const data = useContext(ProfileContext)
  const { i18n } = useTranslation()

  const user = useMemo(() => {
    if (data?.getProfile?.user) {
      return data.getProfile.user
    }
    return null
  }, [data])

  const defaultLanguage = navigator.language
    ? navigator.language === 'es' || navigator.language === 'en'
      ? navigator.language
      : i18n.language
    : 'en'

  // still user.language takes precedence in case the user sets a different language than the one in Alt Bo
  const [language, setLanguage] = useState(
    user?.language ?? data?.getProfile?.altBoProfile?.preferred_language ?? defaultLanguage
  )

  const [updateUserPreferencesById] = useMutation(UPDATE_USER_PREFERENCES)

  const refreshLanguage = useCallback((userEntity: User.UserType) => {
    if (userEntity?.language) {
      setLanguage(userEntity?.language)
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      i18n.changeLanguage(userEntity?.language)
    }
  }, [])

  const setLanguageGlobal = useCallback(async (newLang: string) => {
    try {
      if (user) {
        setLanguage(newLang)
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        i18n.changeLanguage(newLang)

        await updateUserPreferencesById({
          variables: {
            id: user.id,
            language: newLang
          }
        })
      }
      return null
    }
    catch (err) {
      console.error(err)
    }
  }, [i18n, updateUserPreferencesById, user])

  const changeLanguage = useCallback(
    async (e: any) => {
      const newLang = e.target.value as string
      await setLanguageGlobal(newLang)
      return true
    },
    [setLanguageGlobal]
  )

  return {
    setLanguageGlobal,
    changeLanguage,
    refreshLanguage,
    currentLanguage: language,
    bankingStatus: user?.stripe_banking_status,
  }
}
