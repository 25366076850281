import { useCallback, useContext, useMemo } from 'react'
import { ApolloCache, getApolloContext, useLazyQuery, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { Comment, Generic } from '#app/types'
import { MUTATION, QUERY, QUERY_GET_COMMENTS } from '#app/utils/graphqlQueries'

interface Params {
  entityId: Generic.UUID
  entityType: string
  private_note?: boolean
}

export function useEntityComments({ entityId, entityType, private_note }: Params) {
  const { enqueueSnackbar } = useSnackbar()
  const { client } = useContext(getApolloContext())

  const variablesQuery = useMemo(() => {
    let privateNotesIn = [false]

    if (private_note) {
      privateNotesIn = [true, false]
    }

    return {
      entityId,
      entityType,
      privateNotesIn
    }
  }, [entityId, entityType, private_note])

  const updateCacheApollo = useCallback((mutationName: string, cache: ApolloCache<any>, data: any) => {
    const cacheQuery = cache.readQuery<QUERY_GET_COMMENTS>({
      query: QUERY.comments.getComments,
      variables: variablesQuery
    })

    if (cacheQuery?.comments) {
      try {
        const mainComment: Comment.CommentType = data[mutationName].comment
        let commentsUpdated: Comment.CommentType[] = [...(cacheQuery?.comments ?? [])]

        if (mutationName === 'updateComment') {
          commentsUpdated = cacheQuery?.comments.map((comment) => {
            if (comment?.id === mainComment.id) {
              const commentEdited: Comment.CommentType = {
                ...comment,
                ...mainComment
              }
              return commentEdited
            }
            return comment
          })
        }
        else if (mutationName === 'deleteComment') {
          commentsUpdated = cacheQuery?.comments.filter((comment) => {
            if (comment?.id !== mainComment.id) {
              return true
            }
            return false
          })
        }
        else if (mutationName === 'createComment') {
          commentsUpdated.push({
            ...mainComment,
            __typename: 'comments'
          })
        }

        cache.writeQuery({
          query: QUERY.comments.getComments,
          variables: variablesQuery,
          data: {
            comments: commentsUpdated
          }
        })
      }
      catch (err) {
        enqueueSnackbar('We have an error updating the screen', {
          variant: 'error'
        })
      }
    }
  }, [enqueueSnackbar, variablesQuery])

  const [mutationDeleteComment, mutationDeleteCommentUtils] = useMutation(MUTATION.comments.deleteComment, {
    update(cache, { data }) {
      updateCacheApollo('deleteComment', cache, data)
    }
  })

  const [mutationCreateComment, mutationCreateCommentUtils] = useMutation(MUTATION.comments.createComment, {
    update(cache, { data }) {
      if (data.createComment.id) {
        updateCacheApollo('createComment', cache, data)
      }
    }
  })

  const queryCommentCache = client?.readQuery({
    query: QUERY.comments.getComments,
    variables: variablesQuery
  })

  const [
    queryGetComments,
    { data: queryGetCommentsData, ...queryGetCommentsUtils }
  ] = useLazyQuery<QUERY_GET_COMMENTS>(QUERY.comments.getComments, {
    variables: variablesQuery
  }) // pollInterval: 1000 * 120

  return {
    // GraphQL Query
    queryGetComments,
    queryCommentCache,
    queryGetCommentsData,
    queryGetCommentsUtils,
    // GraphQL Mutation
    mutationCreateComment,
    mutationCreateCommentUtils,

    mutationDeleteComment,
    mutationDeleteCommentUtils
  }
}
