import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Stack } from '@mui/system'

import { Alert } from '#app/components/Alerts'
import AppContext from '#app/contexts/AppContext'
import { useDayJs } from '#app/hooks/useDayJs'
import { useBankingOnboardMutation } from '#app/operations/banking/banking.mutations.generated'
import { User } from '#app/types'

export const ConnectedAccountStatus = ({ user }: { user?: User.UserType }) => {
  const { dayjs } = useDayJs()
  const { appState } = useContext(AppContext)
  const { t } = useTranslation()
  const [bankingOnboard] = useBankingOnboardMutation()

  const futureRequirements = user?.stripe_connected_acc?.future_requirements?.currently_due ?? []

  const futureRequirementsDate = Number(user?.stripe_connected_acc?.future_requirements?.current_deadline ?? 0)

  const futureRequirementsDateReadable = dayjs.unix(futureRequirementsDate).format('YYYY-MM-DD')

  const isPayoutsEnabled = user?.stripe_connected_acc?.payouts_enabled

  const redirectToOnboarding = async () => {
    return await bankingOnboard(
      {
        variables: {
          id: user?.id ?? ''
        }
      })
      .then((result) => {
        const url = result.data?.createBankingOnboard?.url
        if (url) {
          window.location.replace(url)
        }
      })
  }

  // the only exception to this banner is users during onboarding state
  if (user?.stripe_banking_status === 'onboarding') {
    return <></>
  }

  return (
    <Stack spacing={1}>
      {
        isPayoutsEnabled === false
        && (
          <a
            href={`http://wa.me/${appState.supportPhone}`}
            style={{
              textDecoration: 'none'
            }}
            target='_blank'
            rel='noreferrer'
          >
            <Alert.Basic
              severity='error'
              description={t(
                'banking.connectedAccountStatus.payoutsDisabled',
                'Your payouts have been suspended, please reach out to support for assistance, click on this banner to open a chat with an account manager'
              )}
            />
          </a>
        )
      }
      {
        futureRequirements.length > 0
        && (
          <Box
            sx={{
              cursor: 'pointer'
            }}
            onClick={redirectToOnboarding}
          >
            <Alert.Basic
              severity='warning'
              description={t(
                'banking.connectedAccountStatus.futureRequirements',
                'We need more information about you, please complete your information by clicking on this banner, if you don\'t complete the missing informaiton by {{deadline}} your payouts will be suspended',
                {
                  deadline: futureRequirementsDateReadable
                }
              )}
            />
          </Box>
        )
      }
    </Stack>
  )
}
