/* eslint-disable @typescript-eslint/no-unsafe-call */
import { isValidPhoneNumber } from 'react-phone-number-input'
import { gql } from '@apollo/client'
import { Typography } from '@mui/material'
import { Form, Formik, FormikConfig } from 'formik'
import { t } from 'i18next'
import * as Yup from 'yup'

import PhoneNumberInput from '#app/components/PhoneNumberInput/PhoneNumberInput'
import { useLinkPhoneMutation } from '#app/operations/business-owners/business-owners.mutations.generated'
import { User } from '#app/types'

import Button from '../../components/Buttons/Button'
interface Props {
  submitCallback: (error?: unknown) => void
  selectedOwner: User.UserType | undefined
}

interface SubmitData {
  phone: string
  email: string
}

const LinkPhoneOwner = ({ submitCallback, selectedOwner, ...props }: Props) => {
  const [linkPhone] = useLinkPhoneMutation()

  const onSubmit: FormikConfig<SubmitData>['onSubmit'] = async (data, { resetForm }) => {
    try {
      await linkPhone({
        variables: data,
        update(cache) {
          const cacheId = `User:${selectedOwner?.id}`
          const cacheData: { linked_phones: [] } | null = cache.readFragment({
            id: cacheId,
            fragment: gql`
          fragment _ on User {
            linked_phones
          }
        `,
          })
          const newLinkedPhones = cacheData?.linked_phones ? [...cacheData.linked_phones, data.phone] : [data.phone]
          cache.writeFragment({
            id: cacheId,
            fragment: gql`
          fragment _ on User {
            linked_phones
          }
        `,
            data: {
              linked_phones: newLinkedPhones,
            },
          })
        },
      })

      submitCallback()
    }
    catch (err) {
      submitCallback(err)
      return
    }

    resetForm()
  }

  const schema = Yup
    .object()
    .shape({
      phone: Yup
        .string()
        .required('This is a required field')
        .test('isValidPhoneNumber', 'Phone number is invalid',
          (value) => {
            if (value) {
              return isValidPhoneNumber(value)
            }
            return true
          })
    })

  const initialValues = {
    phone: '',
    email: selectedOwner?.email ?? ''
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      {...props}
    >
      {({ isSubmitting }) => (
        <Form>
          <Typography variant='h4'>
            Add new phone
          </Typography>
          <div className='row g-3 g-md-4 h-100 mt-3'>
            <div className='col-md-12'>
              <PhoneNumberInput
                label='Phone'
                name='phone'
              />
            </div>
          </div>
          <div className='div-modal-input mt-4'>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type='submit'
            >
              {t('ownersManagement.linkPhone')}
            </Button>
          </div>
        </Form>
      )}

    </Formik>
  )
}

export default LinkPhoneOwner
