export const tags = [
  {
    text: 'Grow: Onboarding Call',
    value: 'grow-onboarding-call'
  },
  {
    text: 'Branding: Company Name',
    value: 'branding-company-name'
  },
  {
    text: 'Branding: Domain',
    value: 'branding-domain'
  },
  {
    text: 'Branding: Logo',
    value: 'branding-logo'
  },
  {
    text: 'App: Call Center',
    value: 'app-call-center'
  },
  {
    value: 'branding-website',
    text: 'Branding: Website'
  },
  {
    text: 'Branding: Marketing Materials',
    value: 'branding-marketing-materials'
  },
  {
    value: 'grow-social-media',
    text: 'Grow: Social Media'
  },
  {
    text: 'Grow: Paid Portals',
    value: 'grow-paid-portals'
  },
  {
    text: 'Grow: Organic Portals',
    value: 'grow-organic-portals'
  },
  {
    text: 'Legal: Incorporation',
    value: 'legal-incorporation'
  },
  {
    text: 'Grow: Outbound Marketing',
    value: 'grow-outbound-marketing'
  },
  {
    text: 'Legal: Insurance',
    value: 'legal-insurance'
  },
  {
    value: 'branding-email',
    text: 'Branding: Email'
  },
  {
    text: 'App: Owners',
    value: 'app-owners'
  },
  {
    text: 'Grow: Strategy Call',
    value: 'grow-strategy-call'
  },
  {
    text: 'Legal: License',
    value: 'legal-license'
  },
  {
    text: 'Grow: Weekly Marketing Report',
    value: 'grow-weekly-marketing-report'
  },
  {
    text: 'Grow: Government',
    value: 'grow-government'
  },
  {
    value: 'grow-inbound-marketing',
    text: 'Grow: Inbound Marketing'
  }
]
