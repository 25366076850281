/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Grid, Typography } from '@mui/material'
import { capitalize } from 'lodash'

import { DialogDelete } from '#app/components/Dialog/DialogDelete'
import { useAppContext } from '#app/contexts/AppContext/useAppContext'
import { AdvancedFilters } from '#app/forms/OwnersFilter'
import { useEntityUser } from '#app/hooks/useEntityUser'
import useMutationWithNotification from '#app/hooks/useMutationWithNotification'
import { getWhereClause, useBusinessWhere } from '#app/hooks/useOwners'
import { useBlockUserMutation } from '#app/operations/users/users.mutations.generated'
import { User } from '#app/types'

import PageContent from '../../components/PageContent/PageContent'
import Table, { TableStructure } from '../../components/Table/Table'
import AppContext from '../../contexts/AppContext'
import { DELETE_USER, GET_BUSINESS_TYPE, GET_BUSINESSES } from '../../utils/graphqlQueries'
import { useIsDesktopSize } from '../../utils/mediaQueries'

import OwnersDashboard from './OwnersDashboard'
import OwnersList from './OwnersList'

interface Props {
  currentOwner?: User.UserType
  owner: User.UserType
  onClick: () => void
  onDelete?: () => void
  onBlock?: () => void
}

const OwnerRow = ({ currentOwner, onClick, owner }: Props) => {
  const isDesktop = useIsDesktopSize()

  return (
    <Table.RowMobile
      className={`${(currentOwner?.id === owner.id && isDesktop) ? 'v1-owner-row-selected' : ''} card-body pt-0`}
      role='button'
      onClick={onClick}
    >
      <Grid
        container
        wrap='nowrap'
      >
        <Grid
          item
          zeroMinWidth
          xs
        >
          <Typography noWrap>
            {((owner.first_name && `${owner.first_name} ${owner.last_name}`) ?? 'Unknown')}
          </Typography>
          <Typography
            noWrap
            style={{
              fontSize: '0.70rem'
            }}
          >
            { owner.email }
          </Typography>
        </Grid>
      </Grid>
    </Table.RowMobile>
  )
}

interface PropsBusinessOwnerModular {
  title?: string
  header?: React.FunctionComponent
  pathname: string
  contentByOwner: React.FunctionComponent<{ currentOwner?: User.UserType, where?: any }>
}

export const BusinessOwnerModular = ({ contentByOwner: ContentByOwner, title, header: Header, pathname }: PropsBusinessOwnerModular) => {
  const { appState, setAppState } = useContext(AppContext)
  const { V1_SET_LOADING } = useAppContext()
  const [currentOwner, setCurrentOwner] = useState<User.UserType>()
  const isDesktop = useIsDesktopSize()
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  const { userEmail } = useEntityUser()

  const [where, setWhereClause] = useBusinessWhere('%')

  const { loading, data, refetch } = useQuery<GET_BUSINESS_TYPE>(GET_BUSINESSES, {
    variables: {
      where: getWhereClause('%'),
      limit: 50
    }
  })

  // start - filter owners
  const [advancedFilters, setAdvancedFilters] = useState<AdvancedFilters>({
    cities: [],
    services: '',
    language: '',
    linkedPhone: ''
  })

  const onAdvancedFilterChange = (values?: AdvancedFilters, closeHandler?: () => void, query?: string) => {
    setAdvancedFilters(values ?? {
      cities: [],
      services: '',
      language: '',
      linkedPhone: ''
    })

    const language = values?.language
    const services = values?.services
    const cities = values?.cities.map((c: { place_id: string }) => c.place_id)
    const linkedPhone = values?.linkedPhone

    const isClearing = !values?.language && !values?.services && (!values?.cities || values?.cities.length === 0) && !values?.linkedPhone

    if (isClearing) {
      // @ts-ignore
      setWhereClause(query ?? '%')
    }
    else {
    // @ts-ignore
      setWhereClause(query ?? '%', services, cities, language, where, linkedPhone)
    }

    if (closeHandler) {
      closeHandler()
    }
  }
  // end - filter owners

  // start - delete owner
  const [isDeleting, setDeleting] = useState(false)
  const [isBlocking, setBlocking] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [block, setBlock] = useState(false)
  const [deleteUserById] = useMutationWithNotification(DELETE_USER)
  const [blockUser] = useBlockUserMutation()

  const onDelete = async () => {
    setDeleting(true)

    try {
      await deleteUserById({
        variables: {
          id: currentOwner?.id
        }
      })

      await refetch()

      setCurrentOwner(undefined)
    }
    finally {
      setDeleting(false)
      setOpen(false)
    }
  }

  const onBlock = async () => {
    setBlocking(true)

    try {
      await blockUser({
        variables: {
          id: currentOwner?.id,
          block: !currentOwner?.blocked,
          block_user: userEmail ?? '',
          block_reason: 'blocked_manually'
        }
      })

      await refetch()

      setCurrentOwner(undefined)
    }
    finally {
      setBlocking(false)
      setBlock(false)
    }
  }

  const ownersData: User.UserType[] | [] = useMemo(() => {
    if (data) {
      return data
        .businesses
        .map((business) => {
          return {
            ...business.user,
            businesses: [business]
          }
        })
    }
    return []
  }, [data])

  const selectRow = useCallback((owner: User.UserType) => {
    const urlNavigate = `/${pathname}/${owner.id}`
    setCurrentOwner(owner)
    if (!params.userId || params.userId !== owner.id) {
      navigate(urlNavigate)
    }
  }, [navigate, params.userId, pathname])

  useEffect(() => {
    if (data) {
      if (appState.isLoading) {
        V1_SET_LOADING(false)
      }
    }
  }, [appState.isLoading, data, params.userId, selectRow, currentOwner, isDesktop, setAppState, ownersData])

  useEffect(() => {
    if (ownersData && ownersData.length > 0) {
      if (params?.userId && !currentOwner) {
        ownersData.map((bo) => {
          if (bo && bo.id === params.userId) {
            selectRow(bo)
          }
        })
      }
      else if (isDesktop && (!params.userId || !currentOwner) && ownersData[0]) {
        selectRow(ownersData[0])
      }
    }
  }, [params?.userId, ownersData, currentOwner, selectRow, isDeleting, isDesktop, isBlocking])

  useEffect(() => {
    if (!loading) {
      refetch({
        where,
        limit: 50
      })
        .catch((err: { toString: () => string }) => {
          console.error(err.toString())
        })
    }
  // @ts-ignore
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancedFilters?.cities, advancedFilters?.language, advancedFilters?.linkedPhone, advancedFilters?.services, refetch, where])

  const searchUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = '%' + e.target.value + '%'
    // @ts-ignore
    onAdvancedFilterChange(undefined, undefined, query)
  }

  // data
  const structure: TableStructure = {
    mobile: {
      rows: ownersData
        .map((owner: User.UserType) => ({
          key: owner.id,
          columns: [
            {
              component: (
                <OwnerRow
                  currentOwner={currentOwner}
                  owner={owner}
                  onClick={() => selectRow(owner)}
                />
              )
            }
          ]
        }))
    }
  }

  return (
    <PageContent>
      {
        Header
        && <Header />
      }
      {
        !Header
        && (
          <PageContent.PageHeader>
            <Typography
              variant='h4'
              sx={{
                cursor: 'pointer'
              }}
              onClick={() => navigate(`/${pathname}`)}
            >
              {!isDesktop && params?.userId && <>&#60;</>}
              {' '}
              {title}
            </Typography>
          </PageContent.PageHeader>
        )
      }
      <Routes>
        {
          !isDesktop
          && (
            <Route
              path='/:userId'
              element={(
                <>
                  {currentOwner
                  && (
                    <ContentByOwner
                      where={where}
                      currentOwner={currentOwner}
                    />
                  )}
                </>
              )}
            >
            </Route>
          )
        }
        <Route
          path={'/*'}
          element={(
            <>
              {
                isDesktop
                  ? (
                    <OwnersDashboard
                      tableStructure={structure}
                      currentOwner={currentOwner}
                      contentByOwner={ContentByOwner}
                      advancedFilters={advancedFilters}
                      where={where}
                      onFilterChange={searchUser}
                      onAdvancedFilterChange={onAdvancedFilterChange}
                      onDelete={() => {
                        setCurrentOwner(currentOwner)
                        setOpen(true)
                      }}
                      onBlock={() => {
                        setCurrentOwner(currentOwner)
                        setBlock(true)
                      }}
                    />
                  )
                  : (
                    <OwnersList
                      tableStructure={structure}
                      advancedFilters={advancedFilters}
                      onFilterChange={searchUser}
                      onAdvancedFilterChange={onAdvancedFilterChange}
                    />
                  )
              }
            </>
          )}
        />
      </Routes>
      <DialogDelete
        open={isOpen}
        setOpen={setOpen}
        texts={{
          description: t('general.deleteDescription', {
            identifier: currentOwner?.email ?? ''
          }),
          confirm: capitalize(t('generic.confirm')),
          cancel: capitalize(t('generic.cancel'))
        }}
        isLoading={isDeleting}
        onConfirm={onDelete}
      />
      <DialogDelete
        open={block}
        setOpen={setBlock}
        texts={{
          title: currentOwner?.blocked ? t('dialogs.unblock.title') : t('dialogs.block.title'),
          description: currentOwner?.blocked
            ? t('general.unblockDescription', {
              identifier: currentOwner?.email ?? ''
            })
            : t('general.blockDescription', {
              identifier: currentOwner?.email ?? ''
            }),
          confirm: capitalize(t('generic.confirm')),
          cancel: capitalize(t('generic.cancel'))
        }}
        isLoading={isBlocking}
        onConfirm={onBlock}
      />
    </PageContent>
  )
}
