import { useCallback, useEffect, useRef, useState } from 'react'
import { html } from '@codemirror/lang-html'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'
import { vscodeDark } from '@uiw/codemirror-theme-vscode'
import CodeMirror from '@uiw/react-codemirror'

import { COLORS } from '#app/layouts/theme'
import useWebsiteEditorStore, { SectionPosition } from '#app/store/website-editor'

import { SectionProps } from '../../types'

interface Props {
  htmlCode: string | undefined
  sectionId: string
  lexorank: string
}

function findAdjacentSections(sections: Record<string, SectionProps>, currentLexorank: string): { next: string | undefined, prev: string | undefined } {
  let next, prev = undefined
  try {
    const sectionsArray = Object.keys(sections).map((key) => ({
      ...sections[key],
      sectionId: key
    }))
    const sortedSections = sectionsArray.sort((a, b) => a.lexorank.localeCompare(b.lexorank))
    const currentIndex = sortedSections.findIndex((section) => section.lexorank === currentLexorank)
    prev = currentIndex - 1 >= 0 ? sortedSections[currentIndex - 1].lexorank : undefined
    next = currentIndex + 1 < sortedSections.length ? sortedSections[currentIndex + 1].lexorank : undefined
  }
  catch (err) {
    console.error(err)
  }
  return {
    prev,
    next
  }
}

export function ManualEdit({ sectionId, lexorank, htmlCode }: Props) {
  const typingTimeoutRef = useRef<number | null>(null)
  const { updateSectionDetails, sections, openNewSectionDrawer } = useWebsiteEditorStore()

  const newSection = useCallback((position: SectionPosition) => {
    const { prev, next } = findAdjacentSections(sections, lexorank)

    const lexorankContext = {
      prev,
      next,
      current: lexorank
    }

    openNewSectionDrawer(position, lexorankContext)
  }, [sections, lexorank, openNewSectionDrawer])

  const newDynamicVariable = useCallback(() => {
    const currentVars = sections[sectionId]?.nunjucksVars ?? []
    if (sections[sectionId]) {
      // console.log('[updateSectionDetails] ManualEdit - newDynamicVariable()')
      updateSectionDetails(sectionId, {
        ...sections[sectionId],
        nunjucksVars: [
          ...currentVars,
          {
            keyValue: 'Nueva variable',
            keyNunjuck: `new.dynamic.${Date.now()}`,
            aiPrompt: '',
            isArray: false
          }
        ]
      })
    }

    return null
  }, [sectionId, sections, updateSectionDetails])

  const handleOnChangeCode = (value: string) => {
    if (typingTimeoutRef.current !== null) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = window.setTimeout(() => {
      // console.log('[updateSectionDetails] ManualEdit - handleOnChangeCode()')
      updateSectionDetails(sectionId, {
        htmlCode: value
      })
    }, 5000)
  }

  useEffect(() => {
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current)
      }
    }
  }, [])

  const [editorDisabled, setEditorDisabled] = useState(true)

  return (
    <Grid
      xs
      flexGrow={1}
      padding={1}
    >
      <FormGroup>
        <FormControlLabel
          control={(
            <Switch
              checked={editorDisabled}
              color='error'
              onChange={(event) => {
                setEditorDisabled(event.target.checked)
              }}
            />
          )}
          label={!editorDisabled ? 'Free HTML' : 'Blocked HTML'}
          sx={{
            '.MuiFormControlLabel-label': {
              minWidth: '5ch',
              color: editorDisabled ? COLORS.custom.error : COLORS.dark.primary,
              fontWeight: '600',
            },
            marginRight: '0px'
          }}
        />
      </FormGroup>
      <CodeMirror
        theme={editorDisabled ? 'light' : vscodeDark}
        value={htmlCode}
        extensions={[html()]}
        height={editorDisabled ? '250px' : '600px'}
        readOnly={editorDisabled}
        style={{
          marginBottom: '1rem'
        }}
        onChange={(value) => { // viewUpdate, viewUpdate
          handleOnChangeCode(value)
        }}
      />
      <Grid
        container
        gap={1}
      >
        <Grid xs>
          <Button
            sx={{
              minWidth: 'auto',
              width: '100%'
            }}
            variant='contained'
            onClick={newDynamicVariable}
          >
            New dynamic variable
          </Button>
        </Grid>
        <Grid xs>
          <Button
            variant='outlined'
            sx={{
              minWidth: 0,
              width: '100%'
            }}
            onClick={() => newSection('above')}
          >
            Section above
          </Button>
        </Grid>
        <Grid xs>
          <Button
            sx={{
              minWidth: 0,
              width: '100%'
            }}
            variant='outlined'
            onClick={() => newSection('below')}
          >
            Section below
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
