import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { useStates } from '#app/hooks/useStates'
import { useUserPreferences } from '#app/hooks/useUserPreferences'

import DatePickerInput from '../../components/DatePickerInput/DatePickerInput'
import FormInput from '../../components/FormInput/FormInput'
import FormSelect from '../../components/FormSelect/FormSelect'
import InputWithChips from '../../components/InputWithChips/InputWithChips'
import NavigationOnboarding from '../../components/NavigationOnboarding/NavigationOnboarding'

const validationSchema = (t) => Yup
  .object()
  .shape({
    firstName: Yup
      .string()
      .required(t('validation.required')),
    lastName: Yup
      .string()
      .required(t('validation.required')),
    existingBusiness: Yup
      .string()
      .required(t('validation.required')),
    timeZone: Yup
      .string()
      .required(t('validation.required')),
    birthday: Yup
      .string()
      .nullable()
      .required(t('validation.required')),
    state: Yup
      .string()
      .required(t('validation.required')),
    language: Yup
      .string()
      .required(t('validation.required'))
  })

const simplifiedValidationSchema = (t) => Yup
  .object()
  .shape({
    firstName: Yup
      .string()
      .required(t('validation.required')),
    lastName: Yup
      .string()
      .required(t('validation.required')),
    existingBusiness: Yup
      .string()
      .required(t('validation.required')),
    language: Yup
      .string()
      .required(t('validation.required'))
  })

const SimplifiedPersonalInfoFields = ({ fieldNames = {}, onChangeLanguage }) => {
  const { t } = useTranslation()

  return (
    <div className='row g-3 g-md-4'>
      <div className='col-md-6'>
        <FormInput
          label={t('onboarding.personalInformation.firstName.label')}
          name={fieldNames.firstName || 'firstName'}
          placeholder={t('onboarding.personalInformation.firstName.placeholder')}
        />
      </div>
      <div className='col-md-6'>
        <FormInput
          label={t('onboarding.personalInformation.lastName.label')}
          name={fieldNames.lastName || 'lastName'}
          placeholder={t('onboarding.personalInformation.lastName.placeholder')}
        />
      </div>
      <div className='col-md-6'>
        <FormSelect
          label={t('onboarding.personalInformation.language.label')}
          name={fieldNames.language || 'language'}
          // https://www.w3.org/International/articles/language-tags/
          options={[
            {
              text: 'English',
              value: 'en'
            },
            {
              text: 'Español',
              value: 'es'
            }
          ]}
          onChange={onChangeLanguage}
        />
      </div>
      <div className='col-md-6'>
        <FormSelect
          label={t('onboarding.personalInformation.existingBusiness.label')}
          name={fieldNames.existingBusiness || 'existingBusiness'}
          placeholder={t('onboarding.personalInformation.existingBusiness.placeholder')}
          options={[
            {
              text: t('booleans.yes'),
              value: true
            },
            {
              text: t('booleans.no'),
              value: false
            }
          ]}
        />
      </div>
    </div>
  )
}

const PersonalInfoFields = ({ fieldNames = {} }) => {
  const { t } = useTranslation()
  const [states, setStates] = useState([])
  const { statesData, isLoading } = useStates()
  useEffect(() => {
    if (!isLoading && statesData?.length > 0) {
      setStates(statesData.map((e) => e.name))
    }
  }, [isLoading])

  return (
    <div className='row g-3 g-md-4'>
      <div className='col-md-6'>
        <FormInput
          label={t('onboarding.personalInformation.firstName.label')}
          name={fieldNames.firstName || 'firstName'}
          placeholder={t('onboarding.personalInformation.firstName.placeholder')}
        />
      </div>
      <div className='col-md-6'>
        <FormInput
          label={t('onboarding.personalInformation.lastName.label')}
          name={fieldNames.lastName || 'lastName'}
          placeholder={t('onboarding.personalInformation.lastName.placeholder')}
        />
      </div>
      <div className='col-md-6'>
        <FormSelect
          label={t('onboarding.personalInformation.existingBusiness.label')}
          name={fieldNames.existingBusiness || 'existingBusiness'}
          placeholder={t('onboarding.personalInformation.existingBusiness.placeholder')}
          options={[
            {
              text: t('booleans.yes'),
              value: true
            },
            {
              text: t('booleans.no'),
              value: false
            }
          ]}
        />
      </div>
      <div className='col-md-6 d-flex flex-column justify-content-end'>
        <InputWithChips
          label={t('onboarding.personalInformation.state.label')}
          name={fieldNames.state || 'state'}
          options={states}
          disabled={isLoading ?? statesData.length === 0}
        />
      </div>
      <div className='col-md-6 d-flex flex-column justify-content-end'>
        <DatePickerInput
          label={t('onboarding.personalInformation.birthday.label')}
          name={fieldNames.birthday || 'birthday'}
        />
      </div>
      <div className='col-md-6'>
        <FormSelect
          label={t('onboarding.personalInformation.language.label')}
          name={fieldNames.language || 'language'}
          // https://www.w3.org/International/articles/language-tags/
          options={[
            {
              text: 'English',
              value: 'en'
            },
            {
              text: 'Español',
              value: 'es'
            }
          ]}
        />
      </div>
    </div>
  )
}

const PersonalInfoForm = ({ information, onSubmit, goBack }) => {
  const { t } = useTranslation()
  const { changeLanguage, currentLanguage } = useUserPreferences()

  const initialValues = {
    language: currentLanguage,
    ...information
  }

  const schema = simplifiedValidationSchema(t)

  return onSubmit
    ? (
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize={true}
        onSubmit={(values) => {
          onSubmit(values)
        }}
      >
        {
          ({ handleSubmit }) => (
            <Form>
              <SimplifiedPersonalInfoFields onChangeLanguage={changeLanguage} />
              <NavigationOnboarding
                handleSubmit={handleSubmit}
                goBack={goBack}
                page={false}
              />
            </Form>
          )
        }
      </Formik>
    )
    : (
      <div></div>
      // <CurrentForm />
    )
}

export {
  PersonalInfoFields,
  validationSchema
}

export default PersonalInfoForm
