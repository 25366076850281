import { useCallback } from 'react'
import { ApolloCache, useLazyQuery, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { Task } from '#app/types'
import { MUTATION, QUERY, QUERY_GET_TASK_TEMPLATES } from '#app/utils/graphqlQueries'

import useMutationWithNotification from './useMutationWithNotification'

export function useEntityTaskTemplates() {
  const { enqueueSnackbar } = useSnackbar()

  const updateCacheApollo = useCallback((mutationName: string, cache: ApolloCache<any>, data: any) => {
    const cacheQuery = cache.readQuery<QUERY_GET_TASK_TEMPLATES>({
      query: QUERY.tasksTemplates.getTemplates
    })

    if (cacheQuery?.task_templates && mutationName) {
      try {
        const taskData = data[mutationName]?.taskTemplate
        const taskTemplateUpdated: Task.Template = {
          ...taskData,
          __typename: 'task_templates'
        }

        let taskTemplatesUpdated: Task.Template[]

        if (mutationName === 'createTaskTemplate') {
          taskTemplatesUpdated = [...(cacheQuery?.task_templates ?? []), taskTemplateUpdated]
        }
        else {
          taskTemplatesUpdated = cacheQuery?.task_templates.map((template) => {
            if (template?.id === taskTemplateUpdated.id) {
              return taskTemplateUpdated
            }
            return template
          })
        }

        cache.writeQuery({
          query: QUERY.tasksTemplates.getTemplates,
          data: {
            task_templates: taskTemplatesUpdated
          }
        })
      }
      catch (err) {
        enqueueSnackbar('We have an error updating the screen', {
          variant: 'error'
        })
      }
    }
  }, [enqueueSnackbar])

  const [
    queryGetTaskTemplates,
    { data: queryGetTaskTemplatesData, ...queryGetTaskTemplatesUtils }
  ] = useLazyQuery<QUERY_GET_TASK_TEMPLATES>(QUERY.tasksTemplates.getTemplates)

  const [mutationCreateTaskTemplate, mutationCreateTaskTemplateUtils] = useMutation(MUTATION.taskTemplates.createTaskTemplate, {
    update(cache, { data }) {
      updateCacheApollo('createTaskTemplate', cache, data)
    }
  })

  const [mutationDeleteTaskTemplate, { loading: mutationDeleteTaskTemplateLoading }] = useMutationWithNotification(MUTATION.taskTemplates.deleteTaskTemplate, {
    update(cache, { data }) {
      updateCacheApollo('deleteTaskTemplate', cache, data)
    }
  })

  return {
    // GraphQL Query
    queryGetTaskTemplates,
    queryGetTaskTemplatesData,
    queryGetTaskTemplatesUtils,
    // GraphQL Mutation
    mutationCreateTaskTemplate,
    mutationCreateTaskTemplateUtils,
    // GraphQL Mutation
    mutationDeleteTaskTemplate,
    mutationDeleteTaskTemplateLoading
  }
}
