import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'

import AppContext from '#app/contexts/AppContext'
import { useAppContext } from '#app/contexts/AppContext/useAppContext'
import ProfileContext from '#app/contexts/ProfileContext'
import { EntityPage } from '#app/layouts/EntityView'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

export const Scheduler = () => {
  const { appState } = useContext(AppContext)
  const { V1_SET_LOADING } = useAppContext()
  const { getProfile: { user } } = useContext(ProfileContext)
  const [loading, setLoading] = useState(true)
  const isDesktop = useIsDesktopSize()
  const { t } = useTranslation()

  const desktopStyle = {
    paddingTop: 0,
    height: '99vh',
    width: '100%'
  }
  const mobileStyle = {
    paddingTop: '1.25rem',
    height: '99vh',
    width: '100%'
  }
  const [iframeStyles, setIframeStyles] = useState(isDesktop ? desktopStyle : mobileStyle)

  useEffect(() => {
    if (appState.isLoading) {
      V1_SET_LOADING(false)
    }
    if (!loading) {
      V1_SET_LOADING(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIframeStyles(isDesktop ? desktopStyle : mobileStyle)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop])

  if (user?.cal_email && user?.cal_hashed_password) {
    return (
      <>
        {loading && (
          <Box sx={{
            height: '100vh',
            width: '100vw',
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          >
            <CircularProgress size={45} />
          </Box>
        )}
        <iframe
          style={iframeStyles}
          name='owners-embed'
          allow='clipboard-write'
          src={`${process.env.REACT_APP_CAL_DOMAIN ?? ''}/auth/login?email=${encodeURIComponent(user.cal_email) || ''}&hashedPassword=${encodeURIComponent(user.cal_hashed_password) || ''}`}
          onLoad={() => setLoading(false)}
        />
      </>
    )
  }

  return (
    <EntityPage
      isLoading={false}
      layout='one-column'
      pageTitle={t('scheduler.pageTitle', 'Scheduler')}
      mainContent={(
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Stack spacing={1}>
              <Typography variant='h4'>{t('scheduler.misconfiguredTitle', 'Your calendar account is not configured')}</Typography>
              <Typography>
                {t('scheduler.misconfiguredDescription', 'Please reach out to our support team to enable this feature')}
                {' '}
                <a
                  href={`http://wa.me/${appState.supportPhone}`}
                  style={{
                    textDecoration: 'none'
                  }}
                  target='_blank'
                  rel='noreferrer'
                >
                  +
                  {appState.supportPhone}
                </a>
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      )}
    />
  )
}
