import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Stack, Typography } from '@mui/material'
import produce from 'immer'
import { useImmer } from 'use-immer'

import confetti from '#app/assets/img/icon_confetti_cannon.png'
import { LoadingGrid } from '#app/components/Loading/LoadingGrid'
import { Button } from '#app/v2/components/ui/button'
import { RadioGroup } from '#app/v2/components/ui/radio-group'
import { Actions } from '#app/v2/pages/Wizard/components/Actions'
import { ConfirmationCard } from '#app/v2/pages/Wizard/components/ConfirmationCard'
import { DirectionFormV2 } from '#app/v2/pages/Wizard/components/DirectionFormv2'
import { SquareCard } from '#app/v2/pages/Wizard/components/SquareCard'
import { SectionDescription } from '#app/v2/pages/Wizard/components/Texts/SectionDescription'
import { SectionTitle } from '#app/v2/pages/Wizard/components/Texts/SectionTitle'
import { TwoRowCard } from '#app/v2/pages/Wizard/components/TwoRowCard'
import { WizardFlow, WizardOption } from '#app/v2/pages/Wizard/types'

import { CircularProgressWithLabel } from './CircularProgressWithLabel'

export interface ResponseSpecial {
  type: string
  text?: string
  action?: string
  data?: any
}

export interface Response {
  [question_key: string]: {
    question_text: string
    question_key: string
    // Adjusted to explicitly declare an object with string keys and string values
    response: { [key: string]: string } | string[] | string
    response_key: { [key: string]: string } | string[] | string
  }
}

interface Props {
  currentQuestion?: WizardFlow | null
  onOptionSelect?: (option: WizardOption) => void
  onNextStep?: () => void
  wizardFlow: WizardFlow[]
  wizardMetadata: Response
  wizardQuestionHistory: any
  wizardQuestionStack: any
  callbackComplete?: (data: any) => Promise<any>
  callbackSetProgress?: (step: number, data: Response, questionStackHistory: any, questionStack: any, status?: string, mustNavigate?: boolean) => Promise<any>
  setTitles: (data: any) => void
  setGoBack?: (bool: boolean) => void
  setIsSubQuestion?: (bool: boolean) => void
  setCurrentStep?: (str: string) => void
  goBack?: boolean
  isWizardCompleted?: boolean
  isDemo?: boolean
  textEnd1?: string
  textEnd2?: string
  currStep?: number | string
}

// Correctly wrapping the component with React.memo
const VideoPreviewPlayer = ({ videoUrl }: { videoUrl: string }) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const handleVideoClick = () => {
    setIsPlaying(true)
  }

  if (!videoUrl) {
    console.log('it is falsy')
    return null
  }
  console.log('it is not falsy')

  return (
    <div
      className='cursor-pointer'
      onClick={handleVideoClick}
    >
      <video
        className='mb-1 w-full overflow-hidden rounded-2xl'
        controls={true}
        autoPlay={isPlaying}
      >
        <source
          src={videoUrl}
          type='video/mp4'
        />
        Your browser does not support the video tag.
      </video>
    </div>
  )
}

export function FormFlowV2({ wizardFlow, callbackComplete, setTitles, goBack, setGoBack, setCurrentStep, callbackSetProgress, wizardMetadata, wizardQuestionHistory, wizardQuestionStack, isWizardCompleted = false, isDemo = false, textEnd1, textEnd2, currStep, setIsSubQuestion }: Props): JSX.Element {
  const [questionStack, setQuestionStack] = useState<WizardFlow[]>([...wizardFlow])
  const { pathname } = useLocation()
  const [summaryResponses, setSummaryResponses] = useImmer<Response>(wizardMetadata)
  const [showOptionConclusion, setShowOptionConclusion] = useState<string | null>(null)
  const [isCompleted, setIsCompleted] = useState<boolean>(isWizardCompleted)
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [progressUI, setProgressUI] = useState<number>(0)
  const [currentOption, setCurrentOption] = useState<WizardOption>()
  const [canProceed, setCanProceed] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const taskId = searchParams.get('taskId')
  const navigate = useNavigate()
  const [questionStackHistory, setQuestionStackHistory] = useState<WizardFlow[][]>(wizardQuestionHistory || [])
  const [step, setStep] = useState<number>(Number(currStep) ?? 1)

  const currentQuestion = questionStack[0]
  useEffect(() => {
    if (wizardQuestionStack?.length > 0 && !isCompleted)
      setQuestionStack([...wizardQuestionStack])
    else if (!isCompleted)
      setQuestionStack([...wizardFlow])
  }, [wizardFlow, wizardQuestionStack])

  useEffect(() => {
    if (!isCompleted)
      setTitles({
        title: currentQuestion?.title || '',
        subtitle: currentQuestion?.subtitle || ''
      })
    else
      setTitles({
        title: '',
        subtitle: ''
      })
  }, [currentQuestion, setTitles, isCompleted])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBack = (): void => {
    if (questionStackHistory.length > 0) {
      const newQuestionStackHistory = [...questionStackHistory]
      const lastQuestionStack = newQuestionStackHistory.pop()
      setQuestionStackHistory(newQuestionStackHistory)
      setQuestionStack(lastQuestionStack as WizardFlow[])
      if (step > 1 && !currentQuestion?.is_subquestion) {
        setStep((prevStep) => prevStep - 1)
      }

      if (step === 1 && currentQuestion.is_subquestion) {
        if (setIsSubQuestion)
          setIsSubQuestion(true)
      }
      else {
        if (setIsSubQuestion)
          setIsSubQuestion(false)
      }
    }
  }

  useEffect(() => {
    if (goBack) {
      handleBack()
      if (setGoBack)
        setGoBack(false)
    }
  }, [goBack, setGoBack])

  const handleOptionClick = async (option: WizardOption, optionResult: string) => {
    console.log('cliqueado')
    setCurrentOption(option)

    const updatedSummaryResponses = produce(summaryResponses, (draft) => {
      const isMultiple = currentQuestion?.is_multiple
      if (isMultiple) {
        // Handle multiple-choice questions
        const questionEntry = draft[currentQuestion.question_key]
        if (!questionEntry) {
          // Initialize with the current response if not present
          draft[currentQuestion.question_key] = {
            question_text: currentQuestion.question_text,
            question_key: currentQuestion.question_key,
            response: [optionResult],
            response_key: [option.option_key],
          }
        }
        else {
          // and optionResult and option.option_key are the values you're checking for

          // Check if the option is already selected
          let responseIndex = -1 // Default to -1, indicating not found
          let responseKeyIndex = -1 // Default to -1, indicating not found

          if (Array.isArray(questionEntry.response)) {
            responseIndex = questionEntry.response.indexOf(optionResult)
          }

          if (Array.isArray(questionEntry.response_key)) {
            responseKeyIndex = questionEntry.response_key.indexOf(option.option_key)
          }

          // Now you can safely use responseIndex and responseKeyIndex in your logic

          if (responseIndex > -1 && responseKeyIndex > -1) {
            // The option is already selected, so remove it (deselect)
            // @ts-ignore
            questionEntry.response.splice(responseIndex, 1)
            // @ts-ignore
            questionEntry.response_key.splice(responseKeyIndex, 1)
            if (!questionEntry.response.length) {
              delete draft[currentQuestion.question_key]
              setCanProceed(false)
            }
          }
          else {
            // The option is not selected, so add it
            // @ts-ignore
            questionEntry.response.push(optionResult)
            // @ts-ignore
            questionEntry.response_key.push(option.option_key)
          }
        }
      }
      else {
        // Handle single-choice questions
        if (draft[currentQuestion.question_key]?.response === optionResult) {
          // Deselect the option if it's already selected
          delete draft[currentQuestion.question_key]
          setCanProceed(false)
        }
        else {
          // Select the option
          draft[currentQuestion.question_key] = {
            question_text: currentQuestion.question_text,
            question_key: currentQuestion.question_key,
            response: optionResult,
            response_key: option.option_key,
          }
        }
      }
    })

    // Update the state with the newly created object
    setSummaryResponses(updatedSummaryResponses)
  }
  // Assuming summaryResponses is of type Response and is part of your component's state
  const handleOptionChange = async (option: WizardOption, inputIdentifier: string, value: string) => {
    const questionKey = currentQuestion.question_key // Ensure currentQuestion is defined and has a question_key

    const updatedSummaryResponses: Response = produce(summaryResponses, (draft) => {
      // Ensure the draft is treated as part of the Response structure
      if (!draft[questionKey]) {
        // Initialize if not present, with response and response_key as objects
        draft[questionKey] = {
          question_text: currentQuestion.question_text,
          question_key: questionKey,
          response: {}, // Initialize as an object
          response_key: {}, // Initialize as an object
        }
      }

      // Type checking to ensure we're working with the correct structure
      const currentResponse = draft[questionKey].response
      if (typeof currentResponse === 'object' && !(currentResponse instanceof Array)) {
        currentResponse[inputIdentifier] = value
      }
      else {
        // Handle unexpected types appropriately
      }

      const currentResponseKey = draft[questionKey].response_key
      if (typeof currentResponseKey === 'object' && !(currentResponseKey instanceof Array)) {
        currentResponseKey[inputIdentifier] = option.option_key
      }
      else {
        // Handle unexpected types appropriately
      }
    })

    // Update your state with updatedSummaryResponses
    setSummaryResponses(updatedSummaryResponses)
  }

  const saveAndContinueLater = async () => {
    if (callbackSetProgress && !isDemo) {
      await callbackSetProgress(step, summaryResponses, questionStackHistory, questionStack, undefined, true)
    }
  }

  const moveToNextQuestion = async (latesResponses: Response, newQuestionStackHistory: any, questionStack: any, cStep: number) => {
    if (questionStack.length === 0) {
      setIsCompleted(true)
      setLoading(true)
      setTitles({
        title: '',
        subtitle: ''
      })
      if (callbackSetProgress && !isDemo) {
        // setStep(step - 1)
        await callbackSetProgress(cStep, latesResponses, newQuestionStackHistory, questionStack, 'completed').then(() => {
          setLoading(false)
        })
      }
    }
    else if (callbackSetProgress && !isDemo) {
      await callbackSetProgress(cStep, latesResponses, newQuestionStackHistory, questionStack)
    }
  }

  useEffect(() => {
    if (setCurrentStep)
      setCurrentStep(`${step}`)
  }, [step])

  const isResponseProvided = (response: any) => {
    if (Array.isArray(response)) {
      // For an array, check if it's not empty
      return response.length > 0
    }
    else if (typeof response === 'object' && response !== null) {
      // For an object, check if any value is a non-empty string
      return Object.values(response).some((val) => typeof val === 'string' && val.trim() !== '')
    }
    else if (typeof response === 'string') {
      // For a string, check if it's not just whitespace
      return response.trim() !== ''
    }
    return false
  }

  useEffect(() => {
    const currentQuestionKey = currentQuestion?.question_key

    // Directly accessing the response for the current question
    const response = summaryResponses[currentQuestionKey]?.response

    // Use isResponseProvided to determine if the user has provided any response
    const hasResponse = isResponseProvided(response)

    setCanProceed(hasResponse)
  }, [currentQuestion, summaryResponses])

  const handleNext = async () => {
    let cStep = step
    setCurrentOption(undefined)
    const newQuestionStackHistory = [...questionStackHistory, questionStack]
    setQuestionStackHistory(newQuestionStackHistory)
    let updatedQuestionStack
    if (currentOption?.subquestions && currentOption.subquestions.length > 0) {
      updatedQuestionStack = [...currentOption.subquestions, ...questionStack.slice(1)]
      setQuestionStack(updatedQuestionStack)
      if (setIsSubQuestion) {
        setIsSubQuestion(true)
      }
    }
    else {
      updatedQuestionStack = questionStack.slice(1)
      if (updatedQuestionStack.length > 0 && !updatedQuestionStack[0].is_subquestion) {
        setStep(step + 1)
        cStep += 1
        if (setIsSubQuestion) {
          setIsSubQuestion(true)
        }
      }
      else {
        if (setIsSubQuestion) {
          setIsSubQuestion(true)
        }
      }

      setQuestionStack(updatedQuestionStack)
    }
    console.log('Step CSTEP', cStep)

    await moveToNextQuestion(summaryResponses, newQuestionStackHistory, updatedQuestionStack, cStep)
  }

  return (
    <div>
      {!isCompleted && (
        <>
          {
            showOptionConclusion
            && (
              <Stack
                spacing={3}
                mt={1}
              >
                <Typography variant='h4'>{showOptionConclusion}</Typography>
              </Stack>
            )
          }
          {!showOptionConclusion
            ? (
              <>
                <div className='flex flex-col gap-4 @container'>
                  {currentQuestion?.step_video && (
                    <VideoPreviewPlayer
                      key={currentQuestion.step_video}
                      videoUrl={currentQuestion?.step_video ?? ''}
                    />
                  )}
                  <RadioGroup className={currentQuestion?.isLine ? '' : 'grid grid-cols-3 gap-3 @sm:grid-cols-4 sm:grid-cols-5'}>
                    {currentQuestion?.options.map((option) => {
                      const currentQuestionKey = currentQuestion?.question_key
                      const responseKeys = currentQuestionKey ? summaryResponses[currentQuestionKey]?.response_key : undefined

                      // Determine if the current option is checked
                      const isChecked = Array.isArray(responseKeys)
                        ? responseKeys.includes(option?.option_key)
                        : option?.option_key === responseKeys

                      if (option?.option_type === 'TwoRowCard') {
                        return (
                          <TwoRowCard
                            key={option?.option_key}
                            option={option}
                            other={option?.option_attributes.other}
                            title={option?.option_attributes?.option_text}
                            description={option?.option_attributes?.description}
                            colors={option.option_attributes.colors}
                            isChecked={isChecked}
                            onClick={async () => await handleOptionClick(option, option.option_key)}
                          />
                        )
                      }
                      else if (option?.option_type === 'SquareCard') {
                        return (
                          <SquareCard
                            key={option?.option_key}
                            isChecked={isChecked}
                            isCheckbox={currentQuestion?.is_multiple}
                            id={option?.option_key}
                            label={option?.option_attributes?.option_text}
                            imageUrl={option?.option_attributes?.image_url}
                            onClick={async () => await handleOptionClick(option, option.option_key)}
                          />
                        )
                      }
                      else if (option?.option_type === 'ConfirmationCard') {
                        return (
                          <ConfirmationCard
                            key={option?.option_key}
                            label={option?.option_attributes?.option_text}
                            isChecked={isChecked}
                            examples={option?.option_attributes?.image_url}
                            onClick={async () => await handleOptionClick(option, option.option_key)}
                          />
                        )
                      }
                    })}
                  </RadioGroup>
                  {currentQuestion?.isForm && (
                    <DirectionFormV2
                      options={currentQuestion?.options}
                      title={currentQuestion.question_text}
                      summaryResponses={summaryResponses[currentQuestion?.question_key]}
                      onChange={handleOptionChange}
                    />
                  )}
                  <Actions
                    canProceed={canProceed ? true : false}
                    onNextStep={handleNext}
                    onSaveAndContinue={saveAndContinueLater}
                  />
                </div>
              </>
            )
            : (
              <>
                <Typography
                  variant='h5'
                  mb={6}
                >
                  {/* @ts-ignore */}
                  {(summaryResponses && currentQuestion) ? currentQuestion.options[summaryResponses?.[currentQuestion?.title]]?.response : ''}
                </Typography>
                <CircularProgressWithLabel value={progressUI} />
              </>
            )}
        </>
      )}
      {isCompleted && (
        <div
          className='flex flex-col items-center'
        >
          {
            loading
              ? <LoadingGrid />
              : <img src={confetti} />
          }
          {!loading
            ? (
              <div className='mt-8 space-y-4'>
                <SectionTitle text={textEnd1 ?? ''} />
                <SectionDescription text={textEnd2} />
              </div>
            )
            : (
              <div className='mt-8 space-y-4'>
                <Typography variant='h3'>Un momento por favor</Typography>
                <Typography> Estamos analizando tus respuestas... </Typography>
              </div>
            )}
          <div className=' mx-auto mb-14 mt-10 w-[60%] max-w-xs space-y-6'>
            <Button
              className=' w-full py-3.5 font-bold'
              onClick={() => {
                if (taskId) {
                  navigate(`/v2/task-detail/${taskId}`, {
                    state: {
                      from: pathname,
                      to: `/v2/task-detail/${taskId}`
                    }
                  })
                }
                else {
                  navigate('/v2/home', {
                    state: {
                      from: pathname,
                      to: '/v2/home'
                    }
                  })
                }
              }}
            >
              {t('v2.wizard.actions.primary', 'Finish')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
