import { AccordionContent } from '@radix-ui/react-accordion'

import { RouteOwners } from '#app/routes/useRoutes'
import { Accordion,
  AccordionItem,
  AccordionTrigger } from '#app/v2/components/ui/accordion'
import { ActivityCenterStory } from '#app/v2/pages/ActivityCenter/story'
import { BlankScreen } from '#app/v2/pages/Blank/page'
import { StoryEditProfile } from '#app/v2/pages/EditProfile/story'
import { StoryHome } from '#app/v2/pages/Home/story'
import { StoryInvoice } from '#app/v2/pages/Invoice/story'
import { StoryLoadingScreen } from '#app/v2/pages/LoadingScreen/story'
import { StoryMyProfile } from '#app/v2/pages/MyProfile/story'
import { Story404NotFound } from '#app/v2/pages/NotFound/story'
import { StoryPayment } from '#app/v2/pages/Payments/story'
import { StorySearchBubble } from '#app/v2/pages/Search/story'
import { StoryTasksCompletedSuccess } from '#app/v2/pages/TaskCompleted/story'
import { StoryTaskDetail } from '#app/v2/pages/TaskDetail/story'
import { StoryTaskList } from '#app/v2/pages/TaskList/story'
import { StoryWizardComponents } from '#app/v2/pages/Wizard/story'
import { StoryWizardContainer } from '#app/v2/pages/Wizard/story-container'
import { StoryWizardSuccessCampaing } from '#app/v2/pages/Wizard/story-success'

// I don't think its neccesary global.css because at /v2-ui/index shadcn + tailwind are already loaded due to <Badge /> component.
// Delete it if it doesn't make sense
import '#app/v2/global.css'

// import { RouterProvider, createBrowserRouter } from "react-router-dom"
// import { NotFoundPage } from "#app/routes/NotFoundPage"

export interface RouteV2ShowCase {
  path: string
  title: string
  element: JSX.Element
  status: 'Backlog' | 'In progress' | 'UX Review' | 'Done'
  description: string
  subvariants: {
    path: string
    description: string
    previewImg?: string
  }[]
}

const prefix = '/v2-ui'
const routes: RouteV2ShowCase[] = [
  {
    path: `${prefix}/home`,
    title: 'Home',
    status: 'In progress',
    description: 'Home origin page where the journey of the BO takes place.',
    subvariants: [
      {
        path: `${prefix}/home`,
        description: 'Default',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FHome%2Flocalhost_4000_v2-ui_home(iPhone%2014%20Pro%20Max).png?alt=media&token=53038340-aa17-43dc-9d0a-1857273cfec6'
      },
      {
        path: `${prefix}/home?scenario=fresh_account`,
        description: 'New / empty / fresh account',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FHome%2Flocalhost_4000_v2-ui_home_scenario%3Dempty_account(iPhone%2014%20Pro%20Max).png?alt=media&token=ee7adff1-d93f-4e86-969b-83a2931376fb'
      },
      {
        path: `${prefix}/home?scenario=no_messages`,
        description: 'No messages / chats',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FHome%2Flocalhost_4000_v2-ui_home_scenario%3Dno_messages(iPhone%2014%20Pro%20Max).png?alt=media&token=8521be42-a090-4a75-abd4-2a0ad1bd8281'
      },
      {
        path: `${prefix}/home?scenario=no_notifications`,
        description: '0 Notifications',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FHome%2Flocalhost_4000_v2-ui_home_scenario%3Dno_notifications(iPhone%2014%20Pro%20Max).png?alt=media&token=03d6c06e-45fb-4afd-9883-824e6ca93027'
      },
      {
        path: `${prefix}/home?scenario=todos_done`,
        description: 'All Tasks up to date',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FHome%2Flocalhost_4000_v2-ui_home_scenario%3Dtodos_done(iPhone%2014%20Pro%20Max).png?alt=media&token=0b3b0155-55e5-4f71-aace-ae8382522733'
      },
    ],
    element: <StoryHome />,
  },
  {
    path: `${prefix}/tasks`,
    title: 'Task List',
    status: 'In progress',
    description: 'Page with all the to-do and completed tasks that the BO has in 2 views.',
    subvariants: [
      {
        path: `${prefix}/tasks`,
        description: 'Default',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FTask%20List%2Flocalhost_4000_v2-ui_tasks(iPhone%2014%20Pro%20Max).png?alt=media&token=65f9f359-73b4-47ed-8085-d8c31147ab1e'
      },
      {
        path: `${prefix}/tasks?scenario=completed_todos`,
        description: 'Completed todo tasks.',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FTask%20List%2Flocalhost_4000_v2-ui_tasks_scenario%3Dcompleted_todos(iPhone%2014%20Pro%20Max).png?alt=media&token=bb7bfede-a5ec-4128-89e8-1a548eedda2f'
      },
      {
        path: `${prefix}/tasks?scenario=empty_done_tasks`,
        description: 'Empty done tasks.',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FTask%20List%2Flocalhost_4000_v2-ui_tasks_scenario%3Dno_done.png?alt=media&token=ae2dbc3c-c13e-4f73-8f18-c6e58c24f414'
      },
    ],
    element: <StoryTaskList />,
  },
  {
    path: `${prefix}/tasksSuccess`,
    title: 'Task Success',
    status: 'In progress',
    description: 'Page  when the BO have completed all the remaining tasks and is up-to-date.',
    subvariants: [
      {
        path: `${prefix}/tasksSuccess`,
        description: 'Default',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FTask%20List%2Flocalhost_4000_v2-ui_tasksSuccess(iPhone%2014%20Pro%20Max).png?alt=media&token=4f817162-beb4-4bb5-8429-65833173e2a3'
      }
    ],
    element: <StoryTasksCompletedSuccess />,
  },
  {
    path: `${prefix}/task-detail/:task-id`,
    title: 'Task Detail',
    status: 'In progress',
    description: 'A dedicated page with self-explanotary information of an individual task where the BO can chat, interact with his/her coach, view and upload files and complete the activity. This will generally being from a template and be connected to the wizard so a BO can resume the task and complete needed information whenerver they want.',
    subvariants: [
      {
        path: `${prefix}/task-detail/:task-id`,
        description: 'Default',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FTask%20Detail%2Flocalhost_4000_v2-ui_task-detail__task-id(iPhone%2014%20Pro%20Max).png?alt=media&token=1e8c6d93-b0ce-4207-b4ad-8a1f6abf67d3'
      },
      {
        path: `${prefix}/task-detail/:task-id?scenario=linear_markdown`,
        description: 'Example of how Linear Markdown looks rendered',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FTask%20Detail%2Flocalhost_4000_v2-ui_task-detail__task-id_scenario%3Dlinear_markdown(iPhone%2014%20Pro%20Max).png?alt=media&token=1f3f5954-1e09-44c7-93fc-becc713beaf0'
      },
      {
        path: `${prefix}/task-detail/:task-id?scenario=completed_task`,
        description: 'Completed Task by BO without rating',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FTask%20Detail%2Flocalhost_4000_v2-ui_task-detail__task-id_scenario%3Dcompleted_task(iPhone%2014%20Pro%20Max).png?alt=media&token=a2908941-51fc-405b-ab58-9df33017c839'
      },
      {
        path: `${prefix}/task-detail/:task-id?scenario=completed_task_with_rating`,
        description: 'Completed Task by BO with rating',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FTask%20Detail%2Flocalhost_4000_v2-ui_task-detail__task-id_scenario%3Dcompleted_task_with_rating(iPhone%2014%20Pro%20Max).png?alt=media&token=95a92a99-8f14-4ab0-9939-4c9f73492343'
      },
      {
        path: `${prefix}/task-detail/:task-id?scenario=admin_completed_task`,
        description: 'Completed Task by Admin',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FTask%20Detail%2Flocalhost_4000_v2-ui_task-detail__task-id_scenario%3Dadmin_completed_task(iPhone%2014%20Pro%20Max).png?alt=media&token=534963f9-527c-4d16-9bc7-4dabb235f7c4'
      },
      {
        path: `${prefix}/task-detail/:task-id?scenario=empty_feed`,
        description: 'Empty feed/no activity',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FTask%20Detail%2Flocalhost_4000_v2-ui_task-detail__task-id_scenario%3Dempty_feed(iPhone%2014%20Pro%20Max).png?alt=media&token=e69c96fe-60b5-4553-b3b7-609ab397b0f9'
      },
      {
        path: `${prefix}/task-detail/:task-id?scenario=loading`,
        description: 'Loading',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FTask%20Detail%2FScreen%20Cast%202024-01-09%20at%207.33.43%20PM.gif?alt=media&token=a34626a6-fbf8-40e8-8fa5-db267ed6b541'
      },
    ],
    element: <StoryTaskDetail />,
  },
  {
    path: `${prefix}/messages`,
    title: 'Message',
    status: 'Backlog',
    description: 'Inbox panel where messages are located.',
    subvariants: [
      {
        path: `${prefix}/messages`,
        description: 'Default'
      }
    ],
    element: <BlankScreen />,
  },
  /*
  {
    path: `${prefix}/messages/:chat-id`,
    status: 'Backlog',
    description: 'Individual and specific chat',
    element: <BlankScreen />
  },
  {
    path: `${prefix}/messages/:group-chat-id`,
    status: 'Backlog',
    description: 'Group chat',
    element: <BlankScreen />
  },
  {
    path: `${prefix}/groups`,
    status: 'Backlog',
    description: 'Page where groups are located.',
    element: <BlankScreen />
  },
  */
  {
    path: `${prefix}/edit-profile`,
    title: 'Edit Profile',
    status: 'In progress',
    description: 'Page to setup or update personal information like the name, email adress and even add other phone numbers.',
    subvariants: [
      {
        path: `${prefix}/edit-profile`,
        description: 'Default',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FEdit%20Profile%2Flocalhost_4000_v2-ui_edit-profile(iPhone%2014%20Pro%20Max).png?alt=media&token=f4e67fc9-4689-48e5-b996-1ee4e94a534b'
      },
      {
        path: `${prefix}/edit-profile?scenario=unsaved_changes`,
        description: 'Unsaved changes',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FEdit%20Profile%2Flocalhost_4000_v2-ui_edit-profile_scenario%3Dunsaved_changes(iPhone%2014%20Pro%20Max).png?alt=media&token=b528e8c2-3b37-4f24-9857-ab4ad48799f0'
      },
      {
        path: `${prefix}/edit-profile?scenario=no_picture`,
        description: 'No picture',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FEdit%20Profile%2Flocalhost_4000_v2-ui_edit-profile_scenario%3Dno_picture(iPhone%2014%20Pro%20Max).png?alt=media&token=db003408-d0e8-43dd-aefa-052061a79c7b'
      },
      {
        path: `${prefix}/edit-profile?scenario=no_services`,
        description: 'No services',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FEdit%20Profile%2Flocalhost_4000_v2-ui_edit-profile_scenario%3Dno_services(iPhone%2014%20Pro%20Max).png?alt=media&token=92e3ec33-cfa8-4d5e-891b-2c0e8a5106c8'
      },
    ],
    element: <StoryEditProfile />,
  },
  {
    path: `${prefix}/profile`,
    title: 'My Profile',
    status: 'In progress',
    description: 'Main access hamburger for BO to look up different shortcuts and access most sections.',
    subvariants: [
      {
        path: `${prefix}/profile`,
        description: 'Default',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FMy%20Profile%2Flocalhost_4000_v2-ui_profile(iPhone%2014%20Pro%20Max).png?alt=media&token=65a383e6-1fd4-483c-bd6f-7739bfb0e54c',
      },
      {
        path: `${prefix}/profile?scenario=modal`,
        description: 'Modal',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FMy%20Profile%2Flocalhost_4000_v2-ui_profile_scenario%3Dmodal(iPhone%2014%20Pro%20Max).png?alt=media&token=40478eaa-678b-4f7f-8689-8ddaf01d9061',
      },
      {
        path: `${prefix}/profile?scenario=mvp_shortcuts`,
        description: 'MVP Shortcuts',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FMy%20Profile%2Flocalhost_4000_v2-ui_profile_scenario%3Dmvp_shortcuts(iPhone%2014%20Pro%20Max).png?alt=media&token=be68d6ca-a9c7-44d6-995d-ef4c3bc52f9f',
      },
      {
        path: `${prefix}/profile?scenario=long_name`,
        description: 'BO with long name',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FMy%20Profile%2Flocalhost_4000_v2-ui_profile_scenario%3Dlong_name(iPhone%2014%20Pro%20Max).png?alt=media&token=33229dc9-218c-40ee-b0d5-42350cdd4028',
      },
    ],
    element: <StoryMyProfile />,
  },
  /*
  {
    path: `${prefix}/add-client`,
    status: 'Backlog',
    description: 'Page from shurtcut button in Feed to add new client.',
    element: <BlankScreen />
  },
  {
    path: `${prefix}/create-estimate`,
    status: 'Backlog',
    description: 'Page from shurtcut button in Feed to add create estimate.',
    element: <BlankScreen />
  },
  {
    path: `${prefix}/req-payment`,
    status: 'Backlog',
    description: 'Page from shurtcut button in Feed to request a payment from a client.',
    element: <BlankScreen />
  },
  {
    path: `${prefix}/req-review`,
    status: 'Backlog',
    description: 'Page from shurtcut button in Feed to request a review from a client for the business.',
    element: <BlankScreen />
  },
  */
  {
    path: `${prefix}/activity-center`,
    title: 'Activity Center',
    status: 'In progress',
    description: 'Activity center where notification will group up to notify things that the back-office people are doing for them and general CRM useful information.',
    subvariants: [
      {
        path: `${prefix}/activity-center`,
        description: 'Default',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FActivity%20Center%2Flocalhost_4000_v2-ui_activity-center(iPhone%2014%20Pro%20Max).png?alt=media&token=765771bb-4949-4656-ad36-3c9e2c60c978'
      },
      {
        path: `${prefix}/activity-center?scenario=no_notifications`,
        description: 'No notifications',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FActivity%20Center%2Flocalhost_4000_v2-ui_activity-center_scenario%3Dno_notifications(iPhone%2014%20Pro%20Max).png?alt=media&token=24cdb6c1-0239-4cb8-ad8f-183e38d18054'
      },
    ],
    element: <ActivityCenterStory />,
  },
  {
    path: `${prefix}/payments`,
    title: 'Payments - Banking',
    status: 'In progress',
    description: 'Banking, invoices and everything related to payment UI.',
    subvariants: [
      {
        path: `${prefix}/payments`,
        description: 'Generate payment link default.',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FPayments%2Flocalhost_4000_v2-ui_payments(iPhone%2014%20Pro%20Max).png?alt=media&token=0067ea5c-a26b-4711-9453-963526ff96d3'
      },
    ],
    element: <StoryPayment />,
  },
  /*
  {
    path: `${prefix}/business`,
    status: 'Backlog',
    description: 'My business data and transactions.',
    element: <BlankScreen />
  },
  */
  {
    path: `${prefix}/wizard-container`,
    title: 'Wizard Container',
    status: 'In progress',
    description: 'List of the differents stages/steps a BO can encounter when doing a task with different forms and inputs.',
    subvariants: [
      {
        path: `${prefix}/wizard-container`,
        description: 'WIP (1 step)',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FWizard%2Flocalhost_4000_v2-ui_wizard-container(iPhone%2014%20Pro%20Max).png?alt=media&token=1f01a9b3-fa17-49b5-89e2-36f203250b68'
      }
    ],
    element: <StoryWizardContainer />
  },
  {
    path: `${prefix}/wizard-components`,
    title: 'Wizard Components',
    status: 'In progress',
    description: 'Wizard components to re-use in the differents process and steps involved.',
    subvariants: [
      {
        path: `${prefix}/wizard-components`,
        description: 'Internal showroom of wizard components',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FWizard%2Flocalhost_4000_v2-ui_wizard-components(iPhone%2014%20Pro%20Max).png?alt=media&token=0433810c-3672-4691-862d-44e45f819930'
      }
    ],
    element: <StoryWizardComponents />
  },
  {
    path: `${prefix}/invoice`,
    title: 'Invoice',
    status: 'In progress',
    description: 'Page that you are being redirect when the BO generates a Payment link or wants to visualize a invoice.',
    subvariants: [
      {
        path: `${prefix}/invoice`,
        description: 'Default',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FInvoice%2Flocalhost_4000_v2-ui_invoice(iPhone%2014%20Pro%20Max).png?alt=media&token=eddb5c4c-64c2-479c-9ef4-e0c5f324ffc5'
      },
      {
        path: `${prefix}/invoice?scenario=no_tips`,
        description: 'No tips allowed',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FInvoice%2Flocalhost_4000_v2-ui_invoice_scenario%3Dno_tips(iPhone%2014%20Pro%20Max).png?alt=media&token=e56a1f42-bb42-4f4a-82f0-ce51f3ecc307'
      }
    ],
    element: <StoryInvoice />
  },
  {
    path: `${prefix}/success-campaing`,
    title: 'Campaing Success',
    status: 'In progress',
    description: 'Page that you are being redirect when the BO completes a campaign.',
    subvariants: [
      {
        path: `${prefix}/success-campaing`,
        description: 'Default',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FOther%2Flocalhost_4000_v2-ui_success-campaing(iPhone%2014%20Pro%20Max).png?alt=media&token=52a787c4-6d64-4991-a74e-da80ea7633e2'
      }
    ],
    element: <StoryWizardSuccessCampaing />
  },
  {
    path: `${prefix}/search`,
    title: 'Bubble Search',
    status: 'In progress',
    description: 'Zip code search engine and business search engine with an Iframe that points to our external bubble app',
    subvariants: [
      {
        path: `${prefix}/search`,
        description: 'Default',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FSearch%2Flocalhost_4000_v2-ui_search(iPhone%2014%20Pro%20Max).png?alt=media&token=a581ef9e-9b9a-487e-8e6e-8c0c4970f60f'
      }
    ],
    element: <StorySearchBubble />
  },
  {
    path: `${prefix}/loading`,
    title: 'Loading Screen',
    status: 'In progress',
    description: 'Loading Screen when the login takes place.',
    subvariants: [
      {
        description: 'Default',
        path: `${prefix}/loading`,
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FScreen%20Cast%202024-01-09%20at%2010.27.15%20AM.gif?alt=media&token=4da1aac9-24b7-4d6a-aa17-26b6cd3ab9d6'
      },
      {
        description: 'Error',
        path: `${prefix}/loading?scenario=error`,
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2Flocalhost_4000_v2-ui_loading_scenario%3Derror(iPhone%2014%20Pro%20Max).png?alt=media&token=00a501d9-3be5-4740-a6ae-75d7c427347b'
      },
    ],
    element: <StoryLoadingScreen />,
  },
  {
    path: `${prefix}/*`,
    title: '404 Not Found',
    status: 'In progress',
    description: '404 Page whenever the user is being into a faulty url without access.',
    subvariants: [
      {
        path: `${prefix}/*`,
        description: 'Default',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FOther%2Flocalhost_4000_v2-ui_wrong-url(iPhone%2014%20Pro%20Max).png?alt=media&token=3aece12c-27c0-4e20-badf-4cc427296a44'
      },
      {
        path: `${prefix}/not-found`,
        description: 'Redirected',
        previewImg: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FStorybook%2FOther%2Flocalhost_4000_v2-ui_not-found(iPhone%2014%20Pro%20Max).png?alt=media&token=3d82c6f9-c608-4013-93e7-85404e625886'
      },
    ],
    element: <Story404NotFound />,
  },
]
export const routesUI: RouteOwners[] = [
  {
    path: `${prefix}/index`,
    element:
  <Accordion
    type='multiple'
    className='bg-slate-100 text-slate-900'
  >
    {routes.map((val) => (
      <AccordionItem
        key={val.path}
        value={val.title}
      >
        <AccordionTrigger className='px-4'>
          {val.title}
          {' '}
          {`(${val.subvariants?.length})`}
        </AccordionTrigger>
        <AccordionContent className='snap-x p-4'>
          <p className=' max-w-3xl italic'>
            {val.description}
          </p>
          <div className='flex snap-x snap-mandatory items-start gap-4 overflow-x-auto sm:gap-6 md:gap-10 lg:gap-16'>
            <div className='h-full w-1/4 shrink-0 snap-center lg:w-1/12'>
            </div>
            {val.subvariants?.map(({ path, description, previewImg }) => (
              <a
                key={description + path}
                target='_blank'
                href={path}
                className='group m-2  w-1/2 max-w-xs shrink-0 snap-center overflow-hidden rounded-xl border border-transparent bg-white text-center shadow transition-colors hover:border-[#6BE7BE] sm:w-1/3 lg:w-1/4'
                rel='noreferrer'
              >
                <div className='overflow-hidden rounded-t-xl '>
                  <img
                    className='grayscale-[75%] transition-all hover:scale-110 group-hover:grayscale-0'
                    src={previewImg}
                    alt={`UI Preview of ${description}`}
                  />

                </div>
                <p className='break-words p-4 font-medium text-[#304985] transition-colors group-hover:animate-bounce'>{description}</p>
              </a>
            )
            )}
            <div className='h-full w-1/4 shrink-0 snap-center lg:w-1/12'>
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    ))}
  </Accordion>,
  },
  ...routes.flatMap((route) =>
    route.subvariants?.map((subvariant) => ({
      element: route.element,
      path: subvariant.path,
    })) || []
  ),
]

// export const V2Router = () => {
//   const { routes } = useRoutesUIOwner()
//   const router = createBrowserRouter(routes)
//   return <RouterProvider router={router} />
// }
