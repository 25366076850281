import { useState } from 'react'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'

import { DividerExtended } from '#app/components/Divider'
import { COLORS } from '#app/layouts/theme'

import { SectionProps } from '../../types'

import { NunjucksEditor } from './Variables/NunjucksEditor'
import { HeaderSection } from './HeaderSection'
import { ManualEdit } from './ManualEdit'

export function SectionEditor(props: SectionProps) {
  const { sectionId, htmlCode, lexorank, nunjucksVars, isContainer } = props
  const [manualEdit, setManualEdit] = useState(false)

  return (
    <Box
      sx={{
        backgroundColor: isContainer ? '#fffeea' : null,
        padding: 2
      }}
    >

      <HeaderSection {...props} />
      <DividerExtended />
      <Grid
        container
        gap={1}
      >
        <Box width='100%'>
          <FormGroup>
            <FormControlLabel
              control={(
                <Switch onChange={() => { setManualEdit(!manualEdit) }} />
              )}
              label={!manualEdit ? 'Manual Edit' : 'Hide Manual Edit'}
              sx={{
                '.MuiFormControlLabel-label': {
                  minWidth: '5ch',
                  color: COLORS.dark.primary,
                  fontWeight: '600',
                },
                marginRight: '0px'
              }}
            />
          </FormGroup>

        </Box>
        { manualEdit
        && (
          <ManualEdit
            htmlCode={htmlCode}
            sectionId={sectionId}
            lexorank={lexorank}
          />
        )}
        <NunjucksEditor
          sectionId={sectionId}
          nunjucksVars={nunjucksVars}
        />
      </Grid>
      <DividerExtended
        sx={{
          height: 2,
          backgroundColor: 'black'
        }}
      />
    </Box>
  )
}
