import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsChevronDown } from 'react-icons/bs'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLocation } from 'react-router-dom'
import { QueryResult } from '@apollo/client'
import isToday from 'date-fns/isToday'
import isYesterday from 'date-fns/isYesterday'
import { FieldArray, Form, Formik } from 'formik'

import { GetOldActivitiesQuery, GetOldActivitiesQueryVariables } from '#app/operations/activitycenter/activitycenter.queries.generated'
import { Activities } from '#app/operations/autogenerate/schemas'
import Images from '#app/v2/assets/Images'
import { PageWrapper } from '#app/v2/components/PageWrapper'
import { Button } from '#app/v2/components/ui/button'
import { Checkbox } from '#app/v2/components/ui/checkbox'
import { Drawer, DrawerClose, DrawerContent, DrawerHeader, DrawerTrigger } from '#app/v2/components/ui/drawer'
import { cn } from '#app/v2/libs/utils'
import { getLastWeek } from '#app/v2-core/utils/dates'

import { NotificationCard } from './components/NotificationCard'
import { Filters } from './types'
interface Props {
  currentFilter: Filters[]
  filters: Filters[]
  notifications: Partial<Activities>[]
  onFilterChange: (filter: string[]) => void
  fetchMore: (currentBatch: number) =>
  Promise<QueryResult<GetOldActivitiesQuery, GetOldActivitiesQueryVariables>>
  batchSize: number
  unreadMessages?: number
}

export const ActivityCenter = ({
  filters,
  notifications,
  currentFilter: filter,
  onFilterChange,
  fetchMore,
  unreadMessages = 0,
  batchSize,
}: Props) => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const [currentBatch, setCurrentBatch] = useState(batchSize)
  const [paginatedNotifications, setPaginatedNotifications] = useState<Partial<Activities>[]>([])

  const fetchMoreData = () => {
    setCurrentBatch((prev) => prev + batchSize)
  }

  useEffect(() => {
    fetchMore(currentBatch).then((res) => {
      setPaginatedNotifications((prev) => Array.from(new Set([...prev, ...res.data?.olderActivities ?? []])))
    })
  }, [currentBatch])

  const filterText = useMemo(() => {
    const key = filter.includes('all')
      ? 'activityCenter.filters'
      : filter.length === 1
        ? `activityCenter.categories.${filter[0]}`
        : 'activityCenter.filters'
    return t(key)
  }, [filter, t])

  // group notifications by: unread, today, yesterday, 7 days ago, older

  const groupedNotifications = useMemo(() => {
    return notifications.filter((activity, index, self) => {
      if (activity.type === 'unreadMessage') {
        // Only include the first 'unreadMessage' activity if unreadMessages > 0
        return (
          self.findIndex((a) => a.type === 'unreadMessage') === index
          && unreadMessages > 0
        )
      }
      // Include all other types of activities
      return true
    }).reduce((acc, activity) => {
      const date = new Date(activity.created_at)

      if (activity.read === false) {
        acc.unread.push(activity)
        return acc
      }

      function isWithinLast7Days(date: Date) {
        return date >= new Date(getLastWeek())
      }

      if (isToday(date)) {
        acc.today.push(activity)
      }
      else if (isYesterday(date)) {
        acc.yesterday.push(activity)
      }
      else if (isWithinLast7Days(date)) {
        acc.week.push(activity)
      }

      return acc
    }, {
      unread: [],
      today: [],
      yesterday: [],
      week: [],
    } as Record<'unread' | 'today' | 'yesterday' | 'week', Partial<Activities>[]>)
  }, [notifications.length, unreadMessages])

  const GROUPS = useMemo(() => [
    {
      title: t('activityCenter.groups.new'),
      activities: groupedNotifications.unread,
    },
    {
      title: t('activityCenter.groups.today'),
      activities: groupedNotifications.today,
    },
    {
      title: t('activityCenter.groups.yesterday'),
      activities: groupedNotifications.yesterday,
    },
    {
      title: t('activityCenter.groups.lastWeek'),
      activities: groupedNotifications.week,
    },
  ], [groupedNotifications, t])

  return (
    <PageWrapper pathname='/v2/activity-center'>
      <Drawer>
        <DrawerTrigger asChild>
          <div className='mt-6 flex w-full justify-center'>
            <Button
              variant={filter.includes('all') ? 'outline' : 'default'}
              className={cn('min-w-[96px] gap-2 rounded-xl border border-[#304985] p-1 text-xs/5 font-medium', {
                'text-[#304985]': filter.includes('all'),
                'text-white': !filter.includes('all'),
              },
              )}
            >
              {filterText}
              {' '}
              {filter.length > 1 && `(${filter.length})`}
              <BsChevronDown className='h-3 w-3 stroke-[1.5]' />

            </Button>
          </div>
        </DrawerTrigger>
        <DrawerContent className=''>
          <Formik
            initialValues={{
              tempFilters: filter
            }}
            onSubmit={(values) =>
              onFilterChange(values.tempFilters)}
          >
            {(props) => {
              const { values, initialValues, setFieldValue, handleSubmit } = props
              return (
                <Form>
                  <DrawerHeader className='flex items-center justify-between border-b border-[#304985]/35 px-7 text-[#304985]'>
                    <span className='font-semibold'>{t('activityCenter.filter', 'Filter')}</span>
                    <DrawerClose asChild>
                      <Button
                        variant='ghost'
                        type='button'
                        className={cn('', {
                          'cursor-not-allowed select-none opacity-50 ': values.tempFilters.includes('all')
                        })}
                        onClick={() => {
                          setFieldValue('tempFilters', ['all'])
                          handleSubmit()
                        }}
                      >
                        {t('activityCenter.ctas.clearAll', 'Clear all')}
                      </Button>
                    </DrawerClose>

                  </DrawerHeader>
                  <div
                    className='space-y-3 px-7 py-5 font-medium text-[#454545] '
                  >
                    <FieldArray
                      name='tempFilters'
                      render={(arrayHelpers) => (
                        <>
                          {filters.map((filter) => (
                            <div
                              key={filter}
                            >
                              <label
                                htmlFor={`${filter}-checkbox`}
                                className={cn('flex items-center justify-between gap-2', {
                                  hidden: filter === 'all'
                                })}
                              >
                                <span>
                                  {t(`activityCenter.categories.${filter}`)}
                                </span>
                                <Checkbox
                                  variant='outline'
                                  name='tempFilters'
                                  id={`${filter}-checkbox`}
                                  value={filter}
                                  checked={values.tempFilters.includes(filter)}
                                  onCheckedChange={(checked) => {
                                    const allFilterIdx = values.tempFilters.indexOf('all')
                                    if (checked) {
                                      if (allFilterIdx !== -1) {
                                        arrayHelpers.remove(allFilterIdx)
                                      }
                                      arrayHelpers.push(filter)
                                    }
                                    else {
                                      const idx = values.tempFilters.indexOf(filter)
                                      arrayHelpers.remove(idx)
                                      if (values.tempFilters.length === 1) {
                                        arrayHelpers.push('all')
                                      }
                                    }
                                  }}
                                />
                              </label>
                            </div>
                          ))}
                        </>
                      )}
                    />
                    <DrawerClose
                      asChild
                      type='submit'
                    >
                      <Button
                        disabled={values === initialValues}
                        className='w-full'
                      >
                        {t('activityCenter.ctas.apply', 'Apply')}
                      </Button>
                    </DrawerClose>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </DrawerContent>
      </Drawer>

      {!notifications.length && !paginatedNotifications.length && (
        <div className='mt-16 flex flex-col items-center gap-6 text-center '>
          <img
            src={Images.NotificationBell}
            alt='Notification bell'
            className=' mix-blend-multiply'
          />
          <h2 className='text-xl font-semibold text-[#304985]'>{t('activityCenter.noActivity.title', 'No notifications found.')}</h2>
          <p className=' max-w-[28ch] font-medium text-[#454545]'>{filter.includes('all') ? t('activityCenter.noActivity.description.unfiltered', 'You don\'t have any notifications yet.') : t('activityCenter.noActivity.description.filtered', 'You don\'t have any notifications for this topic yet.') }</p>
        </div>
      )}
      <div
        id='scrollable'
        className='mt-3 flex grow flex-col overflow-auto px-6 pb-20'
      >

        {
          GROUPS.map((group) => (
            group.activities?.length
              ? (
                <div
                  key={group.title}
                  className='my-3 space-y-3'
                >
                  <h2 className='text-lg font-semibold text-[#304985]'>{group.title}</h2>
                  <div className='grow space-y-3'>
                    {group.activities.map((activity) => (
                      <NotificationCard
                        key={activity.id}
                        {...activity}
                        data={{
                          ...activity.data,
                          unreadCount: unreadMessages,
                        }}
                      />
                    ),
                    )}
                  </div>
                </div>
              )
              : null
          ))
        }

        {paginatedNotifications.length > 0
          ? (
            <div className='my-3 space-y-3'>
              <h2 className='text-lg font-semibold text-[#304985]'>{t('activityCenter.groups.lastMonth')}</h2>
              <InfiniteScroll
                dataLength={paginatedNotifications.length}
                scrollableTarget='scrollable'
                next={fetchMoreData}
                hasMore={paginatedNotifications.length % batchSize === 0}
                loader={<h4>Loading...</h4>}
                className='h-full space-y-3'
              >

                {paginatedNotifications.map((activity) => (
                  <NotificationCard
                    key={activity.id}
                    {...activity}
                  />
                ),
                )}
              </InfiniteScroll>
            </div>
          )
          : null}
      </div>
    </PageWrapper>
  )
}
