import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type EditTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']
  user_id: Types.Scalars['uuid']
  metadata?: Types.InputMaybe<Types.Scalars['jsonb']>
  status?: Types.InputMaybe<Types.Scalars['String']>
}>

export type EditTaskMutation = { __typename?: 'mutation_root', editTask?: { __typename?: 'UpdateUserTaskPayload', task?: { __typename?: 'Task', id: string, user_id: string, metadata?: any | undefined, bo_id: string, created_at?: string | undefined, status?: string | undefined, deleted?: boolean | undefined, title?: string | undefined, description_html?: string | undefined, response_html?: string | undefined, short_description?: string | undefined, loom_videos?: Array<string | undefined> | undefined, cognitoforms_key?: string | undefined, cognitoforms_dataform?: string | undefined, cognitoforms_response?: any | undefined, response_at?: string | undefined, updated_at?: string | undefined, created_by?: string | undefined, updated_by?: string | undefined, tag_slug?: string | undefined } | undefined } | undefined }

export type CreateUserTaskDomainGeneratorMutationVariables = Types.Exact<{
  bo_id: Types.Scalars['ID']
  metadata?: Types.InputMaybe<Types.Scalars['jsonb']>
  withCompanyName?: Types.InputMaybe<Types.Scalars['Boolean']>
}>

export type CreateUserTaskDomainGeneratorMutation = { __typename?: 'mutation_root', createUserTaskDomainGenerator?: { __typename?: 'CreateUserTaskDomainGeneratorPayload', success?: boolean | undefined } | undefined }

export type UpdateUserTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']
  variables?: Types.InputMaybe<Types.User_Tasks_Set_Input>
}>

export type UpdateUserTaskMutation = { __typename?: 'mutation_root', update_user_tasks_by_pk?: { __typename?: 'user_tasks', bo_id?: any | undefined, cognitoforms_dataform?: string | undefined, cognitoforms_key?: string | undefined, cognitoforms_response?: any | undefined, created_at: any, created_by: any, deleted: boolean, description_html?: string | undefined, id: any, linear_issue_id?: any | undefined, loom_videos?: any | undefined, metadata?: any | undefined, user_id?: string | undefined, updated_by?: any | undefined, updated_at: any, title: string, tag_slug?: string | undefined, status: string, short_description?: string | undefined, response_html?: string | undefined, response_at?: any | undefined, userByCreatedBy: { __typename?: 'users', first_name?: string | undefined, last_name?: string | undefined, email: string, id: any, profile_picture_url?: string | undefined } } | undefined }

export const EditTaskDocument = gql`
    mutation editTask($id: ID!, $user_id: uuid!, $metadata: jsonb, $status: String) {
  editTask(
    input: {id: $id, user_id: $user_id, metadata: $metadata, status: $status}
  ) {
    task {
      id
      user_id
      metadata
      bo_id
      created_at
      status
      deleted
      title
      description_html
      response_html
      short_description
      loom_videos
      cognitoforms_key
      cognitoforms_dataform
      cognitoforms_response
      response_at
      updated_at
      created_by
      updated_by
      tag_slug
    }
  }
}
    `
export type EditTaskMutationFn = Apollo.MutationFunction<EditTaskMutation, EditTaskMutationVariables>

/**
 * __useEditTaskMutation__
 *
 * To run a mutation, you first call `useEditTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskMutation, { data, loading, error }] = useEditTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user_id: // value for 'user_id'
 *      metadata: // value for 'metadata'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useEditTaskMutation(baseOptions?: Apollo.MutationHookOptions<EditTaskMutation, EditTaskMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<EditTaskMutation, EditTaskMutationVariables>(EditTaskDocument, options)
}
export type EditTaskMutationHookResult = ReturnType<typeof useEditTaskMutation>
export type EditTaskMutationResult = Apollo.MutationResult<EditTaskMutation>
export type EditTaskMutationOptions = Apollo.BaseMutationOptions<EditTaskMutation, EditTaskMutationVariables>
export const CreateUserTaskDomainGeneratorDocument = gql`
    mutation createUserTaskDomainGenerator($bo_id: ID!, $metadata: jsonb, $withCompanyName: Boolean) {
  createUserTaskDomainGenerator(
    input: {bo_id: $bo_id, metadata: $metadata, withCompanyName: $withCompanyName}
  ) {
    success
  }
}
    `
export type CreateUserTaskDomainGeneratorMutationFn = Apollo.MutationFunction<CreateUserTaskDomainGeneratorMutation, CreateUserTaskDomainGeneratorMutationVariables>

/**
 * __useCreateUserTaskDomainGeneratorMutation__
 *
 * To run a mutation, you first call `useCreateUserTaskDomainGeneratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserTaskDomainGeneratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserTaskDomainGeneratorMutation, { data, loading, error }] = useCreateUserTaskDomainGeneratorMutation({
 *   variables: {
 *      bo_id: // value for 'bo_id'
 *      metadata: // value for 'metadata'
 *      withCompanyName: // value for 'withCompanyName'
 *   },
 * });
 */
export function useCreateUserTaskDomainGeneratorMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserTaskDomainGeneratorMutation, CreateUserTaskDomainGeneratorMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<CreateUserTaskDomainGeneratorMutation, CreateUserTaskDomainGeneratorMutationVariables>(CreateUserTaskDomainGeneratorDocument, options)
}
export type CreateUserTaskDomainGeneratorMutationHookResult = ReturnType<typeof useCreateUserTaskDomainGeneratorMutation>
export type CreateUserTaskDomainGeneratorMutationResult = Apollo.MutationResult<CreateUserTaskDomainGeneratorMutation>
export type CreateUserTaskDomainGeneratorMutationOptions = Apollo.BaseMutationOptions<CreateUserTaskDomainGeneratorMutation, CreateUserTaskDomainGeneratorMutationVariables>
export const UpdateUserTaskDocument = gql`
    mutation UpdateUserTask($id: uuid!, $variables: user_tasks_set_input) {
  update_user_tasks_by_pk(pk_columns: {id: $id}, _set: $variables) {
    bo_id
    cognitoforms_dataform
    cognitoforms_key
    cognitoforms_response
    created_at
    created_by
    deleted
    description_html
    id
    linear_issue_id
    loom_videos
    metadata
    user_id
    updated_by
    updated_at
    title
    userByCreatedBy {
      first_name
      last_name
      email
      id
      profile_picture_url
    }
    tag_slug
    status
    short_description
    response_html
    response_at
  }
}
    `
export type UpdateUserTaskMutationFn = Apollo.MutationFunction<UpdateUserTaskMutation, UpdateUserTaskMutationVariables>

/**
 * __useUpdateUserTaskMutation__
 *
 * To run a mutation, you first call `useUpdateUserTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTaskMutation, { data, loading, error }] = useUpdateUserTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      variables: // value for 'variables'
 *   },
 * });
 */
export function useUpdateUserTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserTaskMutation, UpdateUserTaskMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<UpdateUserTaskMutation, UpdateUserTaskMutationVariables>(UpdateUserTaskDocument, options)
}
export type UpdateUserTaskMutationHookResult = ReturnType<typeof useUpdateUserTaskMutation>
export type UpdateUserTaskMutationResult = Apollo.MutationResult<UpdateUserTaskMutation>
export type UpdateUserTaskMutationOptions = Apollo.BaseMutationOptions<UpdateUserTaskMutation, UpdateUserTaskMutationVariables>
