import { Form, Formik } from 'formik'

import { RadioGroup } from '#app/v2/components/ui/radio-group'

import { Actions } from './components/Actions'
import { ConfirmationCard } from './components/ConfirmationCard'
import { DirectionForm } from './components/DirectionForm'
import { SquareCard } from './components/SquareCard'
import { TwoRowCard } from './components/TwoRowCard'

export const StoryWizardComponents = () => {
  return (
    <div className='min-h-screen bg-[#F7F9FB] p-4'>
      <Formik
        initialValues={{
          nothing: ''
        }}
        onSubmit={(values) => {
          console.log(values)
        }}
      >
        <Form className='flex flex-col gap-4 @container'>
          <h2 className=' text-lg/5 font-semibold text-[#304985] '>Company industry</h2>
          <RadioGroup className='grid grid-cols-3 gap-3 @sm:grid-cols-4 sm:grid-cols-5'>
            <SquareCard
              id='painting'
              label='Painting'
            />
            <SquareCard
              id='plumbing'
              label='Plumbing'
            />
            <SquareCard
              id='landscaping'
              label='Landscaping'
            />
            <SquareCard
              id='general-contractor'
              label='General contractor'
            />
            <SquareCard
              id='other'
              label='Other'
            />
          </RadioGroup>
          <h2 className=' text-lg/5 font-semibold text-[#304985] '>Choose logos</h2>
          <RadioGroup className='grid grid-cols-3 gap-3 @sm:grid-cols-4 sm:grid-cols-5'>
            <SquareCard
              isCheckbox
              id='logo1'
              label='Painting'
            />
            <SquareCard
              isCheckbox
              id='logo2'
              label='Plumbing'
            />
            <SquareCard
              isCheckbox
              id='logo3'
              label='Landscaping'
            />
            <SquareCard
              isCheckbox
              id='logo4'
              label='General contractor'
            />
            <SquareCard
              isCheckbox
              id='logo5'
              label='Other'
            />
          </RadioGroup>
          <h2 className=' text-lg/5 font-semibold text-[#304985] '>Yes/no</h2>
          <RadioGroup>
            <ConfirmationCard
              isChecked
              label='Yes'
            />
            <ConfirmationCard label='No' />
            <ConfirmationCard
              examples='https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2Fwizard%2FLineal.png?alt=media&token=a934e80b-1ca3-4133-ab29-eecd0c101fd5'
              label='Lineal'
            />
            <ConfirmationCard
              examples='https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2Fwizard%2FSolid.png?alt=media&token=4ecda473-cc6a-49f6-9f49-de4a40bc4dd6'
              label='Solid'
            />
            <ConfirmationCard
              examples='https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2Fwizard%2FDual.png?alt=media&token=b7d38749-afb7-4d8e-bb7e-dc6a462d9f3f'
              label='Dual'
            />
          </RadioGroup>
          <h2 className=' text-lg/5 font-semibold text-[#304985] '>Select your services</h2>
          <TwoRowCard
            title='Wall preparation and painting'
            description='Scrape, sand and patch all defects and apply paint.'
          />
          <TwoRowCard
            title='Drywall hole repair'
            description='Cover, caulk and sand.'
          />
          <TwoRowCard other />
          <h2 className=' text-lg/5 font-semibold text-[#304985] '>Select your colors</h2>
          <RadioGroup>
            <TwoRowCard
              title='Basic'
              colors={['#FFFFFF', '#2B2D42', '#8D99AE', '#D90429']}
            />
            <TwoRowCard
              title='Outstanding'
              colors={['#FFFFFF', '#2B2D42', '#8D99AE', '#D90429']}
            />
            <TwoRowCard
              title='Advanced'
              colors={['#FFFFFF', '#2B2D42', '#8D99AE', '#D90429']}
            />

          </RadioGroup>
          <DirectionForm />
          <Actions />
        </Form>
      </Formik>
    </div>
  )
}
