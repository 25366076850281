import { useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import AppContext from '#app/contexts/AppContext'
import { useAppContext } from '#app/contexts/AppContext/useAppContext'
import { usePublicWebsiteDeploymentByIdQuery } from '#app/operations/website-editor/websiteeditor.queries.generated'

export function PreviewDeployment() {
  const { V1_SET_LOADING } = useAppContext()
  const { appState } = useContext(AppContext)
  const { deploymentId } = useParams()

  const { data, loading } = usePublicWebsiteDeploymentByIdQuery({
    variables: {
      deployment_id: deploymentId
    }
  })

  useEffect(() => {
    if (appState.isLoading) {
      V1_SET_LOADING(loading)
    }
  }, [V1_SET_LOADING, appState.isLoading, loading])

  const fullURL = useMemo(() => {
    if (data?.website_deployments[0]) {
      const deploy = data?.website_deployments[0]
      return deploy.bucket_url
    }
    return undefined
  }, [data?.website_deployments])

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999
      }}
    >
      {
        fullURL
        && (
          <iframe
            style={{
              width: '100%',
              height: '100%',
              border: 'none'
            }}
            src={fullURL}
          />
        )
      }
    </div>
  )
}
