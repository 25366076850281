import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { Stack, Typography } from '@mui/material'

export const CurrentBankBalance = ({ amount }: { amount: number }) => {
  const { t } = useTranslation()

  return (
    <Stack>
      <Typography
        sx={{
          textAlign: 'center'
        }}
        variant='h1'
      >
        <NumericFormat
          thousandSeparator=','
          style={{
            fontWeight: 'bold',
            letterSpacing: 2
          }}
          displayType='text'
          value={amount}
          prefix='$'
        />
      </Typography>
      <Typography sx={{
        textAlign: 'center',
        fontWeight: 'bold'
      }}
      >
        { t('currentBankBalance.text', 'Current Balance')}
      </Typography>
    </Stack>
  )
}
