import create from 'zustand'
import { devtools } from 'zustand/middleware'

interface InputState {
  isFocused: boolean
}

interface InputActions {
  setFocusOff: () => void
  setFocusOn: () => void
}

const initialState: InputState = {
  isFocused: false,
}

export const useInputFocus = create<InputState & InputActions>()(
  devtools(
    (set) => ({
      ...initialState,
      setFocusOff() {
        return set(() => {
          return {
            isFocused: false,
          }
        })
      },
      setFocusOn() {
        return set(() => {
          return {
            isFocused: true,
          }
        })
      },

    }),
    {
      name: 'zt_owners_v2_task_comment_focused'
    },
  ),
)
