import { useIsDesktopSize } from '../../utils/mediaQueries'
import ArrowRight from '../icons/ArrowRight'

import './PageOnboarding.css'

const PageOnboarding = ({ children, ...props }) => {
  const isDesktop = useIsDesktopSize()

  const styles = isDesktop
    ? {
      padding: '0',
      height: '100%'
    }
    : {}

  return (
    <div
      className='container-fluid onboarding-content'
      style={styles}
    >
      { children }
    </div>
  )
}

const PageHeader = ({ children, ...props }) => (
  <div className='page-header d-flex justify-content-between align-items-center flex-column'>
    {children}
  </div>
)
PageOnboarding.PageHeader = PageHeader

const PageAction = ({ children, ...props }) => (
  <div className='card-body d-flex justify-content-end'>
    { children}
  </div>
)
PageOnboarding.PageAction = PageAction

const PageActionButton = ({ goBack, step = 0, ...props }) => {
  const isDesktop = useIsDesktopSize()

  if (isDesktop) {
    return (
      <div className='d-flex  w-100 justify-content-end align-items-center'>
        {step > 0 && (
          <span
            style={{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '140%',
              color: '#4498E5',
              textAlign: 'end',
              cursor: 'pointer',
              position: 'absolute',
              left: '2%'
            }}
            onClick={props.goBack}
          >
            Atras
          </span>
        )}
        { step < 2 && (
          <span
            className='v1-action-button d-flex align-items-center justify-content-center'
            onClick={props.onClick}
          >
            <ArrowRight />
          </span>
        )}
      </div>
    )
  }
  else if (step < 2) {
    return (
      <div
        className='v1-action-button d-flex justify-content-center align-items-center w-100'
        style={{
          borderRadius: '5px'
        }}
        onClick={props.onClick}
      >
        <span className='v1-action-button-label'>
          { `${props.label}` }
        </span>
      </div>
    )
  }
}

PageOnboarding.PageActionButton = PageActionButton

export default PageOnboarding
