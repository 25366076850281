import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'

import Images from '#app/v2/assets/Images'

const Container = styled('div')`
  min-height: 100svh;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "main img"
    "main img"
    "btn  img";
  justify-items: center;
  align-content: center;
  row-gap: 5rem ;

  @media ( width < 840px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: 
    "main"
    "img"
    "btn";
    row-gap: 1rem;
  }
`
const PrimaryContent = styled('div')`
  max-width: 465px;
  text-align: center;
  grid-area: main;

  @media (max-width: 600px) {
    max-width: 300px;
  }
`

const PersonLookingImg = styled('div')`
  max-width: 25.1875rem;
  grid-area: img;
  align-self: end;
  img {
    max-width: 100%;
    height: auto;
  }

  @media (width < 840px) {
    max-width: 18.75rem;
  }
  @media (width < 600px) {
    max-width: 14rem;
  }
`
const OoopsText = styled('h3')`
  margin-bottom: 0.5rem;
  font-size: 1.5rem/* 24px */;
  line-height: 1.5rem/* 24px */;
  font-weight: 800;
  color: #263238;
`
const Number404 = styled('h1')`
  margin-bottom: 2.25rem;
  font-size: 100px;
  font-weight: 700;
  line-height: 0.75;
  color: #304985;
  text-shadow: 8px 0px #22335D;

  @media (width < 600px) {
    margin-bottom: 1rem;
  }
`
const NotFoundPageText = styled('h2')`
  margin-bottom: 4rem/* 64px */;
  font-size: 1.25rem/* 20px */;
  line-height: 1.5rem/* 24px */;
  font-weight: 600;
  color: #304985;

  @media ( width < 840px) {
    margin-bottom: 2rem;
  }
`
const Description = styled('p')`
  margin-bottom: 1.5rem/* 24px */;
  font-size: 1.125rem/* 18px */;
  line-height: 1.25rem/* 20px */;
  font-weight: 500;
  color: #454545;
  word-wrap: break-word;
  overflow-wrap: break-word;
  
  span {
    color: #0083B6;
    text-decoration: underline;
  }
`
const InstructionText = styled('p')`
  font-weight: 500;
  color: #454545;
`
const ButtonLink = styled(Link)`
  grid-area: btn;
  text-align: center;
  align-self: center;
  display: inline-block;
  width: 13rem/* 208px */;
  border-radius: 0.625rem;
  padding: 12px 16px;
  background: #304985;
  color: #FFF;
  font-size: 14px;
  font-weight: 700;

`
export const NotFoundPage = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  return (
    <>
      <Container>
        <PrimaryContent>
          <OoopsText>Oops!</OoopsText>
          <Number404>404</Number404>
          <NotFoundPageText>{t('v2.notFoundPage.title', 'Page not found!')}</NotFoundPageText>
          <Description>
            {t('v2.notFoundPage.description1', 'We are sorry, the page')}
            {!pathname.includes('/not-found')
              ? (
                <>
                  <br />
                  <span>{window.location.href}</span>
                  <br />
                </>
              )
              : <br />}
            {t('v2.notFoundPage.description2', 'could not be found.')}
          </Description>
          <InstructionText>{t('v2.notFoundPage.indication', 'Please go back to the home page!')}</InstructionText>
        </PrimaryContent>
        <ButtonLink to='/'>
          {t('v2.notFoundPage.action', 'Go back home')}
        </ButtonLink>
        <PersonLookingImg>
          <img
            src={Images.NotFoundPersonLookingDesktop}
            alt='Not found page of person with a magnifying glass'
          />
        </PersonLookingImg>
      </Container>

    </>
  )
}
