import { ReactNode, useMemo } from 'react'
import Gravatar from 'react-gravatar'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

import { getInitialsUser } from '#app/components/Chip/ChipUser'
import { useAdmins } from '#app/hooks/useAdmins'
import { Avatar, AvatarFallback, AvatarImage } from '#app/v2/components/ui/avatar'

import { cn } from '../libs/utils'

import { ProfileAvatar } from './ProfileAvatar'

interface Props {
  profilePictureUrl?: string
  email?: string
  slug?: string
  fullName?: string
  firstName?: string
  lastName?: string
  linearUserId?: string
  UImin?: boolean
  onlyAvatar?: boolean
  className?: string
  indicator?: boolean
  children?: ReactNode
  urlChat?: string
  rating?: boolean
}

interface SubtitleProps {
  userEmail?: string
  author?: string
  fullSlug?: string
  email?: string
  UImin?: boolean
  children?: ReactNode
  urlChat?: string
  rating?: boolean
}
function Subtitle({ author, fullSlug, userEmail, email, UImin, children, urlChat, rating }: Readonly<SubtitleProps>) {
  const { t } = useTranslation()
  if (children) return <>{children}</>

  if (UImin) return (
    <p className='text-xs text-[#454545] '>
      {' '}
      {author}
      {' '}
      |
      {' '}
      {fullSlug ?? (userEmail ?? email)}
    </p>
  )

  return (
    <div className='w-full'>
      <p className='font-medium text-[#212121]'>
        {author}
        {' '}
        {urlChat && <span className='text-[0.625rem] font-normal text-[#A2A2A2]'>{t('v2.tasks.comment.profile.fromChat', 'from Chat')}</span>}
      </p>
      <p className=' overflow-hidden text-ellipsis text-xs/tight font-medium tracking-[0.02px] text-[#616161]'>{fullSlug ?? (userEmail ?? email) ?? (rating && t('v2.tasks.rating.score', 'Task rating'))}</p>
    </div>
  )
}

export function ProfileAvatarDetailed(props: Props) {
  const {
    profilePictureUrl,
    email,
    slug,
    fullName,
    firstName,
    lastName,
    linearUserId,
    UImin,
    onlyAvatar,
    className,
    children,
    indicator,
    urlChat,
    rating,
  } = props

  const { getAdminEmailByLinearUserId, getAdminByLinearUserId, getAdminNameByLinearUserId } = useAdmins()

  const author = useMemo(() => {
    if (linearUserId) {
      return getAdminNameByLinearUserId(linearUserId, true) ?? 'Owners Team'
    }
    if (fullName) return fullName
    if (firstName || lastName) return `${firstName} ${lastName}`
    if (rating) return t('generic.you', 'You')

    return 'Owners Team'
  }, [linearUserId, getAdminNameByLinearUserId, fullName, firstName, lastName, rating])

  const userEmail = useMemo(() => {
    return !linearUserId ? email : getAdminEmailByLinearUserId(linearUserId)
  }, [linearUserId, email, getAdminEmailByLinearUserId])

  const fullSlug = useMemo(() => {
    if (slug) return slug
    const slugAdmin = getAdminByLinearUserId(linearUserId)
    if (slugAdmin?.title) return slugAdmin?.title
  }, [slug, getAdminByLinearUserId, linearUserId])

  if (onlyAvatar) {
    return (
      <ProfileAvatar
        image={profilePictureUrl}
        gravatar={(
          <Gravatar
            email={userEmail}
            className='object-cover'
          />
        )}
        initials={getInitialsUser(author)}
        fullName={author}
        className={className}
      />
    )
  }

  return (
    <>
      <Avatar className={cn({
        UImin: 'h-6 w-6'
      }, className)}
      >
        <div className='relative inline-block h-full w-full'>
          { profilePictureUrl
            ? (
              <AvatarImage
                src={profilePictureUrl}
                className='object-cover'
              />
            )
            : userEmail && (
              <Gravatar
                email={userEmail}
                size={UImin ? 40 : undefined}
                className='object-cover'
              />
            )}
          <AvatarFallback className=' bg-[#E9F0FF] text-[#304985]'>{getInitialsUser(author)}</AvatarFallback>
          { indicator !== undefined && (
            <span className={cn('absolute bottom-0.5 right-0.5 h-4 w-4 rounded-full border-2 border-white', {
              'bg-green-500': indicator,
              'bg-gray-300': !indicator,
            })}
            >
            </span>
          ) }
        </div>

      </Avatar>
      <Subtitle
        userEmail={userEmail}
        author={author}
        fullSlug={fullSlug}
        email={email}
        UImin={UImin}
        urlChat={urlChat}
        rating={!!rating}
      >
        {children}
      </Subtitle>
    </>
  )
}
