import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BsArchive, BsEnvelope } from 'react-icons/bs'
import { useSearchParams } from 'react-router-dom'
import { Stack, Typography } from '@mui/material'
import { useImmer } from 'use-immer'

import { DividerExtended } from '#app/components/Divider'
import ProfileContext from '#app/contexts/ProfileContext'
import { useEntityUserTasks } from '#app/hooks/useEntityUserTasks'
import { THEME } from '#app/layouts/theme'
import { UserTasks } from '#app/routes/Tasks'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

import { ArchiveBox } from './styles'

const onlyCompleted = [{
  status: {
    _in: ['admin_completed', 'completed'],
    _nin: ['processing', 'draft']
  }
}]
const onlyNotCompleted = [{
  status: {
    _nin: ['admin_completed', 'completed', 'processing', 'draft']
  }
}]

export function OwnersTasksAsUser() {
  const { queryGetUserTasks, queryGetUserTasksUtils } = useEntityUserTasks()
  const { getProfile: { user } } = useContext(ProfileContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()

  const isDesktop = useIsDesktopSize()

  const basicQuery = useMemo(() => {
    return {
      where: {
        deleted: {
          _neq: true
        },
        _and: [{
          bo_id: {
            _eq: user?.id
          }
        }]
      }
    }
  }, [user?.id])

  const [queryVariables, setQueryVariables] = useImmer<any>(basicQuery)

  useEffect(() => {
    if (user?.id) {
      void queryGetUserTasks({
        variables: queryVariables
      })
    }
  }, [queryGetUserTasks, queryVariables, user])

  useEffect(() => {
    queryGetUserTasksUtils
      .startPolling(5000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isWhereGetActive = useMemo(() => {
    if (searchParams.get('where') === null) {
      return false
    }
    return true
  }, [searchParams])

  useEffect(() => {
    const whereConditions = searchParams.get('where')
    if (whereConditions) {
      setQueryVariables((draft: any) => {
        draft.where._and = [
          ...basicQuery.where._and,
          ...JSON.parse(whereConditions)
        ]
      })
    }
    else {
      setQueryVariables((draft: any) => {
        draft.where._and = [
          ...basicQuery.where._and,
          ...onlyNotCompleted
        ]
      })
    }
  }, [basicQuery, searchParams, setQueryVariables, user])

  const switchFilter = useCallback(() => {
    const currentWhere = searchParams.get('where')

    if (!currentWhere) {
      setSearchParams({
        where: JSON.stringify(onlyCompleted)
      })
    }
    else {
      searchParams.delete('where')
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams])

  const filtersOptions = useMemo(() => (
    <ArchiveBox
      isDesktop={isDesktop}
      onClick={switchFilter}
    >
      <Stack
        direction='row'
        spacing={2}
      >
        {
          !isWhereGetActive
            ? (
              <BsArchive
                color={THEME.COLORS.custom.grey}
                fontSize={THEME.ICONS.size.md}
              />
            )
            : (
              <BsEnvelope
                color={THEME.COLORS.custom.grey}
                fontSize={THEME.ICONS.size.md}
              />
            )
        }
        <Typography variant='subtitle2'>
          {!isWhereGetActive ? t('user_tasks.archived') : t('user_tasks.messages')}
        </Typography>
      </Stack>
      <DividerExtended />
    </ArchiveBox>
  ), [isDesktop, isWhereGetActive, switchFilter])

  return (
    <UserTasks
      taskList={queryGetUserTasksUtils.data?.user_tasks ?? []}
      isLoading={queryGetUserTasksUtils.loading}
      isCalled={queryGetUserTasksUtils.called}
      filtersOptions={filtersOptions}
    />
  )
}
