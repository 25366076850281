import { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Grid, Typography } from '@mui/material'

import { useAppContext } from '#app/contexts/AppContext/useAppContext'

import PageContent from '../../../components/PageContent/PageContent'
import AppContext from '../../../contexts/AppContext'
import ProfileContext from '../../../contexts/ProfileContext'
import { CREATE_CHECKOUT_SESSION } from '../../../utils/graphqlQueries'

const Plans = () => {
  const { appState } = useContext(AppContext)
  const { V1_SET_LOADING } = useAppContext()
  const { getProfile: { user } } = useContext(ProfileContext)

  const [createCheckoutSession, { data, loading, error, called }] = useMutation(CREATE_CHECKOUT_SESSION)
  const [pendingSubscriptions, setPendingSubscriptions] = useState(false)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if (!called && !loading && !data && pendingSubscriptions) {
      createCheckoutSession()
    }

    if (data) {
      const { createCheckoutSession: { url } } = data
      window.location.assign(url)
    }
  }, [data, loading, error, called, pendingSubscriptions])

  useEffect(() => {
    const [business = {}] = user.businesses

    const subscriptions = business.subscriptions || []

    const isPending = subscriptions.find((s) => s.status === 'pending')

    isPending ? setPendingSubscriptions(true) : setLoading(false)
  }, [])

  useEffect(() => {
    if (isLoading === false) {
      if (appState.isLoading) {
        setTimeout(() => {
          V1_SET_LOADING(false)
        }, 500)
      }
    }
  }, [isLoading])

  return (
    <PageContent>
      <PageContent.PageHeader>
        <Typography variant='h3'>Welcome to Owners</Typography>
        <PageContent.PageAction>
        </PageContent.PageAction>
      </PageContent.PageHeader>
      <Grid
        item
        xs={12}
      >
        {
          user.blocked
            ? (
              <h5>
                We are currently having issues with your account
              </h5>
            )
            : (
              <h5>
                We have not found records of your account in our system
              </h5>
            )
        }
        <p>
          <span className='me-2'>Please reach out to our support team to continue to our platform</span>
          <a
            href='tel:+1305912-9689'
            style={{
              textDecoration: 'none',
            }}
          >
            +1 (305) 912-9689

          </a>
        </p>
      </Grid>
    </PageContent>
  )
}

export default Plans
