import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'

import { ChatBubbleOval, ClipboardCheck, Home4Line, Home4Solid } from '#app/v2/assets/Icons'

import { cn, useExpoStatus } from '../libs/utils'

import { NotificationDot } from './ui/dot-notification'
import { pageTransition } from './animations'

interface Props {
  routes: {
    home: string
    messages: string
    tasks: string
    business: string
  }
  pendingMessages: number
  isIOS: boolean
}

export const NavigationBottom = ({ routes, pendingMessages, isIOS }: Props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const isExpo = useExpoStatus()
  let bottomNavigationVariants = {}
  if (!isExpo) {
    bottomNavigationVariants = {
      initial: {
        translateY: '100%',
      },
      animate: {
        translateY: '0',
      }
    }
  }

  return (
    <motion.div
      variants={bottomNavigationVariants}
      transition={pageTransition}
      className='fixed bottom-0 w-full'
    >
      {pathname.includes(routes.tasks)
      && <div className=' h-24 bg-gradient-to-t from-white/75 from-35% to-transparent' />}
      <nav className=' flex justify-around bg-[#304985] px-8 py-4 text-white'>
        <Link
          to={routes.home}
          state={{
            from: pathname,
            to: routes.home
          }}
          className={cn('flex flex-col items-center gap-1.5 text-xs', {
            'text-[#6BE7BE]': pathname === routes.home
          })}
        >
          {pathname === routes.home
            ? (
              <Home4Solid className='h-6 w-6 text-[#6BE7BE]' />
            )
            : (
              <Home4Line className='h-6 w-6' />
            ) }
          {t('v2.nav.home', 'Home')}
        </Link>
        <Link
          to={routes.messages}
          state={{
            from: pathname,
            to: routes.messages
          }}
          className={cn('relative flex flex-col items-center gap-1.5 text-xs', {
            'text-[#6BE7BE]': pathname.includes(routes.messages)
          })}
        >
          {pendingMessages > 0
            ? (
              <NotificationDot
                quantity={pendingMessages}
                variant='activityCenter'
                className='bg-[#D81C1C] text-white'

              >
                <ChatBubbleOval className={cn('h-6 w-6', {
                  'fill-[#6BE7BE] text-[#6BE7BE]': pathname.includes(routes.messages)
                })}
                />
              </NotificationDot>
            )
            : (
              <ChatBubbleOval className={cn('h-6 w-6', {
                'fill-[#6BE7BE] text-[#6BE7BE]': pathname.includes(routes.messages)
              })}
              />
            )}
          {t('v2.nav.messages', 'Messages')}
        </Link>
        <Link
          to={routes.tasks}
          state={{
            from: pathname,
            to: routes.tasks
          }}
          className={cn('flex flex-col items-center gap-1.5 text-xs', {
            'text-[#6BE7BE]': pathname.includes(routes.tasks)
          })}
        >
          <ClipboardCheck
            className={cn('h-6 w-6', {
              ' text-[#6BE7BE]': pathname.includes(routes.tasks)
            })}
            extraClassName='text-white'
            fillPath={cn('', {
              'fill-[#6BE7BE]': pathname.includes(routes.tasks)
            })}
          />
          {t('v2.nav.tasks', 'Tasks')}
        </Link>
        {/*
                <Link to={routes.business} className={cn('flex flex-col items-center gap-1.5 text-xs', {
                    'text-[#6BE7BE]' : pathname.includes(routes.business)
                })}>
                    <BagSuitcase2
                    className={cn('h-6 w-6',
                        {'text-[#6BE7BE]' : pathname.includes(routes.business)}
                    )}
                    fillPath={cn({
                        'fill-[#6BE7BE]' : pathname.includes(routes.business)
                    })}
                    />
                    Business
                </Link>
                */}
      </nav>
      {isIOS
        ? (
          <div className='mx-auto mt-3 h-1.5 w-32 rounded-full bg-black' />
        )
        : null}
    </motion.div>
  )
}
