/* eslint-disable @typescript-eslint/no-unsafe-call */

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsArrowRepeat } from 'react-icons/bs'
import { useSearchParams } from 'react-router-dom'
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import { isArray } from 'lodash'
import * as Yup from 'yup'

import Button from '#app/components/Buttons/Button'
import RadioButtonGroup from '#app/components/RadioButtonGroup/RadioButtonGroup'
import { useEntityUserTasks } from '#app/hooks/useEntityUserTasks'
import { useGenerateNamesAndDomainsMutation } from '#app/operations/onboarding/onboarding.mutations.generated'
import { NameDomainSuggestion, useOnboardingNameDomain } from '#app/store/onboarding-name-domain'
import { GET_PROFILE_TYPE } from '#app/utils/graphqlQueries'

export interface GenerateOptionsProps {
  userProfile?: GET_PROFILE_TYPE
}

export const GenerateOptions = ({ userProfile }: GenerateOptionsProps) => {
  const { t } = useTranslation()
  const {
    suggestionComponents,
    generatedSuggestions,
    generatedDomainSuggestions,
    currentStep,
    setCurrentStep,
    setGeneratedSuggestions,
    setGeneratedDomainSuggestions,
    setSelectedSuggestion,
    selectedSuggestion,
    task,
    setTask,
    type,
    setSelectedDomain,
    selectedDomain
  } = useOnboardingNameDomain()

  const [generateDomainAndNames] = useGenerateNamesAndDomainsMutation()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isLoadingMore, setLoadingMore] = useState<boolean>(false)
  const [isBackLoading, setBackLoading] = useState<boolean>(false)
  const [isNextLoading, setNextLoading] = useState<boolean>(false)

  const [searchParams] = useSearchParams()
  const { mutationEditUserTask } = useEntityUserTasks()

  console.log('currentStep', currentStep)

  const goBack = async () => {
    setBackLoading(true)

    console.log('goBack - type', type)

    const prevStep = type === 'name-and-domain' && currentStep === 'generate-options' ? 'select-options' : 'generate-options'

    console.log('goBack - prevStep', prevStep)

    return await mutationEditUserTask(
      {
        variables: {
          id: searchParams.get('taskId') ?? '',
          user_id: userProfile?.getProfile?.user?.id ?? '',
          metadata: {
            ...task?.metadata,
            currentStep: prevStep
          }
        }
      })
      .then(() => {
        setCurrentStep(prevStep)
      })
      .finally(() => {
        setBackLoading(false)
      })
  }

  const onSubmit = async (values: FormikValues) => {
    console.log('onSubmit')
    setNextLoading(true)

    console.log('onSubmit - currentStep', currentStep)
    if (currentStep === 'generate-options') {
      setSelectedSuggestion(Number(values.selectedOption))
    }
    else if (currentStep === 'select-domain-only') {
      setSelectedDomain(Number(values.selectedOption))
    }

    console.log('onSubmit - type', type)

    const nextStep = type === 'name-and-domain' && currentStep === 'generate-options' ? 'select-domain-only' : 'confirm-selection'
    const selectedProp = currentStep === 'generate-options' ? 'selectedSuggestion' : 'selectedDomain'

    console.log('onSubmit - nextStep', nextStep)
    console.log('onSubmit - selectedProp', selectedProp)

    const editTask = {
      id: searchParams.get('taskId') ?? '',
      user_id: userProfile?.getProfile?.user?.id ?? '',
      metadata: {
        ...task?.metadata,
        currentStep: nextStep,
        [selectedProp]: values.selectedOption
      }
    }

    await mutationEditUserTask(
      {
        variables: editTask
      })
      .then(() => {
        if (task && editTask) {
          setTask({
            ...task,
            ...editTask,
            metadata: {
              ...task?.metadata,
              ...editTask.metadata
            }
          })
        }
      })
      .finally(() => {
        setNextLoading(false)
      })

    setCurrentStep(nextStep)
  }

  const generateMore = () => {
    setLoadingMore(true)

    void generateDomainAndNames(
      {
        variables: {
          user_id: userProfile?.getProfile?.user?.id ?? '',
          params: {
            include: currentStep === 'select-domain-only' ? ['includeDomainOnly'] : suggestionComponents,
            exclude: currentStep === 'select-domain-only' ? generatedDomainSuggestions : generatedSuggestions,
            selectedCompanyName: currentStep === 'select-domain-only' ? generatedSuggestions[selectedSuggestion]?.name : '',
            isSoleProp: !!task?.metadata?.isSoleProp
          }
        }
      })
      .then(async (res) => {
        console.log('currentStep', currentStep)

        const existing = currentStep === 'select-domain-only' ? generatedDomainSuggestions : generatedSuggestions

        const allSuggestions = [...existing, ...(isArray(res.data?.generateNamesAndDomains?.results) ? res.data?.generateNamesAndDomains?.results as NameDomainSuggestion[] : [])]

        if (currentStep === 'select-domain-only') {
          setGeneratedDomainSuggestions(allSuggestions)
        }
        else {
          setGeneratedSuggestions(allSuggestions)
        }

        const propToChange = currentStep === 'select-domain-only' ? 'generatedDomainSuggestions' : 'generatedSuggestions'

        const editTask = {
          id: searchParams.get('taskId') ?? '',
          user_id: userProfile?.getProfile?.user?.id ?? '',
          metadata: {
            ...task?.metadata,
            [propToChange]: allSuggestions
          }
        }

        return await mutationEditUserTask(
          {
            variables: editTask
          })
          .then(() => {
            if (task && editTask) {
              console.log({
                ...task,
                ...editTask,
                metadata: {
                  ...task?.metadata,
                  ...editTask.metadata
                }
              })
              setTask({
                ...task,
                ...editTask,
                metadata: {
                  ...task?.metadata,
                  ...editTask.metadata
                }
              })
            }
          })
      })
      .finally(() => {
        setLoadingMore(false)
      })
  }

  useEffect(() => {
    let ignore = false

    if (currentStep === 'generate-options') {
      if (generatedSuggestions.length === 0) {
        setLoading(true)

        void generateDomainAndNames(
          {
            variables: {
              user_id: userProfile?.getProfile?.user?.id ?? '',
              params: {
                include: suggestionComponents,
                isSoleProp: !!task?.metadata?.isSoleProp
              }
            }
          })
          .then((res) => {
            if (!ignore) {
              const suggestions = isArray(res.data?.generateNamesAndDomains?.results) ? res.data?.generateNamesAndDomains?.results as NameDomainSuggestion[] : []

              setGeneratedSuggestions(suggestions)

              const editTask = {
                id: searchParams.get('taskId') ?? '',
                user_id: userProfile?.getProfile?.user?.id ?? '',
                metadata: {
                  ...task?.metadata,
                  generatedSuggestions: suggestions
                }
              }

              return mutationEditUserTask(
                {
                  variables: editTask
                })
                .then(() => {
                  if (task && editTask) {
                    setTask({
                      ...task,
                      ...editTask,
                      metadata: {
                        ...task?.metadata,
                        ...editTask.metadata
                      }
                    })
                  }
                })
            }
          })
          .finally(() => {
            setLoading(false)
          })

        // need this workaround to prevent strict mdoe in development to run this function twice
        // this is only noticeable because the results from open ai are random therefore the second time the results are different
        return () => {
          ignore = true
        }
      }
    }
    else if (currentStep === 'select-domain-only') {
      if (generatedDomainSuggestions.length === 0) {
        setLoading(true)

        const selectedCompanyName = generatedSuggestions[selectedSuggestion]?.name ?? ''

        void generateDomainAndNames(
          {
            variables: {
              user_id: userProfile?.getProfile?.user?.id ?? '',
              params: {
                include: ['includeDomainOnly'],
                selectedCompanyName,
                isSoleProp: !!task?.metadata?.isSoleProp
              }
            }
          })
          .then((res) => {
            if (!ignore) {
              const suggestions = isArray(res.data?.generateNamesAndDomains?.results) ? res.data?.generateNamesAndDomains?.results as NameDomainSuggestion[] : []

              setGeneratedDomainSuggestions(suggestions)

              const editTask = {
                id: searchParams.get('taskId') ?? '',
                user_id: userProfile?.getProfile?.user?.id ?? '',
                metadata: {
                  ...task?.metadata,
                  generatedDomainSuggestions: suggestions
                }
              }

              return mutationEditUserTask(
                {
                  variables: editTask
                })
                .then(() => {
                  if (task && editTask) {
                    setTask({
                      ...task,
                      ...editTask,
                      metadata: {
                        ...task?.metadata,
                        ...editTask.metadata
                      }
                    })
                  }
                })
            }
          })
          .finally(() => {
            setLoading(false)
          })

        // need this workaround to prevent strict mdoe in development to run this function twice
        // this is only noticeable because the results from open ai are random therefore the second time the results are different
        return () => {
          ignore = true
        }
      }
    }
  }, [])

  const schema = Yup
    .object()
    .shape({
      selectedOption: Yup
        .string()
        .test('isValidSelection', t('validation.required'),
          // @ts-ignore
          (value) => {
            if (value) {
              return Number(value) >= 0
            }
          })
    })

  return (
    <Box>
      {
        isLoading
          ? (
            <Stack spacing={3}>
              <Typography variant='h6'>{t('onboarding.domainNameSelection.steps.generateOptions.loading', 'Working on it')}</Typography>
              <Typography>{t('onboarding.domainNameSelection.steps.generateOptions.description', 'We are generating some suggestions for you, hold tight')}</Typography>
              <Stack alignItems='center'>
                <CircularProgress />
              </Stack>
            </Stack>
          )
          : (
            <Stack spacing={3}>
              <Typography variant='h6'>{t('onboarding.domainNameSelection.steps.generateOptions.title', 'We generated some options')}</Typography>
              {
                currentStep === 'generate-options'
                  ? <Typography>{t('onboarding.domainNameSelection.steps.generateOptions.description', 'Pick one name from the following list, this will be your company name and we will use this name in the next step to generate your domain.')}</Typography>
                  : <Typography>{t('onboarding.domainNameSelection.steps.generateOptions.pickDomainDescription', 'The following domains are suitable for your company name, pick one and click the button below to confirm the selection.')}</Typography>
              }
              {
                currentStep === 'generate-options'
                  ? (
                    <Formik
                      initialValues={{
                        selectedOption: String(selectedSuggestion)
                      }}
                      validationSchema={schema}
                      onSubmit={onSubmit}
                    >
                      {
                        ({ values }) => {
                          return (
                            <Form>
                              <Grid
                                container
                                spacing={1}
                              >
                                <Grid
                                  item
                                  xs={12}
                                >
                                  {/* @ts-ignore */}
                                  <RadioButtonGroup
                                    name='selectedOption'
                                    initialValue={values.selectedOption}
                                  >
                                    {
                                      generatedSuggestions?.map((opt, idx) => {
                                        return (
                                          /* @ts-ignore */
                                          <RadioButtonGroup.RadioButton
                                            key={idx}
                                            value={String(idx)}
                                          >
                                            <Stack>
                                              <Typography fontWeight='bold'>{opt.name}</Typography>
                                            </Stack>
                                          </RadioButtonGroup.RadioButton>
                                        )
                                      })
                                    }
                                  </RadioButtonGroup>

                                  {
                                    !task?.metadata?.isSoleProp
                                    && (
                                      <Button
                                        startIcon={<BsArrowRepeat />}
                                        isLoading={isLoadingMore}
                                        disabled={isLoadingMore}
                                        primary={false}
                                        sx={{
                                          width: '100%'
                                        }}
                                        onClick={generateMore}
                                      >
                                        {t('onboarding.domainNameSelection.steps.generateOptions.more', 'Generate more')}
                                      </Button>
                                    )
                                  }
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                  >
                                    <Button
                                      disabled={isLoadingMore || isBackLoading}
                                      isLoading={isBackLoading}
                                      primary={false}
                                      onClick={goBack}
                                    >
                                      {t('onboarding.domainNameSelection.steps.generateOptions.back', 'Back')}
                                    </Button>
                                    <Button
                                      disabled={isLoadingMore}
                                      type='submit'
                                    >
                                      {t('onboarding.domainNameSelection.steps.generateOptions.button', 'Choose')}
                                    </Button>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Form>
                          )
                        }
                      }
                    </Formik>
                  )
                  : (
                    <Formik
                      initialValues={{
                        selectedOption: String(selectedDomain)
                      }}
                      validationSchema={schema}
                      onSubmit={onSubmit}
                    >
                      {
                        ({ values }) => {
                          return (
                            <Form>
                              <Grid
                                container
                                spacing={1}
                              >
                                <Grid
                                  item
                                  xs={12}
                                >
                                  {/* @ts-ignore */}
                                  <RadioButtonGroup
                                    name='selectedOption'
                                    initialValue={values.selectedOption}
                                  >
                                    {
                                      generatedDomainSuggestions?.map((opt, idx) => {
                                        return (
                                          /* @ts-ignore */
                                          <RadioButtonGroup.RadioButton
                                            key={idx}
                                            value={String(idx)}
                                          >
                                            <Stack>
                                              <Typography variant='caption'>{opt.domain}</Typography>
                                            </Stack>
                                          </RadioButtonGroup.RadioButton>
                                        )
                                      })
                                    }
                                  </RadioButtonGroup>
                                  <Button
                                    startIcon={<BsArrowRepeat />}
                                    isLoading={isLoadingMore}
                                    disabled={isLoadingMore}
                                    primary={false}
                                    sx={{
                                      width: '100%'
                                    }}
                                    onClick={generateMore}
                                  >
                                    {t('onboarding.domainNameSelection.steps.generateOptions.more', 'Generate more')}
                                  </Button>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <Stack
                                    direction='row'
                                    justifyContent={type !== 'domain-only' ? 'space-between' : 'flex-end'}
                                  >
                                    {
                                      type !== 'domain-only' && (
                                        <Button
                                          disabled={isLoadingMore || isBackLoading}
                                          isLoading={isBackLoading}
                                          primary={false}
                                          onClick={goBack}
                                        >
                                          {t('onboarding.domainNameSelection.steps.generateOptions.back', 'Back')}
                                        </Button>
                                      )
                                    }

                                    <Button
                                      disabled={isLoadingMore || isNextLoading}
                                      isLoading={isNextLoading}
                                      type='submit'
                                    >
                                      {t('onboarding.domainNameSelection.steps.generateOptions.button', 'Choose')}
                                    </Button>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Form>
                          )
                        }
                      }
                    </Formik>
                  )
              }

            </Stack>
          )
      }
    </Box>
  )
}
