import { useMemo } from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'

import creditCardChipImg from '#app/assets/img/credit_card_chip.png'
import creditCardLogoImg from '#app/assets/img/credit_card_owners_v2.png'
import { COLORS } from '#app/layouts/theme'
import { GetBankCardsQuery } from '#app/operations/banking/banking.queries.generated'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

import { CardContainer, CreditCardChip, CreditCardLogo } from './styled'

export function CardPreview({ cardRow }: { cardRow?: GetBankCardsQuery['bank_cards'][0] }) {
  const debitCard = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return cardRow?.card ?? null
  }, [cardRow])

  const isDesktop = useIsDesktopSize()

  return (
    <Box>
      {debitCard
      && (
        <CardContainer
          isDesktop={isDesktop}
          status={debitCard.status}
        >
          <Stack
            direction='column'
            justifyContent='space-between'
            sx={{
              height: '100%',
              width: '100%'
            }}
          >
            <Box>
              <Grid
                container
                sx={{
                  height: '100%',
                  width: '100%'
                }}
                direction='row'
              >
                <Grid
                  item
                  xs={10}
                >
                  <Typography
                    sx={{
                      color: COLORS.light.primary
                    }}
                    variant='subtitle1'
                  >
                    {debitCard?.cardholder?.name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                >
                  <CreditCardLogo src={creditCardLogoImg} />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <CreditCardChip src={creditCardChipImg} />
            </Box>
            <Box>
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{
                  height: '100%',
                  width: '100%'
                }}
              >
                <Grid
                  item
                  xs={9}
                >
                  <Typography
                    sx={{
                      color: COLORS.light.primary
                    }}
                    variant='subtitle1'
                  >
                    ****
                    {' '}
                    {debitCard?.last4}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                >
                  <Typography
                    sx={{
                      color: COLORS.light.primary,
                      lineHeight: '0'
                    }}
                    mb={0}
                    variant='overline'
                  >
                    Valid until
                  </Typography>
                  <Typography
                    sx={{
                      color: COLORS.light.primary,
                      lineHeight: '1'
                    }}
                    mt={0}
                    variant='subtitle1'
                  >
                    {debitCard?.exp_month}
                    /
                    {debitCard?.exp_year}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </CardContainer>
      )}
    </Box>
  )
}
