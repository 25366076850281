/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useContext, useMemo } from 'react'

import { Generic, User } from '#app/types'

import ProfileContext from '../contexts/ProfileContext'

export const USER_SYSTEM_OWNERS = 'system@joinowners.com'

export function useEntityUser() {
  const { getProfile: { user, altBoProfile }, roles } = useContext(ProfileContext)

  const defaultAltBoEmail = 'dummy+[[service]]_demo_bo@joinowners.com'
  const demoAltBoEmail = 'demo+autogenerated_bo@joinowners.com'

  const rolesBySlug: { [slug: string]: User.RolesType } = useMemo(() => {
    const res: { [slug: string]: User.RolesType } = {}
    if (roles) {
      roles.map((rol: User.RolesType) => {
        res[rol.slug] = rol
      })
    }
    return res
  }, [roles])

  const rolesByID: { [roleID: Generic.RoleID]: User.RolesType } = useMemo(() => {
    const res: { [roleID: Generic.RoleID]: User.RolesType } = {}
    if (roles) {
      roles.map((rol: User.RolesType) => {
        res[rol.id] = rol
      })
    }
    return res
  }, [roles])

  const isAdmin = useMemo(() => {
    if (user?.role.slug === 'owners-admin') {
      return true
    }
    return false
  }, [user])

  const userFullName = useMemo(() => {
    if (!user) return ''
    return `${String(altBoProfile?.first_name ?? user?.first_name ?? '')} ${String(altBoProfile?.last_name ?? user.first_name ?? '')}`
  }, [altBoProfile, user])

  const userId = useMemo(() => {
    return user?.id ?? null
  }, [user])

  const userEmail = useMemo(() => {
    return user?.email ?? null
  }, [user])

  const isSalesDemoAccount = useCallback((email: string) => {
    const emailAccount = email.toLowerCase()
    const result = emailAccount.endsWith('demo_bo@joinowners.com') || emailAccount.endsWith('autogenerated_bo@joinowners.com')
    return result
  }, [])

  const currentIsSalesDemoAccount = useMemo(() => {
    return userEmail ? isSalesDemoAccount(userEmail) : false
  }, [isSalesDemoAccount, userEmail])

  const stripeConnectedAcc = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return user?.stripe_connected_acc ?? null
  }, [user])

  const getServiceSalesDemoEmail: (email: string) => string = useCallback((email) => {
    return email.substring(email.indexOf('+') + 1, email.indexOf('_')) // chris+painting_demo_bo@joinowners.com -> "painting"
  }, [])

  const getSalesDemoEmail: (email: string) => string = useCallback((email) => {
    const service = getServiceSalesDemoEmail(email)
    return defaultAltBoEmail.replace('[[service]]', service)
  }, [getServiceSalesDemoEmail])

  return {
    user,
    userFullName,
    stripeConnectedAcc,
    userEmail,
    userId,
    isAdmin,
    rolesBySlug,
    rolesByID,
    isSalesDemoAccount,
    currentIsSalesDemoAccount,
    defaultAltBoEmail,
    demoAltBoEmail,
    getServiceSalesDemoEmail,
    getSalesDemoEmail,
    altBoProfile
  }
}
