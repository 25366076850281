import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import { Box, FormControlLabel, FormGroup, Stack, Switch, TextField } from '@mui/material'

import Button from '#app/components/Buttons/Button'
import { ChipUserAvatar } from '#app/components/Chip/ChipUserAvatar'
import { DialogConfirm } from '#app/components/Dialog/DialogConfirm'
import { ContainerQuill } from '#app/components/RichTextArea/styled'
import ProfileContext from '#app/contexts/ProfileContext'
import { Attachments } from '#app/feature/Comments/Attachments'
import { useEntityComments } from '#app/hooks/useEntityComments'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { Comment, User } from '#app/types'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

import useUploadResource from '../../hooks/useUploadResource'

import { PropsCommentsThread } from '.'

interface Props {
  config: PropsCommentsThread
}

interface PropsContent extends Props {
  user: User.UserType
}

export function NewComment({ config }: Props) {
  const data = useContext(ProfileContext)
  const user = data.getProfile.user

  if (user) {
    return (
      <NewCommentContent
        config={config}
        user={user}
      />
    )
  }

  return <Box></Box>
}

function NewCommentContent({ config, user }: PropsContent) {
  const { receiverUserId, entityId, entityType, isThreadClosed } = config
  const { isAdmin } = useEntityUser()
  const { t } = useTranslation()
  const { uploadResource } = useUploadResource()
  const isDesktop = useIsDesktopSize()
  const textareaRef = useRef<HTMLInputElement | null>()
  const [sideEffect, setSideEffect] = useState<string | null>(null)
  const [dialogConfirm, setDialogConfirm] = useState<boolean>(false)
  const [isPrivateNote, setPrivateNote] = useState<boolean>(false)
  const [richComment, setRichComment] = useState('')
  const [isDisabled, setDisabled] = useState(isThreadClosed)
  const [attachments, setAttachments] = useState<Comment.Attachment[]>([])

  useEffect(() => {
    const filesUrlHtml = Array.from(attachments).map((file) => `<img src='${file.url}' /><br/>`).join('')
    setRichComment((prev) => `${prev} ${filesUrlHtml}`)
  }, [attachments])

  useEffect(() => {
    if (entityType === 'customer_notes') {
      setSideEffect(null)
    }
    else {
      setSideEffect(isAdmin ? 'update_status_assigned_task' : 'update_status_replied_task')
    }
  }, [entityType, isAdmin])
  useEffect(() => {
    setRichComment('')
    setDisabled(isThreadClosed ?? false)
  }, [entityId, isThreadClosed])

  const {
    mutationCreateComment,
    mutationCreateCommentUtils
  } = useEntityComments({
    entityId,
    entityType
  })

  const profile: User.UserType | null = useMemo(() => {
    if (user) return user
    return null
  }, [user])

  const isRichCommentActive = useMemo(() => {
    if (entityType === 'customer_notes' && isDesktop) {
      return true
    }
    else {
      return isAdmin || false
    }
  }, [isAdmin, entityType, isDesktop])

  const clearCommentBox = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.value = ''
    }
    setRichComment('')
    setAttachments([])
    setPrivateNote(false)
  }, [setRichComment])

  const sendCommentSpecial = useCallback(async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const comment = isRichCommentActive ? richComment : `<p>${textareaRef?.current?.value}</p>`
      if (entityType === 'user_tasks') {
        await mutationCreateComment({
          variables: {
            receiver_user_id: receiverUserId,
            text: comment,
            thread_entity_id: entityId,
            thread_entity_type: entityType,
            side_effect: 'complete_task'
          }
        }).then(() => {
          setDisabled(true)
          clearCommentBox()
        })
      }
    }
    catch (err) {
      console.error(err)
    }
  }, [isRichCommentActive, richComment, entityType, mutationCreateComment, receiverUserId, entityId, clearCommentBox])

  const sendComment = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    if (isRichCommentActive || (!isRichCommentActive && textareaRef?.current?.value !== '')) {
      const comment = isRichCommentActive ? richComment : `<p>${textareaRef?.current?.value as string}</p>`
      try {
        await mutationCreateComment({
          variables: {
            attachments,
            receiver_user_id: receiverUserId,
            text: comment,
            thread_entity_id: entityId,
            thread_entity_type: entityType,
            private_note: isPrivateNote,
            side_effect: sideEffect
          }
        }).then(() => {
          clearCommentBox()
        })
      }
      catch (err) {
        console.error(err)
      }
    }
  }, [isRichCommentActive, richComment, mutationCreateComment, attachments, receiverUserId, entityId, entityType, sideEffect, clearCommentBox, isPrivateNote])

  const isDisabledSendMsg = useMemo(() => {
    const basicValidation = isDisabled ?? mutationCreateCommentUtils.loading
    if (isRichCommentActive) {
      const richCommentContent = richComment.replace(/(<([^>]+)>)/gi, '')
      return (basicValidation || !richCommentContent || richCommentContent === '')
    }
    else {
      return basicValidation
    }
  }, [richComment, isRichCommentActive, isDisabled, mutationCreateCommentUtils.loading])

  const isDisabledCloseRequest = useMemo(() => {
    return isDisabled ?? mutationCreateCommentUtils.loading
  }, [isDisabled, mutationCreateCommentUtils.loading])

  const QuillRef = useRef<ReactQuill>()

  const imageHandler = useCallback(() => {
    if (QuillRef.current) {
      const editor = QuillRef.current.getEditor()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = () => {
        try {
          const file = input?.files?.[0]
          if (file && /^image\//.test(file.type)) {
            uploadResource(user.id, 'comments', file).then((path) => {
              // @ts-ignore
              editor.insertEmbed(editor.getSelection(), 'image', path)
            }).catch((e) => {
              console.error(e)
            })
          }
          else {
            console.error('You could only upload images.')
          }
        }
        catch (e) {
          console.error(e)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        ['image', 'link'],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],

        [{
          header: 1
        }, {
          header: 2
        }], // custom button values
        [{
          list: 'ordered'
        }, {
          list: 'bullet'
        }],
        // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{
          indent: '-1'
        }, {
          indent: '+1'
        }], // outdent/indent
        [{
          direction: 'rtl'
        }], // text direction

        [{
          size: ['small', false, 'large', 'huge']
        }], // custom dropdown
        [{
          header: [1, 2, 3, 4, 5, 6, false]
        }],

        [{
          color: []
        }, {
          background: []
        }], // dropdown with defaults from theme
        [{
          font: []
        }],
        [{
          align: []
        }]
      ],
      handlers: {
        image: imageHandler
      }
    }
  }), [imageHandler])

  return (
    <Box>
      <DialogConfirm
        isLoading={false}
        open={dialogConfirm}
        setOpen={setDialogConfirm}
        onConfirm={sendCommentSpecial}
      />
      {profile
      && (
        <Stack spacing={2}>
          <Stack
            direction='row'
            sx={{
              width: '100%'
            }}
            spacing={2}
          >
            <ChipUserAvatar user={profile} />
            {
              isRichCommentActive
              && (
                <ContainerQuill sx={{
                  width: '100%',
                  backgroundColor: isPrivateNote ? '#FFFBEB' : 'transparent'
                }}
                >
                  <ReactQuill
                    ref={(element) => {
                      if (element != null) {
                        QuillRef.current = element
                      }
                    }}
                    modules={modules}
                    placeholder={isDisabled ? t(`${entityType}.cannotAddNewComments`) : t(`${entityType}.writeNewComment`)}
                    theme='snow'
                    readOnly={isDisabled}
                    value={richComment}
                    style={{
                      borderRadius: '30px'
                    }}
                    onChange={(val) => setRichComment(val)}
                  />
                  <Attachments
                    sx={{
                      m: 2
                    }}
                    attachments={attachments}
                    setAttachments={setAttachments}
                  />
                </ContainerQuill>
              )
            }
            {
              !isRichCommentActive
              && (
                <Stack
                  direction='column'
                  sx={{
                    width: '100%',
                    backgroundColor: isPrivateNote ? '#FFFBEB' : 'transparent'
                  }}
                >
                  <TextField
                    multiline
                    sx={{
                      width: '100%'
                    }}
                    id='new-comment-task'
                    label={isDisabled ? t(`${entityType}.cannotAddNewComments`) : t(`${entityType}.writeNewComment`)}
                    disabled={isDisabled}
                    rows={4}
                    inputRef={textareaRef}
                  />
                  <Attachments
                    sx={{
                      mt: 2
                    }}
                    attachments={attachments}
                    setAttachments={setAttachments}
                  />
                </Stack>
              )
            }
          </Stack>
          <Stack
            alignItems='center'
            justifyContent='flex-end'
            direction='row'
            sx={{
              width: '100%'
            }}
            spacing={2}
          >
            {isAdmin && entityType === 'user_tasks'
            && (
              <Button
                secondary
                disabled={isDisabledCloseRequest}
                onClick={() => setDialogConfirm(true)}
              >
                {t('user_tasks.action.closeMessage')}
              </Button>
            )}
            {
              isAdmin
              && (
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={isPrivateNote}
                        name='isPrivateNote'
                        onChange={() => setPrivateNote(!isPrivateNote)}
                      />
                    )}
                    label={isDesktop ? 'Private Note' : ''}
                  />
                </FormGroup>
              )
            }
            <Button
              disabled={isDisabledSendMsg}
              onClick={sendComment}
            >
              {t(`${entityType}.action.sendComment`)}
            </Button>
          </Stack>
        </Stack>
      )}
    </Box>
  )
}
