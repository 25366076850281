import { Typography } from '@mui/material'

export function PageTitle({ children, onClick, subtitle }: { children: JSX.Element | React.ReactNode, onClick?: (param?: any) => void, subtitle?: string }) {
  return (
    <span
      style={{
        cursor: 'pointer'
      }}
      onClick={onClick}
    >
      <Typography
        variant='h5'
        sx={{
          fontWeight: 600
        }}
      >
        {children}
      </Typography>
      <Typography color='gray'>
        { subtitle ?? '' }
        {' '}
&nbsp;
      </Typography>
    </span>
  )
}
