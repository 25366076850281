import { useTranslation } from 'react-i18next'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { ownerServices } from '#app/hooks/useOwners'

import FormInput from '../../components/FormInput/FormInput'
import InputWithChips from '../../components/InputWithChips/InputWithChips'
import InputWithPlaces from '../../components/InputWithPlaces/InputWithPlaces'
import NavigationOnboarding from '../../components/NavigationOnboarding/NavigationOnboarding'
import PhoneNumberInput from '../../components/PhoneNumberInput/PhoneNumberInput'
import RadioButtonGroup from '../../components/RadioButtonGroup/RadioButtonGroup'

const validationSchema = (t) => (Yup
  .object()
  .shape({
    phoneNumber: Yup
      .string().test('Is valid Number', t('validation.incorrectPhone'),
        function (value) {
          if (value) {
            return isValidPhoneNumber(value)
          }
        }).required(t('validation.required')),
    services: Yup
      .string().nullable().required(t('validation.required')),
    license: Yup
      .string().required(t('validation.required')),
    name: Yup
      .string().required(t('validation.required')),
    address: Yup.object({
      description: Yup.string(),
      matched_substrings: Yup.array(),
      place_id: Yup.string(),
      reference: Yup.string(),
      structured_formatting: Yup.object({
        main_text: Yup.string(),
        main_text_matched_substrings: Yup.array(),
        secondary_text: Yup.string()
      }).nullable(),
      terms: Yup.array(),
      types: Yup.array()
    }).nullable().required(t('validation.required')),
    deliveryAddress: Yup.object({
      description: Yup.string(),
      matched_substrings: Yup.array(),
      place_id: Yup.string(),
      reference: Yup.string(),
      structured_formatting: Yup.object({
        main_text: Yup.string(),
        main_text_matched_substrings: Yup.array(),
        secondary_text: Yup.string()
      }).nullable(),
      terms: Yup.array(),
      types: Yup.array()
    }).nullable().required(t('validation.required')),
    cities: Yup.array().of(
      Yup.object().shape({
        description: Yup.string(),
        matched_substrings: Yup.array(),
        place_id: Yup.string(),
        reference: Yup.string(),
        structured_formatting: Yup.object({
          main_text: Yup.string(),
          main_text_matched_substrings: Yup.array(),
          secondary_text: Yup.string()
        }).nullable(),
        terms: Yup.array(),
        types: Yup.array()
      })
    ).min(1, t('validation.required')),
    legalEntity: Yup
      .boolean().required(t('validation.required'))
  }))

const simplifiedValidationSchema = (t) => (Yup
  .object()
  .shape({
    cities: Yup.array().of(
      Yup.object().shape({
        description: Yup.string(),
        matched_substrings: Yup.array(),
        place_id: Yup.string(),
        reference: Yup.string(),
        structured_formatting: Yup.object({
          main_text: Yup.string(),
          main_text_matched_substrings: Yup.array(),
          secondary_text: Yup.string()
        }).nullable(),
        terms: Yup.array(),
        types: Yup.array()
      })
    ).min(1, t('validation.required'))
  }))

const BusinessInfoFields = ({ values, fieldNames = {} }) => {
  const { t } = useTranslation()

  return (
    <div className='row g-3 g-md-4'>
      <div className='col-md-6'>
        <FormInput
          label={t('onboarding.businessInformation.name.label')}
          name={fieldNames.name || 'name'}
          placeholder={t('onboarding.businessInformation.name.placeholder')}
        />
      </div>
      <div className='col-md-6'>
        <InputWithPlaces
          label={t('onboarding.businessInformation.address.label')}
          name={fieldNames.address || 'address'}
          placeholder={t('onboarding.businessInformation.address.placeholder')}
        />
      </div>
      <div className='col-md-6'>
        <InputWithPlaces
          label={t('onboarding.businessInformation.deliveryAddress.label')}
          name={fieldNames.deliveryAddress || 'deliveryAddress'}
          placeholder={t('onboarding.businessInformation.deliveryAddress.placeholder')}
        />
      </div>
      <div className='col-md-6'>
        <InputWithPlaces
          multiple
          label={t('onboarding.businessInformation.cities.label')}
          name={fieldNames.cities || 'cities'}
          placeholder={t('onboarding.businessInformation.cities.placeholder')}
          types={['locality']}
          limitTags={5}
        />
      </div>
      <div className='col-md-6'>
        <PhoneNumberInput
          label={t('onboarding.businessInformation.businessNumber.label')}
          name={fieldNames.phoneNumber || 'phoneNumber'}
        />
      </div>
      <div className='col-md-6'>
        <InputWithChips
          label={t('onboarding.businessInformation.services.label')}
          name={fieldNames.services || 'services'}
          options={ownerServices}
        />
      </div>
      <div className='col-md-6'>
        <FormInput
          label={t('onboarding.businessInformation.license.label')}
          name={fieldNames.license || 'license'}
          placeholder={t('onboarding.businessInformation.license.placeholder')}
        />
      </div>
      <div className='col-md-12'>
        <RadioButtonGroup
          name={fieldNames.legalEntity || 'legalEntity'}
          initialValue={values.legalEntity}
          label={t('onboarding.businessInformation.legalEntity.label')}
        >
          <RadioButtonGroup.RadioButton value='true'>{t('booleans.yes')}</RadioButtonGroup.RadioButton>
          <RadioButtonGroup.RadioButton value='false'>{t('booleans.no')}</RadioButtonGroup.RadioButton>
        </RadioButtonGroup>
      </div>
    </div>
  )
}

const SimplifiedBusinessInformation = ({ values, fieldNames = {} }) => {
  const { t } = useTranslation()

  return (
    <div className='row g-3 g-md-4'>
      <div className='col-md-12'>
        <InputWithPlaces
          multiple
          label={t('onboarding.businessInformation.cities.label')}
          name={fieldNames.cities || 'cities'}
          placeholder={t('onboarding.businessInformation.cities.placeholder')}
          types={['locality']}
          limitTags={5}
        />
      </div>
    </div>
  )
}

const BusinessInforForm = ({ onSubmit, information, goBack, ...props }) => {
  const initialValues = information
  const { t } = useTranslation()
  const schema = simplifiedValidationSchema(t)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={(values) => {
        onSubmit(values)
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <Form>
            <SimplifiedBusinessInformation values={values} />
            <NavigationOnboarding
              handleSubmit={handleSubmit}
              goBack={goBack}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export {
  BusinessInfoFields,
  validationSchema
}

export default BusinessInforForm
