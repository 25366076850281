import { useState } from 'react'
import Highlight from 'react-highlight'
import { Box, Card, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material'

import { Btn } from '#app/components/Buttons'
import { Table } from '#app/components/Table'

export function TablesDemo() {
  const [forceMobile, setForceMobile] = useState(false)
  const [mobileMergeExludeLastColumn, setMobileMergeExludeLastColumn] = useState(false)
  const [mobileMergeColumns, setMobileMergeColumns] = useState(false)
  const [mobileColumns, setMobileColumns] = useState('1,2,4')

  const EditRow = () => (
    <Btn.MenuButton
      forceMobile={forceMobile}
      onDelete={() => console.warn('delete')}
      onView={() => console.warn('view')}
      onEdit={() => console.warn('edit')}
    />
  )

  return (
    <Card sx={{
      p: 4
    }}
    >
      <Typography variant='h5'>Tables </Typography>
      <Highlight>
        {`
import { Table } from '#app/components/Table'

const EditRow = () => <Btn.MenuButton
  forceMobile={forceMobile}
  onDelete={() => console.warn('delete')}
  onView={() => console.warn('view')}
  onEdit={() => console.warn('edit')}
/>
<Table.V2
headers={[
  { text: 'City' },
  { text: 'State' },
  { text: 'Temperature' },
  { text: 'Actions', align: 'right' }
]}
${forceMobile ? 'forceMobile' : ''}
${mobileColumns && forceMobile ? `mobileColumns={[${mobileColumns}]}` : ''}
${mobileMergeColumns ? 'mobileMergeColumns' : ''}
${mobileMergeExludeLastColumn ? 'mobileMergeExludeLastColumn' : ''}
structure={{
  rows: [
    {
      key: '1',
      columns: [
        { component: <Box> Miami </Box> },
        { component: <Box> Florida </Box> },
        { component: <Box> 26°C </Box> },
        { component: <Box> <EditRow /> </Box>, align: 'right' }
      ]
    },
    {
      key: '2',
      columns: [
        { component: <Box> Dallas </Box> },
        { component: <Box> Texas </Box> },
        { component: <Box> 22°C </Box> },
        { component: <Box> <EditRow /> </Box>, align: 'right' }
      ]
    }
  ]
}}
/>
        `}
      </Highlight>
      <Grid
        container
        spacing={2}
        mt={1}
        mb={8}
      >
        <Grid item>
          <TextField
            label='mobileColumns'
            variant='outlined'
            disabled={!forceMobile}
            value={mobileColumns}
            onChange={(e) => setMobileColumns(e.target.value)}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={(
              <Switch
                checked={forceMobile}
                onChange={() => setForceMobile(!forceMobile)}
              />
            )}
            label='forceMobile'
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={(
              <Switch
                disabled={!forceMobile}
                checked={mobileMergeColumns}
                onChange={() => setMobileMergeColumns(!mobileMergeColumns)}
              />
            )}
            label='mobileMergeColumns'
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={(
              <Switch
                disabled={!forceMobile}
                checked={mobileMergeExludeLastColumn}
                onChange={() => setMobileMergeExludeLastColumn(!mobileMergeExludeLastColumn)}
              />
            )}
            label='mobileMergeExludeLastColumn'
          />
        </Grid>
      </Grid>
      <Box sx={{
        width: '100%'
      }}
      >
        <Table.V2
          forceMobile={forceMobile}
          mobileColumns={mobileColumns === '' ? undefined : mobileColumns.split(',').map((val: string) => Number(val))}
          mobileMergeColumns={mobileMergeColumns}
          mobileMergeExludeLastColumn={mobileMergeExludeLastColumn}
          headers={[
            {
              text: 'City'
            },
            {
              text: 'State'
            },
            {
              text: 'Temperature'
            },
            {
              text: 'Actions',
              align: 'right'
            }
          ]}
          structure={{
            rows: [
              {
                key: '1',
                columns: [
                  {
                    component: <Box> Miami </Box>
                  },
                  {
                    component: <Box> Florida </Box>
                  },
                  {
                    component: <Box> 26°C </Box>
                  },
                  {
                    component:
                    (
                      <Box>
                        {' '}
                        <EditRow />
                        {' '}
                      </Box>
                    ),
                    align: 'right'
                  }
                ]
              },
              {
                key: '2',
                columns: [
                  {
                    component: <Box> Dallas </Box>
                  },
                  {
                    component: <Box> Texas </Box>
                  },
                  {
                    component: <Box> 22°C </Box>
                  },
                  {
                    component: (
                      <Box>
                        {' '}
                        <EditRow />
                        {' '}
                      </Box>
                    ),
                    align: 'right'
                  }
                ]
              }
            ]
          }}
        />
      </Box>

    </Card>
  )
}
