/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { Box, Checkbox, FormControlLabel, SelectChangeEvent, Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Form, Formik } from 'formik'
import { ChannelMemberResponse, DefaultGenerics, StreamChat } from 'stream-chat'

import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import { DividerExtended } from '#app/components/Divider'
import { Input } from '#app/components/Input'
import { COLORS } from '#app/layouts/theme'
import { useChatListAdminsQuery } from '#app/operations/chat/chat.queries.generated'

interface Props {
  open: boolean
  chatClient: StreamChat<DefaultGenerics> | undefined
  channelTeam: string
  channelId: string
  setOpen: Dispatch<SetStateAction<boolean>>
}

export function AssignChannel({ chatClient, open, channelTeam, channelId, setOpen }: Props) {
  const [newAssign, setNewAssign] = useState<any>(undefined)
  const [onlySameTeam, setOnlySameTeam] = useState<boolean>(true)

  const handleClose = () => {
    setNewAssign(undefined)
    setOnlySameTeam(true)
    setOpen(false)
  }

  const onSubmit = async () => {
    const isUserMember = (
      members: ChannelMemberResponse<DefaultGenerics>[],
      memberId: string
    ) => {
      let res = false
      members.map((member) => {
        if (member.user_id === memberId) res = true
      })
      return res
    }

    if (chatClient && channelId && newAssign?.id) {
      const userId = newAssign?.id
      const channel = chatClient.channel('messaging', channelId)

      const addMemberResponse = await channel.addMembers([userId])
      if (isUserMember(addMemberResponse?.members, userId)) {
        const channelUpdated = await channel.updatePartial({
          set: {
            owners_admin_assignee_user_id: userId,
            owners_admin_assignee_name: newAssign?.name
          },
          unset: ['admin_assignee_user_id']
        })
        if (channelUpdated?.channel?.owners_admin_assignee_user_id === userId) {
          handleClose()
        }
        else {
          // show error
        }
      }
      else {
        // show error
      }
    }
  }

  const { data: dataStream } = useChatListAdminsQuery()

  const onChangeSelect = useCallback((e: SelectChangeEvent) => {
    dataStream?.chatListAdmins?.data?.users?.map((admin: any) => {
      if (admin?.id === e?.target?.value) {
        setNewAssign(admin)
      }
    })
  }, [dataStream])

  const assignmentOptions = useMemo(() => {
    const res = []
    const list = onlySameTeam
      ? dataStream?.chatListAdmins?.data?.users.filter((user: any) => user?.teams?.includes(channelTeam))
      : dataStream?.chatListAdmins?.data?.users

    res.push({
      value: 'placeholder',
      text: 'Select a new admin',
      disabled: true
    })

    list?.map((admin: any) => {
      const opt = {
        value: admin?.id,
        text: admin?.name ? `${admin?.name} (${admin?.teams.toString()})` : admin?.id
      }

      res.push({
        ...opt,
        disabled: !admin?.teams.includes(channelTeam)
      })
    })

    return res
  }, [channelTeam, dataStream?.chatListAdmins?.data?.users, onlySameTeam])

  const Row: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Stack
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      spacing={1}
      mt={2}
    >
      {children}
    </Stack>
  )

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        onClose={handleClose}
      >
        <DialogTitle id='alert-dialog-title'>
          Assign conversation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            You can only assign conversations to people on the same team
            {' '}
            <b>
              {channelTeam}
            </b>
            , the person and the business owner will not receive a notification.
          </DialogContentText>
          <Formik
            initialValues={{
              assignment: null
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Input.Select
                  name='assignment'
                  label='Assignment'
                  options={assignmentOptions}
                  onChange={(e) => onChangeSelect(e)}
                />
                <FormControlLabel
                  label='Show admins from other teams'
                  control={(
                    <Checkbox
                      defaultChecked
                      checked={onlySameTeam !== true}
                      onChange={() => setOnlySameTeam(!onlySameTeam)}
                    />
                  )}
                />
                <Stack my={4}>
                  {newAssign !== undefined
                    ? (
                      <Box
                        borderRadius={1}
                        sx={{
                          backgroundColor: COLORS.custom.green
                        }}
                        px={3}
                        pb={2}
                      >
                        <Row>
                          <b>New assignment:</b>
                          <Typography>
                            {newAssign?.name}
                          </Typography>
                        </Row>
                        <Row>
                          <Typography> Teams: </Typography>
                          <Chip.Group list={newAssign?.teams} />
                        </Row>
                        <DividerExtended />
                        <Row>
                          <Typography> ID:</Typography>
                          <Chip.Clipboard
                            label={`${newAssign?.id}`}
                            clipboard={newAssign?.id}
                          />
                        </Row>
                        <Row>
                          <Typography> Role: </Typography>
                          <Chip.Basic
                            label={newAssign?.role}
                            color='primary'
                          />
                        </Row>
                      </Box>
                    )
                    : (
                      <> </>
                    )}
                </Stack>
                <Btn.Button
                  sx={{
                    mt: 1,
                    float: 'right'
                  }}
                  // disabled={!!newAssign || isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  Confirm
                </Btn.Button>
              </Form>
            )}
          </Formik>

          <Stack
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
          >
            <Button
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}
