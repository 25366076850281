import { SxProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useField } from 'formik'

interface Props {
  options: any[]
  placeholder?: string
  sx?: SxProps
  label?: string
  optionLabelHandler?: (option: any) => any
  defaultValue?: any
  multiple?: boolean
  limit?: number
  freeSolo?: boolean
  name: string
  disabled?: boolean
}

export default function InputWithChips({ options = [], placeholder = '', sx = {}, label, optionLabelHandler, defaultValue = [], multiple, limit, freeSolo = false, disabled = false, ...props }: Props) {
  const [, meta, helpers] = useField(props)
  const { setValue } = helpers
  return (
    <Autocomplete
      multiple={multiple}
      limitTags={limit}
      options={options}
      getOptionLabel={optionLabelHandler}
      freeSolo={freeSolo}
      value={meta.value}
      disabled={disabled}
      defaultValue={defaultValue}
      renderInput={(params) => (
        <>
          { label && <label className='form-label'>{label}</label>}
          <TextField
            sx={sx}
            placeholder={placeholder}
            error={meta.touched && !!meta.error}
            {...params}
          />
          <div className='error'>{ meta.touched && meta.error }</div>
        </>
      )}
      onChange={(_, values) => {
        setValue(values)
      }}
    />
  )
}
