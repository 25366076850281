import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Stack, Typography } from '@mui/material'

import { Btn } from '#app/components/Buttons'
import RightSideBar, { RightSideBarContainer } from '#app/components/RightSideBar/RightSideBar'
import { TableStructureV2, TableV2 } from '#app/components/Table/TableV2'
import { CreateOrEditInvoiceForm } from '#app/feature/Invoices/CreateOrEditInvoiceForm'
import { InvoicesTable } from '#app/feature/Invoices/InvoicesTable/InvoicesTable'
import { useGetInvoicesLazyQuery } from '#app/operations/invoices/invoices.queries.generated'
import { useInvoiceStore } from '#app/store/invoice'
import { UUID } from '#app/types/generic'
import { GET_PROFILE, GET_PROFILE_TYPE } from '#app/utils/graphqlQueries'

interface BusinessCustomersAndInvoicesProps {
  customersTableStructure: TableStructureV2
  onRowClick?: (data?: any) => void
  businessId: UUID
  userId: UUID
}

export const BusinessCustomersAndInvoices = ({ customersTableStructure, onRowClick, businessId, userId }: BusinessCustomersAndInvoicesProps) => {
  const { invoiceQuery, setQuery } = useInvoiceStore()
  const [isCreateInvoiceOpen, setCreateInvoiceOpen] = useState<boolean>(false)

  const [fetchInvoices, { data: invoices, loading: invoicesLoading }] = useGetInvoicesLazyQuery()

  const [fetchUser, { data: profileData, loading: userLoading }] = useLazyQuery<GET_PROFILE_TYPE>(GET_PROFILE, {
    variables: {
      userId
    }
  })

  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    setQuery({
      ...invoiceQuery,
      where: {
        ...invoiceQuery.where,
        business_id: {
          _eq: businessId
        }
      }
    })
  }, [businessId])

  useEffect(() => {
    if (invoiceQuery?.where?.business_id) {
      void Promise.all([
        fetchInvoices({
          variables: invoiceQuery
        }),
        fetchUser()
      ])
    }
  }, [invoiceQuery, userId])

  useEffect(() => {
    if (invoices && profileData && !invoicesLoading && !userLoading) {
      setLoading(false)
    }
  }, [invoicesLoading, userLoading])

  return (
    <>
      <Stack spacing={10}>
        <TableV2
          structure={customersTableStructure}
          sx={{
            backgroundColor: 'white',
            borderRadius: '10px'
          }}
          mobileMergeColumns={true}
          headers={[{
            text: 'Name',
            align: 'left'
          }, {
            text: 'Phone',
            align: 'left'
          }, {
            text: 'Email',
            align: 'left'
          }]}
          onRowClick={onRowClick}
        />
        <Stack spacing={2}>
          <Stack
            direction='row'
            justifyContent='space-between'
          >
            <Typography variant='h6'>Invoices</Typography>
            <Btn.Button onClick={() => setCreateInvoiceOpen(true)}>
              New invoice
            </Btn.Button>
          </Stack>
          <InvoicesTable
            asUser={profileData?.getProfile.user}
            data={invoices}
            loading={isLoading}
          />
        </Stack>
      </Stack>
      <RightSideBar
        isOpen={isCreateInvoiceOpen}
        closeHandler={() => setCreateInvoiceOpen(false)}
      >
        <RightSideBarContainer>
          <CreateOrEditInvoiceForm
            asUser={profileData?.getProfile.user}
            onCallback={() => setCreateInvoiceOpen(false)}
          />
        </RightSideBarContainer>
      </RightSideBar>
    </>
  )
}
