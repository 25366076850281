import { isValidPhoneNumber } from 'react-phone-number-input'
import { Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import PhoneNumberInput from '#app/components/PhoneNumberInput/PhoneNumberInput'

import Button from '../../components/Buttons/Button'
import FormInput from '../../components/FormInput/FormInput'
import useMutationWithNotification from '../../hooks/useMutationWithNotification'
import { UPDATE_USER } from '../../utils/graphqlQueries'

const EditUser = ({ submitCallback, initialValues, ...props }) => {
  const [updateUserById] = useMutationWithNotification(UPDATE_USER)

  const editSchema = Yup
    .object()
    .shape({
      first_name: Yup
        .string()
        .required('This is a required field'),
      last_name: Yup
        .string()
        .required('This is a required field'),
      email: Yup
        .string()
        .email('Invalid email')
        .required('This is a required field'),
      phone: Yup
        .string()
        .nullable()
        .test('isValidPhoneNumber', 'Phone number is invalid',
          // @ts-ignore
          (value) => {
            if (value) {
              return isValidPhoneNumber(value)
            }
            return true
          })
    })

  const onSubmit = async (data) => {
    try {
      const result = await updateUserById({
        variables: data
      })
      submitCallback(result)
    }
    catch (err) {
      submitCallback(undefined, err)
    }
  }

  return (
    <Formik
      validationSchema={editSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {
        ({ initialValues, isSubmitting }) => (
          <Form className='row g-3 g-md-4'>
            <Typography variant='h4'>
              {`Make changes to ${initialValues.first_name} ${initialValues.last_name}`}
            </Typography>
            <div className='col-md-12'>
              <FormInput
                name='first_name'
                type='text'
                label='First Name'
              />
            </div>
            <div className='col-md-12'>
              <FormInput
                name='last_name'
                type='text'
                label='Last Name'
              />
            </div>
            <div className='col-md-12'>
              <FormInput
                name='email'
                type='email'
                label='Email'
                disabled={true}
              />
            </div>
            <div className='col-md-12'>
              <PhoneNumberInput
                name='phone'
                type='text'
                label='Phone Number'
                disabled={!!initialValues.phone}
              />
            </div>
            <div className='div-modal-input mt-4'>
              <Button
                disabled={isSubmitting}
                isLoading={isSubmitting}
                type='submit'
              >
                Save
              </Button>
            </div>
          </Form>
        )
      }
    </Formik>
  )
}

export default EditUser
