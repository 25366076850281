import { useCallback } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'

import { Btn } from '#app/components/Buttons'
import { LoadingCard } from '#app/components/Loading/LoadingCard'
import RightSideBar, { RightSideBarContainer } from '#app/components/RightSideBar/RightSideBar'
import { useCreateUserTaskDomainGeneratorMutation } from '#app/operations/tasks/tasks.mutations.generated'

interface Props {
  userId: string
  isOpen: boolean
  setIsOpen: (val: boolean) => void
}

export function DomainGenerator({ userId, isOpen, setIsOpen }: Props) {
  const [createUserTaskDomain, { loading: loadingGenerate }] = useCreateUserTaskDomainGeneratorMutation()
  const { enqueueSnackbar } = useSnackbar()

  const create = useCallback(async (withCompanyName: boolean) => {
    const res = await createUserTaskDomain({
      variables: {
        withCompanyName,
        bo_id: userId
      }
    })
    if (res) enqueueSnackbar('Task generated correctly, check the timeline', {
      variant: 'success'
    })
  }, [createUserTaskDomain, enqueueSnackbar, userId])

  return (
    <>
      <RightSideBar
        isOpen={isOpen}
        size='md'
        closeHandler={() => { setIsOpen(false) }}
      >
        <RightSideBarContainer footer={<Box />}>
          <>
            <Typography variant='h4'>Domain & Name Generator</Typography>
            <Typography variant='subtitle2'>
              In the path of a BO (
              {userId}
              ) he can receive only one domain and name generator task, from here you can see the conditions to send the task. If an error occurs, it means that it was already sent to you in the past or we do not have the necessary information in altBO.
            </Typography>
            <Box>
              <Box mt={4}>
                <Typography variant='subtitle2'>
                  From the &apos;altBo&apos; database, it checks if:
                </Typography>
                <ol>
                  <li>
                    <strong>→ The company is in the onboarding stage</strong>
                    {' '}
                    (field &apos;stage&apos;=&apos;onboarding&apos; in the &apos;companies&apos; table).
                  </li>
                  <li>
                    <strong>→ A city has been specified</strong>
                    {' '}
                    (field &apos;city&apos; in the &apos;companies&apos; table).
                  </li>
                  <li>
                    <strong>→ The company&apos;s domain is either undefined or null</strong>
                    {' '}
                    (field &apos;domain&apos; in the &apos;companies&apos; table).
                  </li>
                  <li>
                    <strong>→ At least one service has been defined in the recurring category</strong>
                    {' '}
                    (field &apos;category_recurring&apos; in the &apos;companies&apos; table).
                  </li>
                </ol>
                {loadingGenerate
                && (
                  <Stack mt={4}>
                    <LoadingCard />
                  </Stack>
                )}
                <Stack
                  direction='row'
                  mt={4}
                  spacing={4}
                >
                  <Btn.Button
                    disabled={loadingGenerate}
                    onClick={async () => await create(true)}
                  >
                    Create domain with company name
                  </Btn.Button>
                  <Btn.Button
                    disabled={loadingGenerate}
                    onClick={async () => await create(false)}
                  >
                    Create only domain
                  </Btn.Button>
                </Stack>
              </Box>
            </Box>
          </>
        </RightSideBarContainer>
      </RightSideBar>
    </>
  )
}
