import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Box } from '@mui/material'

import { useStreamChatList } from '#app/v2-core/hooks/useStreamChatList'

import { ChatChannel } from '..'

import { DrawerChannelList } from './DrawerChannelList'
import { DrawerToolKit } from './DrawerToolKit'

import 'stream-chat-react/dist/css/v2/index.css'

export function Superchat() {
  const [searchParams] = useSearchParams()
  const [channelId, setChannelId] = useState<string | null>(null)
  const { userId } = useParams()

  const { chatClient } = useStreamChatList({
    channelUrl: userId ? `/chat/user/${userId}?chID={{channelId}}` : '/chat?chID={{channelId}}'
  })

  useEffect(() => {
    const chID = searchParams.get('chID')
    if (chID && chID !== channelId) {
      setChannelId(chID)
    }
  }, [channelId, searchParams])

  return (
    <Box sx={{
      display: 'flex',
      height: 'calc(100vh - 50px)'
    }}
    >
      <DrawerChannelList />
      <Box sx={{
        flexGrow: 1,
        height: 'calc(100% - 0px)',
        width: '100%',
        overflow: 'auto',
      }}
      >
        {channelId
        && <ChatChannel channelId={channelId} />}
      </Box>
      { channelId
      && (
        <DrawerToolKit
          chatClient={chatClient}
          channelId={channelId}
        />
      )}
    </Box>
  )
}
