import { useTranslation } from 'react-i18next'
import { BsTrash } from 'react-icons/bs'
import { NumericFormat } from 'react-number-format'
import { Grid, IconButton, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'

import { THEME } from '#app/layouts/theme'
import { InvoiceLineItem, InvoiceState } from '#app/store/invoice'

interface Props {
  invoice: InvoiceState['createInvoice'] | InvoiceState['editInvoice']
  onRemoveLineItem: (lineItem: InvoiceLineItem) => void
}

export const InvoiceLineItems = ({ invoice, onRemoveLineItem }: Props) => {
  const { t } = useTranslation()

  function DeleteIcon({ lineItem }: { lineItem: InvoiceLineItem }) {
    return (
      <IconButton
        edge='end'
        onClick={() => onRemoveLineItem(lineItem)}
      >
        <BsTrash />
      </IconButton>
    )
  }

  return (
    <Stack spacing={2}>
      <Typography fontWeight='bold'>
        {t('banking.invoices.create.details', 'Details')}
      </Typography>
      {
        invoice
          ?.metadata
          ?.lineItems.length
          ? (
            <List>
              {
                invoice
                  ?.metadata
                  ?.lineItems
                  .map((lineItem, idx) => (
                    <ListItem
                      key={idx}
                      sx={{
                        backgroundColor: THEME.COLORS.light.secondary,
                        borderRadius: 1,
                        marginBottom: 1
                      }}
                      secondaryAction={
                        invoice.status === 'created' && <DeleteIcon lineItem={lineItem} />
                      }
                    >
                      <ListItemText
                        primary={(
                          <Grid
                            container
                            wrap='nowrap'
                          >
                            <Grid
                              item
                              zeroMinWidth
                              xs
                            >
                              <Typography>
                                {lineItem.description}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        secondary={(
                          <>
                            <Stack
                              sx={{
                                marginTop: 1
                              }}
                              spacing={3}
                              direction='row'
                            >
                              <Stack>
                                <Typography variant='caption'>{t('banking.invoices.create.lineItem.quantity', 'Quantity')}</Typography>
                                <Typography
                                  fontSize='small'
                                  fontWeight='bold'
                                >
                                  {lineItem.quantity }
                                </Typography>
                              </Stack>
                              <Stack>
                                <Typography variant='caption'>{t('banking.invoices.create.lineItem.unitCost', 'Unit')}</Typography>
                                <NumericFormat
                                  fixedDecimalScale={true}
                                  decimalScale={2}
                                  thousandSeparator=','
                                  style={{
                                    fontSize: 'small',
                                    fontWeight: 'bold'
                                  }}
                                  displayType='text'
                                  value={(lineItem?.unitCost ?? 0) / 100}
                                  prefix='$'
                                />
                              </Stack>
                              <Stack>
                                <Typography variant='caption'>{t('banking.invoices.create.lineItem.total', 'Total')}</Typography>
                                <NumericFormat
                                  fixedDecimalScale={true}
                                  decimalScale={2}
                                  thousandSeparator=','
                                  style={{
                                    fontSize: 'small',
                                    fontWeight: 'bold'
                                  }}
                                  displayType='text'
                                  value={(lineItem?.total ?? 0) / 100}
                                  prefix='$'
                                />
                              </Stack>
                            </Stack>
                          </>
                        )}
                      />
                    </ListItem>
                  ))
              }
            </List>
          )
          : (
            <List>
              <ListItem sx={{
                backgroundColor: THEME.COLORS.light.secondary,
                borderRadius: 1,
                marginBottom: 1
              }}
              >
                <ListItemText
                  primary={t('banking.invoices.create.noLineItemsPrimary', 'There are no items')}
                  secondary={t('banking.invoices.create.noLineItemsSecondary', 'Use the section below to add items')}
                />
              </ListItem>
            </List>
          )
      }
    </Stack>
  )
}
