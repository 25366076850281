import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'

import { Star1 } from '../assets/Icons'
import { cn } from '../libs/utils'

import { Button } from './ui/button'
import { DialogClose } from './ui/dialog'
import { Input } from './ui/input'
import { Label } from './ui/label'
import { RadioGroup, RadioGroupItem } from './ui/radio-group'

interface Props {
  rating?: {
    score: string
    explanation: string
  }
}

export const RateTaskDialog = ({ rating }: Props) => {
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={{
        score: '',
        explanation: ''
      }}
      onSubmit={() => { /*  TODO: submit rating */ }}
    >
      <RadioGroup
        defaultValue={rating?.score}
        className='peer flex flex-row flex-wrap justify-center gap-5'
      >
        <RadioGroupItem
          value='5'
          id='5'
          className='peer/five sr-only'
        />
        <Label
          htmlFor='5'
          className='group order-5'
        >
          <Star1 className={cn('size-8 cursor-pointer fill-[#D9D9D9] text-[#D9D9D9] transition-colors hover:animate-jump-in hover:fill-[#FFBB00] hover:text-[#FFBB00] peer-data-[state=checked]/five:group-[]:fill-[#FFBB00] peer-data-[state=checked]/five:group-[]:text-[#FFBB00]', {})} />
        </Label>
        <RadioGroupItem
          value='4'
          id='4'
          className='peer/four sr-only'
        />
        <Label
          htmlFor='4'
          className='group order-4'
        >
          <Star1 className={cn('size-8 cursor-pointer fill-[#D9D9D9] text-[#D9D9D9] transition-colors hover:animate-jump-in hover:fill-[#FFBB00] hover:text-[#FFBB00] peer-hover/five:group-[]:fill-[#FFBB00] peer-hover/five:group-[]:text-[#FFBB00] peer-data-[state=checked]/five:group-[]:fill-[#FFBB00] peer-data-[state=checked]/four:group-[]:fill-[#FFBB00] peer-data-[state=checked]/five:group-[]:text-[#FFBB00] peer-data-[state=checked]/four:group-[]:text-[#FFBB00]', {})} />
        </Label>
        <RadioGroupItem
          value='3'
          id='3'
          className='peer/three sr-only'
        />
        <Label
          htmlFor='3'
          className='group order-3'
        >
          <Star1 className={cn('size-8 cursor-pointer fill-[#D9D9D9] text-[#D9D9D9] transition-colors hover:animate-jump-in hover:fill-[#FFBB00] hover:text-[#FFBB00] peer-hover/five:group-[]:fill-[#FFBB00] peer-hover/four:group-[]:fill-[#FFBB00] peer-hover/five:group-[]:text-[#FFBB00] peer-hover/four:group-[]:text-[#FFBB00] peer-data-[state=checked]/five:group-[]:fill-[#FFBB00] peer-data-[state=checked]/four:group-[]:fill-[#FFBB00] peer-data-[state=checked]/three:group-[]:fill-[#FFBB00] peer-data-[state=checked]/five:group-[]:text-[#FFBB00] peer-data-[state=checked]/four:group-[]:text-[#FFBB00] peer-data-[state=checked]/three:group-[]:text-[#FFBB00]', {})} />
        </Label>
        <RadioGroupItem
          value='2'
          id='2'
          className='peer/two sr-only'
        />
        <Label
          htmlFor='2'
          className='group order-2'
        >
          <Star1 className={cn('size-8 cursor-pointer fill-[#D9D9D9] text-[#D9D9D9] transition-colors hover:animate-jump-in hover:fill-[#FFBB00] hover:text-[#FFBB00] peer-hover/five:group-[]:fill-[#FFBB00] peer-hover/four:group-[]:fill-[#FFBB00] peer-hover/three:group-[]:fill-[#FFBB00] peer-hover/five:group-[]:text-[#FFBB00] peer-hover/four:group-[]:text-[#FFBB00] peer-hover/three:group-[]:text-[#FFBB00] peer-data-[state=checked]/five:group-[]:fill-[#FFBB00] peer-data-[state=checked]/four:group-[]:fill-[#FFBB00] peer-data-[state=checked]/three:group-[]:fill-[#FFBB00] peer-data-[state=checked]/two:group-[]:fill-[#FFBB00] peer-data-[state=checked]/five:group-[]:text-[#FFBB00] peer-data-[state=checked]/four:group-[]:text-[#FFBB00] peer-data-[state=checked]/three:group-[]:text-[#FFBB00] peer-data-[state=checked]/two:group-[]:text-[#FFBB00]', {})} />
        </Label>
        <RadioGroupItem
          value='1'
          id='1'
          className='peer/one sr-only'
        />
        <Label
          htmlFor='1'
          className='group order-1'
        >
          <Star1 className={cn('size-8 cursor-pointer fill-[#D9D9D9] text-[#D9D9D9] transition-colors hover:animate-jump-in hover:fill-[#FFBB00] hover:text-[#FFBB00] peer-hover/five:group-[]:fill-[#FFBB00] peer-hover/four:group-[]:fill-[#FFBB00] peer-hover/three:group-[]:fill-[#FFBB00] peer-hover/two:group-[]:fill-[#FFBB00] peer-hover/five:group-[]:text-[#FFBB00] peer-hover/four:group-[]:text-[#FFBB00] peer-hover/three:group-[]:text-[#FFBB00] peer-hover/two:group-[]:text-[#FFBB00] peer-data-[state=checked]/five:group-[]:fill-[#FFBB00] peer-data-[state=checked]/four:group-[]:fill-[#FFBB00] peer-data-[state=checked]/one:group-[]:fill-[#FFBB00] peer-data-[state=checked]/three:group-[]:fill-[#FFBB00] peer-data-[state=checked]/two:group-[]:fill-[#FFBB00] peer-data-[state=checked]/five:group-[]:text-[#FFBB00] peer-data-[state=checked]/four:group-[]:text-[#FFBB00] peer-data-[state=checked]/one:group-[]:text-[#FFBB00] peer-data-[state=checked]/three:group-[]:text-[#FFBB00] peer-data-[state=checked]/two:group-[]:text-[#FFBB00]', {})} />
        </Label>
        <div
          className='invisible absolute order-6 grid w-full gap-3 opacity-0 transition-all duration-300 peer-data-[state=checked]/five:visible peer-data-[state=checked]/four:visible peer-data-[state=checked]/one:visible peer-data-[state=checked]/three:visible peer-data-[state=checked]/two:visible peer-data-[state=checked]/five:static peer-data-[state=checked]/four:static peer-data-[state=checked]/one:static peer-data-[state=checked]/three:static peer-data-[state=checked]/two:static peer-data-[state=checked]/five:opacity-100 peer-data-[state=checked]/four:opacity-100 peer-data-[state=checked]/one:opacity-100 peer-data-[state=checked]/three:opacity-100 peer-data-[state=checked]/two:opacity-100'
        >
          <Label htmlFor='description'>{t('v2.tasks.rating.question', 'What is the reason for your score?')}</Label>
          <Input
            name='description'
            defaultValue={rating?.explanation}
            rows={3}
            min={2}
            component='textarea'
            placeholder='Type your message here.'
            id='description'
            className='h-auto'
          />
          <DialogClose asChild>
            <Button className='mt-5 justify-self-center'>{t('generic.save', 'Save')}</Button>
          </DialogClose>
        </div>
      </RadioGroup>
    </Formik>
  )
}
