import { WizardOption } from '../types'

interface FormWizardProps {
  options?: any
  summaryResponses?: any
  title?: string
  onChange?: (option: WizardOption, optionKey: string, value: string) => void
}

export const DirectionFormV2 = ({ options, onChange, title, summaryResponses }: FormWizardProps) => {
  return (
    <form className='space-y-2.5 rounded-xl bg-white px-3.5 py-5 shadow'>
      <label htmlFor='direccion'>{title}</label>
      <div className='grid grid-cols-2 gap-3'>
        {
          options?.map((element: WizardOption) => {
            if (element.option_attributes.fullWidth) {
              if (element.option_type === 'InputForm')
                return (
                  <input
                    type='text'
                    placeholder={element.option_attributes.placeholder}
                    name={element.option_attributes.option_key}
                    value={summaryResponses?.response?.[element.option_key]}
                    className='col-span-2 rounded-lg border border-[#D9D9D9] px-4 py-2.5 text-[#0F172A] placeholder:text-[#454545]/40 focus-visible:outline-[#8BC53F]'
                    onChange={(e) => {
                      if (onChange)
                        onChange(element, element.option_key, e.target.value)
                    }}
                  />
                )
            }
            else {
              if (element.option_type === 'InputForm')
                return (
                  <input
                    type='text'
                    placeholder={element.option_attributes.placeholder}
                    name={element.option_attributes.option_key}
                    value={summaryResponses?.response?.[element.option_key]}
                    className='rounded-lg border border-[#D9D9D9] px-4 py-2.5 text-[#0F172A] placeholder:text-[#454545]/40 focus-visible:outline-[#8BC53F]'
                    onChange={(e) => {
                      if (onChange)
                        onChange(element, element.option_key, e.target.value)
                    }}
                  />
                )
            }
          })
        }
      </div>
    </form>
  )
}
