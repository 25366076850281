import { useTranslation } from 'react-i18next'

import Images from '#app/v2/assets/Images'

export const FinishedNotifications = () => {
  const { t } = useTranslation()
  return (
    <div className='mx-auto flex max-w-96 items-center gap-1.5 rounded-xl bg-white p-2 shadow'>
      <img
        src={Images.NotificationAchievment}
        className=' h-[5.4375rem] w-[5.3125rem]'
      />
      <div className='space-y-3.5 text-[#304985]'>
        <h3 className='text-lg/5 font-semibold '>{t('v2.home.notifications.done.title', 'All done!')}</h3>
        <p className='text-base/5 font-medium'>{t('v2.home.notifications.done.description', 'You are up to date!')}</p>
      </div>
    </div>
  )
}
