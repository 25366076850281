import { Divider, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import Button from '#app/components/Buttons/Button'
import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import { Task, User } from '#app/types'

import { PreviewTaskContent } from './PreviewTaskContent'

import 'react-quill/dist/quill.snow.css'

interface Props {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  currentOwner?: User.UserType
  task?: Task.UserTask | null
}

export function ViewTask({ isOpen, setIsOpen, currentOwner, task }: Props) {
  return (
    <RightSideBar
      isOpen={isOpen}
      closeHandler={() => setIsOpen(false)}
      size='md'
    >
      <>
        <Typography variant='h4'>
          {task?.title}
          {' '}
          |
          <span>
            {currentOwner?.first_name}
            {' '}
            {currentOwner?.last_name}
            {' '}
            (
            {currentOwner?.email}
            )
          </span>
        </Typography>
        { task
        && (
          <Box>
            <PreviewTaskContent
              adminSide
              task={task}
            />
            <Divider sx={{
              mt: 3,
              mb: 3
            }}
            />
            <Button
              primary={false}
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button>
          </Box>
        )}
      </>
    </RightSideBar>
  )
}
