import { useState } from 'react'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'

import { useIsDesktopSize } from '../../utils/mediaQueries'
import Information from '../icons/Information'

interface Props {
  label: string
  popoverText?: string
}

const FormLabel = ({ label, popoverText }: Props) => {
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const isDesktop = useIsDesktopSize()
  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'mouse-over-popover' : undefined
  const pointerEvents = isDesktop ? 'none' : 'auto'
  return (
    <div>
      <div>
        <label className='form-label d-flex align-items-center'>
          {label}
          { (popoverText != null)
          && (
            <Information
              className='ms-1'
              style={{
                cursor: 'pointer'
              }}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              onClick={handlePopoverOpen}
            />
          )}
        </label>
      </div>
      <Popover
        id={id}
        sx={{
          pointerEvents
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
      >
        <Typography sx={{
          p: 1
        }}
        >
          { popoverText }
        </Typography>
      </Popover>
    </div>
  )
}

export default FormLabel
