import { useReducer } from 'react'
import { isEqual, keys } from 'lodash'

const getFormValues = (section) => {
  const formFields = Object.keys(section)
  const formFieldsWithDefault = formFields.reduce((acc, field) => ({
    ...acc,
    [field]: section[field].value
  }), {})
  return formFieldsWithDefault
}

const setSectionFormat = (section) => {
  return keys(section)
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: {
          value: section[curr],
          lastUpdate: new Date().toISOString()
        }
      }
    }, {})
}

const getChanges = (section, values) => {
  const initialValues = getFormValues(section)
  const formFields = Object.keys(initialValues)

  return formFields
    .filter((field) => {
      const previousVal = initialValues[field]
      const currentVal = values[field]
      return !isEqual(previousVal, currentVal)
    })
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: {
          value: values[curr],
          lastUpdate: new Date().toISOString()
        }
      }
    }, {})
}

function onBoardingReducer(state, action) {
  let section, sectionName

  switch (action.type) {
    case 'SET_INITIAL_STATE': {
      return {
        ...action.values
      }
    }
    case 'SET_ONBOARDING': {
      const sectionNames = keys(action.values)
      const sections = sectionNames
        .reduce((acc, sectionName) => ({
          ...acc,
          [sectionName]: setSectionFormat(action.values[sectionName])
        }), {})

      return {
        ...state,
        sections: {
          ...state.sections,
          ...sections
        }
      }
    }
    case 'SET_PERSONAL_INFORMATION': {
      sectionName = 'personalInformation'
      section = state.sections.personalInformation
      break
    }
    case 'SET_BUSINESS_INFORMATION': {
      sectionName = 'businessInformation'
      section = state.sections.businessInformation
      break
    }
    case 'SET_OTHERS': {
      sectionName = 'others'
      section = state.sections.others
      break
    }
    case 'SET_BRANDING': {
      sectionName = 'branding'
      section = state.sections.branding
      break
    }
    case 'SET_MARKETING': {
      sectionName = 'marketing'
      section = state.sections.marketing
      break
    }
    case 'SET_SOCIAL_MEDIA': {
      sectionName = 'socialMedia'
      section = state.sections.socialMedia
      break
    }
    case 'SET_WEB_INFORMATION': {
      sectionName = 'webInformation'
      section = state.sections.webInformation
      break
    }
    default:
      break
  }

  const changedFields = getChanges(section, action.values)
  const hasChanges = !!Object.keys(changedFields).length

  if (hasChanges) {
    const previousValues = state.sections[sectionName]
    const sectionChange = {
      [sectionName]: {
        ...previousValues,
        ...changedFields
      }
    }

    return {
      ...state,
      sections: {
        ...state.sections,
        ...sectionChange
      }
    }
  }

  return {
    ...state
  }
}

function useOnboardingFormReducer(onboardingState) {
  const [onboarding, dispatch] = useReducer(onBoardingReducer, onboardingState)
  return [onboarding, dispatch]
}

export {
  useOnboardingFormReducer,
  getFormValues
}
