import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type GetBusinessPublicDetailsQueryVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['uuid']>
  businessId?: Types.InputMaybe<Types.Scalars['uuid']>
}>

export type GetBusinessPublicDetailsQuery = { __typename?: 'query_root', getPublicBusiness?: { __typename?: 'GetPublicBusinessPayload', business?: any | undefined } | undefined }

export type CreateDemoSalesSiteMutationVariables = Types.Exact<{
  name: Types.Scalars['String']
  salesEmail: Types.Scalars['String']
  companyName: Types.Scalars['String']
  personalEmail: Types.Scalars['String']
  color1: Types.Scalars['String']
  recurringCategory: Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>
  serviceAreasNames: Array<Types.InputMaybe<Types.Scalars['jsonb']>> | Types.InputMaybe<Types.Scalars['jsonb']>
}>

export type CreateDemoSalesSiteMutation = { __typename?: 'mutation_root', createDemoSalesSite?: { __typename?: 'CreateDemoSalesSiteOutput', result?: boolean | undefined, demoSiteId?: string | undefined } | undefined }

export type GetDemoSiteQueryVariables = Types.Exact<{
  siteId: Types.Scalars['String']
  salesEmail?: Types.InputMaybe<Types.Scalars['String']>
}>

export type GetDemoSiteQuery = { __typename?: 'query_root', getDemoSite?: { __typename?: 'GetDemoSitePayload', result?: boolean | undefined } | undefined }

export const GetBusinessPublicDetailsDocument = gql`
    query getBusinessPublicDetails($userId: uuid, $businessId: uuid) {
  getPublicBusiness(input: {user_id: $userId, business_id: $businessId}) {
    business
  }
}
    `

/**
 * __useGetBusinessPublicDetailsQuery__
 *
 * To run a query within a React component, call `useGetBusinessPublicDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessPublicDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessPublicDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useGetBusinessPublicDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessPublicDetailsQuery, GetBusinessPublicDetailsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetBusinessPublicDetailsQuery, GetBusinessPublicDetailsQueryVariables>(GetBusinessPublicDetailsDocument, options)
}
export function useGetBusinessPublicDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessPublicDetailsQuery, GetBusinessPublicDetailsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetBusinessPublicDetailsQuery, GetBusinessPublicDetailsQueryVariables>(GetBusinessPublicDetailsDocument, options)
}
export type GetBusinessPublicDetailsQueryHookResult = ReturnType<typeof useGetBusinessPublicDetailsQuery>
export type GetBusinessPublicDetailsLazyQueryHookResult = ReturnType<typeof useGetBusinessPublicDetailsLazyQuery>
export type GetBusinessPublicDetailsQueryResult = Apollo.QueryResult<GetBusinessPublicDetailsQuery, GetBusinessPublicDetailsQueryVariables>
export const CreateDemoSalesSiteDocument = gql`
    mutation createDemoSalesSite($name: String!, $salesEmail: String!, $companyName: String!, $personalEmail: String!, $color1: String!, $recurringCategory: [String]!, $serviceAreasNames: [jsonb]!) {
  createDemoSalesSite(
    input: {name: $name, salesEmail: $salesEmail, companyName: $companyName, personalEmail: $personalEmail, color1: $color1, recurringCategory: $recurringCategory, serviceAreasNames: $serviceAreasNames}
  ) {
    result
    demoSiteId
  }
}
    `
export type CreateDemoSalesSiteMutationFn = Apollo.MutationFunction<CreateDemoSalesSiteMutation, CreateDemoSalesSiteMutationVariables>

/**
 * __useCreateDemoSalesSiteMutation__
 *
 * To run a mutation, you first call `useCreateDemoSalesSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDemoSalesSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDemoSalesSiteMutation, { data, loading, error }] = useCreateDemoSalesSiteMutation({
 *   variables: {
 *      name: // value for 'name'
 *      salesEmail: // value for 'salesEmail'
 *      companyName: // value for 'companyName'
 *      personalEmail: // value for 'personalEmail'
 *      color1: // value for 'color1'
 *      recurringCategory: // value for 'recurringCategory'
 *      serviceAreasNames: // value for 'serviceAreasNames'
 *   },
 * });
 */
export function useCreateDemoSalesSiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateDemoSalesSiteMutation, CreateDemoSalesSiteMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<CreateDemoSalesSiteMutation, CreateDemoSalesSiteMutationVariables>(CreateDemoSalesSiteDocument, options)
}
export type CreateDemoSalesSiteMutationHookResult = ReturnType<typeof useCreateDemoSalesSiteMutation>
export type CreateDemoSalesSiteMutationResult = Apollo.MutationResult<CreateDemoSalesSiteMutation>
export type CreateDemoSalesSiteMutationOptions = Apollo.BaseMutationOptions<CreateDemoSalesSiteMutation, CreateDemoSalesSiteMutationVariables>
export const GetDemoSiteDocument = gql`
    query getDemoSite($siteId: String!, $salesEmail: String) {
  getDemoSite(input: {siteId: $siteId, salesEmail: $salesEmail}) {
    result
  }
}
    `

/**
 * __useGetDemoSiteQuery__
 *
 * To run a query within a React component, call `useGetDemoSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDemoSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDemoSiteQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      salesEmail: // value for 'salesEmail'
 *   },
 * });
 */
export function useGetDemoSiteQuery(baseOptions: Apollo.QueryHookOptions<GetDemoSiteQuery, GetDemoSiteQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetDemoSiteQuery, GetDemoSiteQueryVariables>(GetDemoSiteDocument, options)
}
export function useGetDemoSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDemoSiteQuery, GetDemoSiteQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetDemoSiteQuery, GetDemoSiteQueryVariables>(GetDemoSiteDocument, options)
}
export type GetDemoSiteQueryHookResult = ReturnType<typeof useGetDemoSiteQuery>
export type GetDemoSiteLazyQueryHookResult = ReturnType<typeof useGetDemoSiteLazyQuery>
export type GetDemoSiteQueryResult = Apollo.QueryResult<GetDemoSiteQuery, GetDemoSiteQueryVariables>
