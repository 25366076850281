import { useState } from 'react'
import Highlight from 'react-highlight'
import { BsFillBugFill } from 'react-icons/bs'
import { Card, FormControlLabel, Grid, Switch, Typography } from '@mui/material'

import { Chip } from '#app/components/Chip'
import { useEntityUser } from '#app/hooks/useEntityUser'

export function ChipsDemo() {
  const [withOnClick, setWithOnClick] = useState(false)
  const { user } = useEntityUser()

  return (
    <Card sx={{
      p: 4
    }}
    >
      <Typography variant='h5'>
        Chips Basic -
        {withOnClick ? 'with' : 'without'}
        {' '}
        onClick
        {' '}
      </Typography>
      <Highlight>
        {`
import { Chip } from '#app/components/Chip'

<Chip.Basic ${withOnClick ? 'onClick={() => console.info("test")}' : ''} label='Without Color' />
<Chip.Basic ${withOnClick ? 'onClick={() => console.info("test")}' : ''} label='With Icon' icon={<BsFillBugFill/>}/>
<Chip.Basic ${withOnClick ? 'onClick={() => console.info("test")}' : ''} label='Error' color='error'/>
<Chip.Basic ${withOnClick ? 'onClick={() => console.info("test")}' : ''} label='Info' color='info'/>
<Chip.Basic ${withOnClick ? 'onClick={() => console.info("test")}' : ''} label='Primary' color='primary'/>
<Chip.Basic ${withOnClick ? 'onClick={() => console.info("test")}' : ''} label='Secondary' color='secondary'/>
<Chip.Basic ${withOnClick ? 'onClick={() => console.info("test")}' : ''} label='Success' color='success'/>
<Chip.Basic ${withOnClick ? 'onClick={() => console.info("test")}' : ''} label='Warning' color='warning'/>
<Chip.Group list={['first', 'second', 'thrid', 'fourth']} />
        `}
      </Highlight>
      <Grid
        container
        spacing={2}
        mt={1}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          <FormControlLabel
            control={(
              <Switch
                checked={withOnClick}
                onChange={() => setWithOnClick(!withOnClick)}
              />
            )}
            label='with onClick'
          />
        </Grid>
        <Grid item>
          <Chip.Basic
            label='Without Color'
            onClick={withOnClick ? () => console.info('test') : undefined}
          />
        </Grid>
        <Grid item>
          <Chip.Basic
            icon={<BsFillBugFill />}
            label='With Icon'
            onClick={withOnClick ? () => console.info('test') : undefined}
          />
        </Grid>
        <Grid item>
          <Chip.Basic
            label='Error'
            color='error'
            onClick={withOnClick ? () => console.info('test') : undefined}
          />
        </Grid>
        <Grid item>
          <Chip.Basic
            label='Info'
            color='info'
            onClick={withOnClick ? () => console.info('test') : undefined}
          />
        </Grid>
        <Grid item>
          <Chip.Basic
            label='Primary'
            color='primary'
            onClick={withOnClick ? () => console.info('test') : undefined}
          />
        </Grid>
        <Grid item>
          <Chip.Basic
            label='Secondary'
            color='secondary'
            onClick={withOnClick ? () => console.info('test') : undefined}
          />
        </Grid>
        <Grid item>
          <Chip.Basic
            label='Success'
            color='success'
            onClick={withOnClick ? () => console.info('test') : undefined}
          />
        </Grid>
        <Grid item>
          <Chip.Basic
            label='Warning'
            color='warning'
            onClick={withOnClick ? () => console.info('test') : undefined}
          />
        </Grid>
        <Grid item>
          <Chip.Group list={['first', 'second', 'thrid', 'fourth']} />
        </Grid>
      </Grid>

      <Typography variant='h5'>Chips Predifined </Typography>
      <Highlight>
        {`
import { Chip } from '#app/components/Chip'

<Chip.Support />
<Chip.Error />
<Chip.Language lang='es' />
<Chip.Language lang='en' />
<Chip.Language lang='ru' />
<Chip.Filter label='#marketing' onDelete={() => console.info('onDelete!')} onClick={() => console.info('onClick!')} />
<Chip.TaskTemplate templateID='id-wrong' />
        `}
      </Highlight>
      <Grid
        container
        spacing={2}
        mt={1}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          <Chip.Support />
        </Grid>
        <Grid item>
          <Chip.Error />
        </Grid>
        <Grid item>
          <Chip.Language lang='es' />
        </Grid>
        <Grid item>
          <Chip.Language lang='en' />
        </Grid>
        <Grid item>
          <Chip.Language lang='ru' />
        </Grid>
        <Grid item>
          <Chip.Filter
            label='#marketing'
            onDelete={() => console.info('onDelete!')}
          />
        </Grid>
        <Grid item>
          <Chip.TaskTemplate templateID='id-wrong' />
        </Grid>
      </Grid>

      <Typography variant='h5'>Chips Sequence Status </Typography>
      <Highlight>
        {`
import { Chip } from '#app/components/Chip'

<Chip.SequenceStatus status='pending' />
<Chip.SequenceStatus status='in_queue' />
<Chip.SequenceStatus status='in_progress' />
<Chip.SequenceStatus status='completed' />
        `}
      </Highlight>
      <Grid
        container
        spacing={2}
        mt={1}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          <Chip.SequenceStatus status='pending' />
        </Grid>
        <Grid item>
          <Chip.SequenceStatus status='in_queue' />
        </Grid>
        <Grid item>
          <Chip.SequenceStatus status='in_progress' />
        </Grid>
        <Grid item>
          <Chip.SequenceStatus status='completed' />
        </Grid>
      </Grid>

      <Typography variant='h5'>Chips User</Typography>
      <Highlight>
        {`
import { Chip } from '#app/components/Chip'

const { user } = useEntityUser()

<Chip.User user={user}/>
<Chip.UserAvatar user={user}/>
<Chip.UserEmail userData={{ email: 'tony@joinowners.com', id: 'id1234' }}/>
        `}
      </Highlight>
      <Grid
        container
        spacing={2}
        mt={1}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          {user
          && <Chip.UserAvatar user={user} />}
        </Grid>

        <Grid item>
          { user
          && <Chip.User user={user} />}
        </Grid>

        <Grid item>
          <Chip.UserEmail userData={{
            email: 'tony@joinowners.com',
            id: 'id1234'
          }}
          />
        </Grid>
      </Grid>

    </Card>
  )
}
