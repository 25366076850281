import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useMutation } from '@apollo/client'
import { Stack, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Btn } from '#app/components/Buttons'
import FormInput from '#app/components/FormInput/FormInput'
import PhoneNumberInput from '#app/components/PhoneNumberInput/PhoneNumberInput'
import { useShareInvoiceMutation } from '#app/operations/invoices/invoices.mutations.generated'
import { InvoiceDetails } from '#app/store/invoice'
import { User } from '#app/types'
import { MUTATION } from '#app/utils/graphqlQueries'

export interface MissingCustomerDataFormProps {
  invoice: InvoiceDetails
  user?: User.UserType
  onSubmit: () => any
  onCancel: () => any
  type: 'email' | 'phone'
}

export interface EmailFormData {
  email: string
}

export interface PhoneFormData {
  phone: string
}

export const UpdateCustomerMissingInformation = ({ user, invoice, onSubmit, onCancel, type }: MissingCustomerDataFormProps) => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [shareInvoice] = useShareInvoiceMutation()
  const [updateCustomer] = useMutation(MUTATION.customers.updateCustomer)

  const initialValuesEmail: EmailFormData = {
    email: ''
  }

  const initialValuesPhone: PhoneFormData = {
    phone: ''
  }

  const emailSchema = Yup
    .object()
    .shape({
      email: Yup
        .string()
        .required(t('validation.required'))
    })

  const phoneSchema = Yup
    .object()
    .shape({
      phone: Yup
        .string()
        .required(t('validation.required'))
        .test('isValidPhoneNumber', t('validation.invalidPhone'),
          // @ts-ignore
          (value) => {
            if (value) {
              return isValidPhoneNumber(value)
            }
          })
    })

  const formSubmit = async (data: EmailFormData | PhoneFormData) => {
    setLoading(true)

    try {
      await updateCustomer({
        variables: {
          ...invoice?.customer,
          user_id: user?.id,
          business_id: user?.businesses?.[0]?.id,
          ...data
        }
      })

      await shareInvoice({
        variables: {
          invoiceId: invoice.id,
          channel: type === 'phone' ? 'sms' : 'email',
          userId: user?.id
        }
      })

      onSubmit()
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <Stack spacing={4}>
      <Typography variant='h6'>{ t('banking.invoices.missingCustomerData.title', 'We need more information') }</Typography>
      <Typography>
        { t('banking.invoices.missingCustomerData.description', 'Complete the field below to share the invoice') }
      </Typography>
      <Formik<EmailFormData | PhoneFormData>
        initialValues={type === 'email' ? initialValuesEmail : initialValuesPhone}
        validationSchema={type === 'email' ? emailSchema : phoneSchema}
        onSubmit={formSubmit}
      >
        {() => {
          return (
            <Form>

              {
                type === 'email' && (
                  <FormInput
                    name='email'
                    label={t('banking.invoices.missingCustomerData.email', 'Email')}
                    type='text'
                  />
                )
              }
              {
                type === 'phone' && (
                  <PhoneNumberInput
                    label={t('banking.invoices.missingCustomerData.phone', 'Phone')}
                    name='phone'
                  />
                )
              }
              <Btn.Button
                isLoading={isLoading}
                disabled={isLoading}
                type='submit'
              >
                {t('banking.invoices.missingCustomerData.update', 'Update and share')}
              </Btn.Button>
              <Btn.Button
                primary={false}
                onClick={onCancel}
              >
                {t('banking.invoices.missingCustomerData.cancel', 'Cancel')}
              </Btn.Button>
            </Form>
          )
        }}
      </Formik>
    </Stack>
  )
}
