interface CounterProps {
  currentStep?: string
  totalSteps?: string
}

export const CounterSteps = ({ currentStep, totalSteps }: CounterProps) => {
  return (
    <p className='justify-self-center text-xl/6 font-medium text-[#304985]'>
      {currentStep}
      /
      {totalSteps}
    </p>
  )
}
