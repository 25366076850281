import Highlight from 'react-highlight'
import { Card, Grid, Typography } from '@mui/material'

import { Alert } from '#app/components/Alerts'

export function AlertsDemo() {
  return (
    <Card sx={{
      p: 4
    }}
    >
      <Typography variant='h5'>Alerts</Typography>
      <Highlight>
        {`
import { Alert } from '#app/components/Alerts'

<Alert.Basic severity='error' title='Title error' />
<Alert.Basic severity='warning' title='Title warning' />
<Alert.Basic severity='success' title='Title success'/>
<Alert.Basic severity='info' title='Title info' />
<Alert.Basic severity='error' title='Title error' description='Description error' />
<Alert.Basic severity='warning' title='Title warning' description='Description warning' />
<Alert.Basic severity='success' title='Title success' description='Description success' />
<Alert.Basic severity='info' title='Title info' description='Description info' />
        `}
      </Highlight>

      <Grid container>
        <Grid
          item
          xs={12}
        >
          <Alert.Basic
            severity='error'
            title='Title error'
            sx={{
              m: 2
            }}
          />
          <Alert.Basic
            severity='warning'
            title='Title warning'
            sx={{
              m: 2
            }}
          />
          <Alert.Basic
            severity='success'
            title='Title success'
            sx={{
              m: 2
            }}
          />
          <Alert.Basic
            severity='info'
            title='Title info'
            sx={{
              m: 2
            }}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
        >
          <Alert.Basic
            severity='error'
            title='Title error'
            description='Description error'
            sx={{
              m: 2
            }}
          />
          <Alert.Basic
            severity='warning'
            title='Title warning'
            description='Description warning'
            sx={{
              m: 2
            }}
          />
          <Alert.Basic
            severity='success'
            title='Title success'
            description='Description success'
            sx={{
              m: 2
            }}
          />
          <Alert.Basic
            severity='info'
            title='Title info'
            description='Description info'
            sx={{
              m: 2
            }}
          />
        </Grid>
      </Grid>

    </Card>
  )
}
