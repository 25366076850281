import { Auth0Provider } from '@auth0/auth0-react'

export const Auth0ProviderWithRedirectCallback = ({ children, ...props }: { children: JSX.Element, props: any }) => {
  // const navigate = useNavigate()
  // const onRedirectCallback = (appState) => {
  //   return navigate((appState && appState.returnTo) || window.location.pathname)
  // }

  // onRedirectCallback={onRedirectCallback}

  return (
    // @ts-ignore
    <Auth0Provider {...props}>
      {children}
    </Auth0Provider>
  )
}
