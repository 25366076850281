import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BsInfoCircleFill, BsStarFill } from 'react-icons/bs'
import { useSearchParams } from 'react-router-dom'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useSnackbar } from 'notistack'

import { Chip } from '#app/components/Chip'
import { DividerExtended } from '#app/components/Divider'
import { LoadingGrid } from '#app/components/Loading/LoadingGrid'
import { BidDetails } from '#app/feature/WorkOrders/BidDetail'
import { WorkOrderEvidence } from '#app/feature/WorkOrders/Evidences'
import WorkOrdersDetails from '#app/feature/WorkOrders/WorkOrdersDetails/WorkOrdersDetails'
import { useUpdateWorkOrderBidWinnerMutation } from '#app/operations/pmmarketplace/pmmarketplace.mutations.generated'
import { GetWorkOrderDetailsDocument, GetWorkOrderDetailsQuery, useGetWorkOrderBidsPublicQuery, useGetWorkOrderDetailsQuery, useGetWorkOrderEvidencesQuery } from '#app/operations/pmmarketplace/pmmarketplace.queries.generated'

const PMWorkOrderDetailsContent = () => {
  const [search] = useSearchParams()

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const { data: dataBids, loading: loadingBids } = useGetWorkOrderBidsPublicQuery({
    variables: {
      workOrderId: search.get('work_order_id') as string,
      serviceRequestId: search.get('service_request_id') as string
    }
  })

  const { data, loading: loadingWorkOrder } = useGetWorkOrderDetailsQuery({
    variables: {
      id: search.get('work_order_id') as string
    }
  })

  const { data: dataEvidences, loading: loadingEvidence } = useGetWorkOrderEvidencesQuery({
    variables: {
      workOrderId: search.get('work_order_id') as string,
      serviceRequestId: search.get('service_request_id') as string
    }
  })

  const [updateWorkOrder, { loading: loadingUpdateWorkOrder }] = useUpdateWorkOrderBidWinnerMutation({
    update(cache, { data }) {
      const cacheQuery = cache.readQuery<GetWorkOrderDetailsQuery>({
        query: GetWorkOrderDetailsDocument,
        variables: {
          id: search.get('work_order_id') as string
        }
      })

      const updatedWorkOrder = data?.updateWorkOrderBidWinner?.work_order
      const currentWorkOrder = cacheQuery?.getWorkOrder?.work_order?.pmmarketplace_work_orders_by_pk

      cache.writeQuery({
        query: GetWorkOrderDetailsDocument,
        variables: {
          id: search.get('work_order_id') as string
        },
        data: {
          getWorkOrder: {
            work_order: {
              pmmarketplace_work_orders_by_pk: {
                ...currentWorkOrder,
                status: updatedWorkOrder.status,
                bid_winner: updatedWorkOrder.bid_winner
              }
            }
          }
        }
      })
    }
  })

  const loading = useMemo(() => loadingWorkOrder || loadingBids || loadingUpdateWorkOrder, [loadingBids, loadingWorkOrder, loadingUpdateWorkOrder])

  const workOrderData: any = useMemo(() => {
    const fields = data?.getWorkOrder?.work_order.pmmarketplace_work_orders_by_pk
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return fields || {}
  }, [data])

  const handlerUpdateWorkOrder = useCallback(async (bidId: string) => {
    const workOrderId = search.get('work_order_id') as string
    const serviceRequestId = search.get('service_request_id') as string
    if (workOrderId) {
      const res = await updateWorkOrder({
        variables: {
          work_order_id: workOrderId,
          bid_id: bidId,
          service_request_id: serviceRequestId
        }
      })

      const result = res?.data?.updateWorkOrderBidWinner
      if (result?.success) {
        enqueueSnackbar(t('general.dataUpdated'), {
          variant: 'success'
        })
      }
    }
  }, [enqueueSnackbar, search, t, updateWorkOrder])

  return (
    <Stack spacing={5}>
      {
        loading
        && (
          <Stack sx={{
            minWidth: '300px'
          }}
          >
            <LoadingGrid />
          </Stack>
        )
      }
      {
        !loading
        && (
          <WorkOrdersDetails
            status={workOrderData?.status}
            workOrderId={data?.getWorkOrder?.work_order.pmmarketplace_work_orders_by_pk.id}
            loading={loading}
            {...workOrderData}
            hideCreateBid
          />
        )
      }
      {
        workOrderData?.status === 'evidence_submitted'
        && (
          <>
            <Stack
              direction='column'
              justifyContent='flex-start'
              alignItems='left'
              spacing={2}
            >
              <Stack
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                spacing={2}
              >
                <BsInfoCircleFill />
                <Typography variant='h4'>
                  {' '}
                  {t('workorder.evidences', 'Evidences & CSAT')}
                  {' '}
                </Typography>
              </Stack>
              {
                dataEvidences?.getWorkOrderEvidences?.evidences?.map((evidence) => <WorkOrderEvidence evidence={evidence} />)
              }
            </Stack>
          </>
        )
      }
      <DividerExtended sx={{
        mt: 2
      }}
      />
      {
        workOrderData?.bid_winner
        && (
          <>
            <Stack
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              spacing={2}
            >
              <BsStarFill />
              <Typography variant='h4'>
                {' '}
                {t('workorder.winningBid', 'Winning bid')}
                {' '}
              </Typography>
            </Stack>
            {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              dataBids?.getWorkOrderBids?.bids?.filter((bid) => bid.id === workOrderData?.bid_winner?.toString()).map((bid, key) => {
                return (
                  <BidDetails
                    key={key}
                    highlight
                    workOrder={workOrderData}
                    bid={bid}
                  />
                )
              })
            }
            <DividerExtended sx={{
              mt: 2
            }}
            />
          </>
        )
      }
      <Typography variant='h4'>
        {' '}
        {dataBids?.getWorkOrderBids?.bids?.length}
        {' '}
        Bids
        {' '}
      </Typography>
      <DividerExtended sx={{
        mt: 2
      }}
      />

      {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        !loading && dataBids?.getWorkOrderBids?.bids && dataBids?.getWorkOrderBids?.bids.map((bid, key) => {
          return (
            <BidDetails
              key={key}
              workOrder={workOrderData}
              bid={bid}
              updateWorkOrder={handlerUpdateWorkOrder}
            />
          )
        })
      }
    </Stack>
  )
}

export const PMWorkOrderDetails = () => {
  const [search] = useSearchParams()

  const validParams = useMemo(() => search.get('work_order_id') && search.get('service_request_id'), [])

  return (
    <>
      {
        !validParams
        && <Chip.Basic label='The parameters of the work order and the service request are missing' />
      }
      {
        validParams
        && <PMWorkOrderDetailsContent />
      }
    </>
  )
}
