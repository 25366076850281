import { ChangeEvent, useContext, useRef, useState } from 'react'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { Box, CircularProgress, IconButton, InputAdornment, Stack, SxProps, TextareaAutosize, TextField } from '@mui/material'

import ProfileContext from '#app/contexts/ProfileContext'
import useUploadResource from '#app/hooks/useUploadResource'

type FileUploadInputProps = {
  uploadPath?: string
  label?: string
  sx?: SxProps
  inputValue?: string
  isTextArea?: boolean
  setInputValue?: (newValue: string) => void
  onUploadComplete: (url: string) => void
  onChange?: (event: string) => void
  userId?: string
}

const FileUploadInput: React.FC<FileUploadInputProps> = ({
  uploadPath = 'public-assets',
  sx,
  label,
  inputValue,
  setInputValue,
  onUploadComplete,
  onChange,
  userId,
  isTextArea,
}) => {
  const { uploadResource } = useUploadResource()
  const [uploading, setUploading] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const data = useContext(ProfileContext)
  const user = data?.getProfile?.user

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setUploading(true)
      try {
        const file = e.target.files[0]
        let fullPath = 'admins-assets'
        if (uploadPath !== '') {
          fullPath += '/' + uploadPath
        }
        const url: string = await uploadResource(userId ?? user?.id, fullPath, file)
        setInputValue && setInputValue(url)
        onUploadComplete(url)
      }
      catch (error) {
        console.error('Error uploading file:', error)
      }
      finally {
        setUploading(false)
      }
    }
  }

  const handleInputChange = (newValue: string) => {
    setInputValue && setInputValue(newValue)
    onChange && onChange(newValue)
  }

  return (
    <div>
      {
        !isTextArea
          ? (
            <TextField
              fullWidth
              label={label ?? 'Upload new file'}
              value={inputValue ?? ''}
              sx={sx}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                      disabled={uploading}
                      onClick={() => fileInputRef.current?.click()}
                    >
                      <AttachFileIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: uploading && <CircularProgress size={20} />,
              }}
              disabled={uploading}
              onChange={(event) => handleInputChange(event?.target?.value)}
            />
          )
          : (
            <Stack
              direction='row'
              sx={{
                width: '100%'
              }}
            >
              <Box mr={1}>
                <IconButton
                  color='primary'
                  aria-label='upload picture'
                  component='span'
                  disabled={uploading}
                  onClick={() => fileInputRef.current?.click()}
                >
                  <AttachFileIcon />
                </IconButton>
              </Box>
              <TextareaAutosize
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '10px',
                  boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.1)',
                  resize: 'vertical',
                  width: '100%'
                }}
                disabled={uploading}
                value={inputValue ?? ''}
                onChange={(eve) => handleInputChange(eve?.target?.value)}
              />
            </Stack>
          )
      }
      <input
        ref={fileInputRef}
        type='file'
        style={{
          display: 'none'
        }}
        onChange={handleFileChange}
        onClick={(event) => (event.currentTarget.value = '')}
      />
    </div>
  )
}

export default FileUploadInput
