import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type BankingSignupMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']
  capabilities?: Types.InputMaybe<Types.Scalars['jsonb']>
}>

export type BankingSignupMutation = { __typename?: 'mutation_root', createBankingSignup?: { __typename?: 'CreateBankingSignupOutput', result: boolean } | undefined }

export type BankingOnboardMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']
}>

export type BankingOnboardMutation = { __typename?: 'mutation_root', createBankingOnboard?: { __typename?: 'CreateBankingOnboardOutput', url: string } | undefined }

export type CreateConnectedCheckoutSessionMutationVariables = Types.Exact<{
  businessId: Types.Scalars['uuid']
  invoiceId?: Types.InputMaybe<Types.Scalars['uuid']>
  email?: Types.InputMaybe<Types.Scalars['String']>
  amount?: Types.InputMaybe<Types.Scalars['Int']>
}>

export type CreateConnectedCheckoutSessionMutation = { __typename?: 'mutation_root', createConnectedCheckoutSession?: { __typename?: 'CheckoutSessionOutput', url: string } | undefined }

export type CreateBankTransferMutationVariables = Types.Exact<{
  bank: Types.Scalars['String']
  amount: Types.Scalars['Int']
  accountHolderName?: Types.InputMaybe<Types.Scalars['String']>
  accountType?: Types.InputMaybe<Types.Scalars['String']>
  routingNumber?: Types.InputMaybe<Types.Scalars['String']>
  accountNumber?: Types.InputMaybe<Types.Scalars['String']>
  email?: Types.InputMaybe<Types.Scalars['String']>
  phone?: Types.InputMaybe<Types.Scalars['String']>
  addressCity?: Types.InputMaybe<Types.Scalars['String']>
  addressStreet?: Types.InputMaybe<Types.Scalars['String']>
  addressState?: Types.InputMaybe<Types.Scalars['String']>
  addressPostalCode?: Types.InputMaybe<Types.Scalars['String']>
  amountSetting?: Types.InputMaybe<Types.Scalars['String']>
  transferType?: Types.InputMaybe<Types.Scalars['String']>
}>

export type CreateBankTransferMutation = { __typename?: 'mutation_root', createBankTransfer?: { __typename?: 'CreateBankTransferOutput', transfer: any } | undefined }

export type PrepareBankTransferMutationVariables = Types.Exact<{
  bank: Types.Scalars['String']
  amount: Types.Scalars['Int']
  accountHolderName?: Types.InputMaybe<Types.Scalars['String']>
  accountType?: Types.InputMaybe<Types.Scalars['String']>
  routingNumber?: Types.InputMaybe<Types.Scalars['String']>
  accountNumber?: Types.InputMaybe<Types.Scalars['String']>
  email?: Types.InputMaybe<Types.Scalars['String']>
  phone?: Types.InputMaybe<Types.Scalars['String']>
  addressCity?: Types.InputMaybe<Types.Scalars['String']>
  addressStreet?: Types.InputMaybe<Types.Scalars['String']>
  addressState?: Types.InputMaybe<Types.Scalars['String']>
  addressPostalCode?: Types.InputMaybe<Types.Scalars['String']>
  amountSetting?: Types.InputMaybe<Types.Scalars['String']>
  transferType?: Types.InputMaybe<Types.Scalars['String']>
}>

export type PrepareBankTransferMutation = { __typename?: 'mutation_root', createPrepareBankTransfer?: { __typename?: 'CreateBankTransferOutput', transfer: any } | undefined }

export type CreateIssuingCardMutationVariables = Types.Exact<{
  billingData: Types.Scalars['jsonb']
}>

export type CreateIssuingCardMutation = { __typename?: 'mutation_root', createIssuingCard?: { __typename?: 'BankCard', user_id?: any | undefined, updated_by?: string | undefined, updated_at?: string | undefined, stripe_card_id?: string | undefined, stripe_card_holder_id?: string | undefined, id?: any | undefined, status?: string | undefined, created_by?: any | undefined, created_at?: string | undefined, card_holder?: any | undefined, card?: any | undefined, business_id?: any | undefined } | undefined }

export type UpdateStatusIssuingCardMutationVariables = Types.Exact<{
  status: Types.Scalars['String']
  cardId: Types.Scalars['String']
  cancellationReason?: Types.InputMaybe<Types.Scalars['String']>
}>

export type UpdateStatusIssuingCardMutation = { __typename?: 'mutation_root', updateStatusIssuingCard?: { __typename?: 'BankCard', stripe_card_id?: string | undefined, status?: string | undefined, card?: any | undefined } | undefined }

export const BankingSignupDocument = gql`
    mutation bankingSignup($id: uuid!, $capabilities: jsonb) {
  createBankingSignup(input: {id: $id, capabilities: $capabilities}) {
    result
  }
}
    `
export type BankingSignupMutationFn = Apollo.MutationFunction<BankingSignupMutation, BankingSignupMutationVariables>

/**
 * __useBankingSignupMutation__
 *
 * To run a mutation, you first call `useBankingSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBankingSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bankingSignupMutation, { data, loading, error }] = useBankingSignupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      capabilities: // value for 'capabilities'
 *   },
 * });
 */
export function useBankingSignupMutation(baseOptions?: Apollo.MutationHookOptions<BankingSignupMutation, BankingSignupMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<BankingSignupMutation, BankingSignupMutationVariables>(BankingSignupDocument, options)
}
export type BankingSignupMutationHookResult = ReturnType<typeof useBankingSignupMutation>
export type BankingSignupMutationResult = Apollo.MutationResult<BankingSignupMutation>
export type BankingSignupMutationOptions = Apollo.BaseMutationOptions<BankingSignupMutation, BankingSignupMutationVariables>
export const BankingOnboardDocument = gql`
    mutation bankingOnboard($id: uuid!) {
  createBankingOnboard(input: {id: $id}) {
    url
  }
}
    `
export type BankingOnboardMutationFn = Apollo.MutationFunction<BankingOnboardMutation, BankingOnboardMutationVariables>

/**
 * __useBankingOnboardMutation__
 *
 * To run a mutation, you first call `useBankingOnboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBankingOnboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bankingOnboardMutation, { data, loading, error }] = useBankingOnboardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBankingOnboardMutation(baseOptions?: Apollo.MutationHookOptions<BankingOnboardMutation, BankingOnboardMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<BankingOnboardMutation, BankingOnboardMutationVariables>(BankingOnboardDocument, options)
}
export type BankingOnboardMutationHookResult = ReturnType<typeof useBankingOnboardMutation>
export type BankingOnboardMutationResult = Apollo.MutationResult<BankingOnboardMutation>
export type BankingOnboardMutationOptions = Apollo.BaseMutationOptions<BankingOnboardMutation, BankingOnboardMutationVariables>
export const CreateConnectedCheckoutSessionDocument = gql`
    mutation createConnectedCheckoutSession($businessId: uuid!, $invoiceId: uuid, $email: String, $amount: Int) {
  createConnectedCheckoutSession(
    input: {businessId: $businessId, invoiceId: $invoiceId, email: $email, amount: $amount}
  ) {
    url
  }
}
    `
export type CreateConnectedCheckoutSessionMutationFn = Apollo.MutationFunction<CreateConnectedCheckoutSessionMutation, CreateConnectedCheckoutSessionMutationVariables>

/**
 * __useCreateConnectedCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateConnectedCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConnectedCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConnectedCheckoutSessionMutation, { data, loading, error }] = useCreateConnectedCheckoutSessionMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      invoiceId: // value for 'invoiceId'
 *      email: // value for 'email'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useCreateConnectedCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateConnectedCheckoutSessionMutation, CreateConnectedCheckoutSessionMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<CreateConnectedCheckoutSessionMutation, CreateConnectedCheckoutSessionMutationVariables>(CreateConnectedCheckoutSessionDocument, options)
}
export type CreateConnectedCheckoutSessionMutationHookResult = ReturnType<typeof useCreateConnectedCheckoutSessionMutation>
export type CreateConnectedCheckoutSessionMutationResult = Apollo.MutationResult<CreateConnectedCheckoutSessionMutation>
export type CreateConnectedCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateConnectedCheckoutSessionMutation, CreateConnectedCheckoutSessionMutationVariables>
export const CreateBankTransferDocument = gql`
    mutation createBankTransfer($bank: String!, $amount: Int!, $accountHolderName: String, $accountType: String, $routingNumber: String, $accountNumber: String, $email: String, $phone: String, $addressCity: String, $addressStreet: String, $addressState: String, $addressPostalCode: String, $amountSetting: String, $transferType: String) {
  createBankTransfer(
    input: {bank: $bank, amount: $amount, accountHolderName: $accountHolderName, accountType: $accountType, routingNumber: $routingNumber, accountNumber: $accountNumber, email: $email, phone: $phone, addressCity: $addressCity, addressStreet: $addressStreet, addressState: $addressState, addressPostalCode: $addressPostalCode, amountSetting: $amountSetting, transferType: $transferType}
  ) {
    transfer
  }
}
    `
export type CreateBankTransferMutationFn = Apollo.MutationFunction<CreateBankTransferMutation, CreateBankTransferMutationVariables>

/**
 * __useCreateBankTransferMutation__
 *
 * To run a mutation, you first call `useCreateBankTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankTransferMutation, { data, loading, error }] = useCreateBankTransferMutation({
 *   variables: {
 *      bank: // value for 'bank'
 *      amount: // value for 'amount'
 *      accountHolderName: // value for 'accountHolderName'
 *      accountType: // value for 'accountType'
 *      routingNumber: // value for 'routingNumber'
 *      accountNumber: // value for 'accountNumber'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      addressCity: // value for 'addressCity'
 *      addressStreet: // value for 'addressStreet'
 *      addressState: // value for 'addressState'
 *      addressPostalCode: // value for 'addressPostalCode'
 *      amountSetting: // value for 'amountSetting'
 *      transferType: // value for 'transferType'
 *   },
 * });
 */
export function useCreateBankTransferMutation(baseOptions?: Apollo.MutationHookOptions<CreateBankTransferMutation, CreateBankTransferMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<CreateBankTransferMutation, CreateBankTransferMutationVariables>(CreateBankTransferDocument, options)
}
export type CreateBankTransferMutationHookResult = ReturnType<typeof useCreateBankTransferMutation>
export type CreateBankTransferMutationResult = Apollo.MutationResult<CreateBankTransferMutation>
export type CreateBankTransferMutationOptions = Apollo.BaseMutationOptions<CreateBankTransferMutation, CreateBankTransferMutationVariables>
export const PrepareBankTransferDocument = gql`
    mutation prepareBankTransfer($bank: String!, $amount: Int!, $accountHolderName: String, $accountType: String, $routingNumber: String, $accountNumber: String, $email: String, $phone: String, $addressCity: String, $addressStreet: String, $addressState: String, $addressPostalCode: String, $amountSetting: String, $transferType: String) {
  createPrepareBankTransfer(
    input: {bank: $bank, amount: $amount, accountHolderName: $accountHolderName, accountType: $accountType, routingNumber: $routingNumber, accountNumber: $accountNumber, email: $email, phone: $phone, addressCity: $addressCity, addressStreet: $addressStreet, addressState: $addressState, addressPostalCode: $addressPostalCode, amountSetting: $amountSetting, transferType: $transferType}
  ) {
    transfer
  }
}
    `
export type PrepareBankTransferMutationFn = Apollo.MutationFunction<PrepareBankTransferMutation, PrepareBankTransferMutationVariables>

/**
 * __usePrepareBankTransferMutation__
 *
 * To run a mutation, you first call `usePrepareBankTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareBankTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareBankTransferMutation, { data, loading, error }] = usePrepareBankTransferMutation({
 *   variables: {
 *      bank: // value for 'bank'
 *      amount: // value for 'amount'
 *      accountHolderName: // value for 'accountHolderName'
 *      accountType: // value for 'accountType'
 *      routingNumber: // value for 'routingNumber'
 *      accountNumber: // value for 'accountNumber'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      addressCity: // value for 'addressCity'
 *      addressStreet: // value for 'addressStreet'
 *      addressState: // value for 'addressState'
 *      addressPostalCode: // value for 'addressPostalCode'
 *      amountSetting: // value for 'amountSetting'
 *      transferType: // value for 'transferType'
 *   },
 * });
 */
export function usePrepareBankTransferMutation(baseOptions?: Apollo.MutationHookOptions<PrepareBankTransferMutation, PrepareBankTransferMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<PrepareBankTransferMutation, PrepareBankTransferMutationVariables>(PrepareBankTransferDocument, options)
}
export type PrepareBankTransferMutationHookResult = ReturnType<typeof usePrepareBankTransferMutation>
export type PrepareBankTransferMutationResult = Apollo.MutationResult<PrepareBankTransferMutation>
export type PrepareBankTransferMutationOptions = Apollo.BaseMutationOptions<PrepareBankTransferMutation, PrepareBankTransferMutationVariables>
export const CreateIssuingCardDocument = gql`
    mutation createIssuingCard($billingData: jsonb!) {
  createIssuingCard(input: {billingData: $billingData}) {
    user_id
    updated_by
    updated_at
    stripe_card_id
    stripe_card_holder_id
    id
    status
    created_by
    created_at
    card_holder
    card
    business_id
  }
}
    `
export type CreateIssuingCardMutationFn = Apollo.MutationFunction<CreateIssuingCardMutation, CreateIssuingCardMutationVariables>

/**
 * __useCreateIssuingCardMutation__
 *
 * To run a mutation, you first call `useCreateIssuingCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIssuingCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIssuingCardMutation, { data, loading, error }] = useCreateIssuingCardMutation({
 *   variables: {
 *      billingData: // value for 'billingData'
 *   },
 * });
 */
export function useCreateIssuingCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateIssuingCardMutation, CreateIssuingCardMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<CreateIssuingCardMutation, CreateIssuingCardMutationVariables>(CreateIssuingCardDocument, options)
}
export type CreateIssuingCardMutationHookResult = ReturnType<typeof useCreateIssuingCardMutation>
export type CreateIssuingCardMutationResult = Apollo.MutationResult<CreateIssuingCardMutation>
export type CreateIssuingCardMutationOptions = Apollo.BaseMutationOptions<CreateIssuingCardMutation, CreateIssuingCardMutationVariables>
export const UpdateStatusIssuingCardDocument = gql`
    mutation updateStatusIssuingCard($status: String!, $cardId: String!, $cancellationReason: String) {
  updateStatusIssuingCard(
    input: {cardId: $cardId, status: $status, cancellationReason: $cancellationReason}
  ) {
    stripe_card_id
    status
    card
  }
}
    `
export type UpdateStatusIssuingCardMutationFn = Apollo.MutationFunction<UpdateStatusIssuingCardMutation, UpdateStatusIssuingCardMutationVariables>

/**
 * __useUpdateStatusIssuingCardMutation__
 *
 * To run a mutation, you first call `useUpdateStatusIssuingCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatusIssuingCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatusIssuingCardMutation, { data, loading, error }] = useUpdateStatusIssuingCardMutation({
 *   variables: {
 *      status: // value for 'status'
 *      cardId: // value for 'cardId'
 *      cancellationReason: // value for 'cancellationReason'
 *   },
 * });
 */
export function useUpdateStatusIssuingCardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStatusIssuingCardMutation, UpdateStatusIssuingCardMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<UpdateStatusIssuingCardMutation, UpdateStatusIssuingCardMutationVariables>(UpdateStatusIssuingCardDocument, options)
}
export type UpdateStatusIssuingCardMutationHookResult = ReturnType<typeof useUpdateStatusIssuingCardMutation>
export type UpdateStatusIssuingCardMutationResult = Apollo.MutationResult<UpdateStatusIssuingCardMutation>
export type UpdateStatusIssuingCardMutationOptions = Apollo.BaseMutationOptions<UpdateStatusIssuingCardMutation, UpdateStatusIssuingCardMutationVariables>
