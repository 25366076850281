import Highlight from 'react-highlight'
import { BsFillCartPlusFill } from 'react-icons/bs'
import { Alert, Button, Card, Grid, Typography } from '@mui/material'

import { Btn } from '#app/components/Buttons'

export function ButtonsDemo() {
  return (
    <Card sx={{
      p: 4
    }}
    >
      <Typography variant='h5'> MUI Buttons (Theming) </Typography>
      <Alert severity='warning'>
        Please try not to import the buttons directly from @mui and use our own component from #app/components/Buttons, unless you have a blocker.
        If you find a button that could be changed to our own component, feel free to report it.
      </Alert>
      <Highlight>
        {'import { Button } from \'@mui/material\''}
        <br />
        <br />
        {'<Button variant="contained" size="large">Text</Button>'}
      </Highlight>
      <Grid
        container
        spacing={2}
      >
        <Grid item>
          <Button
            variant='contained'
            size='large'
          >
            Primary
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant='contained'
            size='large'
            color='secondary'
          >
            Secondary
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant='contained'
            size='large'
            color='warning'
          >
            Warning
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        mt={1}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          <Button
            disabled
            variant='contained'
            size='large'
          >
            Primary
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled
            variant='contained'
            size='large'
            color='secondary'
          >
            Secondary
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled
            variant='contained'
            size='large'
            color='warning'
          >
            Warning
          </Button>
        </Grid>
      </Grid>

      <Typography variant='h5'> Buttons </Typography>
      <Highlight>
        {`
import { Btn } from '#app/components/Buttons'

<Btn.Button primary> Primary </Btn.Button>
<Btn.Button secondary> Secondary </Btn.Button>
<Btn.Button color='warning'> Warning </Btn.Button>
      `}
      </Highlight>
      <Grid
        container
        spacing={2}
      >
        <Grid item>
          <Btn.Button primary> Primary </Btn.Button>
        </Grid>
        <Grid item>
          <Btn.Button secondary> Secondary </Btn.Button>
        </Grid>
        <Grid item>
          <Btn.Button color='warning'> Warning </Btn.Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        mt={1}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          <Btn.Button
            disabled
            primary
          >
            {' '}
            Primary
          </Btn.Button>
        </Grid>
        <Grid item>
          <Btn.Button
            disabled
            secondary
          >
            {' '}
            Secondary
          </Btn.Button>
        </Grid>
        <Grid item>
          <Btn.Button
            disabled
            color='warning'
          >
            {' '}
            Warning
          </Btn.Button>
        </Grid>
      </Grid>

      <Typography variant='h5'> Action Button </Typography>
      <Highlight>
        {`
import { Btn } from '#app/components/Buttons'

<Btn.ActionButton> Action Button </Btn.ActionButton>
<Btn.ActionButton forceMobile> Action Button </Btn.ActionButton>
      `}
      </Highlight>
      <Grid
        container
        spacing={2}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          <Btn.ActionButton> Action Button </Btn.ActionButton>
        </Grid>
        <Grid item>
          <Btn.ActionButton forceMobile> Secondary </Btn.ActionButton>
        </Grid>
      </Grid>

      <Typography variant='h5'> Menu Button - View/Edit/Delete </Typography>
      <Highlight>
        {`
import { Btn } from '#app/components/Buttons'

<Btn.MenuButton
  onDelete={() => console.info('delete')}
  onView={() => console.info('view')}
  onEdit={() => console.info('edit')}
/>

<Btn.MenuButton
  forceMobile
  onEdit={() => console.info('edit')}
  onDelete={() => console.info('delete')}
/>
      `}
      </Highlight>
      <Grid
        container
        spacing={2}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          <Btn.MenuButton
            onDelete={() => console.info('delete')}
            onView={() => console.info('view')}
            onEdit={() => console.info('edit')}
          />
        </Grid>
        <Grid item>
          <Btn.MenuButton
            forceMobile
            onEdit={() => console.info('edit')}
            onDelete={() => console.info('delete')}
          />
        </Grid>
      </Grid>

      <Typography variant='h5'> Action Button with custom icon </Typography>
      <Highlight>
        {`
import { Btn } from '#app/components/Buttons'

<Btn.ActionButton icon={<BsFillCartPlusFill/>}> Action Button </Btn.ActionButton>
<Btn.ActionButton icon={<BsFillCartPlusFill/>} forceMobile> Action Button </Btn.ActionButton>
      `}
      </Highlight>
      <Grid
        container
        spacing={2}
        sx={{
          mb: 2
        }}
      >
        <Grid item>
          <Btn.ActionButton icon={<BsFillCartPlusFill />}> Add Item </Btn.ActionButton>
        </Grid>
        <Grid item>
          <Btn.ActionButton
            forceMobile
            icon={<BsFillCartPlusFill />}
          >
            {' '}
            Secondary
          </Btn.ActionButton>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          <Btn.ActionButton
            disabled
            icon={<BsFillCartPlusFill />}
          >
            {' '}
            Add Item
          </Btn.ActionButton>
        </Grid>
        <Grid item>
          <Btn.ActionButton
            forceMobile
            disabled
            icon={<BsFillCartPlusFill />}
          >
            {' '}
            Secondary
          </Btn.ActionButton>
        </Grid>
      </Grid>

      <Typography variant='h5'> Icon Button with tooltip </Typography>
      <Highlight>
        {`
import { Btn } from '#app/components/Buttons'

<Btn.IconButton.Info/>
<Btn.IconButton.Sync/>
<Btn.IconButton.Close/>
<Btn.IconButton.Plus/>
<Btn.IconButton.Collapse/>
<Btn.IconButton.Delete/>
<Btn.IconButton.Delete withoutWarning/>
      `}
      </Highlight>
      <Grid
        container
        spacing={2}
        sx={{
          mb: 2
        }}
      >
        <Grid item>
          <Btn.IconButton.Info />
        </Grid>
        <Grid item>
          <Btn.IconButton.Sync />
        </Grid>
        <Grid item>
          <Btn.IconButton.Close />
        </Grid>
        <Grid item>
          <Btn.IconButton.Collapse />
        </Grid>
        <Grid item>
          <Btn.IconButton.Plus />
        </Grid>
        <Grid item>
          <Btn.IconButton.Delete withoutWarning />
        </Grid>
        <Grid item>
          <Btn.IconButton.Delete />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          <Btn.IconButton.Info disabled />
        </Grid>
        <Grid item>
          <Btn.IconButton.Sync disabled />
        </Grid>
        <Grid item>
          <Btn.IconButton.Close disabled />
        </Grid>
        <Grid item>
          <Btn.IconButton.Collapse disabled />
        </Grid>
        <Grid item>
          <Btn.IconButton.Plus disabled />
        </Grid>
        <Grid item>
          <Btn.IconButton.Delete
            withoutWarning
            disabled
          />
        </Grid>
        <Grid item>
          <Btn.IconButton.Delete disabled />
        </Grid>
      </Grid>
    </Card>
  )
}
