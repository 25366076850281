import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

import Card from '#app/components/Card/Card'
import SearchInput from '#app/components/SearchInput/SearchInput'
import Table from '#app/components/Table/Table'

interface Props {
  onFilterChange: any
  tableStructure: any
}

export const EmptyPlaceholder = () => (
  <Grid
    container
    direction='row'
    justifyContent='center'
    alignItems='center'
    sx={{
      height: 200
    }}
  >
    No customers
  </Grid>
)

export const CustomerList = ({ onFilterChange, tableStructure }: Props) => {
  const { t } = useTranslation()

  return (
    <Card>
      <>
        <Card.Header>
          <SearchInput
            placeholder={t('general.searchPlaceholder')}
            onFilterChange={onFilterChange}
          />
        </Card.Header>
        <Card.Body className='p-0'>
          {
            tableStructure.mobile.rows.length
              ? (
                <Table
                  displayMobile={true}
                  structure={tableStructure}
                />
              )
              : <EmptyPlaceholder />
          }
        </Card.Body>
      </>
    </Card>
  )
}
