import { useTranslation } from 'react-i18next'
import { ChipProps } from '@mui/material'
import { capitalize } from 'lodash'

import { Chip } from '#app/components/Chip'

import { TransactionRowData } from './TransactionsTable'

export const StatusChip = ({ data }: { data: TransactionRowData }) => {
  const { t } = useTranslation()

  const goodStatuses = [
    'posted',
    'succeeded'
  ]

  const badStatuses = [
    'failed'
  ]

  const neutralStatuses = [
    'returned',
    'canceled'
  ]

  let color: ChipProps['color']

  if (goodStatuses.includes(data.status)) {
    color = 'success'
  }
  else if (badStatuses.includes(data.status)) {
    color = 'error'
  }
  else if (neutralStatuses.includes(data.status)) {
    color = 'default'
  }
  else {
    color = 'warning'
  }

  const translationsByStatus = {
    succeeded: t('transactionsStatusChip.success', 'succeeded'),
    posted: t('transactionsStatusChip.posted', 'posted'),
    failed: t('transactionsStatusChip.failed', 'failed'),
    returned: t('transactionsStatusChip.returned', 'returned'),
    canceled: t('transactionsStatusChip.canceled', 'canceled'),
    processing: t('transactionsStatusChip.processing', 'processing')
  }

  // @ts-ignore
  const label = data.status ? capitalize(String(translationsByStatus[data.status])) : ''

  return (
    <Chip.Basic
      label={label}
      color={color}
    />
  )
}
