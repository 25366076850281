/* eslint-disable indent */

import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BsCodeSlash, BsFillArrowUpCircleFill, BsFillCheckSquareFill, BsFillFilePersonFill, BsNewspaper, BsNodePlusFill, BsPencilFill, BsPlus, BsTrashFill } from 'react-icons/bs'
import { ApolloCache, useLazyQuery, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import ProfileContext from '#app/contexts/ProfileContext'
import { THEME } from '#app/layouts/theme'
import { useEditTaskMutation } from '#app/operations/tasks/tasks.mutations.generated'
import { Generic, Task } from '#app/types'
import { MUTATION, QUERY, QUERY_GET_USER_TASK } from '#app/utils/graphqlQueries'

import { useEntityUser } from './useEntityUser'
import useMutationWithNotification from './useMutationWithNotification'

export const TASK_STATUS = {
  COMPLETED: 'completed',
  RESPONSE_DRAFT: 'response_draft',
  ASSIGNED: 'assigned',
  BO_REPLIED: 'replied',
  ADMIN_COMPLETED: 'admin_completed',
  PROCESSING: 'processing'
}

export const DEFAULT_COGNITO_KEY = 'tuM6Cj9OnEGTISluWGNaSQ'

export function useEntityUserTasks(task?: Partial<Task.UserTask>, boID?: Generic.UserID, calledFrom?: string) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { getProfile: { user } } = useContext(ProfileContext)
  const { isAdmin } = useEntityUser()

  const getUserTasks = useMemo(() => {
    return isAdmin ? QUERY.tasks.getUserTasksAsAdmin : QUERY.tasks.getUserTasks
  }, [isAdmin])

  const updateCacheApollo = useCallback((mutationName: string, cache: ApolloCache<any>, data: any) => {
    // As an admin, I only need to see the tasks from a specific BO
    // Priority based 1. task, 2. bussiness owner user ID 3. current user
    const variables = {
      userID: task
        ? task.bo_id
        : boID ?? user?.id
    }
    const cacheQuery = cache.readQuery<QUERY_GET_USER_TASK>({
      query: getUserTasks,
      variables
    })

    // console.info(task)
    // console.info(variables)
    // console.info('cache query', cacheQuery)
    // console.info(data[mutationName])

    if (cacheQuery?.user_tasks && mutationName) {
      try {
        const taskData = data[mutationName]?.task
        const taskUpdated: Task.UserTask = {
          ...taskData,
          __typename: 'user_tasks'
        }

        let userTasksUpdated: Task.UserTask[]

        if (mutationName === 'createUserTask') {
          userTasksUpdated = [...(cacheQuery?.user_tasks ?? []), taskUpdated]
        }
        else {
          userTasksUpdated = cacheQuery?.user_tasks.map((task) => {
            if (task?.id === taskUpdated.id) {
              return taskUpdated
            }
            return task
          })
        }

        cache.writeQuery({
          query: getUserTasks,
          variables,
          data: {
            user_tasks: userTasksUpdated
          }
        })
      }
      catch (err) {
        enqueueSnackbar('We have an error updating the screen', {
          variant: 'error'
        })
      }
    }
  }, [task, boID, user?.id, getUserTasks, enqueueSnackbar])

  const [mutationReplyUserTask, { loading: mutationLoading }] = useMutation(MUTATION.tasks.replyUserTask, {
    update(cache, { data }) {
      updateCacheApollo('replyUserTask', cache, data)
    }
  })

  const [mutationDeleteTask, { loading: mutationDeleteLoading }] = useMutation(MUTATION.tasks.deleteUserTask, {
    update(cache, { data }) {
      updateCacheApollo('deleteUserTask', cache, data)
    }
  })

  const [mutationCreateUserTask, { loading: mutationCreateUserTaskLoading }] = useMutationWithNotification(MUTATION.tasks.createUserTask, {
    update(cache, { data }) {
      updateCacheApollo('createUserTask', cache, data)
    }
  })

  const [mutationEditUserTask, { loading: mutationEditUserTaskLoading }] = useEditTaskMutation()

  const getTaskStatusOrder: (status: string) => number = useCallback((status: string | undefined) => {
    switch (status) {
      case TASK_STATUS.ASSIGNED: return 1
      case TASK_STATUS.RESPONSE_DRAFT: return 2
      case TASK_STATUS.COMPLETED: return 2
      case TASK_STATUS.ADMIN_COMPLETED: return 2
      case TASK_STATUS.BO_REPLIED: return 3
      default:
        return 2
    }
  }, [])

  const [
    queryGetUserTasks,
    queryGetUserTasksUtils] = useLazyQuery<QUERY_GET_USER_TASK>(getUserTasks)

  const [
    queryGetUserTasksPending,
    queryGetUserTasksPendingUtils] = useLazyQuery<QUERY_GET_USER_TASK>(QUERY.tasks.getUserTasksReplied)

  const [
    queryGetUserTaskById,
    queryGetUserTaskByIdUtils] = useLazyQuery<QUERY_GET_USER_TASK>(QUERY.tasks.getUserTask)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultIconStyle = {
    fontSize: THEME.ICONS.size.sm,
    style: {
      margin: 2
    }
  }

  const getIconByStatus = useCallback((statusTmp: string) => {
    switch (statusTmp) {
      case 'bo_created':
        return <BsFillArrowUpCircleFill />
      case 'create':
        return <BsPlus {...defaultIconStyle} />
      case TASK_STATUS.ASSIGNED:
        return <BsFillFilePersonFill {...defaultIconStyle} />
      case TASK_STATUS.RESPONSE_DRAFT:
        return <BsPencilFill {...defaultIconStyle} />
      case TASK_STATUS.COMPLETED:
        return <BsFillCheckSquareFill {...defaultIconStyle} />
      case TASK_STATUS.ADMIN_COMPLETED:
        return <BsFillCheckSquareFill {...defaultIconStyle} />
      case 'system':
        return <BsCodeSlash {...defaultIconStyle} />
      case 'deleted':
        return <BsTrashFill {...defaultIconStyle} />
      default:
        return <BsFillFilePersonFill {...defaultIconStyle} />
    }
  }, [defaultIconStyle])

  const getIconByMetadata = useCallback((metadataField: string) => {
    switch (metadataField) {
      case 'template_id':
        return <BsNewspaper {...defaultIconStyle} />
      case 'sequence_id':
        return <BsNodePlusFill {...defaultIconStyle} />
    }
  }, [defaultIconStyle])

  const getColorFromStatus = useCallback((status: string | undefined) => {
    switch (status) {
      case TASK_STATUS.PROCESSING:
        return 'warning'
      case TASK_STATUS.ADMIN_COMPLETED:
        return 'success'
      case TASK_STATUS.COMPLETED:
        return 'success'
      case TASK_STATUS.RESPONSE_DRAFT:
        return 'warning'
      case TASK_STATUS.ASSIGNED:
        return 'primary'
      default:
        return 'primary'
    }
  }, [])

  const getTextFromStatus = useCallback((status: string | undefined) => {
    switch (status) {
      case TASK_STATUS.PROCESSING:
        return t('user_tasks.status.processing')
      case TASK_STATUS.ADMIN_COMPLETED:
        return t('user_tasks.status.admin_completed')
      case TASK_STATUS.COMPLETED:
        return t('user_tasks.status.completed')
      case TASK_STATUS.RESPONSE_DRAFT:
        return t('user_tasks.status.response_draft')
      case TASK_STATUS.ASSIGNED:
        return t('user_tasks.status.assigned')
      case TASK_STATUS.BO_REPLIED:
        return t('user_tasks.status.replied')
      case 'deleted':
        return t('user_tasks.status.deleted')
      default:
        return status
    }
  }, [t])

  return {
    // GraphQL Query
    queryGetUserTaskById,
    queryGetUserTaskByIdUtils,
    // GraphQL Query
    queryGetUserTasks,
    queryGetUserTasksUtils,
    // Graphql Query
    queryGetUserTasksPending,
    queryGetUserTasksPendingUtils,
    // GraphQL Mutation
    mutationReplyUserTask,
    mutation: {
      mutationLoading,
      mutationDeleteLoading
    },
    mutationDeleteTask,
    // GraphQL Mutation
    mutationCreateUserTask,
    mutationCreateUserTaskLoading,
    // GraphQL Mutation
    mutationEditUserTask,
    mutationEditUserTaskLoading,
    // Helpers
    getTaskStatusOrder,
    getIconByStatus,
    getIconByMetadata,
    getTextFromStatus,
    getColorFromStatus
  }
}
