import { useEffect, useRef, useState } from 'react'
import debounce from 'lodash/debounce'

import { Activities } from '#app/operations/autogenerate/schemas'

const BOTTOM_NAV_HEIGHT = 94

export default function useReadActivities(activities: Array<Partial<Activities>>) {
  const [activitiesInViewport, setActivitiesInViewport] = useState<string[]>([])
  const observer = useRef<IntersectionObserver | null>(null)
  const readActivities = useRef<Set<string>>()

  const debouncedCallback = useRef(
    debounce((entries: IntersectionObserverEntry[]) => {
      const visibleActivities = entries
        .filter((entry) => entry.isIntersecting)
        .map((entry) => {
          const target = entry.target as HTMLElement
          return target.dataset.activityId ?? ''
        })

      setActivitiesInViewport((prev) => [...prev, ...visibleActivities])
    }, 300)
  )

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      (entries) => debouncedCallback.current(entries),
      {
        threshold: 1,
        rootMargin: `0px 0px -${BOTTOM_NAV_HEIGHT}px 0px`
      }
    )

    observer.current = intersectionObserver
    return () => {
      setActivitiesInViewport([])
      if (observer.current) {
        observer.current.disconnect()
      }
    }
  }, [debouncedCallback])

  useEffect(() => {
    activities.forEach((activity) => {
      const target = document.getElementById(`activity-${activity.id as string}`)

      if (target && observer.current) {
        observer.current.observe(target)
      }
    })
  }, [activities])

  return {
    activitiesInViewport,
    readActivities
  }
}
