import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsArchive } from 'react-icons/bs'
import { NumericFormat } from 'react-number-format'
import { Box, Grid, Stack, Typography } from '@mui/material'

import RightSideBar, { RightSideBarContainer } from '#app/components/RightSideBar/RightSideBar'
import { Table } from '#app/components/Table'
import { TableStructure } from '#app/components/Table/Table'
import { useDayJs } from '#app/hooks/useDayJs'
import { GetTransactionsQuery } from '#app/operations/banking/banking.queries.generated'

import { StatusChip } from './StatusChip'
import { TransactionDetails } from './TransactionDetails'
import { TypeIcon } from './TypeIcon'

export interface TransactionRowData {
  date: string
  type: string
  amount: number
  status: string
  metadata: any
}

export const TransactionsTable = ({ loading, data }: { loading: boolean, data?: GetTransactionsQuery }) => {
  // Date, email, amount, status
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false)
  const [transactionDetails, setTransactionDetails] = useState<TransactionRowData>()
  const { formatDateInFormat } = useDayJs()
  const { t } = useTranslation()

  const rowData: TransactionRowData[] | undefined = data
    ?.bank_transactions
    ?.map((transaction) => {
      const rowData: TransactionRowData = {
        date: transaction?.created_at ?? '',
        type: transaction?.type ?? '',
        amount: transaction?.amount ?? 0,
        status: transaction?.status ?? '',
        metadata: transaction?.metadata ?? {}
      }

      return rowData
    })

  const desktopRows = rowData
    ?.map((row, idx) => {
      return {
        key: String(idx),
        data: row,
        columns: [
          {
            component: (
              <Box>
                <TypeIcon data={row} />
              </Box>
            )
          },
          {
            component: <Box>{ formatDateInFormat(String(row.date), 'YYYY-MM-DD') }</Box>
          },
          {
            component: <Box>
              <NumericFormat
                thousandSeparator=','
                style={{
                  fontWeight: 'bold'
                }}
                displayType='text'
                value={row.amount / 100}
                prefix='$'
              />
            </Box>
          },
          {
            component: <Box><StatusChip data={row} /></Box>
          }
        ]
      }
    }) ?? []

  const mobileRows = rowData
    ?.map((row, idx) => {
      return {
        key: String(idx),
        data: row,
        columns: [{
          component: (
            <Stack
              direction='row'
              justifyContent='space-between'
              spacing={3}
              alignItems='center'
            >
              <Stack
                direction='row'
                alignItems='center'
                spacing={2}
              >
                <TypeIcon data={row} />
                <Stack>
                  <NumericFormat
                    thousandSeparator=','
                    style={{
                      fontWeight: 'bold'
                    }}
                    displayType='text'
                    value={row.amount / 100}
                    prefix='$'
                  />
                  <small>{formatDateInFormat(String(row.date), 'YYYY-MM-DD')}</small>
                </Stack>
              </Stack>
              <Stack
                direction='row'
                spacing={2}
              >
                <StatusChip data={row} />
              </Stack>
            </Stack>
          )
        }]
      }
    }) ?? []

  const onRowClick = (data: TransactionRowData) => {
    setTransactionDetails(data)
    setDetailsOpen(true)
  }

  const tableStructure: TableStructure = {
    desktop: {
      headers: [{
        text: t('transactionsTable.headers.type', 'Type'),
        style: {
          width: '25%'
        }
      },
      {
        text: t('transactionsTable.headers.date', 'Date'),
        style: {
          width: '25%'
        }
      },
      {
        text: t('transactionsTable.headers.amount', 'Amount'),
        style: {
          width: '25%'
        }
      },
      {
        text: t('transactionsTable.headers.status', 'Status'),
        style: {
          width: '25%'
        }
      }],
      rows: desktopRows
    },
    mobile: {
      rows: mobileRows
    }
  }

  return (
    <>
      <Table.Custom
        isLoading={loading}
        structure={tableStructure}
        wrapInTableRow={true}
        onRowClick={onRowClick}
      />
      {
        (!rowData || rowData.length === 0) && (
          <Grid
            container
            spacing={1}
            direction='row'
            justifyContent='center'
            sx={{
              mt: 3
            }}
          >
            <Grid item>
              <Stack
                alignItems='center'
                spacing={2}
              >
                <BsArchive
                  size={50}
                  color='var(--items-admin-nav-color)'
                />
                <Typography color='var(--items-admin-nav-color)'>
                  { t('transactionsTable.noTransactions', 'No transactions') }
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        )
      }
      <RightSideBar
        isOpen={detailsOpen}
        closeHandler={() => setDetailsOpen(false)}
      >
        <RightSideBarContainer>
          { transactionDetails ? <TransactionDetails transactionDetails={transactionDetails} /> : <></> }
        </RightSideBarContainer>
      </RightSideBar>
    </>
  )
}
