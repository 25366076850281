import { BusinessOwnerModular } from '../../../feature/BusinessOwnerModular'

import { Header } from './Header'
import OwnerDetails from './OwnerDetails'

export function OwnersManagement() {
  return (
    <BusinessOwnerModular
      pathname='owners-management'
      header={Header}
      contentByOwner={OwnerDetails}
    />
  )
}
