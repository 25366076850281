import React from 'react'
import { Grid } from '@mui/material'
import { Field, useField } from 'formik'
import { isArray } from 'lodash'
import { v4 as uuid } from 'uuid'

/* <RadioButtonGroup
  name="checked"
  hasBackground
  initialValue={values.checked}
>
  <RadioButtonGroup.RadioButton value="true">
  Yes
  </RadioButtonGroup.RadioButton>
  <RadioButtonGroup.RadioButton value="false">
  No
  </RadioButtonGroup.RadioButton>
</RadioButtonGroup> */

const RadioButtonGroup = ({ name, initialValue, hasBackground, children, label }) => {
  const [, meta] = useField({
    name
  })

  return (
    <>
      { label && <label className='form-label'>{label}</label>}
      <Grid
        container
        spacing={1}
        className='radio-button-group'
      >
        {
          children
            .map((child, idx) => {
              return (
                <Grid
                  key={`${name}-${uuid()}`}
                  item
                  sx={{
                    width: '100%'
                  }}
                >
                  {React.cloneElement(child, {
                    meta,
                    name,
                    initialValue,
                    hasBackground,
                    id: `${name}-${uuid()}`
                  })}
                </Grid>
              )
            })
        }
      </Grid>
      <div className='error'>{ meta.touched && meta.error }</div>
    </>
  )
}

const LabelContainer = ({ children }) => {
  return (
    <div className='d-flex flex-column justify-content-start align-items-start'>
      { children }
    </div>
  )
}

RadioButtonGroup.RadioButton = ({ meta, value, initialValue, hasBackground, name, id, children, ...props }) => {
  const isChecked = initialValue
    ? isArray(initialValue) ? initialValue.includes(value) : initialValue === value
    : false
  return (
    <label
      className={`form-check ${isChecked ? 'form-check-checked' : ''} ${hasBackground ? 'form-check-background' : ''} ${meta.touched && meta.error ? 'form-check-checked-error' : ''} w-100`}
      htmlFor={id}
    >
      <Field
        id={id}
        name={name}
        type='radio'
        value={value}
        className='form-check-input'
      />
      <label
        className='form-check-label'
        htmlFor={id}
      >
        <LabelContainer>
          { children }
        </LabelContainer>
      </label>
    </label>
  )
}

RadioButtonGroup.CheckboxButton = ({ meta, value, initialValue, hasBackground, name, id, children, ...props }) => {
  const isChecked = initialValue
    ? isArray(initialValue) ? initialValue.includes(value) : initialValue === value
    : false
  return (
    <label
      className={`form-check ${isChecked ? 'form-check-checked' : ''} ${hasBackground ? 'form-check-background' : ''} ${meta.touched && meta.error ? 'form-check-checked-error' : ''} w-100`}
      htmlFor={id}
    >
      <Field
        id={id}
        name={name}
        type='checkbox'
        value={value}
        className='form-check-input'
        {...props}
      />
      <label
        className='form-check-label'
        htmlFor={id}
      >
        <LabelContainer>
          { children }
        </LabelContainer>
      </label>
    </label>
  )
}

export default RadioButtonGroup
