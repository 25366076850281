import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useSnackbar } from 'notistack'
import pluralize from 'pluralize'

import { Btn } from '#app/components/Buttons'
import { Dialog } from '#app/components/Dialog'
import { useEntityComments } from '#app/hooks/useEntityComments'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { Comment, Generic } from '#app/types'

import { CommentBox } from './Comment'
import { NewComment } from './NewComment'

import './style.css'

export interface PropsCommentsThread {
  receiverUserId?: Generic.UUID // bo id or null
  syncWithLinear?: boolean
  entityId: Generic.UUID
  entityType: string // 'user_tasks'
  isThreadClosed?: boolean
  scrollToLastComment?: boolean
}

export function CommentsThread({ receiverUserId, syncWithLinear, entityId, entityType, isThreadClosed, scrollToLastComment }: PropsCommentsThread) {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const lastCommentRef = useRef<HTMLElement>(null)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteId, setConfirmDeleteId] = useState<string>()
  const { isAdmin } = useEntityUser()
  useEffect(() => {
    if (scrollToLastComment && lastCommentRef) {
      // using a timeout to ensure it happens on a next cycle and not immediately after rendering
      setTimeout(() => {
        const topPos = lastCommentRef
          ?.current
          ?.getBoundingClientRect()
          ?.top

        const topPosWithOffset = (topPos ?? 0) - 70

        window.scrollTo({
          top: topPosWithOffset,
          behavior: 'smooth'
        })
      }, 250)
    }
  }, [scrollToLastComment])

  const {
    queryGetComments,
    queryGetCommentsData,
    queryGetCommentsUtils,
    mutationDeleteComment
  } = useEntityComments({
    entityId,
    entityType,
    private_note: isAdmin
  })

  const currentComments: Comment.CommentType[] = useMemo(() => {
    if (queryGetCommentsData?.comments) return queryGetCommentsData.comments
    return []
  }, [queryGetCommentsData])

  const initView = useCallback(async () => {
    await queryGetComments()
  }, [queryGetComments])

  const qtyComments = useMemo(() => {
    if (currentComments?.length && currentComments.length > 0) return currentComments.length
    return 0
  }, [currentComments])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initView()
  }, [initView, entityId])

  const refreshComments = useCallback(async () => {
    await queryGetCommentsUtils.refetch()
    enqueueSnackbar('Comments updated successfully')
  }, [enqueueSnackbar, queryGetCommentsUtils])

  return (
    <Box mt={6}>
      <Dialog.Delete
        open={confirmDelete}
        isLoading={false}
        setOpen={setConfirmDelete}
        onConfirm={async () => {
          await mutationDeleteComment({
            variables: {
              id: confirmDeleteId
            }
          })
          console.info(`Confirmed! ${confirmDeleteId ?? ''}`)
        }}
      />
      <Stack
        spacing={2}
        mb={4}
      >
        <Typography
          mb={2}
          variant='h5'
        >
          {qtyComments}
          {' '}
          {pluralize(t(`${entityType}.replies`), qtyComments)}
          <Btn.IconButton.Sync onClick={refreshComments} />
        </Typography>
        {
          queryGetCommentsData?.comments && queryGetCommentsData?.comments.map((comment: Comment.CommentType, key: number) => {
            const isLast = key === (queryGetCommentsData.comments.length - 1)

            const refProp: { ref?: RefObject<HTMLElement> } = {}

            if (isLast && lastCommentRef) {
              refProp.ref = lastCommentRef
            }

            if (comment.thread_entity_id !== entityId) {
              return (<></>)
            }

            return (
              <Box {...refProp}>
                <CommentBox
                  comment={comment}
                  cbDelete={(id: Generic.CommentID) => {
                    setConfirmDeleteId(id)
                    setConfirmDelete(true)
                  }}
                />
              </Box>
            )
          })
        }
        {
          syncWithLinear && isAdmin
          && <Alert severity='error'>We recommend you write your comment from Linear. This task is linked to a Linear issue, all comments you make will not be reflected in Linear (only the Bussiness Owners comments will be reflected in Linear)</Alert>
        }
        {
          isThreadClosed
          && <Alert severity='success'>{t('user_tasks.threadClosedAlert')}</Alert>
        }
      </Stack>
      {
        !isThreadClosed
        && (
          <NewComment config={{
            receiverUserId,
            entityId,
            entityType,
            isThreadClosed
          }}
          />
        )
      }
    </Box>
  )
}
