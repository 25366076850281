import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type CreateInvoiceMutationVariables = Types.Exact<{
  user_id: Types.Scalars['uuid']
  business_id?: Types.InputMaybe<Types.Scalars['uuid']>
  job_id?: Types.InputMaybe<Types.Scalars['uuid']>
  customer_id?: Types.InputMaybe<Types.Scalars['uuid']>
  metadata?: Types.InputMaybe<Types.Scalars['jsonb']>
  status?: Types.InputMaybe<Types.Scalars['String']>
  total?: Types.InputMaybe<Types.Scalars['Int']>
}>

export type CreateInvoiceMutation = { __typename?: 'mutation_root', createInvoice?: { __typename?: 'CreateInvoiceOutput', invoice: { __typename?: 'Invoice', id?: any | undefined, created_at?: string | undefined, user_id?: any | undefined, business_id?: any | undefined, total?: number | undefined, job_id?: any | undefined, status?: string | undefined, metadata?: any | undefined, customer?: { __typename?: 'Customer', id?: string | undefined, first_name?: string | undefined, last_name?: string | undefined, phone?: string | undefined, address?: any | undefined, email?: string | undefined, source?: string | undefined } | undefined } } | undefined }

export type EditInvoiceMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']
  user_id?: Types.InputMaybe<Types.Scalars['uuid']>
  business_id?: Types.InputMaybe<Types.Scalars['uuid']>
  job_id?: Types.InputMaybe<Types.Scalars['uuid']>
  customer_id?: Types.InputMaybe<Types.Scalars['uuid']>
  metadata?: Types.InputMaybe<Types.Scalars['jsonb']>
  status?: Types.InputMaybe<Types.Scalars['String']>
  total?: Types.InputMaybe<Types.Scalars['Int']>
}>

export type EditInvoiceMutation = { __typename?: 'mutation_root', editInvoice?: { __typename?: 'EditInvoiceOutput', invoice: { __typename?: 'Invoice', id?: any | undefined, created_at?: string | undefined, user_id?: any | undefined, business_id?: any | undefined, total?: number | undefined, job_id?: any | undefined, status?: string | undefined, metadata?: any | undefined, customer?: { __typename?: 'Customer', id?: string | undefined, first_name?: string | undefined, last_name?: string | undefined, phone?: string | undefined, address?: any | undefined, email?: string | undefined, source?: string | undefined } | undefined } } | undefined }

export type DeleteInvoiceMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']
  user_id: Types.Scalars['uuid']
}>

export type DeleteInvoiceMutation = { __typename?: 'mutation_root', deleteInvoice?: { __typename?: 'DeleteInvoiceOutput', invoice: { __typename?: 'Invoice', id?: any | undefined, created_at?: string | undefined, user_id?: any | undefined, business_id?: any | undefined, total?: number | undefined, job_id?: any | undefined, status?: string | undefined, metadata?: any | undefined, customer?: { __typename?: 'Customer', id?: string | undefined, first_name?: string | undefined, last_name?: string | undefined, phone?: string | undefined, address?: any | undefined, email?: string | undefined, source?: string | undefined } | undefined } } | undefined }

export type ShareInvoiceMutationVariables = Types.Exact<{
  invoiceId: Types.Scalars['uuid']
  channel: Types.Scalars['String']
  userId?: Types.InputMaybe<Types.Scalars['uuid']>
}>

export type ShareInvoiceMutation = { __typename?: 'mutation_root', shareInvoice?: { __typename?: 'ShareInvoiceOutput', result?: boolean | undefined } | undefined }

export const CreateInvoiceDocument = gql`
    mutation createInvoice($user_id: uuid!, $business_id: uuid, $job_id: uuid, $customer_id: uuid, $metadata: jsonb, $status: String, $total: Int) {
  createInvoice(
    input: {user_id: $user_id, business_id: $business_id, job_id: $job_id, customer_id: $customer_id, metadata: $metadata, status: $status, total: $total}
  ) {
    invoice {
      id
      created_at
      user_id
      business_id
      total
      job_id
      status
      metadata
      customer {
        id
        first_name
        last_name
        phone
        address
        email
        source
      }
    }
  }
}
    `
export type CreateInvoiceMutationFn = Apollo.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      business_id: // value for 'business_id'
 *      job_id: // value for 'job_id'
 *      customer_id: // value for 'customer_id'
 *      metadata: // value for 'metadata'
 *      status: // value for 'status'
 *      total: // value for 'total'
 *   },
 * });
 */
export function useCreateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, options)
}
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>
export type CreateInvoiceMutationResult = Apollo.MutationResult<CreateInvoiceMutation>
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>
export const EditInvoiceDocument = gql`
    mutation editInvoice($id: uuid!, $user_id: uuid, $business_id: uuid, $job_id: uuid, $customer_id: uuid, $metadata: jsonb, $status: String, $total: Int) {
  editInvoice(
    input: {id: $id, user_id: $user_id, business_id: $business_id, job_id: $job_id, customer_id: $customer_id, metadata: $metadata, status: $status, total: $total}
  ) {
    invoice {
      id
      created_at
      user_id
      business_id
      total
      job_id
      status
      metadata
      customer {
        id
        first_name
        last_name
        phone
        address
        email
        source
      }
    }
  }
}
    `
export type EditInvoiceMutationFn = Apollo.MutationFunction<EditInvoiceMutation, EditInvoiceMutationVariables>

/**
 * __useEditInvoiceMutation__
 *
 * To run a mutation, you first call `useEditInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInvoiceMutation, { data, loading, error }] = useEditInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user_id: // value for 'user_id'
 *      business_id: // value for 'business_id'
 *      job_id: // value for 'job_id'
 *      customer_id: // value for 'customer_id'
 *      metadata: // value for 'metadata'
 *      status: // value for 'status'
 *      total: // value for 'total'
 *   },
 * });
 */
export function useEditInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<EditInvoiceMutation, EditInvoiceMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<EditInvoiceMutation, EditInvoiceMutationVariables>(EditInvoiceDocument, options)
}
export type EditInvoiceMutationHookResult = ReturnType<typeof useEditInvoiceMutation>
export type EditInvoiceMutationResult = Apollo.MutationResult<EditInvoiceMutation>
export type EditInvoiceMutationOptions = Apollo.BaseMutationOptions<EditInvoiceMutation, EditInvoiceMutationVariables>
export const DeleteInvoiceDocument = gql`
    mutation deleteInvoice($id: uuid!, $user_id: uuid!) {
  deleteInvoice(input: {id: $id, user_id: $user_id}) {
    invoice {
      id
      created_at
      user_id
      business_id
      total
      job_id
      status
      metadata
      customer {
        id
        first_name
        last_name
        phone
        address
        email
        source
      }
    }
  }
}
    `
export type DeleteInvoiceMutationFn = Apollo.MutationFunction<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>

/**
 * __useDeleteInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceMutation, { data, loading, error }] = useDeleteInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useDeleteInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>(DeleteInvoiceDocument, options)
}
export type DeleteInvoiceMutationHookResult = ReturnType<typeof useDeleteInvoiceMutation>
export type DeleteInvoiceMutationResult = Apollo.MutationResult<DeleteInvoiceMutation>
export type DeleteInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>
export const ShareInvoiceDocument = gql`
    mutation shareInvoice($invoiceId: uuid!, $channel: String!, $userId: uuid) {
  shareInvoice(input: {id: $invoiceId, channel: $channel, user_id: $userId}) {
    result
  }
}
    `
export type ShareInvoiceMutationFn = Apollo.MutationFunction<ShareInvoiceMutation, ShareInvoiceMutationVariables>

/**
 * __useShareInvoiceMutation__
 *
 * To run a mutation, you first call `useShareInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareInvoiceMutation, { data, loading, error }] = useShareInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      channel: // value for 'channel'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useShareInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<ShareInvoiceMutation, ShareInvoiceMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<ShareInvoiceMutation, ShareInvoiceMutationVariables>(ShareInvoiceDocument, options)
}
export type ShareInvoiceMutationHookResult = ReturnType<typeof useShareInvoiceMutation>
export type ShareInvoiceMutationResult = Apollo.MutationResult<ShareInvoiceMutation>
export type ShareInvoiceMutationOptions = Apollo.BaseMutationOptions<ShareInvoiceMutation, ShareInvoiceMutationVariables>
