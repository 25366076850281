import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import LogoOwners from '#app/assets/svg/logo-owners-light.svg'
import Images from '#app/v2/assets/Images'
import { Button } from '#app/v2/components/ui/button'

export const WizardSuccessCampaign = ({ homeUrl }: { homeUrl: string }) => {
  const { t } = useTranslation()
  return (
    <div className='min-h-screen bg-[#F7F9FB]'>
      <header className='bg-white py-4'>
        <img
          className='mx-auto h-6'
          src={LogoOwners}
          alt='Owners logo'
        />
      </header>
      <section className='mx-auto mt-8 flex max-w-sm flex-col items-center gap-5 px-2 text-center'>
        <img
          className=' mx-auto aspect-[3/2] w-[272px]'
          src={Images.WizardCampaingAchievment}
          alt='Sucess on completing all the steps'
        />
        <h1 className='text-lg font-semibold text-[#304985]'>{t('v2.wizard.successCampaign.title', 'Excelent!')}</h1>
        <p className=' font-[#454545] font-medium'>{t('v2.wizard.successCampaign.description', 'We have received your request, and a sales executive will contact you to proceed with the process.')}</p>
        <Button
          asChild
          className='mt-6 w-8/12 max-w-[242px] py-4 text-sm'
        >
          <Link to={homeUrl}>
            {t('user_tasks.success.goHome', 'Go home')}
          </Link>
        </Button>
      </section>
    </div>
  )
}
