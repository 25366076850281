import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'

import { CustomersListFragmentDoc } from './customers.fragments.generated'
const defaultOptions = {} as const
export type GetCustomersQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.Scalars['String']>
  limit?: Types.InputMaybe<Types.Scalars['Int']>
}>

export type GetCustomersQuery = { __typename?: 'query_root', customers: Array<{ __typename?: 'customers', id: any, first_name?: string | undefined, last_name?: string | undefined, email?: string | undefined, phone?: string | undefined, address?: any | undefined, lead?: string | undefined, user_id?: any | undefined, business_id?: any | undefined, search?: string | undefined, customer_histories: Array<{ __typename?: 'customer_histories', created_at: any, status?: string | undefined, metadata?: any | undefined }>, jobs: Array<{ __typename?: 'jobs', business_id?: any | undefined, created_at: any, customer_id?: any | undefined, description?: string | undefined, id: any, source?: string | undefined, status?: string | undefined, user_id?: any | undefined, job_histories: Array<{ __typename?: 'job_histories', created_at: any, end_status?: string | undefined, start_status?: string | undefined, metadata?: any | undefined }> }> }> }

export type GetCustomersLastSevenDaysQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.Scalars['String']>
  limit?: Types.InputMaybe<Types.Scalars['Int']>
  SEVEN_DAYS_AGO?: Types.InputMaybe<Types.Scalars['timestamptz']>
}>

export type GetCustomersLastSevenDaysQuery = { __typename?: 'query_root', customers: Array<{ __typename?: 'customers', id: any, first_name?: string | undefined, last_name?: string | undefined, email?: string | undefined, phone?: string | undefined, address?: any | undefined, lead?: string | undefined, user_id?: any | undefined, business_id?: any | undefined, search?: string | undefined, customer_histories: Array<{ __typename?: 'customer_histories', created_at: any, status?: string | undefined, metadata?: any | undefined }>, jobs: Array<{ __typename?: 'jobs', business_id?: any | undefined, created_at: any, customer_id?: any | undefined, description?: string | undefined, id: any, source?: string | undefined, status?: string | undefined, user_id?: any | undefined, job_histories: Array<{ __typename?: 'job_histories', created_at: any, end_status?: string | undefined, start_status?: string | undefined, metadata?: any | undefined }> }> }> }

export type GetBusinessCustomersQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.Scalars['String']>
  limit?: Types.InputMaybe<Types.Scalars['Int']>
  businessID?: Types.InputMaybe<Types.Scalars['uuid']>
}>

export type GetBusinessCustomersQuery = { __typename?: 'query_root', customers: Array<{ __typename?: 'customers', id: any, first_name?: string | undefined, last_name?: string | undefined, email?: string | undefined, phone?: string | undefined, address?: any | undefined, lead?: string | undefined, user_id?: any | undefined, business_id?: any | undefined, search?: string | undefined, customer_histories: Array<{ __typename?: 'customer_histories', created_at: any, status?: string | undefined, metadata?: any | undefined }>, jobs: Array<{ __typename?: 'jobs', business_id?: any | undefined, created_at: any, customer_id?: any | undefined, description?: string | undefined, id: any, source?: string | undefined, status?: string | undefined, user_id?: any | undefined, job_histories: Array<{ __typename?: 'job_histories', created_at: any, end_status?: string | undefined, start_status?: string | undefined, metadata?: any | undefined }> }> }> }

export const GetCustomersDocument = gql`
    query getCustomers($query: String, $limit: Int) {
  customers(
    limit: $limit
    where: {_and: [{status: {_neq: "deleted"}}, {search: {_ilike: $query}}, {_or: [{first_name: {_is_null: false}}, {last_name: {_is_null: false}}]}]}
    order_by: {created_at: desc}
  ) {
    ...customersList
  }
}
    ${CustomersListFragmentDoc}`

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCustomersQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options)
}
export function useGetCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options)
}
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>
export const GetCustomersLastSevenDaysDocument = gql`
    query getCustomersLastSevenDays($query: String, $limit: Int, $SEVEN_DAYS_AGO: timestamptz) {
  customers(
    limit: $limit
    where: {status: {_neq: "deleted"}, _and: [{_or: [{first_name: {_is_null: false}}, {last_name: {_is_null: false}}]}, {_or: [{jobs: {created_at: {_gte: $SEVEN_DAYS_AGO}}}, {customer_histories: {created_at: {_gte: $SEVEN_DAYS_AGO}}}]}, {_or: [{search: {_ilike: $query}}]}]}
    order_by: {jobs_aggregate: {max: {created_at: desc_nulls_last}}, customer_histories_aggregate: {max: {created_at: desc_nulls_last}}}
  ) {
    ...customersList
  }
}
    ${CustomersListFragmentDoc}`

/**
 * __useGetCustomersLastSevenDaysQuery__
 *
 * To run a query within a React component, call `useGetCustomersLastSevenDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersLastSevenDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersLastSevenDaysQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      SEVEN_DAYS_AGO: // value for 'SEVEN_DAYS_AGO'
 *   },
 * });
 */
export function useGetCustomersLastSevenDaysQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersLastSevenDaysQuery, GetCustomersLastSevenDaysQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetCustomersLastSevenDaysQuery, GetCustomersLastSevenDaysQueryVariables>(GetCustomersLastSevenDaysDocument, options)
}
export function useGetCustomersLastSevenDaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersLastSevenDaysQuery, GetCustomersLastSevenDaysQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetCustomersLastSevenDaysQuery, GetCustomersLastSevenDaysQueryVariables>(GetCustomersLastSevenDaysDocument, options)
}
export type GetCustomersLastSevenDaysQueryHookResult = ReturnType<typeof useGetCustomersLastSevenDaysQuery>
export type GetCustomersLastSevenDaysLazyQueryHookResult = ReturnType<typeof useGetCustomersLastSevenDaysLazyQuery>
export type GetCustomersLastSevenDaysQueryResult = Apollo.QueryResult<GetCustomersLastSevenDaysQuery, GetCustomersLastSevenDaysQueryVariables>
export const GetBusinessCustomersDocument = gql`
    query getBusinessCustomers($query: String, $limit: Int, $businessID: uuid) {
  customers(
    limit: $limit
    where: {status: {_neq: "deleted"}, business_id: {_eq: $businessID}, _or: [{search: {_ilike: $query}}]}
    order_by: {created_at: desc}
  ) {
    ...customersList
  }
}
    ${CustomersListFragmentDoc}`

/**
 * __useGetBusinessCustomersQuery__
 *
 * To run a query within a React component, call `useGetBusinessCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessCustomersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      businessID: // value for 'businessID'
 *   },
 * });
 */
export function useGetBusinessCustomersQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessCustomersQuery, GetBusinessCustomersQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetBusinessCustomersQuery, GetBusinessCustomersQueryVariables>(GetBusinessCustomersDocument, options)
}
export function useGetBusinessCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessCustomersQuery, GetBusinessCustomersQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetBusinessCustomersQuery, GetBusinessCustomersQueryVariables>(GetBusinessCustomersDocument, options)
}
export type GetBusinessCustomersQueryHookResult = ReturnType<typeof useGetBusinessCustomersQuery>
export type GetBusinessCustomersLazyQueryHookResult = ReturnType<typeof useGetBusinessCustomersLazyQuery>
export type GetBusinessCustomersQueryResult = Apollo.QueryResult<GetBusinessCustomersQuery, GetBusinessCustomersQueryVariables>
