import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type GenerateNamesAndDomainsMutationVariables = Types.Exact<{
  user_id: Types.Scalars['uuid']
  params: Types.Scalars['jsonb']
}>

export type GenerateNamesAndDomainsMutation = { __typename?: 'mutation_root', generateNamesAndDomains?: { __typename?: 'NamesAndDomainsPayload', results?: any | undefined } | undefined }

export type PurchaseDomainAndUpdateBusinessMutationVariables = Types.Exact<{
  user_id: Types.Scalars['uuid']
  domain: Types.Scalars['String']
  name: Types.Scalars['String']
}>

export type PurchaseDomainAndUpdateBusinessMutation = { __typename?: 'mutation_root', purchaseDomainAndUpdateBusiness?: { __typename?: 'PurchaseDomainAndUpdateBusinessPayload', result?: boolean | undefined } | undefined }

export type CreateWebsiteMutationVariables = Types.Exact<{
  bo_id: Types.Scalars['ID']
  metadata?: Types.InputMaybe<Types.Scalars['jsonb']>
}>

export type CreateWebsiteMutation = { __typename?: 'mutation_root', createWebsite?: { __typename?: 'CreateWebsitePayload', result: boolean, message?: string | undefined } | undefined }

export const GenerateNamesAndDomainsDocument = gql`
    mutation generateNamesAndDomains($user_id: uuid!, $params: jsonb!) {
  generateNamesAndDomains(input: {user_id: $user_id, params: $params}) {
    results
  }
}
    `
export type GenerateNamesAndDomainsMutationFn = Apollo.MutationFunction<GenerateNamesAndDomainsMutation, GenerateNamesAndDomainsMutationVariables>

/**
 * __useGenerateNamesAndDomainsMutation__
 *
 * To run a mutation, you first call `useGenerateNamesAndDomainsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateNamesAndDomainsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateNamesAndDomainsMutation, { data, loading, error }] = useGenerateNamesAndDomainsMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGenerateNamesAndDomainsMutation(baseOptions?: Apollo.MutationHookOptions<GenerateNamesAndDomainsMutation, GenerateNamesAndDomainsMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<GenerateNamesAndDomainsMutation, GenerateNamesAndDomainsMutationVariables>(GenerateNamesAndDomainsDocument, options)
}
export type GenerateNamesAndDomainsMutationHookResult = ReturnType<typeof useGenerateNamesAndDomainsMutation>
export type GenerateNamesAndDomainsMutationResult = Apollo.MutationResult<GenerateNamesAndDomainsMutation>
export type GenerateNamesAndDomainsMutationOptions = Apollo.BaseMutationOptions<GenerateNamesAndDomainsMutation, GenerateNamesAndDomainsMutationVariables>
export const PurchaseDomainAndUpdateBusinessDocument = gql`
    mutation purchaseDomainAndUpdateBusiness($user_id: uuid!, $domain: String!, $name: String!) {
  purchaseDomainAndUpdateBusiness(
    input: {user_id: $user_id, domain: $domain, name: $name}
  ) {
    result
  }
}
    `
export type PurchaseDomainAndUpdateBusinessMutationFn = Apollo.MutationFunction<PurchaseDomainAndUpdateBusinessMutation, PurchaseDomainAndUpdateBusinessMutationVariables>

/**
 * __usePurchaseDomainAndUpdateBusinessMutation__
 *
 * To run a mutation, you first call `usePurchaseDomainAndUpdateBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseDomainAndUpdateBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseDomainAndUpdateBusinessMutation, { data, loading, error }] = usePurchaseDomainAndUpdateBusinessMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      domain: // value for 'domain'
 *      name: // value for 'name'
 *   },
 * });
 */
export function usePurchaseDomainAndUpdateBusinessMutation(baseOptions?: Apollo.MutationHookOptions<PurchaseDomainAndUpdateBusinessMutation, PurchaseDomainAndUpdateBusinessMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<PurchaseDomainAndUpdateBusinessMutation, PurchaseDomainAndUpdateBusinessMutationVariables>(PurchaseDomainAndUpdateBusinessDocument, options)
}
export type PurchaseDomainAndUpdateBusinessMutationHookResult = ReturnType<typeof usePurchaseDomainAndUpdateBusinessMutation>
export type PurchaseDomainAndUpdateBusinessMutationResult = Apollo.MutationResult<PurchaseDomainAndUpdateBusinessMutation>
export type PurchaseDomainAndUpdateBusinessMutationOptions = Apollo.BaseMutationOptions<PurchaseDomainAndUpdateBusinessMutation, PurchaseDomainAndUpdateBusinessMutationVariables>
export const CreateWebsiteDocument = gql`
    mutation createWebsite($bo_id: ID!, $metadata: jsonb) {
  createWebsite(input: {bo_id: $bo_id, metadata: $metadata}) {
    result
    message
  }
}
    `
export type CreateWebsiteMutationFn = Apollo.MutationFunction<CreateWebsiteMutation, CreateWebsiteMutationVariables>

/**
 * __useCreateWebsiteMutation__
 *
 * To run a mutation, you first call `useCreateWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebsiteMutation, { data, loading, error }] = useCreateWebsiteMutation({
 *   variables: {
 *      bo_id: // value for 'bo_id'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useCreateWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateWebsiteMutation, CreateWebsiteMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<CreateWebsiteMutation, CreateWebsiteMutationVariables>(CreateWebsiteDocument, options)
}
export type CreateWebsiteMutationHookResult = ReturnType<typeof useCreateWebsiteMutation>
export type CreateWebsiteMutationResult = Apollo.MutationResult<CreateWebsiteMutation>
export type CreateWebsiteMutationOptions = Apollo.BaseMutationOptions<CreateWebsiteMutation, CreateWebsiteMutationVariables>
