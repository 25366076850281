import create from 'zustand'
import { devtools } from 'zustand/middleware'

import { Invoice } from '#app/operations/autogenerate/schemas'
import { UUID } from '#app/types/generic'

export interface InvoiceLineItem {
  quantity?: number
  unitCost?: number
  description?: string
  id?: string | number
  total?: number
}

export interface InvoiceDetails {
  id?: UUID
  customer_id?: UUID
  job_id?: UUID
  created_at?: string
  updated_at?: string
  user_id?: UUID
  business_id?: UUID
  status?: string
  total?: number
  download_link?: string
  customer?: {
    id?: UUID
    first_name?: string
    last_name?: string
    phone?: string
    address?: any
    email?: string
    source?: string
  }
  metadata?: {
    lineItems: InvoiceLineItem[]
    isEstimate?: boolean
    workOrderId?: string
    workOrderBidDetails?: any
  }
}

export interface InvoiceState {
  editInvoice: InvoiceDetails
  createInvoice: InvoiceDetails
  invoiceQuery: {
    where: any
    limit: number
  }
}

interface InvoiceActions {

  edit: {
    setInvoice: (data: InvoiceState['editInvoice']) => void
    addLineItem: (data: InvoiceLineItem) => void
    removeLineItem: (data: InvoiceLineItem) => void
    setCustomer: (data: Invoice['customer']) => void
  }

  create: {
    setInvoice: (data: InvoiceState['createInvoice']) => void
    addLineItem: (data: InvoiceLineItem) => void
    removeLineItem: (data: InvoiceLineItem) => void
    setCustomer: (data: Invoice['customer']) => void
  }

  setQuery: (data: InvoiceState['invoiceQuery']) => void
  reset: () => void
}

const initialState: InvoiceState = {
  createInvoice: {
    metadata: {
      lineItems: []
    },
    status: 'created',
    total: 0
  },
  editInvoice: {
    metadata: {
      lineItems: []
    },
    total: 0
  },
  invoiceQuery: {
    where: {
      status: {
        _neq: 'deleted'
      }
    },
    limit: 20
  }
}

export const useInvoiceStore = create<InvoiceState & InvoiceActions>()(
  devtools(
    (set) => ({
      ...initialState,

      edit: {
        setInvoice(data: InvoiceState['editInvoice']) {
          return set((state) => {
            return {
              ...state,
              editInvoice: {
                ...state.editInvoice,
                ...data
              }
            }
          })
        },

        addLineItem(data: InvoiceLineItem) {
          return set((state) => {
            data.unitCost = Number(data?.unitCost ?? 0) * 100
            data.total = data.unitCost * Number(data?.quantity ?? 0)

            const lineItems = state.editInvoice.metadata?.lineItems?.concat([data]) ?? []

            const total = lineItems.reduce((acc, line) => Number(acc) + Number(line.total), 0)

            return {
              ...state,
              editInvoice: {
                ...state.editInvoice,
                metadata: {
                  ...state.editInvoice.metadata,
                  lineItems
                },
                total
              }
            }
          })
        },

        removeLineItem(data: InvoiceLineItem) {
          return set((state) => {
            const lineItems = state.editInvoice.metadata?.lineItems?.filter((l) => l.id !== data.id) ?? []
            const total = lineItems.reduce((acc, line) => Number(acc) + Number(line.total), 0)

            return {
              ...state,
              editInvoice: {
                ...state.editInvoice,
                metadata: {
                  ...state.editInvoice.metadata,
                  lineItems
                },
                total
              }
            }
          })
        },

        setCustomer(data: Invoice['customer']) {
          return set((state) => {
            return {
              ...state,
              editInvoice: {
                ...state.editInvoice,
                customer: data
              }
            }
          })
        }
      },

      create: {
        setInvoice(data: InvoiceState['createInvoice']) {
          return set((state) => {
            return {
              ...state,
              createInvoice: {
                ...state.createInvoice,
                ...data
              }
            }
          })
        },

        addLineItem(data: InvoiceLineItem) {
          return set((state) => {
            data.unitCost = Number(data?.unitCost ?? 0) * 100
            data.total = data.unitCost * Number(data?.quantity ?? 0)

            const lineItems = state.createInvoice.metadata?.lineItems?.concat([data]) ?? []

            const total = lineItems.reduce((acc, line) => Number(acc) + Number(line.total), 0)

            return {
              ...state,
              createInvoice: {
                ...state.createInvoice,
                metadata: {
                  ...state.createInvoice.metadata,
                  lineItems
                },
                total
              }
            }
          })
        },

        removeLineItem(data: InvoiceLineItem) {
          return set((state) => {
            const lineItems = state.createInvoice.metadata?.lineItems?.filter((l) => l.id !== data.id) ?? []
            const total = lineItems.reduce((acc, line) => Number(acc) + Number(line.total), 0)

            return {
              ...state,
              createInvoice: {
                ...state.createInvoice,
                metadata: {
                  ...state.createInvoice.metadata,
                  lineItems
                },
                total
              }
            }
          })
        },

        setCustomer(data: Invoice['customer']) {
          return set((state) => {
            return {
              ...state,
              createInvoice: {
                ...state.createInvoice,
                customer: data
              }
            }
          })
        }
      },

      setQuery(query: InvoiceState['invoiceQuery']) {
        return set((state) => {
          return {
            ...state,
            invoiceQuery: {
              ...state.invoiceQuery,
              ...query
            }
          }
        })
      },

      reset() {
        set((state) => {
          return {
            ...state,
            createInvoice: {
              ...initialState.createInvoice
            },
            editInvoice: {
              ...initialState.editInvoice
            }
          }
        })
      }
    }),
    {
      name: 'zt_owners_invoice'
    }
  )
)
