import TextareaAutosize from '@mui/base/TextareaAutosize'
import { useField } from 'formik'

import FormLabel from '../FormLabel/FormLabel'

import './FormTextArea.css'

const FormTextArea = ({ placeholder = null, label, popoverText = null, ...props }) => {
  const [field, meta, helpers] = useField(props)

  const { setValue } = helpers

  return (
    <>
      { label && (
        <FormLabel
          label={label}
          popoverText={popoverText}
        />
      )}
      <TextareaAutosize
        error={(meta.touched && meta.error) ? meta.error : ''}
        value={meta.value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        {...props}
        {...field}
      />
      <div className='error'>{ meta.touched && meta.error }</div>
    </>
  )
}

export default FormTextArea
