import { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import ProfileContext from '#app/contexts/ProfileContext'

import { useAccessibleRoutes } from './useRoutes'

// @ts-ignore
export const Authorization = ({ children }) => {
  const location = useLocation()
  const { getProfile: { user } } = useContext(ProfileContext)
  const routes = useAccessibleRoutes(user)

  const isAllowed = routes
    .some((r) => {
      return r.pattern ? r.pattern.test(location.pathname) : r.path === location.pathname
    })

  if (!isAllowed) {
    const defaultRoute = routes.find((r) => r.default) || routes.find((r) => r.path === '/configuring')

    if (!defaultRoute) {
      return (
        <Navigate
          replace
          to='/not-found'
        />
      )
    }

    return (
      <Navigate
        replace
        to={defaultRoute.path}
      >
      </Navigate>
    )
  }

  return (
    <>
      { children }
    </>
  )
}
