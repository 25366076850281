import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Box, Card, CardActions, CardContent, Grid, Stack, Typography } from '@mui/material'
import { t } from 'i18next'

import { Btn } from '#app/components/Buttons'
import { LoadingGrid } from '#app/components/Loading/LoadingGrid'
import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import ProfileContext from '#app/contexts/ProfileContext'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { useShare, UseShareProps } from '#app/hooks/useShare'
import { EntityPage } from '#app/layouts/EntityView'
import { EventTypesByEmailQuery, useEventTypesByEmailQuery } from '#app/operations/booking/booking.queries.generated'

import { generateCalSignedUrl, generateEventURL } from '.'

interface Props {
  eventType: any
  dataEventTypes: EventTypesByEmailQuery
  setCalIframeURL: Dispatch<SetStateAction<string | null>>
}

function EventTypeItem({ eventType, dataEventTypes, setCalIframeURL }: Props) {
  const [shareData, setShareData] = useState<UseShareProps>()
  const { getProfile: { user } } = useContext(ProfileContext)

  const { onShare } = useShare(shareData)

  return (
    <Card sx={{
      minWidth: 275,
      mt: 2,
      mb: 2
    }}
    >
      <CardContent>
        <Typography
          gutterBottom
          sx={{
            fontSize: 14
          }}
          color='text.secondary'
        >
        </Typography>
        <Typography
          variant='h5'
          component='div'
        >
          {eventType.title}
        </Typography>
        <Typography
          sx={{
            mb: 1.5
          }}
          color='text.secondary'
        >
          /team/
          {dataEventTypes?.calcom_users[0]?.Memberships[0]?.Team?.slug}
          /
          {eventType.slug}
        </Typography>
        <Grid
          container
          spacing={6}
          direction='row'
          sx={{
            width: '100%'
          }}
        >
          <Grid
            item
            xs={12}
            lg={4}
            md={6}
          >
            <Typography>
              {t('booking.eventType.duration', 'Duration')}
              :
              {' '}
              {eventType.length}
              {' '}
              Min
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            md={6}
          >
            <Typography>

            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={2}
          sx={{
            width: '100%'
          }}
        >
          <Btn.Button
            primary
            variant='contained'
            onClick={() => setCalIframeURL(generateEventURL(dataEventTypes, eventType))}
          >
            {t('booking.eventType.preview', 'Preview')}
          </Btn.Button>
          <Box>
            <Btn.IconButton.Link
              onClick={(event) => {
                setShareData({
                  url: generateEventURL(dataEventTypes, event),
                  successText: t('booking.eventType.copiedSuccess', 'Event type copied successfully')
                })
                void onShare()
              }}
            />
            <Btn.IconButton.Edit onClick={() => setCalIframeURL(generateCalSignedUrl(`${process.env.REACT_APP_CAL_DOMAIN ?? ''}/event-types/${eventType?.id}?`, user))} />
          </Box>
        </Stack>
      </CardActions>
    </Card>
  )
}

export function EventTypes() {
  const { userEmail } = useEntityUser()

  const { data: dataEventTypes, loading: loadingEventType, startPolling } = useEventTypesByEmailQuery({
    variables: {
      email: userEmail ?? ''
    }
  })
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const [calIframeURL, setCalIframeURL] = useState<string | null>(null)

  useEffect(() => {
    startPolling(5000)
  }, [startPolling])

  return (
    <EntityPage
      hasReturn
      isLoading={false}
      layout='free'
      pageTitle={t('booking.eventTypes.title', 'Bookings Types')}
      pageSubtitle={t('booking.eventTypes.subTitle', 'Create events to share for people to book on your calendar.')}
      // actionLabel={t('booking.main.action', 'Preview Booking')}
      // onActionClick={() => setCreateOpen(true)}
      mainContent={(
        <>
          <RightSideBar
            size='lg'
            isOpen={!!calIframeURL}
            closeHandler={() => { setCalIframeURL(null); setIframeLoaded(false) }}
          >
            <>
              <Typography variant='h4'>
                {' '}
                <>
                  {t('booking.eventTypes.title', 'Bookings Types')}
                  {' '}
                </>
              </Typography>
              <Typography variant='subtitle2'>
                {' '}
                <>{t('booking.eventTypes.drawer', 'Editing this event type will affect the booking links you shared in the past.')}</>
                {' '}
              </Typography>
              {
                calIframeURL
                && (
                  <Box
                    sx={{
                      width: '100%',
                      height: '85vh'
                    }}
                    mt={2}
                  >
                    {
                      !iframeLoaded
                      && <LoadingGrid />
                    }
                    <iframe
                      height='100%'
                      width='100%'
                      src={calIframeURL}
                      onLoad={() => setIframeLoaded(true)}
                    />
                  </Box>
                )
              }
            </>
          </RightSideBar>

          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              {/* <CustomCard>
              <CustomCard.Body className='v1-card-bottom-padding'> */}
              {loadingEventType
              && <LoadingGrid />}
              {
                !loadingEventType && dataEventTypes?.calcom_users[0]?.Memberships[0]?.Team?.EventTypes?.map((eventType) => {
                  return (
                    <EventTypeItem
                      eventType={eventType}
                      dataEventTypes={dataEventTypes}
                      setCalIframeURL={setCalIframeURL}
                    />
                  )
                })
              }
              {/* </CustomCard.Body>
            </CustomCard> */}
            </Grid>
          </Grid>
        </>
      )}
    />
  )
}
