import produce from 'immer'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

interface State {
  key: string
  env_staging: { [key: string]: string }
  env_production: { [key: string]: string }
  zt_reset: () => void
}

//
// IMPORTANT: Only use for public keys or URLs
//
const INITIAL = {
  userData: undefined,
  zt_test: false,
  env_staging: {
    front_url: 'https://app-staging.joinowners.com',
    support_whatsapp: '13059129689',
    support_whatsapp_link: 'http://wa.me/13059129689',
    airtable_altbo_companies: 'https://airtable.com/apps7jlHLKF1oFUKl/tblVB9jZ3lV4Wujed',
    airtable_altbo_contacts: 'https://airtable.com/apps7jlHLKF1oFUKl/tblK3UJmaFrM8QUNd',
    airtable_create_bid: 'https://airtable.com/embed/shrBBbEGoo0x79ura',
    airtable_create_service_request: 'https://airtable.com/embed/shrGkDwShBRDmWfIZ',
    airtable_create_work_order: 'https://airtable.com/embed/shr3mq5M4DTKKKz8U',
    airtable_create_bo: 'https://airtable.com/embed/shr4SoUk0hfxcy3pU',
    airtable_table_bids: 'https://airtable.com/appks6mSx1DPfvfRC/tbliOSX54p3EnUk4F',
    airtable_table_work_order_evidence: 'https://airtable.com/appks6mSx1DPfvfRC/tblj5gR1LZbXxt7oV/viw56NceDhwknm95F',
    airtable_table_work_orders: 'https://airtable.com/appks6mSx1DPfvfRC/tblEuPQZkG52pFQ4E/viwgTN0P2jxT9qK85',
  },
  env_production: {
    front_url: 'https://app.joinowners.com',
    support_whatsapp: '13059129689',
    support_whatsapp_link: 'http://wa.me/13059129689',
    airtable_altbo_companies: 'https://airtable.com/app8KfWDtTwDckSBT/tblBe5UVLuMGK9h5L',
    airtable_altbo_contacts: 'https://airtable.com/app8KfWDtTwDckSBT/tblqGQkiSOioWvSEL',
    airtable_create_bid: 'https://airtable.com/embed/shrFWWjEFiI8wkJjs',
    airtable_create_service_request: 'https://airtable.com/embed/shrrTJicOMf9vXYT3',
    airtable_create_work_order: 'https://airtable.com/embed/shrJM46zL2G11IZ17',
    airtable_create_bo: 'https://airtable.com/embed/shrhLCmzQXYKLwQ1k',
    airtable_table_bids: 'https://airtable.com/app6WemyuBZyCO9MI/tbl4i0XL1ZpnKdeZL/viw5k0vzuaJopHc1C',
    airtable_table_work_order_evidence: 'https://airtable.com/app6WemyuBZyCO9MI/tblooD5uObcaT4VGr/viwG5c0wr8x8bx6AI',
    airtable_table_work_orders: 'https://airtable.com/app6WemyuBZyCO9MI/tblOKT80qepZk3uWM/viwooA42Om3EPwu6C',
  },
}

export const ENVIROMENT = process.env.REACT_APP_ENV === 'production' ? INITIAL.env_production : INITIAL.env_staging

export const useStoreUser = create<State>()(
  devtools(
    (set) => ({
      key: 'ZT_owners_enviroment',
      ...INITIAL,
      zt_reset: () =>
        set(
          produce((originalState) => {
            const state = originalState
            Object.keys(INITIAL).map((key) => {
              // @ts-expect-error TODO Improve clean zustand
              state[key] = INITIAL[key]
              return null
            })
          }),
          false,
          'zt_reset',
        ),
    }),
    {
      name: 'ZT_owners_enviroment'
    },
  ),
)
