import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import isFuture from 'date-fns/isFuture'

import OwnersLogo from '#app/assets/img/credit_card_owners_v2.png'
import { useUIStories } from '#app/hooks/useUIStories'
import { Activities } from '#app/operations/autogenerate/schemas'
import { CalendarStar, CallCenter, ClipboardCheck, DollarCoin, Star1 } from '#app/v2/assets/Icons'
import { Button } from '#app/v2/components/ui/button'
import { cn } from '#app/v2/libs/utils'
import { NotificationCategory, NotificationType } from '#app/v2/pages/ActivityCenter/types'

import NotificationCardItems from './NotificationCardItems'

const OwnersIcon = () => {
  const iconClass = 'flex h-full w-full items-center justify-center rounded-full bg-[#304985]'
  return (
    <div className={iconClass}>
      <img
        src={OwnersLogo}
        alt='Owners logo'
        className=' h-4 w-4'
      />
    </div>
  )
}

const ICON_MAP: { [key in NotificationCategory]: JSX.Element } = {
  clients: <OwnersIcon />,
  leads: <OwnersIcon />,
  appointments: <CalendarStar />,
  jobs: <CalendarStar />,
  reviews: <Star1 />,
  estimates: <OwnersIcon />,
  invoices: <DollarCoin />,
  money: <DollarCoin />,
  tasks: <ClipboardCheck />,
  chat: <OwnersIcon />,
  onboarding: <OwnersIcon />,
  callCenter: <CallCenter />,
}

interface NotificationCardProps extends Partial<Activities> {
  homePage?: boolean
  prevCard?: boolean
  handleHomeCard?: () => void
}

export const NotificationCard = (props: NotificationCardProps) => {
  const isNew = !props.read

  const { category, type, data: payload, created_at, homePage, prevCard, handleHomeCard } = props
  const date = isFuture(new Date(created_at)) ? new Date() : new Date(created_at)

  const timeSpan = !homePage && formatDistanceToNow(date, {
    addSuffix: true
  })

  const IconCategory = useCallback((activityCategory: NotificationCategory) => {
    if (ICON_MAP[activityCategory]) return ICON_MAP[activityCategory]
    return <OwnersIcon />
  }, [])

  const { isStoryMode } = useUIStories()

  const NotificationComponentMap = isStoryMode ? NotificationCardItems.NotificationComponentStory : NotificationCardItems.NotificationComponent

  const Component = NotificationComponentMap[type as NotificationType]

  const linkMap: Record<NotificationType, string> = {
    taskAssigned: `../task-detail/${payload.id}`,
    taskCompleted: `../task-detail/${payload.id}`,
    taskCommented: `../task-detail/${payload.id}`,
    unreadMessage: '../messages',
    onboardingCompleted: '../home',
    interestedBrowsing: '',
    interestedCallback: '',
    interestedUnavailable: '',
    potentialLeadLost: '',
    appointmentBooked: '',
    spamNumber: '',
    appointmentFollowUp: '',
    lookingForJob: '',
    voicemailLeft: '',
    rescheduling: '',
    reschedulingNoAnswer: '',
  }

  const url = linkMap[type as NotificationType]

  const Container = url && !homePage ? Link : 'div'

  if (!Component) return null

  return (
    <Container
      className={cn('block rounded-xl px-2 py-3', {
        'bg-[#CED9F3] bg-opacity-30': isNew && !homePage,
        'bg-gray-50': !isNew && !homePage,
        'bg-white p-4 border-1 border-[#EFEFEF] max-w-96 mx-auto shadow flex gap-2 flex-col justify-between': homePage
      })}
      to={linkMap[type as NotificationType]}
    >
      <div
        id={`activity-${props.id}`}
        data-activity-id={props.id}
        className={cn('flex w-full items-center gap-2', {
          'items-start': homePage

        })}
      >
        <div className={cn('h-6 w-6 shrink-0 text-[#304985]', {
          'mt-1': homePage
        })}
        >
          {IconCategory(category as NotificationCategory)}
        </div>
        <p className={cn('grow text-sm text-[#454545]', {
          'text-base line-clamp-4': homePage,
          'line-clamp-2': prevCard
        })}
        >
          <Component {...payload} />
        </p>
        <p className={cn('whitespace-nowrap text-xs font-medium text-[#959595]', {
          hidden: homePage
        })}
        >
          {timeSpan}
        </p>
      </div>
      <div className={cn('hidden w-full justify-end gap-2', {
        flex: homePage
      })}
      >
        <Button
          asChild
          variant='outline'
          className='rounded-lg py-1.5'
        >
          <Link to={linkMap[type as NotificationType]}>
            View Details
          </Link>
        </Button>
        <Button
          className='rounded-lg py-1.5'
          onClick={handleHomeCard ?? handleHomeCard}
        >
          Done
        </Button>
      </div>
    </Container>
  )
}
