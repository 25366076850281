import { Box, BoxProps, styled, TextField, TextFieldProps } from '@mui/material'

export const MainContainer = styled(Box)<BoxProps>(({ theme }) => ({
  maxWidth: 'calc(100vw - 12px - 360px)',
  [theme.breakpoints.up('lg')]: {
    maxWidth: 'calc(100vw - 360px)'
  },
}))

export const CustomTextField = styled(TextField)<TextFieldProps>(() => ({
  fontSize: '2rem',
  '& .MuiInput-underline:before': {
    borderBottomWidth: '0',
  },
  '&:hover': {
    backgroundColor: '#f9f9f9'
  }
}))
