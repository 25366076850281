import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'

import { useExpoStatus } from '../libs/utils'

import { pageTransition } from './animations'

type Props = JSX.IntrinsicElements['div'] & {
  pathname?: string
}

export const PageWrapper = ({ pathname, ...props }: Props) => {
  const { className, children } = props
  const { state } = useLocation()
  const DISABLED_ANIMATIONS = useExpoStatus()

  const { translateXStart, translateXEnd } = useMemo(() => {
    const res = {
      translateXStart: '100%',
      translateXEnd: '-100%'
    }

    switch (pathname) {
      case '/v2/activity-center':
        res.translateXStart = state?.from === '/v2/tasks' ? '-100%' : '100%'
        res.translateXEnd = state?.to === '/v2/home' ? '100%' : '-100%'
        break

      case '/v2/edit-profile':
        res.translateXEnd = '100%'
        break

      case '/v2/home':
        res.translateXStart = state?.from === '/v2/profile' ? '100%' : '-100%'
        res.translateXEnd = state?.to === '/v2/profile' ? '100%' : '-100%'
        break

      case '/v2/messages':
        res.translateXStart = state?.from === '/v2/tasks' ? '-100%' : '100%'
        res.translateXEnd = state?.to === '/v2/home' || state?.to === '/v2/search' ? '100%' : '-100%'
        break

      case '/v2/profile':
        res.translateXStart = '-100%'
        break

      case '/v2/payments':
        break

      case '/v2/task-detail/:id/':
        res.translateXEnd = '100%'
        break

      case '/v2/tasks':
        res.translateXStart = state?.from.includes('/v2/task-detail') ? '-100%' : '100%'
        res.translateXEnd = state?.to.includes('/v2/task-detail') || state?.to === '/v2/edit-profile' ? '-100%' : '100%'
        break
      case '/v2/search':
        res.translateXStart = state?.from === '/v2/messages' || state?.from === '/v2/tasks' ? '-100%' : '100%'
        res.translateXEnd = state?.to === '/v2/messages' || state?.to === '/v2/tasks' ? '-100%' : '100%'
        break

      default:
        break
    }

    return res
  }, [pathname, state])

  if (DISABLED_ANIMATIONS) {
    return <div {...props}>{children}</div>
  }

  return (
    <motion.div
      transition={pageTransition}
      className={className}
      initial={{
        opacity: 0,
        translateX: translateXStart
      }}
      animate={{
        opacity: 1,
        translateX: '0',
      }}
      exit={{
        opacity: 0,
        translateX: translateXEnd,
        filter: 'blur(12px)',
      }}
    >
      {children}
    </motion.div>
  )
}
