import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BsShare } from 'react-icons/bs'
import { useLocation } from 'react-router-dom'
import { Grid, Stack, Typography } from '@mui/material'

import { Btn } from '#app/components/Buttons'
import Card from '#app/components/Card/Card'
import ProfileContext from '#app/contexts/ProfileContext'
import { useShare } from '#app/hooks/useShare'
import { EntityPage } from '#app/layouts/EntityView'
import { useBankingBottomSection } from '#app/store/banking-bottom-sections'

export const Link = () => {
  const { t } = useTranslation()
  const { getProfile: { user } } = useContext(ProfileContext)
  const url = `${window.location.protocol}//${window.location.host}/public/payments/${user?.businesses?.[0]?.id ?? ''}`

  const { onShare } = useShare({
    title: user?.businesses?.[0]?.name ? `${user?.businesses?.[0]?.name} | Payment Link` : 'Payment Link',
    text: 'Please complete the form to send us a payment through',
    url,
    successText: t('banking.paymentLink.copiedToClipboard', 'Your payment URL was copied to your clipboard')
  })

  const { setSelectedSection } = useBankingBottomSection((state) => state)
  const location = useLocation()

  useEffect(() => {
    setSelectedSection({
      url: location.pathname
    })
  }, [])

  return (
    <EntityPage
      isLoading={false}
      layout='free'
      pageTitle={t('banking.paymentLink.pageTitle', 'Payments')}
      pageSubtitle={t('banking.paymentLink.subTitle', 'Receive payments from your customers')}
      mainContent={(
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            lg={6}
          >
            <Card>
              <Card.Body className='v1-card-bottom-padding'>
                <Stack spacing={4}>
                  <Typography align='center'>{ t('banking.paymentLink.topDescription', 'Share the following link with your customers and start collecting payments')}</Typography>
                  <Btn.Button
                    startIcon={<BsShare />}
                    onClick={onShare}
                  >
                    { t('banking.paymentLink.button', 'Click to share') }
                  </Btn.Button>
                  <Typography align='center'>{ t('banking.paymentLink.bottomDescription', 'Your payments will be reflected on your bank account balance, the transaction can take up to 5 days to show up in your transaction history')}</Typography>
                </Stack>
              </Card.Body>
            </Card>
          </Grid>
        </Grid>
      )}
    />
  )
}
