import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { Grid, Typography } from '@mui/material'

import { EntityPage } from '#app/layouts/EntityView'
import { BusinessWithJobsQueryResult } from '#app/types/job'
import { QUERY, QUERY_GET_BUSINESSES_WITH_JOBS } from '#app/utils/graphqlQueries'

import Table from '../../../components/Table/Table'
import { useIsDesktopSize } from '../../../utils/mediaQueries'

import { BusinessDetailsContent } from './BusinessDetails'

interface BusinessRowProps {
  currentBusiness: BusinessWithJobsQueryResult | undefined
  business: BusinessWithJobsQueryResult
  onClick: () => void
}

const BusinessRow = ({ currentBusiness, onClick, business }: BusinessRowProps) => {
  const businessName = business.name?.length ? business?.name : 'Unknown'

  const isDesktop = useIsDesktopSize()

  return (
    <Table.RowMobile
      className={`${(currentBusiness?.id === business.id && isDesktop) ? 'v1-owner-row-selected' : ''} card-body pt-0`}
      role='button'
      onClick={onClick}
    >
      <Grid
        container
        wrap='nowrap'
      >
        <Grid
          item
          zeroMinWidth
          xs
        >
          <Typography noWrap>
            {businessName}
          </Typography>
          <Typography
            noWrap
            style={{
              fontSize: '0.70rem'
            }}
          >
            { business.user?.email ?? '' }
          </Typography>
        </Grid>
      </Grid>
    </Table.RowMobile>
  )
}

export const BusinessesManagement = () => {
  const isDesktop = useIsDesktopSize()
  const [currentBusiness, setCurrentBusiness] = useState<BusinessWithJobsQueryResult | undefined>()
  const [isLoading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  const [query, { data, loading, refetch, startPolling }] = useLazyQuery<QUERY_GET_BUSINESSES_WITH_JOBS>(QUERY.jobs.getBusinessesWithJobs, {
    variables: {
      where: {
        user: {
          status: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            _neq: 'deleted'
          }
        }
      },
      limit: 50
    }
  })

  const searchUser = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value
    await refetch({
      where: {
        user: {
          status: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            _neq: 'deleted'
          }
        },
        name: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          _ilike: `%${query}%`
        }
      },
      limit: 50
    })
  }

  useEffect(() => {
    void query()
    startPolling(5000)
  }, [])

  const tableStructure = useMemo(() => {
    if (data && !loading) {
      const rows = data
        .businesses
        .map((business) => ({
          key: business.id,
          columns: [
            {
              component: <BusinessRow
                business={business}
                currentBusiness={isDesktop ? currentBusiness : undefined}
                onClick={() => {
                  if (business) {
                    if (isDesktop) {
                      setCurrentBusiness(business)
                    }
                    else {
                      navigate(`/businesses/${business.id}`)
                    }
                  }
                }}
              />
            }
          ]
        }))

      return {
        mobile: {
          rows
        }
      }
    }

    return {
      mobile: {
        rows: []
      }
    }
  }, [currentBusiness, data, loading])

  useEffect(() => {
    if (!currentBusiness && data) {
      setCurrentBusiness(data?.businesses[0])
    }

    if (currentBusiness) {
      const existing = data?.businesses.find((b) => b.id === currentBusiness.id)
      setCurrentBusiness(existing)
    }
  }, [data])

  useEffect(() => {
    if (currentBusiness) {
      setLoading(false)
    }
  }, [currentBusiness])

  return (
    <EntityPage
      layout='two-columns'
      isLoading={isLoading}
      pageTitle='Businesses'
      listContent={(
        <Table
          displayMobile={true}
          structure={tableStructure}
        />
      )}
      mainContent={<BusinessDetailsContent currentBusiness={currentBusiness} />}
      onFilterChange={searchUser}
    />
  )
}
