import { useAuth0 } from '@auth0/auth0-react'

export default function useUploadResource() {
  const { getAccessTokenSilently } = useAuth0()

  const uploadResource = async (userId, type, file) => {
    const token = await getAccessTokenSilently()

    // eslint-disable-next-line no-undef
    const data = new FormData()
    data.append('userId', userId)
    data.append('type', type)
    data.append('file', file)

    const result = await fetch(`${process.env.REACT_APP_OWNERS_BACKEND}/api/v1/resources`, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    const { path } = await result.json()

    return path
  }

  return {
    uploadResource
  }
}
