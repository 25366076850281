/* eslint-disable @typescript-eslint/naming-convention */

import { useTranslation } from 'react-i18next'

interface JobStatusMap {
  [key: string]: string
}

export const useJobStatusMap: () => JobStatusMap = () => {
  const { t } = useTranslation()

  const statusMap = {
    'job-booked': t('jobs.statusMap.job-booked'),
    'job-open': t('jobs.statusMap.job-open'),
    'job-closed': t('jobs.statusMap.job-closed'),
    'job-completed': t('jobs.statusMap.job-completed')
  }
  return statusMap
}
