import { useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { t } from 'i18next'

import { Btn } from '#app/components/Buttons'
import { LoadingCard } from '#app/components/Loading/LoadingCard'
import { EntityPage } from '#app/layouts/EntityView'
import { useCancelSubscriptionMutation } from '#app/operations/users/users.mutations.generated'

export function CancelSubscription() {
  const navigate = useNavigate()

  const [cancelSubscription, cancelSubscriptionData] = useCancelSubscriptionMutation()
  const [searchParams] = useSearchParams()

  const confirm = useMemo(() => {
    return searchParams.get('confirm')
  }, [searchParams])

  useEffect(() => {
    if (!cancelSubscriptionData?.called && confirm) {
      void cancelSubscription()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm])

  return (
    <EntityPage
      isLoading={false}
      layout='free'
      pageTitle={
        t('subscription.cancel.title', 'Manage my plan')
      }
      pageSubtitle={
        confirm
          ? (cancelSubscriptionData.loading
            ? t('subscription.cancel.inprogress', 'We are canceling the subscription, do not close the application.')
            : (cancelSubscriptionData.data === undefined && cancelSubscriptionData.error)
              ? t('subscription.cancel.error', 'An error occurred and we were unable to cancel your subscription, please contact support.')
              : t('subscription.cancel.success', 'Your subscription was canceled, if you confirmed this action by mistake, do not hesitate to contact us.'))
          : t('subscription.cancel.confirm', 'Confirm if you want to cancel your subscription')
      }
      mainContent={(
        <Box>
          {cancelSubscriptionData?.loading && <LoadingCard />}
          <Stack
            direction='row'
            spacing={2}
            sx={{
              mt: 4
            }}
          >
            { !confirm
            && (
              <Btn.Button onClick={() => navigate('/cancel-subscription?confirm=true')}>
                {t('subscription.cancel.confirmBtn', 'Cancel my plan')}
              </Btn.Button>
            )}
            <Btn.Button onClick={() => navigate('/help')}>
              {t('subscription.cancel.talkToSpecialist', 'Talk to specialist')}
            </Btn.Button>
          </Stack>
        </Box>
      )}
    />
  )
}
