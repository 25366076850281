import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Skeleton, Typography } from '@mui/material'
import { Stack } from '@mui/system'

import Card from '#app/components/Card/Card'
import { CurrentBankBalance } from '#app/feature/Banking/CurrentBankBalance'
import { TransactionsTable } from '#app/feature/Banking/Transactions/TransactionsTable'
import { GetBankingDetailsQuery, GetTransactionsQuery, useGetBankDetailsAndTransactionsQuery } from '#app/operations/banking/banking.queries.generated'
import { User } from '#app/types'

const BalanceDetails = ({ loading, data }: { loading: boolean, data?: GetBankingDetailsQuery }) => {
  return (
    <>
      <Card style={{
        height: '100%'
      }}
      >
        <Card.Body
          className='v1-card-bottom-padding'
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Grid
            container
            direction='column'
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              {
                loading
                  ? (
                    <Stack>
                      <Skeleton
                        variant='rectangular'
                        height={60}
                      />
                    </Stack>
                  )
                  : <CurrentBankBalance amount={data?.users[0]?.stripe_financial_acc?.balance?.cash?.usd / 100 ?? 0} />
              }
            </Grid>
          </Grid>
        </Card.Body>
      </Card>
    </>
  )
}

const TransactionDetails = ({ loading, data }: { loading: boolean, data?: GetTransactionsQuery }) => {
  const { t } = useTranslation()

  return (
    <Card>
      <Card.Body className='v1-card-bottom-padding'>
        <Grid
          container
          direction='column'
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Typography variant='h6'>{t('banking.transactionDetails.title', 'Transactions')}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TransactionsTable
              data={data}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  )
}

const BankingAccountDetails = ({ data, loading }: { data?: GetBankingDetailsQuery, loading: boolean }) => {
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      spacing={3}
      alignItems='stretch'
    >
      <Grid
        item
        xs={12}
      >
        <BalanceDetails
          loading={loading}
          data={data}
        />
      </Grid>
    </Grid>
  )
}

export const BankingDetails = ({ user }: { user?: User.UserType }) => {
  const { data: bankingData, loading: bankingDataLoading, startPolling: startPollingBanking } = useGetBankDetailsAndTransactionsQuery({
    variables: {
      userId: user?.id ?? ''
    }
  })

  useEffect(() => {
    const everyFiveSeconds = 5000 // 900000
    startPollingBanking(everyFiveSeconds)
  }, [])

  return (
    <Grid
      container
      direction='column'
      spacing={3}
    >
      <Grid item>
        <BankingAccountDetails
          data={bankingData}
          loading={bankingDataLoading}
        />
      </Grid>
      <Grid item>
        <TransactionDetails
          data={bankingData}
          loading={bankingDataLoading}
        />
      </Grid>
    </Grid>
  )
}
