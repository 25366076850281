import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Activities } from '#app/operations/autogenerate/schemas'
import { Cog } from '#app/v2/assets/Icons'
import { CenteredNavTitle } from '#app/v2/components/CenteredNavTitle'
import { HeaderSharedNavigation } from '#app/v2/components/HeaderSharedNavigation'
import { NavigationBottom } from '#app/v2/components/NavigationBottom'
import { allNotifications, data } from '#app/v2/pages/ActivityCenter/data'
import { ActivityCenter } from '#app/v2/pages/ActivityCenter/page'

import { Filters } from './types'

export const ActivityCenterStory = () => {
  const [filter, setFilter] = useState<Filters[]>(['all'])

  function applyFilters(notifications: Activities[], currentFilter: Filters[]) {
    if (currentFilter.includes('all')) {
      return notifications
    }
    return notifications.filter((val) => filter.includes(val.category as Filters))
  }

  const filteredNotifications = applyFilters(allNotifications, filter)

  const [searchParams] = useSearchParams()

  const storyProps = useMemo(() => {
    const scenario = searchParams.get('scenario')

    const res = {
      header: {
        backUrl: data.bottomNavigation.routes.home
      },
      activityCenter: {
        currentFilter: filter,
        filters: data.filters,
        notifications: filteredNotifications,
        fetchMore: async () => Promise.resolve([] as any),
        batchSize: 20,
        onFilterChange: (filter: string[]) => setFilter(filter as Filters[])
      },
      bottomNavigation: data.bottomNavigation

    }

    switch (scenario) {
      case 'no_notifications':
        res.activityCenter.notifications = []
        break
      default:
        break
    }

    return res
  }, [filter, filteredNotifications, searchParams])
  return (
    <div className='flex min-h-screen flex-col bg-white'>
      <div className='flex max-h-screen grow flex-col'>
        <HeaderSharedNavigation
          backUrl={storyProps.header.backUrl}
          main={<CenteredNavTitle title='Activity Center' />}
          extraActions={<Cog className=' h-6 w-6 justify-self-end' />}
        />
        <ActivityCenter
          {...storyProps.activityCenter}
        />
        <NavigationBottom {...storyProps.bottomNavigation} />
      </div>
    </div>
  )
}
