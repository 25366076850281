import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { ChannelList } from 'stream-chat-react'

import ProfileContext from '#app/contexts/ProfileContext'
import { useStoreUser } from '#app/store/user'
import {
  Calculator1,
  ChatBubbleSquareWrite,
  DollarCoin,
  Logout1,
  User,
  UserAddPlus,
} from '#app/v2/assets/Icons'
import { EmptyMessageList } from '#app/v2/components/EmptyMessageList'
import { filters } from '#app/v2/pages/ActivityCenter/types'
import { Home } from '#app/v2/pages/Home/page'
import { useActivityCenter } from '#app/v2-core/hooks/useActivityCenter'
import { useGetShortcuts } from '#app/v2-core/hooks/useGetShortcuts'
import { useStreamChatList } from '#app/v2-core/hooks/useStreamChatList'
import { useUserTasks } from '#app/v2-core/hooks/useUserTasks'
import { useAppContextV2 } from '#app/v2-core/layouts/useAppContextV2'

import Messages from '../Messages/page'

export const WrapperChat = styled.div`
  .str-chat__channel-preview {
    border-radius: 0.75rem;
    background-color: #fff;
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow-color: 0 0 #0000;
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
      0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    margin-bottom: 1.25rem;
    // I dont like this hack at all but cant figure out where the overflow is cutting off the shadow.
    margin-inline: 2px;
    width: calc(100% - 4px);
  }
  .str-chat__channel-list {
    border-inline-end: 0;
  }
  .str-chat__channel-list-react .str-chat__channel-list-messenger-react {
    padding-bottom: 0;
  }
  .str-chat__channel-preview-messenger--left {
    aspect-ratio: 1/1;
    height: 46px;
    width: 46px;

    .react-gravatar {
      width: 100%;
      height: 100%;
    }
  }
`

function LightChatList() {
  const { filters, sort, chatClient, Avatar } = useStreamChatList({
    channelUrl: '/v2/messages/{{channelId}}'
  })
  if (!chatClient) return null
  return (
    <WrapperChat>
      <ChannelList
        filters={filters}
        sort={sort}
        EmptyStateIndicator={() => <EmptyMessageList />}
        Avatar={Avatar}
        setActiveChannelOnMount={false}
      />
    </WrapperChat>
  )
}

export function CoreHome() {
  const profileData = useContext(ProfileContext)
  const { todoTasks, loading } = useUserTasks()
  const { V2_SET_LOADING, V2_LOGOUT } = useAppContextV2()
  const { t } = useTranslation()
  const { activities, markActivitiesAsRead } = useActivityCenter(filters.filter((item) => item !== 'chat'), 20, true)

  const bankingStatus = profileData?.getProfile?.user?.stripe_banking_status
  const bankingPending = bankingStatus !== 'open'

  const { shortcuts, extraShortcuts } = useGetShortcuts()
  const logout = {
    label: t('v2.general.logout', 'Log out'),
    icon: Logout1,
    onClick: V2_LOGOUT
  }

  useEffect(() => {
    if (!loading) {
      V2_SET_LOADING(false)
    }
  }, [V2_SET_LOADING, loading])

  const { unreadNotifications } = useStoreUser()

  return (
    <Home
      profile={{
        avatar: profileData?.getProfile?.user?.profile_picture_url,
        clientId: profileData?.getProfile.altBoProfile?.company_autonumber,
        firstName: profileData.getProfile.user?.first_name,
        fullName: `${profileData?.getProfile?.user?.first_name ?? ''} ${profileData?.getProfile?.user?.last_name ?? ''}`,
        services: profileData?.getProfile?.altBoProfile?.services,
        editProfileUrl: '/v2/edit-profile',
        shortcuts: shortcuts,
        extraShortcuts: extraShortcuts,
        logout: logout
      }}
      activities={activities}
      markActivityAsRead={(activityId) => {
        markActivitiesAsRead({
          variables: {
            activityIds: [activityId],
          },
        })
      }}
      todos={todoTasks}
      channelListStream={<Messages isWidget><LightChatList /></Messages>}
      shortcuts={[
        {
          icon: UserAddPlus,
          url: '/customers?action=newClient',
          description: t('v2.home.addClient', 'Add Client')
        },
        bankingPending
          ? {
            icon: User,
            url: '/customers',
            description: t('v2.home.myCustomers', 'My Customers')
          }
          : {
            icon: Calculator1,
            url: '/payments/invoices?action=createInvoice',
            description: t('v2.home.createInvoice', 'Create Invoice')
          },
        {
          icon: DollarCoin,
          url: '/payments',
          description: bankingPending ? t('v2.home.setupBanking', 'Setup Banking') : t('v2.home.requestPayment', 'Request Payment')
        },
        {
          icon: ChatBubbleSquareWrite,
          url: '/customers',
          description: t('v2.home.requestReview', 'Request Review')
        }
      ]}
      routes={{
        profile: '/v2/profile',
        editProfile: '/v2/edit-profile',
        activityCenter: '/v2/activity-center',
        tasks: '/v2/tasks',
        messages: '/v2/messages'
      }}
      qtyNotifications={unreadNotifications}
    />
  )
}
