import { useTranslation } from 'react-i18next'
import { CgSpinner } from 'react-icons/cg'
import { useNavigate } from 'react-router'

import { useUIStories } from '#app/hooks/useUIStories'
import { CheckSquare, Logout1 } from '#app/v2/assets/Icons'
import { RateTaskDialog } from '#app/v2/components/RateTaskDialog'
import { Button } from '#app/v2/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from '#app/v2/components/ui/dialog'
import { cn } from '#app/v2/libs/utils'
import { useInputFocus } from '#app/v2/pages/TaskDetail/store'

interface Props {
  updateTask?: (status: string) => void
  loading?: boolean
  taskStatus?: string
  wizardAvailable?: string // wizard URL
  rating?: {
    score?: string
    explanation: string
  }
}

export function Actions({ updateTask, taskStatus, loading, wizardAvailable, rating }: Props) {
  const { isFocused } = useInputFocus()
  const { isStoryMode } = useUIStories()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleTaskStatus = () => {
    if (wizardAvailable && taskStatus !== 'completed') {
      navigate(wizardAvailable.replace(/^(?:\/\/|[^/]+)*\//, '/'))
    }
    else if (updateTask) {
      updateTask(taskStatus === 'assigned' ? 'completed' : 'assigned')
    }
  }
  return (
    <>
      {
        (taskStatus !== 'admin_completed' && !isFocused)
        && (
          <footer
            id='mark-complete'
            className='-mb-5 mt-5 flex grow flex-col justify-end'
          >
            <div className='rounded-t-3xl border border-[#EEEEEE] bg-white p-6 pb-9'>
              <Dialog>
                {taskStatus === 'assigned' && !wizardAvailable && !rating && isStoryMode
                  ? (
                    <DialogTrigger asChild>
                      <Button
                        disabled={loading}
                        className={cn('mx-auto flex w-full rounded-xl bg-[#365193] py-[18px] font-bold')}
                        onClick={handleTaskStatus}
                      >
                        {loading
                          ? (
                            <CgSpinner className='mr-4 h-5 w-5 animate-spin' />
                          )
                          : <CheckSquare className='mr-4 h-5 w-5' /> }
                        {t('v2.tasks.action.complete', 'Mark as Complete')}
                      </Button>
                    </DialogTrigger>

                  )
                  : (
                    <Button
                      disabled={loading}
                      className={cn('mx-auto flex w-full rounded-xl bg-[#365193] py-[18px] font-bold')}
                      onClick={handleTaskStatus}
                    >
                      {loading
                        ? (
                          <CgSpinner className='mr-4 h-5 w-5 animate-spin' />
                        )
                        : wizardAvailable
                          ? <></>
                          : taskStatus === 'completed'
                            ? <Logout1 className='mr-4 h-5 w-5' />
                            : <CheckSquare className='mr-4 h-5 w-5' /> }
                      {
                        wizardAvailable && taskStatus !== 'completed'
                          ? t('v2.tasks.action.startTask', 'Start Task')
                          : taskStatus === 'completed' ? t('v2.tasks.action.reOpen', 'Reopen task') : t('v2.tasks.action.complete', 'Mark as Complete')
                      }
                    </Button>
                  )}
                <DialogContent className='max-w-sm'>
                  <DialogHeader className='text-sm sm:text-center'>
                    {t('v2.tasks.rating.qualifyExperience', 'Rate your experience with this task')}
                  </DialogHeader>
                  <RateTaskDialog />
                </DialogContent>
              </Dialog>
            </div>
          </footer>
        )
      }
    </>
  )
}
