import { ReactNode } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

interface Props {
  open: boolean
  children?: ReactNode
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '40vh',
  maxWidth: '1040px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3
}

export default function AttachTask(props: Props) {
  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={{
          ...style,
          width: '70%'
        }}
        >
          {props.children}

        </Box>
      </Modal>
    </>
  )
}
