import { Star1 } from '../assets/Icons'
import { cn } from '../libs/utils'

interface Props {
  rating?: {
    score: string
    explanation: string
  }
  size?: string
  gap?: string
}

export const FiveStars = ({ rating, size, gap }: Props) => {
  return (
    <div className={cn('flex flex-row flex-wrap gap-3', gap, {})}>
      <Star1 className={cn('size-5 fill-[#D9D9D9] text-[#D9D9D9]', size, {
        'fill-[#FFBB00] text-[#FFBB00]': rating?.score === '1' || rating?.score === '2' || rating?.score === '3' || rating?.score === '4' || rating?.score === '5'
      })}
      />
      <Star1 className={cn('size-5 fill-[#D9D9D9] text-[#D9D9D9]', size, {
        'fill-[#FFBB00] text-[#FFBB00]': rating?.score === '2' || rating?.score === '3' || rating?.score === '4' || rating?.score === '5'
      })}
      />
      <Star1 className={cn('size-5 fill-[#D9D9D9] text-[#D9D9D9]', size, {
        'fill-[#FFBB00] text-[#FFBB00]': rating?.score === '3' || rating?.score === '4' || rating?.score === '5'
      })}
      />
      <Star1 className={cn('size-5 fill-[#D9D9D9] text-[#D9D9D9]', size, {
        'fill-[#FFBB00] text-[#FFBB00]': rating?.score === '4' || rating?.score === '5'
      })}
      />
      <Star1 className={cn('size-5 fill-[#D9D9D9] text-[#D9D9D9]', size, {
        'fill-[#FFBB00] text-[#FFBB00]': rating?.score === '5'
      })}
      />
    </div>
  )
}
