/* eslint-disable @typescript-eslint/naming-convention */
import { Typography } from '@mui/material'
import Icon from '@mui/material/Icon'
import { Box, Stack } from '@mui/system'

interface Props {
  icon?: string
  label?: string
  value?: string
  onClick?: () => void
}

const PropertyDetails = ({ icon, label, value, onClick }: Props) => {
  const styles = {
    width: 50,
    height: 50,
    backgroundColor: 'var(--ter-color)',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  return (
    <Stack
      direction='row'
      spacing={2}
    >
      <Box style={styles}>
        <Icon sx={{
          color: 'white'
        }}
        >
          {icon}
        </Icon>
      </Box>
      <Box>
        <Typography>{ label }</Typography>
        <Typography sx={{
          '&:hover': {
            textDecoration: 'underline'
          }
        }}
        >
          { value ?? '-' }
        </Typography>
      </Box>
    </Stack>
  )
}

export default PropertyDetails
