import { Box, SxProps } from '@mui/material'
import ChipMUI from '@mui/material/Chip'

export function ChipGroup({ list, sx }: { list: any[], sx?: SxProps }) {
  return (
    <Box sx={sx}>
      {list?.map((element, key) => {
        return (
          <ChipMUI
            key={key}
            variant='outlined'
            label={element || '??'}
            sx={{
              m: 1
            }}
          />
        )
      })}
    </Box>
  )
}
