import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { Box, Stack, Typography } from '@mui/material'

interface InvoiceTotalProps {
  total?: number
  isEstimate?: boolean
}

export const InvoiceTotal = ({ total, isEstimate }: InvoiceTotalProps) => {
  const { t } = useTranslation()

  return (
    <Box sx={{
      borderRadius: 1,
      padding: 2,
      backgroundColor: 'var(--main-content-bg-color)'
    }}
    >
      <Stack>
        <Typography
          sx={{
            textAlign: 'center'
          }}
          variant='h1'
        >
          <NumericFormat
            thousandSeparator=','
            style={{
              fontWeight: 'bold',
              letterSpacing: 2
            }}
            displayType='text'
            value={(total ?? 0)}
            prefix='$'
          />
        </Typography>
        <Typography sx={{
          textAlign: 'center',
          fontWeight: 'bold'
        }}
        >
          {
            isEstimate
              ? t('banking.invoices.estimate.total', 'Total estimate')
              : t('banking.invoices.create.invoiceTotal', 'Invoice total')
          }
        </Typography>
      </Stack>
    </Box>
  )
}
