/* eslint-disable @stylistic/multiline-ternary */
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'

import AppContext from '#app/contexts/AppContext'
import { FormFlowV2 } from '#app/feature/FormFlow/FormFlowV2'
import { useEditUserWizardProgressMutation } from '#app/operations/wizards/wizards.mutations.generated'
import { UserWizardFragment, UserWizardFragmentDoc } from '#app/operations/wizards/wizards.queries.generated'
import { HeaderSharedNavigation } from '#app/v2/components/HeaderSharedNavigation'

import { CounterSteps } from './components/CounterSteps'
import { SectionDescription } from './components/Texts/SectionDescription'
import { SectionTitle } from './components/Texts/SectionTitle'
import WizardContainerProps from './types'

export const WizardContainer: React.FC<WizardContainerProps> = ({
  wizardData,
  wizardMetadata,
  wizardQuestionHistory,
  wizardQuestionStack,
  isWizardCompleted,
  loading,
  backUrl,
  logo,
  initialStepTitle = 'Missing Title',
  initialStepSubtitle = 'Missing subtitle',
  totalSteps,
  currentStep,
  extraActionsComponent,
  wizardId,
  userId,
  isDemo,
  taskId,
  textEnd1,
  textEnd2
}) => {
  const { setAppState } = useContext(AppContext)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [stepTitle, setStepTitle] = useState(initialStepTitle)
  const [stepSubTitle, setStepSubTitle] = useState(initialStepSubtitle)
  const [loadingWizard, setLoadingWizard] = useState(loading)
  const [goBack, setGoBack] = useState<boolean>(false)
  const [isComplete, setIsComplete] = useState<boolean>(isWizardCompleted ?? false)
  const [isSubQuestion, setIsSubQuestion] = useState<boolean>(false)
  const [currStep, setCurrStep] = useState<string>(currentStep ?? '1')
  const [editProgress] = useEditUserWizardProgressMutation({
    update(cache, { data }) {
      if (!data?.editUserWizardProgress?.wizard) return

      const cacheQuery = cache.readFragment<UserWizardFragment>({
        id: `${userId}${taskId}`,
        fragment: UserWizardFragmentDoc
      })

      if (!cacheQuery || !cacheQuery?.wizard) return

      const updatedWizard = {
        ...cacheQuery.wizard,
        ...data.editUserWizardProgress.wizard,
        __typename: 'GetUserWizardByIdPayload'
      }

      if (data?.editUserWizardProgress?.result) {
        cache.writeFragment({
          id: `${userId}${taskId}`,
          fragment: UserWizardFragmentDoc,
          data: {
            wizard: updatedWizard,
            __typename: 'GetUserWizardByIdPayload'
          }
        })
      }
    }
  })

  useEffect(() => {
    setAppState((previousState) => ({
      ...previousState,
      isLoading: loadingWizard || false
    }))
  }, [loadingWizard, setAppState])

  useEffect(() => {
    if (wizardData) {
      setLoadingWizard(false)
    }
  }, [wizardData])

  const callbackSetProgress = async (step: number, data: any, questionStackHistory: any, questionStack: any, status?: string, mustNavigate?: boolean) => {
    if (!isDemo) {
      const variables = {
        userId,
        wizardId,
        taskId,
        metadata: data,
        questionStackHistory,
        questionStack,
        currStep: step,
        ...(status !== undefined && {
          status
        })
      }
      if (status === 'completed') {
        setIsComplete(true)
      }
      await editProgress({
        variables
      })
      if (taskId && mustNavigate) {
        navigate(`/v2/task-detail/${taskId}`, {
          state: {
            from: pathname,
            to: `/v2/task-detail/${taskId}`
          }
        })
      }
      else if (mustNavigate) {
        navigate('/v2/home', {
          state: {
            from: pathname,
            to: '/v2/home'
          }
        })
      }
    }
  }
  const setTitles = ({ title, subtitle }: { title: string, subtitle: string }) => {
    setStepTitle(title)
    setStepSubTitle(subtitle)
  }

  const onBackFunction = () => {
    if (currStep === '1' && !isSubQuestion) {
      if (taskId) {
        navigate(`/v2/task-detail/${taskId}`, {
          state: {
            from: pathname,
            to: `/v2/task-detail/${taskId}`
          }
        })
      }
      else {
        navigate('/v2/home', {
          state: {
            from: pathname,
            to: '/v2/home'
          }
        })
      }
    }
    setGoBack(true)
  }
  return (
    <div className='min-h-screen bg-[#F7F9FB]'>
      <HeaderSharedNavigation
        backUrl={isWizardCompleted || isComplete ? undefined : backUrl}
        backFunction={isWizardCompleted || isComplete ? undefined : onBackFunction}
        main={(
          <img
            src={logo}
            className='h-6 w-32 justify-self-center'
          />
        )}
        extraActions={(
          <CounterSteps
            key={currStep}
            currentStep={currStep}
            totalSteps={`${wizardData?.length}` ?? ''}
          />
        )}
      />
      <section className='mb-5 mt-4 space-y-3.5 px-4'>
        <SectionTitle text={stepTitle} />
        <SectionDescription text={stepSubTitle} />
      </section>
      {loadingWizard ? (
        <p>Loading Wizard...</p>
      ) : (
        <Formik
          initialValues={{
            nothing: ''
          }}
          onSubmit={(values) => {
            console.log(values)
          }}
        >
          <Form className='px-4'>
            <FormFlowV2
              wizardFlow={wizardData || []}
              wizardMetadata={wizardMetadata || {}}
              setTitles={setTitles}
              callbackSetProgress={callbackSetProgress}
              wizardQuestionHistory={wizardQuestionHistory}
              wizardQuestionStack={wizardQuestionStack}
              isWizardCompleted={isWizardCompleted}
              textEnd1={textEnd1}
              textEnd2={textEnd2}
              goBack={goBack}
              setGoBack={setGoBack}
              setCurrentStep={setCurrStep}
              currStep={currentStep}
              setIsSubQuestion={setIsSubQuestion}
            />
          </Form>
        </Formik>
      )}
    </div>
  )
}
