import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { CenteredNavTitle } from '#app/v2/components/CenteredNavTitle'
import { HeaderSharedNavigation } from '#app/v2/components/HeaderSharedNavigation'
import { NavigationBottom } from '#app/v2/components/NavigationBottom'

import { data } from './data'
import { Payments } from './page'

export const StoryPayment = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const storyProps = useMemo(() => {
    const scenario = searchParams.get('scenario')

    const res = {
      profile: data.profile,
      clients: data.clients,
      bottomNavigation: data.bottomNavigation
    }

    switch (scenario) {
      default:
        break
    }

    return res
  }, [searchParams])
  return (
    <div className='min-h-screen bg-[#F7F9FB]'>
      <HeaderSharedNavigation
        profile={storyProps.profile}
        main={<CenteredNavTitle title={t('banking.pageTitle', 'Payments')} />}
      />
      <Payments clients={storyProps.clients} />
      <NavigationBottom {...storyProps.bottomNavigation} />
    </div>
  )
}
