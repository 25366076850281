import { useLazyQuery } from '@apollo/client'

import { QUERY, QUERY_GET_SEQUENCE_EXECUTIONS } from '#app/utils/graphqlQueries'

export function useEntitySequenceExecutions({ variables }: { variables: any }) {
  const mainQuery = QUERY.sequenceExecutions.getSequenceExecutions

  const [
    queryGetSequenceExecutions,
    { data: queryGetSequenceExecutionsData, ...queryGetSequenceExecutionsUtils }
  ] = useLazyQuery<QUERY_GET_SEQUENCE_EXECUTIONS>(mainQuery, {
    variables,
    pollInterval: 5000
  })

  return {
    // GraphQL Query
    queryGetSequenceExecutions,
    queryGetSequenceExecutionsData,
    queryGetSequenceExecutionsUtils
  }
}
