import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type CreateUserTokenMutationVariables = Types.Exact<{
  token: Types.Scalars['String']
  type: Types.Scalars['String']
  rawData?: Types.InputMaybe<Types.Scalars['jsonb']>
}>

export type CreateUserTokenMutation = { __typename?: 'mutation_root', createUserToken?: { __typename?: 'CreateUserTokenPayload', success: boolean } | undefined }

export const CreateUserTokenDocument = gql`
    mutation CreateUserToken($token: String!, $type: String!, $rawData: jsonb) {
  createUserToken(input: {token: $token, type: $type, raw_data: $rawData}) {
    success
  }
}
    `
export type CreateUserTokenMutationFn = Apollo.MutationFunction<CreateUserTokenMutation, CreateUserTokenMutationVariables>

/**
 * __useCreateUserTokenMutation__
 *
 * To run a mutation, you first call `useCreateUserTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserTokenMutation, { data, loading, error }] = useCreateUserTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      type: // value for 'type'
 *      rawData: // value for 'rawData'
 *   },
 * });
 */
export function useCreateUserTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserTokenMutation, CreateUserTokenMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<CreateUserTokenMutation, CreateUserTokenMutationVariables>(CreateUserTokenDocument, options)
}
export type CreateUserTokenMutationHookResult = ReturnType<typeof useCreateUserTokenMutation>
export type CreateUserTokenMutationResult = Apollo.MutationResult<CreateUserTokenMutation>
export type CreateUserTokenMutationOptions = Apollo.BaseMutationOptions<CreateUserTokenMutation, CreateUserTokenMutationVariables>
