import Images from '#app/v2/assets/Images'

import { modalShortcuts } from '../MyProfile/data'

export const data = {
  profile: {
    avatar: Images.SamBOProfile,
    fullName: 'Hector Hustler',
    services: 'Handyman',
    clientId: 456,
    ...modalShortcuts
  },
  clients: ['Paula Fink', 'Chris Platero', 'Joan Gil', 'Bruk Torra', 'Mati Morrone'],
  bottomNavigation: {
    routes: {
      home: '/v2-ui/home',
      messages: '/v2-ui/messages',
      tasks: '/v2-ui/tasks',
      business: '/v2-ui/business',
    },
    isIOS: false,
    pendingMessages: 109,
  },
}
