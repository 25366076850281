import ReactDOM from 'react-dom/client'
import { getAnalytics } from 'firebase/analytics'
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'

if (!Object.hasOwn) {
  Object.hasOwn = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop)
}
// import 'bootstrap/dist/css/bootstrap.css'
// import './index.css'
import './i18n/config'

import App from './App'

let analytics

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBAE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDING_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig)

if (process.env.REACT_APP_ENV !== 'development') {
  // eslint-disable-next-line no-unused-vars
  analytics = getAnalytics(app)
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<App />)

// NOTE: DO NOT ENABLE WHILE USING PROGRESSIER

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//   onUpdate: async (registration) => {
//     if (registration && registration.waiting) {
//       await registration.unregister()
//       registration?.waiting?.postMessage({ type: 'SKIP_WAITING' })
//       window.location.reload()
//     }
//   }
// })
