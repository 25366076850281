/* eslint-disable no-throw-literal */
import Css from './Css'

const JsonToHtml = (function () {
  let html = ''
  let level = 0
  let rootStyle = ''
  const suffix = '&nbsp;&nbsp;'
  // eslint-disable-next-line no-unused-vars
  let colspan = 2
  let jsonObjOrig
  let subLevel = 0
  let componentLevel = 0
  let cssProp = {}
  let indentProp = 3
  let trStyles = ''
  let tdStyles = ''
  let spacerStyles = ''
  let subElementStyles = ''
  let dataCellStyles = ''
  let rootElementStyles = ''

  const getTable = function (jsonObj, css, indent) {
    cssProp = css || {}
    indentProp = indent || 3
    validateProps(jsonObj, cssProp, indentProp)
    trStyles = getStyleAttributes('jsonTr')
    tdStyles = getStyleAttributes('jsonTd')
    spacerStyles = getStyleAttributes('rowSpacer')
    subElementStyles = getStyleAttributes('subElement')
    dataCellStyles = getStyleAttributes('dataCell')
    rootElementStyles = getStyleAttributes('rootElement')
    jsonObjOrig = jsonObj
    level = 0
    html = '<table>'
    walkTheLevels(jsonObj)
    html += '</table>'
    return html
  }

  const validateProps = function (jsonObj, css, indent) {
    if (!Number.isInteger(indent) || Math.sign(indent) !== 1) {
      throw 'The indent prop must be a positive number'
    }
    if (typeof css !== 'object' || css === null || Array.isArray(css)) {
      throw 'The css prop must be an object(but not an array)'
    }
    if (typeof jsonObj !== 'object' || jsonObj === null || Array.isArray(jsonObj)) {
      throw 'The json prop must be an object(but not an array)'
    }
  }

  const getIndent = function (level) {
    let indent = '&nbsp;'
    const singleIndent = [...Array(indentProp)].map((_, i) => '&nbsp;').join('')

    for (let i = 0; i < level; i++) {
      indent += singleIndent
    }

    return indent
  }

  const getSpacer = function () {
    return '<tr style="' + spacerStyles + '"></tr>'
  }

  // Get the Css obj from Css.js(or from props if present), and return a semicolon
  // separated list of styles
  const getStyleAttributes = function (className) {
    const defaultCssObj = Css[className]
    const defaultKeys = Object.keys(defaultCssObj)
    let attributes = ''

    for (let i = 0; i < defaultKeys.length; i++) {
      const key = defaultKeys[i]
      const cssAttr = key.replace(/([A-Z])/g, '-$1').toLowerCase()
      const cssClass = (cssProp[className] && cssProp[className][key]) ? cssProp[className] : defaultCssObj
      attributes += cssAttr + ':' + cssClass[key] + ';'
    }

    return attributes
  }

  const processArray = function (arr) {
    const distKeys = []
    let html = ''

    if (Array.isArray(arr) && arr.length === 0) {
      return html
    }

    // Get distinct keys from first obj
    // TODO: Handle unstructured objects in array. Assumption, for now, is that
    // all objects in array will have same structure.
    if (typeof arr[0] === 'object') {
      // Render the props if only a single object in the array
      if (arr.length === 1) {
        for (const k in arr[0]) {
          let value = ''

          if (arr[0][k]) {
            value = arr[0][k].toString()
          }

          html += '<tr style="' + trStyles + '">'
          html += '  <td style="' + subElementStyles + '">' + getIndent(level) + k + suffix + '</td>'
          html += '  <td style="' + dataCellStyles + '">' + getIndent(level) + value + suffix + '</td>'
          html += '</tr>'
          html += getSpacer()
        }
      }
      else {
        html = '<tr style="' + trStyles + '">'

        for (const k in arr[0]) {
          distKeys.push(k)
          html += '<td style="' + subElementStyles + '">'
          html += getIndent(level) + k + suffix
          html += '</td>'
        }

        html += '</tr>'
        html += getSpacer()

        // Render a row for each obj, displaying the value for each distinct key
        for (const k in arr) {
          html += '<tr style="' + trStyles + '">'

          for (let i = 0; i < distKeys.length; i++) {
            html += '<td style="' + dataCellStyles + '">'
            html += getIndent(level) + arr[k][distKeys[i]] + suffix
            html += '</td>'
          }

          html += '</tr>'
          html += getSpacer()
        }
      }
    }

    // Render a <tr> and <td> for each string in an array
    if (typeof arr[0] === 'string') {
      for (const k in arr) {
        html += '<tr style="' + trStyles + '">'
        html += '  <td style="' + dataCellStyles + '" colspan="2">'
        html += getIndent(level) + arr[k] + suffix
        html += '  </td>'
        html += '</tr>'
      }
    }

    return html
  }

  const walkTheLevels = function (jsonObj) {
    let hasArray = false

    if (typeof jsonObj === 'string') {
      jsonObj = JSON.parse(jsonObj)
    }

    subLevel = level

    for (const k in jsonObj) {
      // Reset the indent if next element is root
      if (typeof jsonObjOrig[k] !== 'undefined') {
        level = 0
        rootStyle = rootElementStyles
      }
      else {
        rootStyle = subElementStyles
      }

      componentLevel = subLevel

      // eslint-disable-next-line no-prototype-builtins
      if (jsonObj.hasOwnProperty(k)) {
        const v = jsonObj[k]

        if (hasArray) {
          level = componentLevel
        }

        if (typeof v === 'object') {
          colspan += level

          html += '<tr style="' + trStyles + '">'
          html += '  <td style="' + rootStyle + '" colspan="3">'
          html += getIndent(level) + k + suffix
          html += '  </td>'
          html += '</tr>'
          html += getSpacer()

          level += 1
        }
        else {
          const style = tdStyles + dataCellStyles

          html += '<tr style="' + trStyles + '">'
          html += '  <td style="' + rootStyle + '">'
          html += getIndent(level) + k + suffix
          html += '  </td>'
          html += '  <td style="' + style + '" colspan="2">' + v + '</td>'
          html += '</tr>'
          html += getSpacer()
        }

        if (v instanceof Array) {
          html += processArray(v)
          hasArray = true
        }

        if (typeof v === 'object' && !(v instanceof Array)) {
          walkTheLevels(v)
          level = subLevel - 1 // Outdent back
        }
      }
    }
  }

  return {
    getTable
  }
})()

export default JsonToHtml
