import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type CreateLinearIssueBasedOnTemplateMutationVariables = Types.Exact<{
  linear_template_id: Types.Scalars['String']
  request_upsell_call?: Types.InputMaybe<Types.Scalars['Boolean']>
}>

export type CreateLinearIssueBasedOnTemplateMutation = { __typename?: 'mutation_root', createLinearIssueBasedOnTemplate?: { __typename?: 'CreateLinearIssueBasedOnTemplateOutput', issue?: any | undefined, success: boolean } | undefined }

export const CreateLinearIssueBasedOnTemplateDocument = gql`
    mutation CreateLinearIssueBasedOnTemplate($linear_template_id: String!, $request_upsell_call: Boolean) {
  createLinearIssueBasedOnTemplate(
    input: {linear_template_id: $linear_template_id, request_upsell_call: $request_upsell_call}
  ) {
    issue
    success
  }
}
    `
export type CreateLinearIssueBasedOnTemplateMutationFn = Apollo.MutationFunction<CreateLinearIssueBasedOnTemplateMutation, CreateLinearIssueBasedOnTemplateMutationVariables>

/**
 * __useCreateLinearIssueBasedOnTemplateMutation__
 *
 * To run a mutation, you first call `useCreateLinearIssueBasedOnTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinearIssueBasedOnTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinearIssueBasedOnTemplateMutation, { data, loading, error }] = useCreateLinearIssueBasedOnTemplateMutation({
 *   variables: {
 *      linear_template_id: // value for 'linear_template_id'
 *      request_upsell_call: // value for 'request_upsell_call'
 *   },
 * });
 */
export function useCreateLinearIssueBasedOnTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateLinearIssueBasedOnTemplateMutation, CreateLinearIssueBasedOnTemplateMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<CreateLinearIssueBasedOnTemplateMutation, CreateLinearIssueBasedOnTemplateMutationVariables>(CreateLinearIssueBasedOnTemplateDocument, options)
}
export type CreateLinearIssueBasedOnTemplateMutationHookResult = ReturnType<typeof useCreateLinearIssueBasedOnTemplateMutation>
export type CreateLinearIssueBasedOnTemplateMutationResult = Apollo.MutationResult<CreateLinearIssueBasedOnTemplateMutation>
export type CreateLinearIssueBasedOnTemplateMutationOptions = Apollo.BaseMutationOptions<CreateLinearIssueBasedOnTemplateMutation, CreateLinearIssueBasedOnTemplateMutationVariables>
