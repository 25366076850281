import { useMemo } from 'react'
import { BsPlusLg } from 'react-icons/bs'
import { Button as ButtonMui, IconButton, SxProps } from '@mui/material'

import { useIsDesktopSize } from '../../utils/mediaQueries'

interface Props {
  onClick?: (args?: any) => any
  isLoading?: boolean
  disabled?: boolean
  children?: any
  icon?: JSX.Element
  forceMobile?: boolean
  forceDesktop?: boolean
  sx?: SxProps
  form?: string
  type?: 'submit' | 'reset' | 'button' | undefined
}

export const ActionButton = ({ onClick, sx, isLoading = false, forceMobile = false, forceDesktop = false, disabled = false, children, icon, type = 'button', ...props }: Props) => {
  const isDesktop = useIsDesktopSize()

  const mobileVerion = useMemo(() => {
    if (forceMobile) return true
    if (forceDesktop) return false
    return !isDesktop
  }, [isDesktop, forceMobile, forceDesktop])

  return (
    <>
      {!mobileVerion
      && (
        <ButtonMui
          variant='contained'
          color='primary'
          disabled={disabled}
          type={type}
          sx={sx}
          onClick={() => {
            if (!disabled && onClick) onClick()
          }}
          {...props}
        >
          {
            isLoading
              ? (
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                >
                </span>
              )
              : children

          }
        </ButtonMui>
      )}
      {mobileVerion
      && (
        <IconButton
          sx={{
            minWidth: '45px',
            minHeight: '45px',
            ...sx
          }}
          size='medium'
          color='primary'
          disabled={disabled || isLoading}
          aria-label='v1-action-button'
          type={type}
          onClick={() => {
            if (!disabled && onClick) onClick()
          }}
          {...props}
        >
          { icon ?? <BsPlusLg /> }
        </IconButton>
      )}
    </>
  )
}

export default ActionButton
