import { ComponentProps, FC } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationType } from '../types'

type CallCenterProps = {
  customer?: {
    first_name?: string
    last_name: string
  }
  explanation?: string
  metadata?: {
    notes?: string
    count?: number
  }
}

const TaskAssigned = (props: { tag_slug: string }) => {
  const { t } = useTranslation()
  return (
    <>
      {t('activityCenter.activitiesTypes.tasks.taskAssigned', {
        taskTagSlug: props.tag_slug,
        defaultValue: 'You have a new {{taskTagSlug}} Task!'
      })}
    </>
  )
}
const TaskCompleted = (props: { tag_slug: string }) => {
  const { t } = useTranslation()

  return (
    <>
      {t(
        'activityCenter.activitiesTypes.tasks.markedCompleted',
        {
          taskTagSlug: props.tag_slug,
          defaultValue: 'Your {{taskTagSlug}} Task was marked as Complete!'
        })}
    </>
  )
}

const TaskCommented = (props: { title: string }) => {
  const { t } = useTranslation()
  return (
    <>
      {t('activityCenter.activitiesTypes.tasks.taskCommented',
        {
          taskTitle: props.title,
          defaultValue: 'You have a new message in {{taskTitle}}'
        })}
    </>
  )
}

const UnreadMessage = (props: { unreadCount: number }) => {
  const { t } = useTranslation()
  return (
    <>
      {props.unreadCount > 1
        ? t('activityCenter.activitiesTypes.chat.unreadMessage', {
          count: props.unreadCount,
          defaultValue: 'You have {{count}} unread messages'
        })
        : t('activityCenter.activitiesTypes.chat.unreadMessageSingle', 'You have a new message!')}
    </>
  )
}

export const InterestedBrowsing = (props: CallCenterProps) => {
  const customer = props?.customer?.first_name
  const { t } = useTranslation()

  return (
    <>
      {customer
        ? t('activityCenter.activitiesWithDetails.callCenter.customerBrowsing', {
          customer,
          defaultValue: 'We had a call with {{customer}}, we will keep following up to book an appointment'
        })
        : t('activityCenter.activitiesTypes.callCenter.interestedBrowsing', 'We had a call with a potential customer, we will keep following up to book an appointment')}
    </>
  )
}

export const SpamNumber = (props: CallCenterProps) => {
  const { t } = useTranslation()
  const count = props?.metadata?.count
  return (
    <>
      {t('activityCenter.activitiesTypes.callCenter.spamNumber', 'We had {{count}} spam call(s)', {
        count
      })}
    </>
  )
}

export const InterestedCallback = (props: CallCenterProps) => {
  const customer = props?.customer?.first_name
  const { t } = useTranslation()

  return (
    <>
      {customer
        ? t('activityCenter.activitiesWithDetails.callCenter.callbackCustomer', {
          customer,
          defaultValue: 'We had a call with {{customer}}, we have a follow up scheduled to try to book an appointment'
        })
        : t('activityCenter.activitiesTypes.callCenter.interestedCallback', 'We had a call with a potential customer, we have a follow up scheduled to try to book an appointment')}
    </>
  )
}

export const InterestedUnavailable = (props: CallCenterProps) => {
  const customer = props?.customer?.first_name
  const { t } = useTranslation()
  return (
    <>
      {customer
        ? t('activityCenter.activitiesWithDetails.callCenter.unavailableCustomer', {
          customer: {
            first_name: customer
          },
          defaultValue: 'We had a call with {{customer.first_name}}, but they were unavailable, we will keep following up to book an appointment'
        })
        : t('activityCenter.activitiesTypes.callCenter.interestedUnavailable', 'We had a call with a potential customer, but they were unavailable, we will keep following up to book an appointment')}
    </>
  )
}
export const PotentialLeadLost = (props: CallCenterProps) => {
  const customer = props?.customer?.first_name
  const explanation = props?.explanation
  const { t } = useTranslation()

  let message
  switch (explanation) {
    case 'price':
      message = t('activityCenter.explanations.price', 'The customer found the prices too high.')
      break
    case 'competitor':
      message = t('activityCenter.explanations.competitor', 'The customer chose a competitor.')
      break
    case 'wrongService':
      message = t('activityCenter.explanations.wrongService', 'We do not provide the service the customer needed.')
      break
    case 'serviceArea':
      message = t('activityCenter.explanations.serviceArea', 'The customer is not in your service area.')
      break
    case 'rescheduling':
      message = t('activityCenter.explanations.rescheduling', 'The customer had scheduling issues.')
      break
    case 'noProject':
      message = t('activityCenter.explanations.noProject', 'The customer was not ready to start a project.')
      break
    case 'notInterested':
      message = t('activityCenter.explanations.noProject', 'The customer was not interested your services.')
      break
    case 'wrongNumber':
      message = t('activityCenter.explanations.wrongNumber', 'We could not reach the customer at the number provided')
      break
    case 'noVoicemail':
      message = t('activityCenter.explanations.noVoicemail', 'We could not leave a voicemail')
      break
    default:
      message = t('activityCenter.explanations.default', 'The customer was unavailable.')
  }

  return (
    <>
      {customer
        ? t('activityCenter.activitiesWithDetails.callCenter.customerLost', {
          customer,
          message,
          defaultValue: 'We had a call with {{customer}}, but we couldn\'t book an appoinment. {{message}}'
        })
        : t('activityCenter.activitiesTypes.callCenter.potentialLeadLost', {
          message,
          defaultValue: 'We had a call with a potential customer, but we couldn\'t book an appointment. {{message}}'
        })}
    </>
  )
}

export const AppointmentBooked = (props: CallCenterProps) => {
  const customer = props?.customer?.first_name
  const notes = props?.metadata?.notes
  const { t } = useTranslation()

  const message = t('activityCenter.activitiesTypes.callCenter.appointmentBooked', 'We had a call with {{customer}} and booked an appointment. Notes: {{notes}}', {
    customer,
    notes
  })

  return (
    <div dangerouslySetInnerHTML={{
      __html: message
    }}
    />
  )
}

export const AppointmentFollowUp = (props: CallCenterProps) => {
  const customer = props?.customer?.first_name
  const notes = props?.metadata?.notes
  const { t } = useTranslation()
  return (

    <>
      {customer
        ? t('activityCenter.activitiesWithDetails.callCenter.customerAppointmentFollowUp', 'We had a call with {{customer}} to follow up on an appointment. Notes: {{notes}}', {
          customer,
          notes
        })
        : t('activityCenter.activitiesTypes.callCenter.appointmentFollowUp', 'We had a call with a potential customer to follow up on an appointment. Notes: {{notes}}')}
    </>

  )
}

export const LookingForJob = () => {
  const { t } = useTranslation()
  return (
    <>
      {t('activityCenter.activitiesTypes.callCenter.lookingForJob', 'We had a call with a customer but they were unemployed and looking for a job')}
    </>
  )
}

export const VoicemailLeft = () => {
  const { t } = useTranslation()
  return (
    <>
      {t('activityCenter.activitiesTypes.callCenter.voicemailLeft', 'We had a call with a customer but they did not answer, we left a voicemail')}
    </>
  )
}

export const Rescheduling = (props: CallCenterProps) => {
  const { t } = useTranslation()
  const customer = props?.customer?.first_name
  const notes = props?.metadata?.notes
  return (

    <>
      {customer
        ? t('activityCenter.activitiesWithDetails.callCenter.customerRescheduling', 'We had a call with {{customer}} to reschedule an appointment. Notes: {{notes}}', {
          customer,
          notes
        })
        : t('activityCenter.activitiesTypes.callCenter.rescheduling', 'We had a call with a customer to reschedule an appointment')}
    </>

  )
}

export const ReschedulingNoAnswer = (props: CallCenterProps) => {
  const { t } = useTranslation()
  const customer = props?.customer?.first_name
  return (

    <>
      {customer
        ? t('activityCenter.activitiesTypes.callCenter.reschedulingNoAnswer', 'We try to call {{customer}} to reschedule an appointment but we couldn not reach out', {
          customer
        })
        : t('activityCenter.activitiesTypes.callCenter.rescheduling', 'We try to call a customer to reschedule an appointment but we couldn not reach out')}
    </>

  )
}

const OnboardingCompleted = () => {
  const { t } = useTranslation()
  return (
    <>
      {t('activityCenter.activitiesTypes.onboarding.onboardingCompleted', 'Congratulations on completing the onboarding process!')}
    </>
  )
}
type ComponentMap = {
  taskAssigned: typeof TaskAssigned
  taskCompleted: typeof TaskCompleted
  taskCommented: typeof TaskCommented
  unreadMessage: typeof UnreadMessage
  onboardingCompleted: typeof OnboardingCompleted
  interestedBrowsing: typeof InterestedBrowsing
  spamNumber: typeof SpamNumber
  interestedCallback: typeof InterestedCallback
  interestedUnavailable: typeof InterestedUnavailable
  potentialLeadLost: typeof PotentialLeadLost
  appointmentBooked: typeof AppointmentBooked
  appointmentFollowUp: typeof AppointmentFollowUp
  lookingForJob: typeof LookingForJob
  voicemailLeft: typeof VoicemailLeft
  rescheduling: typeof Rescheduling
  reschedulingNoAnswer: typeof ReschedulingNoAnswer
  // other components...
}

type NotificationComponents = {
  [K in NotificationType]: FC<ComponentProps<ComponentMap[K]>>;
}

const NotificationComponent: NotificationComponents = {
  taskAssigned: (props) => <TaskAssigned {...props} />,
  taskCompleted: (props) => <TaskCompleted {...props} />,
  taskCommented: (props) => <TaskCommented {...props} />,
  unreadMessage: (props) => <UnreadMessage {...props} />,
  onboardingCompleted: () => <OnboardingCompleted />,
  interestedBrowsing: (props) => <InterestedBrowsing {...props} />,
  spamNumber: (props) => <SpamNumber {...props} />,
  interestedCallback: (props) => <InterestedCallback {...props} />,
  interestedUnavailable: (props) => <InterestedUnavailable {...props} />,
  potentialLeadLost: (props) => <PotentialLeadLost {...props} />,
  appointmentBooked: (props) => <AppointmentBooked {...props} />,
  appointmentFollowUp: (props) => <AppointmentFollowUp {...props} />,
  lookingForJob: () => <LookingForJob />,
  voicemailLeft: () => <VoicemailLeft />,
  rescheduling: (props) => <Rescheduling {...props} />,
  reschedulingNoAnswer: (props) => <ReschedulingNoAnswer {...props} />,
}

const NotificationComponentStory = {
  taskAssigned: (props: any) => (
    <>
      You have a new Task &nbsp;
      <span className='font-medium'>
        "
        {props.text}
        "
      </span>
      !
    </>
  ),
  taskCompleted: (props: any) => (
    <>
      Your &nbsp;
      <span className='font-medium'>
        "
        {props.text}
        "
      </span>
      {' '}
      Task was marked as Complete!
    </>
  ),
  taskCommented: (props: any) => (
    <>
      You have a new message in &nbsp;
      <span className='font-medium'>
        "
        {props.text}
        "
      </span>
      !
    </>
  ),
  newLead: (props: any) => (
    <>
      A &nbsp;
      <span className='font-medium'>new lead</span>
      {' '}
      has been added from your
      {' '}
      {props.text}
    </>
  ),
  newClient: () => (
    <>You have a new customer, congrats!</> // No Payload needed
  ),
  newAppointment: (props: any) => (
    <>A new appointment booking has been made.</>
  ),
  newJobBooking: (props: any) => (
    <>
      You have a new job schedule for
      {' '}
      {props?.date}
      {' '}
      at
      {' '}
      {props?.time}
      {' '}
      in
      {' '}
      <span className=' text-[#0083B6] underline'>{props?.address}</span>
      {' '}
      for
      {' '}
      {props?.client}
      .
    </> // Multiple data in payload : object
  ),
  newReview: (props: any) => (
    <>
      You have a
      {' '}
      <span className='font-medium'>new review</span>
      {' '}
      in Google My Business.
    </>
  ),
  requestReview: (props: any) => (
    <>
      Request a Review from job
      {' '}
      <span className='text-[#0083B6] underline'>
        #
        {props.text}
      </span>
    </>
  ),
  newEstimate: (props: any) => (
    <>A new estimate has breen created by Owners</>
  ),
  estimatedApproved: (props: any) => (
    <>
      <span className='font-medium'>{props.text}</span>
      {' '}
      has approved your Estimate.
    </>
  ),
  invoiceApproved: (props: any) => (
    <>
      {props.text}
      {' '}
      has approved your Invoice
    </>
  ),
  moneySent: (props: any) => {
    return (
      <>
        <span className='font-medium'>{props.text}</span>
        {' '}
        has sent a new payment
      </>
    )
  },
  callCenterCall: (props: any) => {
    return (
      <>
        We had a call with customer
        {' '}
        <span className='font-medium'>{props.text}</span>
      </> // Inidividual payload data : string
    )
  },
  unreadMessage: () => <UnreadMessage unreadCount={0} />,
  onboardingCompleted: () => <OnboardingCompleted />,
  interestedBrowsing: () => <InterestedBrowsing />,
  spamNumber: () => <SpamNumber />,
  interestedCallback: () => <InterestedCallback />,
  interestedUnavailable: () => <InterestedUnavailable />,
  potentialLeadLost: () => <PotentialLeadLost />,
  appointmentBooked: () => <AppointmentBooked />,
  appointmentFollowUp: () => <AppointmentFollowUp />,
  lookingForJob: () => <LookingForJob />,
  voicemailLeft: () => <VoicemailLeft />,
  rescheduling: () => <Rescheduling />,
  reschedulingNoAnswer: () => <ReschedulingNoAnswer />,
}

export default {
  NotificationComponent,
  NotificationComponentStory,
}
