/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, FormControlLabel, Stack, Switch } from '@mui/material'
import { capitalize, isNil, omitBy } from 'lodash'
import { useImmer } from 'use-immer'

import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import { LoadingCard } from '#app/components/Loading/LoadingCard'
import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import { Table } from '#app/components/Table'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { useWorkOrders } from '#app/hooks/useWorkOrders'
import { EntityPage } from '#app/layouts/EntityView'
import { PmmarketplaceServiceRequestsQuery, usePmmarketplaceServiceRequestsLazyQuery } from '#app/operations/pmmarketplace/pmmarketplace.queries.generated'
import { ENVIROMENT } from '#app/store/enviroment'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

import { CreateWorkOrder } from './CreateWorkOrder'

export function PropertyManagers() {
  const { t } = useTranslation()
  const isDesktop = useIsDesktopSize()
  const navigate = useNavigate()
  const user = useEntityUser()
  const { statusToString } = useWorkOrders()
  const [sideBarStatus, setSideBarStatus] = useState(false)
  const [createWorkOrder, setCreateWorkOrder] = useState<PmmarketplaceServiceRequestsQuery['pmmarketplace_service_requests'][0] | null>(null)
  const [filterSR, setFilterSR] = useImmer<{ oa_email?: string, status?: string, title?: string }>({
    title: undefined,
    oa_email: user.userEmail ?? undefined,
    status: undefined
  })

  const [getWorkOrders, { data: dataServieRequests, loading: loadingMainQuery }] = usePmmarketplaceServiceRequestsLazyQuery({
    pollInterval: 5000
  })

  const mainFetch = useCallback(async () => {
    let where = {}

    if (filterSR.title) {
      where = {
        title: {
          _ilike: `%${filterSR.title}%`
        }
      }
    }
    if (filterSR.status) {
      where = {
        ...where,
        status: {
          _eq: filterSR.status
        }
      }
    }
    if (filterSR.oa_email) {
      where = {
        ...where,
        oa_email_string: {
          _eq: filterSR.oa_email
        }
      }
    }

    where = omitBy(where, isNil)

    await getWorkOrders({
      variables: {
        where
      }
    })
  }, [filterSR, getWorkOrders])

  useEffect(() => {
    void mainFetch()
  }, [mainFetch, filterSR])

  const structure = useMemo(() => {
    const res = {
      rows: []
    }

    if (dataServieRequests?.pmmarketplace_service_requests) {
      dataServieRequests.pmmarketplace_service_requests.map((work_order) => {
        const row = {
          key: 1,
          columns: [
            {
              component: (
                <Box>
                  #
                  {work_order?.record_number}
                  {' '}
                  {work_order.title}
                  {' '}
                  <br />
                </Box>
              )
            },
            {
              component: (
                <Box>
                  <Chip.Basic
                    label={work_order.id}
                    size='small'
                  />
                </Box>
              )
            },
            {
              component: (
                <Box>
                  <Chip.Basic
                    label={statusToString(work_order?.status)}
                    color={work_order?.status === 'new' ? 'warning' : 'primary'}
                  />
                </Box>
              )
            },
            {
              component: (
                <Box pt={isDesktop ? 0 : 2}>
                  {work_order.operations_agent?.first_name}
                  {' '}
                  {work_order.operations_agent?.last_name}
                </Box>
              )
            },
            {
              component: (
                <Box>
                  <Stack spacing={1}>
                    {
                      work_order.work_order.map((workOrder) => {
                        return (
                          <Chip.Basic
                            size='small'
                            color={workOrder.is_follow_up ? 'warning' : 'secondary'}
                            label={`#WO-${workOrder?.record_number} | ${statusToString(workOrder.status)} ${workOrder.is_follow_up ? '(follow up)' : ''}`}
                            onClick={() => navigate(`/property-managers/work-order/${workOrder.id}`)}
                          />
                        )
                      })
                    }
                    <Chip.Basic
                      size='small'
                      color='default'
                      label={`+ ${t('workOrders.createWorkOrder', 'create work order')}`}
                      onClick={() => { setCreateWorkOrder(work_order) }}
                    />
                  </Stack>
                </Box>
              )
            },
            {
              component: (
                <Box>
                  <Btn.MenuButton
                    onView={() => window.open(`${ENVIROMENT.airtable_table_work_orders}/${work_order.id}`)}
                  />
                </Box>
              ),
              align: 'right'
            }
          ]
        }
        // @ts-ignore
        res.rows.push(row)
      })
    }

    return res
  }, [dataServieRequests, isDesktop, navigate, statusToString, t])

  return (
    <EntityPage
      layout='one-column'
      pageTitle='PM - Service Requests'
      actionLabel={capitalize(t('generic.create'))}
      isLoading={false}
      mainContent={(
        <>
          <Stack direction='row'>
            <FormControlLabel
              control={(
                <Switch
                  checked={!!filterSR.oa_email}
                  onChange={() => {
                    if (!filterSR.oa_email) {
                      setFilterSR((draft) => { draft.oa_email = user.userEmail ?? '' })
                    }
                    else {
                      setFilterSR((draft) => { draft.oa_email = undefined })
                    }
                  }}
                />
              )}
              label={t('workorders.filter.assignedToMe', 'Assigned to me')}
            />
            <FormControlLabel
              control={(
                <Switch
                  checked={!!filterSR.status}
                  onChange={() => {
                    if (!filterSR.status) {
                      setFilterSR((draft) => { draft.status = 'new' })
                    }
                    else {
                      setFilterSR((draft) => { draft.status = undefined })
                    }
                  }}
                />
              )}
              label={t('workorders.filter.onlyNew', 'Only new')}
            />
          </Stack>
          <Table.V2
            mobileMergeExludeLastColumn
            headers={[{
              text: t('workOrders.title', 'Title')
            },
            {
              text: 'ID'
            },
            {
              text: t('workOrders.status', 'Status')
            },
            {
              text: 'OA'
            },
            {
              text: t('workOrders.title', 'Work Orders')
            },
            {
              text: ''
            }
            ]}
            sx={{
              mb: 4
            }}
            structure={structure}
            mobileMergeColumns={true}
          />
          {
            loadingMainQuery
            && (
              <Stack>
                <LoadingCard />
              </Stack>
            )
          }
          <RightSideBar
            size='lg'
            isOpen={!!createWorkOrder}
            closeHandler={() => setCreateWorkOrder(null)}
          >
            {
              createWorkOrder
                ? (
                  <CreateWorkOrder
                    serviceRequest={createWorkOrder}
                    closeSidePanel={() => setCreateWorkOrder(null)}
                  />
                )
                : <></>
            }
          </RightSideBar>
          <RightSideBar
            isOpen={sideBarStatus}
            closeHandler={() => setSideBarStatus(false)}
          >
            <iframe
              className='airtable-embed'
              src={ENVIROMENT.airtable_create_service_request}
              frameBorder='0'
              width='100%'
              style={{
                height: '90vh'
              }}
            >
            </iframe>
          </RightSideBar>
        </>
      )}
      onActionClick={() => setSideBarStatus(true)}
      onFilterChange={(e) => { setFilterSR((draft) => { draft.title = e.target.value }) }}
    />
  )
}
