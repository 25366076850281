import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BsStarFill } from 'react-icons/bs'
import { useParams } from 'react-router-dom'
import { Box, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'

import { DividerExtended } from '#app/components/Divider'
import { LoadingGrid } from '#app/components/Loading/LoadingGrid'
import { BidDetails } from '#app/feature/WorkOrders/BidDetail'
import WorkOrdersDetails from '#app/feature/WorkOrders/WorkOrdersDetails/WorkOrdersDetails'
import { EntityPage } from '#app/layouts/EntityView'
import { useUpdateWorkOrderMutation } from '#app/operations/pmmarketplace/pmmarketplace.mutations.generated'
import { PmmarketplaceWorkOrdersDocument, PmmarketplaceWorkOrdersQuery, usePmmarketplaceBidsQuery, usePmmarketplaceWorkOrdersQuery } from '#app/operations/pmmarketplace/pmmarketplace.queries.generated'

export function WorkOrder() {
  const { workOrderId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { data: dataBids, loading: loadingBids } = usePmmarketplaceBidsQuery({
    variables: {
      work_order_id: workOrderId ?? ''
    }
  })
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { data: dataWorkOrder, loading: loadingWorkOrder } = usePmmarketplaceWorkOrdersQuery({
    variables: {
      work_order_id: workOrderId ?? ''
    }
  })

  const [updateWorkOrder, { loading: loadingUpdateWorkOrder }] = useUpdateWorkOrderMutation({
    update(cache, { data }) {
      const cacheQuery = cache.readQuery<PmmarketplaceWorkOrdersQuery>({
        query: PmmarketplaceWorkOrdersDocument,
        variables: {
          work_order_id: workOrderId ?? ''
        }
      })

      const updatedWorkOrder = data?.updateWorkOrder?.work_order
      const currentWorkOrder = cacheQuery?.pmmarketplace_work_orders[0]

      cache.writeQuery({
        query: PmmarketplaceWorkOrdersDocument,
        variables: {
          work_order_id: workOrderId ?? ''
        },
        data: {
          pmmarketplace_work_orders: [
            {
              ...currentWorkOrder,
              status: updatedWorkOrder.status,
              bid_winner: updatedWorkOrder.bid_winner
            }
          ]
        }
      })
    }
  })

  const handlerUpdateWorkOrder = useCallback(async (bidId: string) => {
    if (workOrderId) {
      const res = await updateWorkOrder({
        variables: {
          work_order_id: workOrderId,
          payload: {
            status: 'job_confirmed',
            bid_winner: [bidId]
          }
        }
      })

      const result = res?.data?.updateWorkOrder
      if (result?.success) {
        enqueueSnackbar(t('general.dataUpdated'), {
          variant: 'success'
        })
      }
    }
  }, [enqueueSnackbar, t, updateWorkOrder, workOrderId])

  const loading = useMemo(() => loadingWorkOrder || loadingBids, [loadingBids, loadingWorkOrder])

  const workOrderData: PmmarketplaceWorkOrdersQuery['pmmarketplace_work_orders'][0] | null = useMemo(() => {
    if (dataWorkOrder?.pmmarketplace_work_orders && dataWorkOrder.pmmarketplace_work_orders[0]) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return dataWorkOrder.pmmarketplace_work_orders[0]
    }
    return null
  }, [dataWorkOrder])

  const workOrderDetails = useMemo(() => {
    if (workOrderData) {
      return {
        title: workOrderData.title ?? '',
        due_date_type: workOrderData.due_date_type ?? '',
        due_date: workOrderData.due_date ?? '',
        description: workOrderData.description ?? '',
        fixed_price: workOrderData.fixed_price ?? '',
        notes: workOrderData.additional_notes ?? '',
        attachments: workOrderData.attachments ?? []
      }
    }
  }, [workOrderData])

  return (
    <EntityPage
      layout='one-column'
      pageTitle={`PM - ${t('workorder.pageDetailTitle', 'Work Order Detail')}`}
      isLoading={false}
      mainContent={(
        <>
          <Stack>
            {loading && <LoadingGrid />}
            {
              !loading && workOrderData?.id
              && (
                <>
                  <WorkOrdersDetails
                    hideCreateBid
                    status={workOrderData.status ?? ''}
                    workOrderId={workOrderData.id}
                    {...workOrderDetails}
                  />
                  <Box pt={5}>
                    <DividerExtended sx={{
                      mt: 2
                    }}
                    />
                    {
                      workOrderData?.bid_winner
                      && (
                        <>
                          <Stack
                            direction='row'
                            justifyContent='flex-start'
                            alignItems='center'
                            spacing={2}
                          >
                            <BsStarFill />
                            <Typography variant='h4'>
                              {' '}
                              {t('workorder.winningBid', 'Winning bid')}
                              {' '}
                            </Typography>
                          </Stack>
                          {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                            dataBids?.pmmarketplace_bids.filter((bid) => bid.id === workOrderData?.bid_winner?.toString()).map((bid, key) => {
                              return (
                                <BidDetails
                                  key={key}
                                  highlight
                                  workOrder={workOrderData}
                                  bid={bid}
                                />
                              )
                            })
                          }
                          <DividerExtended sx={{
                            mt: 2
                          }}
                          />
                        </>
                      )
                    }
                    <Typography variant='h4'>
                      {' '}
                      {dataBids?.pmmarketplace_bids.length}
                      {' '}
                      Bids
                      {' '}
                    </Typography>
                    <DividerExtended sx={{
                      mt: 2
                    }}
                    />

                    {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                      !loadingUpdateWorkOrder && dataBids?.pmmarketplace_bids.map((bid, key) => {
                        return (
                          <BidDetails
                            key={key}
                            workOrder={workOrderData}
                            bid={bid}
                            updateWorkOrder={handlerUpdateWorkOrder}
                          />
                        )
                      })
                    }
                    {
                      loadingUpdateWorkOrder
                      && <LoadingGrid />
                    }
                  </Box>
                </>
              )
            }
          </Stack>
        </>
      )}
    />
  )
}
