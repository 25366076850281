import { HTMLAttributes, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useLocation, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import { useUIStories } from '#app/hooks/useUIStories'
import { WarningTriangle } from '#app/v2/assets/Icons'
import { opacityBlurVariant, pageTransition } from '#app/v2/components/animations'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTrigger } from '#app/v2/components/ui/alert-dialog'
import { Button } from '#app/v2/components/ui/button'
import { cn, useExpoStatus } from '#app/v2/libs/utils'

interface BackActionsProps extends HTMLAttributes<HTMLButtonElement> {
  backUrl?: string
  unsavedChanges?: boolean
  backFunction?: () => void
  saveChanges?: () => void
}

export const BackAction = ({ backUrl, unsavedChanges, className, backFunction, saveChanges }: BackActionsProps) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isStoryMode } = useUIStories()
  const { t } = useTranslation()
  const [saving, setSaving] = useState<boolean>(false)
  const isExpo = useExpoStatus()
  let backActionsVariants = {}

  if (!isExpo) {
    backActionsVariants = opacityBlurVariant
  }
  const handleGoBack = () => {
    if (!backFunction) {
      if (backUrl) {
        navigate(backUrl, {
          state: {
            from: pathname,
            to: backUrl
          }
        })
      }
      else if (document.referrer && document.referrer !== window.location.href) {
        navigate(-1)
      }
    }
    else {
      backFunction()
    }
  }

  const saveAndGoBack = async () => {
    setSaving(true)
    if (saveChanges) {
      await saveChanges()
    }

    handleGoBack()
  }

  if (unsavedChanges) {
    return (
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button
            variant='ghost'
            className={cn(' gap-2 px-1 py-0.5 text-sm font-semibold', className)}
          >
            <AiOutlineArrowLeft className=' h-5 w-5' />
            {t('navigations.goBack', 'Back')}
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent className=' w-[90%] max-w-sm justify-items-center rounded-xl px-8 py-10 text-center data-[state=open]:top-[35%]'>
          <WarningTriangle
            className=' h-24 w-24 text-white'
            fillPath=' fill-[#E56B6B]'
          />
          <AlertDialogHeader className=' text-lg font-semibold text-[#304985] sm:text-center'>{t('navigations.alertDialog.title', 'Unsaved changes')}</AlertDialogHeader>
          <AlertDialogDescription className=' text-base text-[#454545] [text-wrap:balance]'>{t('navigations.alertDialog.description', 'You have unsaved changes. Would you like to save them?')}</AlertDialogDescription>
          <AlertDialogFooter className=' flex-row space-x-2 text-sm font-bold  sm:justify-center'>
            <AlertDialogCancel
              className=' mt-0 rounded-lg border border-[#D81C1C] px-1.5 py-2 text-[#D81C1C]'
              disabled={saving}
              onClick={() => {
                if (!isStoryMode) {
                  handleGoBack()
                }
              }}
            >
              {t('navigations.alertDialog.discard', 'Discard changes')}
            </AlertDialogCancel>
            <AlertDialogAction
              disabled={saving}
              onClick={(e) => {
                if (!isStoryMode) {
                  e.preventDefault()
                  saveAndGoBack()
                }
              }}
            >
              {t('navigations.alertDialog.save', 'Save changes')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }
  return (
    <motion.div
      variants={backActionsVariants}
      transition={pageTransition}
      className='inline-flex'
    >
      <Button
        variant='ghost'
        className={cn('cursor-pointer gap-2 px-1 py-0.5 text-sm font-semibold', className)}
        onClick={handleGoBack}
      >
        <AiOutlineArrowLeft className='h-5 w-5' />
        {t('navigations.goBack', 'Back')}
      </Button>
    </motion.div>
  )
}
