import { ReactNode } from 'react'
import { SnackbarProvider } from 'notistack'

import { Snackbar } from '#app/v2/components/ui/snackbar'

interface Props {
  maxSnack?: number
  autoHideDuration?: number
  children: ReactNode
}

export function NotificationsProviderV2({ maxSnack, children, autoHideDuration }: Props) {
  return (
    <SnackbarProvider
      maxSnack={maxSnack ?? 1}
      autoHideDuration={autoHideDuration}
      Components={{
        default: Snackbar,
        info: Snackbar,
        warning: Snackbar,
        success: Snackbar,
        error: Snackbar
      }}
    >
      {children}
    </SnackbarProvider>
  )
}

export function NotificationsProviderV1({ maxSnack, children, autoHideDuration }: Props) {
  return (
    <SnackbarProvider
      maxSnack={maxSnack ?? 3}
      autoHideDuration={autoHideDuration ?? 4000}
    >
      {children}
    </SnackbarProvider>
  )
}
