/* eslint-disable @stylistic/jsx-closing-bracket-location */
/* eslint-disable @stylistic/jsx-closing-tag-location */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { ChangeEvent, useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiSolidError } from 'react-icons/bi'
import { BsCheck, BsCheck2Circle, BsX } from 'react-icons/bs'
import { useQuery } from '@apollo/client'
import { Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { capitalize } from 'lodash'
import { useSnackbar } from 'notistack'
import { useImmer } from 'use-immer'

import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import { DialogDelete } from '#app/components/Dialog/DialogDelete'
import { DividerExtended } from '#app/components/Divider'
import { LoadingGrid } from '#app/components/Loading/LoadingGrid'
import { Table } from '#app/components/Table'
import { JsonTable } from '#app/feature/JsonToHtml'
import { USER_SYSTEM_OWNERS } from '#app/hooks/useEntityUser'
import { EntityPage } from '#app/layouts/EntityView'
import { THEME } from '#app/layouts/theme'
import { AltBoAdminsQuery, useAltBoAdminsQuery } from '#app/operations/altbo.admins/altbo_admins.queries.generated'
import { useChatListAdminsQuery } from '#app/operations/chat/chat.queries.generated'
import { useSyncAdminUserMutation } from '#app/operations/users/users.mutations.generated'
import { Generic, User } from '#app/types'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

import RightSideBar from '../../../components/RightSideBar/RightSideBar'
import ProfileContext from '../../../contexts/ProfileContext'
import CreateUser from '../../../forms/UserManagement/CreateUser'
import EditUser from '../../../forms/UserManagement/EditUser'
import useMutationWithNotification from '../../../hooks/useMutationWithNotification'
import { DELETE_USER, GET_USERS, GET_USERS_TYPE } from '../../../utils/graphqlQueries'

import './UserManagement.css'

interface RowData {
  mini_app_user?: User.UserType
  altbo_admin?: AltBoAdminsQuery['altbo_admins'][0]
  stream_admin?: any
}

const UserManagement = () => {
  // @ts-ignore
  const { getProfile: { user: { email: currentUserEmail } } } = useContext(ProfileContext)
  const [sideBarStatus, setSideBarStatus] = useState(false)
  const [currentUser, setCurrentUser] = useState<User.UserType>()
  const [showDelete, setShowDelete] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showCreate, setShowCreate] = useState(false)
  const [deleteUserById] = useMutationWithNotification(DELETE_USER)
  const [isLoading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [syncAdminUser, { loading: loadingSyncAdminUser }] = useSyncAdminUserMutation()

  const { loading, data, refetch } = useQuery<GET_USERS_TYPE>(GET_USERS, {
    variables: {
      query: '%',
      limit: 200,
      currentUserEmail: '' // TODO - Check this
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })

  const { data: dataActiveAdmins, loading: loadingActiveAdmins, refetch: refetchActiveAdmins } = useAltBoAdminsQuery({
    variables: {
      where: {
        status: {
          _eq: 'active'
        }
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })

  const { data: dataInactiveAdmins, loading: loadingInactiveAdmins, refetch: refetchInactiveAdmins } = useAltBoAdminsQuery({
    variables: {
      where: {
        status: {
          _eq: 'active'
        }
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })

  const { data: dataStream } = useChatListAdminsQuery()

  const refetchAll = useCallback(async () => {
    await refetch()
    await refetchActiveAdmins()
    await refetchInactiveAdmins()
  }, [refetch, refetchActiveAdmins, refetchInactiveAdmins])

  const generalLoading = useMemo(() => {
    return (loadingActiveAdmins || loadingInactiveAdmins || loading)
  }, [loading, loadingActiveAdmins, loadingInactiveAdmins])

  const isDesktop = useIsDesktopSize()

  const createHandler = () => {
    setShowEdit(false)
    setShowCreate(true)
    setSideBarStatus(true)
  }

  const editHandler = (user: User.UserType) => {
    setCurrentUser(user)
    setShowEdit(true)
    setShowCreate(false)
    setSideBarStatus(true)
  }

  const deleteHandler = (user: User.UserType) => {
    setCurrentUser(user)
    setShowDelete(true)
  }

  const submitCallback = async (result: any, error: any) => {
    await refetch()
    if (!error) {
      setSideBarStatus(false)
    }
  }

  const searchUser = async (e: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const query = '%' + e.target.value + '%'
    await refetch({
      query,
      limit: 50
    })
  }

  const deleteUser = async ({ id }: { id: Generic.UserID }) => {
    await deleteUserById({
      variables: {
        id
      }
    })
    await refetch()
    setShowDelete(false)
  }

  // data

  const altBoAdminsById = useMemo(() => {
    const res: { [key: string]: AltBoAdminsQuery['altbo_admins'][0] } = {}

    dataActiveAdmins?.altbo_admins.forEach((admin) => {
      if (admin.mini_app_user_id) {
        res[admin.mini_app_user_id as string] = admin
      }
    })

    dataInactiveAdmins?.altbo_admins.forEach((admin) => {
      if (admin.mini_app_user_id) {
        res[admin.mini_app_user_id as string] = admin
      }
    })

    return res
  }, [dataActiveAdmins, dataInactiveAdmins])

  const streamUserById = useMemo(() => {
    const res: { [key: string]: any } = {}
    dataStream?.chatListAdmins?.data?.users
      ? dataStream?.chatListAdmins?.data?.users?.map((admin: any) => {
        if (admin?.id) {
          res[admin?.id] = admin
        }
      })
      : []

    return res
  }, [dataStream])

  const tableData = useMemo(() => {
    const res: RowData[] = [] // Define `res` como un array de `RowData`

    data?.users.forEach((user) => {
      res.push({
        mini_app_user: user,
        altbo_admin: user?.id && altBoAdminsById[user?.id] ? altBoAdminsById[user.id] : undefined,
        stream_admin: user?.id && streamUserById[user?.id] ? streamUserById[user?.id] : undefined
      })
    })

    return res // Importante: retornar `res` del hook useMemo
  }, [data, altBoAdminsById, streamUserById])

  const usersById = useMemo(() => {
    const res: any = {}
    data?.users.forEach((user) => {
      res[user.id] = user
    })
    return res
  }, [data?.users])

  const airtableAdminWithoutUser = useMemo(() => {
    const res: RowData[] = []

    dataActiveAdmins?.altbo_admins.map((admin) => {
      if (!admin?.mini_app_user_id) return null
      if (usersById && !usersById[admin?.mini_app_user_id as string]) return null
      res.push({
        mini_app_user: undefined,
        altbo_admin: admin
      })
    })

    return res
  }, [dataActiveAdmins?.altbo_admins, usersById])

  const cbSyncUser = useCallback(async (userId: string) => {
    try {
      await syncAdminUser({
        variables: {
          where: {
            id: userId
          }
        }
      })
      enqueueSnackbar('Third party services were consulted to obtain the necessary IDs, This action may take a while, refresh in a few seconds.', {
        variant: 'success'
      })

      await refetchAll()
    }
    catch (err) {
      enqueueSnackbar('An error occurred syncing the Owners member with our third-party services.', {
        variant: 'error'
      })
      console.error(err)
    }
  }, [enqueueSnackbar, refetchAll, syncAdminUser])

  const [detailMode, setDetailMode] = useImmer<{ stream: { [userId: string]: boolean } }>({
    stream: {}
  })

  const structure = useMemo(() => {
    const res = {
      rows: []
    }

    if (tableData) {
      tableData
        .map((user) => {
          const currentUser = user.mini_app_user
          const altBo = user?.altbo_admin
          const streamAdmin = user?.stream_admin

          const row = {
            key: currentUser?.id,
            columns: [
              {
                component: <Stack
                  direction='column'
                  justifyContent='flex-start'
                  alignItems='left'
                >
                  <Chip.Clipboard
                    color='primary'
                    label={`APP: ${currentUser?.id}`}
                    clipboard={`${currentUser?.id}`}
                    sx={{
                      mb: 1,
                      maxWidth: '300px'
                    }}
                    onClick={() => {}}
                  />
                  <Chip.Clipboard
                    color={altBo?.record_id ? 'secondary' : 'error'}
                    sx={{
                      mb: 1,
                      maxWidth: '300px'
                    }}
                    label={altBo?.record_id ? `ALTBO: ${altBo?.record_id}` : 'There is no admin in altBo'}
                    clipboard={`${altBo?.record_id}`}
                    onClick={() => {}}
                  />
                </Stack>
              },
              {
                component: <Box>
                  <Typography>
                    {`${currentUser?.first_name} ${currentUser?.last_name}`}
                  </Typography>
                </Box>
              },
              {
                component: <Box>
                  <Chip.Basic
                    color={altBo?.status === 'active' ? 'primary' : 'error'}
                    label={`${altBo?.status ?? 'unknown'}`} />
                </Box>
              },
              {
                component: <Stack direction='row'>
                  <Box mr={1}>
                    {
                      altBo?.linear_user_id
                        ? (
                          <BsCheck2Circle
                            fontSize={THEME.ICONS.size.sm}
                            color={THEME.ICONS.color.primary} />
                        )
                        : (
                          <BiSolidError
                            fontSize={THEME.ICONS.size.sm}
                            color={THEME.ICONS.color.error} />
                        )
                    }
                  </Box>
                  <Typography>
                    {`${altBo?.linear_user_id ?? 'There is no linked linear user id'}`}
                  </Typography>
                </Stack>
              },
              {
                component: <Stack direction='row'>
                  <Box mr={1}>
                    {
                      altBo?.rasayel_user_id
                        ? (
                          <BsCheck2Circle
                            fontSize={THEME.ICONS.size.sm}
                            color={THEME.ICONS.color.primary} />
                        )
                        : (
                          <BiSolidError
                            fontSize={THEME.ICONS.size.sm}
                            color={THEME.ICONS.color.error} />
                        )
                    }
                  </Box>
                  <Typography>
                    {`${altBo?.rasayel_user_id ?? 'There is no linked rasayel user id'}`}
                  </Typography>
                </Stack>
              },
              {
                component: <Stack direction='row'>
                  <Box mr={1}>
                    {
                      altBo?.slack_user_id
                        ? (
                          <BsCheck2Circle
                            fontSize={THEME.ICONS.size.sm}
                            color={THEME.ICONS.color.primary} />
                        )
                        : (
                          <BiSolidError
                            fontSize={THEME.ICONS.size.sm}
                            color={THEME.ICONS.color.error} />
                        )
                    }
                  </Box>
                  <Typography>
                    {`${altBo?.slack_user_id ?? 'There is no linked slack user id'}`}
                  </Typography>
                </Stack>
              },
              {
                component: <Box>
                  <Typography variant='subtitle2'>
                    {currentUser?.email}
                  </Typography>
                  {altBo?.email !== currentUser?.email
                  && (
                    <Typography variant='subtitle2'>
                      (altBo:
                      {' '}
                      {altBo?.email}
                      )
                    </Typography>
                  )}
                </Box>
              },
              {
                component: <Box>
                  {
                    altBo?.type?.map((typeLabel: string, key: number) => {
                      return (
                        <Chip.Basic
                          key={key}
                          sx={{
                            m: 0.5
                          }}
                          label={typeLabel} />
                      )
                    })
                  }
                </Box>
              },
              {
                component: <Box>
                  <Chip.Basic
                    label='Stream Chat'
                    icon={streamAdmin ? <BsCheck /> : <BsX />}
                    color={streamAdmin ? 'success' : 'error'}
                    onClick={() => setDetailMode((draft) => {
                      const userId = currentUser?.id
                      if (userId && streamAdmin) {
                        if (draft.stream[userId] === undefined) {
                          draft.stream[userId] = true
                        }
                        else {
                          draft.stream[userId] = !draft.stream[userId]
                        }
                      }
                    })}
                  />
                  <>

                    {
                      currentUser?.id && detailMode.stream[currentUser?.id]
                      && <Box mt={2}><JsonTable json={streamAdmin} /></Box>
                    }
                    { currentUser?.teams && <DividerExtended /> }
                    {
                      currentUser?.teams?.map((typeLabel: string, key: number) => {
                        return (
                          <Chip.Basic
                            key={key}
                            color='primary'
                            sx={{
                              m: 0.5
                            }}
                            label={typeLabel} />
                        )
                      })
                    }
                  </>
                </Box>
              },
              {
                component: <Box pt={isDesktop ? 0 : 2}>
                  <Chip.Basic
                    label={currentUser?.role.name}
                    color='success'
                  />
                  {currentUser?.email === USER_SYSTEM_OWNERS
                  && (
                    <Chip.Basic
                      sx={{
                        m: 1
                      }}
                      label='System'
                      color='primary'
                    />
                  )}
                </Box>
              },
              {
                component: <Box>
                  {currentUser && currentUser?.email !== USER_SYSTEM_OWNERS
                  && (
                    <>
                      <Btn.MenuButton
                        onEdit={() => editHandler(currentUser)}
                        onDelete={() => { deleteHandler(currentUser) }}
                      />
                      <Btn.IconButton.Sync
                        tooltip='Sync Platforms'
                        disabled={loadingSyncAdminUser}
                        onClick={() => cbSyncUser(currentUser?.id)} />
                    </>
                  )}
                </Box>,
                align: 'right'
              }
            ]
          }
          // @ts-expect-error ---
          res.rows.push(row)
        })
    }

    return res
  }, [tableData, detailMode.stream, isDesktop, loadingSyncAdminUser, setDetailMode, cbSyncUser])

  return (
    <EntityPage
      layout='one-column'
      pageTitle='Admin Management'
      actionLabel={capitalize(t('generic.create'))}
      isLoading={loading}
      mainContent={(
        <>
          {generalLoading && <LoadingGrid />}
          {!generalLoading && (
            <Table.V2
              mobileMergeExludeLastColumn
              headers={[{
                text: 'ID'
              },
              {
                text: 'User'
              },
              {
                text: 'Status (altBo)'
              },
              {
                text: 'Linear ID'
              },
              {
                text: 'Rasayel ID'
              },
              {
                text: 'Slack ID'
              },
              {
                text: 'Email'
              },
              {
                text: 'Type (altBo)'
              },
              {
                text: 'Teams & Stream'
              },
              {
                text: 'Role'
              },
              {
                text: 'Actions'
              }
              ]}
              sx={{
                mb: 4
              }}
              structure={structure}
              mobileMergeColumns={true}
            />
          )}
          <RightSideBar
            isOpen={sideBarStatus}
            closeHandler={() => setSideBarStatus(false)}
          >
            <>
              { showCreate && (
                <CreateUser
                  ignorePhoneNumber
                  submitCallback={submitCallback} />
              ) }
              { showEdit && (
                <EditUser
                  submitCallback={submitCallback}
                  initialValues={currentUser}
                />
              ) }
            </>
          </RightSideBar>
          <DialogDelete
            open={showDelete}
            setOpen={setShowDelete}
            texts={{
              description: t('general.deleteDescription', {
                identifier: currentUser?.email ?? ''
              }),
              confirm: capitalize(t('generic.confirm')),
              cancel: capitalize(t('generic.cancel'))
            }}
            isLoading={isLoading}
            onConfirm={async () => {
              setLoading(true)
              try {
                await deleteUser({
                  id: currentUser?.id ?? ''
                })
              }
              catch (err) {
              // err
              }
              finally {
                setShowDelete(false)
                setLoading(false)
              }
            }}
          />
        </>
      )}
      secondaryActionIcon='refresh'
      onActionClick={createHandler}
      onFilterChange={searchUser}
      onSecondaryActionClick={refetchAll}
    />
  )
}

export default UserManagement
