import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import dayjs from 'dayjs'
import { useField } from 'formik'

import { useIsDesktopSize } from '../../utils/mediaQueries'

const getDate = (newValue) => {
  return dayjs(newValue).isValid() ? newValue.toISOString() : ''
}

const InternalTextField = ({ meta, ...props }) =>
  (
    <TextField
      {...props}
      fullWidth
      error={meta.touched && !!meta.error}
    />
  )

const CustomDesktopDatePicker = ({ setValue, meta }) =>
  (
    <DesktopDatePicker
      value={meta.value}
      renderInput={(params) => (
        <div className='div-modal-input'>
          <InternalTextField
            meta={meta}
            {...params}
          />
        </div>
      )}
      onChange={(value) => setValue(getDate(value))}
    />
  )

const CustomMobileDatePicker = ({ setValue, meta }) =>
  (
    <MobileDatePicker
      value={meta.value}
      renderInput={(params) => (
        <div className='div-modal-input'>
          <InternalTextField
            meta={meta}
            {...params}
          />
        </div>
      )}
      onChange={(value) => setValue(getDate(value))}
    />
  )

const DatePickerInput = ({ label, ...props }) => {
  const [, meta, helpers] = useField(props)
  const { setValue } = helpers
  const isDesktop = useIsDesktopSize()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      { label && <label className='form-label'>{label}</label>}
      {
        isDesktop
          ? (
            <CustomDesktopDatePicker
              setValue={setValue}
              meta={meta}
            />
          )
          : (
            <CustomMobileDatePicker
              setValue={setValue}
              meta={meta}
            />
          )
      }
      <div className='error'>{ meta.touched && meta.error }</div>
    </LocalizationProvider>

  )
}

export default DatePickerInput
