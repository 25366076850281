import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactQuill from 'react-quill'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Alert, AlertTitle, FormControl, InputLabel, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Tab from '@mui/material/Tab'
import { Form, Formik } from 'formik'
import { isEmpty, isNil, omitBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { Updater, useImmer } from 'use-immer'

import Button from '#app/components/Buttons/Button'
import { DividerExtended } from '#app/components/Divider'
import FormInput from '#app/components/FormInput/FormInput'
import { ContainerQuill } from '#app/components/RichTextArea/styled'
import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import { Nunjucks } from '#app/feature/Tasks/Nunjucks'
import { useEntityTaskTemplates } from '#app/hooks/useEntityTaskTemplates'
import { DEFAULT_COGNITO_KEY } from '#app/hooks/useEntityUserTasks'
import { createTaskTemplateSchema } from '#app/utils/validationSchemas'
import { tags } from '#app/utils/vanilla/data'
import { languageFull } from '#app/utils/vanilla/language'

import { PreviewTaskContent } from '../ViewTask/PreviewTaskContent'

interface TemplateFields {
  title?: string
  // short_description?: string
  loom_videos?: string[]
  description_html?: string
  cognitoforms_dataform?: string
  tag_slug?: string
}
interface TemplateData {
  [language: string]: TemplateFields
}

const initialValues = {
  title: undefined,
  // short_description: undefined,
  loom_videos: undefined,
  description_html: undefined,
  cognitoforms_dataform: undefined,
  tag_slug: undefined
}

interface Props {
  language: string
  templateData: TemplateData
  setTemplateData: Updater<TemplateData>
  isPreviewMode: boolean
  tagSlug: string
}

export function NewTaskTemplate({ language, templateData, setTemplateData, isPreviewMode, tagSlug }: Props) {
  const taskContent = useMemo(() => {
    return templateData[language]
  }, [language, templateData])

  const [tmpHtml, setTmpHtml] = useState(taskContent.description_html)
  const { enqueueSnackbar } = useSnackbar()

  type SetFieldValue = (field: string, value: any, shouldValidate?: boolean | undefined) => void
  const cleanForm = useCallback((setFieldValue: SetFieldValue) => {
    setTmpHtml('')

    setTemplateData((draft) => {
      draft[language] = initialValues
    })

    if (initialValues) {
      Object.keys(initialValues).map((key) => {
        if (key) {
          setFieldValue(key, '')
        }
      })
    }
  }, [language, setTemplateData])

  const submitForm = useCallback((data: any) => {
    setTemplateData((draft) => {
      draft[language] = {
        loom_videos: data.loom_videos ? [data.loom_videos] : undefined,
        title: data.title,
        // short_description: data.short_description,
        description_html: tmpHtml,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        cognitoforms_dataform: data.cognitoforms_dataform ? data.cognitoforms_dataform.toString() : ''
      }
    })

    return true
  }, [language, setTemplateData, tmpHtml])

  const pushNunjuck = useCallback((nunjuckVar: string) => {
    setTmpHtml(tmpHtml ? `${tmpHtml}${nunjuckVar}` : nunjuckVar)
    return true
  }, [tmpHtml])

  return (
    <Formik
      initialValues={taskContent}
      validationSchema={createTaskTemplateSchema}
      onSubmit={(data) => {
        submitForm(data)
        enqueueSnackbar('Form updated successfully, this does not save data to the database', {
          variant: 'info'
        })
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <Box sx={{
            pb: '4rem'
          }}
          >
            {
              isPreviewMode
              && (
                <PreviewTaskContent
                  adminSide
                  previewMode
                  task={templateData[language]}
                  isTemplate={true}
                />
              )
            }

            <Box sx={{
              display: isPreviewMode ? 'none' : 'block'
            }}
            >
              <Grid
                container
                spacing={1}
                mb={4}
                width='100%'
              >
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                >
                  <FormInput
                    label='Title (nunjucks available)'
                    name='title'
                    type='text'
                    placeholder='Write a short title to understand the task'
                  />
                </Grid>
                {/* <Grid item xs={6} md={6} lg={6}>
                  <FormInput
                    label={'Short Description (nunjucks available)'}
                    name={'short_description'}
                    placeholder={'Give the business owner a bit of context'}
                    type='text'
                  />
                </Grid> */}
                <Grid
                  item
                  xs={8}
                  md={9}
                  lg={9}
                >
                  <FormInput
                    label='Loom Video'
                    name='loom_videos'
                    type='text'
                    placeholder='https://www.loom.com/share/1b93d0f446804d0aa032787b57e5ce6c'
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  lg={3}
                >
                  <FormInput
                    label='Cognito Form'
                    name='cognitoforms_dataform'
                    type='number'
                    placeholder='5'
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <Nunjucks onClick={pushNunjuck} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <ContainerQuill>
                    <ReactQuill
                      theme='snow'
                      value={tmpHtml}
                      style={{
                        borderRadius: '30px'
                      }}
                      onChange={(val) => setTmpHtml(val)}
                    />
                  </ContainerQuill>
                </Grid>
              </Grid>
            </Box>
            {
              !isPreviewMode
              && (
                <Box mt={4}>
                  <Button
                    primary={false}
                    style={{
                      float: 'left',
                      marginLeft: '1rem'
                    }}
                    onClick={() => {
                      cleanForm(setFieldValue)
                      enqueueSnackbar('Form cleared successfully, this does not save data to the database', {
                        variant: 'info'
                      })
                    }}
                  >
                    Clean form (
                    {language.toUpperCase()}
                    )
                  </Button>
                  <Button
                    type='submit'
                    style={{
                      float: 'right',
                      marginLeft: '1rem'
                    }}
                  >
                    Set (
                    {languageFull(language, true)}
                    )
                  </Button>
                </Box>
              )
            }
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export function Templates({ open, setOpen }: { open: boolean, setOpen: (val: boolean) => void }) {
  const [value, setValue] = useState('es')
  const [previewMode, setPreviewMode] = useState(false)
  const [titleTemplate, setTitleTemplate] = useState('')
  const [tagSlug, setTagSlug] = useState('')
  const { mutationCreateTaskTemplate } = useEntityTaskTemplates()
  const { enqueueSnackbar } = useSnackbar()
  const handleClose = () => {
    setOpen(false)
  }

  const [templateData, setTemplateData] = useImmer<TemplateData>({
    es: {
      ...initialValues
    },
    en: {
      ...initialValues
    }
  })

  const summaryFields = useMemo(() => {
    const total = Object.keys(initialValues).length - 1
    const res = {
      es: 0,
      en: 0,
      diff: 0,
      total
    }
    Object.keys(templateData.es).map((item: string) => {
      // @ts-ignore
      if (templateData.es[item] && templateData.es[item] !== '') {
        res.es = res.es + 1
      }
    })

    Object.keys(templateData.en).map((item: string) => {
      // @ts-ignore
      if (templateData.en[item] && templateData.en[item] !== '') {
        res.en = res.en + 1
      }
    })

    res.diff = Math.abs(res.es - res.en)

    return res
  }, [templateData])

  const emptyTemplate = useMemo(() => {
    if (summaryFields.es === 0 && summaryFields.en === 0) return true
    return false
  }, [summaryFields])

  const errorTemplate = useMemo(() => {
    let res = false
    if (summaryFields.en > 0 && summaryFields.es > 0) {
      // Validate diff first
      if (summaryFields.diff > 0) {
        res = true
      }

      if (summaryFields.es > 0 || summaryFields.en > 0) {
        Object.keys(templateData.es).map((key) => {
          // @ts-ignore
          if (templateData.es[key] && templateData.es[key] !== '') {
            // @ts-ignore
            if (!templateData.en[key] || templateData.en[key] === '') {
              res = true
            }
          }
        })
      }
    }

    return res
  }, [templateData, summaryFields])

  const onlyLanguage = useMemo(() => {
    if (summaryFields.es === 0 && summaryFields.en > 0) return 'only in English'
    if (summaryFields.en === 0 && summaryFields.es > 0) return 'only in Spanish'
    return ''
  }, [summaryFields])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const handlePreview = () => {
    setPreviewMode(!previewMode)
  }

  const createTemplate = useCallback(async () => {
    const content: any = {}
    const template = {
      en: omitBy(templateData.en, isNil),
      es: omitBy(templateData.es, isNil)
    }
    if (!isEmpty(template.en)) content.en = {
      ...template.en,
      tag_slug: tagSlug,
      cognitoforms_key: DEFAULT_COGNITO_KEY
    }
    if (!isEmpty(template.es)) content.es = {
      ...template.es,
      tag_slug: tagSlug,
      cognitoforms_key: DEFAULT_COGNITO_KEY
    }
    const result = await mutationCreateTaskTemplate({
      variables: {
        title: titleTemplate,
        tag_slug: tagSlug,
        content
      }
    })
    if (result.data.createTaskTemplate) {
      enqueueSnackbar('Template created successfully', {
        variant: 'success'
      })
      setOpen(false)
    }
  }, [enqueueSnackbar, mutationCreateTaskTemplate, setOpen, templateData, titleTemplate, tagSlug])

  const isValidForm = useMemo(() => {
    return errorTemplate || emptyTemplate || tagSlug === '' || titleTemplate === ''
  }, [emptyTemplate, errorTemplate, titleTemplate, tagSlug])

  useEffect(() => {
    if (previewMode && summaryFields.es === 0) {
      setValue('en')
    }
    else if (previewMode && summaryFields.en === 0) {
      setValue('es')
    }
  }, [previewMode, summaryFields.en, summaryFields.es])

  return (
    <>
      <RightSideBar
        size='lg'
        isOpen={open}
        closeHandler={handleClose}
      >
        <Box>
          <Typography variant='h4'>New Template </Typography>
          <Box>
            <Typography variant='subtitle2'>
              Create a new template in English and Spanish, both languages are optional
            </Typography>
            <Box mt={4}>
              <Grid
                container
                spacing={1}
                mt={1}
                width='100%'
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={6}
                >
                  <TextField
                    value={titleTemplate}
                    id='title'
                    label='Template title'
                    variant='outlined'
                    disabled={previewMode}
                    sx={{
                      width: '100%'
                    }}
                    onChange={(e) => setTitleTemplate(e.target.value)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={6}
                >
                  <FormControl sx={{
                    width: '100%'
                  }}
                  >
                    <InputLabel id='tag_slug'>Tag</InputLabel>
                    <Select
                      fullWidth
                      label='tag'
                      id='tag_slug'
                      variant='outlined'
                      value={tagSlug}
                      disabled={previewMode}
                      onChange={(e) => {
                        setTagSlug(e.target.value)
                      }}
                    >
                      {
                        tags?.map((e: { value: string, text: string }) => {
                          return (
                            <MenuItem
                              key={`${e.value}-${e.text}`}
                              value={e.value}
                            >
                              {e.text}
                            </MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <DividerExtended />
              <TabContext value={value}>
                <Box>
                  <TabList
                    aria-label='Templates by language'
                    onChange={handleChange}
                  >
                    { (!previewMode || summaryFields.es > 0)
                    && (
                      <Tab
                        label={`Español (${summaryFields.es}/${summaryFields.total})`}
                        value='es'
                      />
                    )}
                    {
                      (!previewMode || summaryFields.en > 0)
                      && (
                        <Tab
                          label={`English (${summaryFields.en}/${summaryFields.total})`}
                          value='en'
                        />
                      )
                    }
                  </TabList>
                </Box>
                <TabPanel value='es'>
                  <NewTaskTemplate
                    isPreviewMode={previewMode}
                    language='es'
                    tagSlug={tagSlug}
                    templateData={templateData}
                    setTemplateData={setTemplateData}
                  />
                </TabPanel>
                <TabPanel value='en'>
                  <NewTaskTemplate
                    isPreviewMode={previewMode}
                    language='en'
                    tagSlug={tagSlug}
                    templateData={templateData}
                    setTemplateData={setTemplateData}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </Box>
          <DividerExtended />
          {errorTemplate
          && (
            <Alert severity='error'>
              <AlertTitle>Error</AlertTitle>
              You must localize the same fields in both languages if you choose to have both languages. Otherwise clean one of the two languages and don not locate this template.
            </Alert>
          )}
          <Box style={{
            float: 'left',
            width: '100%'
          }}
          >
            {!previewMode
            && (
              <Button
                sx={{
                  m: 1
                }}
                disabled={isValidForm}
                onClick={handlePreview}
              >
                Preview template
                {' '}
                {onlyLanguage}
              </Button>
            )}
            <Button
              primary={false}
              sx={{
                m: 1
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            {
              previewMode
              && (
                <>
                  <Button
                    sx={{
                      m: 1,
                      float: 'right'
                    }}
                    disabled={isValidForm}
                    onClick={createTemplate}
                  >
                    Create template
                    {' '}
                    {onlyLanguage}
                  </Button>
                  <Button
                    primary={false}
                    sx={{
                      m: 1,
                      float: 'right'
                    }}
                    onClick={handlePreview}
                  >
                    Edit
                  </Button>
                </>
              )
            }
          </Box>
        </Box>
      </RightSideBar>
    </>
  )
}
