import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type GetCommentsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Comments_Bool_Exp>
}>

export type GetCommentsQuery = { __typename?: 'query_root', comments: Array<{ __typename?: 'comments', created_at: any, created_by: any, updated_by?: any | undefined, updated_at: any, thread_entity_type: string, thread_entity_id: any, text: string, status: string, receiver_user_id?: any | undefined, parent_comment_id?: any | undefined, metadata?: any | undefined, id: any, attachments?: any | undefined, private_note?: boolean | undefined, linear_comment_id?: any | undefined, userByCreatedBy: { __typename?: 'users', id: any, first_name?: string | undefined, last_name?: string | undefined, profile_picture_url?: string | undefined, email: string }, linearCommentLinked?: { __typename?: 'linear_comments', original_linear_comment_id: string, original_linear_issue_id: string, user?: any | undefined } | undefined }> }

export const GetCommentsDocument = gql`
    query getComments($where: comments_bool_exp) {
  comments(where: $where, order_by: {created_at: asc}) {
    created_at
    created_by
    updated_by
    updated_at
    thread_entity_type
    thread_entity_id
    text
    status
    receiver_user_id
    parent_comment_id
    metadata
    id
    attachments
    private_note
    linear_comment_id
    userByCreatedBy {
      id
      first_name
      last_name
      profile_picture_url
      email
    }
    linearCommentLinked {
      original_linear_comment_id
      original_linear_issue_id
      user
    }
  }
}
    `

/**
 * __useGetCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCommentsQuery(baseOptions?: Apollo.QueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options)
}
export function useGetCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options)
}
export type GetCommentsQueryHookResult = ReturnType<typeof useGetCommentsQuery>
export type GetCommentsLazyQueryHookResult = ReturnType<typeof useGetCommentsLazyQuery>
export type GetCommentsQueryResult = Apollo.QueryResult<GetCommentsQuery, GetCommentsQueryVariables>
