import { useMemo } from 'react'
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill, BsFillXOctagonFill } from 'react-icons/bs'
import { Stack } from '@mui/material'

import { THEME } from '#app/layouts/theme'

import { TransactionRowData } from './TransactionsTable'

export const TypeIcon = ({ data }: { data: TransactionRowData }) => {
  const inboundTypes = useMemo(() => [
    'treasury.received_credit'
  ], [])

  const outboundTypes = useMemo(() => [
    'treasury.outbound_transfer',
    'treasury.outbound_payment',
    'treasury.received_debit'
  ], [])

  const neutralTypes = useMemo(() => [
    'issuing.authorization'
  ], [])

  const summaryTypes = useMemo(() => {
    return [
      ...inboundTypes,
      ...outboundTypes,
      ...neutralTypes
    ]
  }, [inboundTypes, neutralTypes, outboundTypes])

  return (
    <Stack
      direction='row'
      spacing={1}
    >
      {
        inboundTypes.includes(data.type)
          ? (
            <BsFillArrowUpCircleFill
              size={25}
              style={{
                color: 'var(--green)'
              }}
            />
          )
          : outboundTypes.includes(data.type)
          && (
            <BsFillArrowDownCircleFill
              size={25}
              style={{
                color: 'var(--error-color)'
              }}
            />
          )
      }
      {
        neutralTypes.includes(data.type) && data?.metadata?.approved !== true
        && (
          <BsFillXOctagonFill
            size={THEME.ICONS.size.md}
            style={{
              color: THEME.COLORS.dark.primary
            }}
          />
        )
      }
      {
        !summaryTypes.includes(data.type)
        && 'Unknown'
      }
    </Stack>
  )
}
