import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { TableStructure } from '#app/components/Table/Table'
import { User } from '#app/types'

import Card from '../../components/Card/Card'
import SearchInput from '../../components/SearchInput/SearchInput'
import { AdvancedFilters, getNumberOfFilters, OwnersFilter } from '../../forms/OwnersFilter'

import OwnersList from './OwnersList'

interface Props {
  tableStructure?: TableStructure
  contentByOwner: React.FunctionComponent<{ currentOwner?: User.UserType, where?: any, onDelete?: () => void, onBlock?: () => void }>
  currentOwner?: User.UserType
  onFilterChange?: (e: ChangeEvent<HTMLInputElement>) => void
  advancedFilters?: AdvancedFilters
  onAdvancedFilterChange: (values: any, closeHandler?: () => void) => void
  onDelete?: () => void
  onBlock?: () => void
  where?: any
}

export default function OwnersDashboard({
  tableStructure,
  contentByOwner: ContentByOwner,
  currentOwner,
  onFilterChange,
  advancedFilters,
  onAdvancedFilterChange,
  onDelete,
  onBlock,
  where
}: Props) {
  const { t } = useTranslation()

  return (
    <div className='row'>
      <div className='col-12 col-lg-3'>
        <Card>
          <>
            <Card.Header>
              <SearchInput
                advancedFiltersApplied={getNumberOfFilters(advancedFilters)}
                hasAdvancedFilters={true}
                filterForm={(
                  <OwnersFilter
                    filters={advancedFilters}
                    onSubmit={onAdvancedFilterChange}
                  />
                )}
                placeholder={t('general.searchPlaceholder')}
                onFilterChange={onFilterChange}
              />
            </Card.Header>
            <Card.Body className='p-0'>
              {
                tableStructure
                && (
                  <OwnersList
                    tableStructure={tableStructure}
                  />
                )
              }
            </Card.Body>
          </>
        </Card>
      </div>
      <div className='d-none d-lg-block col-lg-9'>
        <Card>
          <Card.Body>
            { currentOwner
            && (
              <ContentByOwner
                where={where}
                currentOwner={currentOwner}
                onDelete={onDelete}
                onBlock={onBlock}
              />
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}
