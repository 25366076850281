import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type GetProxyResolverSequinQueryVariables = Types.Exact<{
  proxyURL: Types.Scalars['String']
  tableOrigin: Types.Scalars['String']
  columnOrigin: Types.Scalars['String']
}>

export type GetProxyResolverSequinQuery = { __typename?: 'query_root', getProxyResolverSequin?: { __typename?: 'GetProxyResolverSequinPayload', proxyURL?: string | undefined, resolvedURL?: string | undefined, resolvedContentType?: Array<string | undefined> | undefined } | undefined }

export const GetProxyResolverSequinDocument = gql`
    query getProxyResolverSequin($proxyURL: String!, $tableOrigin: String!, $columnOrigin: String!) {
  getProxyResolverSequin(
    input: {proxyURL: $proxyURL, tableOrigin: $tableOrigin, columnOrigin: $columnOrigin}
  ) {
    proxyURL
    resolvedURL
    resolvedContentType
  }
}
    `

/**
 * __useGetProxyResolverSequinQuery__
 *
 * To run a query within a React component, call `useGetProxyResolverSequinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProxyResolverSequinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProxyResolverSequinQuery({
 *   variables: {
 *      proxyURL: // value for 'proxyURL'
 *      tableOrigin: // value for 'tableOrigin'
 *      columnOrigin: // value for 'columnOrigin'
 *   },
 * });
 */
export function useGetProxyResolverSequinQuery(baseOptions: Apollo.QueryHookOptions<GetProxyResolverSequinQuery, GetProxyResolverSequinQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetProxyResolverSequinQuery, GetProxyResolverSequinQueryVariables>(GetProxyResolverSequinDocument, options)
}
export function useGetProxyResolverSequinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProxyResolverSequinQuery, GetProxyResolverSequinQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetProxyResolverSequinQuery, GetProxyResolverSequinQueryVariables>(GetProxyResolverSequinDocument, options)
}
export type GetProxyResolverSequinQueryHookResult = ReturnType<typeof useGetProxyResolverSequinQuery>
export type GetProxyResolverSequinLazyQueryHookResult = ReturnType<typeof useGetProxyResolverSequinLazyQuery>
export type GetProxyResolverSequinQueryResult = Apollo.QueryResult<GetProxyResolverSequinQuery, GetProxyResolverSequinQueryVariables>
