/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Box, CircularProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'

import { Btn } from '#app/components/Buttons'
import { LoadingCard } from '#app/components/Loading/LoadingCard'
import RightSideBar, { RightSideBarContainer } from '#app/components/RightSideBar/RightSideBar'
import { useCreateWebsiteMutation } from '#app/operations/onboarding/onboarding.mutations.generated'
import { GET_PROFILE, GET_PROFILE_TYPE } from '#app/utils/graphqlQueries'

interface Props {
  userId: string
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  agnostic?: boolean
  cbCreate?: () => void
}

interface ChecklistProps {
  companyData: {
    stage: string | null
    call_center_phone_number: string | null
    category: string | null
    city: string | null
    company_name: string | null
    company_email: string | null
    contact_record_id: string[] | null
    domain: string | null
    logo: string | null
    service_areas: string[] | null
    sub_services_names: string[] | null
    service_areas_names: string[] | null
    theme: string | null
  }
  setIsChecklistComplete: (val: boolean) => void
  agnostic?: boolean
}

const Checklist: React.FC<ChecklistProps> = ({ companyData, setIsChecklistComplete, agnostic }) => {
  const [checklist, setChecklist] = useState({
    onboardingStage: false,
    serviceDefined: false,
    callCenterPhoneNumberDefined: false,
    citySpecified: false,
    companyNameSpecified: false,
    companyEmailSpecified: false,
    contactRecordIdExists: false,
    domainUndefinedOrNull: false,
    logoSpecified: false,
    serviceAreasNamesDefined: false,
    subServicesNamesDefined: false,
    themeDefined: false
  })

  useEffect(() => {
    const updatedChecklist = {
      onboardingStage: companyData.stage === 'Onboarding',
      serviceDefined: !!companyData.category,
      callCenterPhoneNumberDefined: !!companyData.call_center_phone_number,
      citySpecified: !!companyData.city,
      companyNameSpecified: !!companyData.company_name,
      companyEmailSpecified: !!companyData.company_email,
      contactRecordIdExists: !!companyData.contact_record_id && companyData.contact_record_id.length > 0,
      domainUndefinedOrNull: !!companyData.domain,
      logoSpecified: !!companyData.logo,
      serviceAreasNamesDefined: !!companyData.service_areas_names && companyData.service_areas_names.length > 0,
      subServicesNamesDefined: !!companyData.sub_services_names && companyData.sub_services_names.length > 0,
      themeDefined: !!companyData.theme
    }

    if (agnostic) {
      updatedChecklist.themeDefined = true
      updatedChecklist.onboardingStage = true
    }

    setChecklist(updatedChecklist)
    const allChecked = Object.values(updatedChecklist).every(Boolean)
    setIsChecklistComplete(allChecked)
  }, [agnostic, companyData, setIsChecklistComplete])

  const ChecklistIcon = ({ checked }: { checked: boolean }) => (
    checked ? <CheckCircleOutlineIcon color='success' /> : <HighlightOffIcon color='error' />
  )

  return (
    <List>
      {!agnostic
      && (
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <ChecklistIcon checked={checklist.onboardingStage} />
            </ListItemIcon>
            <ListItemText>
              The company is in the onboarding stage:
              {' '}
              <b>{companyData.stage}</b>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      )}
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <ChecklistIcon checked={checklist.citySpecified} />
          </ListItemIcon>
          <ListItemText>
            A city has been specified:
            {' '}
            <b>{companyData.city}</b>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <ChecklistIcon checked={checklist.domainUndefinedOrNull} />
          </ListItemIcon>
          <ListItemText>
            The company's domain is either undefined or null:
            {' '}
            <b>{companyData.domain}</b>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <ChecklistIcon checked={checklist.serviceDefined} />
          </ListItemIcon>
          <ListItemText>
            At least one service has been defined in the recurring category:
            {' '}
            <b>{companyData.category}</b>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <ChecklistIcon checked={checklist.callCenterPhoneNumberDefined} />
          </ListItemIcon>
          <ListItemText>
            A call center phone number is defined:
            {' '}
            <b>{companyData.call_center_phone_number}</b>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <ChecklistIcon checked={checklist.companyNameSpecified} />
          </ListItemIcon>
          <ListItemText>
            A company name is specified:
            {' '}
            <b>{companyData.company_name}</b>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <ChecklistIcon checked={checklist.companyEmailSpecified} />
          </ListItemIcon>
          <ListItemText>
            A company email is specified:
            {' '}
            <b>{companyData.company_email}</b>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <ChecklistIcon checked={checklist.contactRecordIdExists} />
          </ListItemIcon>
          <ListItemText>
            A contact record ID exists:
            {' '}
            <b>{companyData.contact_record_id}</b>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <ChecklistIcon checked={checklist.logoSpecified} />
          </ListItemIcon>
          <ListItemText>
            A logo is specified:
            {' '}
            <b>
              {companyData?.logo && companyData?.logo.length > 20 ? `${companyData.logo.substring(0, 20)}...` : companyData?.logo}
            </b>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <ChecklistIcon checked={checklist.serviceAreasNamesDefined} />
          </ListItemIcon>
          <ListItemText>
            Service areas names are defined:
            {' '}
            <b>
              {Array.isArray(companyData?.service_areas_names) ? companyData.service_areas_names.join(', ') : ''}
            </b>

          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <ChecklistIcon checked={checklist.subServicesNamesDefined} />
          </ListItemIcon>
          <ListItemText>
            Sub services names are defined:
            {' '}
            <b>
              {Array.isArray(companyData?.sub_services_names) ? companyData.sub_services_names.join(', ') : ''}
            </b>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      {!agnostic && (
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <ChecklistIcon checked={checklist.themeDefined} />
            </ListItemIcon>
            <ListItemText>
              A theme is defined
            </ListItemText>
          </ListItemButton>
        </ListItem>
      )}
    </List>

  )
}

export function WebsiteBuilder({ userId, isOpen, setIsOpen, cbCreate, agnostic }: Props) {
  const [createWebsite, { loading: loadingGenerate }] = useCreateWebsiteMutation()
  const { enqueueSnackbar } = useSnackbar()
  const [isChecklistComplete, setIsChecklistComplete] = useState(false)
  const [message, setMessage] = useState('')
  const { data, refetch, loading } = useQuery<GET_PROFILE_TYPE>(GET_PROFILE, {
    variables: {
      userId
    }
  })

  const create = useCallback(async () => {
    const res = await createWebsite({
      variables: {
        bo_id: userId
      }
    })
    if (res?.data?.createWebsite?.result) enqueueSnackbar(res?.data?.createWebsite?.message, {
      variant: 'success'
    })
    else enqueueSnackbar(res?.data?.createWebsite?.message, {
      variant: 'error'
    })
    setMessage(res?.data?.createWebsite?.message || '')
  }, [createWebsite, enqueueSnackbar, userId])

  return (
    <RightSideBar
      isOpen={isOpen}
      size='md'
      closeHandler={() => {
        setIsChecklistComplete(false)
        setMessage('')
        setIsOpen(false)
      }}
    >
      <RightSideBarContainer footer={<Box />}>
        <Typography variant='h4'>Website Creator</Typography>
        <Typography variant='subtitle2'>
          {agnostic
            ? 'This is the information we need to create a website automatically for a business owner, please note that altbo takes a few minutes to update the cache of the mini app'
            : `This button creates a website for the company. This is a step forward on a successful onboarding, here you will be able to execute a new website for the BO
          ${userId}`}
        </Typography>
        <Box>
          <Box mt={4}>
            {data?.getProfile?.altBoProfile
              ? (
                <Checklist
                  companyData={data?.getProfile?.altBoProfile}
                  setIsChecklistComplete={setIsChecklistComplete}
                  agnostic={agnostic}
                />
              )
              : <CircularProgress />}

            {loadingGenerate
            && (
              <Stack mt={6}>
                <LoadingCard />
              </Stack>
            )}

            <Stack
              mt={6}
              direction='row'
            >
              {!agnostic
              && (
                <Btn.Button
                  disabled={loadingGenerate || !isChecklistComplete}
                  onClick={async () => {
                    setMessage('')
                    await create()
                  }}
                >
                  Create website
                </Btn.Button>
              )}
              {
                cbCreate
                && (
                  <Btn.Button
                    sx={{
                      m: 1
                    }}
                    disabled={!isChecklistComplete || loading}
                    onClick={cbCreate}
                  >
                    Continue
                  </Btn.Button>
                )
              }
              {
                cbCreate && process.env.REACT_APP_ENV !== 'production'
                && (
                  <Btn.Button
                    sx={{
                      m: 1
                    }}
                    onClick={cbCreate}
                  >
                    Force Continue (Only Staging)
                  </Btn.Button>
                )
              }
              <Btn.Button
                sx={{
                  m: 1
                }}
                disabled={loadingGenerate}
                isLoading={loading}
                onClick={async () => await refetch({
                  userId
                })}
              >
                Refetch Data
              </Btn.Button>
            </Stack>
          </Box>
          { message.length > 0 && (
            <Box mt={4}>
              <Typography variant='h6'>
                Response from server:
              </Typography>
              <Typography variant='body1'>
                {message}
              </Typography>
            </Box>
          )}
        </Box>
      </RightSideBarContainer>
    </RightSideBar>
  )
}
