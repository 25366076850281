import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type BlockUserMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']
  block: Types.Scalars['Boolean']
  block_user?: Types.InputMaybe<Types.Scalars['String']>
  block_reason?: Types.InputMaybe<Types.Scalars['String']>
}>

export type BlockUserMutation = { __typename?: 'mutation_root', blockUser?: { __typename?: 'BlockUserPayload', result: boolean } | undefined }

export type ResetSalesDemoUserMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']
  only_clean?: Types.InputMaybe<Types.Scalars['Boolean']>
}>

export type ResetSalesDemoUserMutation = { __typename?: 'mutation_root', resetSalesDemoUser?: { __typename?: 'ResetSalesDemoUserOutput', status?: boolean | undefined } | undefined }

export type CancelSubscriptionMutationVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['uuid']>
}>

export type CancelSubscriptionMutation = { __typename?: 'mutation_root', cancelSubscription?: { __typename?: 'CancelSubscriptionOutput', success: boolean } | undefined }

export type UnlinkPhoneMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']
  phone: Types.Scalars['String']
}>

export type UnlinkPhoneMutation = { __typename?: 'mutation_root', unlinkPhone?: { __typename?: 'UnlinkPhonePayload', result: boolean } | undefined }

export type SyncAdminUserMutationVariables = Types.Exact<{
  where: Types.SyncUserInput
}>

export type SyncAdminUserMutation = { __typename?: 'mutation_root', syncUser?: { __typename?: 'SyncUserPayload', success?: boolean | undefined } | undefined }

export type EditProfileMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']
  firstName?: Types.InputMaybe<Types.Scalars['String']>
  lastName?: Types.InputMaybe<Types.Scalars['String']>
  email?: Types.InputMaybe<Types.Scalars['String']>
  phones?: Types.InputMaybe<Types.Scalars['jsonb']>
  picture?: Types.InputMaybe<Types.Scalars['String']>
  notificationPhone?: Types.InputMaybe<Types.Scalars['String']>
}>

export type EditProfileMutation = { __typename?: 'mutation_root', editProfile?: { __typename?: 'EditProfilePayload', result?: boolean | undefined, altBoProfile?: any | undefined, formattedNotificationPhone?: string | undefined, user?: { __typename?: 'User', first_name?: string | undefined, linked_phones?: any | undefined, phone?: string | undefined, email?: string | undefined, last_name?: string | undefined, profile_picture_url?: string | undefined } | undefined } | undefined }

export const BlockUserDocument = gql`
    mutation blockUser($id: uuid!, $block: Boolean!, $block_user: String, $block_reason: String) {
  blockUser(
    input: {id: $id, block: $block, block_user: $block_user, block_reason: $block_reason}
  ) {
    result
  }
}
    `
export type BlockUserMutationFn = Apollo.MutationFunction<BlockUserMutation, BlockUserMutationVariables>

/**
 * __useBlockUserMutation__
 *
 * To run a mutation, you first call `useBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserMutation, { data, loading, error }] = useBlockUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      block: // value for 'block'
 *      block_user: // value for 'block_user'
 *      block_reason: // value for 'block_reason'
 *   },
 * });
 */
export function useBlockUserMutation(baseOptions?: Apollo.MutationHookOptions<BlockUserMutation, BlockUserMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<BlockUserMutation, BlockUserMutationVariables>(BlockUserDocument, options)
}
export type BlockUserMutationHookResult = ReturnType<typeof useBlockUserMutation>
export type BlockUserMutationResult = Apollo.MutationResult<BlockUserMutation>
export type BlockUserMutationOptions = Apollo.BaseMutationOptions<BlockUserMutation, BlockUserMutationVariables>
export const ResetSalesDemoUserDocument = gql`
    mutation resetSalesDemoUser($id: uuid!, $only_clean: Boolean) {
  resetSalesDemoUser(input: {user_id: $id, only_clean: $only_clean}) {
    status
  }
}
    `
export type ResetSalesDemoUserMutationFn = Apollo.MutationFunction<ResetSalesDemoUserMutation, ResetSalesDemoUserMutationVariables>

/**
 * __useResetSalesDemoUserMutation__
 *
 * To run a mutation, you first call `useResetSalesDemoUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetSalesDemoUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetSalesDemoUserMutation, { data, loading, error }] = useResetSalesDemoUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      only_clean: // value for 'only_clean'
 *   },
 * });
 */
export function useResetSalesDemoUserMutation(baseOptions?: Apollo.MutationHookOptions<ResetSalesDemoUserMutation, ResetSalesDemoUserMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<ResetSalesDemoUserMutation, ResetSalesDemoUserMutationVariables>(ResetSalesDemoUserDocument, options)
}
export type ResetSalesDemoUserMutationHookResult = ReturnType<typeof useResetSalesDemoUserMutation>
export type ResetSalesDemoUserMutationResult = Apollo.MutationResult<ResetSalesDemoUserMutation>
export type ResetSalesDemoUserMutationOptions = Apollo.BaseMutationOptions<ResetSalesDemoUserMutation, ResetSalesDemoUserMutationVariables>
export const CancelSubscriptionDocument = gql`
    mutation cancelSubscription($userId: uuid) {
  cancelSubscription(input: {user_id: $userId}) {
    success
  }
}
    `
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options)
}
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>
export const UnlinkPhoneDocument = gql`
    mutation unlinkPhone($id: uuid!, $phone: String!) {
  unlinkPhone(input: {id: $id, phone: $phone}) {
    result
  }
}
    `
export type UnlinkPhoneMutationFn = Apollo.MutationFunction<UnlinkPhoneMutation, UnlinkPhoneMutationVariables>

/**
 * __useUnlinkPhoneMutation__
 *
 * To run a mutation, you first call `useUnlinkPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkPhoneMutation, { data, loading, error }] = useUnlinkPhoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUnlinkPhoneMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkPhoneMutation, UnlinkPhoneMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<UnlinkPhoneMutation, UnlinkPhoneMutationVariables>(UnlinkPhoneDocument, options)
}
export type UnlinkPhoneMutationHookResult = ReturnType<typeof useUnlinkPhoneMutation>
export type UnlinkPhoneMutationResult = Apollo.MutationResult<UnlinkPhoneMutation>
export type UnlinkPhoneMutationOptions = Apollo.BaseMutationOptions<UnlinkPhoneMutation, UnlinkPhoneMutationVariables>
export const SyncAdminUserDocument = gql`
    mutation SyncAdminUser($where: SyncUserInput!) {
  syncUser(input: $where) {
    success
  }
}
    `
export type SyncAdminUserMutationFn = Apollo.MutationFunction<SyncAdminUserMutation, SyncAdminUserMutationVariables>

/**
 * __useSyncAdminUserMutation__
 *
 * To run a mutation, you first call `useSyncAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncAdminUserMutation, { data, loading, error }] = useSyncAdminUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSyncAdminUserMutation(baseOptions?: Apollo.MutationHookOptions<SyncAdminUserMutation, SyncAdminUserMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<SyncAdminUserMutation, SyncAdminUserMutationVariables>(SyncAdminUserDocument, options)
}
export type SyncAdminUserMutationHookResult = ReturnType<typeof useSyncAdminUserMutation>
export type SyncAdminUserMutationResult = Apollo.MutationResult<SyncAdminUserMutation>
export type SyncAdminUserMutationOptions = Apollo.BaseMutationOptions<SyncAdminUserMutation, SyncAdminUserMutationVariables>
export const EditProfileDocument = gql`
    mutation editProfile($id: uuid!, $firstName: String, $lastName: String, $email: String, $phones: jsonb, $picture: String, $notificationPhone: String) {
  editProfile(
    input: {id: $id, firstName: $firstName, lastName: $lastName, email: $email, phones: $phones, picture: $picture, notificationPhone: $notificationPhone}
  ) {
    result
    user {
      first_name
      linked_phones
      phone
      email
      last_name
      profile_picture_url
    }
    altBoProfile
    formattedNotificationPhone
  }
}
    `
export type EditProfileMutationFn = Apollo.MutationFunction<EditProfileMutation, EditProfileMutationVariables>

/**
 * __useEditProfileMutation__
 *
 * To run a mutation, you first call `useEditProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProfileMutation, { data, loading, error }] = useEditProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phones: // value for 'phones'
 *      picture: // value for 'picture'
 *      notificationPhone: // value for 'notificationPhone'
 *   },
 * });
 */
export function useEditProfileMutation(baseOptions?: Apollo.MutationHookOptions<EditProfileMutation, EditProfileMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<EditProfileMutation, EditProfileMutationVariables>(EditProfileDocument, options)
}
export type EditProfileMutationHookResult = ReturnType<typeof useEditProfileMutation>
export type EditProfileMutationResult = Apollo.MutationResult<EditProfileMutation>
export type EditProfileMutationOptions = Apollo.BaseMutationOptions<EditProfileMutation, EditProfileMutationVariables>
