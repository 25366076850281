/*
export const JessicaOnboarding = 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2Fonboarding-jessica.png?alt=media&token=64cb1082-ba0c-4e1c-8eb7-5a5458fe4dbb&_gl=1*1v6fkhk*_ga*MTQyMjU3Nzc1MS4xNjg4Njc0MDAz*_ga_CW55HF8NVT*MTY5NzgyNTE0NC4xOC4xLjE2OTc4MjUzNjcuNjAuMC4w'
export const MichVideoPoster = 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2Fmich-video.png?alt=media&token=fd141304-8460-442f-ba7b-6d93f66f4681&_gl=1*f2t5rd*_ga*MTQyMjU3Nzc1MS4xNjg4Njc0MDAz*_ga_CW55HF8NVT*MTY5NzgyNTE0NC4xOC4xLjE2OTc4MjUzMzQuMjEuMC4w'
export const SamBOProfile = 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2Fsam-bo-profile-picture.png?alt=media&token=2af0f0b2-c7ed-45e5-935a-5f5030ef6d3a&_gl=1*v8g45b*_ga*MTQyMjU3Nzc1MS4xNjg4Njc0MDAz*_ga_CW55HF8NVT*MTY5NzgyNTE0NC4xOC4xLjE2OTc4MjUzODMuNDQuMC4w'
export const NotFoundPersonLooking = 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2F404-not-found.svg?alt=media&token=04ffc4af-9454-4a5e-9104-e67bd303f2a3&_gl=1*z5zsba*_ga*MTcxNTMwOTE0Ni4xNjk5MjkzMDU2*_ga_CW55HF8NVT*MTY5OTI5MzA1NS4xLjEuMTY5OTI5MzE0My4zMi4wLjA.'
*/
const Images = {
  JessicaOnboarding: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2Fonboarding-jessica.png?alt=media&token=64cb1082-ba0c-4e1c-8eb7-5a5458fe4dbb&_gl=1*1v6fkhk*_ga*MTQyMjU3Nzc1MS4xNjg4Njc0MDAz*_ga_CW55HF8NVT*MTY5NzgyNTE0NC4xOC4xLjE2OTc4MjUzNjcuNjAuMC4w',
  MichVideoPoster: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2Fmich-video.png?alt=media&token=fd141304-8460-442f-ba7b-6d93f66f4681&_gl=1*f2t5rd*_ga*MTQyMjU3Nzc1MS4xNjg4Njc0MDAz*_ga_CW55HF8NVT*MTY5NzgyNTE0NC4xOC4xLjE2OTc4MjUzMzQuMjEuMC4w',
  SamBOProfile: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2Fsam-bo-profile-picture.png?alt=media&token=2af0f0b2-c7ed-45e5-935a-5f5030ef6d3a&_gl=1*v8g45b*_ga*MTQyMjU3Nzc1MS4xNjg4Njc0MDAz*_ga_CW55HF8NVT*MTY5NzgyNTE0NC4xOC4xLjE2OTc4MjUzODMuNDQuMC4w',
  NotFoundPersonLooking: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2F404-not-found.svg?alt=media&token=04ffc4af-9454-4a5e-9104-e67bd303f2a3&_gl=1*z5zsba*_ga*MTcxNTMwOTE0Ni4xNjk5MjkzMDU2*_ga_CW55HF8NVT*MTY5OTI5MzA1NS4xLjEuMTY5OTI5MzE0My4zMi4wLjA.',
  NotFoundPersonLookingDesktop: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2F404-not-found-desktop.png?alt=media&token=fb588b9b-3d0b-40d4-80a2-92ec5ef6bc6b',
  DoneTodoTasks: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FDoneTodoTasks.png?alt=media&token=df5b4fb2-5325-42a8-a9f9-a75a12e94ca3',
  DoneTasksSuccess: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FdoneTasksCanvas.png?alt=media&token=5acd249d-d9ba-42ad-95ed-e0504a36eec0',
  GenericAttachment: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2Fgeneric-attachment.png?alt=media&token=f7e021d3-7057-40de-b04b-14d37fa5ded5',
  LoaderSpinner: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FLoaderSpinner.png?alt=media&token=2339e478-e7d6-4d30-9be7-40f341acc0a7',
  NotificationBell: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FNotificationBell.png?alt=media&token=ef506cdf-fede-4239-890d-6083f330da30',
  NotificationAchievment: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FNotifacationsAchievments.svg?alt=media&token=e816e7d3-226a-4398-b1fb-08ad513c89cd',
  WizardCampaingAchievment: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2FAchievement-campagin.png?alt=media&token=cb00d3cd-ad36-41fa-b338-46d289097870',
  Logo1x1: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2Fwebsite-builder%2Fassets%2FShared%2Flogo1x1.png?alt=media&token=e7688881-4135-4d27-8608-1b1809915b99',
  Logo1x1WithText: 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/ui-develop%2Fwebsite-builder%2Fassets%2FShared%2Flogo1x1-text.png?alt=media&token=0861d091-3064-46db-bd5e-0bef051d5ca1',
}

export default Images
