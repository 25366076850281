import Box from '@mui/material/Box'

import { ENVIROMENT } from '#app/store/enviroment'

export function CreateBO() {
  return (
    <Box sx={{
      height: '75vh',
      width: '600px'
    }}
    >
      <iframe
        className='airtable-embed'
        src={ENVIROMENT.airtable_create_bo}
        frameBorder='0'
        width='100%'
        height='100%'
        style={{
          background: 'transparent'
        }}
      >
      </iframe>
    </Box>
  )
}
