import { useTranslation } from 'react-i18next'

import { GetBusinessOwnerTasksQuery } from '#app/operations/tasks/tasks.queries.generated'
import { ProfileAvatarDetailed } from '#app/v2/components/ProfileAvatarDetailed'

interface Props {
  task?: GetBusinessOwnerTasksQuery['user_tasks'][0]
}

export function Copilot({ task }: Props) {
  const { t } = useTranslation()
  return (
    <section
      id='bo-copilot'
      className=' mb-6'
    >
      <h3 className='mb-1.5 text-sm/tight font-medium text-[#454545]'>
        {t('v2.tasks.copilot', 'Owner’s Copilot')}
      </h3>
      <div className=' flex items-center gap-1'>
        <ProfileAvatarDetailed
          UImin
          linearUserId={task?.linearIssueLinked?.assignee_id}
          firstName={task?.userByCreatedBy?.first_name}
          lastName={task?.userByCreatedBy?.last_name}
          email={task?.userByCreatedBy?.email}
        />
        {/*
        @bruk-owners: please check if "UImin"  in ProfileAvatarDetailed it's okay to replace this ->

        <Avatar className=' h-6 w-6'>
          <AvatarImage src={Images.JessicaOnboarding} className='object-cover' />
          <AvatarFallback>JA</AvatarFallback>
        </Avatar>
        <p className='text-xs text-[#454545] '>Jessica A. | Onboarding specialist</p>

        */}
      </div>
    </section>
  )
}
