import { useCallback } from 'react'
import { Box, Stack } from '@mui/material'

import { Chip } from '#app/components/Chip'

interface Props {
  onClick: (option: string) => void
}

export const nunjucksOptions = [
  'owners.whatsapp',
  'bo.first_name',
  'bo.last_name',
  'bo.company_name',
  'bo.logo',
  'bo.domain',
  'bo.google_my_business_url',
  'bo.call_center_phone_number',
  'bo.personal_address',
  'bo.company_email',
  'bo.services'
]

const nunjucksImgs = [
  'bo.logo'
]

const nunjucksUrls = [
  'owners.whatsapp',
  'bo.google_my_business_url',
  'bo.domain'
]

export function Nunjucks({ onClick }: Props) {
  const createNunjuck = useCallback((textVar: string) => {
    let nunjucksVar = `{{${textVar}}}`
    if (nunjucksImgs.includes(textVar)) {
      nunjucksVar = `<img src='{{${textVar}}}'/><br/><a href='{{${textVar}}}' target='_blank'>Link Logo</a>`
    }
    else if (nunjucksUrls.includes(textVar)) {
      nunjucksVar = `<a href='{{${textVar}}}' target='_blank'>{{${textVar}}}</a>`
    }
    return nunjucksVar
  }, [])

  return (
    <Box
      sx={{
        overflow: 'auto'
      }}
      mt={1}
    >
      <Stack direction='row'>
        {nunjucksOptions.map((nunjuck: string) => (
          <Chip.Basic
            sx={{
              m: 1
            }}
            label={nunjuck}
            onClick={() => { onClick(createNunjuck(nunjuck)) }}
          />
        )
        )}
      </Stack>
    </Box>
  )
}
