import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type GetWizardTemplatesQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetWizardTemplatesQuery = { __typename?: 'query_root', wizard_templates: Array<{ __typename?: 'wizard_templates', status: string, subtitle?: string | undefined, title: string, wizard_flow: any, id: any, text_end_1?: string | undefined, text_end_2?: string | undefined }> }

export type GetWizardByIdQueryVariables = Types.Exact<{
  wizardId: Types.Scalars['uuid']
}>

export type GetWizardByIdQuery = { __typename?: 'query_root', wizard_templates: Array<{ __typename?: 'wizard_templates', status: string, subtitle?: string | undefined, title: string, wizard_flow: any, id: any, text_end_1?: string | undefined, text_end_2?: string | undefined }> }

export type GetUserWizardByIdQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']
  wizardId: Types.Scalars['uuid']
  taskId?: Types.InputMaybe<Types.Scalars['uuid']>
}>

export type GetUserWizardByIdQuery = { __typename?: 'query_root', getUserWizardById?: { __typename?: 'GetUserWizardByIdPayload', result: boolean, wizard?: any | undefined } | undefined }

export type UserWizardFragment = { __typename?: 'GetUserWizardByIdPayload', wizard?: any | undefined }

export const UserWizardFragmentDoc = gql`
    fragment userWizard on GetUserWizardByIdPayload {
  wizard
}
    `
export const GetWizardTemplatesDocument = gql`
    query getWizardTemplates {
  wizard_templates(where: {status: {_neq: "deleted"}}) {
    status
    subtitle
    title
    wizard_flow
    id
    text_end_1
    text_end_2
  }
}
    `

/**
 * __useGetWizardTemplatesQuery__
 *
 * To run a query within a React component, call `useGetWizardTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWizardTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWizardTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWizardTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetWizardTemplatesQuery, GetWizardTemplatesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetWizardTemplatesQuery, GetWizardTemplatesQueryVariables>(GetWizardTemplatesDocument, options)
}
export function useGetWizardTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWizardTemplatesQuery, GetWizardTemplatesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetWizardTemplatesQuery, GetWizardTemplatesQueryVariables>(GetWizardTemplatesDocument, options)
}
export type GetWizardTemplatesQueryHookResult = ReturnType<typeof useGetWizardTemplatesQuery>
export type GetWizardTemplatesLazyQueryHookResult = ReturnType<typeof useGetWizardTemplatesLazyQuery>
export type GetWizardTemplatesQueryResult = Apollo.QueryResult<GetWizardTemplatesQuery, GetWizardTemplatesQueryVariables>
export const GetWizardByIdDocument = gql`
    query getWizardById($wizardId: uuid!) {
  wizard_templates(where: {id: {_eq: $wizardId}, status: {_neq: "deleted"}}) {
    status
    subtitle
    title
    wizard_flow
    id
    text_end_1
    text_end_2
  }
}
    `

/**
 * __useGetWizardByIdQuery__
 *
 * To run a query within a React component, call `useGetWizardByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWizardByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWizardByIdQuery({
 *   variables: {
 *      wizardId: // value for 'wizardId'
 *   },
 * });
 */
export function useGetWizardByIdQuery(baseOptions: Apollo.QueryHookOptions<GetWizardByIdQuery, GetWizardByIdQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetWizardByIdQuery, GetWizardByIdQueryVariables>(GetWizardByIdDocument, options)
}
export function useGetWizardByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWizardByIdQuery, GetWizardByIdQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetWizardByIdQuery, GetWizardByIdQueryVariables>(GetWizardByIdDocument, options)
}
export type GetWizardByIdQueryHookResult = ReturnType<typeof useGetWizardByIdQuery>
export type GetWizardByIdLazyQueryHookResult = ReturnType<typeof useGetWizardByIdLazyQuery>
export type GetWizardByIdQueryResult = Apollo.QueryResult<GetWizardByIdQuery, GetWizardByIdQueryVariables>
export const GetUserWizardByIdDocument = gql`
    query getUserWizardById($userId: uuid!, $wizardId: uuid!, $taskId: uuid) {
  getUserWizardById(
    input: {userId: $userId, wizardId: $wizardId, taskId: $taskId}
  ) {
    result
    wizard
  }
}
    `

/**
 * __useGetUserWizardByIdQuery__
 *
 * To run a query within a React component, call `useGetUserWizardByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWizardByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWizardByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      wizardId: // value for 'wizardId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetUserWizardByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserWizardByIdQuery, GetUserWizardByIdQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetUserWizardByIdQuery, GetUserWizardByIdQueryVariables>(GetUserWizardByIdDocument, options)
}
export function useGetUserWizardByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserWizardByIdQuery, GetUserWizardByIdQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetUserWizardByIdQuery, GetUserWizardByIdQueryVariables>(GetUserWizardByIdDocument, options)
}
export type GetUserWizardByIdQueryHookResult = ReturnType<typeof useGetUserWizardByIdQuery>
export type GetUserWizardByIdLazyQueryHookResult = ReturnType<typeof useGetUserWizardByIdLazyQuery>
export type GetUserWizardByIdQueryResult = Apollo.QueryResult<GetUserWizardByIdQuery, GetUserWizardByIdQueryVariables>
