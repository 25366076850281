/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import ProfileContext from '#app/contexts/ProfileContext'
import useUploadResource from '#app/hooks/useUploadResource'
import { useEditProfileMutation } from '#app/operations/users/users.mutations.generated'
import { GET_PROFILE, GET_PROFILE_TYPE } from '#app/utils/graphqlQueries'
import { CenteredNavTitle } from '#app/v2/components/CenteredNavTitle'
import { HeaderSharedNavigation } from '#app/v2/components/HeaderSharedNavigation'
import { EditProfile } from '#app/v2/pages/EditProfile/page'
import { useAppContextV2 } from '#app/v2-core/layouts/useAppContextV2'

export function ProfileInformation() {
  const { t } = useTranslation()
  const profileData = useContext(ProfileContext)
  const { uploadResource } = useUploadResource()
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([])
  const [avatarPreview, setAvatarPreview] = useState<string>()
  const { V2_SET_LOADING } = useAppContextV2()
  const { enqueueSnackbar } = useSnackbar()
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false)
  const [submitEditProfile, setSubmitEditProfile] = useState<(() => void) | null>(null)
  const [editProfile] = useEditProfileMutation({
    update(cache, { data }) {
      if (!data?.editProfile?.user) return

      const cacheQuery = cache.readQuery<GET_PROFILE_TYPE>({
        query: GET_PROFILE
      })

      if (!cacheQuery || !cacheQuery.getProfile) return

      const updatedProfile = {
        ...cacheQuery.getProfile.user,
        ...data.editProfile.user,
        __typename: 'User'
      }

      const updatedAltbo = {
        ...cacheQuery.getProfile.altBoProfile,
        ...data.editProfile.altBoProfile,
        phone_number: data?.editProfile?.formattedNotificationPhone ?? data?.editProfile?.altBoProfile?.phone_number
      }

      if (data?.editProfile?.result) {
        cache.writeQuery({
          query: GET_PROFILE,
          data: {
            getProfile: {
              user: updatedProfile,
              altBoProfile: updatedAltbo,
              __typename: 'GetProfilePayload'
            },
            roles: cacheQuery.roles
          }
        })
      }
    }
  })

  useEffect(() => {
    V2_SET_LOADING(true)
    const { getProfile: { user } } = profileData

    if (user) {
      // Create a copy of the linked_phones array
      const phones = Array.isArray(user.linked_phones) ? [...user.linked_phones] : []

      // Check and add the user's phone number if it's not already in the array
      if (user.phone && !phones.includes(user.phone)) {
        phones.unshift(user.phone)
      }

      setPhoneNumbers(phones)
      setAvatarPreview(profileData?.getProfile?.user?.profile_picture_url ?? '')
      V2_SET_LOADING(false)
    }
  }, [V2_SET_LOADING, profileData])

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0]
      const path: string = await uploadResource(profileData?.getProfile?.user?.id, 'avatar', file)
      setAvatarPreview(path)
    }
  }

  const onSubmit = async (data: any) => {
    const initialProfile = profileData?.getProfile?.user
    const updatedObj: any = {
      phones: data.phones ?? []
    }

    // Compare each field and add to updatedObj if changed
    if (initialProfile) {
      if (initialProfile.first_name !== data.firstName) {
        updatedObj.firstName = data.firstName
      }
      if (initialProfile.last_name !== data.lastName) {
        updatedObj.lastName = data.lastName
      }
      if (initialProfile.email !== data.email) {
        updatedObj.email = data.email
      }

      if (profileData?.getProfile?.altBoProfile?.phone_number !== data.notificationPhone) {
        updatedObj.notificationPhone = data.notificationPhone
      }
      // Add more fields comparison as needed

      // Handle the avatar separately
      if (initialProfile.profile_picture_url !== avatarPreview) {
        updatedObj.picture = avatarPreview
      }

      // Include the user ID
      updatedObj.id = initialProfile.id
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const result = await editProfile({
      variables: updatedObj
    })

    if (result?.data?.editProfile?.result) {
      enqueueSnackbar('User Updated Correctly', {
        variant: 'success'
      })
    }
  }

  function hasUnsavedChanges(hasChanges: boolean) {
    setUnsavedChanges(hasChanges)
  }

  return (
    <>
      <HeaderSharedNavigation
        backUrl='/v2/home'
        main={<CenteredNavTitle title={t('v2Profile.editProfile.label', 'Edit Profile')} />}
        unsavedChanges={unsavedChanges}
        saveChanges={() => submitEditProfile && submitEditProfile()}
      />
      <EditProfile
        avatar={avatarPreview}
        clientId={profileData?.getProfile?.altBoProfile.company_autonumber}
        firstName={profileData?.getProfile?.user?.first_name ?? ''}
        lastName={profileData?.getProfile?.user?.last_name ?? ''}
        email={profileData?.getProfile?.user?.email}
        phoneNumbers={phoneNumbers}
        notificationPhone={profileData?.getProfile?.altBoProfile?.phone_number}
        services={profileData?.getProfile?.altBoProfile?.sub_services_names}
        handleFileChange={handleFileChange}
        setSubmitForm={setSubmitEditProfile}
        onSubmit={onSubmit}
        onChange={hasUnsavedChanges}
      />
    </>
  )
}
