import create from 'zustand'
import { devtools } from 'zustand/middleware'

import { SectionProps } from '#app/routes/Admin/WebsiteEditor/types'

export type SectionPosition = 'above' | 'below' | undefined

export interface LexorankContext {
  current: string
  next?: string
  prev?: string
}
interface SectionsState {
  newSectionDrawer: SectionPosition
  newSectionDrawerLexorankContext?: LexorankContext
  lastUpdate: Date
  sections: Record<string, SectionProps>
  forceRefresh: boolean
}

interface SectionsActions {
  setSectionDetails: (sectionId: string, details: SectionProps) => void
  updateSectionDetails: (sectionId: string, details: Partial<SectionProps>) => void

  openNewSectionDrawer: (position: SectionPosition, newSectionDrawerLexorankCurrentBlock: LexorankContext) => void
  closeNewSectionDrawer: () => void

  removeSection: (sectionId: string) => void

  setForceRefreshOn: () => void
  setForceRefreshOff: () => void
  refreshSections: () => void
}

const useWebsiteEditorStore = create<SectionsState & SectionsActions>()(
  devtools(
    (set) => ({
      lastUpdate: new Date(),
      sections: {},
      forceRefresh: false,
      newSectionDrawer: undefined,
      newSectionDrawerLexorankContext: undefined,

      setForceRefreshOn() {
        set(() => ({
          forceRefresh: true
        }))
      },

      setForceRefreshOff() {
        set(() => ({
          forceRefresh: false
        }))
      },

      openNewSectionDrawer(position: SectionPosition, newSectionDrawerLexorankContext) {
        set(() => ({
          newSectionDrawer: position,
          newSectionDrawerLexorankContext
        }))
      },

      closeNewSectionDrawer() {
        set(() => ({
          newSectionDrawer: undefined,
        }))
      },

      setSectionDetails(sectionId: string, details: SectionProps) {
        set((state) => ({
          sections: {
            ...state.sections,
            [sectionId]: details,
          },
        }))
      },

      updateSectionDetails(sectionId: string, newDetails: Partial<SectionProps>) {
        set((state) => {
          const existingSection = state.sections[sectionId]
          if (!existingSection) {
            alert('The section is NULL!')
            return {}
          }
          return {
            lastUpdate: new Date(),
            sections: {
              ...state.sections,
              [sectionId]: {
                ...existingSection,
                ...newDetails,
              },
            },
          }
        })
      },

      removeSection(sectionId: string) {
        set((state) => {
          const updatedSections = {
            ...state.sections
          }
          delete updatedSections[sectionId]
          return {
            sections: updatedSections,
          }
        })
      },

      refreshSections() {
        set(() => ({
          sections: {},
        }))
      }

    }),
    {
      name: 'zt_website_editor',
    }
  )
)

export const resetStore = () => {
  useWebsiteEditorStore.setState({
    lastUpdate: new Date(),
    sections: {},
    newSectionDrawer: undefined,
    newSectionDrawerLexorankContext: undefined
  })
}

export default useWebsiteEditorStore
