import { Avatar, AvatarFallback, AvatarImage } from '#app/v2/components/ui/avatar'
import { getInitials } from '#app/v2/libs/utils'

export interface MessageCardProps {
  sender: {
    avatar: string
    name: string
  }
  timeStamp: string
  message: string
}

export const MessageCard = ({ sender, timeStamp, message }: MessageCardProps) => {
  return (
    <li className=' rounded-xl bg-white py-3 pl-2 pr-3.5 text-[#454545] shadow'>
      <div className='flex gap-1.5'>
        <Avatar className='h-11 w-11'>
          <AvatarImage
            src={sender.avatar}
            className=' object-cover'
          />
          {/* Groups without profile picture? */}
          <AvatarFallback>{getInitials(sender.name)}</AvatarFallback>
        </Avatar>
        <div className='grow'>
          <div className='flex items-center'>
            <p className='grow text-sm/6 font-medium '>{sender.name}</p>
            <p className='text-xs'>{timeStamp}</p>
          </div>
          <p className='mt-1 line-clamp-1 max-w-[270px] text-xs first-letter:ml-1'>{message}</p>
        </div>
      </div>
    </li>
  )
}
