import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Box, Card, CardActions, CardContent, Grid, Stack, Typography } from '@mui/material'
import { t } from 'i18next'

import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import { LoadingGrid } from '#app/components/Loading/LoadingGrid'
import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import ProfileContext from '#app/contexts/ProfileContext'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { EntityPage } from '#app/layouts/EntityView'
import { useAvailabilitiesByEmailQuery } from '#app/operations/booking/booking.queries.generated'

import { generateCalSignedUrl } from '.'

export function AvailabilityItem({ schedule, setCalIframeURL }: { schedule: any, setCalIframeURL: Dispatch<SetStateAction<string | null>> }) {
  const calDays = useMemo(() => [
    t('booking.days.sunday', 'Sun'),
    t('booking.days.monday', 'Mon'),
    t('booking.days.tuesday', 'Tue'),
    t('booking.days.wednesday', 'Wen'),
    t('booking.days.thrusday', 'Thu'),
    t('booking.days.friday', 'Fri'),
    t('booking.days.saturday', 'Sat')
  ], [])
  const { getProfile: { user } } = useContext(ProfileContext)

  const generateAvailabilityText = useCallback((availability: any) => {
    let res = ''
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (schedule?.Availabilities.length === 1) {
      res = `${res} ${calDays[availability?.days[0]]} - ${calDays[availability?.days[availability?.days?.length - 1]]}`
    }
    else {
      if (availability?.days && availability?.days.length) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        availability?.days?.forEach((dayNumber: number) => {
          res = `${res} ${calDays[dayNumber]}`
        })
      }
    }

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call
    res = `${res}, ${availability?.startTime?.substring(0, availability?.startTime.length - 3)} - ${availability?.endTime?.substring(0, availability?.endTime.length - 3)}`
    return res
  }, [calDays, schedule?.Availabilities.length])

  return (
    <Card sx={{
      minWidth: 275
    }}
    >
      <CardContent>
        <Typography
          gutterBottom
          sx={{
            fontSize: 14
          }}
          color='text.secondary'
        >
        </Typography>
        <Typography
          variant='h5'
          component='div'
          mb={2}
        >
          {schedule?.name}
        </Typography>
        <Grid
          container
          direction='row'
          sx={{
            width: '100%'
          }}
          mb={2}
        >
          {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            schedule?.Availabilities?.map((availability: any) => {
              return (
                <Grid
                  item
                  xs={12}
                  lg={12}
                  md={12}
                >
                  <Typography>{generateAvailabilityText(availability)}</Typography>
                </Grid>
              )
            })
          }
        </Grid>
        {
          schedule?.timeZone
          && <Chip.Basic label={schedule?.timeZone} />
        }
      </CardContent>
      <CardActions>
        <Stack
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          spacing={2}
          sx={{
            width: '100%'
          }}
        >
          <Box>
            <Btn.IconButton.Edit
              onClick={() => setCalIframeURL(generateCalSignedUrl(`${process.env.REACT_APP_CAL_DOMAIN ?? ''}/availability/${schedule?.id}?`, user))}
            />
          </Box>
        </Stack>
      </CardActions>
    </Card>
  )
}

export function Availability() {
  const { userEmail } = useEntityUser()
  const { data, loading, startPolling } = useAvailabilitiesByEmailQuery({
    variables: {
      email: userEmail ?? ''
    }
  })
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const [calIframeURL, setCalIframeURL] = useState<string | null>(null)

  useEffect(() => {
    startPolling(5000)
  }, [startPolling])

  return (
    <EntityPage
      hasReturn
      isLoading={false}
      layout='free'
      pageTitle={t('booking.availability.title', 'Booking Availability')}
      pageSubtitle={t('booking.availability.subTitle', 'Configure times when you are available for bookings.')}
      mainContent={(
        <Grid
          container
          spacing={3}
        >
          <RightSideBar
            size='lg'
            isOpen={!!calIframeURL}
            closeHandler={() => { setCalIframeURL(null); setIframeLoaded(false) }}
          >
            <>
              <Typography variant='h4'>
                {' '}
                <>{t('booking.availability.title', 'Booking Availability')}</>
                {' '}
              </Typography>
              <Typography variant='subtitle2'>
                {' '}
                <>{t('booking.availability.drawer', 'Editing this availability will affect the booking types that are scheduled in the future')}</>
                {' '}
              </Typography>
              {
                calIframeURL
                && (
                  <Box
                    sx={{
                      width: '100%',
                      height: '85vh'
                    }}
                    mt={2}
                  >
                    {
                      !iframeLoaded
                      && <LoadingGrid />
                    }
                    <iframe
                      height='100%'
                      width='100%'
                      src={calIframeURL}
                      onLoad={() => setIframeLoaded(true)}
                    />
                  </Box>
                )
              }
            </>
          </RightSideBar>

          <Grid
            item
            xs={12}
          >
            {/* <CustomCard>
            <CustomCard.Body className='v1-card-bottom-padding'> */}
            <Stack spacing={2}>
              {loading && <LoadingGrid />}
              {data?.calcom_users[0].Schedules.map((schedule) => {
                return (
                  <AvailabilityItem
                    schedule={schedule}
                    setCalIframeURL={setCalIframeURL}
                  />
                )
              })}
            </Stack>
            {/* </CustomCard.Body>
          </CustomCard> */}
          </Grid>
        </Grid>
      )}
    />
  )
}
