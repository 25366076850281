import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type UserTaskFieldsFragment = { __typename?: 'user_tasks', id: any, status: string, title: string, metadata?: any | undefined, description_html?: string | undefined, response_html?: string | undefined, short_description?: string | undefined, loom_videos?: any | undefined, cognitoforms_key?: string | undefined, cognitoforms_dataform?: string | undefined, cognitoforms_response?: any | undefined, user_id?: string | undefined, bo_id?: any | undefined, response_at?: any | undefined, created_at: any, updated_at: any, created_by: any, updated_by?: any | undefined, deleted: boolean, tag_slug?: string | undefined, linear_issue_id?: any | undefined, userByCreatedBy: { __typename?: 'users', id: any, first_name?: string | undefined, last_name?: string | undefined, profile_picture_url?: string | undefined, email: string }, userByBo?: { __typename?: 'users', id: any, first_name?: string | undefined, last_name?: string | undefined, profile_picture_url?: string | undefined, email: string } | undefined, userByUpdatedBy?: { __typename?: 'users', id: any, first_name?: string | undefined, last_name?: string | undefined, profile_picture_url?: string | undefined, email: string } | undefined, linearIssueLinked?: { __typename?: 'linear_issues', original_linear_issue_id: string, assignee_id?: string | undefined, assignee?: any | undefined, creator_id?: string | undefined, title?: string | undefined, description?: string | undefined, number?: number | undefined } | undefined }

export type GetBusinessOwnerTasksQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']
  status?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>
}>

export type GetBusinessOwnerTasksQuery = { __typename?: 'query_root', user_tasks: Array<{ __typename?: 'user_tasks', id: any, status: string, title: string, metadata?: any | undefined, description_html?: string | undefined, response_html?: string | undefined, short_description?: string | undefined, loom_videos?: any | undefined, cognitoforms_key?: string | undefined, cognitoforms_dataform?: string | undefined, cognitoforms_response?: any | undefined, user_id?: string | undefined, bo_id?: any | undefined, response_at?: any | undefined, created_at: any, updated_at: any, created_by: any, updated_by?: any | undefined, deleted: boolean, tag_slug?: string | undefined, linear_issue_id?: any | undefined, userByCreatedBy: { __typename?: 'users', id: any, first_name?: string | undefined, last_name?: string | undefined, profile_picture_url?: string | undefined, email: string }, userByBo?: { __typename?: 'users', id: any, first_name?: string | undefined, last_name?: string | undefined, profile_picture_url?: string | undefined, email: string } | undefined, userByUpdatedBy?: { __typename?: 'users', id: any, first_name?: string | undefined, last_name?: string | undefined, profile_picture_url?: string | undefined, email: string } | undefined, linearIssueLinked?: { __typename?: 'linear_issues', original_linear_issue_id: string, assignee_id?: string | undefined, assignee?: any | undefined, creator_id?: string | undefined, title?: string | undefined, description?: string | undefined, number?: number | undefined } | undefined }> }

export type GetBusinessOwnerTaskByIdQueryVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']
  userId: Types.Scalars['uuid']
}>

export type GetBusinessOwnerTaskByIdQuery = { __typename?: 'query_root', user_tasks: Array<{ __typename?: 'user_tasks', id: any, status: string, title: string, metadata?: any | undefined, description_html?: string | undefined, response_html?: string | undefined, short_description?: string | undefined, loom_videos?: any | undefined, cognitoforms_key?: string | undefined, cognitoforms_dataform?: string | undefined, cognitoforms_response?: any | undefined, user_id?: string | undefined, bo_id?: any | undefined, response_at?: any | undefined, created_at: any, updated_at: any, created_by: any, updated_by?: any | undefined, deleted: boolean, tag_slug?: string | undefined, linear_issue_id?: any | undefined, userByCreatedBy: { __typename?: 'users', id: any, first_name?: string | undefined, last_name?: string | undefined, profile_picture_url?: string | undefined, email: string }, userByBo?: { __typename?: 'users', id: any, first_name?: string | undefined, last_name?: string | undefined, profile_picture_url?: string | undefined, email: string } | undefined, userByUpdatedBy?: { __typename?: 'users', id: any, first_name?: string | undefined, last_name?: string | undefined, profile_picture_url?: string | undefined, email: string } | undefined, linearIssueLinked?: { __typename?: 'linear_issues', original_linear_issue_id: string, assignee_id?: string | undefined, assignee?: any | undefined, creator_id?: string | undefined, title?: string | undefined, description?: string | undefined, number?: number | undefined } | undefined }> }

export const UserTaskFieldsFragmentDoc = gql`
    fragment UserTaskFields on user_tasks {
  id
  status
  title
  metadata
  description_html
  response_html
  short_description
  loom_videos
  cognitoforms_key
  cognitoforms_dataform
  cognitoforms_response
  user_id
  bo_id
  response_at
  created_at
  updated_at
  created_by
  updated_by
  deleted
  tag_slug
  linear_issue_id
  userByCreatedBy {
    id
    first_name
    last_name
    profile_picture_url
    email
  }
  userByBo {
    id
    first_name
    last_name
    profile_picture_url
    email
  }
  userByUpdatedBy {
    id
    first_name
    last_name
    profile_picture_url
    email
  }
  linearIssueLinked {
    original_linear_issue_id
    assignee_id
    assignee
    creator_id
    title
    description
    number
  }
}
    `
export const GetBusinessOwnerTasksDocument = gql`
    query getBusinessOwnerTasks($userId: uuid!, $status: [String!] = ["assigned", "replied", "response_draft"]) {
  user_tasks(
    where: {deleted: {_neq: true}, bo_id: {_eq: $userId}, status: {_in: $status}}
    order_by: {created_at: desc}
  ) {
    ...UserTaskFields
  }
}
    ${UserTaskFieldsFragmentDoc}`

/**
 * __useGetBusinessOwnerTasksQuery__
 *
 * To run a query within a React component, call `useGetBusinessOwnerTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessOwnerTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessOwnerTasksQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetBusinessOwnerTasksQuery(baseOptions: Apollo.QueryHookOptions<GetBusinessOwnerTasksQuery, GetBusinessOwnerTasksQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetBusinessOwnerTasksQuery, GetBusinessOwnerTasksQueryVariables>(GetBusinessOwnerTasksDocument, options)
}
export function useGetBusinessOwnerTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessOwnerTasksQuery, GetBusinessOwnerTasksQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetBusinessOwnerTasksQuery, GetBusinessOwnerTasksQueryVariables>(GetBusinessOwnerTasksDocument, options)
}
export type GetBusinessOwnerTasksQueryHookResult = ReturnType<typeof useGetBusinessOwnerTasksQuery>
export type GetBusinessOwnerTasksLazyQueryHookResult = ReturnType<typeof useGetBusinessOwnerTasksLazyQuery>
export type GetBusinessOwnerTasksQueryResult = Apollo.QueryResult<GetBusinessOwnerTasksQuery, GetBusinessOwnerTasksQueryVariables>
export const GetBusinessOwnerTaskByIdDocument = gql`
    query getBusinessOwnerTaskById($taskId: uuid!, $userId: uuid!) {
  user_tasks(
    where: {id: {_eq: $taskId}, deleted: {_neq: true}, bo_id: {_eq: $userId}}
  ) {
    ...UserTaskFields
  }
}
    ${UserTaskFieldsFragmentDoc}`

/**
 * __useGetBusinessOwnerTaskByIdQuery__
 *
 * To run a query within a React component, call `useGetBusinessOwnerTaskByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessOwnerTaskByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessOwnerTaskByIdQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetBusinessOwnerTaskByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBusinessOwnerTaskByIdQuery, GetBusinessOwnerTaskByIdQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<GetBusinessOwnerTaskByIdQuery, GetBusinessOwnerTaskByIdQueryVariables>(GetBusinessOwnerTaskByIdDocument, options)
}
export function useGetBusinessOwnerTaskByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessOwnerTaskByIdQuery, GetBusinessOwnerTaskByIdQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<GetBusinessOwnerTaskByIdQuery, GetBusinessOwnerTaskByIdQueryVariables>(GetBusinessOwnerTaskByIdDocument, options)
}
export type GetBusinessOwnerTaskByIdQueryHookResult = ReturnType<typeof useGetBusinessOwnerTaskByIdQuery>
export type GetBusinessOwnerTaskByIdLazyQueryHookResult = ReturnType<typeof useGetBusinessOwnerTaskByIdLazyQuery>
export type GetBusinessOwnerTaskByIdQueryResult = Apollo.QueryResult<GetBusinessOwnerTaskByIdQuery, GetBusinessOwnerTaskByIdQueryVariables>
