import produce from 'immer'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

interface State {
  key: string
  usedLinearTemplateIds: string[]
  addLinearTemplateId: (templateId: string) => void
  fatalErrorMessage: string | null
  newFatalError: (newError: string) => void
}

const INITIAL_STATE = {
  usedLinearTemplateIds: [],
  fatalErrorMessage: null
}

export const useStoreCustomerJourney = create<State>()(
  devtools(
    (set) => ({
      key: 'ZT_customer_journey',
      ...INITIAL_STATE,
      addLinearTemplateId: (templateId) => {
        return set(
          produce((state) => {
            state.usedLinearTemplateIds.push(templateId)
          })
        )
      },
      newFatalError: (err: string) => {
        return set(
          produce((state) => {
            state.fatalErrorMessage = err
          })
        )
      }
    }),
    {
      name: 'ZT_customer_journey'
    }
  )
)
