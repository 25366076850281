import { AltBoProfile } from '#app/feature/AltBoProfile'
import { useEntityUser } from '#app/hooks/useEntityUser'
import { EntityPage } from '#app/layouts/EntityView'

export function Profile() {
  const { currentIsSalesDemoAccount, getSalesDemoEmail, getServiceSalesDemoEmail, demoAltBoEmail, altBoProfile, userEmail } = useEntityUser()

  return (
    <EntityPage
      isLoading={false}
      layout='free'
      pageTitle='Profile'
      mainContent={(
        <AltBoProfile
          profile={{
            altBoProfile
          }}
          currentIsSalesDemoAccount={currentIsSalesDemoAccount}
          getSalesDemoEmail={getSalesDemoEmail}
          getServiceSalesDemoEmail={getServiceSalesDemoEmail}
          demoAltBoEmail={demoAltBoEmail}
          userEmail={userEmail}
        />
      )}
    />
  )
}
