import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate, useNavigation } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { findIndex, isEqual } from 'lodash'

import Card from '../../../components/Card/Card'
import OnboardingHeader from '../../../components/OnboardingHeader/OnboardingHeader'
import OnboardingNav from '../../../components/OnboardingNav/OnboardingNav'
import PageOnboarding from '../../../components/PageOnboarding/PageOnboarding'
import ProfileContext from '../../../contexts/ProfileContext'
import { useOnboardingFormReducer } from '../../../hooks/useOnboardingFormReducer'
import { EDIT_ONBOARDING } from '../../../utils/graphqlQueries'
import { useIsDesktopSize } from '../../../utils/mediaQueries'
const Onboarding = () => {
  const data = useContext(ProfileContext)
  const business = data.getProfile.user.businesses[0]
  const [isLoading, setLoading] = useState(false)
  const { logout } = useAuth0()
  const [editOnboarding] = useMutation(EDIT_ONBOARDING)
  const [onboarding, dispatch] = useOnboardingFormReducer(business.onboarding)
  const navigate = useNavigate()
  const navigation = useNavigation()
  const location = useLocation()
  const { t } = useTranslation()

  const onboardingPages = [
    {
      path: '/onboarding/personal-information',
      section: 'personalInformation',
      title: t('onboarding.personalInformation.title'),
      progress: 45,
      page: 1
    },
    {
      path: '/onboarding/first-business-information',
      section: 'businessInformation',
      title: t('onboarding.businessInformation.title'),
      progress: 45,
      page: 2
    },
    {
      path: '/onboarding/congratulations',
      section: 'congratulations',
      title: t('onboarding.congratulations.title'),
      progress: 100,
      page: 3
    }
  ]

  let currentPage = onboardingPages.find((p) => p.path === location.pathname)

  if (!currentPage) {
    let lastEdited = onboardingPages
      .find((page) => page.section === onboarding.metadata.lastEditedSection)

    if (lastEdited) {
      const secondBusinessPageFields = ['legalEntityType', 'liabilityInsurance']

      if (secondBusinessPageFields.includes(onboarding.metadata.lastEditedSection)) {
        lastEdited = onboardingPages.find((p) => p.path === '/onboarding/second-business-information')
      }
    }

    const congratulationsPage = onboardingPages.find((p) => p.section === 'congratulations')
    const isOnboarded = business.status === 'onboarded'

    currentPage = (isOnboarded && congratulationsPage) || lastEdited || onboardingPages[0]
  }

  useEffect(() => {
    if (location.pathname !== currentPage.path && navigation.state === 'idle' && location.pathname === '/onboarding') {
      navigate(currentPage.path)
    }
  }, [])

  const navigateToPrevious = async () => {
    const idx = findIndex(onboardingPages, currentPage)
    const previousPage = idx - 1
    if (previousPage >= 0) {
      navigate(onboardingPages[previousPage].path)
    }
  }

  const navigateToNext = async () => {
    const idx = findIndex(onboardingPages, currentPage)
    const nextPage = idx + 1
    if (nextPage < onboardingPages.length) {
      navigate(onboardingPages[nextPage].path)
    }
  }

  const currentOnboarding = useRef(onboarding)

  useEffect(() => {
    if (isLoading) {
      if (!isEqual(currentOnboarding.current, onboarding)) {
        currentOnboarding.current = onboarding

        editOnboarding(
          {
            variables: {
              id: business.id,
              onboarding
            }
          })
          .then(() => {
            return navigateToNext()
          })
          .catch((err) => {
            console.error(err)
          })
          .finally(() => {
            setLoading(false)
          })
      }
      else {
        navigateToNext()
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }, [onboarding, editOnboarding])

  const isDesktop = useIsDesktopSize()
  const parentDiv = !isDesktop
    ? {
      flexDirection: 'column'
    }
    : {}
  const styles = isDesktop
    ? {
      width: '55vw',
      height: '100vh',
      paddingBottom: '2rem',
      overflowY: 'scroll'
    }
    : {
      order: 1
    }
  const titleStyle = isDesktop
    ? {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '150%',
      width: '90%'
    }
    : {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '150%',
      width: '100%'
    }

  const saveAndLogout = async () => {
    const saved = await editOnboarding({
      variables: {
        id: business.id,
        onboarding
      }
    })
    if (saved) {
      logout({
        returnTo: window.location.origin
      })
    }
  }

  return (
    <PageOnboarding>
      <div
        className='d-flex'
        style={parentDiv}
      >
        <div style={styles}>
          <PageOnboarding.PageHeader>
            <OnboardingHeader
              progress={currentPage.progress}
              page={currentPage.page}
              totalPages={onboardingPages.length}
              goBack={navigateToPrevious}
              logout={saveAndLogout}
            />
            <p
              className='my-3'
              style={titleStyle}
            >
              { currentPage.title }
            </p>
          </PageOnboarding.PageHeader>
          <Card>
            <Card.Body>
              <Outlet context={{
                onboardingReducer: [onboarding, dispatch],
                navigateToPrevious,
                loadingState: [isLoading, setLoading]
              }}
              />
            </Card.Body>
          </Card>
        </div>
        <OnboardingNav />
      </div>
    </PageOnboarding>
  )
}

export default Onboarding
