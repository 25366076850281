import { useMemo } from 'react'
import { ScrollRestoration, useLocation } from 'react-router-dom'

import { useUIStories } from '#app/hooks/useUIStories'
import { useStoreUser } from '#app/store/user'
import { NavigationBottom } from '#app/v2/components/NavigationBottom'

import '#app/v2/global.css'

interface Props {
  children?: React.ReactNode
  fullScrenLoading?: boolean
}

const exclusiveRoutesWithBottomNav = [
  '/v2/home',
  '/v2/messages',
  '/v2/tasks',
  '/v2/business',
  '/v2/activity-center',
  '/v2/search'
]

const exclusiveRoutesWithBottomNavStory = [
  '/v2-ui/home',
  '/v2-ui/messages',
  '/v2-ui/tasks',
  '/v2-ui/business',
]

export const MobileContainer = ({ children, fullScrenLoading }: Props) => {
  const { pathname } = useLocation()
  const { isStoryMode } = useUIStories()

  const { unreadMessages } = useStoreUser()

  const routes = useMemo(() => {
    return isStoryMode ? exclusiveRoutesWithBottomNavStory : exclusiveRoutesWithBottomNav
  }, [isStoryMode])

  return (
    <div className='flex min-h-screen flex-col bg-[#F9F9F9]'>
      <div className='flex max-h-screen flex-grow flex-col'>
        <ScrollRestoration />
        {children}
      </div>
      {!fullScrenLoading
      && (
        <>
          {routes.includes(pathname)
          && (
            <NavigationBottom
              isIOS={false}
              pendingMessages={unreadMessages}
              routes={{
                home: '/v2/home',
                messages: '/v2/messages',
                tasks: '/v2/tasks',
                business: '/v2/business',
              }}
            />
          )}
        </>
      )}
    </div>
  )
}
