/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { CSSProperties, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsCalendarCheckFill, BsFillBookFill, BsFillBriefcaseFill, BsFillCalendarEventFill, BsFillChatLeftDotsFill, BsFillExclamationCircleFill, BsGoogle, BsPencil, BsSoundwave, BsSticky } from 'react-icons/bs'
import { useOutletContext, useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { Box, Grid, Stack, Tab, Tabs, Typography } from '@mui/material'
import { capitalize, pick } from 'lodash'
import { useSnackbar } from 'notistack'

import { AudioPlayer } from '#app/components/AudioPlayer/AudioPlayer'
import { Btn } from '#app/components/Buttons'
import Card from '#app/components/Card/Card'
import { Dialog } from '#app/components/Dialog'
import NotesSideBar from '#app/components/NotesSideBar/NotesSideBar'
import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import { Timeline } from '#app/components/Timeline/Timeline'
import ProfileContext from '#app/contexts/ProfileContext'
import CustomerCard from '#app/feature/Customers/CustomerCard'
import { JobsTable } from '#app/feature/JobsTable/JobsTable'
import CreateCustomer from '#app/forms/CustomersManagement/CreateCustomer'
import { useCustomerEventsMap } from '#app/hooks/useCustomers'
import { useDayJs } from '#app/hooks/useDayJs'
import { useEntityUser } from '#app/hooks/useEntityUser'
import useMutationWithNotification from '#app/hooks/useMutationWithNotification'
import { EntityPage } from '#app/layouts/EntityView'
import { THEME } from '#app/layouts/theme'
import { useRequestCustomerReviewMutation } from '#app/operations/customers/customer.mutations.generated'
import { GetCustomersDocument } from '#app/operations/customers/customers.queries.generated'
import { Generic } from '#app/types'
import { CustomerHistory, CustomerInput } from '#app/types/customer'
import { MUTATION, QUERY, QUERY_GET_CUSTOMERS } from '#app/utils/graphqlQueries'
import { useIsDesktopSize } from '#app/utils/mediaQueries'
import { getOnlyDefined } from '#app/utils/vanilla/helpers'

interface CustomerHistoryTimelineProps {
  customerHistory?: CustomerHistory[]
}

const CustomerHistoryTimeline = ({ customerHistory }: CustomerHistoryTimelineProps) => {
  const { formatDateInFormat } = useDayJs()
  const { t } = useTranslation()
  const customerEventsMap = useCustomerEventsMap()
  const isDesktop = useIsDesktopSize()

  const getIdentifier = (history: CustomerHistory) => {
    switch (history.status) {
      case 'sms-sent':
      case 'sms-received':
        return <BsFillChatLeftDotsFill />
      case 'call-recording-available':
        return <BsSoundwave />
      case 'appointment-booked':
        return <BsCalendarCheckFill />
      case 'requested-review':
        return <BsGoogle />
      default:
        return <BsFillExclamationCircleFill />
    }
  }

  const getContent = (history: CustomerHistory) => {
    const style: CSSProperties = {
      position: 'absolute',
      top: isDesktop ? 50 : 70,
      width: '100%',
      maxWidth: 250,
      backgroundColor: THEME.COLORS.custom.audio,
      borderRadius: '15px'
    }

    switch (history.status) {
      case 'call-recording-available':
        return (
          <div style={{
            position: 'relative',
            height: 180
          }}
          >
            <Typography
              variant='subtitle1'
              style={{
                fontWeight: 'bolder'
              }}
            >
              {t(`customers.customerEvents.${history.status}`)}
            </Typography>
            <AudioPlayer
              src={history.metadata.recording_link.replace(/mp3/g, 'wav')}
              style={style}
            />
          </div>
        )
      case 'sms-sent':
      case 'sms-received':
        return (
          <>
            <Typography
              variant='subtitle1'
              style={{
                fontWeight: 'bolder'
              }}
            >
              {t(`customers.customerEvents.${history.status}`)}
            </Typography>
            <Typography
              variant='body2'
              style={{
                overflowWrap: 'anywhere'
              }}
            >
              {history.metadata.content}
            </Typography>
          </>
        )
      case 'appointment-booked':
        return (
          <div>
            <Typography
              variant='subtitle1'
              style={{
                fontWeight: 'bolder'
              }}
            >
              {t(`customers.customerEvents.${history.status}`)}
            </Typography>
            <Typography
              variant='body2'
              style={{
                overflowWrap: 'anywhere'
              }}
            >
              {history.metadata.notes}
            </Typography>
          </div>
        )
      case 'requested-review':
        return (
          <div>
            <Typography
              variant='subtitle1'
              style={{
                fontWeight: 'bolder'
              }}
            >
              {t('customers.customerEvents.requested-review', 'Requested review')}
            </Typography>
            {history?.metadata?.gmb_url
            && (
              <a
                href={history.metadata.gmb_url}
                target='_blank'
                rel='noreferrer'
              >
                <Typography
                  variant='body2'
                  style={{
                    overflowWrap: 'anywhere'
                  }}
                >
                  {t('customers.customerEvents.requested-review-link', 'Review link')}
                </Typography>
              </a>
            )}
          </div>
        )
      default: {
        const status = String(history.status)
        const customerEvent = customerEventsMap[status]
        const label = customerEvent ?? customerEventsMap.other
        return (
          <div>
            <Typography
              variant='subtitle1'
              style={{
                fontWeight: 'bolder'
              }}
            >
              {label}
            </Typography>
            <Typography
              variant='body2'
              style={{
                overflowWrap: 'anywhere'
              }}
            >
              {history.metadata.notes}
            </Typography>
          </div>
        )
      }
    }
  }

  const mapped = customerHistory?.map((history) => {
    return {
      content: (
        <div>
          {getContent(history)}
        </div>
      ),
      itemIdentifier: getIdentifier(history),
      oppositeContent: (
        <Typography variant='caption'>
          {formatDateInFormat(history?.created_at, 'DD/MM/YYYY HH:MM:ss')}
        </Typography>
      )
    }
  })

  return (
    <Timeline
      leftAligned={true}
      items={mapped}
    />
  )
}

interface Props {
  customer?: CustomerInput
}

export const CustomerContent = ({ currentCustomer, userId }: { currentCustomer: CustomerInput, userId?: Generic.UserID }) => {
  const fullName = `${currentCustomer?.first_name ?? ''} ${currentCustomer?.last_name ?? ''}`
  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState('deals')
  const [sidebarCustomerForm, setSidebarCustomerForm] = useState(false)
  const [sideBarNotesForm, setSideBarNotesForm] = useState(false)
  const [selectedJobId, setSelectedJobId] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const { userId: currentUserId } = useEntityUser()
  const [requestCustomerReview, { loading: loadingRequestCustomerReview }] = useRequestCustomerReviewMutation()
  const [confirmRequestReview, setConfirmRequestReview] = useState(false)
  const reviewRequestedBefore = useMemo(() => {
    return currentCustomer?.customer_histories
      ? currentCustomer?.customer_histories.filter((history) => history?.status === 'requested-review').length > 0
      : false
  }, [currentCustomer])

  const requestReview = () => {
    if (currentCustomer?.id) {
      requestCustomerReview({
        variables: {
          customer_id: currentCustomer.id,
          user_id: userId ?? currentUserId
        }
      }).then(() => {
        enqueueSnackbar(t('general.requestSentSuccessfully'), {
          variant: 'success'
        })
      })
    }
  }

  const customerTabsNavigation = [
    {
      value: 'deals',
      label: t('jobs.jobs'),
      icon: <BsFillBriefcaseFill fontSize={THEME.ICONS.size.sm} />
    },
    {
      value: 'interactions',
      label: 'Interactions',
      icon: <BsFillBookFill fontSize={THEME.ICONS.size.sm} />
    },
    {
      value: 'appointments',
      label: t('jobs.appointments'),
      icon: <BsFillCalendarEventFill fontSize={THEME.ICONS.size.sm} />
    }
  ]

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  const showNotesFromJob = (customerId: Generic.UUID) => {
    if (sideBarNotesForm) {
      setSelectedJobId('')
    }
    else {
      setSelectedJobId(customerId)
    }
    setSideBarNotesForm(!sideBarNotesForm)
  }

  const { getProfile: { user } } = useContext(ProfileContext)

  const [updateCustomer] = useMutationWithNotification(MUTATION.customers.updateCustomer)

  const onSubmit = async (data: CustomerInput) => {
    const payload = getOnlyDefined({
      id: currentCustomer.id,
      user_id: user?.id,
      business_id: user?.businesses?.[0]?.id,
      ...data
    })

    try {
      await updateCustomer({
        variables: payload,
        update(cache, { data: updateResult }) {
          cache.updateQuery({ // options object
            query: GetCustomersDocument,
            variables: {
              query: '%',
              limit: 50
            }
          }, (data) => { // update function
            return {
              customers: data
                .customers
                .map((cus: CustomerInput) => {
                  if (cus.id === payload.id) {
                    return {
                      ...cus,
                      ...pick(updateResult?.updateCustomer?.customer, ['first_name', 'last_name', 'email', 'phone', 'address', 'lead'])
                    }
                  }
                  return cus
                })
            }
          })
        }
      })
      setSidebarCustomerForm(false)
    }
    catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <RightSideBar
        isOpen={sidebarCustomerForm}
        closeHandler={() => setSidebarCustomerForm(false)}
      >
        <CreateCustomer
          editMode
          information={currentCustomer}
          onSubmit={onSubmit}
        />
      </RightSideBar>
      <NotesSideBar
        isOpen={sideBarNotesForm}
        closeHandler={() => setSideBarNotesForm(!sideBarNotesForm)}
        jobId={selectedJobId}
        entityType='customer_notes'
        userId={currentCustomer?.user_id ?? ''}
      />
      <Grid
        container
        rowSpacing={3}
      >
        <Grid
          item
          width='100%'
        >
          <Card>
            <Card.Body className='v1-card-bottom-padding'>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  lg={6}
                >
                  <Typography variant='h6'>
                    {`${(fullName.trim() ? fullName : capitalize(t('generic.unknown')))}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                >
                  <Dialog.Confirm
                    open={confirmRequestReview}
                    setOpen={setConfirmRequestReview}
                    texts={{
                      title: t('customers.review.confirm.title', 'Are you sure you want to request a review?'),
                      description: t('customers.review.confirm.subtitle', 'You have already requested a review in the past from this customer.'),
                      confirm: t('customers.review.confirm.btn', 'Yes, request'),
                      cancel: t('customers.review.confirm.cancel', 'Cancel')
                    }}
                    onConfirm={() => requestReview()}
                  />
                  <Stack
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='center'
                    spacing={2}
                  >
                    <Btn.ActionButton
                      forceMobile
                      icon={<BsSticky />}
                      disabled={loadingRequestCustomerReview}
                      onClick={() => showNotesFromJob(currentCustomer.id ?? '')}
                    />
                    <Btn.ActionButton
                      forceMobile
                      icon={<BsPencil />}
                      disabled={loadingRequestCustomerReview}
                      onClick={() => setSidebarCustomerForm(true)}
                    />
                    <Btn.ActionButton
                      forceDesktop
                      disabled={loadingRequestCustomerReview}
                      onClick={() => reviewRequestedBefore ? setConfirmRequestReview(true) : requestReview()}
                    >
                      {t('customers.request-review', 'Request review')}
                    </Btn.ActionButton>
                  </Stack>
                </Grid>
              </Stack>
              <CustomerCard customer={currentCustomer} />
            </Card.Body>
          </Card>
        </Grid>
        <Grid
          item
          width='100%'
        >
          <Card>
            <Card.Body className='v1-card-bottom-padding'>
              <Box sx={{
                width: '100%'
              }}
              >
                <Tabs
                  value={tabValue}
                  variant='scrollable'
                  scrollButtons='auto'
                  onChange={handleChange}
                >
                  {customerTabsNavigation.map((e, i) => {
                    return (
                      <Tab
                        key={i}
                        icon={e.icon}
                        iconPosition='start'
                        value={e.value}
                        label={e.label}
                      />
                    )
                  })}
                </Tabs>
              </Box>

              {// @ts-ignore
                currentCustomer.jobs?.length && tabValue === 'deals' && currentCustomer?.jobs?.some((job) => ['job-open', 'job-closed', 'job-completed'].includes(job.status))
                  ? (
                    <CustomerJobs
                      currentCustomer={currentCustomer}
                      statusValues={['job-open', 'job-closed', 'job-completed']}
                      tab={customerTabsNavigation[0].label}
                    />
                  )
                  : tabValue === 'deals'
                    ? (
                      <Grid
                        container
                        sx={{
                          marginTop: '20px'
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <Typography align='center'>{t('jobs.tabs.noJobs')}</Typography>
                        </Grid>
                      </Grid>
                    )
                    : null
              }

              {
                currentCustomer
                  ?.customer_histories
                  ?.length && tabValue === 'interactions'
                  ? <CustomerInteractions customerHistory={currentCustomer.customer_histories} />
                  : tabValue === 'interactions'
                    ? (
                      <Grid
                        container
                        xs={12}
                        sx={{
                          marginTop: '20px'
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <Typography align='center'>{t('jobs.tabs.noInteractions')}</Typography>
                        </Grid>
                      </Grid>
                    )
                    : null
              }

              { // @ts-ignore
                currentCustomer.jobs?.length && tabValue === 'appointments' && currentCustomer?.jobs?.some((job) => ['job-booked'].includes(job.status))
                  ? (
                    <CustomerJobs
                      currentCustomer={currentCustomer}
                      statusValues={['job-booked']}
                      headers={[{
                        text: t('generic.appointment')
                      }]}
                      tab={customerTabsNavigation[2].label}
                    />
                  )
                  : tabValue === 'appointments'
                    ? (
                      <Grid
                        container
                        xs={12}
                        sx={{
                          marginTop: '20px'
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <Typography align='center'>{t('jobs.tabs.noAppointments')}</Typography>
                        </Grid>
                      </Grid>
                    )
                    : null
              }
            </Card.Body>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default function CustomerDetails(props: Props) {
  const { t } = useTranslation()
  // let customer: CustomerInput | undefined
  const [customer, setCustomer] = useState<CustomerInput | undefined>()
  const { customerId } = useParams()

  const ctx = useOutletContext<{ currentCustomer?: CustomerInput }>()

  const [query, { data, loading }] = useLazyQuery<QUERY_GET_CUSTOMERS>(QUERY.customers.getCustomer, {
    variables: {
      id: customerId
    }
  })

  useEffect(() => {
    if (ctx) {
      setCustomer(ctx.currentCustomer)
    }
    else if (props.customer) {
      setCustomer(props.customer)
    }
    else {
      void query()
    }
  }, [])

  useEffect(() => {
    if (data && !loading) {
      setCustomer(data.customers[0])
    }
  }, [data, loading])

  if (!customer) return <></>

  return (
    <EntityPage
      hasReturn
      pageTitle={t('customers.customerDetails')}
      layout='free'
      isLoading={loading}
      mainContent={<CustomerContent currentCustomer={customer} />}
    />
  )
}

// Navigations

export const CustomerJobs = ({ currentCustomer, statusValues, headers, tab }: { currentCustomer: CustomerInput, statusValues: string[], headers?: any, tab?: string }) => {
  const { isAdmin } = useEntityUser()
  let navigateTo
  if (isAdmin) {
    // @ts-ignore
    navigateTo = (job) => `/businesses/${currentCustomer?.business_id}/jobs/${job.id}`
  }
  else {
    // @ts-ignore
    navigateTo = (job) => `/customers/${currentCustomer?.id}/jobs/${job.id}`
  }
  return (
    <>
      <Grid
        container
        spacing={0}
        className='mt-3'
      >
        <Grid
          item
          xs={12}
        >
          <JobsTable
            headers={headers}
            statusValues={statusValues}
            jobs={currentCustomer.jobs}
            hasCustomerName={false}
            navigateTo={navigateTo}
            tab={tab}
          />
        </Grid>
      </Grid>
    </>
  )
}

export const CustomerInteractions = ({ customerHistory }: CustomerHistoryTimelineProps) => {
  return (
    <Grid
      container
      spacing={0}
      className='mt-3'
    >
      <Grid
        item
        xs={12}
      >
        <CustomerHistoryTimeline customerHistory={customerHistory} />
      </Grid>
    </Grid>
  )
}
