import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cva, VariantProps } from 'class-variance-authority'
import { cn } from 'src/v2/libs/utils'

import { Check } from '#app/v2/assets/Icons'

const checkboxVariants = cva(
  'peer h-6 w-6 shrink-0 rounded-md border border-[#304985] ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50  data-[state=checked]:text-gray-50 dark:border-gray-50 dark:ring-offset-gray-950 dark:focus-visible:ring-gray-300 dark:data-[state=checked]:bg-gray-50 dark:data-[state=checked]:text-gray-900',
  {
    variants: {
      variant: {
        default: 'text-white hover:bg-[#304985]/10 data-[state=checked]:bg-[#304985]',
        outline:
          'bg-white border-2 hover:bg-[#304985]/5 hover:text-[#304985]/95 data-[state=checked]:text-[#304985]',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface CheckboxProps
  extends
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
  VariantProps<typeof checkboxVariants> {
}

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(({ className, variant, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(checkboxVariants({
      variant,
      className
    }))}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn('flex items-center justify-center text-current')}
    >
      <Check className='size-2/3 stroke-2' />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
