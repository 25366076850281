import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import LogoOwners from '#app/assets/svg/logo-owners-light.svg'
import Images from '#app/v2/assets/Images'
import { Button } from '#app/v2/components/ui/button'
import { NotFoundProps } from '#app/v2/pages/NotFound/types'

export const NotFound = ({ homeLink }: NotFoundProps) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  if (homeLink) {
    return (
      <div className='flex min-h-screen flex-col items-center justify-center bg-[#F7F9FB] py-4 text-center'>
        <img
          src={LogoOwners}
          alt='Owners logo'
          className='mb-6 h-5 w-full'
        />
        <p className='mb-2 text-2xl/6 font-extrabold text-[#263238]'>Oops!</p>
        <p className='mb-8 text-[100px]  font-bold leading-[0.75] text-[#304985] [text-shadow:8px_0px_#22335D]'>404</p>
        <p className='mb-4 text-xl/6 font-semibold text-[#304985]'>{t('v2.notFoundPage.title', 'Page not found!')}</p>
        <p className='mb-5 max-w-sm text-base/5 font-medium text-[#454545]'>
          {t('v2.notFoundPage.description1', 'We are sorry, the page')}
          {!pathname.includes('/not-found')
            ? (
              <>
                <br />
                <span className='  break-words text-[#0083B6] underline'>{window.location.href}</span>
                <br />
              </>
            )
            : <br />}
          {t('v2.notFoundPage.description2', 'could not be found.')}
        </p>
        <p className='font-medium text-[#454545]'>{t('v2.notFoundPage.indication', 'Please go back to the home page!')}</p>
        <img
          src={Images.NotFoundPersonLooking}
          alt='Svg of a person with a magnifying glass'
          className='mx-auto my-4 block w-24'
        />
        <Button
          asChild
          size='lg'
          className=' w-52 rounded-lg'
        >
          <Link to={homeLink}>
            {t('v2.notFoundPage.action', 'Go back home')}
          </Link>
        </Button>
      </div>
    )
  }
  return (<>NotFound</>)
}
