/* eslint-disable @typescript-eslint/naming-convention */
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import FormSelect from '#app/components/FormSelect/FormSelect'
import RichTextArea from '#app/components/RichTextArea/RichTextArea'
import { InputWithCustomers } from '#app/feature/InputWithCustomers/InputWithCustomers'
import { useJobStatusMap } from '#app/hooks/useJobs'
import { JobInput } from '#app/types/job'
import { MUTATION, QUERY } from '#app/utils/graphqlQueries'
import { getOnlyDefined } from '#app/utils/vanilla/helpers'

import Button from '../../components/Buttons/Button'
import useMutationWithNotification from '../../hooks/useMutationWithNotification'// '#app/hooks/useMutationWithNotification'

interface Props {
  submitCallback: (result: any, error?: any) => void
  currentJob: JobInput
}

export const EditJob = ({ submitCallback, currentJob }: Props) => {
  const { t } = useTranslation()

  const [editJob] = useMutationWithNotification(MUTATION.jobs.editJob, {
    update(cache, { data }: any) {
      const { editJob: { job: editedJob } } = data

      // @ts-ignore
      const cachedData: { jobs: any[] } = cache.readQuery({
        query: QUERY.jobs.getJobs,
        variables: {
          where: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            id: {
              _eq: editedJob.id
            },
            user: {
              status: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                _neq: 'deleted'
              }
            }
          },
          limit: 1
        }
      })

      // @ts-ignore
      if (!cachedData || !cachedData.jobs) return

      const { jobs: [existingJob] } = cachedData as { jobs: any[] }

      if (!existingJob) return

      const updatedJob = {
        ...existingJob,
        ...editedJob
      }

      cache.writeQuery({
        query: QUERY.jobs.getJobs,
        variables: {
          where: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            id: {
              _eq: editedJob.id
            },
            user: {
              status: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                _neq: 'deleted'
              }
            }
          },
          limit: 1
        },
        data: {
          jobs: [updatedJob]
        }
      })
    }
  })

  const initialValues = {
    description: currentJob?.description,
    status: currentJob?.status,
    user_id: currentJob?.user_id,
    business_id: currentJob?.business_id,
    customer: currentJob?.customer
  }

  const onSubmit = async (data: any, { resetForm }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const payload = getOnlyDefined({
      ...currentJob,
      customer_id: data?.customer?.id,
      description: data?.description,
      metadata: data?.metadata,
      status: data?.status
    })

    try {
      const result: any = await editJob({
        variables: payload
      })

      const { data: { editJob: { job } } } = result

      submitCallback(job)

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      resetForm()
    }
    catch (err: any) {
      if (err?.toString) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        console.error(err?.toString())
      }
    }
  }

  const schema = Yup
    .object()
    .shape({
      description: Yup
        .string()
        .required(t('validation.required')),
      status: Yup
        .string()
        .required(t('validation.required')),
      customer: Yup
        .object()
        .nullable()
        .required(t('validation.required')),
      metadata: Yup
        .object()
        .nullable()
    })

  const jobMap = useJobStatusMap()

  const jobOptions = Object.keys(jobMap)
    .map((key) => {
      return {
        value: key,
        text: jobMap[key]
      }
    })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Typography variant='h4'>Edit Job</Typography>
          <div className='mt-3'>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <InputWithCustomers
                  label='Customer'
                  name='customer'
                  userId={currentJob?.user_id}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <RichTextArea
                  name='description'
                  label='Description'
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <FormSelect
                  label='Status'
                  name='status'
                  options={jobOptions}
                  popoverText={undefined}
                  placeholder={undefined}
                />
              </Grid>
              {
                values.status !== initialValues.status && (
                  <Grid
                    item
                    xs={12}
                  >
                    <RichTextArea
                      name='metadata.reason_for_change'
                      label='Reason for change'
                    />
                  </Grid>
                )
              }
            </Grid>
          </div>
          <div className='div-modal-input mt-4'>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type='submit'
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
