import { ChangeEvent, Dispatch, SetStateAction, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { BsPaperclip, BsX } from 'react-icons/bs'
import { Box, Card, CardActions, CardContent, Grid, SxProps, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import ProfileContext from '#app/contexts/ProfileContext'
import { COLORS } from '#app/layouts/theme'
import { Comment } from '#app/types'

import { Btn } from '../../../components/Buttons'
import { Chip } from '../../../components/Chip'
import useUploadResource from '../../../hooks/useUploadResource'

interface Props {
  sx?: SxProps
  attachments: Comment.Attachment[]
  entityName?: string
  setAttachments?: Dispatch<SetStateAction<Comment.Attachment[]>>
}

// ONLY FOR COMMENTS AT THE MOMENT
export function Attachments({ sx, attachments, setAttachments, entityName = 'comments' }: Props) {
  const { uploadResource } = useUploadResource()
  const data = useContext(ProfileContext)
  const user = data.getProfile.user
  const { t } = useTranslation()

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files)
      const filesList: Comment.Attachment[] = await Promise.all(files.map(async (file) => {
        const path: string = await uploadResource(user?.id, entityName, file)
        return {
          url: path,
          filename: file.name,
          type: file.type
        }
      }))
      setAttachments?.(filesList)
    }
  }

  const urlValid = useCallback((file: Comment.Attachment) => {
    if (file?.url === 'Error') return false
    return true
  }, [])

  const download = useCallback((attachment: Comment.Attachment) => {
    const linkAnchor = document.createElement('a')
    linkAnchor.href = attachment.url
    linkAnchor.download = attachment.filename
    document.body.appendChild(linkAnchor)
    linkAnchor.click()
    document.body.removeChild(linkAnchor)
  }, [])

  return (
    <Box sx={sx}>
      <Grid
        container
        mb={4}
        spacing={2}
        alignItems='stretch'
      >
        {attachments && attachments.length > 0 && attachments.map((attachment, i) => (
          <Grid
            item
            xs={12}
            md={4}
            lg={12}
          >
            <Card>
              <CardContent>
                <Chip.Attachment />
                <Box mt={2}>
                  {urlValid(attachment)
                    ? (
                      <a
                        target='_blank'
                        href={attachment ? attachment.url : '#'}
                        rel='noreferrer'
                      >
                        <Typography>{!attachment ? 'Uploading file...' : attachment.filename}</Typography>
                      </a>
                    )
                    : (
                      <>
                        <Typography color={COLORS.custom.error}>
                          {' '}
                          Error | Failed to upload file -
                          {attachment.filename}
                        </Typography>
                      </>
                    )}
                </Box>
              </CardContent>
              <CardActions>
                <Btn.Button
                  disabled={!attachment || !urlValid(attachment)}
                  onClick={() => download(attachment)}
                >
                  {t('user_tasks.download')}
                </Btn.Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {setAttachments
      && (
        <>
          {(!attachments || attachments.length === 0)
            ? (
              <IconButton
                color='primary'
                aria-label='upload picture'
                component='label'
              >
                <input
                  hidden
                  multiple
                  type='file'
                  onChange={handleFileChange}
                />
                <BsPaperclip />
              </IconButton>
            )
            : (
              <Btn.ActionButton
                forceMobile
                icon={<BsX />}
                onClick={() => {
                  setAttachments([])
                }}
              />
            )}
        </>
      )}
    </Box>
  )
}
