import { MouseEvent, useState } from 'react'
import { BsFillPencilFill, BsInfoCircleFill, BsTrashFill } from 'react-icons/bs'
import { ListItemText, MenuList } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { THEME } from '#app/layouts/theme'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

import { IconButton } from './IconButtonCustom'

interface Props {
  forceMobile?: boolean
  onView?: () => void
  onDelete?: () => void
  onEdit?: () => void
}

export function MenuButton({ forceMobile, onEdit, onDelete, onView }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isDesktop = useIsDesktopSize()
  const open = Boolean(anchorEl)

  return (
    <>
      {isDesktop && !forceMobile
      && (
        <>
          {onView && <IconButton.Info onClick={onView} />}
          {onEdit && <IconButton.Edit onClick={onEdit} />}
          {onDelete && (
            <IconButton.Delete
              withoutWarning
              onClick={onDelete}
            />
          )}
        </>
      )}
      {(!isDesktop || forceMobile)
      && (
        <>
          <IconButton.More onClick={(event: MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget)
          }}
          />
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            sx={{
              pt: 0,
              pb: 0
            }}
            onClose={() =>
              setAnchorEl(null)}
          >
            <MenuList disablePadding>
              {onView
              && (
                <MenuItem onClick={onView}>
                  <ListItemIcon>
                    <BsInfoCircleFill fontSize={THEME.ICONS.size.sm} />
                  </ListItemIcon>
                  <ListItemText>View</ListItemText>
                </MenuItem>
              )}
              {onEdit
              && (
                <MenuItem onClick={onEdit}>
                  <ListItemIcon>
                    <BsFillPencilFill fontSize={THEME.ICONS.size.sm} />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>
              )}
              {
                onDelete
                && (
                  <MenuItem onClick={onDelete}>
                    <ListItemIcon>
                      <BsTrashFill fontSize={THEME.ICONS.size.sm} />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                )
              }
            </MenuList>
          </Menu>
        </>
      )}
    </>
  )
}
