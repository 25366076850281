import { cn } from '#app/v2/libs/utils'

export const SectionTitle = ({ text }: { text: string }) => {
  return (
    <h2 className={cn('text-lg/6 font-semibold text-[#304985]', {
      hidden: text === ''
    })}
    >
      {text}
    </h2>
  )
}
