interface CardProps {
  children?: JSX.Element | React.ReactNode
  className?: string
  withScrollY?: boolean
  style?: any
  props?: any
}

const Card = ({ children, className, withScrollY, ...props }: CardProps) => {
  return (
    <div
      className={`card ${className ?? ''} ${withScrollY ? 'v1-card-with-scroll' : ''}`}
      {...props}
    >
      {children}
    </div>
  )
}

Card.Header = ({ children, className, ...props }: CardProps) => {
  return (
    <div
      className={`card-body ${className ?? ''}`}
      {...props}
    >
      { children }
    </div>
  )
}

Card.Body = ({ children, className, ...props }: CardProps) => {
  return (
    <div
      className={`card-body ${className ?? ''}`}
      {...props}
    >
      { children }
    </div>
  )
}

export default Card
