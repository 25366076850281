import { Box } from '@mui/material'

import RightSideBar from '#app/components/RightSideBar/RightSideBar'
import { CommentsThread } from '#app/feature/Comments'
import { Generic } from '#app/types'

interface Props {
  size?: string
  closeHandler: () => void
  isOpen?: boolean
  jobId: Generic.UUID
  entityType: string // user_notes
  userId: Generic.UUID
}

const NotesSideBar = ({ closeHandler, size, isOpen, jobId, userId, entityType }: Props) => {
  return (
    <RightSideBar
      isOpen={isOpen}
      closeHandler={closeHandler}
      size={size}
    >
      <Box>
        <CommentsThread
          receiverUserId={userId}
          entityId={jobId}
          entityType={entityType}
          scrollToLastComment={true}
        />
      </Box>
    </RightSideBar>
  )
}

export default NotesSideBar
