import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useReadActivities from '#app/hooks/useReadActivities'
import { useStoreUser } from '#app/store/user'
import { CenteredNavTitle } from '#app/v2/components/CenteredNavTitle'
import { HeaderSharedNavigation } from '#app/v2/components/HeaderSharedNavigation'
import { ActivityCenter } from '#app/v2/pages/ActivityCenter/page'
import { Filters, filters } from '#app/v2/pages/ActivityCenter/types'
import { useActivityCenter } from '#app/v2-core/hooks/useActivityCenter'
import { useAppContextV2 } from '#app/v2-core/layouts/useAppContextV2'

export function CoreActivityCenter() {
  const { t } = useTranslation()

  const [filter, setFilter] = useState<Filters[]>(['all'])
  const LIMIT = 20

  const onFilterChange = (filter: string[]) => {
    setFilter([...filter] as Filters[])
  }

  const batchSize = 20

  const { activities: notifications, activitiesLoading, fetchMore, markActivitiesAsRead } = useActivityCenter(filter.includes('all') ? filters : filter, batchSize)
  const { activitiesInViewport, readActivities } = useReadActivities(notifications)
  const { V2_SET_LOADING } = useAppContextV2()
  const { setUnreadNotifications, unreadMessages } = useStoreUser()

  useEffect(() => {
    if (!activitiesLoading) {
      V2_SET_LOADING(activitiesLoading)
    }
  }, [V2_SET_LOADING, activitiesLoading])

  useEffect(() => {
    const notificationsToUpdate = activitiesInViewport.filter((activity) => !readActivities.current?.has(activity))
    const notificationsById = notifications.reduce((acc, notification) => {
      return {
        ...acc,
        [notification.id]: notification
      }
    }, {} as Record<string, typeof notifications[0]>)

    if (notificationsToUpdate.length > 0) {
      markActivitiesAsRead({
        variables: {
          activityIds: activitiesInViewport,
        },
      }).then(() => {
        readActivities.current = new Set([...Array.from(readActivities.current ?? []), ...activitiesInViewport])
        setUnreadNotifications(activitiesInViewport.map((activity) => ({
          ...notificationsById[activity],
          read: true
        })))
      }).catch(() => {
        console.error('Error marking activities as read')
      })
    }
  }, [activitiesInViewport, markActivitiesAsRead, readActivities, setUnreadNotifications, notifications])

  return (
    <>
      <HeaderSharedNavigation
        backUrl='/v2/home'
        main={<CenteredNavTitle title={t('navigations.activityCenter', 'Activity Center')} />}
      />
      <ActivityCenter
        currentFilter={filter}
        filters={filters}
        notifications={notifications}
        fetchMore={fetchMore}
        batchSize={LIMIT}
        unreadMessages={unreadMessages}
        onFilterChange={onFilterChange}
      />
    </>
  )
}
