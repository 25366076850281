import { useState } from 'react'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'

import { Btn } from '#app/components/Buttons'

import { Option, Question } from '.'

interface Props {
  currentQuestion: Question
  onSubmit: (option: Option, optionString: string) => void
}

export function FreeCheckbox({ currentQuestion, onSubmit }: Props) {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])

  const handleOptionChange = (option: string, optionKey: string) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option))
      setSelectedKeys(selectedKeys.filter((item) => item !== optionKey))
    }
    else {
      setSelectedOptions([...selectedOptions, option])
      setSelectedKeys([...selectedKeys, optionKey])
    }
  }

  const submitWithReferences = () => {
    const keys = selectedKeys.toString()
    const options = selectedOptions.toString()
    onSubmit({
      option_key: keys,
      option_es: options
    }, options)
  }

  return (
    <FormGroup>
      {currentQuestion.options.map((option) => {
        return (
          <FormControlLabel
            key={option.option_es}
            control={(
              <Checkbox
                // eslint-disable-next-line @typescript-eslint/naming-convention
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 28
                  }
                }}
                name={option.option_es}
                checked={selectedOptions.includes(option.option_es)}
                onChange={() => handleOptionChange(option.option_es, option.option_key)}
              />
            )}
            label={option.option_es}
          />
        )
      })}
      <Btn.Button
        sx={{
          mt: 4
        }}
        variant='contained'
        onClick={submitWithReferences}
      >
        Continuar
      </Btn.Button>
    </FormGroup>
  )
}
