import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { PageWrapper } from '#app/v2/components/PageWrapper'
import { ProfileAvatarDetailed } from '#app/v2/components/ProfileAvatarDetailed'
import { Accordion } from '#app/v2/components/ui/accordion'
import { Button } from '#app/v2/components/ui/button'
import { Separator } from '#app/v2/components/ui/separator'
import { Shortcut, ShortcutProps } from '#app/v2/pages/MyProfile/components/Shortcut'
export interface ProfileProps {
  avatar?: string
  firstName?: string
  fullName?: string
  services?: string
  shortcuts?: ShortcutProps[]
  extraShortcuts?: ShortcutProps[]
  logout?: ShortcutProps
  editProfileUrl?: string
  clientId?: number
}

export const MyProfile = (props: ProfileProps) => {
  const { avatar, fullName, services, editProfileUrl, shortcuts, extraShortcuts, logout, clientId } = props
  const { t } = useTranslation()

  return (
    <PageWrapper
      pathname='/v2/profile'
      className=' pb-28'
    >
      <div className='mx-6 mt-4 rounded-xl bg-white px-5 py-7 shadow'>
        <div className='flex items-center justify-between gap-2'>
          <div className=' flex items-center gap-2'>
            <ProfileAvatarDetailed
              onlyAvatar
              profilePictureUrl={avatar}
              fullName={fullName}
              className=' h-16 w-16'
            />
            <div className='space-y-1 font-semibold'>
              <h4 className='text-xl/5 text-[#304985]'>{fullName}</h4>
              <p className='text-sm/4 text-[#454545]'>{services}</p>
              <p className='text-sm/4 text-[#BDBDBD]'>{clientId}</p>
            </div>
          </div>
          <Button
            asChild
            className='whitespace-nowrap rounded-lg px-3 py-1.5'
          >
            <Link to={editProfileUrl ? editProfileUrl : ''}>
              {t('v2.profile.edit', 'Edit Profile')}
            </Link>
          </Button>

        </div>
        <Accordion
          collapsible
          type='single'
          className=' mt-7 space-y-3.5 text-lg/5 text-[#304985]'
        >
          {/* Accordion item with children links */}
          {shortcuts
            ? (shortcuts.map((shortcut) =>
            //  Accordion Trigger (flex grow === block element) -->
            //   inline-flex -->
            //   repeat
              (
                <Shortcut
                  key={shortcut.label}
                  label={shortcut.label}
                  icon={shortcut.icon}
                  url={shortcut.url}
                  childrens={shortcut.childrens}
                  onClick={shortcut.onClick}
                />
              )))
            : null}
          <Separator />
          {extraShortcuts
            ? extraShortcuts.map((shortcut: ShortcutProps) => (
              <Shortcut
                key={shortcut.label}
                label={shortcut.label}
                icon={shortcut.icon}
                url={shortcut.url}
                childrens={shortcut.childrens}
                hasSwitch={shortcut.hasSwitch}
                onClick={shortcut.onClick}
              />
            ),
            )
            : null}
        </Accordion>
      </div>
      {logout?.url && logout?.icon
        ? (
          <div className=' mx-6 mt-4 rounded-xl bg-white px-5 py-2.5 text-[#D81C1C] shadow'>
            <Shortcut
              label={logout.label}
              icon={logout.icon}
              url={logout.url}
            />
          </div>
        )
        : logout?.onClick && logout?.icon
          ? (
            <div className=' mx-6 mt-4 rounded-xl bg-white px-5 py-2.5 text-[#D81C1C] shadow'>
              <Shortcut
                label={logout.label}
                icon={logout.icon}
                onClick={logout.onClick}
              />
            </div>
          )
          : <>Logout</>}
    </PageWrapper>

  )
}
