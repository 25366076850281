/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { capitalize } from 'lodash'

import PropertyDetails from '#app/components/PropertyDetail/PropertyDetail'
import { useLeadsMap } from '#app/hooks/useCustomers'
import { CustomerInput } from '#app/types/customer'

interface Props {
  customer?: CustomerInput
}

export default function CustomerCard({ customer }: Props) {
  const { t } = useTranslation()
  const leadsMap = useLeadsMap()

  if (!customer) return <></>

  return (
    <>
      <Grid
        container
        spacing={3}
        className='mt-1'
      >
        <Grid
          item
          xs={12}
          lg={6}
        >
          <a
            className='text-reset text-decoration-none'
            href={customer?.phone ? `tel:${customer?.phone}` : undefined}
          >
            <PropertyDetails
              icon='phone'
              label={capitalize(t('generic.phone'))}
              value={customer?.phone}
            />
          </a>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
        >
          <a
            className='text-reset text-decoration-none'
            href={customer?.email ? `mailto:${customer?.email}` : undefined}
          >
            <PropertyDetails
              icon='email'
              label={capitalize(t('generic.email'))}
              value={customer?.email}
            />
          </a>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
        >
          <a
            className='text-reset text-decoration-none'
            href={customer?.address ? `https://www.google.com/maps/dir/?api=1&destination=${customer?.address?.description}&travel_mode=driving` : undefined}
            target='_blank'
            rel='noreferrer'
          >
            <PropertyDetails
              icon='address'
              label={capitalize(t('generic.address'))}
              value={customer?.address?.description}
            />
          </a>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
        >
          <PropertyDetails
            icon='source'
            label={capitalize(t('generic.lead'))}
            value={customer?.lead && leadsMap[customer.lead]}
          />
        </Grid>
      </Grid>
    </>
  )
}
