import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type RequestCustomerReviewMutationVariables = Types.Exact<{
  user_id: Types.Scalars['uuid']
  customer_id: Types.Scalars['uuid']
}>

export type RequestCustomerReviewMutation = { __typename?: 'mutation_root', requestCustomerReview?: { __typename?: 'RequestCustomerReviewOutput', status?: boolean | undefined } | undefined }

export type CreateCustomerFromWorkOrderMutationVariables = Types.Exact<{
  work_order_id: Types.Scalars['String']
}>

export type CreateCustomerFromWorkOrderMutation = { __typename?: 'mutation_root', createCustomerFromWorkOrder?: { __typename?: 'createCustomerFromWorkOrderOutput', is_new?: boolean | undefined, customer?: { __typename?: 'Customer', user_id: string, status?: string | undefined, phone?: string | undefined, source?: string | undefined, lead?: string | undefined, last_name?: string | undefined, id?: string | undefined, first_name?: string | undefined, email?: string | undefined, business_id: string, address?: any | undefined } | undefined } | undefined }

export const RequestCustomerReviewDocument = gql`
    mutation RequestCustomerReview($user_id: uuid!, $customer_id: uuid!) {
  requestCustomerReview(input: {user_id: $user_id, customer_id: $customer_id}) {
    status
  }
}
    `
export type RequestCustomerReviewMutationFn = Apollo.MutationFunction<RequestCustomerReviewMutation, RequestCustomerReviewMutationVariables>

/**
 * __useRequestCustomerReviewMutation__
 *
 * To run a mutation, you first call `useRequestCustomerReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCustomerReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCustomerReviewMutation, { data, loading, error }] = useRequestCustomerReviewMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useRequestCustomerReviewMutation(baseOptions?: Apollo.MutationHookOptions<RequestCustomerReviewMutation, RequestCustomerReviewMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<RequestCustomerReviewMutation, RequestCustomerReviewMutationVariables>(RequestCustomerReviewDocument, options)
}
export type RequestCustomerReviewMutationHookResult = ReturnType<typeof useRequestCustomerReviewMutation>
export type RequestCustomerReviewMutationResult = Apollo.MutationResult<RequestCustomerReviewMutation>
export type RequestCustomerReviewMutationOptions = Apollo.BaseMutationOptions<RequestCustomerReviewMutation, RequestCustomerReviewMutationVariables>
export const CreateCustomerFromWorkOrderDocument = gql`
    mutation createCustomerFromWorkOrder($work_order_id: String!) {
  createCustomerFromWorkOrder(input: {work_order_id: $work_order_id}) {
    is_new
    customer {
      user_id
      status
      phone
      source
      lead
      last_name
      id
      first_name
      email
      business_id
      address
    }
  }
}
    `
export type CreateCustomerFromWorkOrderMutationFn = Apollo.MutationFunction<CreateCustomerFromWorkOrderMutation, CreateCustomerFromWorkOrderMutationVariables>

/**
 * __useCreateCustomerFromWorkOrderMutation__
 *
 * To run a mutation, you first call `useCreateCustomerFromWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerFromWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerFromWorkOrderMutation, { data, loading, error }] = useCreateCustomerFromWorkOrderMutation({
 *   variables: {
 *      work_order_id: // value for 'work_order_id'
 *   },
 * });
 */
export function useCreateCustomerFromWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerFromWorkOrderMutation, CreateCustomerFromWorkOrderMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<CreateCustomerFromWorkOrderMutation, CreateCustomerFromWorkOrderMutationVariables>(CreateCustomerFromWorkOrderDocument, options)
}
export type CreateCustomerFromWorkOrderMutationHookResult = ReturnType<typeof useCreateCustomerFromWorkOrderMutation>
export type CreateCustomerFromWorkOrderMutationResult = Apollo.MutationResult<CreateCustomerFromWorkOrderMutation>
export type CreateCustomerFromWorkOrderMutationOptions = Apollo.BaseMutationOptions<CreateCustomerFromWorkOrderMutation, CreateCustomerFromWorkOrderMutationVariables>
