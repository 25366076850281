import { NunjucksDefinition } from './types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertToNestedObject(arr: NunjucksDefinition[]): Record<string, any> {
  const result: Record<string, any> = {}

  arr.forEach((item) => {
    if (item?.keyNunjuck) {
      const keys = item.keyNunjuck.split('.')
      let temp: Record<string, any> = result

      keys.forEach((key, index) => {
        try {
          if (index === keys.length - 1) {
            if (item?.isArray && item?.keyValue) {
              try {
                temp[key] = JSON.parse(item.keyValue)
                if (!Array.isArray(temp[key])) {
                  throw new Error('No es un array')
                }
              }
              catch {
                temp[key] = item.keyValue
              }
            }
            else {
              temp[key] = item.keyValue
            }
            return
          }
          if (!temp[key]) temp[key] = {}
          temp = temp[key]
        }
        catch (err) {
          console.log('Probably the user made the mistake of creating a variable as text that was already an object and this alters the other variables. Check convertToNestedObjec()')
        }
      })
    }
  })

  return result
}
