import { useState } from 'react'
import { BsFillFileEarmarkPdfFill, BsLink45Deg } from 'react-icons/bs'
import Markdown from 'react-markdown'
import { Box, Card, Skeleton, styled, SxProps } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import parse from 'html-react-parser'
import IframeResizer from 'iframe-resizer-react'

import { Chip } from '#app/components/Chip'

export const helperRichContent = {
  isYoutube: (url: string) => {
    // Verify if url contains "youtube.com" or "youtu.be"
    return /youtube\.com|youtu\.be/.test(url)
  },
  isDoc: (filename: any, allowedExtensions = ['.pdf', '.doc']) => {
    if (typeof filename !== 'string') return false
    const lowerCaseUrl = filename.toLowerCase()
    return allowedExtensions.some((extension) => lowerCaseUrl.endsWith(extension))
  },
  getYoutube(url: string) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = url.match(regExp)

    if (match && match[2].length === 11) {
      return 'https://www.youtube.com/embed/' + match[2]
    }
    else {
      return 'error'
    }
  },
  isVideoFile: (filename: any, allowedExtensions = ['.mp4', '.avi', '.mov', '.wmv', '.mkv']) => {
    if (typeof filename !== 'string') return false
    const lowerCaseUrl = filename.toLowerCase()
    return allowedExtensions.some((extension) => lowerCaseUrl.endsWith(extension))
  }
}

// TODO: Check renders https://codesandbox.io/s/react-markdown-with-custom-renderers-961l3?file=/src/App.js:466-473
const ParseStringMarkdown = ({ markdownContent }: { markdownContent: string }) => {
  return (
    <Markdown components={{
      img: function ({ ...props }) {
        const source = props?.src?.replaceAll('&amp;', '&')
        return <img src={source} />
      },
      a: function ({ ...props }) {
        const link = String(props?.href?.replaceAll('&amp;', '&'))
        const elementName = String(props?.children)

        if (helperRichContent.isDoc(elementName ?? '')) return (
          <Chip.Basic
            label={elementName}
            icon={<BsFillFileEarmarkPdfFill />}
            onClick={() => window.open(link, '_blank')}
          />
        )
        if (helperRichContent.isYoutube(link ?? '')) return <Container><Video src={helperRichContent.getYoutube(link)} /></Container>
        if (helperRichContent.isVideoFile(elementName ?? '')) return (
          <VideoContainer>
            <VideoElement
              controls
              src={link}
            />
          </VideoContainer>
        )

        return (
          <Chip.Basic
            label={elementName}
            icon={<BsLink45Deg />}
            onClick={() => window.open(link, '_blank')}
          />
        )
      }
    }}
    >
      {markdownContent}
    </Markdown>
  )
}

const VideoContainer = styled('div')`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Relación de aspecto 16:9 (9/16 * 100%) */
`

const VideoElement = styled('video')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Container = styled('div')`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
`

// Estilos para el iframe
const Video = styled('iframe')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const CardContentExtended = styled(CardContent)`
  img {
    max-width: 100%
  }
`

const BoxExtended = styled(Box)`
width: 100%;
margin-bottom: 2rem;
margin-top: 2rem;
img {
    max-width: 100%
  }
`

export const PreviewRichContentStyled = {
  VideoContainer,
  VideoElement,
  Video,
  Container
}

function ParseStringHtml({ content }: { content: string }) {
  const [isLoading, setIsLoading] = useState(true)

  if (!content) return <></>
  return (
    <>
      {
        parse(content, {
          replace: (domNode) => {
            // @ts-ignore
            if (domNode?.name === 'iframe') {
              return (
                <>
                  {isLoading && (
                    <Skeleton
                      variant='rounded'
                      width='100%'
                      height={60}
                    />
                  )}
                  <IframeResizer
                  // @ts-ignore
                    src={domNode?.attribs?.src}
                    style={{
                      width: '1px',
                      minWidth: '100%',
                      display: isLoading ? 'none' : 'block'
                    }}
                    heightCalculationMethod='lowestElement'
                    onLoad={() => setIsLoading(false)}
                  />
                </>
              )
            }
          }
        })
      }
    </>
  )
}

interface Props {
  header?: JSX.Element
  content?: string
  sx?: SxProps
  withCard?: boolean
  isMarkdown?: boolean
  withoutCardContent?: boolean
}

export function PreviewRichContent({ header, content, withCard, withoutCardContent, isMarkdown, sx }: Props) {
  // TODO: Rebuild this component, there is no point in conditionals just to change the wrapper
  if (withCard) {
    return (
      <Card sx={{
        width: '100%',
        marginBottom: '2rem',
        ...sx
      }}
      >
        <CardContentExtended>
          {header}
          {
            content && !isMarkdown && <ParseStringHtml content={content} />
          }
          {
            content && isMarkdown && <ParseStringMarkdown markdownContent={content} />
          }
        </CardContentExtended>
      </Card>
    )
  }
  else if (withoutCardContent) {
    return (
      <BoxExtended sx={{
        ...sx
      }}
      >
        {header}
        {
          content && !isMarkdown && <ParseStringHtml content={content} />
        }
        {
          content && isMarkdown && <ParseStringMarkdown markdownContent={content} />
        }
      </BoxExtended>
    )
  }
  else {
    return (
      <Box sx={{
        width: '100%',
        marginBottom: '2rem',
        padding: '0.5rem',
        ...sx
      }}
      >
        <CardContentExtended>
          {header}
          {
            content && !isMarkdown && <ParseStringHtml content={content} />
          }
          {
            content && isMarkdown && <ParseStringMarkdown markdownContent={content} />
          }
        </CardContentExtended>
      </Box>
    )
  }
}
