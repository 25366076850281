/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUIStories } from '#app/hooks/useUIStories'
import { useUserPreferences } from '#app/hooks/useUserPreferences'
import { ENVIROMENT } from '#app/store/enviroment'
import { BagSuitcase2, BlankCalendar, Cog, CoinShare, Diamond2, HelpQuestion1, Invoice, MoneyBagDollar, NotesCash, SingleNeutralHome, Web } from '#app/v2/assets/Icons'
import { ShortcutProps } from '#app/v2/pages/MyProfile/components/Shortcut'

interface Shortcut {
  url?: string | string[]
  childrens?: Shortcut[]
}

export function useGetShortcuts() {
  const { isStoryMode } = useUIStories()
  const { setLanguageGlobal, currentLanguage, bankingStatus } = useUserPreferences()
  const { t } = useTranslation()

  const filterShortcuts = useCallback((shortcut: Shortcut): boolean => {
    if (isStoryMode) return true

    const hasValidUrl = shortcut.url ? !shortcut.url.includes('?isStoryMode=true') : true

    let hasValidChildren = true
    if (shortcut.childrens && shortcut.childrens.length > 0) {
      shortcut.childrens = shortcut.childrens.filter(filterShortcuts)
      hasValidChildren = shortcut.childrens.length > 0
    }

    let isBankingOpen = true
    if (shortcut.url?.includes('/payments/') && bankingStatus !== 'open') {
      isBankingOpen = false
    }

    return hasValidUrl && hasValidChildren && isBankingOpen
  }, [isStoryMode])

  const shortcuts = useMemo<ShortcutProps[]>(() => [
    {
      icon: BagSuitcase2,
      label: t('v2Profile.shortcuts.jobs', 'Jobs'),
      url: '/customers',
      childrens: [{
        label: t('v2Profile.shortcuts.insights', 'Insights'),
        url: '/profile?isStoryMode=true',
        childrens: []
      },
      {
        label: t('v2Profile.shortcuts.reviews', 'Reviews'),
        url: '/profile?isStoryMode=true',
        childrens: []
      },
      {
        label: t('v2Profile.shortcuts.estimates', 'Estimates'),
        url: '/profile?isStoryMode=true',
        childrens: []
      },
      {
        label: t('v2Profile.shortcuts.subcontractors', 'Subcontractors'),
        url: '/profile?isStoryMode=true',
        childrens: []
      },
      {
        label: t('v2Profile.shortcuts.pricingBook', 'Pricing Book'),
        url: '/profile?isStoryMode=true',
        childrens: []
      }]
    },
    bankingStatus === 'open'
      ? {
        icon: MoneyBagDollar,
        label: t('v2Profile.shortcuts.myMoney', 'My Money'),
        url: '/payments/banking'
      }
      : {
        icon: MoneyBagDollar,
        label: t('v2Profile.shortcuts.setupBanking', 'Setup Banking'),
        url: '/payments'

      },
    {
      icon: SingleNeutralHome,
      label: t('v2Profile.shortcuts.customers', 'Customers'),
      url: '/customers',
    },
    {
      icon: Invoice,
      label: t('v2Profile.shortcuts.invoices', 'Invoices'),
      url: '/payments/invoices',
    },
    {
      icon: Diamond2,
      label: t('v2Profile.shortcuts.myBrand', 'My Brand'),
      url: '/profile',
      childrens: [{
        label: t('v2Profile.shortcuts.designAndBranding', 'Design & Branding'),
        url: '/profile?isStoryMode=true',
        childrens: []
      },
      {
        label: t('v2Profile.shortcuts.photos', 'Photos'),
        url: '/profile?isStoryMode=true',
        childrens: []
      },
      {
        label: t('v2Profile.shortcuts.myWebsite', 'My Website'),
        url: '/profile?isStoryMode=true',
        childrens: []
      },
      {
        label: t('v2Profile.shortcuts.socialMedia', 'Social Media'),
        url: '/profile?isStoryMode=true',
        childrens: []
      },
      {
        label: t('v2Profile.shortcuts.printStore', 'Print Store'),
        url: '/profile?isStoryMode=true',
        childrens: []
      }]
    },
    {
      icon: BlankCalendar,
      label: t('v2Profile.shortcuts.bookings', 'Bookings'),
      url: '/bookings/main'
    },
    {
      label: t('v2.nav.search', 'Search'),
      icon: NotesCash,
      url: '/v2/search',
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ].filter(filterShortcuts), [filterShortcuts, t, currentLanguage])

  const extraShortcuts = useMemo<ShortcutProps[]>(() => [
    {
      icon: CoinShare,
      label: t('v2.general.refer', 'Refer & Earn'),
      url: '/profile?isStoryMode=true'
    },
    {
      icon: Cog,
      label: t('v2.general.settings', 'Settings'),
      url: '/payments/banking?isStoryMode=true'
    },
    {
      icon: HelpQuestion1,
      label: t('v2.general.help', 'Help'),
      onClick: () => {
        window.open(`http://wa.me/${ENVIROMENT.support_whatsapp}`, '_blank')
      }
    },
    {
      label: currentLanguage === 'es' ? 'En Español' : 'In English',
      icon: Web,
      hasSwitch: {
        valueSwitch: currentLanguage === 'es',
        onClickSwitch: () => { void setLanguageGlobal(currentLanguage === 'en' ? 'es' : 'en') }
      }
    }
  ].filter(filterShortcuts), [currentLanguage, filterShortcuts, setLanguageGlobal, t])

  return {
    shortcuts,
    extraShortcuts
  }
}
