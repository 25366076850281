import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Btn } from '#app/components/Buttons'
import { Input } from '#app/components/Input'

const BidSchema = Yup.object().shape({
  slot_1: Yup.date()
    .required('Required'),
  slot_2: Yup.date()
    .required('Required'),
  slot_3: Yup.date()
    .required('Required'),
  additional_notes: Yup.string()
    .required('Required')
})

function BidForm({ onSubmit, initialValues }: any) {
  return (
    <Grid
      container
      mb={8}
    >
      <Grid
        item
        xs={12}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={BidSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Input.DateTimePicker
                disablePast
                label='Disponibilidad - Propuesta 1'
                name='slot_1'
              />
              <Input.DateTimePicker
                disablePast
                label='Disponibilidad - Propuesta 2'
                name='slot_2'
              />
              <Input.DateTimePicker
                disablePast
                label='Disponibilidad - Propuesta 3'
                name='slot_3'
              />
              <Input.TextArea
                label='Notas Adicionales'
                name='additional_notes'
                style={{
                  width: '100%',
                  height: '100px'
                }}
              />
              <Btn.Button
                disabled={isSubmitting}
                isLoading={isSubmitting}
                type='submit'
              >
                Continuar
              </Btn.Button>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default BidForm
