import { useState } from 'react'

import { Checkbox } from '#app/v2/components/ui/checkbox'
import { Input } from '#app/v2/components/ui/input'
import { Label } from '#app/v2/components/ui/label'
import { RadioGroupItem } from '#app/v2/components/ui/radio-group'
import { cn } from '#app/v2/libs/utils'

import { WizardOption } from '../types'

interface Props {
  title?: string
  description?: string
  other?: boolean
  isChecked?: boolean
  colors?: string[]
  option?: WizardOption
  onClick?: (e: any) => void
  onChange?: (option: WizardOption, optionKey: string, value: string) => void
}

export const TwoRowCard = ({ title = 'other', description, other, colors, onClick, isChecked, onChange, option }: Props) => {
  // Dentro de tu componente
  const [otherValue, setOtherValue] = useState('')
  return (
    <div className='rounded-xl bg-white  text-[#454545] shadow'>
      {colors
        ? (
          <RadioGroupItem
            value={title ? title : 'error_label_no_value'}
            id={title}
            className='peer sr-only'
            checked={isChecked}
            onClick={onClick}
          />
        )
        : (
          <Checkbox
            id={`${title}`}
            className='peer sr-only'
            checked={isChecked}
            onClick={onClick}
          />

        )}
      <Label
        htmlFor={`${title}`}
        className=' block cursor-pointer space-y-3 rounded-xl border border-transparent p-0 px-5 py-3 transition-colors peer-data-[state=checked]:border-[#8BC53F]'
      >
        {other
          ? (
            <>
              <p>Other (please add)</p>
              <Input
                name='other'
                value={otherValue} // Usa el estado aquí
                id={`input-${title}`}
                type='text'
                placeholder='Add other'
                className=' rounded-lg'
                onChange={(e: { target: { value: string } }) => {
                  setOtherValue(e.target.value)
                  if (onChange && option)
                    onChange(option, option?.option_key, e.target.value)
                }}
              />
            </>
          )
          : colors
            ? (
              <>
                <p>
                  {title}
                </p>
                <div className='flex gap-2'>
                  {colors.map((color, i) => (
                    <div
                      key={color + i}
                      style={{
                        backgroundColor: color
                      }}
                      className=' h-6 w-9 rounded-sm border border-[#949494]/30'
                    />
                  )

                  )}
                </div>
              </>
            )
            : (
              <>
                <p className={cn('', {
                  'leading-tight': !description
                })}
                >
                  {title}
                </p>

                <p className={cn('font-normal', {
                  hidden: !description
                })}
                >
                  {description}
                </p>
              </>
            )}
      </Label>
    </div>
  )
}
