import { useCallback, useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Switch from '@mui/material/Switch'

import { useEntityUser } from '#app/hooks/useEntityUser'
import { useEntityUserTasks } from '#app/hooks/useEntityUserTasks'
import { UserTasks } from '#app/routes/Tasks'

export function OwnersTasksPending() {
  const [onlyMyCreatedTasks, setOnlyMyCreatedTasks] = useState(false)

  const {
    queryGetUserTasksPending, queryGetUserTasksPendingUtils: queryUtils
  } = useEntityUserTasks()

  const { userId } = useEntityUser()

  const refetch = useCallback(async () => {
    if (onlyMyCreatedTasks) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      await queryGetUserTasksPending({
        variables: {
          where: {
            deleted: {
              _eq: false
            },
            _and: {
              created_by: {
                _eq: userId
              },
              status: {
                _eq: 'replied'
              },
              userByBo: {
                blocked: {
                  _eq: false
                }
              }
            }
          }
        },
        pollInterval: 5000
      })
    }
    else {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      await queryGetUserTasksPending({
        variables: {
          where: {
            deleted: {
              _eq: false
            },
            _and: {
              status: {
                _eq: 'replied'
              },
              userByBo: {
                blocked: {
                  _eq: false
                }
              }
            }
          }
        },
        pollInterval: 5000
      })
    }
  }, [onlyMyCreatedTasks, queryGetUserTasksPending, userId])

  useEffect(() => {
    void refetch()
  }, [onlyMyCreatedTasks, refetch])

  const filtersOptions = useMemo(() => (
    <Box p={0.5}>
      <FormGroup sx={{
        p: 1
      }}
      >
        <FormControlLabel
          control={(
            <Switch
              size='small'
              checked={onlyMyCreatedTasks}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOnlyMyCreatedTasks(event.target.checked)}
            />
          )}
          label='Only my created tasks'
        />
      </FormGroup>
    </Box>
  ), [onlyMyCreatedTasks])

  return (
    <UserTasks
      taskList={queryUtils.data?.user_tasks ?? []}
      isLoading={queryUtils.loading}
      filtersOptions={filtersOptions}
    />
  )
}
