import { useState } from 'react'
import Highlight from 'react-highlight'
import { Card, Grid, Typography } from '@mui/material'

import { Btn } from '#app/components/Buttons'
import { Dialog } from '#app/components/Dialog'

export function DialogDemo() {
  const [itemName, setItemName] = useState<string | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [open4, setOpen4] = useState(false)
  const [open5, setOpen5] = useState(false)

  const [withoutCancel, setWithoutCancel] = useState(false)

  return (
    <Card sx={{
      p: 4
    }}
    >
      <Typography variant='h5'> Confirm Dialog - Basic </Typography>
      <Highlight>
        {`
import { Dialog } from '#app/components/Dialog'

<Dialog.Confirm withoutCancel={withoutCancel} open={open} setOpen={setOpen} onConfirm={() => console.info('Confirmed!')} />

<Btn.Button primary onClick={() => { setWithoutCancel(false); setOpen(true) }}>Basic</Btn.Button>
<Btn.Button primary onClick={() => { setWithoutCancel(true); setOpen(true) }}>Without Cancel</Btn.Button>
        `}
      </Highlight>
      <Grid
        container
        spacing={2}
        mt={1}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          <Dialog.Confirm
            withoutCancel={withoutCancel}
            open={open}
            setOpen={setOpen}
            onConfirm={() => console.info('Confirmed!')}
          />

          <Btn.Button
            primary
            sx={{
              m: 1
            }}
            onClick={() => { setWithoutCancel(false); setOpen(true) }}
          >
            Basic
          </Btn.Button>
          <Btn.Button
            primary
            sx={{
              m: 1
            }}
            onClick={() => { setWithoutCancel(true); setOpen(true) }}
          >
            Without Cancel
          </Btn.Button>
        </Grid>
      </Grid>

      <Typography variant='h5'> Confirm Dialog - onClose callback </Typography>
      <Highlight>
        {`
import { Dialog } from '#app/components/Dialog'

<Dialog.Confirm
  open={open}
  setOpen={setOpen}
  onConfirm={() => console.info('Confirmed!')}
  onClose={() => console.info('Track this event!')}
/>

<Btn.Button primary onClick={() => { setOpen(true) }}>onClose callback</Btn.Button>
        `}
      </Highlight>
      <Grid
        container
        spacing={2}
        mt={1}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          <Dialog.Confirm
            open={open3}
            setOpen={setOpen3}
            onConfirm={() => console.info('Confirmed!')}
            onClose={() => console.info('Track this event!')}
          />

          <Btn.Button
            primary
            sx={{
              m: 1
            }}
            onClick={() => { setOpen3(true) }}
          >
            onClose callback
          </Btn.Button>
        </Grid>
      </Grid>

      <Typography variant='h5'> Confirm Dialog - Custom Texts</Typography>
      <Highlight>
        {`
import { Dialog } from '#app/components/Dialog'

 <Dialog.Confirm
  open={open}
  setOpen={setOpen}
  onConfirm={() => console.info('Confirmed!')}
  texts={{
    title: 'Please validate this dialog',
    description: 'Are you sure you want to send 250 messages to this BO?',
    confirm: 'Go',
    cancel: "I'm not sure"
  }}
/>

<Btn.Button primary onClick={() => { setOpen(true) }}>Custom Texts</Btn.Button>
        `}
      </Highlight>
      <Grid
        container
        spacing={2}
        mt={1}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          <Dialog.Confirm
            open={open2}
            setOpen={setOpen2}
            texts={{
              title: 'Please validate this dialog',
              description: 'Are you sure you want to send 250 messages to this BO?',
              confirm: 'Go',
              cancel: 'I\'m not sure'
            }}
            onConfirm={() => console.info('Confirmed!')}
          />

          <Btn.Button
            primary
            sx={{
              m: 1
            }}
            onClick={() => { setOpen2(true) }}
          >
            Custom Texts
          </Btn.Button>
        </Grid>
      </Grid>

      <Typography variant='h5'> Delete Dialog - Custom Texts</Typography>
      <Highlight>
        {`
import { Dialog } from '#app/components/Dialog'

<Dialog.Delete
  open={open}
  isLoading={false}
  setOpen={setOpen}
  onConfirm={() => console.info('Confirmed!')}
/>

<Btn.Button color='warning' onClick={() => { setOpen(true) }}>Delete with custom texts</Btn.Button>
        `}
      </Highlight>
      <Grid
        container
        spacing={2}
        mt={1}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          <Dialog.Delete
            open={open4}
            isLoading={false}
            setOpen={setOpen4}
            itemName={itemName}
            onConfirm={() => console.info('Confirmed!')}
          />

          <Btn.Button
            sx={{
              m: 1
            }}
            color='warning'
            onClick={() => { setOpen4(true); setItemName(undefined) }}
          >
            Delete
          </Btn.Button>
          <Btn.Button
            sx={{
              m: 1
            }}
            color='warning'
            onClick={() => { setOpen4(true); setItemName('mike@joinowners.com') }}
          >
            Delete with Item name
          </Btn.Button>
        </Grid>
      </Grid>

      <Typography variant='h5'> Delete Dialog - Custom Texts</Typography>
      <Highlight>
        {`
import { Dialog } from '#app/components/Dialog'

<Dialog.Delete
  open={open}
  isLoading={false}
  setOpen={setOpen}
  onConfirm={() => console.info('Confirmed!')}
  texts={{
    title: 'Please validate this dialog',
    description: 'Are you sure you want to delete 10 messages?',
    confirm: 'Delete All',
    cancel: "I'm not sure"
  }}
/>

<Btn.Button color='warning' onClick={() => { setOpen(true) }}>Delete with custom texts</Btn.Button>
        `}
      </Highlight>
      <Grid
        container
        spacing={2}
        mt={1}
        sx={{
          mb: 8
        }}
      >
        <Grid item>
          <Dialog.Delete
            open={open5}
            isLoading={false}
            setOpen={setOpen5}
            texts={{
              title: 'Please validate this dialog',
              description: 'Are you sure you want to delete 10 messages?',
              confirm: 'Delete All',
              cancel: 'I\'m not sure'
            }}
            onConfirm={() => console.info('Confirmed!')}
          />

          <Btn.Button
            color='warning'
            onClick={() => { setOpen5(true) }}
          >
            Delete with custom texts
          </Btn.Button>
        </Grid>
      </Grid>

    </Card>
  )
}
