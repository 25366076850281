import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type CreateWorkOrderMutationVariables = Types.Exact<{
  description: Types.Scalars['String']
  due_date?: Types.InputMaybe<Types.Scalars['String']>
  fixed_price?: Types.InputMaybe<Types.Scalars['Int']>
  is_follow_up: Types.Scalars['Boolean']
  additional_notes: Types.Scalars['String']
  title: Types.Scalars['String']
  service_request_id: Types.Scalars['String']
}>

export type CreateWorkOrderMutation = { __typename?: 'mutation_root', createWorkOrder?: { __typename?: 'CreateWorkOrderPayload', workOrder: any } | undefined }

export type UpdateWorkOrderMutationVariables = Types.Exact<{
  work_order_id: Types.Scalars['String']
  payload: Types.Scalars['jsonb']
}>

export type UpdateWorkOrderMutation = { __typename?: 'mutation_root', updateWorkOrder?: { __typename?: 'UpdateWorkOrderOutput', work_order: any, success: boolean } | undefined }

export type UpdateWorkOrderBidWinnerMutationVariables = Types.Exact<{
  work_order_id: Types.Scalars['String']
  service_request_id: Types.Scalars['String']
  bid_id: Types.Scalars['String']
}>

export type UpdateWorkOrderBidWinnerMutation = { __typename?: 'mutation_root', updateWorkOrderBidWinner?: { __typename?: 'updateWorkOrderBidWinnerOutput', work_order: any, success: boolean } | undefined }

export type CreatePublicBidMutationVariables = Types.Exact<{
  work_order_id: Types.Scalars['String']
  sp_id?: Types.InputMaybe<Types.Scalars['String']>
  candidate_id?: Types.InputMaybe<Types.Scalars['String']>
  due_date?: Types.InputMaybe<Types.Scalars['String']>
  fixed_price?: Types.InputMaybe<Types.Scalars['Int']>
  additional_notes?: Types.InputMaybe<Types.Scalars['String']>
}>

export type CreatePublicBidMutation = { __typename?: 'mutation_root', createPublicBid?: { __typename?: 'CreatePublicBidPayload', result?: boolean | undefined } | undefined }

export const CreateWorkOrderDocument = gql`
    mutation CreateWorkOrder($description: String!, $due_date: String, $fixed_price: Int, $is_follow_up: Boolean!, $additional_notes: String!, $title: String!, $service_request_id: String!) {
  createWorkOrder(
    input: {description: $description, due_date: $due_date, fixed_price: $fixed_price, is_follow_up: $is_follow_up, additional_notes: $additional_notes, service_request_id: $service_request_id, title: $title}
  ) {
    workOrder
  }
}
    `
export type CreateWorkOrderMutationFn = Apollo.MutationFunction<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>

/**
 * __useCreateWorkOrderMutation__
 *
 * To run a mutation, you first call `useCreateWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkOrderMutation, { data, loading, error }] = useCreateWorkOrderMutation({
 *   variables: {
 *      description: // value for 'description'
 *      due_date: // value for 'due_date'
 *      fixed_price: // value for 'fixed_price'
 *      is_follow_up: // value for 'is_follow_up'
 *      additional_notes: // value for 'additional_notes'
 *      title: // value for 'title'
 *      service_request_id: // value for 'service_request_id'
 *   },
 * });
 */
export function useCreateWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>(CreateWorkOrderDocument, options)
}
export type CreateWorkOrderMutationHookResult = ReturnType<typeof useCreateWorkOrderMutation>
export type CreateWorkOrderMutationResult = Apollo.MutationResult<CreateWorkOrderMutation>
export type CreateWorkOrderMutationOptions = Apollo.BaseMutationOptions<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>
export const UpdateWorkOrderDocument = gql`
    mutation UpdateWorkOrder($work_order_id: String!, $payload: jsonb!) {
  updateWorkOrder(input: {payload: $payload, work_order_id: $work_order_id}) {
    work_order
    success
  }
}
    `
export type UpdateWorkOrderMutationFn = Apollo.MutationFunction<UpdateWorkOrderMutation, UpdateWorkOrderMutationVariables>

/**
 * __useUpdateWorkOrderMutation__
 *
 * To run a mutation, you first call `useUpdateWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkOrderMutation, { data, loading, error }] = useUpdateWorkOrderMutation({
 *   variables: {
 *      work_order_id: // value for 'work_order_id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkOrderMutation, UpdateWorkOrderMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<UpdateWorkOrderMutation, UpdateWorkOrderMutationVariables>(UpdateWorkOrderDocument, options)
}
export type UpdateWorkOrderMutationHookResult = ReturnType<typeof useUpdateWorkOrderMutation>
export type UpdateWorkOrderMutationResult = Apollo.MutationResult<UpdateWorkOrderMutation>
export type UpdateWorkOrderMutationOptions = Apollo.BaseMutationOptions<UpdateWorkOrderMutation, UpdateWorkOrderMutationVariables>
export const UpdateWorkOrderBidWinnerDocument = gql`
    mutation UpdateWorkOrderBidWinner($work_order_id: String!, $service_request_id: String!, $bid_id: String!) {
  updateWorkOrderBidWinner(
    input: {bidId: $bid_id, serviceRequestId: $service_request_id, workOrderId: $work_order_id}
  ) {
    work_order
    success
  }
}
    `
export type UpdateWorkOrderBidWinnerMutationFn = Apollo.MutationFunction<UpdateWorkOrderBidWinnerMutation, UpdateWorkOrderBidWinnerMutationVariables>

/**
 * __useUpdateWorkOrderBidWinnerMutation__
 *
 * To run a mutation, you first call `useUpdateWorkOrderBidWinnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkOrderBidWinnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkOrderBidWinnerMutation, { data, loading, error }] = useUpdateWorkOrderBidWinnerMutation({
 *   variables: {
 *      work_order_id: // value for 'work_order_id'
 *      service_request_id: // value for 'service_request_id'
 *      bid_id: // value for 'bid_id'
 *   },
 * });
 */
export function useUpdateWorkOrderBidWinnerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkOrderBidWinnerMutation, UpdateWorkOrderBidWinnerMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<UpdateWorkOrderBidWinnerMutation, UpdateWorkOrderBidWinnerMutationVariables>(UpdateWorkOrderBidWinnerDocument, options)
}
export type UpdateWorkOrderBidWinnerMutationHookResult = ReturnType<typeof useUpdateWorkOrderBidWinnerMutation>
export type UpdateWorkOrderBidWinnerMutationResult = Apollo.MutationResult<UpdateWorkOrderBidWinnerMutation>
export type UpdateWorkOrderBidWinnerMutationOptions = Apollo.BaseMutationOptions<UpdateWorkOrderBidWinnerMutation, UpdateWorkOrderBidWinnerMutationVariables>
export const CreatePublicBidDocument = gql`
    mutation createPublicBid($work_order_id: String!, $sp_id: String, $candidate_id: String, $due_date: String, $fixed_price: Int, $additional_notes: String) {
  createPublicBid(
    input: {work_order_id: $work_order_id, sp_id: $sp_id, candidate_id: $candidate_id, due_date: $due_date, fixed_price: $fixed_price, additional_notes: $additional_notes}
  ) {
    result
  }
}
    `
export type CreatePublicBidMutationFn = Apollo.MutationFunction<CreatePublicBidMutation, CreatePublicBidMutationVariables>

/**
 * __useCreatePublicBidMutation__
 *
 * To run a mutation, you first call `useCreatePublicBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicBidMutation, { data, loading, error }] = useCreatePublicBidMutation({
 *   variables: {
 *      work_order_id: // value for 'work_order_id'
 *      sp_id: // value for 'sp_id'
 *      candidate_id: // value for 'candidate_id'
 *      due_date: // value for 'due_date'
 *      fixed_price: // value for 'fixed_price'
 *      additional_notes: // value for 'additional_notes'
 *   },
 * });
 */
export function useCreatePublicBidMutation(baseOptions?: Apollo.MutationHookOptions<CreatePublicBidMutation, CreatePublicBidMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useMutation<CreatePublicBidMutation, CreatePublicBidMutationVariables>(CreatePublicBidDocument, options)
}
export type CreatePublicBidMutationHookResult = ReturnType<typeof useCreatePublicBidMutation>
export type CreatePublicBidMutationResult = Apollo.MutationResult<CreatePublicBidMutation>
export type CreatePublicBidMutationOptions = Apollo.BaseMutationOptions<CreatePublicBidMutation, CreatePublicBidMutationVariables>
