import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { json } from '@codemirror/lang-json'
import { Box, capitalize, Grid, Stack } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
// Grid version 1
import Typography from '@mui/material/Typography'
import { vscodeDark } from '@uiw/codemirror-theme-vscode'
import CodeMirror from '@uiw/react-codemirror'
import { useSnackbar } from 'notistack'

import { Alert } from '#app/components/Alerts'
import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import { DividerExtended } from '#app/components/Divider'
import AppContext from '#app/contexts/AppContext'
import { useAppContext } from '#app/contexts/AppContext/useAppContext'
import { useDayJs } from '#app/hooks/useDayJs'
import { EntityPage } from '#app/layouts/EntityView'
import { useWebsiteEditorCreateMutation } from '#app/operations/website-editor/websiteeditor.mutations.generated'
import { useWebsiteEditorGetAssetsIssuesQuery, useWebsiteEditorGetWebsitesLazyQuery } from '#app/operations/website-editor/websiteeditor.queries.generated'

import { WebsiteBuilder } from '../../OwnersTasks/WebsiteBuilder'
import { WebsiteDeployments } from '../WebsiteDeployments'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function WebsiteCard({ website, index, loading, isWebsiteInProgress }: { website?: any, index?: number, loading: boolean, isWebsiteInProgress?: boolean }) {
  const { formatShortDate, formatDateV2 } = useDayJs()
  const navigate = useNavigate()

  const title = useMemo(() => {
    if (isWebsiteInProgress) return 'Website in progress...'
    return `Website ${index} - ${formatShortDate(website?.first_created_at)}`
  }, [formatShortDate, isWebsiteInProgress, website, index])

  if ((!website || !index) && !isWebsiteInProgress) {
    return (
      <Card
        sx={{
          maxWidth: 345,
          margin: 2
        }}
      >
        Invalid Website
      </Card>
    )
  }

  return (
    <Card
      sx={{
        maxWidth: 345,
        margin: 2
      }}
    >
      <CardMedia
        component='img'
        alt='website'
        height='140'
        sx={{
          opacity: isWebsiteInProgress ? '1' : '0.25'
        }}
        image={isWebsiteInProgress ? 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/website-editor%2Ffree-website-builder.webp?alt=media&token=53a4b033-0d71-494e-839a-85d66cf58654' : 'https://firebasestorage.googleapis.com/v0/b/owners-staging-360619.appspot.com/o/website-editor%2Fwebsite-editor.png?alt=media&token=8586bfea-42cb-42c7-a6fb-587aca2e16c1'}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant='h5'
          component='div'
        >
          {title}
        </Typography>
        <Typography>
          {!isWebsiteInProgress && 'First section created:'}
        </Typography>
        <Typography
          variant='body2'
          color='text.secondary'
        >
          {isWebsiteInProgress ? 'We are creating an amazing site for you, wait a few seconds.' : formatShortDate(website?.first_created_at) + ' - ' + formatDateV2(website?.first_created_at)}
        </Typography>
        <DividerExtended />
        <Typography
          variant='body2'
          color='text.secondary'
        >
          {isWebsiteInProgress ? 'The total number of sections depends on the latest version of our active template.' : `Total Sections: ${website?.total_sections}`}
        </Typography>
        <DividerExtended />
        <Typography
          variant='body2'
          color='text.secondary'
        >
          {website?.section_version && <Chip.Basic label={website?.section_version} />}
        </Typography>
      </CardContent>
      { (!loading && !isWebsiteInProgress)
      && (
        <CardActions>
          <Btn.Button
            variant='outlined'
            onClick={() => navigate(`${website?.section_version}`)}
          >
            Edit & Publish
          </Btn.Button>
        </CardActions>
      )}
    </Card>
  )
}

export function WebsiteEditorAssetsCheckError() {
  const { data: dataGetAssetsErrors, loading: loadingGetAssetsError } = useWebsiteEditorGetAssetsIssuesQuery()

  const [viewAll, setViewAll] = useState(false)

  if (loadingGetAssetsError) {
    return (
      <Alert.Basic
        sx={{
          mb: 2,
        }}
        severity='info'
        descriptionJSX={(
          <Box sx={{
            width: '100%'
          }}
          >
            <div>
              <p>Loading: We are verifying all the assets needed to create websites...</p>
            </div>
          </Box>
        )}
      />
    )
  }

  if (!loadingGetAssetsError && dataGetAssetsErrors?.websiteEditorGetAssetsIssues?.data?.total === 0) {
    return <></>
  }

  return (
    <Box mb={2}>
      <Alert.Basic
        sx={{
          mb: 2
        }}
        severity='error'
        title='There are errors in Airtable that can produce fatal errors when generating a new website.'
        descriptionJSX={(
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={4}
          >
            <Box width='100%'>
              <p>
                Before creating a new website, it is important that all the necessary assets are loaded in their respective tables: "services_categories", "services_images", "category_images"
              </p>
              <br />
              <p>
                There are
                {' '}
                <a
                  href='https://n8n.joinowners.co/webhook/check-assets-errors'
                  target='_blank'
                  rel='noreferrer'
                >
                  {dataGetAssetsErrors?.websiteEditorGetAssetsIssues?.data?.total}
                  {' '}
                  error
                </a>
                , you can view the details in this dialog
                {' '}
              </p>
            </Box>

            <Chip.Basic
              label='Check all errors'
              onClick={() => setViewAll(!viewAll)}
            />
          </Stack>
        )}
      />
      {
        viewAll
        && (
          <CodeMirror
            readOnly
            extensions={[json()]}
            value={JSON.stringify(dataGetAssetsErrors, null, 2)}
            height='500px'
            theme={vscodeDark}
          />
        )
      }
    </Box>
  )
}

export function WebsiteTemplates() {
  const { appState } = useContext(AppContext)
  const { V1_SET_LOADING, isLoading } = useAppContext()
  const { t } = useTranslation()
  const { userId } = useParams()

  const [generateWebsite, { loading }] = useWebsiteEditorCreateMutation()
  const [getWebsites, { data, loading: loadingFetchingCards }] = useWebsiteEditorGetWebsitesLazyQuery({
    variables: {
      user_id: userId
    },
    fetchPolicy: 'no-cache'
  })
  const { enqueueSnackbar } = useSnackbar()

  const generateNewWebsite = useCallback(async () => {
    const res = await generateWebsite({
      variables: {
        user_id: userId
      }
    })

    if (res?.data?.websiteEditorCreate?.success) {
      enqueueSnackbar('The site was created successfully!', {
        variant: 'success'
      })
    }

    await getWebsites()
  }, [enqueueSnackbar, generateWebsite, getWebsites, userId])

  useEffect(() => {
    getWebsites().then(() => {
      if (appState.isLoading) {
        V1_SET_LOADING(false)
      }
    })
  }, [V1_SET_LOADING, appState.isLoading, getWebsites])

  const [openWebsiteBuilderDisclaimer, setOpenWebsiteBuilderDisclaimer] = useState(false)

  const generalLoading: boolean = useMemo(() => {
    return (!!loadingFetchingCards || !!loading)
  }, [loading, loadingFetchingCards])
  return (
    <>
      <EntityPage
        pageTitle='Websites Editor - Sites by user'
        pageSubtitle='In this list you can find all the sites generated for a user, you do not need to generate a new website if you only want to edit a part of the existing website. Use the create new website function only when you want to receive updates from the parent template.'
        actionLabel={capitalize(t('generic.create'))}
        layout='one-column'
        isLoading={isLoading}
        mainContent={(
          <>
            {userId
            && (
              <WebsiteBuilder
                agnostic
                isOpen={openWebsiteBuilderDisclaimer}
                setIsOpen={setOpenWebsiteBuilderDisclaimer}
                userId={userId}
                cbCreate={() => {
                  setOpenWebsiteBuilderDisclaimer(false)
                  generateNewWebsite()
                }}
              />
            )}
            <WebsiteEditorAssetsCheckError />
            <Grid
              container
              spacing={2}
            >
              {
                data?.websiteEditorGetWebsites?.websites?.map((website, key) => {
                  return (
                    <Grid
                      key={key}
                    >
                      <WebsiteCard
                        index={key + 1}
                        website={website}
                        loading={generalLoading}
                      />
                    </Grid>
                  )
                }
                )
              }
              {
                generalLoading
                && (
                  <Grid>
                    <WebsiteCard
                      isWebsiteInProgress
                      loading={generalLoading}
                    />
                  </Grid>
                )
              }
            </Grid>
            <DividerExtended />
            <Stack
              direction='row'
              spacing={2}
              padding={2}
            >
              <Box>
                <Btn.Button
                  disabled={loading}
                  onClick={() => setOpenWebsiteBuilderDisclaimer(true)}
                >
                  {' '}
                  Generate new website
                </Btn.Button>
                <Typography
                  color='text.secondary'
                  mt={2}
                >
                  All websites generated are based on the "general_feb24" template, Each section has a default component and some components are turned off by default because they depend on information that is not part of the system.
                </Typography>
              </Box>
            </Stack>
          </>
        )}
      />

      <Card sx={{
        mx: 4,
        mt: -16,
        mb: 8
      }}
      >
        <Grid
          container
          spacing={2}
        >
          {userId
          && <WebsiteDeployments boId={userId} />}
        </Grid>
      </Card>
    </>
  )
}
