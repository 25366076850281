import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { CenteredNavTitle } from '#app/v2/components/CenteredNavTitle'
import { HeaderSharedNavigation } from '#app/v2/components/HeaderSharedNavigation'

import { feed, linearIssueMarkdown, Rating, rating, task } from './data'
import { TaskDetail } from './page'

export const StoryTaskDetail = () => {
  const [searchParams] = useSearchParams()

  const storyProps = useMemo(() => {
    const scenario = searchParams.get('scenario')

    const res = {
      task: task,
      comments: feed,
      loading: false,
      rating: undefined as Rating['rating']
    }

    switch (scenario) {
      case 'empty_feed':
        res.comments = []
        break
      case 'linear_markdown':
        res.task = linearIssueMarkdown
        break
      case 'completed_task':
        res.task.status = 'completed'
        break
      case 'completed_task_with_rating':
        res.task.status = 'completed'
        res.rating = rating
        break
      case 'admin_completed_task':
        res.task.status = 'admin_completed'
        break
      case 'loading':
        res.loading = true
        break
      default:
        break
    }

    return res
  }, [searchParams])
  return (
    <div className=' flex min-h-screen flex-col bg-[#F7F9FB]'>
      {/* HEADER */}
      <HeaderSharedNavigation
        backUrl='/v2-ui/tasks'
        main={<CenteredNavTitle title='Task' />}
      />
      <TaskDetail
        task={storyProps.task}
        comments={storyProps.comments}
        loading={storyProps.loading}
        rating={storyProps.rating}
      />
    </div>
  )
}
