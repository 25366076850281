/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Fade, Grid, Icon, IconButton, SxProps } from '@mui/material'
import { Stack } from '@mui/system'

import Card from '#app/components/Card/Card'
import PageContent from '#app/components/PageContent/PageContent'
import SearchInput from '#app/components/SearchInput/SearchInput'
import { PageTitle } from '#app/components/Text/PageTitle'
import AppContext from '#app/contexts/AppContext'
import { OwnersFilter } from '#app/forms/OwnersFilter'
import { useIsDesktopSize } from '#app/utils/mediaQueries'

import { EntityPageProps, EntityProps } from './types'

export const EntityPage = ({ layout, mainContent, listContent, onActionClick, actionLabel, pageTitle, secondaryActionIcon, onSecondaryActionClick, pageSubtitle, hasReturn, backgroundColor, mainContentWithoutCard, awaitLoad, desktopPaddings, mobilePaddings, ...props }: EntityPageProps) => {
  const { appState, setAppState } = useContext(AppContext)
  const navigate = useNavigate()
  const isDesktop = useIsDesktopSize()
  const [style, setStyle] = useState<SxProps | undefined>()

  useEffect(() => {
    if (appState.isLoading && !props.isLoading) {
      setAppState((previousState) => ({
        ...previousState,
        isLoading: false
      }))
    }

    if (isDesktop && layout === 'two-columns') {
      setStyle(undefined)
    }
    else {
      setStyle(undefined)
    }
  }, [appState.isLoading, setAppState, isDesktop, props.isLoading])

  if ((appState.isLoading || props.isLoading) && !awaitLoad) { return <></> }

  return (
    <PageContent
      backgroundColor={backgroundColor}
      desktopPaddings={desktopPaddings}
      mobilePaddings={mobilePaddings}
    >
      {
        !pageTitle && !onActionClick
          ? null
          : (
            <PageContent.PageHeader>
              {
                pageTitle
                && (
                  <PageTitle
                    subtitle={pageSubtitle}
                    onClick={() => {
                      if (hasReturn) {
                        navigate(-1)
                      }
                    }}
                  >
                    {
                      hasReturn
                      && <ArrowBackIcon />
                    }
                    <span className={hasReturn ? 'ms-2' : ''}>{ pageTitle }</span>
                  </PageTitle>
                )
              }
              {
                onActionClick
                && (
                  <PageContent.PageAction>
                    <Stack
                      direction='row'
                      spacing={2}
                    >
                      {
                        onSecondaryActionClick
                        && (
                          <IconButton
                            color='primary'
                            size={isDesktop ? 'large' : 'medium'}
                            onClick={onSecondaryActionClick}
                          >
                            <Icon>
                              { secondaryActionIcon }
                            </Icon>
                          </IconButton>
                        )
                      }
                      <PageContent.PageActionButton
                        label={actionLabel}
                        onClick={onActionClick}
                      />
                    </Stack>

                  </PageContent.PageAction>
                )
              }
            </PageContent.PageHeader>
          )
      }
      <Grid
        container
        sx={style}
        columnSpacing={3}
      >
        { (layout === 'one-column' && (mainContent ?? mainContentWithoutCard))
          ? (
            <OneColumn
              layout={layout}
              mainContent={mainContent}
              mainContentWithoutCard={mainContentWithoutCard}
              {...props}
            />
          )
          : null }
        { (layout === 'two-columns' && (mainContent ?? mainContentWithoutCard ?? listContent))
          ? (
            <TwoColumns
              layout={layout}
              mainContent={mainContent}
              listContent={listContent}
              mainContentWithoutCard={mainContentWithoutCard}
              {...props}
            />
          )
          : null }
        { layout === 'free' && (
          <Grid
            item
            xs={12}
            sx={{
              height: '100%'
            }}
          >
            { mainContent }
          </Grid>
        ) }
      </Grid>
    </PageContent>
  )
}

export const OneColumn = ({ mainContent, mainContentWithoutCard, ...props }: EntityPageProps) => {
  if (mainContent) {
    return (
      <Grid
        item
        xs={12}
        sx={{
          height: '100%'
        }}
      >
        <EntityDetailsWrapper
          content={mainContent}
          {...props}
        />
      </Grid>
    )
  }
  else if (mainContentWithoutCard) {
    return (
      <Grid
        item
        xs={12}
        sx={{
          height: '100%'
        }}
      >
        <EntityDetailsWrapperWithoutCard
          content={mainContentWithoutCard}
          {...props}
        />
      </Grid>
    )
  }
  else {
    return <></>
  }
}

export const TwoColumns = ({ listContent, mainContent, mainContentWithoutCard, ...props }: EntityPageProps) => {
  const isDesktop = useIsDesktopSize()

  if (isDesktop) {
    return (
      <>
        <Grid
          item
          xs={3}
          sx={{
            height: '87vh'
          }}
        >
          { listContent
            ? (
              <EntityListWrapper
                content={listContent}
                {...props}
              />
            )
            : null }
        </Grid>
        <Grid
          item
          xs={9}
          sx={{
            minHeight: '87vh'
          }}
        >
          { mainContent
            ? (
              <EntityDetailsWrapper
                content={mainContent}
                {...props}
              />
            )
            : null }
          { mainContentWithoutCard
            ? (
              <EntityDetailsWrapperWithoutCard
                content={mainContentWithoutCard}
                {...props}
              />
            )
            : null }
        </Grid>
      </>
    )
  }

  return (
    <Grid
      item
      xs={12}
      sx={{
        height: '100%'
      }}
    >
      <EntityListWrapper
        content={listContent}
        {...props}
      />
    </Grid>
  )
}

export const EntityListWrapper = ({ onFilterChange, onAdvancedFilterChange, hasAdvancedFilters, advancedFilters, advancedFiltersApplied, content, isLoading }: EntityProps) => {
  return (
    <Card
      withScrollY
      className='h-100'
    >
      <Stack spacing={3}>
        {
          onFilterChange
          && (
            <Grid item>
              <Card>
                <Card.Body>
                  <SearchInput
                    advancedFiltersApplied={advancedFiltersApplied}
                    hasAdvancedFilters={hasAdvancedFilters}
                    filterForm={(
                      <OwnersFilter
                        filters={advancedFilters}
                        onSubmit={onAdvancedFilterChange}
                      />
                    )}
                    placeholder='Search'
                    onFilterChange={onFilterChange}
                  />
                </Card.Body>
              </Card>
            </Grid>
          )
        }
        <Grid
          item
          style={{
            paddingLeft: 0,
            paddingRight: 0
          }}
        >
          <LoadingContainer
            content={content}
            isLoading={isLoading}
          />
        </Grid>
      </Stack>
    </Card>
  )
}

export const EntityDetailsWrapper = ({ onFilterChange, layout, onAdvancedFilterChange, hasAdvancedFilters, advancedFilters, advancedFiltersApplied, content, isLoading }: EntityProps) => {
  return (
    <Card className='h-100'>
      <Card.Body>
        <Grid
          container
          rowSpacing={3}
          sx={{
            paddingBottom: 3
          }}
        >
          {
            onFilterChange
            && layout === 'one-column'
            && (
              <Grid
                item
                xs={12}
              >
                <SearchInput
                  advancedFiltersApplied={advancedFiltersApplied}
                  hasAdvancedFilters={hasAdvancedFilters}
                  filterForm={(
                    <OwnersFilter
                      filters={advancedFilters}
                      onSubmit={onAdvancedFilterChange}
                    />
                  )}
                  placeholder='Search'
                  onFilterChange={onFilterChange}
                />
              </Grid>
            )
          }
          <Grid
            item
            xs={12}
          >
            <LoadingContainer
              content={content}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  )
}

export const EntityDetailsWrapperWithoutCard = ({ onFilterChange, layout, onAdvancedFilterChange, hasAdvancedFilters, advancedFilters, advancedFiltersApplied, content, isLoading, getBack, getBackAction }: EntityProps) => {
  return (
    <Grid
      container
      className='h-100'
    >
      <Grid
        item
        xs={12}
      >
        <Grid
          container
          rowSpacing={3}
          sx={{
            paddingBottom: 3
          }}
        >
          {
            onFilterChange && !getBack
            && layout === 'one-column'
            && (
              <Grid
                item
                xs={12}
              >
                <SearchInput
                  advancedFiltersApplied={advancedFiltersApplied}
                  hasAdvancedFilters={hasAdvancedFilters}
                  filterForm={(
                    <OwnersFilter
                      filters={advancedFilters}
                      onSubmit={onAdvancedFilterChange}
                    />
                  )}
                  placeholder='Search'
                  onFilterChange={onFilterChange}
                />
              </Grid>
            )
          }
          {
            getBack
            && (
              <Grid
                item
                xs={3}
                sx={{
                  cursor: 'pointer'
                }}
                onClick={getBackAction}
              >
                <ArrowBackIcon />
              </Grid>
            )
          }
          <Grid
            item
            xs={12}
          >
            <LoadingContainer
              content={content}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

// Do not delete the commented out lines, these are in case we need to add an animation on top of the Fade
export const LoadingContainer = ({ content, isLoading }: EntityProps) => {
  // const [wrapperLoading, setWrapperLoading] = useState<boolean>(true)

  // useEffect(() => {
  //   if (isLoading === false) {
  //     setTimeout(() => {
  //       setWrapperLoading(false)
  //     }, 500)
  //   }
  // }, [isLoading])

  // style={{ position: 'relative', width: '100%', height: '100%' }}
  return (
    <div>
      {/* <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'white', visibility: wrapperLoading ? 'visible' : 'hidden', zIndex: 1000 }}>
      <div className='d-flex h-100 w-100 justify-content-center align-items-center'>
        <CircularProgress />
      </div>
    </div> */}
      <Fade
        in={!isLoading}
        timeout={{
          enter: 500,
          exit: 0
        }}
      >
        <div>
          { content }
        </div>
      </Fade>
    </div>
  )
}
