import { useCallback, useContext, useEffect, useRef } from 'react'

import AppContext from '#app/contexts/AppContext'

export function useAppContextV2() {
  const { appState, setAppState } = useContext(AppContext)

  const isLoadingRef = useRef(appState.isLoading)

  useEffect(() => {
    isLoadingRef.current = appState.isLoading
  }, [appState.isLoading])

  const setLoading = useCallback((val: boolean) => {
    if (isLoadingRef.current !== val) {
      setTimeout(() => {
        setAppState((prevAppState) => ({
          ...prevAppState,
          isLoading: false
        }))
      }, 2000)
    }
  }, [setAppState])

  return {
    V2_LOGOUT: () => {
      setAppState((prevState) => ({
        ...prevState,
        forceLogout: true
      }))
    },
    V2_SET_LOADING: setLoading,
    V2_IS_LOADING: appState.isLoading,
    appState
  }
}
