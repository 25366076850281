import { useMemo } from 'react'

export function LoomVideo({ loomURL }: { loomURL: string }) {
  const loomEmbed = useMemo(() => {
    if (!loomURL) return ''
    const loomID = /[^/]*$/.exec(loomURL)
    if (loomID?.[0]) {
      return `https://www.loom.com/embed/${loomID[0]}`
    }
    return loomURL
  }, [loomURL])

  if (!loomURL) {
    return <></>
  }

  return (
    <div style={{
      position: 'relative',
      paddingBottom: '56.25%',
      height: '0'
    }}
    >
      <iframe
        allowFullScreen
        src={loomEmbed}
        frameBorder='0'
        // @ts-ignore
        mozallowfullscreen='true'
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%'
        }}
        // @ts-ignore
        webkitallowfullscreen='true'
      >
      </iframe>
    </div>
  )
}
