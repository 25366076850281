import * as React from 'react'
import { Box } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useField } from 'formik'
import { debounce } from 'lodash'

export default function InputWithQuery({ label, getOptions, getQueryVariables, getPrimaryText, getSecondaryText, fetchData, icon, loading, data, error, onChange, isOptionEqualToValue, ...props }) {
  const [, meta, helpers] = useField({
    name: props.name
  })
  const { setValue } = helpers

  const [inputValue, setInputValue] = React.useState()

  const [options, setOptions] = React.useState([])

  const fetchWithDebounce = React.useMemo(
    () =>
      debounce((inputValue, callback) => {
        if (!loading) {
          fetchData({
            variables: getQueryVariables(inputValue)
          })
        }
      }, 500),
    []
  )

  React.useEffect(() => {
    fetchWithDebounce(inputValue)
  }, [inputValue, fetch])

  React.useEffect(() => {
    setOptions(getOptions(data))
  }, [data])

  return (
    <Autocomplete
      fullWidth
      autoComplete
      multiple={false}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getPrimaryText}
      filterOptions={(x) => x}
      options={options}
      value={meta.value}
      renderInput={(params) => (
        <>
          { label && <label className='form-label'>{label}</label> }
          <TextField
            {...params}
            error={meta.touched && !!meta.error}
          />
          <div className='error'>{ meta.touched && meta.error }</div>
        </>
      )}
      renderOption={(props, option) => {
        if (!option) { return null }

        return (
          <li {...props}>
            <Grid
              container
              alignItems='center'
            >
              <Grid item>
                <Box
                  component={icon}
                  sx={{
                    color: 'text.secondary',
                    mr: 2
                  }}
                />
              </Grid>
              <Grid
                item
                xs
              >
                <span style={{
                  fontWeight: 700
                }}
                >
                  { getPrimaryText(option) ?? '' }
                </span>
                <Typography
                  variant='body2'
                  color='text.secondary'
                >
                  { getSecondaryText(option) ?? '' }
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
      onChange={(event, value) => {
        setValue(value)
        if (onChange) {
          onChange(value)
        }
        document?.activeElement?.blur()
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      {...props}
    />
  )
}
