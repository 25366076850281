import { Box, styled } from '@mui/material'

import { COLORS } from '#app/layouts/theme'

export const EmptyCreditCard = styled(Box)`
width: 100%;
height: 212px;
border: 2px gray;
border-style: dashed;
border-color: ${COLORS.light.secondary};
margin-top: 1rem;
margin-bottom: 2rem;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
cursor: pointer;
:hover {
  transition: 0.5s;
  border-style: solid;
  background-color: ${COLORS.light.primary};
}
`

export const CardContainer = styled(Box)<{ status: string, isDesktop: boolean }>`
width: 337px;
zoom: ${(props) => props.isDesktop ? '1' : '0.7'};
height: 212px;
background-color: #000;
border-radius: 1rem;
opacity:  ${(props) => props.status !== 'active' ? '0.5' : '1'};
padding: 1rem 1.25rem;
`

export const CreditCardChip = styled('img')`
width: 3.25rem;
`

export const CreditCardLogo = styled('img')`
width: 2.25rem;
float: right;
`
