// bootstrap = !important specificity selector
// import 'bootstrap/dist/css/bootstrap.css'
import '#app/assets/styles/owners-v1-bootstrap.css'
import '#app/assets/styles/owners-v1-custom.css'

interface Props {
  children: React.ReactElement
}

export const V1Dependencies = ({ children }: Props) => {
  return <>{children}</>
}
