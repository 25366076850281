/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'

import { THEME } from '#app/layouts/theme'
import { useGetWorkOrderDetailsQuery } from '#app/operations/pmmarketplace/pmmarketplace.queries.generated'
import { ENVIROMENT } from '#app/store/enviroment'

// interface PublicBidProps {
//   due_date: string
//   fixed_price: number
// }

export const CreateBid = () => {
  const [created] = useState(false)
  const { t } = useTranslation()
  // Get the query parameters from the URL
  const queryParams = new URLSearchParams(window.location.search)
  // Get the value of a specific query parameter
  const workOrderId = queryParams.get('work_order_id')
  const spId = queryParams.get('sp_id')
  // const candidateId = queryParams.get('candidate_id')

  // const [createPublicBid] = useCreatePublicBidMutation()
  const { data, loading } = useGetWorkOrderDetailsQuery({
    variables: {
      id: workOrderId ?? ''
    }
  })

  // let initialValues = {
  //   due_date: '' as Date | string,
  //   fixed_price: '' as number | string,
  //   additional_notes: '' as number | string
  // }
  // let title = ''
  // if (data) {
  //   const jobDetails = data?.getWorkOrder?.work_order?.pmmarketplace_work_orders_by_pk
  //   const serviceRequests = jobDetails?.service_requests
  //   title = jobDetails.title
  //   initialValues = {
  //     ...initialValues,
  //     // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  //     due_date: jobDetails.due_date ? moment(jobDetails.due_date).format('ddd MMM D YYYY HH:mm') : '',
  //     fixed_price: serviceRequests.fixed_price || ''
  //   }
  // }

  // const onSubmit = async (values: PublicBidProps) => {
  //   const result = await createPublicBid({
  //     variables: {
  //       work_order_id: workOrderId ?? '',
  //       sp_id: spId ?? '',
  //       candidate_id: candidateId ?? '',
  //       ...values
  //     }
  //   })
  //   if (result?.data?.createPublicBid?.result) {
  //     // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  //     setCreated(result?.data?.createPublicBid?.result)
  //   }
  // }

  const airtableURL: string | null = useMemo(() => {
    const workOrder = data?.getWorkOrder?.work_order?.pmmarketplace_work_orders_by_pk
    if (!workOrderId || !spId) return null
    let baseUrl = `${ENVIROMENT.airtable_create_bid}?prefill_sp=${spId}&prefill_work_order=${workOrderId}&hide_sp=true&hide_work_order=true`

    if (workOrder?.due_date_type === 'fixed-date' && workOrder?.due_date) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      baseUrl = `${baseUrl}&prefill_date=${workOrder?.due_date}&hide_date=true`
    }

    if (workOrder?.fixed_price) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      baseUrl = `${baseUrl}&prefill_price=${workOrder?.fixed_price}&hide_price=true`
    }

    return baseUrl
  }, [spId, workOrderId, data])

  return (
    <Grid
      container
      mb={8}
    >
      {!created && <Typography variant='h3'>{data?.getWorkOrder?.work_order?.pmmarketplace_work_orders_by_pk?.title}</Typography>}
      {/* { !created && <WorkOrdersBidForm initialValues={ initialValues } onSubmit={onSubmit} /> } */}
      {spId && workOrderId && airtableURL && !loading
      && (
        <iframe
          className='airtable-embed airtable-dynamic-height'
          src={airtableURL}
          frameBorder='0'
          width='100%'
          height='1300px'
        >
        </iframe>
      )}
      {created && (
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='body2'
            color={THEME.COLORS.custom.black}
          >
            <b>
              {t('bids.successTitle')}
              :
            </b>
          </Typography>
          <Typography variant='body1'>
            {t('bids.successDescription')}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}
