import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { useCreateLinearIssueBasedOnTemplateMutation } from '#app/operations/linear/linear.mutations.generated'
import { useStoreCustomerJourney } from '#app/store/customer-journey'
import { WizardSuccessCampaign } from '#app/v2/pages/Wizard/success-page'
import { useAppContextV2 } from '#app/v2-core/layouts/useAppContextV2'

export function CoreTemplateCallback() {
  const { V2_SET_LOADING } = useAppContextV2()
  const { linearTemplateId } = useParams()
  const [generateIssue] = useCreateLinearIssueBasedOnTemplateMutation()
  const { usedLinearTemplateIds, addLinearTemplateId } = useStoreCustomerJourney()
  const location = useLocation()

  const [shouldGenerateIssue, setShouldGenerateIssue] = useState(false)

  const checkAndSetGenerateIssue = useCallback(() => {
    const templateId = linearTemplateId ?? ''
    if (!usedLinearTemplateIds.includes(templateId) && templateId) {
      setShouldGenerateIssue(true)
    }
  }, [linearTemplateId, usedLinearTemplateIds])

  const createIssue = useCallback(async () => {
    if (shouldGenerateIssue && linearTemplateId) {
      try {
        addLinearTemplateId(linearTemplateId)
        const searchParams = new URLSearchParams(location.search)
        const upsell_call = searchParams.get('upsell_call')
        await generateIssue({
          variables: {
            linear_template_id: linearTemplateId,
            request_upsell_call: !!upsell_call
          }
        })
      }
      catch (err) {
        console.error(err)
      }
      finally {
        setShouldGenerateIssue(false)
        V2_SET_LOADING(false)
      }
    }
  }, [V2_SET_LOADING, addLinearTemplateId, generateIssue, linearTemplateId, shouldGenerateIssue, location.search])

  useEffect(() => {
    checkAndSetGenerateIssue()
  }, [checkAndSetGenerateIssue])

  useEffect(() => {
    if (shouldGenerateIssue) {
      createIssue()
    }
  }, [shouldGenerateIssue, createIssue])

  return <WizardSuccessCampaign homeUrl='/v2/home' />
}
