import { Box, CircularProgress, Stack, Typography } from '@mui/material'

export function CircularProgressWithLabel({ value }: { value: number }) {
  return (
    <Box sx={{
      width: '100%'
    }}
    >
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={2}
      >
        <Box sx={{
          position: 'relative',
          display: 'inline-flex'
        }}
        >
          <CircularProgress
            value={value}
            size={90}
            variant='determinate'
          />
          <Box sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          >
            <Typography
              variant='body1'
              component='div'
              color='text.secondary'
            >
              {`${Math.round(value)}%`}
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}
