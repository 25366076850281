import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { useImmer } from 'use-immer'

import ProfileContext from '#app/contexts/ProfileContext'
import { Comments } from '#app/operations/autogenerate/schemas'
import { useCreateCommentMutation, useUpdateCommentByPkMutation } from '#app/operations/comments/comments.mutations.generated'
import { GetCommentsQuery, useGetCommentsLazyQuery } from '#app/operations/comments/comments.queries.generated'
import { useEditTaskMutation } from '#app/operations/tasks/tasks.mutations.generated'
import { GetBusinessOwnerTasksQuery, useGetBusinessOwnerTaskByIdLazyQuery, UserTaskFieldsFragmentDoc } from '#app/operations/tasks/tasks.queries.generated'
import { Comment } from '#app/types'
import { CenteredNavTitle } from '#app/v2/components/CenteredNavTitle'
import { HeaderSharedNavigation } from '#app/v2/components/HeaderSharedNavigation'
import { TaskDetail } from '#app/v2/pages/TaskDetail/page'
import { useAppContextV2 } from '#app/v2-core/layouts/useAppContextV2'

export function CoreTaskDetail() {
  const [getQuery, { data: dataTasks, loading: loadingTask, refetch: refetchData }] = useGetBusinessOwnerTaskByIdLazyQuery()
  const [queryComments, { data: dataComments, loading: loadingComments, refetch: refetchComments }] = useGetCommentsLazyQuery()
  const { getProfile: { user } } = useContext(ProfileContext)
  const { V2_SET_LOADING } = useAppContextV2()
  const location = useLocation()
  const [commentsList, setCommentList] = useImmer<{ available: GetCommentsQuery['comments'] }>({
    available: []
  })
  const params = useParams()
  const { t } = useTranslation()

  useEffect(() => {
    if (!loadingTask && !loadingComments) {
      V2_SET_LOADING(false)
    }
  }, [V2_SET_LOADING, loadingComments, loadingTask])

  const where = useMemo(() => {
    return {
      comments: {
        thread_entity_id: {
          _eq: params?.taskId
        },
        _and: [
          {
            thread_entity_type: {
              _eq: 'user_tasks'
            }
          },
          {
            status: {
              _neq: 'deleted'
            }
          },
          {
            private_note: {
              _in: [false]
            }
          }
        ]
      }
    }
  }, [params?.taskId])

  useEffect(() => {
    void refetchDataNeeded()
  }, [])

  const refetchDataNeeded = useCallback(async () => {
    // Check if the user came from a specific pathname
    const wizardLinkPattern = /v2\/wizard\/([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})/
    const comesFromWizard = wizardLinkPattern.test(location?.state?.from)

    if (comesFromWizard) {
      await refetchData({
        taskId: params?.taskId,
        userId: user?.id
      }
      )
      await refetchComments({
        where: where.comments
      })
    }
  }, [location?.state?.from, params?.taskId, refetchComments, refetchData, user?.id, where.comments])

  const initView = useCallback(async () => {
    await getQuery({
      variables: {
        taskId: params?.taskId,
        userId: user?.id
      }
    })

    await queryComments({
      variables: {
        where: where.comments
      }
    })
  }, [getQuery, params?.taskId, queryComments, user?.id, where.comments])

  const [createCommentMutation, { loading: loadingCreateComment }] = useCreateCommentMutation()
  const [editTask, { loading: loadingEditTask }] = useEditTaskMutation({
    update(cache, { data: dataMutation }) {
      const editTask = dataMutation?.editTask?.task

      // Update the individual task in the cache
      const cachedTask = cache.readFragment<GetBusinessOwnerTasksQuery['user_tasks'][0]>({
        id: cache.identify({
          __typename: 'user_tasks',
          id: editTask?.id
        }),
        fragment: UserTaskFieldsFragmentDoc,
      })

      if (cachedTask) {
        cache.writeFragment({
          id: cache.identify({
            __typename: 'user_tasks',
            id: editTask?.id
          }),
          fragment: UserTaskFieldsFragmentDoc,
          data: {
            ...cachedTask,
            ...editTask,
            __typename: 'user_tasks'
          },
        })
      }
    }
  })

  const updateTask = useCallback(async (status: string) => {
    if (status && params?.taskId) {
      await editTask({
        variables: {
          id: params?.taskId,
          status,
          user_id: user?.id
        }
      })
    }
    else {
      console.error('Without params', params, dataTasks)
    }
  }, [editTask, params, dataTasks, user?.id])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateComment, { loading: loadingEdit }] = useUpdateCommentByPkMutation()

  const editComment = useCallback(async (commentId: string, newComment: string) => {
    return await updateComment({
      variables: {
        id: commentId,
        payload: {
          text: newComment
        }
      }
    })
  }, [updateComment])

  const deleteComment = useCallback(async (commentId: string) => {
    return await updateComment({
      variables: {
        id: commentId,
        payload: {
          status: 'deleted'
        }
      }
    })
  }, [updateComment])

  const createComment = useCallback(async (comment: string, attachments?: Comment.Attachment[]) => {
    if (params?.taskId && dataTasks?.user_tasks?.[0]?.created_by) {
      if (comment) {
        try {
          const res = await createCommentMutation({
            variables: {
              attachments,
              text: `<p>${comment}</p>`,
              thread_entity_id: params?.taskId,
              thread_entity_type: 'user_tasks',
              receiver_user_id: dataTasks?.user_tasks?.[0]?.created_by,
              private_note: false
            }
          })
          if (res?.data?.createComment?.comment) {
            // Why this immer hack?
            // Apollo's cache doesn't let me write because linearCommentLinked is null and yet it expects a value only because the query returns it, if I set it null or not it fails, and I can't edit the policy, inMemoryCache doesn't work either, wwwwwhhhhy
            setCommentList((draft) => {
              // @ts-ignore
              draft.available.push(res?.data?.createComment?.comment)
            })
          }
        }
        catch (err) { /* empty */ }
      }
    }
    else {
      console.error('Without params', params, dataTasks)
    }
  }, [params, dataTasks, createCommentMutation, setCommentList])

  useEffect(() => {
    void initView()
  }, [initView])

  useEffect(() => {
    setCommentList((draft) => {
      // @ts-ignore
      draft.available = dataComments?.comments
    })
  }, [dataComments, setCommentList])

  return (
    <>
      <HeaderSharedNavigation
        backUrl='/v2/tasks'
        main={<CenteredNavTitle title={t('v2.nav.tasks', 'Tasks')} />}
      />
      <TaskDetail
        author={user}
        task={dataTasks?.user_tasks?.[0]}
        comments={commentsList.available as Comments[]}
        createComment={createComment}
        editComment={editComment}
        deleteComment={deleteComment}
        updateTask={updateTask}
        loading={loadingCreateComment || loadingEditTask}
      />
    </>
  )
}
