import { Alert, AlertTitle, SxProps } from '@mui/material'

export interface BasicAlertProps {
  severity: 'error' | 'warning' | 'info' | 'success'
  title?: string
  titleJSX?: JSX.Element
  description?: string
  descriptionJSX?: JSX.Element
  sx?: SxProps
  action?: JSX.Element
  onClick?: (() => void) | undefined
}

export function BasicAlert({ onClick, severity, title, titleJSX, description, descriptionJSX, sx, action }: BasicAlertProps) {
  return (
    <>
      <Alert
        severity={severity}
        sx={sx}
        action={action}
        onClick={onClick}
      >
        <AlertTitle>
          {title ?? <>{titleJSX}</>}
        </AlertTitle>
        {description ?? <>{descriptionJSX}</>}
      </Alert>
    </>
  )
}
