import { useEffect } from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import { Avatar, Box, Grid, Link, Typography } from '@mui/material'
import { Stack } from '@mui/system'

import OwnersLogoDark from '#app/assets/svg/logo-owners-dark.svg'
import Card from '#app/components/Card/Card'
import { DividerExtended } from '#app/components/Divider'
import { EntityPage } from '#app/layouts/EntityView'
import { useGetBusinessPublicDetailsLazyQuery } from '#app/operations/businesses/businesses.queries.generated'

import { THEME } from './theme'

export const PublicPageLayout = () => {
  const { pathname } = useLocation()
  const { userId, businessId } = useParams()
  const [getBusiness, { data, loading }] = useGetBusinessPublicDetailsLazyQuery({
    variables: {
      userId,
      businessId
    }
  })

  useEffect(() => {
    if (businessId ?? userId) {
      void getBusiness()
    }
  }, [businessId, getBusiness, userId])

  const contactPhone = String(data?.getPublicBusiness?.business?.invoice_phone_number ?? data?.getPublicBusiness?.business?.call_center_phone_number ?? '')
  const contactEmail = String(data?.getPublicBusiness?.business?.company_email ?? '')

  if (pathname.startsWith('/public/b/')) {
    return <Outlet />
  }

  return (
    <EntityPage
      layout='free'
      isLoading={loading}
      backgroundColor='var(--secondary-bg-color)'
      mobilePaddings={{
        paddingTop: 0,
        paddingBottom: '1rem'
      }}
      mainContent={(
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={2}
        >

          <Grid item>
            <Card
              className='v1-card-bottom-padding'
              style={{
                maxWidth: 700
              }}
            >
              <Card.Body>
                {
                  data
                  && (
                    <Stack
                      alignItems='start'
                      spacing={3}
                    >
                      <Box sx={{
                        width: '100%'
                      }}
                      >
                        <Stack spacing={2}>
                          <Stack
                            direction='row'
                            spacing={1}
                            alignItems='center'
                          >
                            <Avatar
                              sx={{
                                width: 75,
                                height: 75,
                                bgcolor: 'white'
                              }}
                              src={data?.getPublicBusiness?.business?.logo}
                            />
                            <Typography
                              variant='h2'
                              sx={{
                                textAlign: 'center'
                              }}
                            >
                              {data?.getPublicBusiness?.business?.company_name}
                            </Typography>
                          </Stack>
                          <DividerExtended />
                          <Stack spacing={2}>
                            <Stack>
                              <Stack
                                direction={{
                                  xs: 'column',
                                  md: 'row'
                                }}
                                spacing={2}
                              >
                                {
                                  contactEmail
                                  && (
                                    <Box>
                                      <Typography
                                        variant='body2'
                                        color={THEME.COLORS.custom.grey}
                                      >
                                        Email
                                      </Typography>
                                      <Link href={`mailto:${contactEmail}`}>{contactEmail}</Link>
                                    </Box>
                                  )
                                }
                                {
                                  contactPhone
                                  && (
                                    <Box>
                                      <Typography
                                        variant='body2'
                                        color={THEME.COLORS.custom.grey}
                                      >
                                        Phone
                                      </Typography>
                                      <Link href={`tel:${contactPhone}`}>{contactPhone}</Link>
                                    </Box>
                                  )
                                }
                              </Stack>
                            </Stack>
                            {
                              data?.getPublicBusiness?.business?.business_address
                              && (
                                <Stack>
                                  <Typography
                                    variant='body2'
                                    color={THEME.COLORS.custom.grey}
                                  >
                                    Address
                                  </Typography>
                                  <Typography>{data?.getPublicBusiness?.business?.business_address}</Typography>
                                </Stack>
                              )
                            }

                            {
                              data?.getPublicBusiness?.business?.domain
                              && (
                                <Stack>
                                  <Typography
                                    variant='body2'
                                    color={THEME.COLORS.custom.grey}
                                  >
                                    Website
                                  </Typography>
                                  <Link
                                    href={data?.getPublicBusiness?.business?.domain}
                                    target='_blank'
                                  >
                                    {data?.getPublicBusiness?.business?.domain}
                                  </Link>
                                </Stack>
                              )
                            }
                          </Stack>
                          <DividerExtended />
                        </Stack>
                      </Box>
                    </Stack>
                  )
                }
                <Box sx={{
                  paddingTop: '1rem'
                }}
                >
                  <Outlet />
                </Box>
              </Card.Body>
            </Card>
          </Grid>
          <Grid item>
            <Stack
              direction='row'
              spacing={1}
            >
              <Typography
                sx={{
                  color: 'white'
                }}
                mt={0.5}
              >
                Powered by
              </Typography>
              <img
                src={OwnersLogoDark}
                style={{
                  width: '100px'
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      )}
    />
  )
}
