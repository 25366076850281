export const useLinear = () => {
  const linearBaseURL = process.env.REACT_APP_ENV === 'production'
    ? 'https://linear.app/owners'
    : process.env.REACT_APP_ENV === 'staging'
      ? 'https://linear.app/owners-staging'
      : 'https://linear.app/owners-staging'

  return {
    linearBaseURL,
    linearIssueURL: linearBaseURL + '/issue'
  }
}
