import { Timeline as MuiTimeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, timelineOppositeContentClasses, TimelineSeparator } from '@mui/lab'

interface Props {
  leftAligned?: boolean
  items: Array<{ content: JSX.Element, oppositeContent: JSX.Element, itemIdentifier?: JSX.Element }> | undefined
}

export const Timeline = ({ leftAligned = false, items = [] }: Props) => {
  let sx

  if (leftAligned) {
    sx = {
      [`& .${timelineOppositeContentClasses.root}`]: {
        flex: 0.1
      }
    }
  }

  return (
    <MuiTimeline sx={sx}>
      {
        items.map(({ oppositeContent, content, itemIdentifier }, idx: number) => {
          return (
            <TimelineItem key={idx}>
              <TimelineOppositeContent color='text.secondary'>
                { oppositeContent }
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                  { itemIdentifier ?? '' }
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                { content }
              </TimelineContent>
            </TimelineItem>
          )
        })
      }
    </MuiTimeline>
  )
}
