import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, CardContent, Stack, Typography } from '@mui/material'

import { Btn } from '#app/components/Buttons'
import { Chip } from '#app/components/Chip'
import { Dialog } from '#app/components/Dialog'
import { DividerExtended } from '#app/components/Divider'
import { useDayJs } from '#app/hooks/useDayJs'
import { useWorkOrders } from '#app/hooks/useWorkOrders'
import { THEME } from '#app/layouts/theme'
import { PmmarketplaceBidsQuery, PmmarketplaceWorkOrdersQuery } from '#app/operations/pmmarketplace/pmmarketplace.queries.generated'
import { ENVIROMENT } from '#app/store/enviroment'

interface Props {
  bid: PmmarketplaceBidsQuery['pmmarketplace_bids'][0]
  updateWorkOrder?: (payload: any) => void
  workOrder: PmmarketplaceWorkOrdersQuery['pmmarketplace_work_orders'][0] | null
  highlight?: boolean
}

export function BidDetails({ bid, updateWorkOrder, workOrder, highlight }: Props) {
  const { formatDateV2 } = useDayJs()
  const { fetchAttachments, AttachmentSequin } = useWorkOrders(bid.work_order ?? '', bid.id)
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (bid?.estimate) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      void fetchAttachments(bid?.estimate)
    }
  }, [bid, fetchAttachments])

  return (
    <Card sx={{
      mb: 4,
      mt: 4,
      backgroundColor: highlight ? THEME.COLORS.custom.green : ''
    }}
    >
      {updateWorkOrder
      && (
        <Dialog.Confirm
          open={open}
          setOpen={setOpen}
          onConfirm={() => updateWorkOrder(bid.id)}
        />
      )}
      <CardContent>
        <Stack
          sx={{
            position: 'relative'
          }}
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
        >
          <Chip.Basic
            label={bid.id}
            size='small'
          />
          <Btn.MenuButton onView={() => window.open(`${ENVIROMENT.airtable_table_bids}/${bid.id}`)} />
        </Stack>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box>
            <Typography variant='h2'>
              $
              {bid.price}
            </Typography>
            <Typography variant='subtitle1'>
              {bid.service_provider?.company_name}
              {' '}
              -
              {bid.service_provider?.first_name}
              {' '}
              {bid.service_provider?.last_name}
              {' '}
              <br />
            </Typography>
            <Typography variant='subtitle2'>
              {bid.service_provider?.phone}
              {' '}
              |
              {t('workOrders.address', 'Address')}
              :
              {bid.service_provider?.address ?? 'N/A'}
            </Typography>
            <DividerExtended />
            <Stack
              direction='row'
              mt={-2}
              spacing={1}
            >
              {
                bid.estimate
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  ? bid.estimate.map((attachmentUrl: string, key: string) => {
                    return (
                      <AttachmentSequin
                        key={key}
                        withoutPreview
                        withoutDownload
                        attachmentUrl={attachmentUrl}
                      />
                    )
                  })
                  : <></>
              }
            </Stack>
            <DividerExtended />
            <Typography
              variant='caption'
              mt={4}
            >
              {t('workOrders.additionalNotes', 'Additional notes')}
              :
              {bid.additional_notes ?? 'N/A'}
              {' '}
              <br />
              {t('workOrders.date', 'Date')}
              :
              {bid?.date ? formatDateV2(bid?.date as string) : 'N/A'}
              {' '}
              <br />
            </Typography>
          </Box>
          <Box>
            {
              updateWorkOrder
              && (
                <Btn.Button
                  disabled={workOrder?.status !== 'open'}
                  onClick={() => setOpen(true)}
                >
                  {t('workOrders.pickBidAsWinner', 'Pick as winner')}
                </Btn.Button>
              )
            }
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}
