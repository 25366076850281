import { StrictMode, useEffect } from 'react'

import { OpenReplayProvider } from './contexts/OpenReplayContext'
import { OwnersRouter } from './routes/useRoutes'

function App() {
  useEffect(() => {
    const handleRightClick = (event: MouseEvent): void => {
      event.preventDefault()
    }

    document.addEventListener('contextmenu', handleRightClick)

    return () => {
      document.removeEventListener('contextmenu', handleRightClick)
    }
  }, [])

  useEffect(() => {
    const handleIncomingMessage = (event: MessageEvent) => {
      const message = event.data

      switch (message.type) {
        case 'scrollTop': {
          window.scrollTo(0, 0)
          break
        }
        default:
          break
      }
    }

    window.addEventListener('message', handleIncomingMessage, false)

    return () => {
      window.removeEventListener('message', handleIncomingMessage, false)
    }
  }, [])

  return (
    <StrictMode>
      <OpenReplayProvider>
        <OwnersRouter />
      </OpenReplayProvider>
    </StrictMode>
  )
}

export default App
