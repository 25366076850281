import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, NavLink, Outlet, useLocation } from 'react-router-dom'
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material'
import { Box } from '@mui/system'

import { DialogConfirm } from '#app/components/Dialog/DialogConfirm'
import ProfileContext from '#app/contexts/ProfileContext'
import { useAccessibleRoutes } from '#app/routes/useRoutes'

import { useForceMobile, useIsDesktopSize } from '../../utils/mediaQueries'
import DashboardNav from '../DashboardNav/DashboardNav'
import { Navbar } from '../Navbar/Navbar'

const DashboardView = () => {
  const { t } = useTranslation()
  const { getProfile: { user } } = useContext(ProfileContext)
  const routes = useAccessibleRoutes(user)
  const location = useLocation()

  const isDesktopVal = useIsDesktopSize()
  const forceMobile = useForceMobile()
  const isDesktop = forceMobile ? false : isDesktopVal

  const [open, setOpen] = useState(false)
  const [bottomNavigationSelected, setBottomNavigationSelected] = useState(0)

  if (location.pathname === '/') {
    const defaultRoute = routes.find((r) => r.default) || routes.find((r) => r.path === '/configuring')

    if (!defaultRoute) {
      return (
        <Navigate
          replace
          to='/not-found'
        />
      )
    }
    return (
      <Navigate
        replace
        to={defaultRoute.path}
      >
      </Navigate>
    )
  }

  const currentRoute = routes.find((r) => r.path === location.pathname)

  return (
    <Box>
      { isDesktop
      && (
        <>
          <Box className='d-flex flex-row'>
            { !location.pathname.startsWith('/onboarding') && <DashboardNav routes={routes} /> }
            <Box className='flex-grow-1 v1-bg-gray'>
              <Box style={{
                height: '100vh',
                overflow: 'auto',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column'
              }}
              >
                <Box className='flex-grow-1'>
                  <Outlet />
                </Box>
                {
                  currentRoute?.bottomNavigation
                  && (
                    <Paper
                      sx={{
                        position: 'sticky',
                        bottom: 0,
                        left: 0,
                        right: 0
                      }}
                      elevation={3}
                    >
                      <BottomNavigation
                        showLabels
                        value={bottomNavigationSelected}
                      >
                        {
                          currentRoute
                            .bottomNavigation
                            .map((bottomRoute, idx) => {
                              return (
                                <BottomNavigationAction
                                  key={idx}
                                  style={({ isActive }) => {
                                    if (isActive) {
                                      setTimeout(() => {
                                        setBottomNavigationSelected(idx)
                                      })
                                    }
                                  }}
                                  component={NavLink}
                                  to={bottomRoute.path}
                                  label={bottomRoute.label}
                                  icon={bottomRoute.icon}
                                />
                              )
                            })
                        }
                      </BottomNavigation>
                    </Paper>
                  )
                }
              </Box>
            </Box>
          </Box>
        </>
      )}
      {
        !isDesktop
        && (
          <>
            {!location.pathname.startsWith('/onboarding') && <Navbar routes={routes} />}
            <div className={`${!location.pathname.startsWith('/onboarding') ? 'container-fluid' : ''}`}>
              <Outlet />
            </div>
            {
              currentRoute
                ?.bottomNavigation
                && (
                  <Paper
                    sx={{
                      position: 'fixed',
                      bottom: 0,
                      left: 0,
                      right: 0
                    }}
                    elevation={3}
                  >
                    <BottomNavigation
                      showLabels
                      value={bottomNavigationSelected}
                    >
                      {
                        currentRoute
                          .bottomNavigation
                          .map((bottomRoute, idx) => {
                            return (
                              <BottomNavigationAction
                                key={idx}
                                style={({ isActive }) => {
                                  if (isActive) {
                                    setTimeout(() => {
                                      setBottomNavigationSelected(idx)
                                    })
                                  }
                                }}
                                component={NavLink}
                                to={bottomRoute.path}
                                label={bottomRoute.label}
                                icon={bottomRoute.icon}
                              />
                            )
                          })
                      }
                    </BottomNavigation>
                  </Paper>
                )
            }
            <DialogConfirm
              open={open}
              setOpen={setOpen}
              texts={{
                title: t('PWA.popup.title'),
                confirm: t('PWA.popup.confirmLabel'),
                cancel: t('PWA.popup.cancelLabel'),
                description: t('PWA.popup.description')
              }}
              onClose={() => {
                localStorage.setItem('promptPWA', 'true')
              }}
              onConfirm={() => {
                localStorage.setItem('promptPWA', 'true')
              }}
            />
          </>
        )
      }
    </Box>
  )
}

export default DashboardView
