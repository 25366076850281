import { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BsArrowClockwise } from 'react-icons/bs'
import { Navigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { Grid, Stack, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { Btn } from '#app/components/Buttons'
import Card from '#app/components/Card/Card'
import ProfileContext from '#app/contexts/ProfileContext'
import { EntityPage } from '#app/layouts/EntityView'
import { useBankingOnboardMutation, useBankingSignupMutation } from '#app/operations/banking/banking.mutations.generated'
import { useBankingBottomSection } from '#app/store/banking-bottom-sections'
import { User } from '#app/types'
import { GET_PROFILE, GET_PROFILE_TYPE } from '#app/utils/graphqlQueries'

const BankingSignupCallToAction = ({ user, onRedirect }: { user?: User.UserType, onRedirect?: () => void }) => {
  const [bankingSignUp, { loading: signupLoading }] = useBankingSignupMutation()
  const [bankingOnboard, { loading: onboardLoading }] = useBankingOnboardMutation()
  const [isLoading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    setLoading(Boolean(signupLoading || onboardLoading))
  }, [signupLoading, onboardLoading])

  const callSignup = () => {
    void bankingSignUp(
      {
        variables: {
          id: user?.id ?? ''
        }
      })
      .then(async () => {
        return await bankingOnboard(
          {
            variables: {
              id: user?.id ?? ''
            }
          })
      })
      .then((result) => {
        const url = result.data?.createBankingOnboard?.url
        if (url) {
          if (onRedirect) {
            onRedirect()
          }

          window.location.replace(url)
        }
      })
  }

  return (
    <Card>
      <Card.Body className='v1-card-bottom-padding'>
        <Grid
          container
          direction='column'
          spacing={3}
        >
          <Grid item>
            <Typography variant='h6'>{t('bankingSignupCallToAction.signup', 'Banking Signup')}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              <Trans
                t={t}
                i18nKey='bankingSignupCallToAction.signupText'
              >
                Sign up to get access to our payments and banking solution. Collect payments on the go, get payouts instantly, and get Cashback on your Owners debit card.
                <br />
                <br />
                Banking services provided by Celtic Bank; Member FDIC. The Owners Visa® Debit Card is issued by Celtic Bank. Pursuant to a license from Visa U.S.A. Inc. and may be used everywhere Visa debit cards are accepted.
                <br />
                <br />
                Give choices to your customers: Let your customers pay through their preferred payment medium like Apple Pay, Google Pay, PayPal, Bank transfer, credit cards, and debit cards of Visa, Mastercard, American Express, Diners Club, etc. Your customers don’t need to download any app or sign in. And you don’t need to integrate with any payment method. The Owners' app has you completely covered.
                <br />
                <br />
                Instant Payouts: Now get your payment payouts instantly into your Owners banking account. No more waiting 2-3 business days for your payment payout to reach your bank account.
              </Trans>
            </Typography>
          </Grid>
          <Grid item>
            <Btn.Button
              isLoading={isLoading}
              disabled={isLoading}
              onClick={callSignup}
            >
              {t('bankingSignupCallToAction.signupButton', 'Sign-up')}
            </Btn.Button>
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  )
}

const BankingOnboardingCallToAction = ({ user, refetchUser, onRedirect }: { user?: User.UserType, refetchUser: () => void, onRedirect?: () => void }) => {
  const [bankingOnboard, { loading: isLoading }] = useBankingOnboardMutation()
  const { t } = useTranslation()

  const callOnboard = () => {
    void bankingOnboard(
      {
        variables: {
          id: user?.id ?? ''
        }
      })
      .then((result) => {
        const url = result.data?.createBankingOnboard?.url
        if (url) {
          if (onRedirect) {
            onRedirect()
          }
          window.location.replace(url)
        }
        // if no url the user is complete
        return refetchUser()
      })
  }

  return (
    <Card>
      <Card.Body className='v1-card-bottom-padding'>
        <Grid
          container
          direction='column'
          spacing={3}
        >
          <Grid item>
            <Typography variant='h6'>{ t('bankingOnboardingCallToAction.header', 'Onboarding') }</Typography>
          </Grid>
          <Grid item>
            <Typography>
              <Trans
                t={t}
                i18nKey='bankingOnboardingCallToAction.onboardingText'
              >
                Tell us more about your business
                <br />
                <br />
                To give you access to our amazing payments and banking solution, we need to collect information to better serve your business and help us meet the requirements of regulators and financial partners.
              </Trans>
            </Typography>
          </Grid>
          <Grid item>
            <Btn.Button
              isLoading={isLoading}
              disabled={isLoading}
              onClick={callOnboard}
            >
              { t('bankingOnboardingCallToAction.button', 'Continue onboard')}
            </Btn.Button>
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  )
}

const BankingVerified = ({ user }: { user?: User.UserType }) => {
  const { t } = useTranslation()

  return (
    <Card>
      <Card.Body className='v1-card-bottom-padding'>
        <Grid
          container
          direction='column'
          spacing={3}
        >
          <Grid item>
            <Typography variant='h6'>{t('bankingVerified.header', 'Details Verified')}</Typography>
          </Grid>
          <Grid item>
            <Stack spacing={3}>
              <Typography>
                <Trans
                  t={t}
                  i18nKey='bankingVerified.verificationText'
                >
                  Thank You for your patience as we strive to give you the best payments and banking for Small Local Businesses.
                  <br />
                  <br />
                  We will review your application in the next 4-5 business days.
                </Trans>
              </Typography>
              <Stack
                spacing={1}
                sx={{
                  maxWidth: 500
                }}
              >
                <Btn.Button
                  startIcon={<BsArrowClockwise />}
                  onClick={
                    () => window.location.reload()
                  }
                >
                  { t('bankingVerified.button', 'Check if I\'m ready')}
                </Btn.Button>
                <Typography
                  variant='caption'
                  align='center'
                >
                  <Trans
                    t={t}
                    i18nKey='bankingVerified.buttonHelper'
                  >
                    Is your verification taking longer than expected?
                    <br />
                    Click on the button above
                  </Trans>
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  )
}

export const Onboarding = () => {
  const { t } = useTranslation()
  const { getProfile: { user } } = useContext(ProfileContext)
  const [userData, setUser] = useState<User.UserType | undefined>(user)
  const { setSelectedSection, ...section } = useBankingBottomSection((state) => state)

  const [,{ refetch: refetchUserFromServer }] = useLazyQuery<GET_PROFILE_TYPE>(GET_PROFILE)

  const [isRedirectOpen, setRedirectOpen] = useState<boolean>(false)

  const refetchUser = async () => {
    const results = await refetchUserFromServer()
    setUser(results.data?.getProfile.user)
  }

  if (userData?.stripe_connected_id && userData?.stripe_banking_status === 'open') {
    const nextSection = section.url ?? '/payments/link'
    return (
      <Navigate
        replace
        to={nextSection}
      />
    )
  }

  return (
    <EntityPage
      isLoading={false}
      layout='free'
      pageTitle={t('banking.onboarding.pageTitle', 'Onboarding')}
      mainContent={(
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            {
              !userData?.stripe_connected_id && (
                <BankingSignupCallToAction
                  user={userData}
                  onRedirect={() => setRedirectOpen(true)}
                />
              )
            }
            {
              userData?.stripe_connected_id && userData?.stripe_banking_status === 'onboarding' && (
                <BankingOnboardingCallToAction
                  refetchUser={refetchUser}
                  user={userData}
                  onRedirect={() => setRedirectOpen(true)}
                />
              )
            }
            {
              userData?.stripe_connected_id && userData?.stripe_banking_status === 'verified' && <BankingVerified />
            }
          </Grid>
          <Dialog open={isRedirectOpen}>
            <DialogTitle>{t('bankingRedirect.dialog.title', 'We are preparing your onboard experience')}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('bankingRedirect.dialog.description', 'Depending on your internet connection this process may take a bit, please wait...')}
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Grid>
      )}
    />
  )
}
