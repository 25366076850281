import { gql } from '@apollo/client'

import { WizardState } from '#app/routes/Admin/Wizard/CreateWizard'
import { CustomerInput } from '#app/types/customer'
import { JSONB } from '#app/types/generic'
import { BusinessWithJobsQueryResult, JobsQueryResult } from '#app/types/job'

import { Businesses, Comment, Sequence, Task, User } from '../types'

export interface GET_USERS_TYPE {
  users: User.UserType[]
  roles: User.RolesType[]
}
export const GET_USERS = gql`
query GetUsers($query: String, $limit: Int, $currentUserEmail: String!) {
  roles {
    id
    name
  }
  users(limit: $limit, where: {
    search: { _ilike: $query },
    role: {name: {_eq: "Admin"} },
    email: { _neq: $currentUserEmail },
    status: { _neq: "deleted" }
    }, order_by: {created_at: desc}) {
    first_name
    last_name
    email
    phone
    id
    sync_calendar
    teams
    role {
      name
      id
    }
  }
}
`

export const GET_BUSINESSES = gql`
  query GetBusinesses($limit: Int, $where: businesses_bool_exp) {
    businesses(limit: $limit, where: $where, order_by: { created_at: desc }) {
      cities
      services
      id
      status
      onboarding
      stripe_payment_link
      signup_fee
      subscriptions {
        plan
        status
      }
      user {
        first_name
        last_name
        email
        phone
        id
        profile_picture_url
        status
        created_at
        updated_at
        language
        blocked
        sync_calendar
        role {
          name
          id
        }
      }
    }
  }
`

export interface GET_BUSINESS_TYPE {
  businesses: Businesses.Businesses[]
}

export interface GET_WIZARD_TEMPLATES_TYPE {
  wizard_templates: WizardState[]
}

export interface GET_USER_WIZARD_TYPE {
  editUserWizardProgress: WizardState[]
}

export const GET_OWNERS = gql`
query GetOwners($query: String, $limit: Int) {
  roles {
    id
    name
  }
  users(limit: $limit, where: { _or: [{email: {_ilike: $query}}, { first_name: {_ilike: $query }}, { last_name: {_ilike: $query }}], role: { name: { _eq: "User" } }, status: { _neq: "deleted" } }, order_by: {created_at: desc}) {
    first_name
    last_name
    email
    phone
    id
    profile_picture_url
    status
    created_at
    updated_at
    language
    sync_calendar
    role {
      name
      id
    }
    businesses {
      id
      status
      onboarding
      stripe_payment_link
      signup_fee
    }
  }
}
`

export interface GET_OWNERS_TYPE {
  users: User.UserType[]
  roles: User.RolesType[]
}

export const GET_OWNER = gql`
query getOwner($id: uuid!) {
  users(where: { id: { _eq: $id } }) {
    first_name
    email
    id
    profile_picture_url
    status
    sync_calendar
    role {
      name
      id
    }
    businesses {
      id
      status
      onboarding
      stripe_payment_link
      signup_fee
    }
  }
}
`

export const ADD_USER = gql`
    mutation createUser($email: String!, $first_name: String!, $last_name: String!, $phone: String!, $role_id: String!, $password: String) {
      createUser(input: { email: $email, first_name: $first_name, last_name: $last_name, role_id: $role_id, password: $password, phone: $phone }) {
        user{
          first_name
          last_name
          phone
          email
        }
      }
    }
  `

export const ADD_OWNER = gql`
    mutation createUser($email: String!, $signup_fee: String!) {
      createOwner(input: { email: $email, signup_fee: $signup_fee }) {
        owner {
          id
          email
          status
          role {
            id
            slug
          },
          businesses {
            id
            status
            stripe_payment_link
          }
        }
      }
    }
  `

export const UPDATE_USER_PREFERENCES = gql`
  mutation editUser( $id: ID!, $language: String) {
    editUser(input: { id:$id , language: $language}) {
      user{
        id
        language
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation editUser( $first_name: String! , $last_name: String!, $phone: String, $id: ID!) {
    editUser(input: { first_name: $first_name, last_name: $last_name, phone: $phone, id:$id }) {
      user{
        first_name
        last_name
        phone
        email
      }
    }
  }
`

export const DELETE_USER = gql`
mutation deleteUser($id: ID!) {
  deleteUser(input: {id:$id }) {
    result
  }
}
`

export interface GET_PROFILE_TYPE {
  getProfile: {
    user?: User.UserType
    // ['business_address', 'call_center_phone_number', 'website_status', 'status', 't_shirt_size', 'services', 'state', 'service_areas', 'phone_number', 'personal_address', 'marketing_material_status', 'logo_status', 'logo', 'plan', 'liability_insurance_status', 'legal_entitystatus', 'legal_entity', 'legal_company_name', 'last_name', 'google_my_business_url', 'google_my_business_status', 'google_local_services_status', 'google_local_services_account_number', 'first_name', 'file_storage_folder', 'facebook_status', 'facebook_profile_url', 'employer_identificationnumber', 'email', 'domain', 'domain_status', 'date_of_birth', 'country_of_origin', 'company_name', 'company_email_status', 'company_email', 'comain', 'city', 'certificate_of_insurance', 'category', 'call_center_status', 'company_search_id', 'company_id']
    altBoProfile?: JSONB
  }
  roles: User.RolesType[]
}

export const GET_PROFILE = gql`
    query($userId: uuid) {
      getProfile(input: { user_id: $userId }) {
        user {
          id
          first_name
          last_name
          profile_picture_url
          email
          phone
          status
          blocked
          stripe_connected_id
          stripe_banking_status
          stripe_connected_acc
          created_at
          cal_email
          cal_hashed_password
          sync_calendar
          linked_phones
          role {
            slug
          }
          businesses {
            status
            stripe_payment_link
            subscriptions {
              id
              status
              fee
            }
            onboarding
            id
          }
          language
        }
        altBoProfile
      }
      roles {
        id
        slug
        name
      }
    }`
export const GET_WIZARD_TEMPLATES = gql`
  query getWizardTemplates {
    wizard_templates(where: {status: {_neq: "deleted"}}) {
      status
      subtitle
      title
      wizard_flow
      id
    }
  }`

export const GET_USER_WIZARD = gql`
query getUserWizardById($userId: uuid!, $wizardId: uuid!, $taskId: uuid) {
  getUserWizardById(input: { userId: $userId, wizardId: $wizardId, taskId: $taskId  }) {
    result
    wizard
  }
}`

export const CREATE_CHECKOUT_SESSION = gql`
mutation createCheckoutSession {
  createCheckoutSession {
    url
  }
}`

export const EDIT_ONBOARDING = gql`
mutation editBusiness($id: ID!, $onboarding: jsonb!) {
  editBusiness( input: { id: $id, onboarding: $onboarding}) {
    business {
      id
      user {
        id
        email
      }
      onboarding
    }
  }
}
`

export const CHANGE_ONBOARDING_STATUS = gql`
mutation editBusiness($id: ID!, $status: String!) {
  editBusiness( input: { id: $id, status: $status}) {
    business {
      id
      user {
        id
        email
      }
      status
    }
  }
}
`

export const CREATE_SUBSCRIPTION = gql`
mutation createSubscription(
    $first_name: String!
    $last_name: String!
    $email: String!
    $cities: jsonb!
    $language: String!
    $services: String!
    $plans: [SignupPlan]!
    $phone: String!
    $start_plan_fee: Int
    $grow_plan_fee: Int
    $grow_plan_start_date: Int
  ) {
  createSubscription(input: { 
    first_name: $first_name
    last_name: $last_name
    email: $email
    cities: $cities
    language: $language
    services: $services
    plans: $plans
    phone: $phone
    grow_plan_start_date: $grow_plan_start_date, 
    grow_plan_fee: $grow_plan_fee, 
    start_plan_fee: $start_plan_fee
  }) {
    result
  }
}`

export const LINK_PHONE = gql`
mutation linkPhone(
    $phone: String!
    $email: String!
  ) {
  linkPhone(input: { 
    phone: $phone
    email: $email
  }) {
    result
  }
}`

const getJobs = `
  jobs(order_by: { created_at: desc }, limit: 10) {
    business_id
    created_at
    customer_id
    customer {
      id
      email
      first_name
      last_name
      lead
      phone
    }
    job_histories(limit: 30, order_by: { created_at: desc }) {
      created_at
      end_status
      start_status
      metadata
    }
    description
    id
    source
    status
    user_id
  }
`

export interface QUERY_GET_CUSTOMERS {
  customers: CustomerInput[]
}

export interface QUERY_GET_JOBS {
  jobs: JobsQueryResult[]
}

export interface QUERY_GET_BUSINESSES_WITH_JOBS {
  businesses: BusinessWithJobsQueryResult[]
}

export const fields = {
  users: `
    id
    first_name
    last_name
    profile_picture_url
    email
    status
    blocked
    language
    sync_calendar
  `,
  usersLimited: `
    id
    first_name
    last_name
    profile_picture_url
    email
  `,
  audience: `
    conditions
    created_at
    created_by
    id
    metadata
    status
    title
    updated_at
    updated_by
  `,
  linearIssues: `
  original_linear_issue_id
  title
  description
  number
  `,
  get tasksLimited() {
    return `
      id
      status
      title
      metadata
      description_html
      response_html
      short_description
      loom_videos
      cognitoforms_key
      cognitoforms_dataform
      cognitoforms_response
      user_id
      bo_id
      response_at
      created_at
      updated_at
      created_by
      updated_by
      deleted
      tag_slug
      linear_issue_id
    `
  },
  get tasks() {
    return `
      ${this.tasksLimited}
      userByCreatedBy {
        ${this.usersLimited}
      }
      userByBo {
        ${this.usersLimited}
      }
      userByUpdatedBy {
        ${this.usersLimited}
      }
      linearIssueLinked {
        ${this.linearIssues}
      }
    `
  },
  get comments() {
    return `
      created_at
      created_by
      updated_by
      updated_at
      thread_entity_type
      thread_entity_id
      text
      status
      receiver_user_id
      parent_comment_id
      metadata
      id
      attachments
      private_note
      linear_comment_id
      userByCreatedBy {
        ${this.usersLimited}
      }
    `
  },
  get taskTemplates() {
    return `
      id
      title
      content
      status
      metadata
      tag_slug
      created_by
      created_at
      updated_by
      updated_at
      userByCreatedBy {
        ${this.usersLimited}
      }
      userByUpdatedBy {
        ${this.usersLimited}
      }
    `
  },
  get sequences() {
    return `
      id
      updated_by
      updated_at
      type
      title
      status
      payload
      metadata
      created_by
      created_at
      audience_id
      audience {
        ${this.audience}
      }
    `
  },
  get sequenceExecutions() {
    return `
      id
      created_by
      payload
      metadata
      status
      sequence_id
      type
      updated_at
      updated_by
      created_at
    `
  }
}

export interface QUERY_GET_USER_TASK {
  user_tasks: Task.UserTask[]
}

export interface QUERY_GET_PENDING_USER_TASK {
  v_pending_user_tasks: Task.UserTask[]
}
export interface QUERY_GET_COMMENTS {
  comments: Comment.CommentType[]
}

export interface QUERY_GET_TASK_TEMPLATES {
  task_templates: Task.Template[]
}

export interface QUERY_GET_SEQUENCES {
  sequences: Sequence.SequenceType[]
}

export interface QUERY_GET_SEQUENCE_EXECUTIONS {
  sequence_executions: Sequence.SequenceExecution[]
}

export interface QUERY_GET_AUDIENCES {
  audiences: Sequence.Audience[]
}

export const QUERY = {
  audiences: {
    getAudiences: gql`
    query getAudiences {
        audiences(order_by: {created_at: desc}) {
          ${fields.audience}
        }
      }
    `
  },
  sequences: {
    getSequences: gql`
      query getSequences($type: String) {
        sequences(
          where: {
            type: {_eq: $type}
          }, order_by: {created_at: desc}) {
          ${fields.sequences}
        }
      }
    `
  },
  sequenceExecutions: {
    getSequenceExecutions: gql`
      query getSequenceExecutions($sequence_id: uuid) {
        sequence_executions(
          where: {
            sequence_id: {_eq: $sequence_id}
          }, order_by: {created_at: desc}) {
          ${fields.sequenceExecutions}
        }
      }
    `
  },
  comments: {
    getComments: gql`
    query getComments($entityId: uuid, $entityType: String, $privateNotesIn: [Boolean!]) {
      comments(where: {
        thread_entity_id: {_eq: $entityId},
        _and: [
          {thread_entity_type: {_eq: $entityType}},
          {status: {_neq: "deleted"}},
          {private_note: {_in: $privateNotesIn}}
        ]
      }) {
        ${fields.comments}
        linearCommentLinked{
          original_linear_comment_id
          original_linear_issue_id
          user
        }
      }
    }
    `
  },
  tasks: {
    getUserTask: gql`
    query getUserTasks($taskId: uuid) {
      user_tasks(
        where: {
          id: {_eq: $taskId}
        }, order_by: {created_at: desc}) {
        ${fields.tasks}
      }
    }
  `,
    getUserTasks: gql`
    query getUserTasks($where: user_tasks_bool_exp) {
      user_tasks(
        where: $where,
        order_by: {created_at: desc}) {
        ${fields.tasks}
      }
    }
  `,
    getUserTasksAsAdmin: gql`
    query getUserTasks($userID: uuid, $filter: [String!] = []) {
      user_tasks(
        where: {
         bo_id: {_eq: $userID},
         status: {_nin: $filter}
        }, order_by: {created_at: desc}) {
        ${fields.tasks}
      }
    }
  `,
    getUserTasksReplied: gql`
  query getUserTasks($where: user_tasks_bool_exp){
    user_tasks(limit: 30, where: $where, order_by: {created_at: asc}) {
      ${fields.tasks}
    }
  }
`,
    getUserTasksPending: gql`
    query v_pending_user_tasks($where: v_pending_user_tasks_bool_exp){
      v_pending_user_tasks(limit: 20, where: $where, order_by: {created_at: asc}) {
        ${fields.tasks}
      }
    }
  `
  },
  customers: {
    getCustomers: gql`
      query getCustomers($query: String, $limit: Int) {
        customers(
            limit: $limit, 
            where: {
              status: { _neq: "deleted" },
              _or: [{email: {_ilike: $query}}, { first_name: {_ilike: $query }}, { last_name: {_ilike: $query }}, { phone: {_ilike: $query }}]
            }, 
            order_by: { created_at: desc }
          ) {
          id
          first_name
          last_name
          email
          phone
          address
          lead
          user_id
          business_id
          customer_histories(limit: 20, order_by: { created_at: desc }) {
            created_at
            status
            metadata
          }
          jobs(order_by: { created_at: desc }, limit: 10) {
            business_id
            created_at
            customer_id
            customer {
              id
              email
              first_name
              last_name
              lead
              phone
            }
            job_histories(limit: 30, order_by: { created_at: desc }) {
              created_at
              end_status
              start_status
              metadata
            }
            description
            id
            source
            status
            user_id
          }
        }
      }`,
    getCustomer: gql`
      query getCustomers($id: uuid!) {
        customers( 
            where: {
              id: { _eq: $id },
            }
          ) {
          id
          first_name
          last_name
          email
          phone
          address
          lead
          user_id
          business_id
          customer_histories(limit: 20, order_by: { created_at: desc }) {
            created_at
            status
            metadata
          }
          jobs(order_by: { created_at: desc }, limit: 10) {
            business_id
            created_at
            customer_id
            customer {
              id
              email
              first_name
              last_name
              lead
              phone
            }
            job_histories(limit: 30, order_by: { created_at: desc }) {
              created_at
              end_status
              start_status
              metadata
            }
            description
            id
            source
            status
            user_id
          }
        }
      }`
  },
  tasksTemplates: {
    getTemplates: gql`
      query getTemplates{
        task_templates{
          ${fields.taskTemplates}
        }
      }
    `
  },
  jobs: {
    getBusinesses: gql`
      query GetBusinesses($where: businesses_bool_exp!, $limit: Int!) {
        businesses(limit: $limit, where: $where, order_by: { created_at: desc }) {
          id
          name
          status
          user {
            id
            first_name
            last_name
            language
            email
            phone
          }
        }
      }
    `,
    getJobs: gql`
    query GetJobs($where: jobs_bool_exp!, $limit: Int!) {
      jobs(limit: $limit, where: $where, order_by: { created_at: desc }) {
        business_id
        created_at
        customer_id
        customer {
          id
          email
          first_name
          last_name
          lead
          phone
        }
        business {
          id
          name
          status
        }
        user {
          id
          first_name
          last_name
          language
          email
          phone
        }
        job_histories {
          created_at
          end_status
          start_status
          metadata
        }
        description
        id
        source
        status
        user_id
      }
    }
    `,
    getJobsWithHistory: gql`
    query GetJobsWithHistory($where: jobs_bool_exp!, $limit: Int!) {
      jobs(limit: $limit, where: $where, order_by: { created_at: desc }) {
        business_id
        created_at
        customer_id
        customer {
          id
          email
          first_name
          last_name
          lead
          phone
        }
        business {
          id
          name
          status
        }
        user {
          id
          first_name
          last_name
          language
          email
          phone
        }
        job_histories {
          created_at
          end_status
          start_status
          metadata
        }
        description
        id
        source
        status
        user_id
      }
    }
    `,
    getBusinessesWithJobs: gql`
      query GetBusinesses($where: businesses_bool_exp!, $limit: Int!) {
        businesses(limit: $limit, where: $where, order_by: { created_at: desc }) {
          id
          name
          status
          user {
            id
            first_name
            last_name
            language
            email
            phone
          }
          ${getJobs}
        }
      }`
  }
}

export const MUTATION = {
  customers: {
    createCustomer: gql`
      mutation createCustomer($email: String, $first_name: String, $last_name: String, $phone: String, $address: jsonb, $lead: String, $user_id: String!, $business_id: String!) {
        createCustomer(input: { email: $email, first_name: $first_name, last_name: $last_name, phone: $phone, address: $address, lead: $lead, user_id: $user_id, business_id: $business_id}) {
          customer {
            id
            first_name
            last_name
            email
            phone
            address
            lead
            user_id
            business_id
          }
        }
      }`,
    updateCustomer: gql`
      mutation updateCustomer($id: uuid!, $email: String, $first_name: String, $last_name: String, $phone: String, $address: jsonb, $lead: String, $user_id: String!, $business_id: String!) {
        updateCustomer(input: { id: $id, email: $email, first_name: $first_name, last_name: $last_name, phone: $phone, address: $address, lead: $lead, user_id: $user_id, business_id: $business_id}) {
          customer {
            id
            first_name
            last_name
            email
            phone
            address
            lead
            user_id
            business_id
          }
        }
      }`,
    deleteCustomer: gql`
      mutation deleteCustomer($id: ID!) {
        deleteCustomer(input: { id: $id }) {
          customer { 
            id
            first_name
            last_name
            email
            phone
            address
            lead
            user_id
            business_id
          }
        }
      }
    `,
    createPublicCustomer: gql`
    mutation createPublicCustomer($email: String, $first_name: String, $last_name: String, $phone: String, $address: jsonb, $lead: String, $user_id: String!, $business_id: String) {
      createPublicCustomer(input: { email: $email, first_name: $first_name, last_name: $last_name, phone: $phone, address: $address, lead: $lead, user_id: $user_id, business_id: $business_id}) {
       result
      }
    }`
  },
  tasks: {
    deleteUserTask: gql`
      mutation deleteUserTask(
        $id: ID!
      ) {
        deleteUserTask( input: { id: $id }){
          task {
            ${fields.tasks}
          }
        }
      }
    `,
    replyUserTask: gql`
      mutation replyUserTask(
        $id: ID!
        $is_draft: Boolean!
        $response_html: String
        $cognitoforms_response: jsonb
        ) {
        replyUserTask( input: {
          id: $id,
          is_draft: $is_draft,
          response_html: $response_html,
          cognitoforms_response: $cognitoforms_response,
          }) {
          task {
            ${fields.tasks}
          }
        }
      }
    `,
    createUserTask: gql`
      mutation createUserTask(
        $title: String
        $description_html: String
        $short_description: String
        $loom_videos: [String]
        $user_id: String!
        $bo_id: ID!
        $cognitoforms_key: String,
        $cognitoforms_dataform: String,
        $metadata: jsonb,
        $tag_slug: String
        ) {
        createUserTask( input: {
          title: $title,
          description_html: $description_html,
          short_description: $short_description,
          loom_videos: $loom_videos,
          user_id: $user_id,
          bo_id: $bo_id,
          cognitoforms_key: $cognitoforms_key,
          cognitoforms_dataform: $cognitoforms_dataform,
          metadata: $metadata,
          tag_slug: $tag_slug
          }) {
          task {
            ${fields.tasks}
          }
        }
      }
    `
  },
  comments: {
    deleteComment: gql`
      mutation deleteComment($id: ID!){
        deleteComment(input: { id: $id }){
          comment {
            ${fields.comments}
          }
        }
      }
    `,
    createComment: gql`
      mutation createComment(
        $text: String!
        $receiver_user_id: ID
        $thread_entity_id: ID!
        $thread_entity_type: String!
        $side_effect: String
        $attachments: jsonb
        $private_note: Boolean
        $metadata: jsonb
      ) {
        createComment(input: {
          text: $text,
          receiver_user_id: $receiver_user_id
          thread_entity_id: $thread_entity_id
          thread_entity_type: $thread_entity_type
          side_effect: $side_effect
          attachments: $attachments,
          private_note: $private_note
          metadata: $metadata
        }) {
          comment {
            ${fields.comments}
          }
        }
      }
    `
  },
  taskTemplates: {
    createTaskTemplate: gql`
    mutation createTaskTemplate($content: jsonb!, $title: String!, $tag_slug: String) {
        createTaskTemplate(input: {content: $content, title: $title, tag_slug: $tag_slug}) {
          taskTemplate {
            ${fields.taskTemplates}
          }
        }
      }
    `,
    deleteTaskTemplate: gql`
    mutation deleteTaskTemplate($id: ID!) {
        deleteTaskTemplate(input: {id: $id}) {
          taskTemplate {
            ${fields.taskTemplates}
          }
        }
      }
    `
  },
  jobs: {
    createJob: gql`
    mutation CreateJob($business_id: uuid!, $customer_id: uuid!, $user_id: uuid!, $status: String!, $description: String!) {
      createJob(input: { business_id: $business_id, customer_id: $customer_id, user_id: $user_id, status: $status, description: $description }) {
        job {
          business_id
          created_at
          customer_id
          customer {
            id
            email
            first_name
            lead
            last_name
            phone
          }
          job_histories {
            created_at
            end_status
            start_status
            metadata
          }
          description
          id
          source
          status
          user_id
        }
      }
    }
    `,
    editJob: gql`
    mutation EditJob($id: uuid!, $business_id: uuid!, $customer_id: uuid!, $user_id: uuid!, $status: String, $description: String!, $metadata: jsonb) {
      editJob(input: { id: $id, business_id: $business_id, customer_id: $customer_id, user_id: $user_id, status: $status, description: $description, metadata: $metadata }) {
        job {
          business_id
          created_at
          customer_id
          customer {
            id
            email
            first_name
            lead
            last_name
            phone
          }
          job_histories {
            created_at
            end_status
            start_status
            metadata
          }
          description
          id
          source
          status
          user_id
        }
      }
    }
    `
  },
  sequences: {
    createSequence: gql`
      mutation createSequence($payload: jsonb!, $title: String!, $type: String!, $audience_id: ID, $audience_conditions: jsonb) {
        createSequence(input: {payload: $payload, title: $title, type: $type, audience_id: $audience_id, audience_conditions: $audience_conditions}) {
          sequence {
            ${fields.sequences}
          }
        }
      }
    `
  },
  audiences: {
    createAudience: gql`
      mutation createAudience($title: String!, $conditions: jsonb!){
        createAudience(input: { title: $title, conditions: $conditions}){
          audience {
            ${fields.audience}
          }
        }
      }
    `
  }
}
