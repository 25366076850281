import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getApolloContext, gql, useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { excludeGraphQLFetch } from 'apollo-link-sentry'
import { StreamChat } from 'stream-chat'

import { useAppContext } from '#app/contexts/AppContext/useAppContext'
import { useGetActivityCenterLazyQuery } from '#app/operations/activitycenter/activitycenter.queries.generated'
import { useCreateUserTokenMutation } from '#app/operations/usertoken/usertoken.mutations.generated'
import { useStoreUser } from '#app/store/user'
import { enviromentDomain } from '#app/utils/vanilla/environmentDomain'
import { filters } from '#app/v2/pages/ActivityCenter/types'
import { useConnectStream } from '#app/v2-core/hooks/useConnectStream'
import { getLastWeek } from '#app/v2-core/utils/dates'

import { useUserPreferences } from '../hooks/useUserPreferences'
import { GET_PROFILE, GET_PROFILE_TYPE } from '../utils/graphqlQueries'

import AppContext from './AppContext'
import OpenReplayContext from './OpenReplayContext'

const ProfileContext = createContext<GET_PROFILE_TYPE>({
  getProfile: {},
  roles: []
})

ProfileContext.displayName = 'UserProfile'

interface Props {
  children: JSX.Element
}

export const ProfileProvider = ({ children }: Props) => {
  const { client } = useContext(getApolloContext())
  const { appState } = useContext(AppContext)
  const { V1_SET_ERROR } = useAppContext()
  const { tracker: openReplayTracker } = useContext(OpenReplayContext)
  const { loginWithRedirect } = useAuth0()

  const { data, loading, error } = useQuery<GET_PROFILE_TYPE>(GET_PROFILE)
  const { refreshLanguage } = useUserPreferences()

  // Expo Mobile
  const [createToken] = useCreateUserTokenMutation()
  const [lastToken, setLastToken] = useState<string>('')
  const location = useLocation()

  const { setUnreadMessages, setUnreadNotifications } = useStoreUser()

  const [getActivities, { data: activities }] = useGetActivityCenterLazyQuery({
    variables: {
      userId: data?.getProfile?.user?.id,
      category: filters,
      lastWeek: getLastWeek(),
      read: [false, true]
    }
  })

  useEffect(() => {
    if (data?.getProfile?.user?.id) {
      void getActivities()
    }
  }, [data?.getProfile?.user?.id, getActivities])

  useEffect(() => {
    if (activities?.activities) {
      setUnreadNotifications(activities.activities)
    }
  }, [activities?.activities, setUnreadNotifications])

  const { client: chatClient } = useConnectStream(data?.getProfile?.user?.id)
  const messageNewHandler = useRef<ReturnType<StreamChat['on']>>()
  const markReadHandler = useRef<ReturnType<StreamChat['on']>>()

  useEffect(() => {
    chatClient?.getUnreadCount().then((res) => {
      setUnreadMessages(res.total_unread_count)

      if (activities?.activities) {
        setUnreadNotifications(activities.activities, res.total_unread_count)
      }
    })

    messageNewHandler.current = chatClient?.on('notification.message_new', (event) => {
      if (event.total_unread_count != undefined) {
        setUnreadMessages(event.total_unread_count)
      }
    })

    markReadHandler.current = chatClient?.on('notification.mark_read', (event) => {
      if (event.total_unread_count != undefined) {
        setUnreadMessages(event.total_unread_count)
      }
    })

    return () => {
      messageNewHandler.current?.unsubscribe()
      markReadHandler.current?.unsubscribe()
    }
  }, [chatClient, setUnreadMessages, activities?.activities, setUnreadNotifications])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const expoPushToken = searchParams.get('expoPushToken')
    const platform = searchParams.get('platform') ?? 'unknown'

    if (expoPushToken && expoPushToken !== lastToken) {
      void createToken({
        variables: {
          token: expoPushToken,
          type: 'expo-push-token',
          rawData: {
            platform,
            expoPushToken
          }
        }
      })
      setLastToken(expoPushToken)
    }
  }, [location, lastToken, createToken])

  useEffect(() => {
    if ((data?.getProfile?.user) != null) {
      refreshLanguage(data.getProfile.user)
      // @ts-ignore
    }

    if (!data?.getProfile?.user && !appState?.isLoading) {
      V1_SET_ERROR('No user found in the database')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, refreshLanguage])

  useEffect(() => {
    if (!loading && data) {
      const { getProfile: { user } } = data
      try {
        if (user?.email && user?.id) {
          openReplayTracker?.setUserID(user.email)

          const firstName = (user?.first_name ?? 'not_found')
          const lastName = (user?.last_name ?? 'not_found')
          const role = (user?.role?.slug ?? 'not_found')
          const phoneNumber = (user?.phone ?? 'not_found')
          const language = (user?.language ?? 'not_found')

          openReplayTracker?.setMetadata('first_name', firstName)
          openReplayTracker?.setMetadata('last_name', lastName)
          openReplayTracker?.setMetadata('phone_number', phoneNumber)
          openReplayTracker?.setMetadata('language', language)
          openReplayTracker?.setMetadata('role', role)

          if (process.env.REACT_APP_ENV !== 'development' && role === 'owners-user') {
            Sentry
              .init({
                dsn: 'https://e5d94e699fe647ab9e11e8e0b324d5f7@o1342000.ingest.sentry.io/4504016779542528',
                ignoreErrors: [/WebWorker/gm],
                integrations: [new BrowserTracing()],
                environment: String(enviromentDomain()),
                beforeBreadcrumb: excludeGraphQLFetch,
                denyUrls: ['https://www.cognitoforms.com/f/seamless.js'],
                // tracks the release version that is the gihub sha coming from the CI/CD
                release: process.env.REACT_APP_RELEASE_VERSION,
                tracesSampleRate: 1.0,
                initialScope: {
                  user: {
                    id: user.id,
                    email: user.email
                  }
                }
              })
          }
        }
      }
      catch (err) {
        console.error(err)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data])

  useEffect(() => {
    if (error != null) {
      const incompleteMetadata = error.graphQLErrors.find((err) => err.extensions.code === 'owners-incomplete-metadata')

      if (incompleteMetadata != null) {
        client?.mutate({
          mutation: gql`
            mutation {
              syncProfile {
                result
              }
            }
          `
        })
          .then(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            loginWithRedirect()
          })
          .catch((err) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            V1_SET_ERROR(err.toString() as string)
          })
      }
      else {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        V1_SET_ERROR(error.toString())
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  if (loading || (error != null)) {
    return (<></>)
  }

  if (!data) {
    return <></>
  }

  return (
    <ProfileContext.Provider value={data}>
      { children }
    </ProfileContext.Provider>
  )
}

export default ProfileContext
