import Images from '#app/v2/assets/Images'

export const data = {

  logo: Images.Logo1x1,
  companyName: 'Scrubbrush',
  address: 'Address 1234, NY, New York',
  email: 'email@email.com',
  phoneNumber: '(555) 555-5555',
  invoiceNumber: '121979',
  date: '09/04/2024',
  dueDate: '13/04/2024',
  services: [
    {
      amount: 2,
      price: 25,
      description: 'Lavandina'
    },
    {
      amount: 1,
      price: 575,
      description: 'Service for Paula - Residential cleaning on tuesday 1 bedroom 1 bathroom, 200ft, no pet'
    },
  ],
  enabledTips: true,
  primaryColor: '#0B6B9D',

}
