import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../autogenerate/schemas'
const defaultOptions = {} as const
export type AltBoAdminsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Altbo_Admins_Bool_Exp>
}>

export type AltBoAdminsQuery = { __typename?: 'query_root', altbo_admins: Array<{ __typename?: 'altbo_admins', zendesk_user_id?: any | undefined, type?: any | undefined, title?: string | undefined, status?: string | undefined, slack_user_id?: string | undefined, record_id?: string | undefined, rasayel_user_id?: any | undefined, project?: any | undefined, phone?: string | undefined, personal_email?: string | undefined, mini_app_user_id?: string | undefined, linear_user_id?: string | undefined, last_name?: string | undefined, gravatar?: string | undefined, first_name?: string | undefined, email?: string | undefined, created_at?: any | undefined, address?: string | undefined, birthdate?: any | undefined, nationality?: string | undefined }> }

export const AltBoAdminsDocument = gql`
    query AltBoAdmins($where: altbo_admins_bool_exp) {
  altbo_admins(where: $where) {
    zendesk_user_id
    type
    title
    type
    status
    slack_user_id
    record_id
    rasayel_user_id
    project
    phone
    personal_email
    mini_app_user_id
    linear_user_id
    last_name
    gravatar
    first_name
    email
    created_at
    address
    birthdate
    nationality
  }
}
    `

/**
 * __useAltBoAdminsQuery__
 *
 * To run a query within a React component, call `useAltBoAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAltBoAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAltBoAdminsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAltBoAdminsQuery(baseOptions?: Apollo.QueryHookOptions<AltBoAdminsQuery, AltBoAdminsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useQuery<AltBoAdminsQuery, AltBoAdminsQueryVariables>(AltBoAdminsDocument, options)
}
export function useAltBoAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AltBoAdminsQuery, AltBoAdminsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  }
  return Apollo.useLazyQuery<AltBoAdminsQuery, AltBoAdminsQueryVariables>(AltBoAdminsDocument, options)
}
export type AltBoAdminsQueryHookResult = ReturnType<typeof useAltBoAdminsQuery>
export type AltBoAdminsLazyQueryHookResult = ReturnType<typeof useAltBoAdminsLazyQuery>
export type AltBoAdminsQueryResult = Apollo.QueryResult<AltBoAdminsQuery, AltBoAdminsQueryVariables>
