import { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { SxProps } from '@mui/material'
import ChipMUI from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'

export function ChipUserEmail({ userData, sx, size }: { userData: { email: string, id: string }, sx?: SxProps, size?: 'small' | 'medium' | undefined }) {
  const [showID, setShowID] = useState(false)
  const [copied, setCopied] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    }
  }, [copied])

  return (
    <CopyToClipboard text={userData.email}>
      <Tooltip title={copied ? t('preferences.emailCopied') : null}>
        <ChipMUI
          sx={sx}
          variant='outlined'
          label={showID ? userData?.id : userData?.email}
          size={size ?? 'medium'}
          color='default'
          onClick={() => {
            setShowID(!showID)
            setCopied(true)
          }}
        />
      </Tooltip>
    </CopyToClipboard>
  )
}
