import { gql } from '@apollo/client'
export type JobsListFragment = { __typename?: 'jobs', business_id?: any | undefined, created_at: any, customer_id?: any | undefined, description?: string | undefined, id: any, source?: string | undefined, status?: string | undefined, user_id?: any | undefined, job_histories: Array<{ __typename?: 'job_histories', created_at: any, end_status?: string | undefined, start_status?: string | undefined, metadata?: any | undefined }> }

export type JobHistoriesListFragment = { __typename?: 'job_histories', created_at: any, end_status?: string | undefined, start_status?: string | undefined, metadata?: any | undefined }

export const JobHistoriesListFragmentDoc = gql`
    fragment jobHistoriesList on job_histories {
  created_at
  end_status
  start_status
  metadata
}
    `
export const JobsListFragmentDoc = gql`
    fragment jobsList on jobs {
  business_id
  created_at
  customer_id
  job_histories(limit: 30, order_by: {created_at: desc}) {
    ...jobHistoriesList
  }
  description
  id
  source
  status
  user_id
}
    ${JobHistoriesListFragmentDoc}`
