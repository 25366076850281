// @ts-ignore

import OwnersErrorBoundary from '#app/components/OwnersErrorBoundary'
import { AppProvider } from '#app/contexts/AppContext'
import { NotificationsProviderV1 } from '#app/contexts/NotificationsProvider'
import SegmentContext from '#app/contexts/SegmentContext'
import { PublicPageLayout } from '#app/layouts/PublicPageLayout'
import { ApolloProviderWithClient } from '#app/root/ApolloProviderWithClient'

export const PublicRoutesWrapper = () => (
  <div className='v1-root-container'>
    <AppProvider>
      <SegmentContext>
        <OwnersErrorBoundary>
          <NotificationsProviderV1
            maxSnack={3}
            autoHideDuration={4000}
          >
            <ApolloProviderWithClient isPublic={true}>
              <PublicPageLayout />
            </ApolloProviderWithClient>
          </NotificationsProviderV1>
        </OwnersErrorBoundary>
      </SegmentContext>
    </AppProvider>
  </div>
)
