import { useMemo } from 'react'
import { Box, Grid } from '@mui/material'
import Drawer from '@mui/material/Drawer'

import { useIsDesktopSize } from '#app/utils/mediaQueries'

import { Btn } from '../Buttons'

interface Props {
  children: JSX.Element
  size?: string
  closeHandler: () => void
  isOpen?: boolean
}

export const RightSideBarContainer = ({ children, footer }: { children: JSX.Element[] | JSX.Element, footer?: JSX.Element }) => {
  return (
    <Grid
      container
      sx={{
        minHeight: 'calc(100vh - 80px)'
      }}
      direction='column'
      justifyContent='space-between'
      alignItems='center'
    >
      <Grid
        item
        sx={{
          width: '100%'
        }}
      >
        <Box sx={{
          paddingTop: 2
        }}
        >
          {children}
        </Box>
      </Grid>
      <Grid
        item
        sx={{
          width: '100%',
          pt: '1rem'
        }}
      >
        {footer ?? null}
      </Grid>
    </Grid>
  )
}

const RightSideBar = ({ children, closeHandler, size, isOpen }: Props) => {
  const isDesktop = useIsDesktopSize()

  const sizeVal = useMemo(() => {
    if (size === 'md') return 600
    if (size === 'lg') return 900
    if (size === 'full') return '100vw'
    return 450
  }, [size])

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={closeHandler}
    >
      <Grid
        container
        p={3}
        sx={{
          width: isDesktop ? sizeVal : '100vw',
          zIndex: 9999
        }}
      >
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
          >
            <Btn.IconButton.Close onClick={closeHandler} />
          </Grid>
          { children }
        </Grid>
      </Grid>
    </Drawer>
  )
}

export default RightSideBar
