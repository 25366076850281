// @ts-ignore

import { ReactNode } from 'react'

import OwnersErrorBoundary from '#app/components/OwnersErrorBoundary'
import { AppProvider } from '#app/contexts/AppContext'
import { NotificationsProviderV2 } from '#app/contexts/NotificationsProvider'
import { ProfileProvider } from '#app/contexts/ProfileContext'
import SegmentContext from '#app/contexts/SegmentContext'
import SessionDetectContext from '#app/contexts/SessionDetectContext'
import { ForceLogout } from '#app/feature/ForceLogout'
import { ApolloProviderWithClient } from '#app/root/ApolloProviderWithClient'
import { Auth0ProviderWithRedirectCallback } from '#app/root/Auth0ProviderWithRedirectCallback'
import { BaseLayout } from '#app/v2-core/layouts/BaseLayout'

import { Authentication } from './Authentication'
import { Authorization } from './Authorization'

function MainContent({ content }: { content: React.ReactNode }) {
  return (
    <>{content}</>
  )
}

function Auth0Provider({ children }: { children: ReactNode }) {
  return (
    <Auth0ProviderWithRedirectCallback
    // @ts-expect-error type env
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      cacheLocation='localstorage'
      audience='backend-api'
      useRefreshTokens={true}
    >
      <>
        {children}
      </>
    </Auth0ProviderWithRedirectCallback>
  )
}

function MainProviders({ children }: { children: React.ReactNode }) {
  return (
    <SegmentContext>
      {/* <ProgressierScript> */}
      <OwnersErrorBoundary>
        <Auth0Provider>
          <ForceLogout>
            <NotificationsProviderV2>
              <Authentication>
                <ApolloProviderWithClient>
                  <ProfileProvider>
                    <SessionDetectContext>
                      <Authorization>
                        <MainContent content={children} />
                      </Authorization>
                    </SessionDetectContext>
                  </ProfileProvider>
                </ApolloProviderWithClient>
              </Authentication>
            </NotificationsProviderV2>
          </ForceLogout>
        </Auth0Provider>
      </OwnersErrorBoundary>
      {/* </ProgressierScript> */}
    </SegmentContext>
  )
}

export function ProtectedRoutesWrapperV2() {
  return (
    <AppProvider agnostic={true}>
      <MainProviders>
        <BaseLayout />
      </MainProviders>
    </AppProvider>
  )
}
