import { FormEvent, useRef } from 'react'
import { Send as SendIcon } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'

interface Props {
  label: string
  onSubmit: (option: string) => void
  variant: 'standard' | 'filled' | 'outlined' | undefined
}

export const InputWithSubmitButton = ({ label, onSubmit, variant }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    const inputVal = inputRef.current?.value
    onSubmit(inputVal ?? '')
    if (inputRef?.current) {
      inputRef.current.value = ''
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        label={label}
        variant={variant}
        size='medium'
        inputRef={inputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton type='submit'>
                <SendIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </form>
  )
}
