/* eslint-disable @typescript-eslint/indent */
import { JSXElementConstructor, ReactElement, useMemo } from 'react'
import { BsHourglassTop, BsQuestionSquareFill, BsUiChecks } from 'react-icons/bs'
import { SxProps } from '@mui/material'
import ChipMUI from '@mui/material/Chip'

import { THEME } from '#app/layouts/theme'

import { IconInQueue, IconLoop } from '../IconLoop'

export function ChipSequenceStatus({ status, onClick, sx }: { status: string, onClick?: any, sx?: SxProps }) {
  const chipData: {
    icon: ReactElement<any, string | JSXElementConstructor<any>> | undefined
    color: 'info' | 'warning' | 'default' | 'primary' | 'secondary' | 'error' | 'success' | undefined
    status: string
    text: string
  } = useMemo(() => {
    switch (status) {
      case 'pending':
        return {
          color: 'default',
          status,
          text: 'Pending',
          icon: <BsHourglassTop fontSize={THEME.ICONS.size.xs} />
        }
      case 'in_queue':
        return {
          color: 'info',
          status,
          text: 'In waiting queue',
          icon: <IconInQueue />
        }
      case 'in_progress':
        return {
          color: 'info',
          status,
          text: 'In Progress',
          icon: <IconLoop />
        }
      case 'completed':
        return {
          color: 'success',
          status,
          text: 'Completed',
          icon: <BsUiChecks fontSize={THEME.ICONS.size.xs} />
        }
      default:
        return {
          color: 'warning',
          status,
          text: status,
          icon: <BsQuestionSquareFill fontSize={THEME.ICONS.size.xs} />
        }
    }
  }, [status])

  return (
    <ChipMUI
      variant={onClick ? 'filled' : 'outlined'}
      sx={{
        cursor: onClick ? 'pointer' : 'auto',
        pl: 1,
        ...sx
      }}
      icon={chipData.icon}
      label={chipData.text}
      color={chipData.color}
      onClick={onClick || undefined}
    />
  )
}
