import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button } from '#app/v2/components/ui/button'

interface Props {
  title: string
  viewAllUrl: string
}
export const HeaderPlusView = ({ title, viewAllUrl }: Props) => {
  const { t } = useTranslation()

  return (
    <div className='flex items-center justify-between font-semibold text-[#304985]'>
      <h2 className=' text-lg/6  '>{title}</h2>
      <Button
        asChild
        variant='ghost'
        className=' px-1.5 py-0.5'
      >
        <Link
          to={viewAllUrl}
          state={{
            from: location.pathname,
            to: viewAllUrl
          }}
        >
          { t('v2.generic.viewAll', 'View all') }
        </Link>
      </Button>
    </div>
  )
}
