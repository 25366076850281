import { Activities } from '#app/operations/autogenerate/schemas'

import { Filters } from './types'

export const data = {
  bottomNavigation: {
    routes: {
      home: '/v2-ui/home',
      messages: '/v2-ui/messages',
      tasks: '/v2-ui/tasks',
      business: '/v2-ui/business',
    },
    isIOS: false,
    pendingMessages: 5,

  },
  filters: [
    'all',
    'clients',
    'leads',
    'appointments',
    'jobs',
    'reviews',
    'estimates',
    'invoices',
    'money',
    'tasks',
  ] as Filters[],
}

export const noNotifications = []
export const allNotifications = [
  // extracted from Joan's localhost
  {
    id: 'ceaa5c5f-bab2-4700-b0a2-06cb76920420',
    type: 'callCenterCall',
    user_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    receiver_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    category: 'clients',
    created_by: 'cd6915aa-c190-4fcf-b5b2-7e0e017bfde3',
    updated_by: null,
    source: 'n8n',
    created_at: '2023-11-21 21:42:30.482960 +00:00',
    updated_at: '2023-11-21 21:42:30.482960 +00:00',
    metadata: null,
    data: {
      text: 'John Doe'
    },
    read: false,
    whatsapp: null,
  },
  {
    id: 'cfaa5c5f-bab2-4700-b0a2-06cb76920420',
    type: 'newClient',
    user_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    receiver_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    category: 'clients',
    created_by: 'cd6915aa-c190-4fcf-b5b2-7e0e017bfde3',
    updated_by: null,
    source: 'n8n',
    created_at: '2023-11-21 21:42:30.482960 +00:00',
    updated_at: '2023-11-21 21:42:30.482960 +00:00',
    metadata: null,
    data: {},
    read: false,
    whatsapp: null,
  },
  {
    id: 'bf7fd844-5c71-4f81-9f50-df5b3c85278e',
    type: 'newLead',
    user_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    receiver_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    category: 'leads',
    created_by: 'cd6915aa-c190-4fcf-b5b2-7e0e017bfde3',
    updated_by: null,
    source: 'n8n',
    created_at: '2023-11-21 21:42:15.776572 +00:00',
    updated_at: '2023-11-21 21:42:15.776572 +00:00',
    metadata: null,
    data: {
      text: 'call-center'
    },
    read: false,
    whatsapp: null,
  },
  {
    id: '9a5daacf-54e8-44c2-a097-a13154f85193',
    type: 'newLead',
    user_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    receiver_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    category: 'leads',
    created_by: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    updated_by: null,
    source: 'joan-test',
    created_at: '2023-11-21 21:33:06.114762 +00:00',
    updated_at: '2023-11-21 21:33:06.114762 +00:00',
    metadata: null,
    data: {
      text: 'website'
    },
    read: false,

  },
  {
    id: '03260834-7794-4fea-8a13-1c100a324ac1',
    type: 'newAppointment',
    user_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    receiver_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    category: 'appointments',
    created_by: 'cd6915aa-c190-4fcf-b5b2-7e0e017bfde3',
    updated_by: null,
    source: 'n8n',
    created_at: '2023-11-21 21:26:45.817688 +00:00',
    updated_at: '2023-11-21 21:26:45.817688 +00:00',
    metadata: null,
    data: {},
    read: false,

    whatsapp: null,
  },
  {
    id: '4c311906-f9fa-4509-aaf0-a91de40cb45b',
    type: 'newJobBooking',
    user_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    receiver_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    category: 'jobs',
    created_by: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    updated_by: null,
    source: 'n8n',
    created_at: '2023-11-21 20:37:14.422845 +00:00',
    updated_at: '2023-11-21 20:37:14.422845 +00:00',
    metadata: null,
    data: {
      client: 'John Doe',
      address: 'Address 1234, City Country'
    },
    read: false,

    whatsapp: null,
  },
  {
    id: '1234db11-6e86-4e4c-b733-a0e6662e9eba',
    type: 'newReview',
    user_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    receiver_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    category: 'reviews',
    created_by: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    updated_by: null,
    source: 'n8n',
    created_at: '2023-11-21 20:35:11.103742 +00:00',
    updated_at: '2023-11-21 20:35:11.103742 +00:00',
    metadata: null,
    data: {},
    read: false,
    whatsapp: null,
  },
  {
    id: '2345db11-6e86-4e4c-b733-a0e6662e9eba',
    type: 'requestReview',
    user_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    receiver_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    category: 'reviews',
    created_by: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    updated_by: null,
    source: 'n8n',
    created_at: '2023-11-21 20:35:11.103742 +00:00',
    updated_at: '2023-11-21 20:35:11.103742 +00:00',
    metadata: null,
    data: {
      text: '233'
    },
    read: false,
    whatsapp: null,
  },
  {
    id: '2345db11-3e45-4e4c-b733-a0e6662e9eba',
    type: 'newEstimate',
    user_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    receiver_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    category: 'estimates',
    created_by: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    updated_by: null,
    source: 'n8n',
    created_at: '2023-11-21 20:35:11.103742 +00:00',
    updated_at: '2023-11-21 20:35:11.103742 +00:00',
    metadata: null,
    data: {},
    read: false,
    whatsapp: null,
  },
  {
    id: '2345db21-1e46-4e4c-b733-a0e6662e9eba',
    type: 'estimatedApproved',
    user_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    receiver_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    category: 'estimates',
    created_by: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    updated_by: null,
    source: 'n8n',
    created_at: '2023-11-21 20:35:11.103742 +00:00',
    updated_at: '2023-11-21 20:35:11.103742 +00:00',
    metadata: null,
    data: {
      text: 'Hector Buffs'
    },
    read: false,
    whatsapp: null,
  },
  {
    id: '1456db62-1e46-4e4c-b733-a0e6662e9eba',
    type: 'moneySent',
    user_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    receiver_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    category: 'money',
    created_by: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    updated_by: null,
    source: 'n8n',
    created_at: '2023-11-21 20:35:11.103742 +00:00',
    updated_at: '2023-11-21 20:35:11.103742 +00:00',
    metadata: null,
    data: {
      text: 'Carlos Slim'
    },
    read: false,
    whatsapp: null,
  },
  {
    id: '0372db11-6e86-4e4c-b733-a0e6662e9eba',
    type: 'taskAssigned',
    user_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    receiver_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    category: 'tasks',
    created_by: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    updated_by: null,
    source: 'n8n',
    created_at: '2023-11-21 20:35:11.103742 +00:00',
    updated_at: '2023-11-21 20:35:11.103742 +00:00',
    metadata: null,
    data: {
      text: 'Ask for reviews'
    },
    read: false,
    whatsapp: null,
  },
  {
    id: '0483db22-6e86-4e4c-b733-a0e6662e9eba',
    type: 'taskCommented',
    user_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    receiver_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    category: 'tasks',
    created_by: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    updated_by: null,
    source: 'n8n',
    created_at: '2023-11-21 20:35:11.103742 +00:00',
    updated_at: '2023-11-21 20:35:11.103742 +00:00',
    metadata: null,
    data: {
      text: 'Approve Website'
    },
    read: false,

    whatsapp: null,
  },
  {
    id: '26873968-b664-4348-a6a5-1fa187ee0301',
    type: 'taskCompleted',
    user_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    receiver_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    category: 'tasks',
    created_by: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    updated_by: null,
    source: 'n8n',
    created_at: '2023-11-21 20:35:11.103742 +00:00',
    updated_at: '2023-11-21 20:35:11.103742 +00:00',
    metadata: null,
    data: {
      text: 'Choose Logo'
    },
    read: false,
    whatsapp: null,
  },
  {
    id: '55bb02a2-b3ee-4f4d-a597-dc21388fa57c',
    type: 'invoiceApproved',
    user_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    receiver_id: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    category: 'invoices',
    created_by: '5844ac5e-c909-49df-bd2d-5ab4be9fd2c7',
    updated_by: null,
    source: 'joan-test',
    created_at: '2023-11-21 20:32:17.045575 +00:00',
    updated_at: '2023-11-21 20:32:17.045575 +00:00',
    metadata: null,
    data: {
      text: 'Josh Hamilton'
    },
    read: false,
  },
] as Activities[]
