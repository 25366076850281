import { MouseEvent } from 'react'
import { BsArrowRepeat, BsArrowsCollapse, BsFillInfoCircleFill, BsLink45Deg, BsPencil, BsPlus, BsThreeDotsVertical, BsTrashFill, BsXLg } from 'react-icons/bs'
import { IoIosCopy, IoMdArrowRoundBack, IoMdSend } from 'react-icons/io'
import { styled, SxProps } from '@mui/material'
import IconButtonMui from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import { THEME } from '#app/layouts/theme'

export const IconButtonStyled = styled(IconButtonMui)`
  margin-left: 0.5rem;
  color: ${(props) => props.color ?? THEME.ICONS.color.secondary};
`

export const IconButtonInfo = ({ cb }: { cb?: () => void }) => {
  return (
    <Tooltip title='View Info'>
      <IconButtonStyled
        aria-label='info'
        size='small'
        onClick={cb}
      >
        <BsFillInfoCircleFill />
      </IconButtonStyled>
    </Tooltip>
  )
}

interface StandardProps {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  sx?: SxProps
  disabled?: boolean
  tooltip?: string
}

export const IconButton = {
  Delete: ({ onClick, sx, disabled, isDeleted, withoutWarning, loading }: { onClick?: () => void, disabled?: boolean, sx?: SxProps, isDeleted?: boolean, withoutWarning?: boolean, loading?: boolean }) => {
    return (
      <Tooltip title={isDeleted ? 'This element is already deleted' : 'Delete'}>
        <IconButtonStyled
          disabled={disabled}
          color={withoutWarning ? 'default' : isDeleted ? 'default' : 'error'}
          sx={sx}
          aria-label='refresh'
          size='small'
          onClick={onClick}
        >
          {loading
            ? (
              <BsArrowRepeat
                className='animate-spin'
                fontSize={THEME.ICONS.size.sm}
              />
            )
            : <BsTrashFill fontSize={THEME.ICONS.size.sm} />}
        </IconButtonStyled>
      </Tooltip>
    )
  },
  Close: ({ onClick, sx, disabled, tooltip }: StandardProps) => {
    return (
      <Tooltip title={tooltip ?? 'Close'}>
        <IconButtonStyled
          disabled={disabled}
          sx={sx}
          aria-label='refresh'
          size='small'
          onClick={onClick}
        >
          <BsXLg fontSize={THEME.ICONS.size.sm} />
        </IconButtonStyled>
      </Tooltip>
    )
  },
  Sync: ({ onClick, sx, disabled, tooltip }: StandardProps) => {
    return (
      <Tooltip title={tooltip ?? 'Refresh'}>
        <IconButtonStyled
          disabled={disabled}
          sx={sx}
          aria-label='refresh'
          size='small'
          onClick={onClick}
        >
          <BsArrowRepeat fontSize={THEME.ICONS.size.sm} />
        </IconButtonStyled>
      </Tooltip>
    )
  },
  Plus: ({ onClick, sx, disabled }: StandardProps) => {
    return (
      <Tooltip title='Add'>
        <IconButtonStyled
          disabled={disabled}
          sx={sx}
          aria-label='add element'
          size='small'
          onClick={onClick}
        >
          <BsPlus fontSize={THEME.ICONS.size.sm} />
        </IconButtonStyled>
      </Tooltip>
    )
  },
  Info: ({ onClick, sx, disabled }: StandardProps) => {
    return (
      <Tooltip title='View Info'>
        <IconButtonStyled
          disabled={disabled}
          sx={sx}
          aria-label='info'
          size='small'
          onClick={onClick}
        >
          <BsFillInfoCircleFill fontSize={THEME.ICONS.size.sm} />
        </IconButtonStyled>
      </Tooltip>
    )
  },
  Edit: ({ onClick, sx, disabled }: StandardProps) => {
    return (
      <Tooltip title='Edit'>
        <IconButtonStyled
          disabled={disabled}
          sx={sx}
          aria-label='edit'
          size='small'
          onClick={onClick}
        >
          <BsPencil fontSize={THEME.ICONS.size.sm} />
        </IconButtonStyled>
      </Tooltip>
    )
  },
  Collapse: ({ onClick, sx, disabled }: StandardProps) => {
    return (
      <Tooltip title='Collapse'>
        <IconButtonStyled
          disabled={disabled}
          sx={sx}
          aria-label='collapse'
          size='small'
          onClick={onClick}
        >
          <BsArrowsCollapse fontSize={THEME.ICONS.size.sm} />
        </IconButtonStyled>
      </Tooltip>
    )
  },
  More: ({ onClick, sx, disabled }: StandardProps) => {
    return (
      <Tooltip title='More info'>
        <IconButtonStyled
          disabled={disabled}
          sx={sx}
          aria-label='More info'
          size='small'
          onClick={onClick}
        >
          <BsThreeDotsVertical fontSize={THEME.ICONS.size.sm} />
        </IconButtonStyled>
      </Tooltip>
    )
  },
  Send: ({ onClick, sx, tooltip, disabled }: StandardProps) => {
    return (
      <Tooltip title={tooltip ?? 'Send'}>
        <IconButtonStyled
          disabled={disabled}
          sx={sx}
          aria-label='Send'
          size='small'
          onClick={onClick}
        >
          <IoMdSend fontSize={THEME.ICONS.size.sm} />
        </IconButtonStyled>
      </Tooltip>
    )
  },
  Copy: ({ onClick, sx, tooltip, disabled }: StandardProps) => {
    return (
      <Tooltip title={tooltip ?? 'Copy'}>
        <IconButtonStyled
          disabled={disabled}
          sx={sx}
          aria-label='Copy'
          size='small'
          onClick={onClick}
        >
          <IoIosCopy fontSize={THEME.ICONS.size.sm} />
        </IconButtonStyled>
      </Tooltip>
    )
  },
  Link: ({ onClick, sx, tooltip, disabled }: StandardProps) => {
    return (
      <Tooltip title={tooltip ?? 'Link'}>
        <IconButtonStyled
          disabled={disabled}
          sx={sx}
          aria-label='Link'
          size='small'
          onClick={onClick}
        >
          <BsLink45Deg fontSize={THEME.ICONS.size.sm} />
        </IconButtonStyled>
      </Tooltip>
    )
  },
  Back: ({ onClick, sx, tooltip, disabled }: StandardProps) => {
    return (
      <Tooltip title={tooltip ?? 'Back'}>
        <IconButtonStyled
          disabled={disabled}
          sx={sx}
          aria-label='Back'
          size='small'
          onClick={onClick}
        >
          <IoMdArrowRoundBack fontSize={THEME.ICONS.size.sm} />
        </IconButtonStyled>
      </Tooltip>
    )
  }
}
